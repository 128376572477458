import { Providers } from '@lynx/core/src/interfaces/ObjectStore'
import { Logger } from '../../modules/Logger'
import { BaseCall } from '../BaseCall'
import { APIConfig, APIResponse, SharesInfo, Thumbnails } from '../interfaces'

export class Share extends BaseCall {
	public async getObjectUrl(token: string): Promise<{ link: string; extension: string }> {
		try {
			const response = await this.client.get(`/shares/${token}/download`, {
				responseType: 'blob'
			})

			let downloadLink = ''
			downloadLink = this.getContentDispositionHeader(response)
			if (!downloadLink) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return
			}

			const extension = downloadLink.split('.').pop()
			if (!extension) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return
			}

			const blob = new Blob([response.data], { type: response.headers['content-type'] })

			return { link: window.URL.createObjectURL(blob), extension }
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public async download(token: string): Promise<boolean> {
		try {
			const response = await this.client.get(`/shares/${token}/download`, {
				responseType: 'blob'
			})

			let downloadLink = ''
			downloadLink = this.getContentDispositionHeader(response)
			if (!downloadLink) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return
			}

			const extension = downloadLink.split('.').pop()
			if (!extension) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return
			}

			const blob = new Blob([response.data], { type: response.headers['content-type'] })
			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = downloadLink
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public async downloadBuffer(token: string): Promise<Buffer | void> {
		try {
			const response = await this.client.get(`/shares/${token}/download`, {
				responseType: 'arraybuffer'
			})

			return Buffer.from(response.data)
		} catch (e) {
			Logger.error(e)
		}
	}

	public async makePrivate(token: string): Promise<APIResponse> {
		try {
			const response = await this.client.patch<APIResponse>(`/shares/${token}`, {
				isShared: false
			})

			if (response.status !== 200) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public async makePublic(token: string): Promise<APIResponse> {
		try {
			const response = await this.client.patch<APIResponse>(`/shares/${token}`, {
				isShared: true
			})

			if (response.status !== 200) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public async details(token: string): Promise<APIResponse<SharesInfo>> {
		try {
			const response = await this.client.get<APIResponse<SharesInfo>>(`/shares/${token}`)

			return response.data
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return e.response.data || null
		}
	}

	public async save(token: string, provider: Providers, driveItemId: string, filename: string): Promise<APIResponse> {
		try {
			const response = await this.client.post<APIResponse>(`/shares/save/${token}/${provider}/${driveItemId}`, {
				filename
			})
			return response.data
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return e.response.data || null
		}
	}

	public async thumbnails(token: string): Promise<APIResponse<Thumbnails>> {
		try {
			const response = await this.client.get<APIResponse<Thumbnails>>(`/shares/${token}/thumbnails`)

			if (response.status !== 200) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
