import { BasicButton, ListItem } from '@lynx/client-core/src/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './YourPlanFree.module.scss'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'

export const YourPlanFree = (): React.ReactElement => {
	const {
		profile: { isPro }
	} = useSelector((state: ApplicationState) => state)
	const { t } = useTranslation()
	const navigate = useNavigate()

	const handleUpgradeClicked = (): void => {
		navigate('/plans')
	}

	return (
		<ListItem header={t('pages.profile.yourPlan')} hidden={isPro}>
			<div className={css['container']}>
				<div className={css['container__description']}>
					<h3>{t('pages.profile.lynxFree')}</h3>
					<div>{t('pages.profile.allBasicFeatures')}</div>
				</div>
				<BasicButton variant="pro" onClick={handleUpgradeClicked}>
					{t('pages.profile.upgrade')}
				</BasicButton>
			</div>
		</ListItem>
	)
}
