/* eslint-disable */
import { Logger } from '../../modules/Logger'
import { BaseCall } from '../BaseCall'
import { APIConfig, ActivityConfig, DriveItemType, APIResponse, ActivityDefinition } from '../interfaces'

export class Activities extends BaseCall {
	public async definitions(): Promise<ActivityDefinition[] | undefined> {
		try {
			const response = await this.client.get<APIResponse<ActivityDefinition[]>>('/activities/definitions')
			if (response.status !== 200) {
				Logger.error(response.statusText)
				return []
			}
			return response.data.data
		} catch (e) {
			Logger.error(e)
			return []
		}
	}

	public async updateLastPlayed(collectionId: string, activityId: string): Promise<void> {
		try {
			const response = await this.client.post(`/activities/touch/${collectionId}/${activityId}`)
			if (response.status !== 200) {
				Logger.error(response.statusText)
			}
		} catch (e) {
			Logger.error(e)
		}
	}

	public async publish(info: { name: string; description: string; id: string; availability: string; category: string }): Promise<boolean | undefined> {
		try {
			const response = await this.client.post<APIResponse>(`/activities/publish`, {
				...info
			})
			if (response.status !== 200) {
				Logger.error(response.statusText)
				return false
			}

			return true
		} catch (e) {
			Logger.error(e)
		}
	}

	public async get(): Promise<any> {
		try {
			const response = await this.client.get('/activities')
			return response.data
		} catch (e) {
			Logger.error(e)
		}
	}

	public async exportCollection(collectionIndex: number): Promise<DriveItemType | null> {
		try {
			const response = await this.client.post('/activities/exportCollection', {
				index: collectionIndex
			})

			if (response.status !== 200) {
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public async renameCollection(id: number, name: string): Promise<boolean> {
		try {
			const response = await this.client.post(`/activities/renameCollection/${id}`, {
				name
			})

			if (response.status !== 200) {
				return false
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public async importCollection(collectionExport: any): Promise<boolean> {
		try {
			const response = await this.client.post('/activities/importCollection', {
				collectionExport
			})

			if (response.status !== 200) {
				return false
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public async createCollection(name: string): Promise<boolean> {
		try {
			const response = await this.client.post('/activities/createCollection', {
				name
			})

			if (response.status !== 200) {
				return false
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public async deleteCollection(collectionIndex: number): Promise<boolean> {
		try {
			const response = await this.client.delete(`/activities/deleteCollection/${collectionIndex}`)

			if (response.status !== 200) {
				return false
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public async add(collection_id: string, collectionIndex: number, configuration: any): Promise<boolean> {
		try {
			const response = await this.client.post(
				'/activities',
				{
					collectionIndex,
					configuration,
					collection_id
				},
				{
					headers: { 'Content-Type': 'application/json' }
				}
			)

			if (response.status !== 200) {
				return false
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public async delete(collectionIndex: number, id: string): Promise<boolean> {
		try {
			const response = await this.client.delete(`/activities/${collectionIndex}/${id}`)

			if (response.status !== 200) {
				Logger.error(response)
				return false
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public async config(): Promise<ActivityConfig[] | undefined> {
		try {
			const response = await this.client.get<ActivityConfig[]>(`/activities`)
			return response.data
		} catch (e) {
			Logger.error(e)
		}
	}

	public async remove(name: string, filePathB64: string): Promise<boolean> {
		try {
			const response = await this.client.delete(`/activities/${name}/${filePathB64}`)

			if (response.status !== 200) {
				Logger.error(response)
				return false
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public async updateConfig(activity: string, fileId: string): Promise<ActivityConfig[] | undefined> {
		try {
			const response = await this.client.post<ActivityConfig[]>(
				'/activities',
				{
					activity,
					fileId
				},
				{
					headers: { 'Content-Type': 'application/json' }
				}
			)

			return response.data
		} catch (e) {
			Logger.error(e)
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
