import { BaseCall } from '../BaseCall'
import { AccessType, APIConfig, APIResponse, DriveItemShare, SharesInfo, ShareType, Thumbnails } from '../interfaces'
import { Providers } from '@lynx/core/src/interfaces/ObjectStore'
import { Logger } from '../../modules/Logger'

export class Sharing extends BaseCall {
	public async getObjectUrl(token: string): Promise<{ link: string; extension: string }> {
		try {
			const response = await this.client.get(`/sharing/${token}/download`, {
				responseType: 'blob'
			})

			let downloadLink = ''
			downloadLink = this.getContentDispositionHeader(response)
			if (!downloadLink) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return
			}

			const extension = downloadLink.split('.').pop()
			if (!extension) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return
			}

			const blob = new Blob([response.data], { type: response.headers['content-type'] })

			return { link: window.URL.createObjectURL(blob), extension }
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public async download(token: string): Promise<boolean> {
		try {
			const response = await this.client.get(`/sharing/${token}/download`, {
				responseType: 'blob'
			})

			let downloadLink = ''
			downloadLink = this.getContentDispositionHeader(response)
			if (!downloadLink) {
				return false
			}

			const extension = downloadLink.split('.').pop()
			if (!extension) {
				return false
			}

			const blob = new Blob([response.data], { type: response.headers['content-type'] })
			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = downloadLink
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public async downloadBuffer(token: string): Promise<Buffer | void> {
		try {
			const response = await this.client.get(`/sharing/${token}/download`, {
				responseType: 'arraybuffer'
			})

			return Buffer.from(response.data)
		} catch (e) {
			Logger.error(e)
		}
	}

	public async getCode(token: string): Promise<APIResponse<{ url: string; code: string }> | null> {
		try {
			const response = await this.client.get<APIResponse<{ url: string; code: string }>>(`/sharing/code/${token}`)
			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public async removeUser(driveItemId: string, userId: string): Promise<APIResponse<DriveItemShare> | null> {
		try {
			const response = await this.client.delete<APIResponse<DriveItemShare>>(`/sharing/${driveItemId}/${userId}`)
			if (response.status !== 200) {
				Logger.error(response.statusText)
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public updateShare = async (shareToken: string, file: File): Promise<{ url: string }> => {
		try {
			const data = new FormData()
			data.append('file', file)
			data.append('shareToken', shareToken)

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}

			const res = await this.client.post(`/sharing/update`, data, config)
			return res.data
		} catch (err) {
			Logger.error(err)
			throw new Error('Failed to upscale share')
		}
	}

	public async save(token: string, provider: Providers, driveItemId: string, filename: string): Promise<APIResponse> {
		try {
			const response = await this.client.post<APIResponse>(`/sharing/save/${token}/${provider}/${driveItemId}`, {
				filename
			})
			return response.data
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return e.response.data || null
		}
	}

	public async info(token: string): Promise<APIResponse<SharesInfo | null>> {
		try {
			const response = await this.client({
				method: 'GET',
				url: `/sharing/info/${token}`,
				validateStatus: () => true
			})
			return response?.data || null
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public async invite(driveItemId: string, email: string, shareType: ShareType, message = ''): Promise<APIResponse<DriveItemShare> | null> {
		try {
			const response = await this.client.post<APIResponse<DriveItemShare>>(`/sharing/invite/${driveItemId}`, {
				shareType,
				email,
				message
			})

			if (response.status !== 200) {
				Logger.error(response.statusText)
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public async removeSymLink(driveItemId: string): Promise<APIResponse> {
		try {
			const response = await this.client.delete<APIResponse>(`/sharing/${driveItemId}/remove`)
			if (response.status !== 200) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public async thumbnails(token: string, options: { lowResolution: boolean }): Promise<APIResponse<Thumbnails>> {
		try {
			const response = await this.client.get<APIResponse<Thumbnails>>(`/sharing/${token}/thumbnails?lowResolution=${options.lowResolution}`)
			if (response.status !== 200) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public async updateAccessType(driveItemId: string, accessType: AccessType): Promise<APIResponse<DriveItemShare> | null> {
		try {
			const response = await this.client.patch<APIResponse<DriveItemShare>>(`/sharing/accesstype/${driveItemId}`, {
				accessType
			})

			if (response.status !== 200) {
				Logger.error(response.statusText)
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public async updatePermission(driveItemId: string, userId: string, shareType: ShareType): Promise<APIResponse<DriveItemShare> | null> {
		try {
			const response = await this.client.patch<APIResponse<DriveItemShare>>(`/sharing/permission/${driveItemId}`, {
				shareType,
				userId
			})

			if (response.status !== 200) {
				Logger.error(response.statusText)
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public async get(driveItemId: string): Promise<APIResponse<DriveItemShare> | null> {
		try {
			const response = await this.client.get<APIResponse<DriveItemShare>>(`/sharing/${driveItemId}`)
			if (response.status !== 200) {
				Logger.error(response.statusText)
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public async getAll(): Promise<APIResponse<DriveItemShare[]> | []> {
		try {
			const response = await this.client.get<APIResponse<DriveItemShare[]>>('/sharing')
			if (response.status !== 200) {
				Logger.error(response.statusText)
				return []
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			return []
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
