import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { useNavigate } from 'react-router'

export default function useRedirectOnLogin(): void {
	const navigate = useNavigate()
	const { profile: profileState } = useSelector((state: ApplicationState) => state)

	const { showLoginModal } = profileState

	useEffect(() => {
		if (showLoginModal) {
			navigate('/')
		}
	}, [showLoginModal])
}
