import { BasicButton, Checkbox, LanguageDropdown, Modal } from '@lynx/client-core/src/components'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { Cookie } from '@lynx/client-core/src/modules'
import i18next from 'i18next'
import { addAnalytics } from 'modules/utils'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { ApplicationState } from 'store'
import { hideModal, showModal } from 'store/modal'
import css from './CookieConsentModal.module.css'

export const CookieConsentModal = (): React.ReactElement => {
	const [cookiesAnalytics, setCookiesAnalytics] = React.useState<boolean>(false)
	const profile = useSelector((state: ApplicationState) => state.profile)
	const config = useSelector((state: ApplicationState) => state.config)
	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		const consent = Cookie.get('lynxconsent') as 'necessary' | 'all'
		if (!consent && !profile.loggedIn && location.pathname !== '/search') {
			dispatch(showModal({ name: 'CookieConsentModal', sticky: true }))
		}
	}, [])

	const handleToggleAnalytics = (): void => {
		setCookiesAnalytics(!cookiesAnalytics)
	}

	const handleAcceptSelected = (): void => {
		Cookie.deleteMarketing()
		Cookie.set('lynxconsent', cookiesAnalytics ? 'all' : 'necessary', {
			SameSite: 'Strict',
			Path: '/',
			Domain: config.ENVIRONMENT === 'dev' ? 'localhost' : '.lynxcloud.app'
		})

		window.location.reload()
	}

	const handleAcceptAll = (): void => {
		addAnalytics()

		Cookie.set('lynxconsent', 'all', { SameSite: 'Strict', Path: '/', Domain: config.ENVIRONMENT === 'dev' ? 'localhost' : '.lynxcloud.app' })
		dispatch(hideModal())
	}

	return (
		<Modal name="CookieConsentModal" hideCloseButton={true} className={css['zIndex']}>
			<ModalDefaultLayout
				title={
					<div className={css.titleContainer}>
						Allow Cookies <p>{i18next.t('components.cookieconsent.header')}</p>
					</div>
				}
				footer={
					<React.Fragment>
						<BasicButton onClick={handleAcceptSelected} variant="transparent">
							{i18next.t('components.cookieconsent.acceptSelected')}
						</BasicButton>
						<BasicButton onClick={handleAcceptAll} variant="blue">
							{i18next.t('components.cookieconsent.acceptAll')}
						</BasicButton>
					</React.Fragment>
				}
				body={
					<React.Fragment>
						<div className={css.container}>
							<p>{i18next.t('components.cookieconsent.body')} </p>
							<div className={css.option}>
								<Checkbox aria-label="required-cookies-disabled" disabled checked className={css.checkbox} />
								<div>
									<span style={{ fontWeight: 'bold' }}>{i18next.t('components.cookieconsent.necessaryCookies1')}</span>
									&nbsp;
									{i18next.t('components.cookieconsent.necessaryCookies2')}
								</div>
							</div>
							<div className={css.option}>
								<Checkbox aria-label="enable-analytics" checked={cookiesAnalytics} onChange={handleToggleAnalytics} className={css.checkbox} />
								<div>
									<span style={{ fontWeight: 'bold' }}>{i18next.t('components.cookieconsent.analyticalCookies1')}</span>
									&nbsp;
									{i18next.t('components.cookieconsent.analyticalCookies2')}
								</div>
							</div>
							<div className={css.selectListContainer}>
								<LanguageDropdown />
							</div>
						</div>
					</React.Fragment>
				}
			/>
		</Modal>
	)
}
