import { BasicButton, Button, CheckCircleOutlineIcon, CloseIcon, DeleteOutlineIcon } from '@lynx/client-core/src/components'
import { SubmitTranslationInterface } from '@lynx/core/src/interfaces/Translation'
import React, { useState } from 'react'
import css from './PreSubmittedTranslations.module.scss'
import { t } from 'i18next'
import { Logger } from '@lynx/client-core/src/modules'

export const PreSubmittedTranslations = ({
	editedTranslations,
	removeTranslation,
	onSubmitTranslations,
	isLoading,
	isTranslationSubmitted,
	setIsLoading,
	mRef
}: {
	editedTranslations: SubmitTranslationInterface[]
	onSubmitTranslations: () => Promise<void>
	removeTranslation: (key: string) => void
	isLoading: boolean
	isTranslationSubmitted: boolean
	setIsLoading: (isLoading: boolean) => void
	mRef?: React.RefObject<HTMLDivElement>
}): React.ReactElement => {
	const [isError, setIsError] = useState<boolean>(false)

	const submitTranslations = async (): Promise<void> => {
		setIsLoading(true)
		setIsError(false)
		try {
			await onSubmitTranslations()
		} catch (err) {
			setIsError(true)
			Logger.error(err)
		} finally {
			setIsLoading(false)
		}
	}

	const responseContainer = isError ? (
		<div className={[css.submittedTranslationsError, css.submittedTranslations].join(' ')}>
			<CloseIcon /> {t('components.lynxTranslations.translationChangesSubmitError')}
		</div>
	) : (
		<div className={css.submittedTranslations}>
			<CheckCircleOutlineIcon /> {t('components.lynxTranslations.translationChangesSubmitted')}
		</div>
	)
	return (
		<div ref={mRef}>
			<div className={css.translationsContainer}>
				<div className={css.header}>
					{t('components.lynxTranslations.translationChanges')} {editedTranslations.length ? `(${editedTranslations.length})` : ''}
				</div>
				<div className={css.body}>
					<div>
						{editedTranslations.length ? (
							editedTranslations.reverse().map(({ textFrom, textTo, key }, i) => (
								<div key={textFrom + key} className={css.singleRow}>
									<div className={css.changeCount}>
										{i + 1} <span> - </span>
									</div>
									<div className={css.singleChanges}>
										<span className={css.textTo}>{textTo}</span>
										<span className={css.textFrom}>{textFrom}</span>
									</div>
									<div onClick={(): void => removeTranslation(key)} className={css.singleRemove}>
										<DeleteOutlineIcon />
									</div>
								</div>
							))
						) : (
							<div className={`${css.singleRow} ${css.singleRowNoChanges}`}>{t('components.lynxTranslations.noChangesToSubmit')}</div>
						)}
						<div className={`${css.singleRow} ${css.singleRowButton}`}>
							{isTranslationSubmitted ? (
								responseContainer
							) : (
								<BasicButton variant="blue" disabled={!editedTranslations.length} onClick={submitTranslations} isLoading={isLoading}>
									{t('components.lynxTranslations.submitTranslationChanges')}
								</BasicButton>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
