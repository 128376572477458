import { BasicButton, Document } from '@lynx/client-core/src/components'
import { Cookie } from '@lynx/client-core/src/modules'
import { ApplicationState } from '@lynx/client-core/src/store'
import { Card } from 'components/elmstone/Card'
import React from 'react'
import { useSelector } from 'react-redux'
import css from './Base64.module.scss'

export const Base64 = (): React.ReactElement => {
	const config = useSelector((state: ApplicationState) => state.config)
	const [unencoded, setUnencoded] = React.useState<string>('')
	const [decoded, setDecoded] = React.useState<string>('')

	const getDecoded = (base64: string): string => {
		try {
			let decoded = atob(base64)
			decoded = decoded.replaceAll(' ', '\n\n')
			return decoded
		} catch (e) {
			return ''
		}
	}

	const handleBase64Changed = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
		if (!e.target.value?.length) {
			return
		}
		setUnencoded(e.target.value)
		setDecoded(getDecoded(e.target.value))
	}

	const handleFromCookieClicked = (): void => {
		const cookie = Cookie.get('lynxbridge', config.ENVIRONMENT)
		if (!cookie) {
			return
		}
		setUnencoded(cookie)
		setDecoded(getDecoded(cookie))
	}

	const handleClearButtonClicked = (): void => {
		setUnencoded('')
		setDecoded('')
	}

	return (
		<Document title={'Base64'} description="Base64" keywords="Base64" auth={true} disableOverflow={true}>
			<React.Fragment>
				<Card className={css['container']}>
					<div className={css['container__buttons']}>
						<BasicButton variant="danger" onClick={handleClearButtonClicked}>
							Clear
						</BasicButton>
						<BasicButton variant="blue" onClick={handleFromCookieClicked}>
							From LynxBridge Cookie
						</BasicButton>
					</div>
					<textarea onChange={handleBase64Changed} value={unencoded} />
					<textarea value={decoded} readOnly={true} />
				</Card>
			</React.Fragment>
		</Document>
	)
}
