import { Tier } from '@lynx/core/src/interfaces'

export const isPreviousTierHigherThenCurrent = (tier: Tier, index: number, tiers: Tier[]): { isError: boolean; previousTierUpTo: number } => {
	const previousTier = tiers[index - 1]
	if (index === 0 || tier.upTo === null || !previousTier) return { isError: false, previousTierUpTo: 0 }
	if (previousTier.upTo === null) throw new Error('Invalid previous tier ' + previousTier)
	const isError = previousTier.upTo >= tier.upTo
	return { isError, previousTierUpTo: previousTier.upTo }
}

export const getTierErrorMsgs = (tier: Tier, index: number, tiers: Tier[]): { type: 'error'; message: string }[] => {
	const { isError, previousTierUpTo } = isPreviousTierHigherThenCurrent(tier, index, tiers)
	if (!isError) return []
	return [{ message: `Overlap error, minimum quantity: ${previousTierUpTo + 1}`, type: 'error' }]
}
