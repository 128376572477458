import { BasicButton, Modal } from '@lynx/client-core/src/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { hideModal } from 'store/modal'
import css from './InviteSentModal.module.scss'

export const InviteSentModal = (): React.ReactElement | null => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const handleCloseClicked = (): void => {
		dispatch(hideModal())
	}

	return (
		<Modal name="InviteSentModal" title={'Invite Sent'}>
			<div>
				<div className={css['textContainer']}>
					<p>{t('pages.organisations.inviteSent')}</p>
				</div>
				<div className={css['buttons']}>
					<BasicButton variant="blue" onClick={handleCloseClicked}>
						{t('pages.organisations.close')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
