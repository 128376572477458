import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Document } from '@lynx/client-core/src/components'
import { OrganisationInviteApiData } from '@lynx/core/src/interfaces/Organisation'
import { useThunkDispatch } from 'hooks'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ApplicationState } from 'store'
import { logout } from 'store/profile'
import css from './OrganisationInvite.module.scss'

export const OrganisationInvite = (): React.ReactElement => {
	const { orgId, inviteId } = useParams<{ orgId: string; inviteId: string; organisationName?: string }>()
	const { email, loggedIn } = useSelector((state: ApplicationState) => state.profile)
	const navigate = useNavigate()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const organisationId = Number(orgId)
	const [inviteData, setInviteData] = useState<null | OrganisationInviteApiData>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const logoutUser = async (): Promise<void> => {
		await dispatchThunk(logout())
	}

	const getInviteData = async (): Promise<void> => {
		if (!orgId || !inviteId) return
		try {
			setIsLoading(true)
			const inviteData = await APIRequest.Organisations.getInvite(organisationId, inviteId)
			setInviteData(inviteData)
		} catch (error) {
			// do nothing
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (inviteData && loggedIn) {
			const isInvitedUser = inviteData.email === email
			if (!isInvitedUser) logoutUser()
		}
	}, [inviteData])

	useEffect(() => {
		const mount = async (): Promise<void> => {
			if (!orgId || !inviteId) {
				navigate('/')
				return
			}
			await getInviteData()
		}
		mount()
	}, [])

	const accept = async (): Promise<void> => {
		if (!orgId || !inviteId) {
			return
		}

		const response = await APIRequest.Organisations.acceptOrganisationInvite(organisationId, inviteId)
		if (response.error) {
			alert('error')
			return
		}
		navigate('/organisations')
	}

	const decline = async (): Promise<void> => {
		if (!orgId || !inviteId) {
			return
		}

		const response = await APIRequest.Organisations.declineOrganisationInvite(organisationId, inviteId)
		if (response.error) {
			alert('error')
			return
		}
		navigate('/')
	}

	return (
		<Document title={`LYNX ${t('pages.organisation.organisationInvite')}`} auth>
			<div className={css['container']}>
				{!inviteData && !isLoading ? (
					<p>{t('pages.organisation.inviteNotFound')}</p>
				) : (
					<>
						<h2>
							{inviteData?.organisationName} {i18next.t('pages.organisationInvite.invite')}
						</h2>
						<p>
							{i18next.t('pages.organisationInvite.description')} {inviteData?.organisationName}{' '}
							{i18next.t('pages.organisationInvite.organisation')}
						</p>
						<div className={css['btn-container']}>
							<BasicButton variant="danger" onClick={decline}>
								{i18next.t('pages.organisationInvite.decline')}
							</BasicButton>
							<BasicButton variant="blue" onClick={accept}>
								{i18next.t('pages.organisationInvite.accept')}
							</BasicButton>
						</div>
					</>
				)}
			</div>
		</Document>
	)
}
