import * as React from 'react'
import * as ReactDOM from 'react-dom'

const modalRoot = document.getElementById('modal-root')

interface ModalPortalProps {
	children: React.ReactNode
}

export class ModalPortal extends React.Component<ModalPortalProps> {
	modalRootElement: HTMLDivElement

	constructor(props: ModalPortalProps) {
		super(props)
		this.modalRootElement = document.createElement('div')
	}

	componentDidMount(): void {
		if (modalRoot) {
			modalRoot.appendChild(this.modalRootElement)
		}
	}

	componentWillUnmount(): void {
		if (modalRoot) {
			modalRoot.removeChild(this.modalRootElement)
		}
	}

	render(): React.ReactPortal {
		return ReactDOM.createPortal(this.props.children, this.modalRootElement)
	}
}
