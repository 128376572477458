import { APIRequest } from '@lynx/client-core/src/api'
import { DriveItemType } from '@lynx/client-core/src/api/interfaces'
import { BasicButton, Input, Modal } from '@lynx/client-core/src/components'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { extractResponseError } from '@lynx/core'
import i18next from 'i18next'
import QueryString from 'qs'
import React, { ChangeEvent, ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateDriveItem } from 'store/drive'
import { hideModal, ModalContext } from 'store/modal'

export const RenameModal = (): ReactElement | null => {
	const dispatch = useDispatch()
	const [itemname, setItemname] = useState<string>('')
	const [driveItem, setItem] = useState<DriveItemType | undefined>(undefined)
	const [errorMessages, setErrorMessages] = useState<{ message: string; type: 'error' }[]>([])
	const [isSaving, setIsSaving] = useState<boolean>(false)
	const validateFilename = (filename: string): boolean => {
		return filename.length > 0 && !/[\\/:*?#"<>|]/.test(filename)
	}

	const invalidErrMessage = { message: i18next.t('components.modals.renameModal.notAllowedCharacters') + ' " * / : # < > ? \\ |', type: 'error' as const }
	const validateForm = (filename: string): boolean => {
		if (!validateFilename(filename)) {
			setErrorMessages([invalidErrMessage])
			return false
		}

		setErrorMessages([])
		return true
	}

	const handleSubmit = async (): Promise<void> => {
		try {
			setErrorMessages([])
			setIsSaving(true)

			if (!driveItem || !itemname) {
				return
			}

			if (!validateForm(itemname)) {
				return
			}

			if (driveItem.name === itemname) {
				setIsSaving(false)
				dispatch(hideModal())
				return
			}

			const query = QueryString.parse(window.location.search.substring(1)) as { driveId?: string }
			const item = await APIRequest.ObjectStore.rename(driveItem, itemname, query.driveId)
			if (item) {
				const { id, webViewLink, name, thumbnail } = item
				dispatch(updateDriveItem({ id, thumbnail, webViewLink, name, mimeType: driveItem.mimeType }, driveItem.id))
			}

			dispatch(hideModal())
		} catch (err) {
			const validationSchemaErrorData = extractResponseError(err)
			setErrorMessages([{ message: validationSchemaErrorData?.message || i18next.t('components.drive.somethingWentWrong'), type: 'error' }])
		} finally {
			setIsSaving(false)
		}
	}

	const handleVisible = async (context?: ModalContext): Promise<void> => {
		if (context?.type !== 'RenameModalContextType') {
			return
		}

		const { driveItem } = context

		setItem(driveItem)
		setItemname(driveItem.name)
	}

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setItemname(event.target.value)
		validateForm(event.target.value)
	}

	return (
		<Modal name="RenameModal" onVisible={handleVisible} hideCloseButton>
			<ModalDefaultLayout
				title={i18next.t('components.modals.renameModal.title')}
				body={
					<React.Fragment>
						<Input
							variant="dark"
							label={i18next.t('components.modals.renameModal.name')}
							onChange={handleChange}
							value={itemname || ''}
							messages={errorMessages}
						/>
					</React.Fragment>
				}
				footer={
					<BasicButton onClick={handleSubmit} isLoading={isSaving} disabled={isSaving} variant="blue">
						{i18next.t('components.modals.renameModal.rename')}
					</BasicButton>
				}
			/>
		</Modal>
	)
}

export default RenameModal
