import { LoginModal, UpsellModal } from '@lynx/client-core/src/components'
import { CookieConsentModal } from 'components/CookieConsent/CookieConsentModal'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from './Header'
import css from './App.root.module.scss'
import { WSWatcher } from './WSWatcher/WSWatcher'
import { Language } from './Language'
import { NotificationToastContainer } from './Notifications'
import { Feedback } from './Feedback'
import { SessionEndWatch } from './SessionEndWatch'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { ProfilePictureModal } from './modals/ProfilePictureModal/ProfilePictureModal'
import { TermsListener } from './TermsListener/TermsListener'
import { AcceptTermsModal } from '@lynx/client-core/src/pages/Authenticated/modals/AcceptTermsModal/AcceptTermsModal'

export const Root = (): React.ReactElement | null => {
	const { feedback } = useSelector((state: ApplicationState) => state)

	return (
		<div className={css['container']}>
			<SessionEndWatch />
			<Header />
			<Outlet />
			<CookieConsentModal />
			<LoginModal />
			<WSWatcher />
			<Language />
			<NotificationToastContainer />
			<ProfilePictureModal />
			<UpsellModal />
			<TermsListener />
			<AcceptTermsModal />
			{feedback.isVisible && <Feedback />}
		</div>
	)
}
