import { APIRequest } from '@lynx/client-core/src/api'
import { GetOauthOrganisationData } from '@lynx/client-core/src/api/interfaces'
import { BasicButton, Input, Modal } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { ErrorMessage, Form, Formik } from 'formik'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { oauthOrganisationSchema } from 'pages/Organisations/validationSchemas'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { hideModal } from 'store/modal'
import css from './OAUTHConfigurationModal.module.scss'

export const OAUTHConfigurationModal = (): ReactElement => {
	const dispatch = useDispatch()
	const organisation = useCurrentOrganisation()
	const [oauthData, setOAUTHData] = useState<GetOauthOrganisationData['oauthDetails'] | null>(null)
	const { t } = useTranslation()

	const handleVisible = async (): Promise<void> => {
		if (!organisation?.id) {
			return
		}

		const oauthDetails = await APIRequest.Organisations.getOauthOrganisationDetails(organisation.id)
		if (!oauthDetails?.data) {
			Logger.error('no oauth data')
			return
		}

		setOAUTHData(oauthDetails.data.oauthDetails)
	}

	const handleFormSubmitted = async ({
		authorizationUrl,
		tokenUrl,
		userUrl,
		clientId,
		clientSecret
	}: {
		authorizationUrl: string
		tokenUrl: string
		userUrl: string
		clientId: string
		clientSecret: string
	}): Promise<void> => {
		if (!organisation) return
		await APIRequest.Organisations.updateOauthSettings(organisation.id, authorizationUrl, tokenUrl, userUrl, clientId, clientSecret)
		dispatch(hideModal())
	}

	return (
		<Modal name="OAUTHConfigurationModal" title={'Update OAUTH Settings'} onVisible={handleVisible}>
			<Formik
				initialValues={{
					authorizationUrl: oauthData?.authorizationUrl || '',
					tokenUrl: oauthData?.tokenUrl || '',
					userUrl: oauthData?.userUrl || '',
					clientId: oauthData?.clientId || '',
					clientSecret: oauthData?.clientSecret || ''
				}}
				validationSchema={oauthOrganisationSchema}
				onSubmit={(values, actions): void => {
					handleFormSubmitted(values)
					actions.setSubmitting(false)
				}}
				enableReinitialize={true}
			>
				{({ isSubmitting, handleChange, values, errors, touched, isValid, dirty }): React.ReactNode => (
					<Form className={css['container']}>
						<div>
							<label htmlFor="authorizationUrl">Authorization Url</label>
							<Input
								autoFocus={true}
								value={values.authorizationUrl}
								type="search"
								name="authorizationUrl"
								onChange={handleChange}
								inError={(errors.authorizationUrl && touched.authorizationUrl) || false}
								placeholder="https://..."
							/>
							<ErrorMessage name="authorizationUrl" component="span" />
						</div>
						<div>
							<label htmlFor="tokenUrl">Token Url</label>
							<Input
								value={values.tokenUrl}
								type="search"
								name="tokenUrl"
								onChange={handleChange}
								inError={(errors.tokenUrl && touched.tokenUrl) || false}
								placeholder="https://..."
							/>
							<ErrorMessage name="tokenUrl" component="span" />
						</div>
						<div>
							<label htmlFor="userUrl">User Url</label>
							<Input
								value={values.userUrl}
								type="search"
								name="userUrl"
								onChange={handleChange}
								inError={(errors.userUrl && touched.userUrl) || false}
								placeholder="https://..."
							/>
							<ErrorMessage name="userUrl" component="span" />
						</div>
						<div>
							<label htmlFor="clientId">Client Id</label>
							<Input
								value={values.clientId}
								type="search"
								name="clientId"
								onChange={handleChange}
								inError={(errors.clientId && touched.clientId) || false}
							/>
							<ErrorMessage name="clientId" component="span" />
						</div>
						<div>
							<label htmlFor="clientSecret">Client Secret</label>
							<Input
								value={values.clientSecret}
								type="password"
								name="clientSecret"
								onChange={handleChange}
								inError={(errors.clientSecret && touched.clientSecret) || false}
							/>
							<ErrorMessage name="clientSecret" component="span" />
						</div>
						<div className={css['buttons']}>
							<BasicButton
								onClick={(): void => {
									dispatch(hideModal())
								}}
							>
								{t('pages.organisations.createModal.back')}
							</BasicButton>
							<BasicButton variant={isValid ? 'blue' : 'transparent'} disabled={isSubmitting || !dirty} type="submit">
								{t('pages.organisations.save')}
							</BasicButton>
						</div>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}
