import { SkeletonBone } from '@lynx/client-core/src/components'
import * as React from 'react'
import css from './DriveItemTemplate.module.scss'

export const DriveItemTemplateLoadingSkeleton = (): React.ReactElement => {
	return (
		<div className={css.driveItem}>
			<div className={css.contentContainer}>
				<SkeletonBone className={css.loadingImg} />
				<SkeletonBone className={css.loadingText} />
			</div>
		</div>
	)
}
