import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ApplicationState } from 'store'
import { useNavigate } from 'react-router'
import { setLoginModal } from 'store/profile'

export const SignInSection = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const isLoggedIn = useSelector((state: ApplicationState) => state.profile.loggedIn)
	const navigate = useNavigate()

	useEffect(() => {
		if (isLoggedIn) navigate('/')
		else dispatch(setLoginModal(true, true))
	}, [isLoggedIn])

	return null
}
