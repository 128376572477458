import { MessagingState } from './types'

export const initialMessagingState: MessagingState = {
	connected: false,
	channel: { id: '', paired: false },
	lastMessage: {
		type: '',
		channelId: '',
		payload: {},
		datetime: 0
	}
}
