import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Card, Document } from '@lynx/client-core/src/components'
import { ProCloudSrc, ProHandwritingSrc, ProMediaBigSrc, ProQuizSrc, ProSpinnerSrc } from '@lynx/client-core/src/components/Illustrations'
import { Logger } from '@lynx/client-core/src/modules'
import { showModal } from '@lynx/client-core/src/store/modal'
import { useBillingDetails } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import css from './CancelMembership.module.scss'
import { StorageLimitWarningModal } from './modals/StorageLimitWarningModal'
import { useBillingPlans } from '@lynx/client-core/src/hooks'

export const CancelMembership = (): React.ReactElement | null => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { billingDetails } = useBillingDetails()
	const { billingPlans } = useBillingPlans()
	const { t } = useTranslation()

	const handleBackClicked = (): void => {
		navigate(-1)
	}

	const handleFinishCancellationClicked = async (): Promise<void> => {
		try {
			if (!billingPlans) {
				return
			}
			const { usedBytes } = await APIRequest.ObjectStore.getDriveSize('lynxcloud')
			if ((usedBytes || 0) > billingPlans['free'].storageLimitGB * 1024 * 1024 * 1024) {
				dispatch(showModal({ name: 'StorageLimitWarningModal' }))
				return
			}

			const response = await APIRequest.Billing.getManageSubscriptionPortalURL()
			window.location.href = response.url
		} catch (e) {
			Logger.error(e)
		}
	}

	const getFormattedPeriodEnd = (): string => {
		if (!billingDetails) {
			return ''
		}
		return new Date(billingDetails.current_period_end * 1000).toLocaleDateString()
	}

	if (!billingPlans) {
		return null
	}

	return (
		<>
			<Document title={'Cancel Membership'} description={'Cancel Membership'} keywords={'cancel'} className={css['document']}>
				<Card className={css['container']}>
					<h3>{t('pages.billing.cancelMembership')}</h3>
					<p>{t('pages.billing.loseBenefits')} </p>
					<div className={css['grid']}>
						<div>
							<img src={ProCloudSrc} alt={`${billingPlans['pro'].storageLimitGB}GB LYNX Cloud storage`} />
							<div>{billingPlans['pro'].storageLimitGB}GB LYNX Cloud storage</div>
						</div>
						<div>
							<img src={ProHandwritingSrc} alt={'Handwriting recognition pens'} />
							<div>{t('pages.billing.handwritingPens')}</div>
						</div>
						<div>
							<img src={ProSpinnerSrc} alt={'AI Spinner'} />
							<div>AI {t('pages.billing.spinnerWord')}</div>
						</div>
						<div>
							<img src={ProQuizSrc} alt={'AI Quiz'} />
							<div>AI {t('pages.billing.quizQuestion')}</div>
						</div>
						<div>
							<img src={ProMediaBigSrc} alt={'Additional media search providers'} />
							<div>{t('pages.billing.additionalMedia')}</div>
						</div>
					</div>
					<p>
						{t('pages.billing.ifYouCancel')}
						<b> {getFormattedPeriodEnd()}</b>. {t('pages.billing.willStillBeAble')}
					</p>

					<p>{t('pages.billing.areYouSureCancel')}</p>
					<div className={css['buttons']}>
						<BasicButton variant="light" onClick={handleBackClicked}>
							{t('pages.billing.back')}
						</BasicButton>
						<BasicButton variant="danger" onClick={handleFinishCancellationClicked}>
							{t('pages.billing.proceed')}
						</BasicButton>
					</div>
				</Card>
			</Document>
			<StorageLimitWarningModal />
		</>
	)
}
