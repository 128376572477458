import { Button, Modal } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideModal, InformationModalContextType, ModalContext } from 'store/modal'
import Styles from './InformationModal.module.css'

export const InformationModal = (): React.ReactElement => {
	const [state, setState] = useState({
		title: '',
		message: ''
	})
	const dispatch = useDispatch()

	const handleVisible = async (context?: ModalContext): Promise<void> => {
		if (!context) {
			return
		}

		if (context.type !== InformationModalContextType) {
			return
		}

		const { title, message } = context

		setState({
			title,
			message
		})
	}

	const handleCloseClicked = (): void => {
		dispatch(hideModal())
	}

	const { title, message } = state
	return (
		<Modal name="InformationModal" onVisible={handleVisible} title={title}>
			<div className={Styles['message']}>{message}</div>
			<div className={Styles['button-container']}>
				<Button onClick={handleCloseClicked}>{i18next.t('components.modals.informationModal.close')}</Button>
			</div>
		</Modal>
	)
}
