import i18next from 'i18next'
import * as React from 'react'
import FileInput from './FileInput'
import { FilePickerProps } from './interfaces/FilePickerProps'

export const FilePicker = ({ children, style, disabled, onError, onChange, maxSize, extensions, accept, multiple }: FilePickerProps): React.ReactElement => {
	const validate = (file: File, numberOfFiles: number): void => {
		if (disabled) {
			return
		}

		maxSize = maxSize || 500

		// make sure a file was provided in the first place
		if (!file && onError) {
			onError(i18next.t('components.filePicker.failedToUpload'))
			return
		}

		// if we care about file extensions
		if (extensions) {
			const fileNameSplit = file.name.split('.')
			const ext = fileNameSplit?.pop()?.toLowerCase() || ''
			const uploadedFileExt = ext
			const isValidFileExt = extensions.map((ext: string) => ext.toLowerCase()).includes(uploadedFileExt)

			if (!isValidFileExt && onError) {
				onError(`Must upload a file of type: ${extensions.join(' or ')}`)
				return
			}
		}

		// convert maxSize from megabytes to bytes
		const maxBytes = maxSize * 1000000

		if (file?.size > maxBytes && onError) {
			onError(`File size must be less than ${maxSize} MB.`)
			return
		}

		// return native file object
		if (onChange) {
			onChange(file, numberOfFiles)
		}
	}

	return (
		<FileInput onChange={validate} style={style || {}} disabled={disabled} accept={accept} multiple={multiple}>
			{children}
		</FileInput>
	)
}

export default FilePicker
