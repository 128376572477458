import { DriveItemType, DriveItemShare } from '@lynx/client-core/src/api/interfaces'

export const LOAD_SHARE = '@@sharing/LOAD_SHARE'
export const UNLOAD_SHARES = '@@sharing/UNLOAD_SHARES'
export const LOAD_CODE_URL = '@@sharing/LOAD_CODE_URL'

export interface SharingState {
	driveItem: DriveItemType | null
	share: DriveItemShare | null
	codeUrl: string
}

interface LoadShareAction {
	type: typeof LOAD_SHARE
	payload: {
		driveItem: DriveItemType
		share: DriveItemShare
	}
}

interface LoadCodeUrlAction {
	type: typeof LOAD_CODE_URL
	payload: {
		codeUrl: string
	}
}

interface UnloadSharesAction {
	type: typeof UNLOAD_SHARES
}

export type SharingActionTypes = LoadShareAction | UnloadSharesAction | LoadCodeUrlAction
