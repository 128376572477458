import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { Card } from 'components/elmstone/Card'
import i18next from 'i18next'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'

interface MailingListState {
	columns: string[]
	options: any
	title: string
	auditRecords: any[]
	dateDownload: string
}

export const MailingList = (): React.ReactElement => {
	const [state, setState] = useState<MailingListState>({
		auditRecords: [],
		columns: ['displayName', 'logDate', 'ipAddress', 'recordCount'],
		dateDownload: '',
		options: {
			responsive: 'standard',
			selectableRows: 'none'
		},
		title: ''
	})

	useEffect(() => {
		const mount = (): void => {
			getData()
		}

		mount()
	}, [])

	const onFormSubmit = (event: FormEvent): void => {
		event.preventDefault()
		APIRequest.Pages.postMailingList(state.dateDownload)
			.then((data: any) => {
				const url = window.URL.createObjectURL(new Blob([data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'mailing-list.xlsx')
				document.body.appendChild(link)
				link.click()
				getData()
			})
			.catch((err: any) => {
				Logger.error(err)
			})
	}

	const onDateChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setState({ ...state, dateDownload: event.target.value })
	}

	const getData = (): void => {
		APIRequest.Pages.getMailingList()
			.then((data) => {
				setState({ ...state, auditRecords: data.auditRecords })
			})
			.catch((err) => {
				Logger.error(err)
			})
	}

	return (
		<Document title="Mailing List" description="Mailing List" keywords="Mailing List" auth={true} disableOverflow={true}>
			<Card>
				<h1 className="panel-header text-center">{i18next.t('pages.mailingList.mailingList')}</h1>
				<div className="card-datatable">
					<form action="/api-v2/mailing-list" onSubmit={onFormSubmit} method="POST">
						<div className="input-group">
							<span className="input-group-addon">
								<i className="ti-calendar" />
							</span>
							<input id="createdDate" name="createdDate" type="text" placeholder="Created Date" onChange={onDateChange} />
							<button type="submit" className="input-group-addon btn btn-primary">
								{i18next.t('pages.mailingList.download')}
							</button>
						</div>
					</form>
					<Table title={'Mailing'} items={state.auditRecords} headers={state.columns.map((v) => ({ label: v, valueKey: v }))} />
				</div>
			</Card>
		</Document>
	)
}
