import { ClientProviders, DriveItemType } from '@lynx/client-core/src/api/interfaces'
import {
	DriveItemDefaultThumbnail,
	DropboxLogoIcon,
	Dropdown,
	FolderCloudIcon,
	FolderIcon,
	GoogleDriveLogoIcon,
	LynxWhiteboardLogo,
	MoreVertIcon,
	NextCloudLogoIcon,
	OnedriveLogoIcon,
	OrganisationsIcon,
	SharePointIcon
} from '@lynx/client-core/src/components'
import css from '@lynx/client-core/src/components/Drive/DriveItem.module.scss'
import { Logger } from '@lynx/client-core/src/modules'
import { Providers } from '@lynx/core/src/interfaces/ObjectStore'
import { useThunkDispatch } from 'hooks'
import { DriveDropdownItems } from 'pages/Drives/DriveDropdownItems'
import { DriveItemTemplate } from 'pages/Drives/DriveItemTemplate'
import { DriveItemActionType } from 'pages/Drives/DriveList/Drive.types'
import React, { ForwardedRef, forwardRef } from 'react'
import { pushDrivePath } from 'store/drive'

interface DriveItemProps {
	item: DriveItemType
	handleItemClick: (action: DriveItemActionType, item: DriveItemType) => void
	onFavouriteClick?: (item: DriveItemType) => Promise<void>
	isDisplayProviderLogo?: boolean
}

export const DriveItem = forwardRef(
	({ item, handleItemClick, isDisplayProviderLogo }: DriveItemProps, ref: ForwardedRef<HTMLDivElement> | undefined): React.ReactElement | null => {
		const { id, size, name, provider, additionalData } = item

		const dispatchThunk = useThunkDispatch()

		const hideDropdown = !item.capabilities || Object.values(item.capabilities).every((c) => !c)
		const dropdown = hideDropdown ? null : (
			<Dropdown openedClassName={css.dropdown} title={<MoreVertIcon />}>
				<DriveDropdownItems driveItem={item} handleItemClick={handleItemClick} classes={css.dropdownItem} />
			</Dropdown>
		)
		const onFolderClicked = async (): Promise<void> => {
			try {
				await dispatchThunk(pushDrivePath(provider, id, additionalData))
			} catch (e) {
				Logger.error(e)
			}
		}

		const defaultProps = {
			ref,
			dropdown,
			size,
			text: name,
			subText: '',
			isShortcut: Boolean(item.shortcutDetails)
		}
		switch (item.mimeType) {
			case 'application/folder': {
				const thumbnail = id === 'sharedwithme' ? <FolderCloudIcon className={css.thumbnailIcon} /> : <FolderIcon className={css.thumbnailIcon} />
				return <DriveItemTemplate {...defaultProps} thumbnail={thumbnail} handleItemClick={onFolderClicked} />
			}
			default: {
				return (
					<DriveItemTemplate
						{...defaultProps}
						fileProviderLogo={isDisplayProviderLogo ? getProviderLogo(provider) : undefined}
						size={size}
						thumbnail={<DriveItemDefaultThumbnail item={item} />}
						handleItemClick={(): void => {
							item.capabilities?.canOpen && handleItemClick('open', item)
						}}
					/>
				)
			}
		}
	}
)

const getProviderLogo = (provider: ClientProviders | 'onedriveSharepoint'): JSX.Element => {
	const className = css[`recentFilesLogo-${provider}`]
	const providerLogoMapping: { [x in Providers | 'onedriveSharepoint']: JSX.Element } = {
		google: <GoogleDriveLogoIcon className={className} />,
		onedrive: <OnedriveLogoIcon className={className} />,
		lynxcloud: <LynxWhiteboardLogo className={className} />,
		organisation: <OrganisationsIcon className={className} />,
		dropbox: <DropboxLogoIcon className={className} />,
		nextcloud: <NextCloudLogoIcon className={className} />,
		onedriveSharepoint: <SharePointIcon className={className} />
	}
	return providerLogoMapping[provider as keyof typeof providerLogoMapping]
}
