import { APIRequest } from '@lynx/client-core/src/api'
import { PersonAddIcon, Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { showModal } from '@lynx/client-core/src/store/modal'
import { OrganisationInvitesInterface } from '@lynx/core/src/interfaces/Organisation'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { removeInvite } from 'store/organisations'
import { EditInviteEntryOverlay } from './EditInviteEntryOverlay'
import css from './InvitesTable.module.scss'
import { useTranslation } from 'react-i18next'

export const InvitesTable = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const organisation = useCurrentOrganisation()
	if (!organisation) return null

	const handleRemoveUserClicked = async (item: OrganisationInvitesInterface): Promise<void> => {
		if (!item.id) return
		await dispatchThunk(removeInvite(organisation.id, String(item.id)))
	}

	const handleResendInviteClicked = async ({ email, groupId }: OrganisationInvitesInterface): Promise<void> => {
		await APIRequest.Organisations.inviteOrganisationUser(organisation.id, email, groupId)
		dispatch(showModal({ name: 'InviteSentModal' }))
	}

	const handleInviteClicked = (): void => {
		dispatch(showModal({ name: 'InvitePeopleModal' }))
	}

	const columns: TableHeaders = [
		{
			valueKey: 'email',
			label: 'EMAIL',
			cellAdditionalStyles: { alignItems: 'center' }
		},
		{
			valueKey: '__edit',
			label: '',
			customBodyRender: (item: OrganisationInvitesInterface) => (
				<div className={css['optionsContainer']}>
					<EditInviteEntryOverlay>
						<ul>
							<li onClick={(): Promise<void> => handleRemoveUserClicked(item)}>{t('pages.organisations.remove')}</li>
							<li onClick={(): Promise<void> => handleResendInviteClicked(item)}>{t('pages.organisations.resendInvite')}</li>
						</ul>
					</EditInviteEntryOverlay>
				</div>
			)
		}
	]

	const items = organisation.invites

	return (
		<div>
			<Table
				headers={columns}
				items={items}
				toolBarItems={[
					{
						component: (
							<div onClick={handleInviteClicked}>
								<PersonAddIcon />
								<div>{t('pages.organisations.inviteUser')}</div>
							</div>
						)
					}
				]}
			/>
		</div>
	)
}
