import { Card, Document } from '@lynx/client-core/src/components'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setLoginModal } from 'store/profile'
import css from './Disabled.module.scss'
import { useTranslation } from 'react-i18next'

export const Disabled = (): React.ReactElement => {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const reason = queryParams.get('reason') || 'Not provided'
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const decodedReason = reason ? atob(reason) : 'Not provided'

	useEffect(() => {
		dispatch(setLoginModal(false))
	}, [])

	return (
		<Document title="Disabled" className={css['container']}>
			<Card className={css['card']}>
				<h2>{t('pages.disabled.accountDisabled')}</h2>
				<div>{t('pages.disabled.signInNotPossible')}</div>
				<div>{t('pages.disabled.contactAdministrator')}</div>
				<div>{t('pages.disabled.reason')}</div>
				{reason && <div>{decodedReason}</div>}
			</Card>
		</Document>
	)
}
