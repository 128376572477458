import { Logger } from '../../modules/Logger'
import { BaseCall } from '../BaseCall'
import { APIConfig } from '../interfaces'
import { UserSessionsInterface } from '@lynx/core/src/interfaces/UserSessions'

export class UserSessions extends BaseCall {
	public getUserSessions = async (): Promise<UserSessionsInterface[]> => {
		try {
			const res = await this.client.get('/user-sessions')
			return res.data.userSessions
		} catch (e) {
			Logger.error(e)
			return []
		}
	}

	public getCurrentSessionRef = async (): Promise<string> => {
		const res = await this.client.get('/user-sessions/session-ref')
		return res.data.tokenRefId
	}

	public invalidateSession = async (id: UserSessionsInterface['id']): Promise<void> => {
		await this.client.patch(`/user-sessions/invalidate-session/${id}`)
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
