import { Modal } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React from 'react'
import Styles from './SavingModal.module.css'

export const SavingModal = (): React.ReactElement => (
	<Modal name="SavingModal" hideCloseButton={true} title={i18next.t('pages.drive.modals.savingmodal.saving')}>
		<div className={Styles['container']}>
			<h2>{i18next.t('pages.drive.modals.savingmodal.pleasewait')}</h2>
		</div>
	</Modal>
)
