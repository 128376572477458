import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { useDebounce } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { SubscriptionTableMonthlyData } from '@lynx/core/src/interfaces'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import css from './MyScriptReport.module.scss'

interface MyScriptState {
	data: { users: SubscriptionTableMonthlyData[]; organisations: SubscriptionTableMonthlyData[] }
	searchText: string
	page: number
	rowsPerPage: number
}

const columns: TableHeaders = [
	{
		valueKey: 'year',
		label: 'Year'
	},
	{
		valueKey: 'month',
		label: 'Month'
	},
	{
		valueKey: 'new',
		label: 'New yearly end-user subscriptions'
	},
	{
		valueKey: 'renewed',
		label: 'Renewed yearly end-user subscriptions'
	},
	{
		valueKey: 'cancelled',
		label: 'Cancelled yearly subscriptions'
	},
	{
		valueKey: 'trialChurned',
		label: 'Churned Trials'
	},
	{
		valueKey: 'trialled',
		label: 'Trialling Users'
	},
	{
		valueKey: 'total',
		label: 'Total number of yearly end-user subscriptions'
	}
]

export const MyScriptReport = (): React.ReactElement => {
	const [state, setState] = useState<MyScriptState>({
		data: { users: [], organisations: [] },
		searchText: '',
		page: 0,
		rowsPerPage: 30
	})

	const { searchText, rowsPerPage, data } = state

	useEffect(() => {
		getReport(1, '', rowsPerPage)
	}, [])

	const changePage = (page: number): void => {
		getReport(page, searchText, false, rowsPerPage)
	}

	const changeSearchText = (text: string): void => {
		setState({ ...state, searchText: text })
		getReport(1, text, false, rowsPerPage)
	}

	const getReport = useDebounce(async (pageNumber: number, searchValue: string, clearSearch = false, perPage = rowsPerPage) => {
		try {
			const responseData = await APIRequest.Billing.getReport({
				start: pageNumber,
				length: perPage,
				searchText: clearSearch ? '' : searchValue
			})

			const data = responseData.data
			if (!data) return

			setState({
				...state,
				page: pageNumber,
				data
			})
		} catch (err) {
			Logger.error(err)
		}
	}, 300)

	return (
		<Document title="MyScript Report" keywords="MyScript" auth={true} disableOverflow={true}>
			<Card className={css['container']}>
				<Table
					pageSizes={[rowsPerPage]}
					onPageChange={changePage}
					totalCount={data.users.length}
					onTableSearchChange={changeSearchText}
					title={'MyScript Report - Users'}
					headers={columns}
					items={data.users}
					initialSortHeaderKey="year"
					isCustomSearchRender={true}
				/>
				<Table
					pageSizes={[rowsPerPage]}
					onPageChange={changePage}
					totalCount={data.organisations.length}
					onTableSearchChange={changeSearchText}
					title={'MyScript Report - Organisations'}
					headers={columns}
					items={data.organisations}
					initialSortHeaderKey="year"
					isCustomSearchRender={true}
				/>
			</Card>
		</Document>
	)
}
