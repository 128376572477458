import qs from 'query-string'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { ApplicationState } from 'store'
import Styles from './ActivitiesGraphic.module.css'

export const ActivitiesGraphic = (): React.ReactElement => {
	const { config: configState } = useSelector((state: ApplicationState) => state)
	const location = useLocation()

	const query = qs.parse(location.search)
	let queryString = qs.stringify(query)
	queryString = queryString ? `?${queryString}` : ''

	return (
		<div
			className={Styles['container']}
			onClick={(): void => {
				window.location.href = configState.LYNXACTIVITIES_CLIENT + queryString
			}}
		>
			<div className={Styles['activities']}>
				<img alt="activities" src={'/assets/images/search.png'} />
			</div>
		</div>
	)
}
