import { BasicButton, Input, Textarea } from '@lynx/client-core/src/components'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SamlSetupProps } from './interfaces'
import css from './SamlSetup.module.css'

export const SamlSetup = (props: SamlSetupProps): React.ReactElement => {
	const { t } = useTranslation()

	let samlEntryStr = ''
	let samlIssuerStr = ''
	let certificateStr = ''
	const isUCS = !!props.context?.isUCS

	if (props.context?.saml) {
		const { saml } = props.context
		const { entrypoint, issuer, certificate } = saml
		samlEntryStr = entrypoint
		samlIssuerStr = issuer
		certificateStr = certificate
	}

	const [entrypoint, setEntrypoint] = useState<string>(samlEntryStr || '')
	const [issuer, setIssuer] = useState<string>(samlIssuerStr || '')
	const [cert, setCert] = useState<string>(certificateStr || '')

	const onSamlEntrypointChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		setEntrypoint(event.target.value)
	}

	const onSamlIssuerChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		setIssuer(event.target.value)
	}

	const onSamlCertChange = async (event: ChangeEvent<HTMLTextAreaElement>): Promise<void> => {
		setCert(event.target.value)
	}

	const handleNext = (): void => {
		props.navigate('SamlDomain', {
			saml: {
				entrypoint,
				certificate: cert,
				issuer
			},
			isUCS
		})
	}

	return (
		<div className={css['container']}>
			<p>{t('pages.organisation.samlEntrypoint')}</p>
			<Input autoFocus value={entrypoint} onChange={onSamlEntrypointChange} />
			<p>{t('pages.organisation.samlIssuer')}</p>
			<Input value={issuer} onChange={onSamlIssuerChange} />
			<p>{t('pages.organisation.samlCert')}</p>
			<Textarea value={cert} onChange={onSamlCertChange} />
			<div className={css['buttons']}>
				<BasicButton
					onClick={(): void => {
						props.navigate('SamlIntroduction', null)
					}}
				>
					{t('pages.organisations.createModal.back')}
				</BasicButton>
				<BasicButton variant="blue" disabled={!issuer || !cert || !entrypoint} onClick={handleNext}>
					{t('pages.organisations.createModal.next')}
				</BasicButton>
			</div>
		</div>
	)
}
