import { showModal } from '@lynx/client-core/src/store/modal'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'

export const TermsListener = (): React.ReactElement | null => {
	const { loggedIn, isTermsAccepted } = useSelector((state: ApplicationState) => state.profile)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!isTermsAccepted && loggedIn) {
			dispatch(showModal({ name: 'AcceptTermsModal', sticky: true }))
		}
	}, [loggedIn])

	return null
}
