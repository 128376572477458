import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, ListItem } from '@lynx/client-core/src/components'
import React, { useEffect, useState } from 'react'
import css from './Devices.module.scss'
import { useTranslation } from 'react-i18next'

export const Devices = (): React.ReactElement => {
	const [sessionCount, setSessionCount] = useState<number>(0)
	const { t } = useTranslation()

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const userSessionsData = await APIRequest.UserSessions.getUserSessions()

			setSessionCount(userSessionsData?.filter((s) => s.tokenRefId).length || 0)
		}

		mount()
	}, [])

	return (
		<ListItem header={t('pages.profile.devices')}>
			<div className={css['container']}>
				<div>
					{t('pages.profile.signedInOn')} {sessionCount} {t('pages.profile.devices')}
				</div>
				<BasicButton to="/device-activity" variant="light">
					{t('pages.profile.viewDeviceActivity')}
				</BasicButton>
			</div>
		</ListItem>
	)
}
