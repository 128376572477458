export const SCHEMA_VALIDATION_FAILED = 'SCHEMA_VALIDATION_FAILED'
export const UNAUTHORIZED = 'UNAUTHORIZED'
export const DRIVE_SIZE_LIMIT_EXCEEDED = 'DRIVE_SIZE_LIMIT_EXCEEDED'
export const REQUEST_LIMIT_MAX = 'REQUEST_LIMIT_MAX'
export const STRIPE_ERROR = 'STRIPE_ERROR'
export interface SingleSchemaError {
	msg: string
	location: 'body' | 'query' | 'params'
	param: string // field key
}
export interface SchemaValidationError extends DefaultErrorResponseType<typeof SCHEMA_VALIDATION_FAILED> {
	options: {
		errors: SingleSchemaError[]
	}
}

export interface UnauthorizedOptions extends DefaultErrorResponseType<typeof UNAUTHORIZED> {
	options?: {
		authenticationRequired: boolean
		provider: 'onedrive'
	}
}

export interface StripeErrorCreateSubscriptionOptions extends DefaultErrorResponseType<typeof STRIPE_ERROR> {
	options: {
		stripeErrorMessage: string
	}
}
export interface DefaultErrorResponseType<N = string> {
	name: N
	message: string
}

type DefaultError = DefaultErrorResponseType<typeof DRIVE_SIZE_LIMIT_EXCEEDED | typeof REQUEST_LIMIT_MAX>

export type ErrorResponseType = SchemaValidationError | UnauthorizedOptions | StripeErrorCreateSubscriptionOptions | DefaultError
