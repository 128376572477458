import { Document } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React from 'react'
import { TermsCard } from '@lynx/client-core/src/pages/Authenticated/modals/AcceptTermsModal/TermsCard'
import css from './Terms.module.scss'

export const Terms = (): React.ReactElement => {
	return (
		<Document
			title={i18next.t('pages.pageTitles.termsAndConditions')}
			description="Terms and Conditions for using the LYNX website and LYNX software"
			keywords="Terms, Conditions, LYNX"
			className={css['container']}
		>
			<TermsCard />
		</Document>
	)
}
