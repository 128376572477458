import { Modal } from '@lynx/client-core/src/components'
import { SectionNavigator } from 'components/Sections'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ModalContext, SharingModalContextType } from 'store/modal'
import { loadShare, unloadShares } from 'store/sharing'
import { JoinSection, ManagePeopleSection, ShareSection } from './sections'
import css from './SharingModal.module.css'

const SectionNavigatorConfig = {
	ShareSection: ShareSection,
	ManagePeopleSection: ManagePeopleSection
}

export const SharingModal = (): React.ReactElement => {
	const dispatchThunk = useThunkDispatch()
	const dispatch = useDispatch()

	const handleVisible = async (context?: ModalContext): Promise<void> => {
		if (context?.type !== SharingModalContextType) {
			return
		}

		// Get driveitem from context
		await dispatchThunk(loadShare(context.driveItem))
	}

	const handleHidden = (): void => {
		dispatch(unloadShares())
	}

	return (
		<Modal name="SharingModal" className={css['modal']} variant="purple" onHidden={handleHidden} onVisible={handleVisible}>
			<div className={css['container']}>
				<JoinSection />
				<SectionNavigator config={SectionNavigatorConfig} section={'ShareSection'} />
			</div>
		</Modal>
	)
}
