/* eslint-disable */
import { AxiosResponse } from 'axios'
import { BaseCall } from '../BaseCall'
import { APIConfig, Channel, CreateChannelResponse } from '../interfaces'

export interface ChannelType {
	name: string // reference name used when creating a channel
	maxClients: number // maximum number of clients allowed in this channel. 0 for any
	supportedMessageTypes: string[] // supported messages allowed
	singleUser: boolean // only supports a single user, albeit with multiple clients
	expireInSeconds?: number // time till expire after channel is created
	rollingExpire?: boolean // whether expiry time is reset to default whenever channel is used (subscribed, unsubscribed, messaged)
	hidden: boolean // channel is hidden from public view
	storeMessages: boolean // channel keeps a log of each message sent
	channelEvents?: boolean // whether channel:join, channel:leave and channel:delete messages are sent
	tag: string // group tag that the type belongs to
}

export interface ChannelConfig {
	type: ChannelType // Channel type id
	name: string // name of Channel
	owner: string // owner (this is also the creator, Channel ownership can not be transferred)
	createdDate: number // date of creation from epoch
	expiresDate: number // date of expiry from epoch
}

export class Channels extends BaseCall {
	public owned = async (): Promise<any> => {
		try {
			const res = await this.client.get('/channels/owned')
			return res.data
		} catch (e) {
			console.error(e)
		}
	}

	public create = async (channelType: string, name: string, id?: string | null): Promise<CreateChannelResponse | undefined> => {
		try {
			const endpoint = id ? `/channels/create/${id}` : '/channels/create'
			const res: AxiosResponse<CreateChannelResponse> = await this.client.post(endpoint, {
				channelType,
				name,
				subscribe: true
			})

			if (res.status !== 200) {
				console.error(res.statusText)
			}

			return res.data
		} catch (e) {
			console.error(e)
		}
	}

	public delete = async (channelId: string): Promise<AxiosResponse> => {
		try {
			const res = await this.client.delete(`/channels/delete/${channelId}`)

			return res
		} catch (e) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			throw Error(e)
		}
	}

	public async messages(channelId: string) {
		try {
			const res = await this.client.get(`/channels/messages/${channelId}`)

			if (res.status !== 200) {
				console.error(res.statusText)
			}

			return res.data
		} catch (e) {
			console.error(e)
		}
	}

	public async changeDisplayName(displayName: string): Promise<AxiosResponse | void> {
		try {
			return await this.client.post(`/channels/rename/${displayName}`)
		} catch (e) {
			console.error(e)
		}
	}

	public async sendMessage(channelId: string, type: string, data: unknown, targetUserId?: string): Promise<AxiosResponse> {
		try {
			const res = await this.client.post(`/channels/sendMessage`, {
				channelId,
				payload: data,
				type,
				targetId: targetUserId
			})

			return res
		} catch (e) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			throw Error(e)
		}
	}

	public subscribe = async (channelId: string): Promise<boolean> => {
		try {
			const res = await this.client.post(`/channels/subscribe/${channelId}`, {})

			return res.status === 200
		} catch (e) {
			return false
		}
	}

	public subscribed = async (): Promise<Channel[] | undefined> => {
		try {
			const res = await this.client.get('/channels/subscribed')
			return res.data
		} catch (e) {
			console.error(e)
			// @ts-ignore
			console.error(e.response)
		}
	}

	public unsubscribe = async (channelId: string): Promise<boolean> => {
		try {
			const res = await this.client.post(`/channels/unsubscribe/${channelId}`, {})
			return res.status === 200
		} catch (e) {
			console.error(e)
			// @ts-ignore
			console.error(e.response)
			return false
		}
	}

	public exists = async (channelId: string): Promise<boolean> => {
		try {
			const res = await this.client.get(`/channels/exists/${channelId}`, {})
			return !!res.data.exists
		} catch (e) {
			console.error(e)
			// @ts-ignore
			console.error(e.response)
			return false
		}
	}

	public subscribers = async (channelId: string): Promise<{ userId: string; displayName: string; isConnected: boolean }[]> => {
		try {
			const res = await this.client.get(`/channels/subscribers/${channelId}`, {})
			return res?.data || []
		} catch (e) {
			console.error(e)
			return []
		}
	}

	public codeInfo = async (code: string): Promise<{ exists: boolean; secret: boolean } | null> => {
		try {
			const res = await this.client.get<{ exists: boolean; secret: boolean }>(`/channels/code/${code}`)

			if (res.status !== 200) {
				return null
			}

			return res.data
		} catch (e) {
			console.error(e)
			return null
		}
	}

	// @ts-ignore
	public generateCode = async (channelId: string, secret = ''): Promise<string> => {
		try {
			const res = await this.client.post(`/channels/generateCode/${channelId}`, { secret })
			return res.data.code
		} catch (e) {
			console.error(e)
			// @ts-ignore
			console.error(e.response)
		}
	}
	// @ts-ignore
	public async get(channelId): Promise<Channel | null> {
		try {
			const res = await this.client.get<Channel>(`/channels/${channelId}`)
			if (res.status !== 200) {
				console.error(res.status)
				console.error(res.statusText)
				// @ts-ignore
				return null
			}

			return res.data
		} catch (e) {
			console.error(e)
			return null
		}
	}

	public async getAttachment(channelId: string): Promise<string | null> {
		try {
			const res = await this.client.get<{ attachment: string }>(`/channels/attachment/${channelId}`)
			if (res.status !== 200) {
				console.error(res.status)
				console.error(res.statusText)
				return null
			}

			return res.data.attachment
		} catch (e) {
			console.error(e)
			return null
		}
	}

	public async clearAttachment(channelId: string): Promise<boolean> {
		try {
			const res = await this.client.delete(`/channels/attachment/${channelId}`)

			if (res.status !== 200) {
				console.error(res.status)
				console.error(res.statusText)
				return false
			}

			return true
		} catch (e) {
			console.error(e)
			return false
		}
	}

	public async updateAttachment(channelId: string, attachment: string): Promise<boolean> {
		try {
			const res = await this.client.put(`/channels/attachment/${channelId}`, {
				attachment
			})

			if (res.status !== 200) {
				console.error(res.status)
				console.error(res.statusText)
				return false
			}

			return true
		} catch (e) {
			console.error(e)
			return false
		}
	}

	public redeemCode = async (code: string, secret = ''): Promise<{ channelId: string; accessToken: string } | undefined> => {
		try {
			const res = await this.client.post(`/channels/redeemCode`, { code, secret })

			if (res.status !== 200) {
				console.error(res.statusText)
			}

			return res.data
		} catch (e) {
			console.error(e)
		}
	}

	public async getUserId(token: string): Promise<string> {
		try {
			const res = await this.client.get<{ userId: string }>(`/user/${token}`)
			if (res.status !== 200) {
				console.error(res.statusText)
			}

			return res.data.userId || ''
		} catch (e) {
			console.error(e)
			return ''
		}
	}

	public callbacks = async (
		channelId: string,
		callbacks: { [key: string]: { url: string; method: string; body: { [key: string]: string }; headers: { [key: string]: string } } }
	): Promise<boolean> => {
		try {
			const res = await this.client.post(`/channels/callbacks/${channelId}`, { callbacks })

			return res.status === 200
		} catch (e) {
			return false
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.MESSAGING_API_SERVER)
	}
}
