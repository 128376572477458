import { ShareType } from '@lynx/client-core/src/api/interfaces'
import { TextField } from '@lynx/client-core/src/components'
import { Overlay } from '@lynx/client-core/src/components/Popup/Overlay'
import { PermissionsText } from 'components/modals/SharingModal/helpers'
import i18next from 'i18next'
import React from 'react'
import { InviteProps } from './interfaces'
import css from './Invite.module.scss'

export const Invite = ({ email, onEmailChanged, onPermissionChanged, permission }: InviteProps): React.ReactElement => {
	const inviteMapping: { key: ShareType; name: string }[] = [
		{ key: 'viewer', name: i18next.t('components.modals.sharingModal.viewer') },
		{ key: 'admin', name: i18next.t('components.modals.sharingModal.admin') },
		{ key: 'editor', name: i18next.t('components.modals.sharingModal.editor') }
	]

	const inviteElements = inviteMapping.map((im) => {
		return (
			<li key={im.key} className={permission === im.key ? css['dropdown-current-item'] : ''} onClick={(): void => onPermissionChanged(im.key)}>
				{im.name}
			</li>
		)
	})

	return (
		<div>
			<label>{i18next.t('components.modals.sharingModal.sendTo')}</label>
			<TextField
				className={css['textfield']}
				onChange={onEmailChanged}
				placeholder="Email"
				value={email}
				adornment={
					<Overlay title={PermissionsText[permission]} className={css['dropdown']} popUpClassName={css['popup']}>
						<ul>{inviteElements}</ul>
					</Overlay>
				}
				justifyAdornment={'end'}
			/>
		</div>
	)
}
