import { APIRequest } from '@lynx/client-core/src/api'
import { DriveFolderItem, DrivePath, ClientProviders, DriveItemType } from '@lynx/client-core/src/api/interfaces'
import { DriveSize, ProfileDriveItem, ProviderContainerId, Providers, ProvidersObject } from '@lynx/core/src/interfaces/ObjectStore'
import { AsyncStorage, Logger } from '@lynx/client-core/src/modules'
import { Action, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ApplicationState } from 'store'
import { addBusy, removeBusy } from 'store/busy'
import { defaultHomeDriveItem } from './initialState'
import QueryString from 'qs'
import {
	CHANGE_DRIVE,
	CHANGE_DRIVE_STYLE,
	DriveActionTypes,
	DRIVE_RESPONSE,
	POP_DRIVE_PATH,
	PUSH_DRIVE_PATH,
	REFRESH_DRIVE_PATH,
	SELECT_DRIVEITEM,
	MAKE_DRIVE_READONLY,
	MAKE_DRIVE_WRITEABLE,
	SHOW_DRIVE_BROWSER,
	HIDE_DRIVE_BROWSER,
	DESELECT_DRIVEITEM,
	SET_HOME_DRIVE,
	UPDATE_DRIVE_ITEMS,
	UPDATE_NEXT_PAGE_STATE,
	UPDATE_DRIVE_ITEM,
	DELETE_DRIVE_ITEM,
	UploadingItem,
	ADD_UPLOADING_ITEM,
	UPDATE_UPLOADING_ITEM,
	DELETE_UPLOADING_ITEM,
	UPDATE_DRIVE_SIZE,
	UPDATE_UPLOADING_ITEMS,
	SET_DRIVES_SIZES,
	SET_FAVOURITES,
	ADD_FAVOURITE,
	REMOVE_FAVOURITE,
	SET_DRIVES,
	SET_DISALLOWED_LINK_PROVIDERS
} from './types'
import { AuthCookie } from '@lynx/client-core/src/modules'
import { extractResponseError } from '@lynx/core'
import { UNAUTHORIZED } from '@lynx/core/src/interfaces/ServerErrors'

export * from '@lynx/client-core/src/store/drive/actions'

export const driveResponse = (provider: ClientProviders, OK: boolean, message = ''): DriveActionTypes => {
	return {
		type: DRIVE_RESPONSE,
		payload: {
			provider,
			OK,
			message
		}
	}
}

/**
 * Changes exposed drive
 * @param provider
 */
const changeDriveInternal = (providerName: ClientProviders, data: DriveFolderItem, driveStyle: 'grid' | 'table'): DriveActionTypes => {
	return {
		type: CHANGE_DRIVE,
		payload: {
			provider: providerName,
			data,
			driveStyle
		}
	}
}

/**
 * Change to a drive path and push to the path stack
 * @param provider
 * @param id
 * @param name
 */
const pushDrivePathInternal = (providerName: ClientProviders, data: DriveFolderItem): DriveActionTypes => {
	return {
		type: PUSH_DRIVE_PATH,
		payload: {
			provider: providerName,
			data: data
		}
	}
}

/**
 * Change to the previous drive path
 * @param provider
 */
const popDrivePathInternal = (provider: ClientProviders, data: DriveFolderItem, paths: DrivePath[]): DriveActionTypes => {
	return {
		type: POP_DRIVE_PATH,
		payload: {
			provider,
			data,
			paths
		}
	}
}

const refreshDrivePathInternal = (provider: ClientProviders, data: DriveFolderItem): DriveActionTypes => {
	return {
		type: REFRESH_DRIVE_PATH,
		payload: {
			provider,
			data
		}
	}
}

export const selectDriveItem = (driveItem: DriveItemType): DriveActionTypes => {
	return {
		type: SELECT_DRIVEITEM,
		payload: {
			driveItem
		}
	}
}

export const deselectDriveItem = (): DriveActionTypes => {
	return {
		type: DESELECT_DRIVEITEM
	}
}

export const changeDriveStyle =
	(style: 'grid' | 'table'): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		await AsyncStorage.setItem('drivestyle', style)
		dispatch({
			type: CHANGE_DRIVE_STYLE,
			payload: style
		})
	}

export const setDisallowedLinkProviders = (data: string[]): DriveActionTypes => {
	return {
		type: SET_DISALLOWED_LINK_PROVIDERS,
		payload: data
	}
}

export const makeDriveReadonly = (): DriveActionTypes => {
	return {
		type: MAKE_DRIVE_READONLY
	}
}

export const makeDriveWriteable = (): DriveActionTypes => {
	return {
		type: MAKE_DRIVE_WRITEABLE
	}
}

export const showDriveBrowser = (): DriveActionTypes => {
	return {
		type: SHOW_DRIVE_BROWSER
	}
}

export const hideDriveBrowser = (): DriveActionTypes => {
	return {
		type: HIDE_DRIVE_BROWSER
	}
}

export const updateDriveItems = (data: Partial<DriveFolderItem>): DriveActionTypes => {
	return {
		payload: data,
		type: UPDATE_DRIVE_ITEMS
	}
}

export const updateDriveItem = (item: Partial<DriveItemType> & { id: DriveItemType['id'] }, originalId: string): DriveActionTypes => {
	return {
		payload: { item, originalId },
		type: UPDATE_DRIVE_ITEM
	}
}

export const deleteDriveItem = (id: DriveItemType['id']): DriveActionTypes => {
	return {
		payload: { id },
		type: DELETE_DRIVE_ITEM
	}
}

export const addUploadingItem = (data: UploadingItem): DriveActionTypes => {
	return {
		payload: data,
		type: ADD_UPLOADING_ITEM
	}
}

export const updateUploadingItem = (item: Partial<UploadingItem> & { id: UploadingItem['id'] }): DriveActionTypes => {
	return {
		payload: item,
		type: UPDATE_UPLOADING_ITEM
	}
}

export const updateUploadingItems = (items: UploadingItem[]): DriveActionTypes => {
	return {
		payload: items,
		type: UPDATE_UPLOADING_ITEMS
	}
}

export const deleteUploadingItem = (id: UploadingItem['id']): DriveActionTypes => {
	return {
		payload: { id },
		type: DELETE_UPLOADING_ITEM
	}
}

export const loadUserDrives =
	(): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		const userDrives = await APIRequest.ObjectStore.getDrives()
		if (!userDrives) return

		dispatch({
			type: SET_DRIVES,
			payload: userDrives
		})
	}

export const updateUserDrive =
	(updatedCards: ProfileDriveItem[]): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState): Promise<void> => {
		await APIRequest.ObjectStore.updateDrives(updatedCards.concat(getState().drive.drives.hidden))
		const userDrives = await APIRequest.ObjectStore.getDrives()
		dispatch({
			type: SET_DRIVES,
			payload: userDrives
		})
	}

export const updateDriveVisibility =
	(driveId: string, hide: boolean): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState): Promise<void> => {
		const { visible, hidden } = getState().drive.drives
		const updatedCards = [...visible.concat(hidden)]

		const driveToHide = updatedCards.find((d) => d.driveId === driveId)
		if (!driveToHide) {
			Logger.error('Could not find drive to change visibility')
			return
		}
		driveToHide.hidden = hide
		await updateUserDrive(updatedCards)(dispatch, getState, null)
	}

export const updateAllDriveVisibility =
	(): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		const { visible, hidden } = getState().drive.drives
		const updatedCards = [...visible.concat(hidden)]

		for (const card of updatedCards) {
			if (card.provider === 'lynxcloud') continue
			card.hidden = true
		}

		await updateUserDrive(updatedCards)(dispatch, getState, null)
	}

export const setHomeDrives =
	(): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		const homeItem = {
			...defaultHomeDriveItem,
			children: []
		}

		dispatch({
			type: SET_HOME_DRIVE,
			payload: {
				homeDrives: homeItem
			}
		})
	}

const updateNextPageState = (payload: { isNextPageLoading: boolean; loadingItemsCount: number }): DriveActionTypes => ({
	type: UPDATE_NEXT_PAGE_STATE,
	payload
})

export const updateDriveSize = (driveSize: DriveSize): DriveActionTypes => ({ type: UPDATE_DRIVE_SIZE, payload: driveSize })

export const getDrivesSizes =
	(): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		const { drivesSizes } = await APIRequest.ObjectStore.getDrivesSizes()
		dispatch({ type: SET_DRIVES_SIZES, payload: drivesSizes })
	}

export const getFavourites =
	(): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		const response = await APIRequest.Favourites.getFavourites()
		dispatch({ type: SET_FAVOURITES, payload: response.favourites })
	}

export const addFavourite =
	(itemId: string): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch({ type: ADD_FAVOURITE, payload: { itemId } })
		await APIRequest.Favourites.addFavourite(itemId)
	}

export const removeFavourite =
	(itemId: string): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch({ type: REMOVE_FAVOURITE, payload: { itemId } })
		await APIRequest.Favourites.removeFavourite(itemId)
	}

export const loadDriveNextPage =
	(): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		const blocking = true
		const {
			data: { id, nextPageToken, children },
			style,
			provider
		} = getState().drive
		if (!nextPageToken) return
		dispatch(addBusy(blocking))
		const query = QueryString.parse(window.location.search.substring(1)) as { driveId?: string }

		const loadingItemsCount = maxElementsCount(style).maxRowElements
		dispatch(updateNextPageState({ loadingItemsCount, isNextPageLoading: true }))
		try {
			const options = {
				providerContainerId: query.driveId,
				nextPageToken,
				pageSize: loadingItemsCount
			}
			const { data } = await APIRequest.ObjectStore.list(provider, id, options)
			dispatch(updateDriveItems({ nextPageToken: data.nextPageToken, children: [...children, ...data.children] }))
		} finally {
			dispatch(updateNextPageState({ loadingItemsCount: 0, isNextPageLoading: false }))
			dispatch(removeBusy(blocking))
		}
	}

export const changeDrive =
	(providerName: ClientProviders, providerContainerId?: ProviderContainerId): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		const blocking = true
		dispatch(addBusy(blocking))
		const state = getState()

		try {
			let driveStyle: 'grid' | 'table' = 'grid'
			try {
				driveStyle = ((await AsyncStorage.getItem('drivestyle')) as 'grid' | 'table') || 'grid'
			} catch (e) {
				driveStyle = 'grid'
			}
			const { nextPageToken } = getState().drive.data

			if (providerName) {
				const query = QueryString.parse(window.location.search.substring(1)) as { driveId?: string }
				const options = {
					providerContainerId: query.driveId || providerContainerId,
					nextPageToken,
					pageSize: maxElementsCount(driveStyle).maxElements
				}
				const driveResponse = await APIRequest.ObjectStore.list(providerName, '', options)
				dispatch(changeDriveInternal(providerName, driveResponse.data, driveStyle))
			} else {
				const { homeDrives } = state.drive
				homeDrives && dispatch(changeDriveInternal(providerName, homeDrives, driveStyle))
			}

			dispatch(removeBusy(blocking))

			if (!state.drive.writeable) {
				return
			}
		} catch (e) {
			const responseError = extractResponseError<{ provider: string; authenticationRequired: boolean }>(e)
			if (responseError?.name === UNAUTHORIZED && responseError.options?.authenticationRequired) {
				const { provider } = responseError.options
				const accessToken = AuthCookie.getAccessToken(state.config.ENVIRONMENT)
				const linkUrl = `${state.config.API_SERVER}/auth/${provider}/link/${accessToken}`
				accessToken && window.open(linkUrl, '_newtab')
				return
			}

			dispatch(driveResponse(providerName, false, 'Error occurred while loading drive.'))
			state.drive.homeDrives && dispatch(changeDriveInternal(null, state.drive.homeDrives, 'grid'))
			dispatch(removeBusy(blocking))
		}
	}

export const pushDrivePath =
	(providerName: ClientProviders, parentId: string, queryData?: { driveId: string }): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		const blocking = true
		dispatch(addBusy(blocking))

		const { style } = getState().drive
		const query = QueryString.parse(window.location.search.substring(1)) as { driveId?: string }
		const options = {
			providerContainerId: queryData?.driveId || query.driveId,
			pageSize: maxElementsCount(style).maxElements
		}

		const driveResponse = await APIRequest.ObjectStore.list(providerName, parentId, options)

		dispatch(pushDrivePathInternal(providerName, driveResponse.data))

		dispatch(removeBusy(blocking))
	}

export const popDrivePath =
	(providerName: ClientProviders): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		const blocking = true
		dispatch(addBusy(blocking))

		const paths = [...getState().drive.paths]
		const { additionalData } = paths[paths.length - 1]
		if (paths.length > 1) {
			paths.pop()
		}
		const { id } = paths[paths.length - 1]
		const { style } = getState().drive
		const query = QueryString.parse(window.location.search.substring(1)) as { driveId?: string }
		const options: { providerContainerId: string | undefined; pageSize: number; driveId?: string } = {
			providerContainerId: additionalData?.driveId || query.driveId,
			pageSize: maxElementsCount(style).maxElements
		}

		const driveResponse = await APIRequest.ObjectStore.list(providerName, id, options)

		dispatch(popDrivePathInternal(providerName, driveResponse.data, paths))

		dispatch(removeBusy(blocking))
	}

export const refreshDrivePath =
	(count?: number): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		const blocking = true
		dispatch(addBusy(blocking))

		const {
			currentPath: { id: parentId },
			data,
			provider
		} = getState().drive
		const query = QueryString.parse(window.location.search.substring(1)) as { driveId?: string }
		const options = {
			providerContainerId: query.driveId,
			pageSize: data.children.length + (count || 1)
		}
		const driveResponse = await APIRequest.ObjectStore.list(provider, parentId, options)

		if (parentId === getState().drive.currentPath.id) {
			dispatch(refreshDrivePathInternal(provider, driveResponse.data))
		}

		dispatch(removeBusy(blocking))
	}

export const changeDrivePath =
	(providerName: ClientProviders, parentId: string): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		const blocking = true
		dispatch(addBusy(blocking))

		let paths = [...getState().drive.paths]
		const pathIndex = paths.findIndex((path) => path.id === parentId)
		const { additionalData } = paths[pathIndex]
		paths = paths.slice(0, pathIndex + 1)

		const { style } = getState().drive
		const query = QueryString.parse(window.location.search.substring(1)) as { driveId?: string }
		const options: { providerContainerId: string | undefined; pageSize: number } = {
			providerContainerId: additionalData?.driveId || query.driveId,
			pageSize: maxElementsCount(style).maxElements
		}

		const driveResponse = await APIRequest.ObjectStore.list(providerName, parentId, options)

		dispatch(popDrivePathInternal(providerName, driveResponse.data, paths))

		dispatch(removeBusy(blocking))
	}

export const maxElementsCount = (layoutStyle: 'grid' | 'table'): { maxRowElements: number; maxElements: number } => {
	const headerHeight = 50 //px
	const controlsHeight = 90 //px
	const containerHeight = window.innerHeight - headerHeight - controlsHeight

	const padding = 10
	const containerWidth = window.innerWidth - padding

	const tileHight = layoutStyle === 'grid' ? 120 : 70 //px
	const tileWidth = 220 //px

	const maxRowElements = Math.floor(containerWidth / tileWidth)
	const maxElInHeight = Math.floor(containerHeight / tileHight)

	if (layoutStyle === 'grid') {
		const maxElements = maxElInHeight * maxRowElements
		return { maxRowElements, maxElements }
	} else {
		const maxElements = maxElInHeight
		return { maxRowElements, maxElements }
	}
}
