import { NotData } from '../Illustrations'
import i18next from 'i18next'
import React from 'react'
import Styles from './TableBody.module.css'
import { TableBodyProps } from './interfaces'
import { HighlightedText as Highlighted } from '../HighlightedText'

export const TableBody = <T,>({ gridTemplate, headers, items, tableSearchValue, onRowClick }: TableBodyProps<T>): React.ReactElement => {
	const onCellClicked = (e: React.MouseEvent<HTMLElement>, item: T): void => {
		e.preventDefault()
		if (e.target === e.currentTarget) {
			onRowClick && onRowClick(item)
		}
	}

	const getItemKey = (item: T, i: number): string => `${Object.values(item && typeof item === 'object' ? item : {}).join()}row${i}`

	return (
		<div className={Styles.container} style={{ gridTemplateRows: gridTemplate.gridTemplateRows, display: 'grid' }}>
			{items.length ? (
				items.map((item, i) => (
					<div key={getItemKey(item, i)} role="row" style={{ gridTemplateColumns: gridTemplate.gridTemplateColumns }} className={Styles.row}>
						{headers.map(({ customBodyRender, valueKey, cellAdditionalStyles }, headerIndex) => {
							const HighlightedText = <Highlighted text={String(item[valueKey as keyof T])} highlight={tableSearchValue} />
							return (
								<div
									style={cellAdditionalStyles}
									className={Styles.cellContainer}
									key={`${String(item[valueKey as keyof T])}${valueKey}cell${headerIndex}${i}`}
									onClick={(e: React.MouseEvent<HTMLElement>): void => onCellClicked(e, item)}
								>
									{(customBodyRender && customBodyRender(item, i, { highlightedText: HighlightedText })) || HighlightedText}
								</div>
							)
						})}
					</div>
				))
			) : (
				<div className={Styles.emptyStateContainer}>
					<NotData className={Styles.emptyStateIllustration} />
					<div>{i18next.t('components.table.noResultsFound')}</div>
				</div>
			)}
		</div>
	)
}
