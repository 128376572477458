export const languages: { value: string; label: string }[] = [
	{ value: 'af', label: 'Afrikaans - Afrikaans' },
	{ value: 'sq', label: 'Shqip - Albanian' },
	{ value: 'ar', label: 'العربية - Arabic' },
	{ value: 'bg', label: 'Български - Bulgarian' },
	{ value: 'ca', label: 'Català - Catalan' },
	{ value: 'hr', label: 'Hrvatski - Croatian' },
	{ value: 'cs', label: 'Čeština - Czech' },
	{ value: 'da', label: 'Dansk  - Danish' },
	{ value: 'nl', label: 'Nederlands - Dutch' },
	{ value: 'en', label: 'English - English' },
	{ value: 'et', label: 'Eesti Keel - Estonian' },
	{ value: 'fo', label: 'Føroyskt - Faroese' },
	{ value: 'fi', label: 'Suomalainen - Finnish' },
	{ value: 'fr', label: 'Français - French' },
	{ value: 'gl', label: 'Galego - Galician' },
	{ value: 'de', label: 'Deutsch - German' },
	{ value: 'el', label: 'Ελληνικά  - Greek' },
	{ value: 'he', label: 'עברית - Hebrew' },
	{ value: 'hu', label: 'Magyar - Hungarian' },
	{ value: 'is', label: 'Íslenska - Icelandic' },
	{ value: 'ga', label: 'Gaeilge - Irish' },
	{ value: 'it', label: 'Italiano - Italian' },
	{ value: 'kk', label: 'Kazakh - Kazakhstan' },
	{ value: 'ky', label: 'Kyrgyz - Kyrgyzstan' },
	{ value: 'lv', label: 'Latviešu valoda - Latvian' },
	{ value: 'lt', label: 'Lietuvių kalba - Lithuanian' },
	{ value: 'mk', label: 'Македонски - Macedonian' },
	{ value: 'nb', label: 'Norsk - Norwegian' },
	{ value: 'pl', label: 'Polski - Polish' },
	{ value: 'pt', label: 'Português - Portuguese' },
	{ value: 'ro', label: 'Română - Romanian' },
	{ value: 'ru', label: 'Pусский - Russian' },
	{ value: 'sr', label: 'Српски - Serbian' },
	{ value: 'zh', label: '简体中文 - Simplified Chinese' },
	{ value: 'sk', label: 'Slovenčina - Slovak' },
	{ value: 'sl', label: 'Slovenščina - Slovenian' },
	{ value: 'th', label: 'แบบไทย - Thai' },
	{ value: 'es', label: 'Español - Spanish' },
	{ value: 'sv', label: 'Svenska - Swedish' },
	{ value: 'zh-Hant', label: '繁體中文 - Traditional Chinese' },
	{ value: 'tg', label: 'Tajiki - Tajikistan' },
	{ value: 'tk', label: 'Turkmen - Turkmenistan' },
	{ value: 'tr', label: 'Türkçe - Turkish' },
	{ value: 'uk', label: 'Українська - Ukrainian' },
	{ value: 'uz', label: 'Uzbek - Uzbekistan' },
	{ value: 'cy', label: 'Cymraeg - Welsh' }
]

export const supportedLanguages: string[] = languages.map(({ value }) => value)
