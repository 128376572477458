/* eslint-disable */
import { APIQueryParams, AccountProfile, UserPermissionsType } from '@lynx/core/src/interfaces'
import { UserProfile } from '@lynx/core/src/interfaces/User'
import { AxiosResponse } from 'axios'
import { Logger } from '../../modules/Logger'
import { BaseCall } from '../BaseCall'
import LoginError from '../LoginError'
import { APIConfig, APIResponse, CheckReturn, LoginProfile, ProfileReturn } from '../interfaces'

export class User extends BaseCall {
	public deleteUserPermission = async (userId: string, permissionTypeId: UserPermissionsType): Promise<void> => {
		try {
			await this.client.delete(`/user/userPermission/${userId}/${permissionTypeId}`)
		} catch (e) {
			Logger.error(e)
		}
	}
	public addUserPermission = async (userId: string, permissionTypeId: UserPermissionsType): Promise<void> => {
		try {
			await this.client.post(`/user/userPermission/${userId}/${permissionTypeId}`)
		} catch (e) {
			Logger.error(e)
		}
	}
	public acceptLatestTerms = async (): Promise<boolean> => {
		try {
			const response = await this.client.post(
				'/user/acceptTerms',
				{},
				{
					withCredentials: true
				}
			)
			return response.status === 200
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public NextCloudPoll = async (token: string, endpoint: string): Promise<APIResponse | { error: boolean }> => {
		const bodyFormData = new FormData()
		bodyFormData.set('token', token)
		bodyFormData.set('endpoint', endpoint)

		try {
			const response = await this.client.post<APIResponse>('/auth/NextCloud/check', bodyFormData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				withCredentials: true
			})

			if (response.status === 200) {
				return response.data
			}

			throw new LoginError({
				data: 'Incorrect username or password'
			})
		} catch (err) {
			Logger.error(err)
			return { error: true }
		}
	}

	public check = async (username: string): Promise<APIResponse<CheckReturn>> => {
		const bodyFormData = new FormData()
		bodyFormData.set('username', username)
		//Not required on the check endpoint, but local auth requires it anyway
		bodyFormData.set('password', 'password')

		try {
			const response = await this.client.post<APIResponse<CheckReturn>>('/auth/login/check', bodyFormData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				withCredentials: true
			})

			if (response.status === 200) {
				return response.data
			}

			throw new LoginError({
				data: 'Incorrect username or password'
			})
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public login = async (username: string, password: string, code?: string, scheme?: string): Promise<LoginProfile> => {
		const bodyFormData = new FormData()
		bodyFormData.set('username', username)
		bodyFormData.set('password', password)

		if (code) {
			bodyFormData.set('code', code)
		}

		scheme && bodyFormData.set('scheme', scheme)

		try {
			const response = await this.client.post<LoginProfile>('/auth/login/token', bodyFormData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				withCredentials: true
			})

			if (response.status === 200) {
				return response.data
			}

			throw new LoginError({
				data: 'Incorrect username or password'
			})
		} catch (err: any) {
			if (err.response.data?.redirect) {
				window.location = err.response.data.redirect
			}
			throw err
		}
	}

	public session = async (): Promise<any> => {
		try {
			const response = await this.client.get('/auth/login/session', {
				withCredentials: true
			})

			if (response.status === 200) {
				return response.data
			}

			throw new LoginError({
				data: 'No session found'
			})
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public profile = async (): Promise<UserProfile> => {
		try {
			const response = await this.client.get<UserProfile>('/user')

			if (response.status === 200) {
				return response.data
			}

			throw new LoginError({
				data: 'Bad Profile'
			})
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public qrLoginSuccess = async (code: string): Promise<APIResponse> => {
		const response = await this.client.post(`/auth/qr/redirect/${code}`, {}, { withCredentials: true })
		return response.data
	}

	public renew = async (tokens: { accessToken: string; refreshToken: string }): Promise<void | string> => {
		try {
			const response = await this.client.patch('/user/renew', tokens, {
				headers: {
					'Content-Type': 'application/json'
				}
			})

			if (response.status !== 200 || !response.data) {
				Logger.error(response.statusText)
				return
			}

			return response.data.accessToken
		} catch (err) {
			Logger.error(err)
		}
	}

	public getTokensFromTokenId = async (tokenId: string): Promise<{ accessToken: string; refreshToken: string }> => {
		const response = await this.client.post(
			'/user/token-id/tokens',
			{ tokenId },
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		)

		return response.data
	}

	public requestDelete = async (): Promise<APIResponse<{ requested: boolean }>> => {
		try {
			const response = await this.client.post<APIResponse<{ requested: boolean }>>('/user/requestDelete')

			if (response.status !== 200 || !response.data) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return null
			}

			return response.data
		} catch (e) {
			Logger.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public verify = async (token: string): Promise<boolean> => {
		try {
			const response = await this.client.post(
				'/user/verify',
				{ token },
				{
					headers: {
						'Content-Type': 'application/json'
					},
					withCredentials: true
				}
			)

			return response.status === 200
		} catch (err) {
			Logger.error(err)
			return false
		}
	}

	public forgottenPassword = async (username: string): Promise<any> => {
		const body = {
			userName: username
		}

		try {
			return await this.client.post('/auth/forgot', body, {
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true
			})
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public federatedCheck = async (code: string, { isWhiteboard }: { isWhiteboard: boolean }): Promise<{ error: boolean }> => {
		let url = '/auth/login/federated'
		if (isWhiteboard) url += '?deviceType=whiteboard'

		try {
			return await this.client.post(
				url,
				{ code },
				{
					headers: {
						'Content-Type': 'application/json'
					},
					withCredentials: true
				}
			)
		} catch (err) {
			Logger.error(err)
			return {
				error: true
			}
		}
	}

	public updateUserStorageLimit = async (userId: string, storageLimit: number): Promise<boolean> => {
		try {
			const response = await this.client.post(`/user/storageLimit/${userId}`, { storageLimit })

			return response.status === 200
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public addBetaAccess = async (userId: string): Promise<boolean> => {
		try {
			const response = await this.client.post(`/user/accessType/${userId}`, { accessType: 'beta' })

			return response.status === 200
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public removeBetaAccess = async (userId: string): Promise<boolean> => {
		try {
			const response = await this.client.delete(`/user/accessType/${userId}`)
			return response.status === 200
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public addProSubscription = async (userId: string): Promise<boolean> => {
		try {
			const response = await this.client.post(`/user/pro/${userId}`)
			return response.status === 200
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public removeProSubscription = async (userId: string): Promise<boolean> => {
		try {
			const response = await this.client.delete(`/user/pro/${userId}`)
			return response.status === 200
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public resetPassword = async (user: string, id: string, password: string): Promise<any> => {
		const bodyFormData = new FormData()
		bodyFormData.set('userName', user)
		bodyFormData.set('resetId', id)
		bodyFormData.set('password', password)

		try {
			return await this.client.post('/auth/forgot-confirm', bodyFormData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				withCredentials: true
			})
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public changePassword = async (currentPassword: string, newPassword: string, repeatPassword: string): Promise<ProfileReturn> => {
		try {
			const response = await this.client.post('/profile/change-password', {
				currentPassword,
				newPassword,
				repeatPassword
			})
			return response.data
		} catch (err) {
			Logger.error(err)
			return {
				success: false,
				message: 'Something went wrong, could not continue!'
			}
		}
	}

	public changeEmail = async (newEmail: string): Promise<ProfileReturn> => {
		try {
			const response = await this.client.post('/profile/change-email', {
				newEmail
			})
			if (response.status === 200) {
				return response.data
			}
			return {
				success: false,
				message: 'Something went wrong, could not continue!'
			}
		} catch (err) {
			Logger.error(err)
			return {
				success: false,
				message: 'Something went wrong, could not continue!'
			}
		}
	}

	public changeDisplayName = async (displayName: string): Promise<ProfileReturn> => {
		try {
			const response = await this.client.post('/profile/change-displayname', {
				displayName
			})

			if (response.status === 200) {
				return response.data
			}

			return {
				success: false,
				message: 'Something went wrong, could not continue!'
			}
		} catch (err) {
			Logger.error(err)
			return {
				success: false,
				message: 'Something went wrong, could not continue!'
			}
		}
	}

	public changeLang = async (lang: string): Promise<ProfileReturn> => {
		try {
			const response = await this.client.post('/profile/change-lang', {
				lang
			})

			if (response.status === 200) {
				return response.data
			}

			return {
				success: false,
				message: 'Something went wrong, could not continue!'
			}
		} catch (err) {
			Logger.error(err)
			return {
				success: false,
				message: 'Something went wrong, could not continue!'
			}
		}
	}

	public checkEmail = async (email: string): Promise<{ error: boolean | string }> => {
		try {
			const response = await this.client.post(`/check-email`, { email })
			return response.data
		} catch (err) {
			Logger.error(err)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return err.response.data
		}
	}

	public getAll = async (
		params: APIQueryParams
	): Promise<{
		data: { accounts: AccountProfile[]; totalCount: number }
	} | null> => {
		try {
			const response = await this.client.get('/user/all', { params })
			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public getLynxKeys = async (): Promise<ProfileReturn> => {
		try {
			const response = await this.client.get('/profile/get-lynx-keys')
			return response.data
		} catch (err) {
			Logger.error(err)
			return {
				success: false,
				message: 'Something went wrong, could not continue!'
			}
		}
	}

	public setProfilePicture = async (pictureFile: File): Promise<{ url: string }> => {
		try {
			const formData = new FormData()
			formData.append('file', pictureFile)

			const response = await this.client.post('/profile/upload-avatar', formData)
			return response.data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public getProviderAvatars = async (): Promise<any> => {
		try {
			const response = await this.client.get('/profile/providers-avatars')
			return response.data
		} catch (err) {
			Logger.error(err)
		}
	}

	public resendVerificationEmail = async (username: string, password: string): Promise<AxiosResponse> => {
		const body = {
			userName: username,
			password: password
		}

		return await this.client.post('/auth/resend-verification', body, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
	}

	public register = (username: string, password: string): Promise<AxiosResponse> => {
		const body = {
			userName: username,
			password: password
		}

		return this.client.post('/auth/register', body, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
	}

	public async createToken(
		deviceType: 'whiteboard' | null,
		skipToken = false
	): Promise<{ accessToken: string; refreshToken: string; expireAt: string; sessionId: string }> {
		const url = new URL('http://dummy.com')
		deviceType && url.searchParams.append('deviceType', deviceType)
		skipToken && url.searchParams.append('skipToken', 'true')

		const response = await this.client.get('/auth/create-token?' + url.searchParams.toString(), {
			withCredentials: true
		})
		return response.data
	}

	public async logout(): Promise<AxiosResponse> {
		const response = await this.client.get('/auth/logout', {
			withCredentials: true
		})
		return response
	}

	public async logoutEverywhere(): Promise<AxiosResponse> {
		const response = await this.client.get('/auth/logout/everywhere', {
			withCredentials: true
		})
		return response
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
