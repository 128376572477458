import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Input, Textarea } from '@lynx/client-core/src/components'
import { Cookie, Logger } from '@lynx/client-core/src/modules'
import i18next from 'i18next'
import * as React from 'react'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { changeFeedbackToken, changeFeedbackView } from 'store/feedback'
import Styles from './Feedback.module.scss'
import StarRating from '@lynx/client-core/src/components/StarRating/StarRating'

const convertRating = (rating: number): number => {
	if (rating >= 1 && rating <= 5) {
		return 5 - rating
	}
	return 0
}

export const Feedback = (): React.ReactElement => {
	const dispatch = useDispatch()
	const { feedback, config } = useSelector((state: ApplicationState) => state)
	const { ENVIRONMENT } = config

	const [rating, setRating] = useState(0)
	const [comment, setComment] = useState('')
	const [email, setEmail] = useState('')

	const showFeedback = async (): Promise<void> => {
		const { token: currentToken } = feedback
		const getFeedbackResponse = await APIRequest.Feedback.get(currentToken)
		const { data } = getFeedbackResponse || {}

		cleanForm()

		// If no question for token or an error occurred then early out
		if (!data) {
			return
		}

		const { token } = data

		// Log a shown feedback event to GA
		ReactGA.event({
			action: `Feedback [token:${token}] shown`,
			category: 'Feedback'
		})

		// Set feedback as shown, leave the token as is.
		updateFeedbackDate(token, 15)

		dispatch(changeFeedbackView('questions'))
	}

	const handleClose = (): void => {
		const { view } = feedback
		ReactGA.event({
			action: `Feedback closed on ${view} view`,
			category: 'Feedback'
		})

		dispatch(changeFeedbackView('hidden'))
		dispatch(changeFeedbackToken(0))
	}

	const updateFeedbackDate = (token: number, days: number): void => {
		const removeDaysFromNow = new Date()
		removeDaysFromNow.setDate(removeDaysFromNow.getDate() - days)
		const unixDateDaysFromNow = removeDaysFromNow.getTime() / 1000

		const cloudCookie = Buffer.from(JSON.stringify({ lastToken: token, lastAskedDate: unixDateDaysFromNow }), 'base64')

		Cookie.set('lynxcloud', cloudCookie.toString('base64'), {
			SameSite: 'Strict',
			Path: '/',
			Domain: ENVIRONMENT === 'dev' ? 'localhost' : '.lynxcloud.app'
		})
	}

	const handleComplete = async (): Promise<void> => {
		try {
			await APIRequest.Feedback.send({
				token: 1,
				email: email,
				extra: comment,
				optionIndex: convertRating(rating)
			})

			const { token } = feedback

			// Set feedback as shown, leave the token as is.
			updateFeedbackDate(token, 30)

			dispatch(changeFeedbackView('thankyou'))

			setTimeout(() => {
				dispatch(changeFeedbackView('hidden'))
			}, 4000)

			ReactGA.event({
				action: `Feedback [token:${token}] completed`,
				category: 'Feedback'
			})

			cleanForm()
		} catch (e) {
			Logger.error(e)
		}
	}

	const cleanForm = (): void => {
		setRating(0)
		setComment('')
		setEmail('')
	}

	const isValid = (): boolean => {
		return rating > 0 && comment.length > 0
	}

	useEffect(() => {
		const mount = async (): Promise<void> => {
			await showFeedback()
		}

		mount()
	}, [])

	useEffect(() => {
		const update = async (): Promise<void> => {
			const { token } = feedback
			if (token) {
				await showFeedback()
				dispatch(changeFeedbackToken(token))
			}
		}
		update()
	}, [feedback.token])

	const { view: viewState } = feedback
	const hidden = viewState === 'hidden'

	return (
		<div className={[Styles['container'], Styles['form']].join(' ')} style={{ display: hidden ? 'none' : 'block' }}>
			{viewState === 'thankyou' && <h3>{i18next.t('components.feedback.sent')}</h3>}
			{(viewState === 'questions' || viewState === 'intro') && (
				<>
					<div className={Styles['question']}>{i18next.t('pages.activitygroup.modals.giveFeedback.title')}</div>

					<div className={Styles['star-rating-container']}>
						<StarRating
							onRating={(rating: number): void => {
								setRating(rating)
							}}
						/>
					</div>

					<div>
						<Textarea
							className={Styles.textarea}
							placeholder={i18next.t('pages.activitygroup.modals.giveFeedback.commentPlaceholder')}
							onChange={(e): void => setComment(e.target.value)}
							value={comment}
						/>
					</div>

					<div>
						<Input
							label={i18next.t('pages.activitygroup.modals.giveFeedback.emailLabel')}
							onChange={(e): void => setEmail(e.target.value)}
							value={email}
							placeholder={i18next.t('pages.activitygroup.modals.giveFeedback.emailPlaceholder')}
						/>
					</div>

					<div className={Styles['tips']}>
						{i18next.t('pages.activitygroup.modals.giveFeedback.supportInfo')}{' '}
						<a href="https://support.clevertouch.com/support-cases" target="_blank">
							CleverSupport
						</a>
						.
					</div>

					<div className={Styles['buttons-section']}>
						<BasicButton onClick={handleClose} variant="grey" className="feedbackButton">
							{i18next.t('pages.activitygroup.modals.giveFeedback.buttonClose')}
						</BasicButton>
						<BasicButton disabled={!isValid()} onClick={handleComplete} variant="blue" className="feedbackButton">
							{i18next.t('pages.activitygroup.modals.giveFeedback.buttonSend')}
						</BasicButton>
					</div>
				</>
			)}
		</div>
	)
}
