import { Document } from '@lynx/client-core/src/components'
import React from 'react'
import css from './FAQ.module.scss'
import Accordion, { AccordionItem } from '@lynx/client-core/src/components/Accordion/Accordion'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function FAQ(): React.ReactElement {
	const location = useLocation()
	const { t } = useTranslation()

	const params = new URLSearchParams(location.search)
	const activeIndex = params.get('activeIndex') ? Number(params.get('activeIndex')) : undefined

	return (
		<Document
			title="Browse Frequently Asked Questions"
			description="Browse our frequently asked questions to get information on specific subjects"
			keywords="FAQ, Questions, Topics"
		>
			<div className={css['container']}>
				<div className={css['faq-section']}>
					<h1 className={css['faq-main-title']}>{t('pages.faq.title')}</h1>
					<div className={css['faq-questions']}>
						<Accordion forceActiveIndex={activeIndex}>
							<AccordionItem title={t(`pages.faq.questions.q1.title`)}>
								<p>{t('pages.faq.questions.q1.p1')}</p>
								<p>{t('pages.faq.questions.q1.p2')}</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q2.title`)}>
								<p>{t('pages.faq.questions.q2.p1')}</p>
								<ul>
									<li>
										<a href="https://fonts.google.com/specimen/ABeeZee?query=Arch">ABeeZee</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Architects+Daughter?query=Arch">Architects Daughter</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Comfortaa?query=Arch">Comfortaa</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Creepster?query=Arch">Creepster</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Dancing+Script?query=Arch">Dancing Script</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Gloria+Hallelujah?query=Arch">Gloria Hallelujah</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/JosefinSans?query=Arch">JosefinSans</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Lemon?query=Arch">Lemon</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Mulir?query=Arch">Muli</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/OpenSans?query=Arch">OpenSans</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Pacifico?query=Arch">Pacifico</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Poiret+One?query=Arch">Poiret One</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Poppins?query=Arch">Poppins</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Questrial?query=Arch">Questrial</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Quicksand?query=Arch">Quicksand</a>
									</li>
									<li>
										<a href="https://fonts.google.com/specimen/Tillana?query=Arch">Tillana</a>
									</li>
								</ul>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q3.title`)}>
								<p>{t('pages.faq.questions.q3.p1')}</p>
								<p>{t('pages.faq.questions.q3.p2')}</p>
								<p>{t('pages.faq.questions.q3.p3')}</p>
								<p>{t('pages.faq.questions.q3.p4')}</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q4.title`)}>
								<p>
									{t('pages.faq.questions.q4.p1')}
									<a href="https://www.microsoft.com/en-gb/p/lynx-whiteboard/9np33m4m3r35?activetab=pivot:overviewtab">
										https://www.microsoft.com/en-gb/p/lynx-whiteboard/9np33m4m3r35?activetab=pivot:overviewtab
									</a>
								</p>
								<p>
									{t('pages.faq.questions.q4.p2')}
									<a href="https://play.google.com/store/apps/details?id=com.clevertouch.lynx">
										https://play.google.com/store/apps/details?id=com.clevertouch.lynx
									</a>
								</p>
								<p>
									{t('pages.faq.questions.q4.p3')}
									<a href="https://apps.apple.com/us/app/lynx-5/id1260946498?ls=1">https://apps.apple.com/us/app/lynx-5/id1260946498?ls=1</a>
								</p>
								<p>
									{t('pages.faq.questions.q4.p4')}
									<a href="https://www.lynxcloud.app/more-downloads">https://www.lynxcloud.app/more-downloads</a>
								</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q5.title`)}>
								<p>{t('pages.faq.questions.q5.p1')}</p>
								<p>
									{t('pages.faq.questions.q5.p2')}
									<a href="https://download.saharasupport.com/Documents/Lynx_Group_Policy_Documentation.zip">
										{t('pages.faq.questions.q5.p3')}
									</a>
								</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q6.title`)}>
								<p>{t('pages.faq.questions.q6.p1')}</p>
								<p>
									<a href="https://api.saharasupport.com">https://api.saharasupport.com</a>
								</p>
								<p>
									<a href="https://download.saharasupport.com">https://download.saharasupport.com</a>
								</p>
								<p>
									<a href="https://www.lynxcloud.app">https://www.lynxcloud.app</a>
								</p>
								<p>
									<a href="https://api.lynxcloud.app">https://api.lynxcloud.app</a>
								</p>
								<p>{t('pages.faq.questions.q6.p2')}</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q7.title`)}>
								<p>{t('pages.faq.questions.q7.p1')}</p>
								<p>
									<a href="https://www.microsoft.com/en-gb/download/details.aspx?id=35">
										https://www.microsoft.com/en-gb/download/details.aspx?id=35
									</a>
								</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q8.title`)}>
								<p>{t('pages.faq.questions.q8.p1')}</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q9.title`)}>
								<p>{t('pages.faq.questions.q9.p1')}</p>
								<p>{t('pages.faq.questions.q9.p2')}</p>
								<p>{t('pages.faq.questions.q9.p3')}</p>
								<p>{t('pages.faq.questions.q9.p4')}</p>
								<p>
									<a href="https://api.saharasupport.com/api-v1/info/software">https://api.saharasupport.com/api-v1/info/software</a>
									https://download.saharasupport.com/
								</p>
								<p>{t('pages.faq.questions.q9.p5')}</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q10.title`)}>
								<p>{t('pages.faq.questions.q10.p1')}</p>
								<p>{t('pages.faq.questions.q10.p2')}</p>
								<p>C:\Program Files\Lynx</p>
								<p>{t('pages.faq.questions.q10.p3')}</p>
								<p>Documents\My Lynx\logs</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q11.title`)}>
								<p>{t('pages.faq.questions.q11.p1')}</p>
								<p>{t('pages.faq.questions.q11.p2')}</p>
								<p>
									<a href="https://www.microsoft.com/en-gb/download/details.aspx?id=17718">
										https://www.microsoft.com/en-gb/download/details.aspx?id=17718
									</a>
								</p>
								<p>{t('pages.faq.questions.q11.p3')}</p>
								<p>
									<a href="https://downloads.saharasupport.com/vc_redist_x86-2019.exe">2019 - 32bit</a>
								</p>
								<p>
									<a href="https://downloads.saharasupport.com/vc_redist_x64-2019.exe">2019 - 64bit</a>
								</p>
								<p>Microsoft DirectX 9: </p>
								<p>
									<a href="https://www.microsoft.com/en-gb/download/details.aspx?id=35">
										https://www.microsoft.com/en-gb/download/details.aspx?id=35
									</a>
								</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q12.title`)}>
								<p>{t('pages.faq.questions.q12.p1')}</p>
								<p>{t('pages.faq.questions.q12.p2')}</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q13.title`)}>
								<p>{t('pages.faq.questions.q13.p1')}</p>
								<p>
									<a href="https://api.saharasupport.com">https://api.saharasupport.com</a>
								</p>
								<p>
									<a href="https://download.saharasupport.com">https://download.saharasupport.com</a>
								</p>
								<p>
									<a href="https://www.lynxcloud.app">https://www.lynxcloud.app</a>
								</p>
								<p>
									<a href="https://api.lynxcloud.app">https://api.lynxcloud.app</a>
								</p>
								<p>
									<a href="wss://api.lynxcloud.app">wss://api.lynxcloud.app</a>
								</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q14.title`)}>
								<p>{t('pages.faq.questions.q14.p1')}</p>
								<p>{t('pages.faq.questions.q14.p2')}</p>
								<p>
									Computer\HKEY<em>CURRENT</em>USER\SOFTWARE\Lynx\4\Lynx::UI::Controller
								</p>
								<p>{t('pages.faq.questions.q14.p3')}</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q15.title`)}>
								<div className={css['video-frame-container']}>
									<iframe
										src="https://iframe.mediadelivery.net/embed/114646/22257b91-49ed-4db2-ba7c-05849fe980b8?autoplay=false"
										loading="lazy"
										className={css['video-frame']}
										allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
										allowFullScreen={true}
									></iframe>
								</div>
								<p>
									<b>{t('pages.faq.questions.q15.p1')}</b>: /simplesamlphp/saml2/idp/SSOService.php
								</p>
								<p>
									<b>{t('pages.faq.questions.q15.p2')}</b>: https://www.lynxcloud.app/auth/logout
								</p>
								<p>
									<b>{t('pages.faq.questions.q15.p3')} NameId</b>: urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified
								</p>
								<p>
									<b>{t('pages.faq.questions.q15.p4')}</b>: urn:oasis:names:tc:SAML:2.0:nameid-format:email
								</p>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q16.title`)}>
								<div className={css['video-frame-container']}>
									<iframe
										src="https://iframe.mediadelivery.net/embed/114646/b049fa4a-2695-4447-840f-5a2f26534896?autoplay=false&loop=false&muted=false&preload=true"
										loading="lazy"
										className={css['video-frame']}
										allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
										allowFullScreen={true}
									></iframe>
								</div>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q17.title`)}>
								<div className={css['video-frame-container']}>
									<iframe
										src="https://iframe.mediadelivery.net/embed/114646/eddb2bd1-f99a-4041-a210-5a1a29197b64?autoplay=false&loop=false&muted=false&preload=true"
										loading="lazy"
										className={css['video-frame']}
										allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
										allowFullScreen={true}
									></iframe>
								</div>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q18.title`)}>
								<div className={css['video-frame-container']}>
									<iframe
										src="https://iframe.mediadelivery.net/embed/114646/48aeb960-c761-4f48-b185-9db1b528cf30?autoplay=false&loop=false&muted=false&preload=true"
										loading="lazy"
										className={css['video-frame']}
										allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
										allowFullScreen={true}
									></iframe>
								</div>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q19.title`)}>
								<div className={css['video-frame-container']}>
									<iframe
										src="https://iframe.mediadelivery.net/embed/114646/e7e03472-021b-409c-97cb-7b9c8e56f241?autoplay=false&loop=false&muted=false&preload=true"
										loading="lazy"
										className={css['video-frame']}
										allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
										allowFullScreen={true}
									></iframe>
								</div>
								<p>
									<b>{t('pages.faq.questions.q19.p1')}</b>: #YOUR ISERV DOMAIN#/iserv/oauth/v2/auth
								</p>
								<p>
									<b>{t('pages.faq.questions.q19.p2')}</b>: #YOUR ISERV DOMAIN#/iserv/oauth/v2/token
								</p>
								<p>
									<b>{t('pages.faq.questions.q19.p3')}</b>: #YOUR ISERV DOMAIN#/iserv/public/oauth/userinfo
								</p>
							</AccordionItem>
							<AccordionItem title={'How to setup organisation domain verification'}>
								<div className={css['domain-verification']}>
									<p>
										To set up a TXT record on DNS (Domain Name System), you typically need access to your domain registrar's DNS management
										interface. Here's a general guide on how to do it:
									</p>
									<ol>
										<li>
											<b>Log in to your DNS management interface:</b> Go to your domain registrar's website and log in to your account.
											Navigate to the DNS management section for the domain you want to modify.
										</li>
										<li>
											<b>Locate the TXT records section:</b> In the DNS management interface, look for an option to manage TXT records.
											This might be labeled as "DNS Management," "DNS Settings," or something similar.
										</li>
										<li>
											<b> Add a new TXT record:</b> Once you've found the TXT records section, there should be an option to add a new
											record. Click on this option to create a new TXT record.
										</li>
										<li>
											<b>Enter the TXT record details:</b>
											<ol>
												<li>
													<b>Name/Host/Alias:</b> Enter "lynxauth.&lt;yourdomain&gt;" as the TXT domain name, for example
													"lynxauth.example.com".
												</li>
												<li>
													<b>TTL (Time to Live):</b> Set the TTL value for the record. This determines how long the record is cached
													by DNS servers, we recommend setting this to as low as possible, typically 300 seconds (5 minutes).
												</li>
												<li>
													<b>Type:</b> Select "TXT" from the dropdown menu to specify that you're adding a TXT record.
												</li>
												<li>
													<b>Value/Text:</b> Enter the key string provided.
												</li>
											</ol>
										</li>
										<li>
											<b>Save the changes:</b> After entering the TXT record details, save the changes using the appropriate option in
											your DNS management interface. This might be labeled as "Save Changes," "Update Record," or similar.
										</li>
										<li>
											<b>Verify the TXT record:</b> Once saved, it may take some time for the changes to propagate across DNS servers. You
											can use online DNS lookup tools to verify that the TXT record has been successfully added and is resolving
											correctly.
										</li>
									</ol>
									<p>
										Keep in mind that the exact steps and terminology may vary depending on your domain registrar's interface. If you're
										unsure about any aspect of setting up a TXT record, you can consult your registrar's documentation or contact their
										support for assistance.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title={t(`pages.faq.questions.q21.title`)}>
								<p>{t('pages.faq.questions.q21.p1')}</p>
								<p>{t('pages.faq.questions.q21.p2')}</p>
								<p>{t('pages.faq.questions.q21.p3')}</p>
								<p>{t('pages.faq.questions.q21.p4')}</p>
							</AccordionItem>
						</Accordion>
					</div>
				</div>
			</div>
		</Document>
	)
}
