import { logoutEverywhere } from 'store/profile'
import { useThunkDispatch } from 'hooks'
import React, { useEffect } from 'react'
import { Logger } from '@lynx/client-core/src/modules'
import { useNavigate } from 'react-router-dom'

export const LogoutEverywhere = (): React.ReactElement | null => {
	const dispatchThunk = useThunkDispatch()
	const navigate = useNavigate()
	useEffect(() => {
		const mount = async (): Promise<void> => {
			try {
				await dispatchThunk(logoutEverywhere())
				navigate('/')
			} catch (err) {
				Logger.error(err)
			}
		}
		mount()
	}, [])

	return null
}
