import { APIRequest } from '@lynx/client-core/src/api'
import { CancelFilledIcon, Checkbox, SaveIcon, Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { formatDateString } from '@lynx/core/src/date'
import { AccountProfile, UserPermissionsType } from '@lynx/core/src/interfaces'
import { Card } from 'components/elmstone/Card'
import React, { useState } from 'react'
import { Plan } from '@lynx/client-core/src/api/Billing/Billing'
import { Pill } from 'pages/Pricing/components/LynxPill/LynxPill'
import { SubscriptionsOverlay } from 'pages/Admin/routes/elmstone/Subscriptions/SubscriptionsOverlay'
import { OptionsContainer, getTableSearchParam, renderStorageElement, useSubscriptionPlan } from 'pages/Admin/routes/elmstone/Subscriptions/ListShared'
import { useDataWithPaginationAndSearch } from 'hooks/useDataWithPaginationAndSearch'
import { PlanFreeIcon, PlanProIcon } from 'components/PlanIcons/PlanIcons'
import { useDispatch } from 'react-redux'
import { showModal } from '@lynx/client-core/src/store/modal'
import { NoStripeAccountModal } from 'pages/Admin/routes/elmstone/Subscriptions/modals'
import css from './UsersList.module.scss'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { BoxLightCustomerServicePermission, permissions } from '@lynx/core/src/constants'

interface Props {
	plans: Plan[]
}

export const UsersList = ({ plans }: Props): React.ReactElement => {
	const { getPlanStorageLimit } = useSubscriptionPlan(plans, 'pro')
	const [editedStorage, setEditedStorage] = useState<number | null>(null)
	const dispatch = useDispatch()
	const { accessType } = useSelector((state: ApplicationState) => state.profile)
	const [isProvidersHeaderVisible, setIsProvidersHeaderVisible] = useState<boolean>(false)
	const isDev = accessType === 'dev'
	const fetchData = async ({
		start,
		length,
		searchText
	}: {
		start: number
		length: number
		searchText: string
	}): Promise<{ data: AccountProfile[]; totalCount: number }> => {
		const res = await APIRequest.User.getAll({ start, length, searchText })
		if (!res) {
			return { data: [], totalCount: 0 }
		}
		return { data: res.data.accounts, totalCount: res.data.totalCount }
	}

	const {
		state: { data, totalCount, rowsPerPage, page },
		isLoading,
		setIsLoading,
		editableRowId,
		setEditableRowId,
		getData,
		changeSearchText,
		changePage
	} = useDataWithPaginationAndSearch<AccountProfile>({ fetchData, initialPageSize: 30 })

	const handleToggleBeta = async (item: AccountProfile): Promise<void> => {
		let updated = false
		if (item.accessType === 'prod') {
			updated = await APIRequest.User.addBetaAccess(item.userId)
		} else if (item.accessType === 'beta') {
			updated = await APIRequest.User.removeBetaAccess(item.userId)
		}

		updated && getData(page, getTableSearchParam() || '', rowsPerPage)
	}

	const handleTogglePro = async (item: AccountProfile): Promise<void> => {
		let updated = false
		if (item.isPro) {
			updated = await APIRequest.User.removeProSubscription(item.userId)
		} else {
			updated = await APIRequest.User.addProSubscription(item.userId)
		}
		updated && getData(page, getTableSearchParam() || '', rowsPerPage)
	}

	const handleViewStripeSubscription = async (item: AccountProfile): Promise<void> => {
		const stripeAccount = await APIRequest.Billing.getAccountUrl('user', item.userId)
		if (stripeAccount?.url) {
			window.open(stripeAccount.url, '_blank')
			return
		}

		dispatch(showModal({ name: 'NoStripeAccountModal' }))
	}

	const updateUserStorageLimit = async (userId: string): Promise<void> => {
		if (editableRowId && editedStorage) {
			try {
				setIsLoading(true)
				await APIRequest.User.updateUserStorageLimit(userId, editedStorage)
				setEditableRowId('')
				getData(page, getTableSearchParam(), rowsPerPage)
			} finally {
				setIsLoading(false)
			}
		}
	}

	const onEditRowClicked = (item: AccountProfile): void => {
		setEditableRowId(editableRowId !== item.userId ? item.userId : '')
		setEditedStorage(item.storageLimitGB || getPlanStorageLimit(item.isPro))
	}

	const togglePermission = async (item: AccountProfile, permissionTypeId: UserPermissionsType): Promise<void> => {
		const toggleFunction = item.permissions.includes(permissionTypeId) ? APIRequest.User.deleteUserPermission : APIRequest.User.addUserPermission
		await toggleFunction(item.userId, permissionTypeId)

		getData(page, getTableSearchParam() || '', rowsPerPage)
	}

	const permissionColumn = isDev
		? [
				{
					valueKey: 'permissions',
					label: 'Permissions',
					customBodyRender: (item: AccountProfile): React.ReactNode => {
						const userPermissionLabels = item.permissions.map((perm) => permissions.find((p) => p.dbValue === perm)?.label)
						return userPermissionLabels.join(', ')
					}
				}
		  ]
		: []

	const columns: TableHeaders = [
		{ valueKey: 'email', label: 'Email' },
		...(isProvidersHeaderVisible ? [{ valueKey: 'providers', label: 'Auth Providers' }] : []),
		{
			valueKey: 'lastUpdated',
			label: 'Last Updated',
			customBodyRender: (item: AccountProfile): React.ReactNode => formatDateString(new Date(item.lastUpdated), 'DD/MM/YYYY')
		},
		{
			valueKey: 'accessType',
			label: 'Access Type',
			customBodyRender: (item: AccountProfile): React.ReactNode => {
				const devColor = 'black'
				const betaColor = 'grey'
				const prodColor = 'green'
				const color = item.accessType === 'prod' ? prodColor : item.accessType === 'beta' ? betaColor : devColor
				return <Pill backColor={color} foreColor="#ffffff" text={item.accessType} size="sm" bold />
			}
		},
		...permissionColumn,
		{
			valueKey: 'isPro',
			label: 'Plan',
			customBodyRender: (item: AccountProfile): React.ReactNode => (item.isPro ? <PlanProIcon /> : <PlanFreeIcon />)
		},
		{
			valueKey: 'storageLimitGB',
			label: 'Storage',
			customBodyRender: (item: AccountProfile) =>
				renderStorageElement({
					id: item.userId,
					storageLimitGB: item.storageLimitGB,
					editableRowId,
					setEditedStorage,
					isLoading,
					planStorageLimit: getPlanStorageLimit(item.isPro),
					editedStorage
				})
		},
		{
			valueKey: '__edit',
			label: '',
			customBodyRender: (item: AccountProfile) => (
				<OptionsContainer>
					{editableRowId === item.userId ? (
						<>
							<CancelFilledIcon onClick={(): boolean | void => !isLoading && setEditableRowId('')} />
							{item.storageLimitGB !== editedStorage && (
								<SaveIcon onClick={(): boolean | Promise<void> => !isLoading && updateUserStorageLimit(item.userId)} />
							)}
						</>
					) : (
						<SubscriptionsOverlay>
							<ul>
								{editableRowId !== item.userId && Boolean(item.isPro) && (
									<li
										onClick={(): void => {
											onEditRowClicked(item)
										}}
									>
										Edit
									</li>
								)}
								<li
									onClick={(): void => {
										handleToggleBeta(item)
									}}
								>
									Toggle Beta Access
								</li>
								<li
									onClick={(): void => {
										handleTogglePro(item)
									}}
								>
									Toggle Pro Subscription
								</li>
								<li
									onClick={(): void => {
										handleViewStripeSubscription(item)
									}}
								>
									View Stripe Account
								</li>
								{isDev &&
									permissions.map((perm) => (
										<li
											key={perm.dbValue + 'permission'}
											onClick={(): void => {
												togglePermission(item, perm.dbValue)
											}}
										>
											Toggle {BoxLightCustomerServicePermission.label} permission
										</li>
									))}
							</ul>
						</SubscriptionsOverlay>
					)}
				</OptionsContainer>
			)
		}
	]

	return (
		<>
			<Card>
				<div className={css.headersSwitchContainer}>
					<div className={css.title}>Headers</div>
					<div className={css.header}>
						<div>Auth Providers</div>
						<Checkbox checked={isProvidersHeaderVisible} onChange={(): void => setIsProvidersHeaderVisible((prev) => !prev)} />
					</div>
				</div>
				<Table
					pageSizes={[rowsPerPage]}
					onPageChange={changePage}
					totalCount={totalCount}
					onTableSearchChange={changeSearchText}
					title={'Users'}
					headers={columns}
					items={data}
					isCustomSearchRender={true}
				/>
			</Card>
			<NoStripeAccountModal />
		</>
	)
}
