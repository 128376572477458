import { boolean, object, string } from 'yup'

export const organisationSchema = object({
	orgName: string()
		.min(4, 'Should be between 4 and 64 characters long')
		.max(64, 'Should be between 4 and 64 characters long')
		.required('Organisation name is a required field'),
	addr: string()
		.min(4, 'Should be between 4 and 64 characters long')
		.max(64, 'Should be between 4 and 64 characters long')
		.required('Address is a required field'),
	postCode: string().required('Postcode is a required field'),
	termsAgree: boolean().oneOf([true], 'Terms must be agreed to')
})
