import { Modal } from '../../../components'
import { Upsell } from '../../../components/Upsell/Upsell'
import * as React from 'react'

interface UpsellModalProps {
	redirect?: { success_url: string; cancel_url: string }
}

export const UpsellModal = ({ redirect }: UpsellModalProps): React.ReactElement => {
	return (
		<Modal name="UpsellModal" variant="transparent" includeDefaultStyles={false}>
			<Upsell redirect={redirect} />
		</Modal>
	)
}
