import React, { useRef } from 'react'
import Styles from './index.module.css'

interface TooltipProps {
	className?: string
	['data-automation-id']?: string
	style?: React.CSSProperties
	['data-tooltip']?: string
	children?: React.ReactNode
	showOnHover?: boolean
	forceShow?: boolean
	hideOnMobileDevices?: boolean
	position?: 'top' | 'bottom' | 'right' | 'left'
}

export const Tooltip: React.FunctionComponent<TooltipProps> = ({
	children,
	'data-tooltip': dataTooltip,
	'data-automation-id': automationId,
	className,
	showOnHover,
	forceShow,
	hideOnMobileDevices,
	position,
	style
}) => {
	const hover = showOnHover ? 'hover' : ''
	const active = forceShow ? 'active' : ''
	const toolTipPosition = Styles[position || 'top']
	const classes = [
		className,
		Styles[hover],
		Styles[active],
		toolTipPosition,
		Styles['inner-container'],
		hideOnMobileDevices && Styles['hide-on-mobile-devices']
	]

	return (
		<div className={Styles['container']}>
			<div className={`${classes.join(' ')}`} style={style} data-automation-id={automationId} data-tooltip={dataTooltip}>
				{children}
			</div>
		</div>
	)
}
