import { Document } from '@lynx/client-core/src/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './AccountDeletion.module.scss'

export const AccountDeletion = (): React.ReactElement => {
	const { t } = useTranslation()

	return (
		<Document title={t('pages.pageTitles.accountDeletion')} description="Account Deletion" keywords="Privacy, Policy, LYNX" className={css['document']}>
			<h1 className={css['heading']}>{t('pages.accountDeletion.title')}</h1>
			<div className={css['container']}>
				<div>
					<p>
						{t('pages.accountDeletion.paragraph1')} {t('pages.accountDeletion.paragraph2')}
					</p>
					<div>
						<div>
							{t('pages.accountDeletion.paragraph21')} <a href="/profile">{t('pages.accountDeletion.paragraph21LinkTitle')}</a>
						</div>
						<div>{t('pages.accountDeletion.paragraph22')}</div>
						<div>{t('pages.accountDeletion.paragraph23')}</div>
					</div>
					<p>{t('pages.accountDeletion.paragraph3')}</p>
					<div>
						<div>{t('pages.accountDeletion.paragraph31')}</div>
						<div>{t('pages.accountDeletion.paragraph32')}</div>
						<div>{t('pages.accountDeletion.paragraph33')}</div>
					</div>
					<p>{t('pages.accountDeletion.paragraph4')}</p>
					<p>
						{t('pages.accountDeletion.paragraph5start')}{' '}
						<a href="https://support.clevertouch.com/support-cases">{t('pages.accountDeletion.paragraph5LinkTitle')}</a>{' '}
						{t('pages.accountDeletion.paragraph5end')}
					</p>
				</div>
			</div>
		</Document>
	)
}
