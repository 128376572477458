import { Reducer } from 'redux'
import { initialNotificationsState } from './initialState'
import {
	NotificationsActionTypes,
	NotificationsState,
	PUSH_NOTIFICATION_TOAST,
	SET_NOTIFICATIONS,
	DELETE_NOTIFICATION_TOAST,
	UPDATE_NOTIFICATIONS,
	DELETE_NOTIFICATION
} from './types'

const reducer: Reducer<NotificationsState, NotificationsActionTypes> = (state = initialNotificationsState, action: NotificationsActionTypes) => {
	switch (action.type) {
		case SET_NOTIFICATIONS: {
			return {
				...state,
				notificationsData: action.payload
			}
		}
		case UPDATE_NOTIFICATIONS: {
			return {
				...state,
				notificationsData: state.notificationsData.map((notification) => {
					const updatedNotification = action.payload.find(({ id }) => id === notification.id)
					if (updatedNotification) return { ...notification, ...updatedNotification }
					return notification
				})
			}
		}
		case PUSH_NOTIFICATION_TOAST: {
			return {
				...state,
				notificationToasts: [...state.notificationToasts, action.payload]
			}
		}
		case DELETE_NOTIFICATION_TOAST: {
			return {
				...state,
				notificationToasts: state.notificationToasts.filter(({ toastId }) => toastId !== action.payload.toastId)
			}
		}
		case DELETE_NOTIFICATION: {
			return {
				...state,
				notificationsData: state.notificationsData.filter(({ id }) => id !== action.payload.id)
			}
		}
		default: {
			return state
		}
	}
}

export { reducer as notificationsReducer }
