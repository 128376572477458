import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ModalContext, RelinkModalContextType, hideModal } from 'store/modal'

interface RelinkModalProps {
	onRelinkClicked: (provider: string) => void
}

export const RelinkModal = ({ onRelinkClicked }: RelinkModalProps): React.ReactElement | null => {
	const [provider, setProvider] = React.useState<string>('')
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleVisible = (context?: ModalContext): void => {
		if (context?.type !== RelinkModalContextType) {
			return
		}
		setProvider(context.provider)
	}

	return (
		<Modal name="RelinkModal" variant="yellow" hideCloseButton onVisible={handleVisible}>
			<ModalDefaultLayout
				title={t('server.drive.relink')}
				body={t('server.drive.error.description')}
				footer={
					<React.Fragment>
						<BasicButton onClick={(): ReturnType<typeof hideModal> => dispatch(hideModal())} variant="transparent">
							{t('components.modals.areYouSureModal.cancel')}
						</BasicButton>
						<BasicButton
							onClick={(): void => {
								dispatch(hideModal())
								onRelinkClicked(provider)
							}}
							variant="blue"
						>
							{t('server.drive.relink')}
						</BasicButton>
					</React.Fragment>
				}
			/>
		</Modal>
	)
}
