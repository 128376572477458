import { APIRequest } from '@lynx/client-core/src/api'
import { Version, VersionChange } from '@lynx/client-core/src/api/Logs'
import { Document } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { VersionsState } from './interfaces'
import Styles from './Versions.module.scss'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'

const order: { [key: string]: number } = {
	Feature: 0,
	Improvement: 1,
	Bug: 2,
	Translation: 3
}

const products: {
	name: string
	productName: string
	environment?: string
	version?: string
}[] = [
	{
		name: 'LYNX Cloud',
		productName: 'cloud'
	},
	{
		name: 'LYNX Whiteboard',
		productName: 'lynx-whiteboard',
		environment: 'releases',
		version: 'win64'
	},
	{
		name: 'LYNX Screen Recorder',
		productName: 'lynxsr',
		version: 'win32'
	}
]

const pluralize = (singular: string): string => {
	if (singular === 'BUG') {
		return 'BUG FIXES'
	}

	return `${singular}s`
}

export const Versions = (): React.ReactElement => {
	const [state, setState] = useState<VersionsState>({
		title: '',
		versions: [],
		productName: ''
	})
	const params = useParams<{ product: string; environment: string; os: string }>()
	const config = useSelector((state: ApplicationState) => state.config)

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const { product, os } = params
			let { environment } = params
			if (!product || !environment || !os) {
				return
			}

			if (config.ENVIRONMENT === 'beta') {
				environment = 'beta'
			}

			try {
				const changeLogsData = await APIRequest.Logs.getChangeLogs(product, environment === 'current' ? 'production' : environment, os)
				if (!changeLogsData) {
					return
				}

				const sortDelegate = (a: VersionChange, b: VersionChange): number => {
					return order[a.changeType] - order[b.changeType]
				}

				const { versions } = changeLogsData

				const v = versions.map((v) => {
					v.changes = v.changes.sort(sortDelegate)
					return v
				})

				const currentProduct = products.find((p) => p.productName === product)

				setState({ ...state, versions: v, productName: currentProduct?.productName || '', title: currentProduct?.name || '' })
			} catch (e) {
				Logger.error(e)
			}
		}

		mount()
	}, [])

	let currentLabel = ''

	const versionsComponent = state.versions.map((item: Version, key: number) => {
		return (
			<div key={key} className={Styles['block']}>
				{item.versionNumber ? (
					<div className={Styles['versionHeadContainer']}>
						<h2>
							{i18next.t('pages.moreDownloads.version')} {item.versionNumber}
						</h2>
						<div className={Styles['versionDate']}>{item.versionDate}</div>
					</div>
				) : (
					<div className={Styles['versionHeadContainer']}>
						<h2>{item.versionDate}</h2>
					</div>
				)}
				<div>
					<div>
						{item.changes.map((change: VersionChange, changeKey: number) => {
							let printLabel = false

							const changeType = change.changeType.toUpperCase()

							let changeColor = '#0060F0'
							switch (changeType) {
								case 'FEATURE': {
									changeColor = '#0060F0'
									break
								}
								case 'BUG': {
									changeColor = '#DF3333'
									break
								}
								case 'IMPROVEMENT': {
									changeColor = '#01863A'
									break
								}
							}

							if (changeType !== currentLabel) {
								currentLabel = changeType
								printLabel = true
							} else {
								printLabel = false
							}

							return (
								<div key={changeKey}>
									{printLabel && (
										<label
											className={Styles['versionTag']}
											style={{
												backgroundColor: `${changeColor}`
											}}
										>
											{pluralize(changeType)}
										</label>
									)}

									<div key={changeKey} className={Styles['entry']}>
										<div>{change.description}</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		)
	})

	const { productName, title } = state

	return (
		<Document title={i18next.t('pages.pageTitles.versions') + ' ' + title} description={'Versions ' + title} keywords={'Versions ' + title}>
			<div className={Styles['container']}>
				<h1>{i18next.t('pages.pageTitles.versions')}</h1>
				<div>
					<select
						aria-label="product"
						value={productName}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
							const product = products.find((p) => p.productName === e.target.value)
							if (product) {
								window.location.href = `/change-logs/${product.productName}/${product.environment || 'current'}/${product.version || 'win32'}`
							}
						}}
						className={Styles['select']}
					>
						{products.map((product) => {
							return (
								<option key={product.productName} value={product.productName}>
									{product.name}
								</option>
							)
						})}
					</select>
				</div>
				<div className={Styles['section']}>{versionsComponent}</div>
			</div>
		</Document>
	)
}
