import { APIRequest } from '@lynx/client-core/src/api'
import { AreYouSure, BasicButton, ListItem } from '@lynx/client-core/src/components'
import { AreYouSureModalContextType, showModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from 'store/profile'
import css from './AccountRemoval.module.scss'

export const AccountRemoval = (): React.ReactElement => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const handleDeleteAccountClicked = (): void => {
		dispatch(
			showModal({
				name: 'AreYouSure',
				context: {
					type: AreYouSureModalContextType,
					handleConfirm: async (): Promise<void> => {
						const res = await APIRequest.User.requestDelete()
						if (res?.error === false) {
							await dispatchThunk(logout())
							navigate('/goodbye')
						}
					},
					confirmText: t('pages.profile.delete'),
					confirmBody: t('pages.profile.deleteAccountBody')
				}
			})
		)
	}

	return (
		<ListItem header={t('pages.profile.accountRemoval')}>
			<div className={css['container']}>
				<div>{t('pages.profile.deletingYourAccount')}</div>
				<BasicButton variant="danger" onClick={handleDeleteAccountClicked}>
					{t('pages.profile.deleteAccount')}
				</BasicButton>
			</div>
			<AreYouSure />
		</ListItem>
	)
}
