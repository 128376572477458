import { Reducer } from 'redux'
import { initialBusyState } from './initialState'
import { ADD_BUSY, BusyActionTypes, BusyState, REMOVE_BUSY } from './types'

const reducer: Reducer<BusyState, BusyActionTypes> = (state: BusyState = initialBusyState, action: BusyActionTypes) => {
	switch (action.type) {
		case ADD_BUSY: {
			const { blocking } = action.payload
			let { blockers, nonblockers } = state

			blockers += blocking ? 1 : 0
			nonblockers += blocking ? 0 : 1

			return {
				...state,
				...{
					blockers,
					nonblockers,
					isBusy: blockers + nonblockers !== 0
				}
			}
		}
		case REMOVE_BUSY: {
			const { blocking } = action.payload
			let { blockers, nonblockers } = state

			blockers -= blocking ? 1 : 0
			nonblockers -= blocking ? 0 : 1

			if (blockers < 0 || nonblockers < 0) {
				console.error('Busy flag depth would be < 0')
			}
			return {
				...state,
				...{
					blockers: Math.max(blockers, 0),
					nonblockers: Math.max(nonblockers, 0),
					isBusy: blockers + nonblockers !== 0
				}
			}
		}
		default: {
			return state
		}
	}
}

export { reducer as busyReducer }
