import { FeedbackActionTypes, FeedbackView, CHANGE_TOKEN, CHANGE_VIEW, SET_FEEDBACK_CHANCE, HIDE_FEEDBACK, SHOW_FEEDBACK } from './types'

export const changeFeedbackToken = (token: number): FeedbackActionTypes => {
	return {
		type: CHANGE_TOKEN,
		payload: token
	}
}

export const changeFeedbackView = (view: FeedbackView): FeedbackActionTypes => {
	return {
		type: CHANGE_VIEW,
		payload: view
	}
}

export const showFeedback = (): FeedbackActionTypes => {
	return {
		type: SHOW_FEEDBACK
	}
}

export const hideFeedback = (): FeedbackActionTypes => {
	return {
		type: HIDE_FEEDBACK
	}
}

export const setFeedbackChance = (chance: number): FeedbackActionTypes => {
	return {
		type: SET_FEEDBACK_CHANCE,
		payload: chance
	}
}
