import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

export const RegReportByMonth = (): React.ReactElement => {
	const navigate = useNavigate()
	const [reportMonths, setReportMonths] = useState([])
	const columns = ['Date', 'Total', 'LogYear', 'LogMonth']

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const data = await APIRequest.Reports.getRegReportByMonth()
			setReportMonths(data.reportMonths)
		}
		mount()
	}, [])

	const goToDetails = (data: any): void => {
		navigate('/reports/reg-report-detail-month/' + data.LogYear + '/' + data.LogMonth)
	}

	return (
		<Document
			title="Registration Report By Month"
			description="Registration Report By Month"
			keywords="Registration Report By Month"
			disableOverflow={true}
		>
			<Card>
				<Table onRowClick={goToDetails} title={'Reg Report By Month'} headers={columns.map((v) => ({ label: v, valueKey: v }))} items={reportMonths} />
			</Card>
		</Document>
	)
}
