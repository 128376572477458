import React, { ReactElement, useState } from 'react'
import css from './Accordion.module.scss'

interface AccordionItemProps {
	title: string
	activeIndex?: number | null
	index?: number | undefined
	onTitleClick?: (index: number | undefined) => void
	children: React.ReactNode
}

export const AccordionItem: React.FC<AccordionItemProps> = ({ title, activeIndex, index, onTitleClick, children }) => {
	return (
		<div className={css['accordion-item']}>
			<div className={css['accordion-item-title']} onClick={(): void => onTitleClick?.(index)}>
				{activeIndex === index ? <div>&#9660;</div> : <div>&#9654;</div>}
				<div>{title}</div>
				<i className={[css['accordion-item-arrow'], activeIndex === index ? css['open'] : null].join(' ')} />
			</div>
			{activeIndex === index && <div className={css['accordion-item-content']}>{children}</div>}
		</div>
	)
}

interface AccordionProps {
	children: ReactElement<AccordionItemProps> | ReactElement<AccordionItemProps>[]
	forceActiveIndex?: number
}

const Accordion: React.FC<AccordionProps> = ({ children, forceActiveIndex }) => {
	const [activeIndex, setActiveIndex] = useState<number | undefined>(forceActiveIndex)

	const onTitleClick = (index: number | undefined): void => {
		setActiveIndex(index === activeIndex ? undefined : index)
	}

	return (
		<div className={css['accordion']}>
			{React.Children.map(children, (child, index) =>
				React.cloneElement(child, {
					activeIndex,
					index,
					onTitleClick
				})
			)}
		</div>
	)
}

export default Accordion
