import { BasicButton, Modal } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { hideModal, showModal } from 'store/modal'
import css from './OrganisationCreatedModal.module.scss'

export const OrganisationCreatedModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const organisation = useCurrentOrganisation()
	const { t } = useTranslation()

	const handleClose = (): void => {
		dispatch(hideModal())
	}

	if (!organisation) return null

	return (
		<Modal name="OrganisationCreatedModal" title={t('pages.organisation.organisationCreated')}>
			<div>
				<div className={css['textContainer']}>
					<p>
						{organisation.organisationName} {t('pages.organisations.organisationHasBeenCreated')}
					</p>
					<p>{t('pages.organisations.addADomain')}</p>
				</div>
				<div className={css['buttons']}>
					<BasicButton
						variant="grey"
						onClick={(): void => {
							handleClose()
						}}
					>
						{i18next.t('pages.organisation.skip')}
					</BasicButton>
					<BasicButton
						variant="blue"
						onClick={(): void => {
							dispatch(showModal({ name: 'ManageDomainsModal' }))
						}}
					>
						{i18next.t('pages.organisation.addDomain')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
