import {
	CancelFilledIcon,
	CheckCircleFilledIcon,
	ChevronDownIcon,
	CloseIcon,
	FileIcon,
	ErrorIcon,
	LoadingSpinner,
	TransitionSwitch
} from '@lynx/client-core/src/components'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from 'store'
import { deleteUploadingItem, updateUploadingItems, UploadingItem } from 'store/drive'
import css from './UploadStateModal.module.scss'
import { useTranslation } from 'react-i18next'

export const UploadStateModal = (): React.ReactElement | null => {
	const { drive: driveState, layout } = useSelector((state: ApplicationState) => state)
	const [isMinimized, setIsMinimized] = useState(false)
	const dispatch = useDispatch()
	const [mouseOverItemId, setMouseOverItemId] = useState<string | null>(null)
	const { t } = useTranslation()

	if (!driveState.uploadingItems.length) return null

	const getHeaderText = (): string => {
		const { failedCount, processingCount, uploadingCount, completedCount } = driveState.uploadingItems?.reduce(
			(acc, { status }: UploadingItem) => ({
				...acc,
				failedCount: acc.failedCount + (status === 'failed' ? 1 : 0),
				uploadingCount: acc.uploadingCount + (status === 'uploading' ? 1 : 0),
				completedCount: acc.completedCount + (status === 'completed' ? 1 : 0),
				processingCount: acc.processingCount + (status === 'processing' ? 1 : 0)
			}),
			{ uploadingCount: 0, processingCount: 0, failedCount: 0, completedCount: 0 }
		)

		if (uploadingCount || processingCount)
			return `${t('components.uploadModal.uploading')} ${uploadingCount || processingCount} ${t('components.uploadModal.files')}`
		if (!failedCount && completedCount) return t('components.uploadModal.uploadComplete')
		if (failedCount && !completedCount) return t('components.uploadModal.uploadFiled')
		if (failedCount && completedCount) return `${t('components.uploadModal.uploadComplete')} (${failedCount} ${t('components.uploadModal.failed')}`
		return `${t('components.uploadModal.uploading')}...`
	}

	const onItemDelete = (id: string): void => {
		dispatch(deleteUploadingItem(id))
	}

	const getFinalStatusIcon = ({ id, status, uploadCancelHandler }: UploadingItem): React.ReactElement | null => {
		if (status === 'processing' || status === 'uploading') {
			return <CancelFilledIcon className={css.closeIcon} onClick={(): void => uploadCancelHandler?.()} />
		}
		if (id === mouseOverItemId) return <CancelFilledIcon className={css.closeIcon} onClick={(): void => onItemDelete(id)} />
		if (status === 'completed') return <CheckCircleFilledIcon className={css.completedIcon} />
		if (status === 'failed') return <ErrorIcon className={css.failedIcon} />
		return null
	}

	const getPrefixIcon = (status: UploadingItem['status']): React.ReactElement | null =>
		status === 'processing' || status === 'uploading' ? <LoadingSpinner size="lg" className={css.loadingSpinner} /> : <FileIcon className={css.fileIcon} />

	const getStatus = (item: UploadingItem): string => {
		const { status, progress } = item
		if (status === 'processing') return `${t('components.uploadModal.processing')}...`
		if (status === 'uploading') return `${progress}% ${t('components.uploadModal.uploading')}...`
		if (status === 'completed') return t('components.uploadModal.completed')
		if (status === 'failed') return t('components.uploadModal.Failed')
		return ''
	}

	const clearItems = (): void => {
		dispatch(updateUploadingItems([]))
	}

	const minimiseIcon = (
		<ChevronDownIcon
			style={{ transform: `rotate(${isMinimized ? '-180deg' : '0'})` }}
			className={css.minimiseIcon}
			onClick={(): void => setIsMinimized((v: boolean): boolean => !v)}
		/>
	)

	const onMouseOverHandler = ({ id, status }: UploadingItem): void => {
		if (status === 'processing' || status === 'uploading') return
		setMouseOverItemId(id)
	}

	return (
		<div className={css.container} style={{ bottom: `${layout.footerHeight + 10}px` }}>
			<div className={css.header}>
				{getHeaderText()}
				<div className={css.iconContainer}>
					{minimiseIcon}
					<CloseIcon onClick={clearItems} />
				</div>
			</div>
			<div className={css.body} style={{ maxHeight: isMinimized ? '0px' : '500px' }}>
				{driveState.uploadingItems.map((item: UploadingItem) => (
					<div key={item.id} className={css.item}>
						<TransitionSwitch elKey={item.status}>{getPrefixIcon(item.status)}</TransitionSwitch>
						<div className={css.nameContainer}>
							<div className={css.name}>{item.name}</div>
							<div className={css.status}>{item.message || getStatus(item)}</div>
						</div>
						<div onMouseOver={(): void => onMouseOverHandler(item)} onMouseLeave={(): void => setMouseOverItemId(null)}>
							<TransitionSwitch elKey={item.id === mouseOverItemId ? item.id : item.status}>{getFinalStatusIcon(item)}</TransitionSwitch>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
