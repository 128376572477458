import { BasicButton, Input } from '@lynx/client-core/src/components'
import { ErrorMessage, Form, Formik } from 'formik'
import { oauthOrganisationSchema } from 'pages/Organisations/validationSchemas'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './EntraIdSetup.module.scss'

interface EntraIdProps {
	entraId: {
		tenantId: string
	}
	isEntraId: boolean
}

export interface EntraIdSetupProps {
	navigate: (section: string, props: EntraIdProps | null) => void
	context?: EntraIdProps
}

export const EntraIdSetup = (props: EntraIdSetupProps): React.ReactElement => {
	const { t } = useTranslation()

	const handleFormSubmitted = async ({ tenantId }: { tenantId: string }): Promise<void> => {
		props.navigate('EntraIdDomains', {
			entraId: {
				tenantId
			},
			isEntraId: true
		})
	}

	return (
		<Formik
			initialValues={{
				tenantId: ''
			}}
			validationSchema={oauthOrganisationSchema}
			onSubmit={(values, actions): void => {
				handleFormSubmitted(values)
				actions.setSubmitting(false)
			}}
			enableReinitialize={true}
		>
			{({ isSubmitting, handleChange, values, errors, touched, isValid, dirty }): React.ReactNode => (
				<Form className={css['container']}>
					<div>
						<label htmlFor="tenantId">Tenant Id</label>
						<Input
							value={values.tenantId}
							type="search"
							name="tenantId"
							onChange={handleChange}
							inError={(errors.tenantId && touched.tenantId) || false}
						/>
						<ErrorMessage name="tenant" component="span" />
					</div>
					<div className={css['buttons']}>
						<BasicButton
							onClick={(): void => {
								props.navigate('OrganisationTypeSelector', props.context || null)
							}}
						>
							{t('pages.organisations.createModal.back')}
						</BasicButton>
						<BasicButton
							variant="blue"
							disabled={!values.tenantId}
							onClick={(): void => {
								handleFormSubmitted(values)
							}}
						>
							{t('pages.organisations.createModal.next')}
						</BasicButton>
					</div>
				</Form>
			)}
		</Formik>
	)
}
