import { BasicButton, Input } from '@lynx/client-core/src/components'
import { ErrorMessage, Form, Formik } from 'formik'
import { oauthOrganisationSchema } from 'pages/Organisations/validationSchemas'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './OauthSetup.module.scss'

interface OauthProps {
	oauth: {
		clientId: string
		clientSecret: string
		authorizationUrl: string
		tokenUrl: string
		userUrl: string
	}
	isIserv: boolean
}

export interface OauthSetupProps {
	navigate: (section: string, props: OauthProps | null) => void
	context?: OauthProps
}

export const OauthSetup = (props: OauthSetupProps): React.ReactElement => {
	const { t } = useTranslation()

	const handleFormSubmitted = async ({
		authorizationUrl,
		tokenUrl,
		userUrl,
		clientId,
		clientSecret
	}: {
		authorizationUrl: string
		tokenUrl: string
		userUrl: string
		clientId: string
		clientSecret: string
	}): Promise<void> => {
		props.navigate('OauthDomains', {
			oauth: {
				clientId,
				clientSecret,
				authorizationUrl,
				tokenUrl,
				userUrl
			},
			isIserv: true
		})
	}

	return (
		<Formik
			initialValues={{
				authorizationUrl: '',
				tokenUrl: '',
				userUrl: '',
				clientId: '',
				clientSecret: ''
			}}
			validationSchema={oauthOrganisationSchema}
			onSubmit={(values, actions): void => {
				handleFormSubmitted(values)
				actions.setSubmitting(false)
			}}
			enableReinitialize={true}
		>
			{({ isSubmitting, handleChange, values, errors, touched, isValid, dirty }): React.ReactNode => (
				<Form className={css['container']}>
					<div>
						<label htmlFor="authorizationUrl">Authorization Url</label>
						<Input
							autoFocus={true}
							value={values.authorizationUrl}
							type="search"
							name="authorizationUrl"
							onChange={handleChange}
							inError={(errors.authorizationUrl && touched.authorizationUrl) || false}
							placeholder="https://..."
						/>
						<ErrorMessage name="authorizationUrl" component="span" />
					</div>
					<div>
						<label htmlFor="tokenUrl">Token Url</label>
						<Input
							value={values.tokenUrl}
							type="search"
							name="tokenUrl"
							onChange={handleChange}
							inError={(errors.tokenUrl && touched.tokenUrl) || false}
							placeholder="https://..."
						/>
						<ErrorMessage name="tokenUrl" component="span" />
					</div>
					<div>
						<label htmlFor="userUrl">User Url</label>
						<Input
							value={values.userUrl}
							type="search"
							name="userUrl"
							onChange={handleChange}
							inError={(errors.userUrl && touched.userUrl) || false}
							placeholder="https://..."
							autoComplete="off"
						/>
						<ErrorMessage name="userUrl" component="span" />
					</div>
					<div>
						<label htmlFor="clientId">Client Id</label>
						<Input
							value={values.clientId}
							type="search"
							name="clientId"
							onChange={handleChange}
							inError={(errors.clientId && touched.clientId) || false}
						/>
						<ErrorMessage name="clientId" component="span" />
					</div>
					<div>
						<label htmlFor="clientSecret">Client Secret</label>
						<Input
							value={values.clientSecret}
							type="password"
							name="clientSecret"
							onChange={handleChange}
							inError={(errors.clientSecret && touched.clientSecret) || false}
							autoComplete="new-password"
						/>
						<ErrorMessage name="clientSecret" component="span" />
					</div>
					<div className={css['buttons']}>
						<BasicButton
							onClick={(): void => {
								props.navigate('SamlIntroduction', props.context || null)
							}}
						>
							{t('pages.organisations.createModal.back')}
						</BasicButton>
						<BasicButton variant={isValid ? 'blue' : 'transparent'} disabled={isSubmitting || !dirty} type="submit">
							{t('pages.organisations.save')}
						</BasicButton>
					</div>
				</Form>
			)}
		</Formik>
	)
}
