import { ArrowBackIcon, MailOutlineIcon } from '../../../../Icons'
import { Section } from '../../../../Sections'
import { Button, TextField } from '../../../../'
import { useThunkDispatch } from '../../../../../hooks/useThunkDispatch'
import i18next from 'i18next'
import { APIRequest } from '../../../../../api'
import * as qs from 'query-string'
import * as React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { login } from '../../../../../store/profile'
import { QueryParams } from './interfaces'
import { NextCloudSectionProps } from './interfaces/NextCloudSectionProps'
import Styles from './NextCloudSection.module.css'

export const NextCloudSection = ({ navigate: navigateSection, context }: NextCloudSectionProps): React.ReactElement => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatchThunk = useThunkDispatch()
	const [state, setState] = useState({
		email: '',
		password: '',
		queryParams: qs.parse(location.search) as any,
		error: '',
		nextStep: false,
		privacyChecked: context.privacyChecked,
		showInfo: false
	})

	const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const email = event.target.value
		setState({ ...state, email })
	}

	const nextCloudLogin = async (pollToken: string, endpoint: string, authUrl: string): Promise<void> => {
		window.open(authUrl, '_blank')
		let retry = 0
		const interval = setInterval(async (): Promise<void> => {
			++retry
			if (retry === 15) {
				clearInterval(interval)
			}
			const result = await APIRequest.User.NextCloudPoll(pollToken, endpoint)
			if (!result.error) {
				clearInterval(interval)
				await dispatchThunk(login(null))

				const { redirect, qt, code, qrCode } = state.queryParams

				if (redirect?.length) {
					if (redirect.includes('http')) {
						window.location.href = redirect
						return
					}
					navigate(redirect)
					return
				}

				if (qt?.length) {
					const queryObj: QueryParams = {
						header: false,
						footer: false,
						rememberMe: !state.privacyChecked,
						code
					}

					if (qrCode) {
						queryObj.remote = true
						queryObj.qrCode = qrCode
					}

					const qrParams = qs.stringify(queryObj)
					navigate(`/authenticated?${qrParams}`)
					return
				}

				if (location.pathname === '/auth/login') {
					navigate('/')
				}
			}
		}, 5000)
	}

	const handleGoBack = (): void => {
		navigateSection('SignInSection')
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault()
		event.stopPropagation()
		const username = (event.currentTarget.elements.namedItem('email') as HTMLInputElement).value || state.email

		if (!username) {
			return
		}

		const result = await APIRequest.User.check(username)
		const { data } = result

		if (!result.error) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const { user, type } = data
			switch (type) {
				case 'nextcloud':
					await nextCloudLogin(user.poll.token, user.poll.endpoint, user.login)
					return
			}
		}
		setState({ ...state, error: 'User is not a NextCloud User' })
	}

	const { error } = state

	return (
		<Section
			side="right"
			title={
				<div className={Styles['title-container']}>
					<div className={Styles['back-arrow']} onClick={handleGoBack}>
						<ArrowBackIcon />
					</div>
					<div>{i18next.t('pages.login.nextcloudSignIn')}</div>
				</div>
			}
			className={Styles['nextcloud-section']}
		>
			<form className={Styles['nextcloud-form']} onSubmit={handleSubmit}>
				{error && <p className={Styles['error-message']}>{error}</p>}
				<div className={Styles['inputfields']}>
					<TextField
						type="text"
						name="email"
						data-automation-id="SignInSection-Email"
						placeholder={i18next.t('pages.login.email')}
						className={Styles['textfield']}
						onChange={handleEmailChanged}
						adornment={<MailOutlineIcon className={Styles.inputIcon} />}
					/>
				</div>
				<Button type="submit" className={Styles['signin-button-ready']} elevation={true}>
					{i18next.t('pages.login.signIn')}
				</Button>
			</form>
		</Section>
	)
}
