import { APIRequest } from '@lynx/client-core/src/api'
import { AddCircleIcon, BasicButton, ListItem } from '@lynx/client-core/src/components'
import { showModal } from '@lynx/client-core/src/store/modal'
import update from 'immutability-helper'
import { OrganisationCreateModal } from 'pages/Organisations/modals/OrganisationCreateModal'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Card } from './Card'
import { ItemTypes } from './ItemTypes'
import css from './Organisations.module.scss'
import { Item, OrganisationsCardBody } from './OrganisationsCardBody'

export const Organisations = (): React.ReactElement => {
	const [cards, setCards] = useState<Item[]>([])
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		fetchOrganisations()
	}, [])

	const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
		setCards((prevCards: Item[]) =>
			update(prevCards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevCards[dragIndex] as Item]
				]
			})
		)
	}, [])

	const renderCard = useCallback((card: Item, index: number) => {
		return (
			<Card key={card.id} index={index} id={card.id} moveCard={moveCard} itemType={ItemTypes.ORGANISATIONS}>
				<OrganisationsCardBody organisation={card} onDeleted={fetchOrganisations} />
			</Card>
		)
	}, [])

	const fetchOrganisations = async (): Promise<void> => {
		const organisations = await APIRequest.Organisations.getOrganisationsByUser()
		if (!organisations?.data) return

		const organisationCards = organisations.data.map(({ organisationName, id, users, avatarUrl }, index: number) => {
			return {
				id: String(index),
				name: organisationName,
				organisationId: id,
				userCount: users.length,
				avatarUrl
			}
		})
		setCards(organisationCards)
	}

	const handleAddOrganisationClicked = (): void => {
		dispatch(showModal({ name: 'OrganisationCreateModal' }))
	}

	return (
		<ListItem header={t('pages.profile.organisations')}>
			<div className={css['buttons']}>
				<BasicButton variant="blue" icon={AddCircleIcon} onClick={handleAddOrganisationClicked}>
					{t('pages.profile.addOrganisation')}
				</BasicButton>
			</div>
			<div className={css['container']}>{cards.map((card, i) => renderCard(card, i))}</div>
			<OrganisationCreateModal />
		</ListItem>
	)
}
