import { useEffect, useState } from 'react'

export interface Flag {
	name: string
	iso: string
	code: string
	height: number
	flag: number
	url: string
}

export const useFlags = (isoCodes?: string[]): { flags: Flag[] } => {
	const [flags, setFlags] = useState<Flag[]>([])
	const isos = isoCodes || []

	useEffect(() => {
		if (isos.length === 0) {
			setFlags(flagsSource)
		} else {
			setFlags(flagsSource.filter((flag) => isos.includes(flag.iso)))
		}
	}, [])

	return { flags }
}

export const flagsSource: Flag[] = [
	{
		name: 'Afghanistan (‫افغانستان‬‎)',
		iso: 'AF',
		code: '93',
		height: 13,
		flag: 2,
		url: '/assets/icons/flags/af.svg'
	},
	{ name: 'Albania (Shqipëri)', iso: 'AL', code: '355', height: 14, flag: 5, url: '/assets/icons/flags/al.svg' },
	{
		name: 'Algeria (‫الجزائر‬‎)',
		iso: 'DZ',
		code: '213',
		height: 13,
		flag: 59,
		url: '/assets/icons/flags/dz.svg'
	},
	{ name: 'American Samoa', iso: 'AS', code: '1684', height: 10, flag: 9, url: '/assets/icons/flags/as.svg' },
	{ name: 'Andorra', iso: 'AD', code: '376', height: 14, flag: 0, url: '/assets/icons/flags/ad.svg' },
	{ name: 'Angola', iso: 'AO', code: '244', height: 13, flag: 7, url: '/assets/icons/flags/ao.svg' },
	{ name: 'Anguilla', iso: 'AI', code: '1264', height: 10, flag: 4, url: '/assets/icons/flags/ai.svg' },
	{ name: 'Antigua and Barbuda', iso: 'AG', code: '1268', height: 13, flag: 3, url: '/assets/icons/flags/ag.svg' },
	{ name: 'Argentina', iso: 'AR', code: '54', height: 13, flag: 8, url: '/assets/icons/flags/ar.svg' },
	{ name: 'Armenia (Հայաստան)', iso: 'AM', code: '374', height: 10, flag: 6, url: '/assets/icons/flags/am.svg' },
	{ name: 'Aruba', iso: 'AW', code: '297', height: 13, flag: 12, url: '/assets/icons/flags/aw.svg' },
	{ name: 'Australia', iso: 'AU', code: '61', height: 10, flag: 11, url: '/assets/icons/flags/au.svg' },
	{ name: 'Austria (Österreich)', iso: 'AT', code: '43', height: 13, flag: 10, url: '/assets/icons/flags/at.svg' },
	{
		name: 'Azerbaijan (Azərbaycan)',
		iso: 'AZ',
		code: '994',
		height: 10,
		flag: 14,
		url: '/assets/icons/flags/az.svg'
	},
	{ name: 'Bahamas', iso: 'BS', code: '1242', height: 10, flag: 30, url: '/assets/icons/flags/bs.svg' },
	{
		name: 'Bahrain (‫البحرين‬‎)',
		iso: 'BH',
		code: '973',
		height: 12,
		flag: 21,
		url: '/assets/icons/flags/bh.svg'
	},
	{
		name: 'Bangladesh (বাংলাদেশ)',
		iso: 'BD',
		code: '880',
		height: 12,
		flag: 17,
		url: '/assets/icons/flags/bd.svg'
	},
	{ name: 'Barbados', iso: 'BB', code: '1246', height: 13, flag: 16, url: '/assets/icons/flags/bb.svg' },
	{ name: 'Belarus (Беларусь)', iso: 'BY', code: '375', height: 10, flag: 33, url: '/assets/icons/flags/by.svg' },
	{ name: 'Belgium (België)', iso: 'BE', code: '32', height: 17, flag: 18, url: '/assets/icons/flags/be.svg' },
	{ name: 'Belize', iso: 'BZ', code: '501', height: 13, flag: 34, url: '/assets/icons/flags/bz.svg' },
	{ name: 'Benin (Bénin)', iso: 'BJ', code: '229', height: 13, flag: 23, url: '/assets/icons/flags/bj.svg' },
	{ name: 'Bermuda', iso: 'BM', code: '1441', height: 10, flag: 25, url: '/assets/icons/flags/bm.svg' },
	{ name: 'Bhutan (འབྲུག)', iso: 'BT', code: '975', height: 13, flag: 31, url: '/assets/icons/flags/bt.svg' },
	{ name: 'Bolivia', iso: 'BO', code: '591', height: 14, flag: 27, url: '/assets/icons/flags/bo.svg' },
	{
		name: 'Bosnia and Herzegovina',
		iso: 'BA',
		code: '387',
		height: 10,
		flag: 15,
		url: '/assets/icons/flags/ba.svg'
	},
	{ name: 'Botswana', iso: 'BW', code: '267', height: 13, flag: 32, url: '/assets/icons/flags/bw.svg' },
	{ name: 'Brazil (Brasil)', iso: 'BR', code: '55', height: 14, flag: 29, url: '/assets/icons/flags/br.svg' },
	{
		name: 'British Indian Ocean Territory',
		iso: 'IO',
		code: '246',
		height: 10,
		flag: 101,
		url: '/assets/icons/flags/io.svg'
	},
	{
		name: 'British Virgin Islands',
		iso: 'VG',
		code: '1284',
		height: 10,
		flag: 231,
		url: '/assets/icons/flags/vg.svg'
	},
	{ name: 'Brunei', iso: 'BN', code: '673', height: 10, flag: 26, url: '/assets/icons/flags/bn.svg' },
	{ name: 'Bulgaria (България)', iso: 'BG', code: '359', height: 12, flag: 20, url: '/assets/icons/flags/bg.svg' },
	{ name: 'Burkina Faso', iso: 'BF', code: '226', height: 13, flag: 19, url: '/assets/icons/flags/bf.svg' },
	{ name: 'Burundi (Uburundi)', iso: 'BI', code: '257', height: 12, flag: 22, url: '/assets/icons/flags/bi.svg' },
	{ name: 'Cambodia (កម្ពុជា)', iso: 'KH', code: '855', height: 13, flag: 112, url: '/assets/icons/flags/kh.svg' },
	{ name: 'Cameroon (Cameroun)', iso: 'CM', code: '237', height: 13, flag: 44, url: '/assets/icons/flags/cm.svg' },
	{ name: 'Canada', iso: 'CA', code: '1', height: 10, flag: 35, url: '/assets/icons/flags/ca.svg' },
	{
		name: 'Cape Verde (Kabu Verdi)',
		iso: 'CV',
		code: '238',
		height: 12,
		flag: 49,
		url: '/assets/icons/flags/cv.svg'
	},
	{
		name: 'Caribbean Netherlands',
		iso: 'BQ',
		code: '599',
		height: 13,
		flag: 28,
		url: '/assets/icons/flags/bq.svg'
	},
	{ name: 'Cayman Islands', iso: 'KY', code: '1345', height: 10, flag: 119, url: '/assets/icons/flags/ky.svg' },
	{
		name: 'Central African Republic',
		iso: 'CF',
		code: '236',
		height: 13,
		flag: 38,
		url: '/assets/icons/flags/cf.svg'
	},
	{ name: 'Chad (Tchad)', iso: 'TD', code: '235', height: 13, flag: 209, url: '/assets/icons/flags/td.svg' },
	{ name: 'Chile', iso: 'CL', code: '56', height: 13, flag: 43, url: '/assets/icons/flags/cl.svg' },
	{ name: 'China (中国)', iso: 'CN', code: '86', height: 13, flag: 45, url: '/assets/icons/flags/cn.svg' },
	{ name: 'Christmas Island', iso: 'CX', code: '61', height: 10, flag: 51, url: '/assets/icons/flags/cx.svg' },
	{
		name: 'Cocos (Keeling) Islands',
		iso: 'CC',
		code: '61',
		height: 10,
		flag: 36,
		url: '/assets/icons/flags/cc.svg'
	},
	{ name: 'Colombia', iso: 'CO', code: '57', height: 13, flag: 46, url: '/assets/icons/flags/co.svg' },
	{
		name: 'Comoros',
		iso: 'KM',
		code: '269',
		height: 12,
		flag: 114,
		url: '/assets/icons/flags/km.svg'
	},
	{
		name: 'Congo (DRC)',
		iso: 'CD',
		code: '243',
		height: 15,
		flag: 37,
		url: '/assets/icons/flags/cd.svg'
	},
	{
		name: 'Congo (Republic)',
		iso: 'CG',
		code: '242',
		height: 13,
		flag: 39,
		url: '/assets/icons/flags/cg.svg'
	},
	{ name: 'Cook Islands', iso: 'CK', code: '682', height: 10, flag: 42, url: '/assets/icons/flags/ck.svg' },
	{ name: 'Costa Rica', iso: 'CR', code: '506', height: 12, flag: 47, url: '/assets/icons/flags/cr.svg' },
	{ name: 'Côte d’Ivoire', iso: 'CI', code: '225', height: 13, flag: 41, url: '/assets/icons/flags/ci.svg' },
	{ name: 'Croatia (Hrvatska)', iso: 'HR', code: '385', height: 10, flag: 93, url: '/assets/icons/flags/hr.svg' },
	{ name: 'Cuba', iso: 'CU', code: '53', height: 10, flag: 48, url: '/assets/icons/flags/cu.svg' },
	{ name: 'Curaçao', iso: 'CW', code: '599', height: 13, flag: 50, url: '/assets/icons/flags/cw.svg' },
	{ name: 'Cyprus (Κύπρος)', iso: 'CY', code: '357', height: 13, flag: 52, url: '/assets/icons/flags/cy.svg' },
	{
		name: 'Czech Republic',
		iso: 'CZ',
		code: '420',
		height: 13,
		flag: 53,
		url: '/assets/icons/flags/cz.svg'
	},
	{ name: 'Denmark (Danmark)', iso: 'DK', code: '45', height: 15, flag: 56, url: '/assets/icons/flags/dk.svg' },
	{ name: 'Djibouti', iso: 'DJ', code: '253', height: 13, flag: 55, url: '/assets/icons/flags/dj.svg' },
	{ name: 'Dominica', iso: 'DM', code: '1767', height: 10, flag: 57, url: '/assets/icons/flags/dm.svg' },
	{
		name: 'Dominican Republic',
		iso: 'DO',
		code: '1',
		height: 13,
		flag: 58,
		url: '/assets/icons/flags/do.svg'
	},
	{ name: 'Ecuador', iso: 'EC', code: '593', height: 13, flag: 60, url: '/assets/icons/flags/ec.svg' },
	{ name: 'Egypt (‫مصر‬‎)', iso: 'EG', code: '20', height: 13, flag: 62, url: '/assets/icons/flags/eg.svg' },
	{ name: 'El Salvador', iso: 'SV', code: '503', height: 11, flag: 204, url: '/assets/icons/flags/sv.svg' },
	{
		name: 'Equatorial Guinea',
		iso: 'GQ',
		code: '240',
		height: 13,
		flag: 85,
		url: '/assets/icons/flags/gq.svg'
	},
	{ name: 'Eritrea', iso: 'ER', code: '291', height: 10, flag: 64, url: '/assets/icons/flags/er.svg' },
	{ name: 'Estonia (Eesti)', iso: 'EE', code: '372', height: 13, flag: 61, url: '/assets/icons/flags/ee.svg' },
	{ name: 'Ethiopia', iso: 'ET', code: '251', height: 10, flag: 66, url: '/assets/icons/flags/et.svg' },
	{
		name: 'Falkland Islands ',
		iso: 'FK',
		code: '500',
		height: 10,
		flag: 69,
		url: '/assets/icons/flags/fk.svg'
	},
	{
		name: 'Faroe Islands (Føroyar)',
		iso: 'FO',
		code: '298',
		height: 15,
		flag: 71,
		url: '/assets/icons/flags/fo.svg'
	},
	{ name: 'Fiji', iso: 'FJ', code: '679', height: 10, flag: 68, url: '/assets/icons/flags/fj.svg' },
	{ name: 'Finland (Suomi)', iso: 'FI', code: '358', height: 12, flag: 67, url: '/assets/icons/flags/fi.svg' },
	{ name: 'France', iso: 'FR', code: '33', height: 13, flag: 72, url: '/assets/icons/flags/fr.svg' },
	{
		name: 'French Guiana',
		iso: 'GF',
		code: '594',
		height: 13,
		flag: 77,
		url: '/assets/icons/flags/gf.svg'
	},
	{
		name: 'French Polynesia',
		iso: 'PF',
		code: '689',
		height: 13,
		flag: 170,
		url: '/assets/icons/flags/pf.svg'
	},
	{ name: 'Gabon', iso: 'GA', code: '241', height: 15, flag: 73, url: '/assets/icons/flags/ga.svg' },
	{ name: 'Gambia', iso: 'GM', code: '220', height: 13, flag: 82, url: '/assets/icons/flags/gm.svg' },
	{
		name: 'Georgia (საქართველო)',
		iso: 'GE',
		code: '995',
		height: 13,
		flag: 76,
		url: '/assets/icons/flags/ge.svg'
	},
	{
		name: 'Germany (Deutschland)',
		iso: 'DE',
		code: '49',
		height: 12,
		flag: 54,
		url: '/assets/icons/flags/de.svg'
	},
	{ name: 'Ghana (Gaana)', iso: 'GH', code: '233', height: 13, flag: 79, url: '/assets/icons/flags/gh.svg' },
	{ name: 'Gibraltar', iso: 'GI', code: '350', height: 10, flag: 80, url: '/assets/icons/flags/gi.svg' },
	{ name: 'Greece (Ελλάδα)', iso: 'GR', code: '30', height: 13, flag: 86, url: '/assets/icons/flags/gr.svg' },
	{
		name: 'Greenland (Kalaallit Nunaat)',
		iso: 'GL',
		code: '299',
		height: 13,
		flag: 81,
		url: '/assets/icons/flags/gl.svg'
	},
	{ name: 'Grenada', iso: 'GD', code: '1473', height: 12, flag: 75, url: '/assets/icons/flags/gd.svg' },
	{ name: 'Guadeloupe', iso: 'GP', code: '590', height: 13, flag: 84, url: '/assets/icons/flags/gp.svg' },
	{ name: 'Guam', iso: 'GU', code: '1671', height: 11, flag: 88, url: '/assets/icons/flags/gu.svg' },
	{ name: 'Guatemala', iso: 'GT', code: '502', height: 13, flag: 87, url: '/assets/icons/flags/gt.svg' },
	{ name: 'Guernsey', iso: 'GG', code: '44', height: 13, flag: 78, url: '/assets/icons/flags/gg.svg' },
	{ name: 'Guinea (Guinée)', iso: 'GN', code: '224', height: 13, flag: 83, url: '/assets/icons/flags/gn.svg' },
	{
		name: 'Guinea-Bissau (Guiné Bissau)',
		iso: 'GW',
		code: '245',
		height: 10,
		flag: 89,
		url: '/assets/icons/flags/gw.svg'
	},
	{ name: 'Guyana', iso: 'GY', code: '592', height: 12, flag: 90, url: '/assets/icons/flags/gy.svg' },
	{ name: 'Haiti', iso: 'HT', code: '509', height: 12, flag: 94, url: '/assets/icons/flags/ht.svg' },
	{ name: 'Honduras', iso: 'HN', code: '504', height: 10, flag: 92, url: '/assets/icons/flags/hn.svg' },
	{ name: 'Hong Kong (香港)', iso: 'HK', code: '852', height: 13, flag: 91, url: '/assets/icons/flags/hk.svg' },
	{
		name: 'Hungary (Magyarország)',
		iso: 'HU',
		code: '36',
		height: 10,
		flag: 95,
		url: '/assets/icons/flags/hu.svg'
	},
	{ name: 'Iceland (Ísland)', iso: 'IS', code: '354', height: 14, flag: 104, url: '/assets/icons/flags/is.svg' },
	{ name: 'India (भारत)', iso: 'IN', code: '91', height: 13, flag: 100, url: '/assets/icons/flags/in.svg' },
	{ name: 'Indonesia', iso: 'ID', code: '62', height: 13, flag: 96, url: '/assets/icons/flags/id.svg' },
	{ name: 'Iran (‫ایران‬‎)', iso: 'IR', code: '98', height: 11, flag: 103, url: '/assets/icons/flags/ir.svg' },
	{ name: 'Iraq (‫العراق‬‎)', iso: 'IQ', code: '964', height: 13, flag: 102, url: '/assets/icons/flags/iq.svg' },
	{ name: 'Ireland', iso: 'IE', code: '353', height: 10, flag: 97, url: '/assets/icons/flags/ie.svg' },
	{ name: 'Isle of Man', iso: 'IM', code: '44', height: 10, flag: 99, url: '/assets/icons/flags/im.svg' },
	{ name: 'Israel (‫ישראל‬‎)', iso: 'IL', code: '972', height: 15, flag: 98, url: '/assets/icons/flags/il.svg' },
	{ name: 'Italy (Italia)', iso: 'IT', code: '39', height: 13, flag: 105, url: '/assets/icons/flags/it.svg' },
	{ name: 'Jamaica', iso: 'JM', code: '1876', height: 10, flag: 107, url: '/assets/icons/flags/jm.svg' },
	{ name: 'Japan (日本)', iso: 'JP', code: '81', height: 13, flag: 109, url: '/assets/icons/flags/jp.svg' },
	{ name: 'Jersey', iso: 'JE', code: '44', height: 12, flag: 106, url: '/assets/icons/flags/je.svg' },
	{ name: 'Jordan (‫الأردن‬‎)', iso: 'JO', code: '962', height: 10, flag: 108, url: '/assets/icons/flags/jo.svg' },
	{
		name: 'Kazakhstan (Казахстан)',
		iso: 'KZ',
		code: '7',
		height: 10,
		flag: 120,
		url: '/assets/icons/flags/kz.svg'
	},
	{ name: 'Kenya', iso: 'KE', code: '254', height: 13, flag: 110, url: '/assets/icons/flags/ke.svg' },
	{ name: 'Kiribati', iso: 'KI', code: '686', height: 10, flag: 113, url: '/assets/icons/flags/ki.svg' },
	{ name: 'Kosovo', iso: 'XK', code: '383', height: 14, flag: 237, url: '/assets/icons/flags/xk.svg' },
	{ name: 'Kuwait (‫الكويت‬‎)', iso: 'KW', code: '965', height: 10, flag: 118, url: '/assets/icons/flags/kw.svg' },
	{
		name: 'Kyrgyzstan (Кыргызстан)',
		iso: 'KG',
		code: '996',
		height: 12,
		flag: 111,
		url: '/assets/icons/flags/kg.svg'
	},
	{ name: 'Laos (ລາວ)', iso: 'LA', code: '856', height: 13, flag: 121, url: '/assets/icons/flags/la.svg' },
	{ name: 'Latvia (Latvija)', iso: 'LV', code: '371', height: 10, flag: 130, url: '/assets/icons/flags/lv.svg' },
	{ name: 'Lebanon (‫لبنان‬‎)', iso: 'LB', code: '961', height: 13, flag: 122, url: '/assets/icons/flags/lb.svg' },
	{ name: 'Lesotho', iso: 'LS', code: '266', height: 13, flag: 127, url: '/assets/icons/flags/ls.svg' },
	{ name: 'Liberia', iso: 'LR', code: '231', height: 11, flag: 126, url: '/assets/icons/flags/lr.svg' },
	{ name: 'Libya (‫ليبيا‬‎)', iso: 'LY', code: '218', height: 10, flag: 131, url: '/assets/icons/flags/ly.svg' },
	{ name: 'Liechtenstein', iso: 'LI', code: '423', height: 12, flag: 124, url: '/assets/icons/flags/li.svg' },
	{
		name: 'Lithuania (Lietuva)',
		iso: 'LT',
		code: '370',
		height: 12,
		flag: 128,
		url: '/assets/icons/flags/lt.svg'
	},
	{ name: 'Luxembourg', iso: 'LU', code: '352', height: 12, flag: 129, url: '/assets/icons/flags/lu.svg' },
	{ name: 'Macau (澳門)', iso: 'MO', code: '853', height: 13, flag: 143, url: '/assets/icons/flags/mo.svg' },
	{
		name: 'Macedonia',
		iso: 'MK',
		code: '389',
		height: 10,
		flag: 139,
		url: '/assets/icons/flags/mk.svg'
	},
	{
		name: 'Madagascar',
		iso: 'MG',
		code: '261',
		height: 13,
		flag: 137,
		url: '/assets/icons/flags/mg.svg'
	},
	{ name: 'Malawi', iso: 'MW', code: '265', height: 13, flag: 151, url: '/assets/icons/flags/mw.svg' },
	{ name: 'Malaysia', iso: 'MY', code: '60', height: 10, flag: 153, url: '/assets/icons/flags/my.svg' },
	{ name: 'Maldives', iso: 'MV', code: '960', height: 13, flag: 150, url: '/assets/icons/flags/mv.svg' },
	{ name: 'Mali', iso: 'ML', code: '223', height: 13, flag: 140, url: '/assets/icons/flags/ml.svg' },
	{ name: 'Malta', iso: 'MT', code: '356', height: 13, flag: 148, url: '/assets/icons/flags/mt.svg' },
	{ name: 'Marshall Islands', iso: 'MH', code: '692', height: 11, flag: 138, url: '/assets/icons/flags/mh.svg' },
	{ name: 'Martinique', iso: 'MQ', code: '596', height: 13, flag: 145, url: '/assets/icons/flags/mq.svg' },
	{
		name: 'Mauritania (‫موريتانيا‬‎)',
		iso: 'MR',
		code: '222',
		height: 13,
		flag: 146,
		url: '/assets/icons/flags/mr.svg'
	},
	{ name: 'Mauritius (Moris)', iso: 'MU', code: '230', height: 13, flag: 149, url: '/assets/icons/flags/mu.svg' },
	{ name: 'Mayotte', iso: 'YT', code: '262', height: 13, flag: 239, url: '/assets/icons/flags/yt.svg' },
	{ name: 'Mexico (México)', iso: 'MX', code: '52', height: 11, flag: 152, url: '/assets/icons/flags/mx.svg' },
	{ name: 'Micronesia', iso: 'FM', code: '691', height: 11, flag: 70, url: '/assets/icons/flags/fm.svg' },
	{
		name: 'Moldova (Republica Moldova)',
		iso: 'MD',
		code: '373',
		height: 10,
		flag: 134,
		url: '/assets/icons/flags/md.svg'
	},
	{ name: 'Monaco', iso: 'MC', code: '377', height: 16, flag: 133, url: '/assets/icons/flags/mc.svg' },
	{ name: 'Mongolia (Монгол)', iso: 'MN', code: '976', height: 10, flag: 142, url: '/assets/icons/flags/mn.svg' },
	{
		name: 'Montenegro (Crna Gora)',
		iso: 'ME',
		code: '382',
		height: 10,
		flag: 135,
		url: '/assets/icons/flags/me.svg'
	},
	{ name: 'Montserrat', iso: 'MS', code: '1664', height: 10, flag: 147, url: '/assets/icons/flags/ms.svg' },
	{
		name: 'Morocco (‫المغرب‬‎)',
		iso: 'MA',
		code: '212',
		height: 13,
		flag: 132,
		url: '/assets/icons/flags/ma.svg'
	},
	{
		name: 'Mozambique (Moçambique)',
		iso: 'MZ',
		code: '258',
		height: 13,
		flag: 154,
		url: '/assets/icons/flags/mz.svg'
	},
	{
		name: 'Myanmar (Burma) (မြန်မာ)',
		iso: 'MM',
		code: '95',
		height: 13,
		flag: 141,
		url: '/assets/icons/flags/mm.svg'
	},
	{ name: 'Namibia (Namibië)', iso: 'NA', code: '264', height: 13, flag: 155, url: '/assets/icons/flags/na.svg' },
	{ name: 'Nauru', iso: 'NR', code: '674', height: 10, flag: 164, url: '/assets/icons/flags/nr.svg' },
	{ name: 'Nepal (नेपाल)', iso: 'NP', code: '977', height: 24, flag: 163, url: '/assets/icons/flags/np.svg' },
	{
		name: 'Netherlands',
		iso: 'NL',
		code: '31',
		height: 13,
		flag: 161,
		url: '/assets/icons/flags/nl.svg'
	},
	{
		name: 'New Caledonia',
		iso: 'NC',
		code: '687',
		height: 10,
		flag: 156,
		url: '/assets/icons/flags/nc.svg'
	},
	{ name: 'New Zealand', iso: 'NZ', code: '64', height: 10, flag: 166, url: '/assets/icons/flags/nz.svg' },
	{ name: 'Nicaragua', iso: 'NI', code: '505', height: 12, flag: 160, url: '/assets/icons/flags/ni.svg' },
	{ name: 'Niger (Nijar)', iso: 'NE', code: '227', height: 17, flag: 157, url: '/assets/icons/flags/ne.svg' },
	{ name: 'Nigeria', iso: 'NG', code: '234', height: 10, flag: 159, url: '/assets/icons/flags/ng.svg' },
	{ name: 'Niue', iso: 'NU', code: '683', height: 10, flag: 165, url: '/assets/icons/flags/nu.svg' },
	{ name: 'Norfolk Island', iso: 'NF', code: '672', height: 10, flag: 158, url: '/assets/icons/flags/nf.svg' },
	{
		name: 'North Korea',
		iso: 'KP',
		code: '850',
		height: 10,
		flag: 116,
		url: '/assets/icons/flags/kp.svg'
	},
	{
		name: 'Northern Mariana Islands',
		iso: 'MP',
		code: '1670',
		height: 10,
		flag: 144,
		url: '/assets/icons/flags/mp.svg'
	},
	{ name: 'Norway (Norge)', iso: 'NO', code: '47', height: 15, flag: 162, url: '/assets/icons/flags/no.svg' },
	{ name: 'Oman (‫عُمان‬‎)', iso: 'OM', code: '968', height: 10, flag: 167, url: '/assets/icons/flags/om.svg' },
	{
		name: 'Pakistan (‫پاکستان‬‎)',
		iso: 'PK',
		code: '92',
		height: 13,
		flag: 173,
		url: '/assets/icons/flags/pk.svg'
	},
	{ name: 'Palau', iso: 'PW', code: '680', height: 13, flag: 179, url: '/assets/icons/flags/pw.svg' },
	{
		name: 'Palestine (‫فلسطين‬‎)',
		iso: 'PS',
		code: '970',
		height: 10,
		flag: 177,
		url: '/assets/icons/flags/ps.svg'
	},
	{ name: 'Panama (Panamá)', iso: 'PA', code: '507', height: 13, flag: 168, url: '/assets/icons/flags/pa.svg' },
	{ name: 'Papua New Guinea', iso: 'PG', code: '675', height: 15, flag: 171, url: '/assets/icons/flags/pg.svg' },
	{ name: 'Paraguay', iso: 'PY', code: '595', height: 11, flag: 180, url: '/assets/icons/flags/py.svg' },
	{ name: 'Peru (Perú)', iso: 'PE', code: '51', height: 13, flag: 169, url: '/assets/icons/flags/pe.svg' },
	{ name: 'Philippines', iso: 'PH', code: '63', height: 10, flag: 172, url: '/assets/icons/flags/ph.svg' },
	{ name: 'Poland (Polska)', iso: 'PL', code: '48', height: 13, flag: 174, url: '/assets/icons/flags/pl.svg' },
	{ name: 'Portugal', iso: 'PT', code: '351', height: 13, flag: 178, url: '/assets/icons/flags/pt.svg' },
	{ name: 'Puerto Rico', iso: 'PR', code: '1', height: 13, flag: 176, url: '/assets/icons/flags/pr.svg' },
	{ name: 'Qatar (‫قطر‬‎)', iso: 'QA', code: '974', height: 8, flag: 181, url: '/assets/icons/flags/qa.svg' },
	{
		name: 'Réunion (La Réunion)',
		iso: 'RE',
		code: '262',
		height: 13,
		flag: 182,
		url: '/assets/icons/flags/re.svg'
	},
	{ name: 'Romania (România)', iso: 'RO', code: '40', height: 13, flag: 183, url: '/assets/icons/flags/ro.svg' },
	{ name: 'Russia (Россия)', iso: 'RU', code: '7', height: 13, flag: 185, url: '/assets/icons/flags/ru.svg' },
	{ name: 'Rwanda', iso: 'RW', code: '250', height: 13, flag: 186, url: '/assets/icons/flags/rw.svg' },
	{
		name: 'Saint Barthélemy',
		iso: 'BL',
		code: '590',
		height: 13,
		flag: 24,
		url: '/assets/icons/flags/bl.svg'
	},
	{ name: 'Saint Helena', iso: 'SH', code: '290', height: 10, flag: 193, url: '/assets/icons/flags/sh.svg' },
	{
		name: 'Saint Kitts and Nevis',
		iso: 'KN',
		code: '1869',
		height: 13,
		flag: 115,
		url: '/assets/icons/flags/kn.svg'
	},
	{ name: 'Saint Lucia', iso: 'LC', code: '1758', height: 10, flag: 123, url: '/assets/icons/flags/lc.svg' },
	{
		name: 'Saint Martin',
		iso: 'MF',
		code: '590',
		height: 13,
		flag: 136,
		url: '/assets/icons/flags/mf.svg'
	},
	{
		name: 'Saint Pierre and Miquelon',
		iso: 'PM',
		code: '508',
		height: 13,
		flag: 175,
		url: '/assets/icons/flags/pm.svg'
	},
	{
		name: 'Saint Vincent and the Grenadines',
		iso: 'VC',
		code: '1784',
		height: 13,
		flag: 229,
		url: '/assets/icons/flags/vc.svg'
	},
	{ name: 'Samoa', iso: 'WS', code: '685', height: 10, flag: 236, url: '/assets/icons/flags/ws.svg' },
	{ name: 'San Marino', iso: 'SM', code: '378', height: 15, flag: 198, url: '/assets/icons/flags/sm.svg' },
	{
		name: 'São Tomé and Príncipe',
		iso: 'ST',
		code: '239',
		height: 10,
		flag: 203,
		url: '/assets/icons/flags/st.svg'
	},
	{
		name: 'Saudi Arabia',
		iso: 'SA',
		code: '966',
		height: 13,
		flag: 187,
		url: '/assets/icons/flags/sa.svg'
	},
	{ name: 'Senegal (Sénégal)', iso: 'SN', code: '221', height: 13, flag: 199, url: '/assets/icons/flags/sn.svg' },
	{ name: 'Serbia (Србија)', iso: 'RS', code: '381', height: 13, flag: 184, url: '/assets/icons/flags/rs.svg' },
	{ name: 'Seychelles', iso: 'SC', code: '248', height: 10, flag: 189, url: '/assets/icons/flags/sc.svg' },
	{ name: 'Sierra Leone', iso: 'SL', code: '232', height: 13, flag: 197, url: '/assets/icons/flags/sl.svg' },
	{ name: 'Singapore', iso: 'SG', code: '65', height: 13, flag: 192, url: '/assets/icons/flags/sg.svg' },
	{ name: 'Sint Maarten', iso: 'SX', code: '1721', height: 13, flag: 205, url: '/assets/icons/flags/sx.svg' },
	{
		name: 'Slovakia (Slovensko)',
		iso: 'SK',
		code: '421',
		height: 13,
		flag: 196,
		url: '/assets/icons/flags/sk.svg'
	},
	{
		name: 'Slovenia (Slovenija)',
		iso: 'SI',
		code: '386',
		height: 10,
		flag: 194,
		url: '/assets/icons/flags/si.svg'
	},
	{ name: 'Solomon Islands', iso: 'SB', code: '677', height: 10, flag: 188, url: '/assets/icons/flags/sb.svg' },
	{
		name: 'Somalia (Soomaaliya)',
		iso: 'SO',
		code: '252',
		height: 13,
		flag: 200,
		url: '/assets/icons/flags/so.svg'
	},
	{ name: 'South Africa', iso: 'ZA', code: '27', height: 13, flag: 240, url: '/assets/icons/flags/za.svg' },
	{
		name: 'South Korea (대한민국)',
		iso: 'KR',
		code: '82',
		height: 13,
		flag: 117,
		url: '/assets/icons/flags/kr.svg'
	},
	{
		name: 'South Sudan (‫جنوب السودان‬‎)',
		iso: 'SS',
		code: '211',
		height: 10,
		flag: 202,
		url: '/assets/icons/flags/ss.svg'
	},
	{ name: 'Spain (España)', iso: 'ES', code: '34', height: 13, flag: 65, url: '/assets/icons/flags/es.svg' },
	{
		name: 'Sri Lanka (ශ්‍රී ලංකාව)',
		iso: 'LK',
		code: '94',
		height: 10,
		flag: 125,
		url: '/assets/icons/flags/lk.svg'
	},
	{ name: 'Sudan (‫السودان‬‎)', iso: 'SD', code: '249', height: 10, flag: 190, url: '/assets/icons/flags/sd.svg' },
	{ name: 'Suriname', iso: 'SR', code: '597', height: 13, flag: 201, url: '/assets/icons/flags/sr.svg' },
	{
		name: 'Svalbard and Jan Mayen',
		iso: 'SJ',
		code: '47',
		height: 15,
		flag: 195,
		url: '/assets/icons/flags/sj.svg'
	},
	{ name: 'Swaziland', iso: 'SZ', code: '268', height: 13, flag: 207, url: '/assets/icons/flags/sz.svg' },
	{ name: 'Sweden (Sverige)', iso: 'SE', code: '46', height: 13, flag: 191, url: '/assets/icons/flags/se.svg' },
	{
		name: 'Switzerland (Schweiz)',
		iso: 'CH',
		code: '41',
		height: 20,
		flag: 40,
		url: '/assets/icons/flags/ch.svg'
	},
	{ name: 'Syria (‫سوريا‬‎)', iso: 'SY', code: '963', height: 13, flag: 206, url: '/assets/icons/flags/sy.svg' },
	{ name: 'Taiwan (台灣)', iso: 'TW', code: '886', height: 13, flag: 221, url: '/assets/icons/flags/tw.svg' },
	{ name: 'Tajikistan', iso: 'TJ', code: '992', height: 10, flag: 212, url: '/assets/icons/flags/tj.svg' },
	{ name: 'Tanzania', iso: 'TZ', code: '255', height: 13, flag: 222, url: '/assets/icons/flags/tz.svg' },
	{ name: 'Thailand (ไทย)', iso: 'TH', code: '66', height: 13, flag: 211, url: '/assets/icons/flags/th.svg' },
	{ name: 'Timor-Leste', iso: 'TL', code: '670', height: 10, flag: 214, url: '/assets/icons/flags/tl.svg' },
	{ name: 'Togo', iso: 'TG', code: '228', height: 12, flag: 210, url: '/assets/icons/flags/tg.svg' },
	{ name: 'Tokelau', iso: 'TK', code: '690', height: 10, flag: 213, url: '/assets/icons/flags/tk.svg' },
	{ name: 'Tonga', iso: 'TO', code: '676', height: 10, flag: 217, url: '/assets/icons/flags/to.svg' },
	{
		name: 'Trinidad and Tobago',
		iso: 'TT',
		code: '1868',
		height: 12,
		flag: 219,
		url: '/assets/icons/flags/tt.svg'
	},
	{ name: 'Tunisia (‫تونس‬‎)', iso: 'TN', code: '216', height: 13, flag: 216, url: '/assets/icons/flags/tn.svg' },
	{ name: 'Turkey (Türkiye)', iso: 'TR', code: '90', height: 13, flag: 218, url: '/assets/icons/flags/tr.svg' },
	{ name: 'Turkmenistan', iso: 'TM', code: '993', height: 13, flag: 215, url: '/assets/icons/flags/tm.svg' },
	{
		name: 'Turks and Caicos Islands',
		iso: 'TC',
		code: '1649',
		height: 10,
		flag: 208,
		url: '/assets/icons/flags/tc.svg'
	},
	{ name: 'Tuvalu', iso: 'TV', code: '688', height: 10, flag: 220, url: '/assets/icons/flags/tv.svg' },
	{
		name: 'U.S. Virgin Islands',
		iso: 'VI',
		code: '1340',
		height: 13,
		flag: 232,
		url: '/assets/icons/flags/vi.svg'
	},
	{ name: 'Uganda', iso: 'UG', code: '256', height: 13, flag: 224, url: '/assets/icons/flags/ug.svg' },
	{ name: 'Ukraine (Україна)', iso: 'UA', code: '380', height: 13, flag: 223, url: '/assets/icons/flags/ua.svg' },
	{
		name: 'United Arab Emirates',
		iso: 'AE',
		code: '971',
		height: 10,
		flag: 1,
		url: '/assets/icons/flags/ae.svg'
	},
	{ name: 'United Kingdom', iso: 'GB', code: '44', height: 10, flag: 74, url: '/assets/icons/flags/gb.svg' },
	{ name: 'United States', iso: 'US', code: '1', height: 11, flag: 225, url: '/assets/icons/flags/us.svg' },
	{ name: 'Uruguay', iso: 'UY', code: '598', height: 13, flag: 226, url: '/assets/icons/flags/uy.svg' },
	{
		name: 'Uzbekistan (Oʻzbekiston)',
		iso: 'UZ',
		code: '998',
		height: 10,
		flag: 227,
		url: '/assets/icons/flags/uz.svg'
	},
	{ name: 'Vanuatu', iso: 'VU', code: '678', height: 12, flag: 234, url: '/assets/icons/flags/vu.svg' },
	{
		name: 'Vatican City (Città del Vaticano)',
		iso: 'VA',
		code: '39',
		height: 20,
		flag: 228,
		url: '/assets/icons/flags/va.svg'
	},
	{ name: 'Venezuela', iso: 'VE', code: '58', height: 13, flag: 230, url: '/assets/icons/flags/ve.svg' },
	{ name: 'Vietnam (Việt Nam)', iso: 'VN', code: '84', height: 13, flag: 233, url: '/assets/icons/flags/vn.svg' },
	{ name: 'Wallis and Futuna', iso: 'WF', code: '681', height: 13, flag: 235, url: '/assets/icons/flags/wf.svg' },
	{
		name: 'Western Sahara (‫الصحراء الغربية‬‎)',
		iso: 'EH',
		code: '212',
		height: 10,
		flag: 63,
		url: '/assets/icons/flags/eh.svg'
	},
	{ name: 'Yemen (‫اليمن‬‎)', iso: 'YE', code: '967', height: 13, flag: 238, url: '/assets/icons/flags/ye.svg' },
	{ name: 'Zambia', iso: 'ZM', code: '260', height: 13, flag: 241, url: '/assets/icons/flags/zm.svg' },
	{ name: 'Zimbabwe', iso: 'ZW', code: '263', height: 10, flag: 242, url: '/assets/icons/flags/zw.svg' },
	{ name: 'Åland Islands', iso: 'AX', code: '358', height: 13, flag: 13, url: '/assets/icons/flags/ax.svg' }
]
