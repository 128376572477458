import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from '../../hooks'
import { useKeyPress } from '../../hooks/useKeyPress'
import { ApplicationState } from '../../store'
import { hideModal } from '../../store/modal'
import { CloseIcon } from '../Icons'
import { ModalBaseProps } from './interfaces'
import css from './Modal.module.scss'
import { ModalPortal } from './ModalPortal'

const ModalBase = (props: ModalBaseProps): React.ReactElement | null => {
	const dispatch = useDispatch()
	const modal = useSelector((state: ApplicationState) => state.modal)

	const [dragging, setDragging] = useState(false)
	const { name: oldName } = usePrevious(modal) || {}

	useEffect(() => {
		if (props.uncontrolled) {
			return
		}
		const { name: newName, context } = modal
		const { name: modalName } = props

		if (oldName !== newName && modalName === newName) {
			props.onVisible && props.onVisible(context)
		}

		if (oldName === modalName && modalName !== newName) {
			props.onHidden && props.onHidden(context)
		}
	}, [oldName, modal])

	const hideModalHandler = (): void => {
		const { uncontrolled } = props

		if (dragging) {
			return
		}

		if (modal.sticky) {
			return
		}

		if (uncontrolled) {
			props.onHidden && props.onHidden()
			return
		}

		dispatch(hideModal())
	}

	const handleClose = (): void => {
		if (props.uncontrolled) {
			props.onHidden && props.onHidden()
			return
		}

		dispatch(hideModal())
	}

	const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.stopPropagation()
		setDragging(true)
	}

	const handleMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.stopPropagation()
		setDragging(false)
	}

	useKeyPress('Escape', hideModalHandler)

	const { includeDefaultStyles = true, name, title, className, uncontrolled, hideCloseButton = false, variant = 'blue' } = props
	if (name !== modal.name && !uncontrolled) return null

	const classNames = [css['container'], className].filter((c) => c)
	const innerContainerClasses = [props.innerContainerClasses || css['inner-container']]
	variant && innerContainerClasses.push(css[variant])
	includeDefaultStyles && innerContainerClasses.push(css['inner-container--defaultStyles'])

	return (
		<ModalPortal>
			<div className={classNames.join(' ')} onMouseUp={handleMouseUp} onMouseDown={hideModalHandler}>
				<div
					className={innerContainerClasses.join(' ')}
					onMouseDown={handleMouseDown}
					onMouseUp={handleMouseUp}
					onDragExit={handleMouseUp}
					onDragEnd={handleMouseUp}
					onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
						e.stopPropagation()
					}}
				>
					{!hideCloseButton && (
						<div className={css.closeIconContainer}>
							<CloseIcon onClick={handleClose} className={css.icon} />
						</div>
					)}
					{title && <React.Fragment>{title && <div className={css['title']}>{title}</div>}</React.Fragment>}
					{props.children}
				</div>
			</div>
		</ModalPortal>
	)
}

export default ModalBase
