import { LoadingSpinner } from '../'
import React, { useEffect } from 'react'

import Styles from './Button.module.css'

interface ButtonProps {
	className?: string
	['data-automation-id']?: string
	style?: React.CSSProperties
	variant?: 'blue' | 'grey' | 'danger'
	elevation?: boolean
	disabled?: boolean
	isLoading?: boolean
	children?: React.ReactNode
	type?: 'button' | 'reset' | 'submit'
	onSubmit?: (event: React.FormEvent<HTMLButtonElement>) => void
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const Button: React.FunctionComponent<ButtonProps> = ({
	children,
	className,
	'data-automation-id': automationId,
	disabled,
	isLoading,
	elevation,
	onClick,
	onSubmit,
	style,
	type,
	variant
}) => {
	const classNames = [Styles['container'], className, variant && Styles[variant]].filter((c) => c)
	elevation && classNames.push(Styles['elevation'])
	type = type || 'button'

	useEffect(() => {
		// eslint-disable-next-line no-console
		console.warn('Button is deprecated. Please use BasicButton instead.')
	}, [])

	const clickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
		if (isLoading || disabled) return
		onClick && onClick(e)
	}

	return (
		<div className={classNames.join(' ')} style={style}>
			<button className={'button-element'} data-automation-id={automationId} type={type} disabled={disabled} onSubmit={onSubmit} onClick={clickHandler}>
				{isLoading ? <LoadingSpinner /> : children}
			</button>
		</div>
	)
}
