import { useEffect } from 'react'

export const usePopupBeforeExit = (isOn = true): void => {
	useEffect(() => {
		if (isOn) {
			window.addEventListener('beforeunload', handleNotSavedStateBeforeUnload)
			return () => {
				window.removeEventListener('beforeunload', handleNotSavedStateBeforeUnload)
			}
		}
	}, [isOn])

	const handleNotSavedStateBeforeUnload = (event: BeforeUnloadEvent): void => {
		event.preventDefault()
		event.returnValue = ''
	}
}
