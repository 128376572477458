import { BaseCall } from '../BaseCall'
import { APIConfig } from '../interfaces'

export class Translator extends BaseCall {
	public apply = async (fileName: string, userId: string) => {
		await this.client.post('/translator', {
			fileName, userId
		})
	}

	public delete = async (fileName: string) => {
		await this.client.post('/translator/delete', {
			fileName
		})
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
