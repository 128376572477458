import { APIRequest } from '@lynx/client-core/src/api'
import {
	BasicButton,
	BrowserChromeIcon,
	BrowserEdgeIcon,
	BrowserFirefoxIcon,
	BrowserSafariIcon,
	CheckCircleOutlineIcon,
	ComputerIcon,
	LanguageIcon,
	LynxWhiteboardLogo,
	PhoneIphoneIcon,
	PinDropIcon
} from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { objKeys, timeSince } from '@lynx/client-core/src/utils'
import { formatDateOrString } from '@lynx/core/src/date'
import { UserSessionsInterface } from '@lynx/core/src/interfaces/UserSessions'
import { upperFirstLetter } from '@lynx/core/src/string'
import { UAParser } from '@lynx/core/src/utils/uaParser'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeviceGroup, DeviceGrouped } from './SessionList.types'
import css from './SessionsList.module.scss'

const BrowserIcons = {
	Chrome: BrowserChromeIcon,
	Edge: BrowserEdgeIcon,
	Safari: BrowserSafariIcon,
	Firefox: BrowserFirefoxIcon
}

const getDeviceIcon = (deviceType: DeviceGroup['deviceType']): JSX.Element => {
	switch (deviceType) {
		case 'whiteboard':
			return <LynxWhiteboardLogo />
		case 'mobile':
			return <PhoneIphoneIcon />
		case 'desktop':
			return <ComputerIcon />
		case 'unknown':
			return <div />
	}
}

export const SessionsList = (): React.ReactElement => {
	const [sessions, setSessions] = useState<UserSessionsInterface[]>([])
	const [currentSessionRef, setCurrentSessionRef] = useState<string | null>(null)
	const { t } = useTranslation()

	const fetchUserSessions = async (): Promise<void> => {
		const userSessionsData = await APIRequest.UserSessions.getUserSessions()
		setSessions(userSessionsData)

		const currentSessionRef = await APIRequest.UserSessions.getCurrentSessionRef()
		setCurrentSessionRef(currentSessionRef)
	}

	useEffect(() => {
		try {
			fetchUserSessions()
		} catch (err) {
			Logger.error(err)
		}
	}, [])

	const onDeviceSignOut = async (sessionId: number): Promise<void> => {
		await APIRequest.UserSessions.invalidateSession(sessionId)
		await fetchUserSessions()
	}

	const singleItem = ({ createdAt, userAgent, updatedAt, tokenRefId, id, ip, location }: UserSessionsInterface): React.ReactElement => {
		const locationParsed = (typeof location === 'string' && JSON.parse(location)) || null
		const browser = userAgent ? new UAParser(userAgent).getBrowser() : null
		const BrowserIcon = (browser && BrowserIcons[browser.name as keyof typeof BrowserIcons]) || LanguageIcon
		const singleSessionClasses = [css.singleSession]
		!tokenRefId && singleSessionClasses.push(css.signedOutSingleSession)
		const isCurrentSession = currentSessionRef && currentSessionRef === tokenRefId

		return (
			<div className={singleSessionClasses.join(' ')} key={id}>
				{!tokenRefId && <div className={css.signedOutContainer}>{t('components.sessionList.signedOut')}</div>}
				<div className={css.firstRow}>
					{browser && (
						<div className={css.browserContainer}>
							{BrowserIcon && <BrowserIcon className={css.browserIcon} />} {browser.name} {browser.version}
						</div>
					)}
					{locationParsed && (
						<div className={css.locationContainer}>
							<PinDropIcon className={css.locationIcon} />
							{`${locationParsed.city}, ${locationParsed.country_name}`}
						</div>
					)}
				</div>

				{!(updatedAt === createdAt) && !isCurrentSession && (
					<div className={css.lastUpdatedContainer}>
						{timeSince(new Date(updatedAt))} {t('components.sessionList.ago')}
					</div>
				)}
				<div className={css.bottomContainer}>
					<div className={css.firstSignInContainer}>
						<div className={css.titleContainer}>
							<div className={css.title}>{t('components.sessionList.firstSignIn')}:</div>{' '}
							<div>{formatDateOrString(createdAt, 'DD MMM YYYY hh:mm:ss')}</div>
						</div>
					</div>
					{!isCurrentSession && tokenRefId ? (
						<BasicButton onClick={(): Promise<void> => onDeviceSignOut(id)}>{t('components.profile.signOut')}</BasicButton>
					) : (
						<div />
					)}
					{isCurrentSession && (
						<div className={css.currentSessionContainer}>
							<CheckCircleOutlineIcon className={css.currentSessionIcon} />
							{t('components.sessionList.currentSession')}
						</div>
					)}
				</div>
			</div>
		)
	}

	const groupedByDevice = sessions.reduce((acc: DeviceGrouped, userSession: UserSessionsInterface): DeviceGrouped => {
		const { userAgent, deviceType, screenWidth } = userSession
		const osName = userAgent && new UAParser(userAgent).getOS().name
		const deviceSizeType = screenWidth ? (screenWidth > 768 ? 'desktop' : 'mobile') : null
		const categoryType = deviceType || deviceSizeType || 'unknown'

		const newCategory: DeviceGroup = {
			os: osName || null,
			deviceType: categoryType,
			sessions: []
		}
		const deviceCategory = acc[categoryType] || newCategory
		deviceCategory.sessions.push(userSession)
		return {
			...acc,
			[categoryType]: deviceCategory
		}
	}, {} as DeviceGrouped)

	const categoryKeys = objKeys(groupedByDevice)

	const sortByMostRecentLogin = (x: UserSessionsInterface, y: UserSessionsInterface): number =>
		new Date(y.updatedAt).getTime() - new Date(x.updatedAt).getTime()

	return (
		<div className={css.container}>
			{categoryKeys.map((key: keyof typeof groupedByDevice) => {
				const { sessions, deviceType } = groupedByDevice[key]
				const count = sessions.length > 1 ? `(${sessions.length})` : ''
				const sessionText = sessions.length === 1 ? t('components.sessionList.session') : t('components.sessionList.sessions')
				const sortedSessions = sessions.sort(sortByMostRecentLogin)
				return (
					<div className={css.sessionGroupContainer} key={key}>
						<div className={css.groupHeader}>
							{getDeviceIcon(deviceType)}
							<div className={css.groupTitle}>
								{upperFirstLetter(String(key))} {sessionText} <span>{count}</span>
							</div>
						</div>
						<div className={css.sessionList}>{sortedSessions.map(singleItem)}</div>
					</div>
				)
			})}
		</div>
	)
}
