import { APIRequest } from '@lynx/client-core/src/api'
import { Document } from '@lynx/client-core/src/components'
import { NotificationsList } from 'components/Notifications/NotificationsList'
import { useThunkDispatch } from 'hooks'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { getNotifications, setNotifications } from 'store/notifications'
import css from './Notifications.module.css'
import { useTranslation } from 'react-i18next'

export const Notifications = (): React.ReactElement => {
	const notificationsText = i18next.t('components.notifications.notifications')
	const { t } = useTranslation()
	const { notificationsData } = useSelector((state: ApplicationState) => state.notifications)
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const [itemsCount, setItemsCount] = useState(12)
	const showMoreItemsCount = 25

	useEffect(() => {
		const mount = async (): Promise<void> => {
			await dispatchThunk(getNotifications(itemsCount))
		}
		mount()
	}, [itemsCount])

	const onClearAllClick = async (): Promise<void> => {
		await APIRequest.Notifications.deleteAllNotifications()
		dispatch(setNotifications([]))
	}

	return (
		<Document title={notificationsText} description={notificationsText} keywords={notificationsText} auth={true}>
			<div>
				<div className={css.container}>
					<div className={css.headerContainer}>
						<span className={css.textHeader}>{t('components.notifications.notifications')}</span>
						{Boolean(notificationsData.length) && (
							<span onClick={onClearAllClick} className={css.textClearAll}>
								{t('components.notifications.clearAll')}
							</span>
						)}
					</div>
					<div className={css.listContainer}>
						<NotificationsList />
					</div>
					{notificationsData.length > 0 && itemsCount !== showMoreItemsCount && (
						<div
							onClick={(): void => {
								setItemsCount(showMoreItemsCount)
							}}
							className={css.showMoreContainer}
						>
							{t('components.notifications.showMore')}
						</div>
					)}
				</div>
			</div>
		</Document>
	)
}
