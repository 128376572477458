interface ErrorObject {
	data: string;
}

class LoginError extends Error {
	response: ErrorObject
	constructor(message: ErrorObject) {
		super('Wrong username or password')
		this.response = message
	}
}

export default LoginError