import { Dropdown } from '@lynx/client-core/src/components'
import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ApplicationState } from 'store'
import css from './OrganisationDropdown.module.scss'

interface OrganisationDropdownProps {
	onItemClicked: (organisation: OrganisationRedux) => void
}

export const OrganisationDropdown = ({ onItemClicked }: OrganisationDropdownProps): React.ReactElement | null => {
	const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationRedux | null>()
	const organisations = useSelector((state: ApplicationState) => state.organisations)
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)

	useEffect(() => {
		if (queryParams.get('organisationId')) {
			const organisation = organisations.organisations.find((organisation) => String(organisation.id) === queryParams.get('organisationId'))
			if (organisation) {
				setSelectedOrganisation(organisation)
				onItemClicked(organisation)
			}
		}
	}, [organisations.organisations])

	const handleOrganisationClicked = (organisation: OrganisationRedux): void => {
		setSelectedOrganisation(organisation)
		onItemClicked(organisation)
	}

	if (!organisations.organisations) return null

	return (
		<div className={css['container']}>
			<Dropdown
				variant="light"
				justify="right"
				title={selectedOrganisation ? selectedOrganisation.organisationName : ''}
				className={css['dropdown']}
				openedClassName={css['dropdown--opened']}
				chevron={true}
			>
				{organisations.organisations.map((organisation, index: number) => {
					const { organisationName } = organisation
					return (
						<li key={index} className={css['item']} onClick={(): void => handleOrganisationClicked(organisation)}>
							<span>{organisationName}</span>
						</li>
					)
				})}
			</Dropdown>
		</div>
	)
}
