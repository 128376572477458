import React from 'react'
import css from './BulletPoints.module.scss'

export interface BulletPointsProps {
	children: React.ReactNode
	size?: 'xs' | 'sm' | 'md' | 'lg'
}

/**
 * BulletPoints Container for a BulletPoint Item. This doesn't use list-style-image due to it's shortcomings and
 * difficulty to manage with css without a separate package. Happy for this component to be updated with something
 * to handle this.
 * @returns Component
 */
export const BulletPoints = ({ children, size = 'lg' }: BulletPointsProps): React.ReactElement => {
	const classNames = [css['container']]
	classNames.push(css[`container__${size}`])

	return <ul className={classNames.join(' ')}>{children}</ul>
}
