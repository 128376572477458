import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'

export const RecentUsersSahara = (): React.ReactElement => {
	const headers = [
		{ valueKey: 'CPU', label: 'CPU' },
		{ valueKey: 'CreatedDate', label: 'Created Date' },
		{ valueKey: 'Email', label: 'Email' },
		{ valueKey: 'LastConnected', label: 'Last connected' },
		{ valueKey: 'LynxVersion', label: 'LYNX version' },
		{ valueKey: 'OSVersion', label: 'OS version' }
	]
	const [users, setUsers] = useState([])
	useEffect(() => {
		getUsers()
	}, [])

	const getUsers = async (): Promise<void> => {
		try {
			const responseData = await APIRequest.Logs.getRecentSaharaUsersData({})
			setUsers(responseData)
		} catch (err) {
			Logger.error(err)
		}
	}

	return (
		<Document title="Recent Sahara Users" description="Recent Sahara Users" keywords="Recent Sahara Users" auth={true} disableOverflow={true}>
			<Card>
				<div>
					<Table headers={headers} items={users} initialSortHeaderKey="CreatedDate" />
				</div>
			</Card>
		</Document>
	)
}
