import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { IOrganisationReport } from '@lynx/core/src/interfaces/Organisation'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

export const OrganisationsReport = (): React.ReactElement => {
	const navigate = useNavigate()
	const [organisationData, setOrganisationData] = useState<IOrganisationReport[]>([])
	const headers = [
		{ valueKey: 'organisationName', label: 'Organisation Name' },
		{ valueKey: 'users', label: 'Generation Count' }
	]

	useEffect(() => {
		const mount = async (): Promise<void> => {
			try {
				const responseData = await APIRequest.Organisations.getOrganisationReport()
				const { data } = responseData
				if (!data) {
					return
				}
				setOrganisationData(data)
			} catch (err) {
				Logger.error(err)
			}
		}
		mount()
	}, [])

	const goToDetails = (item: IOrganisationReport): void => {
		navigate('/organisation/' + item.id)
	}

	return (
		<Document title="Organisation Report" description="Organisation Report" keywords="Organisation Report" auth={true}>
			<Card>
				<Table onRowClick={goToDetails} title={'Mailing'} items={organisationData} headers={headers} />
			</Card>
		</Document>
	)
}
