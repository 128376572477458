import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { string } from '@lynx/core'
import { somethingWentWrong } from '@lynx/core/src/interfaces/Notifications'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ModalContext, hideModal } from 'store/modal'
import { pushNotification } from 'store/notifications'
import css from './ConfirmAccountCreationModal.module.scss'
import { ConfirmAccountCreationModalContextType } from './ConfirmAccountCreationModalContextType'
import { loadOrganisations } from 'store/organisations'

enum UserType {
	admin,
	readyOnly
}

interface NewUser {
	userName: string
	email: string
	userType: UserType
	isEmailInvalid: boolean
}

export const ConfirmAccountCreationModal = (): React.ReactElement => {
	const [isLoading, setIsLoading] = useState(false)
	const organisation = useCurrentOrganisation()
	const dispatchThunk = useThunkDispatch()
	const [organisationId, setOrganisationId] = useState(-1)
	const [users, setUsers] = useState<NewUser[]>([])
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const getAccountsValidityCount = (): { invalidAccountCount: number; validAccountsCount: number } => {
		return users.reduce<{ invalidAccountCount: number; validAccountsCount: number }>(
			(acc, { isEmailInvalid }: NewUser) => {
				const { invalidAccountCount, validAccountsCount } = acc
				if (isEmailInvalid) {
					return { invalidAccountCount: acc.invalidAccountCount + 1, validAccountsCount }
				}
				return { invalidAccountCount, validAccountsCount: validAccountsCount + 1 }
			},
			{ invalidAccountCount: 0, validAccountsCount: 0 }
		)
	}

	const handleCreateAccountsClicked = async (): Promise<void | undefined> => {
		const mappedUsers = users.map((user) => ({ isAdmin: user.userType === UserType.admin, email: user.email, userName: user.email.split('@')[0] }))
		try {
			setIsLoading(true)
			const response = await APIRequest.Organisations.bulkCreateAccounts(organisationId, mappedUsers)
			if (response?.error) return
			await dispatchThunk(loadOrganisations())
			dispatch(hideModal())
		} catch (err) {
			if (err instanceof Error) {
				await dispatchThunk(pushNotification({ type: somethingWentWrong, payload: null }))
			}
		} finally {
			setIsLoading(false)
		}
	}

	const handleModalVisible = (context?: ModalContext): void => {
		if (context?.type !== ConfirmAccountCreationModalContextType || !organisation) return
		const mappedData = (context.fileData || []).map((user) => ({
			...user,
			userType: UserType.readyOnly,
			isEmailInvalid: !string.isEmail(user.email)
		}))
		setUsers(mappedData)
		setOrganisationId(organisation.id)
	}

	const invalidEmail = t('pages.organisation.bulkCsv.invalidEmailFormat')

	const { invalidAccountCount, validAccountsCount } = getAccountsValidityCount()
	return (
		<Modal name="ConfirmAccountCreationModal" title={t('pages.organisation.bulkCsv.confirmAccountCreation')} onVisible={handleModalVisible}>
			<div className={css['container']}>
				<div className={css['header-container']}>
					<div className={css['accounts-validity-container']}>
						<span>
							{validAccountsCount} {t('pages.organisation.bulkCsv.accountsReady')} /{' '}
						</span>
						<span className={invalidAccountCount ? css['error-text'] : ''}>
							{invalidAccountCount} {t('pages.organisation.bulkCsv.errors')}
						</span>
					</div>
				</div>
				<div className={css['emails-container']}>
					{users.map((user, i) => (
						<div className={css['email-container']} key={user.email + i}>
							<div>{user.email}</div>
							<div>{user.isEmailInvalid && <div className={css['invalid-container-user']}>{invalidEmail}</div>} </div>
						</div>
					))}
				</div>
				<div className={css['buttons']}>
					<BasicButton variant="blue" disabled={!!invalidAccountCount || isLoading} isLoading={isLoading} onClick={handleCreateAccountsClicked}>
						{t('pages.organisation.bulkCsv.createAccounts')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
