import { APIRequest } from '@lynx/client-core/src/api'
import { AddCircleOutlineIcon, BasicButton, Document, Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { useDebounce } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { hasUserPermission } from '@lynx/core'
import { BoxLightCustomerServicePermission } from '@lynx/core/src/constants'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'

interface EnquiriesState {
	data: ContactInfo[]
	totalCount: number
	searchText: string
	page: number
	rowsPerPage: number
}

interface ContactInfo {
	name: string
	email: string
	phoneCountryCode: string
	phoneNumber: string
	organisationName: string
	organisationUrl: string
	city: string
	postcode: string
	country: string
	numberOfPeople: string
	additionalInformation: string
	lynxOrganisation: number
}

const formatDate = (date: Date | string): string => {
	return new Date(date).toLocaleString()
}

const columns: TableHeaders = [
	{
		valueKey: 'lynxOrganisation',
		label: 'Org Id',
		customBodyRender: (item: { lynxOrganisation?: number }): React.ReactNode => String(item.lynxOrganisation) || 'None Selected'
	},
	{
		valueKey: 'name',
		label: 'Name'
	},
	{
		valueKey: 'email',
		label: 'Email'
	},
	{
		valueKey: 'organisationName',
		label: 'Organisation'
	},
	{
		valueKey: 'numberOfPeople',
		label: 'No. of People'
	},
	{
		valueKey: 'organisationUrl',
		label: 'Org Url'
	},
	{
		valueKey: 'phoneCountryCode',
		label: 'Country Code'
	},
	{
		valueKey: 'phoneNumber',
		label: 'Phone Number'
	},
	{
		valueKey: 'city',
		label: 'City'
	},
	{
		valueKey: 'country',
		label: 'Country'
	},
	{
		valueKey: 'postcode',
		label: 'Postcode'
	},
	{
		valueKey: 'additionalInformation',
		label: 'Info'
	},
	{
		valueKey: 'createdDate',
		label: 'Created date',
		customBodyRender: (item: { createdDate: Date }): React.ReactNode => formatDate(item.createdDate)
	}
]

function convertToSingleObject(arr: { [key: string]: string }[]): ContactInfo {
	return arr.reduce((acc, item) => {
		const key = Object.keys(item)[0]
		const value = item[key]
		return { ...acc, [key]: value }
	}, {} as ContactInfo)
}

export const Enquiries = (): React.ReactElement => {
	const [enquiryIdLoading, setEnquiryIdLoading] = useState<string[]>([])
	const [createdCustomersIds, setCreatedCustomersIds] = useState<string[]>([])
	const [state, setState] = useState<EnquiriesState>({
		data: [],
		totalCount: 0,
		searchText: '',
		page: 0,
		rowsPerPage: 30
	})
	const { permissions } = useSelector((state: ApplicationState) => state.profile)
	const { searchText, rowsPerPage, totalCount, data } = state

	useEffect(() => {
		getEnquiries(1, '', rowsPerPage)
	}, [])

	const onCustomerCreate = async (item: ContactInfo): Promise<void> => {
		const { name, email, phoneNumber, country } = item
		try {
			setEnquiryIdLoading([...enquiryIdLoading, item.email])
			await APIRequest.Billing.createStripeCustomer({ name, email, isReseller: false, phone: phoneNumber, address: { country } })
			setCreatedCustomersIds([...createdCustomersIds, item.email])
		} catch (e) {
			// do nothing
		} finally {
			setEnquiryIdLoading(enquiryIdLoading.filter((e) => e !== item.email))
		}
	}

	const changePage = (page: number): void => {
		getEnquiries(page, searchText, false, rowsPerPage)
	}

	const changeSearchText = (text: string): void => {
		setState({ ...state, searchText: text })
		getEnquiries(1, text, false, rowsPerPage)
	}

	const getEnquiries = useDebounce(async (pageNumber: number, searchValue: string, clearSearch = false, perPage = rowsPerPage) => {
		try {
			const responseData = await APIRequest.Billing.getEnquiries({
				start: pageNumber,
				length: perPage,
				searchText: clearSearch ? '' : searchValue
			})
			if (!responseData?.data) return

			const dataMap = responseData.data
				.filter((f) => Array.isArray(JSON.parse(f.formValues)))
				.map(({ id, formValues, createdDate }) => {
					return { ...convertToSingleObject(JSON.parse(formValues)), createdDate, id }
				})

			setState({
				...state,
				page: pageNumber,
				data: dataMap || [],
				totalCount: dataMap.length || 0
			})
		} catch (err) {
			Logger.error(err)
		}
	}, 300)

	const actionsCol = hasUserPermission(BoxLightCustomerServicePermission, permissions)
		? [
				{
					valueKey: 'actions',
					label: '',
					customBodyRender: (item: ContactInfo): React.ReactNode => {
						const isLoading = enquiryIdLoading.includes(item.email)
						const isDisabled = createdCustomersIds.includes(item.email)
						return (
							<div>
								<BasicButton
									variant="blue"
									icon={AddCircleOutlineIcon}
									onClick={(): Promise<void> => onCustomerCreate(item)}
									isLoading={isLoading}
									disabled={isDisabled}
								>
									Create Customer
								</BasicButton>
							</div>
						)
					}
				}
		  ]
		: []

	const allColumns = [...columns, ...actionsCol]

	return (
		<Document title="Enquiries Log" keywords="Enquiries" auth={true} disableOverflow={true}>
			<Card>
				<Table
					pageSizes={[rowsPerPage]}
					onPageChange={changePage}
					totalCount={totalCount}
					onTableSearchChange={changeSearchText}
					title={'Enquiries Log'}
					headers={allColumns}
					items={data}
					initialSortHeaderKey="createdDate"
					isCustomSearchRender={true}
				/>
			</Card>
		</Document>
	)
}
