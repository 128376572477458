import * as React from 'react'
import Styles from './Pills.module.scss'

export interface Pill {
	label: string
	value: string
	badge?: string
}

export const Pills = ({ pills, selected, onSelect }: { pills: Pill[]; selected?: string | null; onSelect?: (value: string) => void }): React.ReactElement => {
	return (
		<div className={Styles['container']}>
			{pills.map((pill, index) => {
				return (
					<div
						key={index}
						className={[Styles['pill'], selected === pill.value ? Styles['pill-selected'] : ''].join(' ')}
						onClick={(): void => onSelect?.(pill.value)}
					>
						{pill.label}

						{pill.badge && <div className={Styles['pill-badge']}>{pill.badge}</div>}
					</div>
				)
			})}
		</div>
	)
}
