import { Overlay } from '@lynx/client-core/src/components/Popup/Overlay'
import React from 'react'
import css from './Resend.module.css'

export const Resend = (): React.ReactElement => {
	return (
		<Overlay title="Resend All" className={css['dropdown']} popUpClassName={css['popup']}>
			<ul>
				<li>Resend All Invites</li>
				<li>Resend Unread Invites</li>
			</ul>
		</Overlay>
	)
}
