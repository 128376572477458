import React from 'react'
import css from './UserButtonItem.module.scss'
import { ProfileState } from '../../store/profile'
import { useProfileIcon } from '../../hooks/useProfileIcon'

interface UserButtonItemProps {
	profile: ProfileState
	handleClick?: () => void
}

const UserButtonItem = ({ profile, handleClick }: UserButtonItemProps): React.ReactElement => {
	const { icon } = useProfileIcon({ profile })

	return (
		<div
			className={css['userbutton-item']}
			onClick={(): void => {
				if (handleClick) {
					handleClick()
				}
			}}
		>
			<div className={css['userbutton-item-left']}>
				<div className={css['userbutton-item-icon']}>{icon}</div>
			</div>
			<div className={css['userbutton-item-right']}>
				<div className={css['userbutton-item-text']}>{profile.displayName}</div>
				<div className={css['userbutton-item-name']}>{profile.email}</div>
			</div>
		</div>
	)
}

export default UserButtonItem
