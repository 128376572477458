import { Section } from 'components/Sections'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import i18next from 'i18next'
import { ShareType } from '@lynx/client-core/src/api/interfaces'
import * as React from 'react'
import { useState } from 'react'
import { inviteShare } from 'store/sharing'
import { Invite, People } from './components'
import { FooterButtons } from './components/FooterButtons/FooterButtons'
import { ManagePeopleSectionProps } from './interfaces/ManagePeopleSectionProps'
import css from './ManagePeopleSection.module.scss'
import { string } from '@lynx/core'
import { Logger } from '@lynx/client-core/src/modules'

export const ManagePeopleSection = ({ navigate }: ManagePeopleSectionProps): React.ReactElement => {
	const [email, setEmail] = useState<string>('')
	const [invitePermission, setInvitePermission] = useState<ShareType>('viewer')
	const dispatchThunk = useThunkDispatch()

	const handleBackNavigation = (): void => {
		navigate('ShareSection')
	}

	const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setEmail(event.target.value)
	}

	const handleSendInvitesClicked = async (): Promise<void> => {
		if (!string.isEmail(email)) {
			Logger.error('invalid email')
		}

		await dispatchThunk(inviteShare(email, invitePermission))
		setEmail('')
	}

	const handleInvitePermissionChanged = (permission: ShareType): void => {
		setInvitePermission(permission)
	}

	return (
		<Section side="right" title={i18next.t('components.modals.sharingModal.managePeople')} className={css['container']} navLeft={handleBackNavigation}>
			<People />
			<Invite email={email} permission={invitePermission} onEmailChanged={handleEmailChanged} onPermissionChanged={handleInvitePermissionChanged} />
			<FooterButtons onBackClicked={handleBackNavigation} onSendInvitesClicked={handleSendInvitesClicked} />
		</Section>
	)
}
