import { Button, Modal } from '@lynx/client-core/src/components'
import { deleteQueryParam } from '@lynx/client-core/src/utils'
import i18next from 'i18next'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideModal } from 'store/modal'
import Styles from './OrganisationDepartedModal.module.css'

export const OrganisationDepartedModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const [orgName, setOrgName] = useState('')

	useEffect(() => {
		const mounted = (): void => {
			const parsedQuery = qs.parse(location.search) as { quitOrgName: string }
			setOrgName(parsedQuery.quitOrgName)
			deleteQueryParam('quitOrgName')
		}
		mounted()
	}, [])

	return (
		<Modal
			data-automation-id="OrganisationDepartedModal"
			name="OrganisationDepartedModal"
			title={i18next.t('components.modals.OrganisationDepartedModal.organisationDeparted')}
		>
			<div className={Styles.container}>
				<div className={Styles.body}>
					<p>
						{i18next.t('components.modals.OrganisationDepartedModal.youAreNoLonger')} <b>{orgName}</b>.
					</p>
				</div>
				<div className={Styles.bottomContainer}>
					<div className={Styles.buttonWrapper}>
						<Button variant="blue" onClick={(): ReturnType<typeof hideModal> => dispatch(hideModal())}>
							{i18next.t('pages.organisation.ok')}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}
