import React from 'react'
import { NavLink } from 'react-router-dom'
import { LoadingSpinner } from '../LoadingSpinner'
import css from './BasicButton.module.scss'
import { AddPageIcon } from '../Icons'

interface BasicButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string
	['data-automation-id']?: string
	style?: React.CSSProperties
	variant?: 'transparent' | 'dark' | 'blue' | 'blue-gradient' | 'grey' | 'danger' | 'light' | 'pro' | 'warning'
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'oval'
	elevation?: boolean
	disabled?: boolean
	isLoading?: boolean
	children?: React.ReactNode
	onSubmit?: (event: React.FormEvent<HTMLButtonElement>) => void
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	to?: string
	target?: string
	icon?: typeof AddPageIcon
}

export const BasicButton = ({
	children,
	className,
	'data-automation-id': automationId,
	disabled,
	isLoading,
	elevation,
	onClick,
	onSubmit,
	size = 'md',
	style,
	type = 'button',
	variant = 'grey',
	to,
	icon,
	target = '',
	...rest
}: BasicButtonProps): React.ReactElement => {
	const classNames = [css['container'], css[size], css[variant], elevation && css['elevation'], className, children && css.childrenExists].filter((c) => c)

	const clickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
		if (isLoading || disabled) return
		onClick && onClick(e)
	}
	const Icon = icon

	if (to) {
		// Center <a/> tag's children within parent, but first we check that the center class isn't
		// already there, which it only would be if isLoading is true
		!isLoading && classNames.push(css['container--center'])
		return (
			<NavLink to={to} target={target} className={classNames.join(' ')}>
				{Icon && <Icon className={css.icon} />}
				{children}
			</NavLink>
		)
	}

	return (
		<button
			style={style}
			className={classNames.join(' ')}
			data-automation-id={automationId}
			type={type}
			disabled={disabled}
			onSubmit={onSubmit}
			onClick={clickHandler}
			{...rest}
		>
			<div className={css.buttonInnerContainer}>
				{isLoading ? (
					<LoadingSpinner />
				) : (
					<React.Fragment>
						{Icon && <Icon />}
						{children}
					</React.Fragment>
				)}
			</div>
		</button>
	)
}
