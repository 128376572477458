import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Document } from '@lynx/client-core/src/components'
import Accordion, { AccordionItem } from '@lynx/client-core/src/components/Accordion/Accordion'
import { Logger } from '@lynx/client-core/src/modules'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import css from './License.module.scss'

interface LicenseState {
	topics: any[]
	title: string
}

export const License = (): React.ReactElement => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [state, setState] = useState<LicenseState>({
		title: '',
		topics: []
	})

	const sections = [
		{ title: 'LYNX 3rd Party Licenses', searchFor: '3rd' },
		{ title: 'MyScript Terms of Use', searchFor: 'myscript' },
		{ title: 'Qt Licenses', searchFor: 'qt' },
		{ title: 'Mapbox Earcut License', searchFor: 'eartcut' }
	]

	const mount = (): void => {
		APIRequest.Pages.getLicense()
			.then((data: any) => {
				setState({ ...state, topics: data.licenses })
			})
			.catch((err) => {
				Logger.error(err)
			})
	}

	const handleViewTermsClicked = (): void => {
		navigate('/support/terms')
	}

	useEffect(() => {
		mount()
	}, [])

	return (
		<Document title="View licenses used" description="List of licenses used within the LYNX software" keywords="Licenses">
			<h1 className={css['heading']}>{t('pages.license.title')}</h1>
			<div className={css['content']}>
				<BasicButton variant="blue" onClick={handleViewTermsClicked}>
					{t('pages.license.viewTerms')}
				</BasicButton>
				<Accordion>
					{sections.map((section, index) => {
						return (
							<AccordionItem key={index} title={section.title}>
								{state.topics
									.filter((value) => value.name.toLowerCase().includes(section.searchFor))
									.map((value, index) => {
										return (
											<div key={index}>
												<a href={value.href} target="_blank">
													{value.name}
												</a>
											</div>
										)
									})}
							</AccordionItem>
						)
					})}
				</Accordion>
			</div>
		</Document>
	)
}
