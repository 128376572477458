import * as React from 'react'
import { SectionProps } from './interfaces'
import Styles from './Section.module.css'

export const Section = ({ children, className, side, title, navLeft, onClick }: SectionProps): React.ReactElement => {
	const classNames = [Styles['container'], className].filter((c) => c)
	side = side || 'left'
	side === 'left' && classNames.push(Styles['side-left'])
	side === 'right' && classNames.push(Styles['side-right'])
	navLeft && classNames.push(Styles['justify-between'])

	return (
		<section className={classNames.join(' ')} onClick={onClick}>
			{title && (
				<div className={Styles['title']}>
					{navLeft && <img src="/resources/icons/arrow_back_black_24dp.svg" alt="back" onClick={navLeft} />}
					<div>{title}</div>
					<div />
				</div>
			)}
			{children}
		</section>
	)
}
