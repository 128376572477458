import css from './Card.module.scss'
import React from 'react'

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
	className?: string
	enableBorder?: boolean
	enablePadding?: boolean
}

export const Card = ({ children, className, enableBorder = true, enablePadding = true, ...rest }: CardProps): React.ReactElement | null => {
	const classNames = [className, css['container']].filter((c) => c)
	enableBorder && classNames.push(css['container--border'])
	enablePadding && classNames.push(css['container--padding'])

	return (
		<div className={classNames.join(' ')} {...rest}>
			{children}
		</div>
	)
}
