import { APIRequest } from '../../api'
import i18next from 'i18next'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CloseIcon, Dropdown, LanguageMainIcon } from '..'
import { ApplicationState } from '../../store'
import { setLang } from '../../store/profile'
import css from './LanguageMenu.module.scss'
import { languages } from '../LanguageDropdown/languagesList'

interface LanguageMenuProps {
	children?: React.ReactNode
	openedClassName?: string
}

export const LanguageMenu = ({ openedClassName }: LanguageMenuProps): React.ReactElement => {
	const dispatch = useDispatch()
	const profileState = useSelector((state: ApplicationState) => state.profile)

	const selectLang = async (lang: string): Promise<void> => {
		i18next.changeLanguage(lang) // Set i18next
		dispatch(setLang(lang))

		if (profileState.loggedIn) {
			await APIRequest.User.changeLang(lang)
		}
	}

	const onSelectLanguage = async (value: string): Promise<void> => {
		await selectLang((value || 'en') as string)
	}

	const items = languages.map((lang: { label: string; value: string }, key: number) => {
		const originalText = lang.label.split('-')[0]
		const translatedText = lang.label.split('-')[1]
		const classNames = [css['languageMenuItem']]
		profileState.lang === lang.value && classNames.push(css['languageMenuItem--selected'])

		return (
			<li
				key={key}
				value={lang.value}
				onClick={(): void => {
					onSelectLanguage(lang.value)
				}}
				className={classNames.join(' ')}
			>
				<div>{originalText}</div>
				<div>{translatedText}</div>
			</li>
		)
	})

	const openedClassNameClasses = [css.openedClassName]
	openedClassName && openedClassNameClasses.push(openedClassName)
	return (
		<Dropdown title={<LanguageMainIcon className={css.icon} />} openedClassName={openedClassNameClasses.join(' ')}>
			<div className={css.languageMenuHeader}>
				<div>{i18next.t('components.header.selectLanguage')}</div>
				<CloseIcon />
			</div>
			{items}
		</Dropdown>
	)
}
