import * as React from 'react'
import css from './index.module.scss'

interface TextFieldProps {
	autoFocus?: boolean
	className?: string
	type?: 'text' | 'password' | 'number' | 'search' | 'email'
	size?: 'sm' | 'md'
	variant?: 'grey'
	['data-automation-id']?: string
	adornment?: React.ReactElement
	placeholder?: string
	name?: string
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	value?: string
	disabled?: boolean
	ref?: React.RefObject<HTMLInputElement>
	style?: React.CSSProperties
	justifyAdornment?: 'start' | 'end'
	justifyPlaceholder?: 'start' | 'center' | 'end'
	maxLength?: number
	min?: React.InputHTMLAttributes<HTMLInputElement>['min']
	max?: React.InputHTMLAttributes<HTMLInputElement>['max']
}

export const TextField: React.FunctionComponent<TextFieldProps> = ({
	autoFocus,
	adornment,
	className,
	'data-automation-id': automationId,
	justifyPlaceholder,
	justifyAdornment,
	name,
	onChange,
	onKeyDown,
	placeholder,
	ref,
	style,
	type,
	disabled = false,
	value,
	maxLength,
	variant,
	min,
	max,
	size = 'md'
}) => {
	const classNames = [css.container, className, css[size], variant && css[variant]].filter((c) => c)
	type = type || 'text'
	adornment && classNames.push(css['adornment'])

	justifyPlaceholder = justifyPlaceholder || 'start'
	switch (justifyPlaceholder) {
		case 'center': {
			classNames.push(css['placeholder-center'])
			break
		}
		case 'end': {
			classNames.push(css['placeholder-end'])
			break
		}
	}

	justifyAdornment = justifyAdornment || 'start'
	if (justifyAdornment === 'end') {
		classNames.push(css['adornment-right'])
	}
	return (
		<div className={classNames.join(' ')} style={style}>
			{adornment}
			<input
				min={min}
				max={max}
				data-lpignore="true"
				autoFocus={autoFocus || false}
				maxLength={maxLength}
				data-automation-id={automationId}
				ref={ref}
				name={name}
				value={value}
				type={type}
				disabled={disabled}
				placeholder={placeholder}
				autoComplete="off"
				onChange={onChange}
				onKeyDown={onKeyDown}
			/>
		</div>
	)
}
