import { BasicButton, CloseIcon, Input, RadioButton } from '@lynx/client-core/src/components'
import { currencySource } from '@lynx/core/src/constants'
import { FlatPriceOption } from '@lynx/core/src/interfaces'
import { CurrencyDropdown } from 'pages/Pricing/components'
import css from './OrganisationSubscriptionNewPrice.module.scss'

interface Props {
	flatPriceObject: FlatPriceOption[]
	setFlatPriceObject?: React.Dispatch<React.SetStateAction<FlatPriceOption[]>>
	mainCurrency: string
	setMainCurrency?: React.Dispatch<React.SetStateAction<string>>
}

export const SubscriptionCreatePriceUnit = ({ flatPriceObject, setFlatPriceObject, mainCurrency, setMainCurrency }: Props): React.ReactElement => {
	const isReadOnly = !setFlatPriceObject
	const flatNotSelectedCurrency = currencySource.find((currency) => !flatPriceObject.some((price) => price.amountCurrencyCode === currency.code))
	const getAvailableCurrencies = (current: string): typeof currencySource =>
		currencySource.filter((currency) => !flatPriceObject.some((price) => price.amountCurrencyCode === currency.code) || currency.code === current)

	const onFlatPriceObjectUpdate = (index: number, key: keyof FlatPriceOption, value: string | number): void => {
		const newFlatPriceObject = [...flatPriceObject]
		newFlatPriceObject[index] = { ...newFlatPriceObject[index], [key]: value }
		setFlatPriceObject?.(newFlatPriceObject)
	}

	return (
		<div className={css.perUnitContainer}>
			{flatPriceObject.map((price, index) => {
				return (
					<div className={css.singleFlatPrice} key={price.amountCurrencyCode + 'per_unit'}>
						<Input
							disabled={isReadOnly}
							label={index === 0 ? 'Price' : ''}
							value={price.unitAmount}
							onChange={(e): void => onFlatPriceObjectUpdate(index, 'unitAmount', Number(e.target.value))}
						/>
						<div className={css.dropdownAndIconContainer}>
							<CurrencyDropdown
								disabled={isReadOnly}
								onItemClicked={(code): void => onFlatPriceObjectUpdate(index, 'amountCurrencyCode', code)}
								currencyCode={price.amountCurrencyCode}
								currenciesList={getAvailableCurrencies(price.amountCurrencyCode)}
							/>
							{!isReadOnly && (
								<div className={css.defaultCurContainer}>
									{mainCurrency === price.amountCurrencyCode && <div>Default</div>}
									<RadioButton
										isChecked={mainCurrency === price.amountCurrencyCode}
										key={index}
										value={index}
										label={''}
										handleChange={(): void => {
											setMainCurrency?.(price.amountCurrencyCode)
										}}
									/>
								</div>
							)}
							{!isReadOnly && (
								<CloseIcon
									style={{ visibility: index !== 0 ? 'visible' : 'hidden' }}
									className={css.closeIcon}
									onClick={(): void => setFlatPriceObject?.(flatPriceObject.filter((_, i) => i !== index))}
								/>
							)}
						</div>
					</div>
				)
			})}
			{flatNotSelectedCurrency && !isReadOnly && (
				<BasicButton
					className={css.newRowButton}
					size="sm"
					onClick={(): void => setFlatPriceObject?.([...flatPriceObject, { unitAmount: 0, amountCurrencyCode: flatNotSelectedCurrency.code }])}
				>
					Add a price by currency
				</BasicButton>
			)}
		</div>
	)
}
