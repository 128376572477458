import React from 'react'
import { ErrorIcon, InfoIcon } from '../Icons'
import css from './InputMessages.module.scss'
import { InputMessagesProps, Message } from './Input.types'

export const InputMessages = ({ messages }: InputMessagesProps): React.ReactElement => {
	const messagesContainer = (
		<div className={css.messagesContainer}>
			{messages?.map(({ message, type }: Message) => {
				const getIcon = (type: Message['type']): React.ReactElement => {
					const iconClass = css[`singleMessage--${type}-icon`]
					switch (type) {
						case 'error':
							return <ErrorIcon className={iconClass} />
						case 'info':
							return <InfoIcon className={iconClass} />
					}
				}
				return (
					<div className={css.singleMessage} key={message}>
						{getIcon(type)}
						<div>{message}</div>
					</div>
				)
			})}
		</div>
	)
	return messagesContainer
}
