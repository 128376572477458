import { Section } from 'components/Sections'
import * as QRCode from 'qrcode'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './JoinSection.module.scss'

export const JoinSection = (): React.ReactElement => {
	const sharingState = useSelector((state: ApplicationState) => state.sharing)
	const [qrCodeUrl, setQrCodeUrl] = useState<string>('')

	useEffect(() => {
		QRCode.toDataURL(sharingState.codeUrl, (err, qrUrl) => {
			setQrCodeUrl(qrUrl)
		})
	}, [sharingState.codeUrl])

	return (
		<Section className={css['container']}>
			<div>{qrCodeUrl && <img alt="QR Code" src={qrCodeUrl} width="326" />}</div>
		</Section>
	)
}
