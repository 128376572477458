import { Document } from '@lynx/client-core/src/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './Privacy.module.scss'

export const Privacy = (): React.ReactElement => {
	const { t } = useTranslation()

	return (
		<Document title={t('pages.pageTitles.privacyPolicy')} description="Privacy Policy" keywords="Privacy, Policy, LYNX">
			<div className={css['container']}>
				<div className={css['heading']}>
					<p>Sahara Presentation Systems Ltd.</p>
					<p>Privacy Notice</p>
				</div>
				<p>
					This privacy notice is provided to inform about how and why your personal data is used so that we can be as transparent as possible, and to
					ensure that you are aware of your rights under data protection legislation.
				</p>
				<p>
					<strong>Who is the Data Controller?</strong>
				</p>
				<p>
					Sahara Presentation Systems Ltd (also trading as Clevertouch) is the Data Controller for your personal data. Our correspondence address is
					Unit 4, 5 Church Manorway, Kent, United Kingdom, DA8 1DG. We can be contacted on +44(0)20 8319 7777 or{' '}
					<a href="mailto:info@saharaltd.com">info@saharaltd.com</a>.
				</p>
				<p>
					<strong>Which personal data do we process and why?</strong>
				</p>
				<p>
					We process your personal data so we can engage with you and supply our products and service. We use your personal data for account
					management purposes, maintaining communication and providing service based information. We also use your personal data for the purpose of
					providing you focussed marketing material and updates on products and services.
				</p>
				<p>
					We are a business to business organisation and will usually obtain your personal data directly from you such as at exhibitions, through our
					website contact forms or social media. We may be provided with your contact details by a third party, for example through a reseller
					referral.
				</p>
				<p>
					As a client or prospective client, we process your name and contact data as well as job title and details and type of the organisation you
					work for, including physical address. We will also use your contact details to send you appropriate marketing material. The lawful basis we
					use for this is Article 6.1.f UK GDPR (Legitimate Interest). The legitimate interest we pursue is to grow our business through expanding and
					retaining our client base and provide excellent customer service. As with any legitimate interest processing, you have the right to object
					to this and you can do so by contacting us using the information provided above. If you fail to provide the information required, we will be
					unable to provide the agreed products and services to you.
				</p>
				<p>
					If you contact us through our web form we will ask for your consent per Article 6.1.a UK GDPR to send you news and marketing materials. You
					can withdraw consent at any time by contacting us.
				</p>
				<p>
					We collect data through our website analytics some of which may identify you, such as an IP address. Our Cookie Policy details the cookies
					we use and you can manage your preferences through our cookie consent platform.
				</p>
				<p>
					<strong>Who are the recipients of your data?</strong>
				</p>
				<p>
					As a general principle, we will not transfer your personal data to other recipients without your permission. There are some exceptions to
					this:
				</p>
				<ul>
					<li>
						It is possible, that we might be obliged to disclose personal information in response to a court order or other lawful obligation. Our
						lawful basis for this is Article 6.1.c -legal obligation.
					</li>
					<li>
						We may share data within our group of companies for administrative and sales / marketing purposes. We would do this as under the basis
						of Article 6.1.f - legitimate interest as we have a legitimate interest in developing our business.
					</li>
					<li>
						We may share your data as part of a sale, transfer, or merge part of our business or our assets. Alternatively, we may seek to acquire
						other businesses or merge with them. We would do this as under the basis of Article 6.1.f - legitimate interest as we have a legitimate
						interest in developing our business.
					</li>
					<li>We do not sell your personal data.</li>
				</ul>
				<p>
					<strong>What about data processed by third parties on our behalf?</strong>
				</p>
				<p>
					We use the services of other organisations in the processing your data. We use cloud-based email and document storage, customer relationship
					management and video conference platforms. Our website processes limited personal data such as through our contact form and we use a cloud
					based customer service solution for support purposes. We also provide limited personal data to organisations that perform maintenance and
					service work on our behalf such as our IT managed service providers.
				</p>
				<p>
					Those organisations that process personal data on our behalf are subject to a data processing contract as required by Article 28 of the UK
					GPDR. This ensures that your data is handled in accordance with the UK GPDR.
				</p>
				<p>
					<strong>Do we transfer your data outside of the UK?</strong>
				</p>
				<p>
					We transfer personal data outside of the UK by virtue of our cloud hosted CRM which holds data in the USA. This transfer of data is
					facilitated through the UK extension to the EU – US Data Privacy Framework. We also transfer personal data to the EU which is facilitated by
					the EU / UK adequacy decision. Our customer support platform transfers personal data outside of the UK to the USA and has in place Binding
					Corporate Rules to manage that transfer.
				</p>
				<p>
					<strong>How long do we keep your data?</strong>
				</p>
				<p>
					We will retain your data only for the time we require it for the purposes stated and / or where we have a legal obligation or other
					legitimate purpose. We will retain the personal data of our clients for 7 years.
				</p>
				<p>
					<strong>These are your rights.</strong>
				</p>
				<p>
					The UK GDPR provides you with several rights in relation to the data <del>of your</del> we process. The rights relevant to our activities
					are:
				</p>
				<ul>
					<li>You have the right to get access to and copies of your personal data.</li>
					<li>You can ask us to rectify any inaccurate information we may be holding.</li>
					<li>You can request we restrict our processing of your data.</li>
					<li>
						You can ask us to erase your personal data if it is no longer required for the purpose we collected it or have no legal obligation to
						retain it.
					</li>
					<li>You have the right to object to processing we undertake under a legitimate interest basis.</li>
				</ul>
				<p>If you want to exercise any of these rights, please contact us using the above contact details.</p>
				You also have the right to lodge a complaint about our processing with a supervisory authority, in the UK, this is the Information
				Commissioner&#39;s Office. The Information Commissioner's Office
				<br />
				<br />
				Wycliffe House, Water Lane,
				<br />
				Wilmslow,
				<br />
				Cheshire SK9 5AF.
				<br />
				Helpline number: 0303 123 1113
				<br />
				<p>
					<strong>Where we act as Data Processors.</strong>
				</p>
				<p>
					When Clevertouch customers (the Data Controller) create accounts for their users and use our applications such as CleverLive, LYNX
					Whiteboard and LYNX Cloud we act as Data Processors as defined by the UK General Data Protection Regulation. This means that we have the
					responsibility to keep personal data secure in accordance with the law. Technical security procedures are in place to protect your data such
					as encryption of data in transit and at rest. We use the Microsoft Azure and IONOS environments along with the attendant security controls
					associated with those to host our applications. Our developers are ISO 27001 accredited.
				</p>
				<p>
					We will collect, or process on behalf of our customers, the following types of personal data when you use or interact with our products and
					services for account management and support: business email address, name and device IP address (for diagnostic purposes). We do not process
					special category personal data. We do not access or store content created by our LYNX Whiteboard application, it is only passed through from
					the user’s device to our servers enroute to a Google Drive account or our CleverLive application.
				</p>
				<p>
					As the Data Processor, we retain personal data at the direction of the Data Controller in accordance with the UK GDPR. User’s personal data
					is retained for the period that a user account is active. The LYNX Cloud account holders can delete user data from within the ‘Account’ page
					on the website via a single button. CleverLive account holders can direct Clevertouch to delete personal data on their behalf.
				</p>
				<br />
				<p>V1.3 Approved May 2024</p>
			</div>
		</Document>
	)
}
