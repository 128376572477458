import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useProfileIcon } from '../../hooks/useProfileIcon'
import { ApplicationState } from '../../store'
import { setLoginModal } from '../../store/profile'
import { NavigationItemProps } from '../NavigationBar'
import { ProfileDropdownButton } from './ProfileDropdown.button'
import css from './ProfileDropdown.module.scss'
import { Link } from 'react-router-dom'
interface ProfileDropdownProps {
	name: string
	email: string
	navItems: NavigationItemProps[]
	items: { icon: React.ReactElement; text: string; action: () => void; visible: boolean }[]
}

export const ProfileDropdown = ({ name, email, items = [], navItems = [] }: ProfileDropdownProps): React.ReactElement | null => {
	const { profile } = useSelector((state: ApplicationState) => state)
	const { icon } = useProfileIcon()
	const [open, setOpen] = useState<boolean>(false)
	const dispatch = useDispatch()

	const { loggedIn } = profile

	const handleOpenClicked = (): void => {
		setOpen(!open)
	}

	const handleBackgroundClicked = (): void => {
		handleOpenClicked()
	}

	const handleItemClicked = (action: () => void): void => {
		setOpen(false)
		action()
	}

	const handleSignInClicked = (): void => {
		dispatch(setLoginModal(true, false))
	}

	const classNames = [css['container']]
	open && classNames.push(css['container--open'])

	const itemElements = items
		.filter((i) => i.visible)
		.map(({ icon, action, text }, key) => (
			<li
				key={key}
				onClick={(): void => {
					handleItemClicked(action)
				}}
			>
				{icon}
				<div>{text}</div>
			</li>
		))

	const navItemElements = navItems
		.filter((n) => n.visible)
		.map((n, key) => (
			<li key={key} className={css['navigation_items']}>
				<NavLink to={n.to}>{n.children}</NavLink>
			</li>
		))

	return (
		<div className={classNames.join(' ')}>
			{open && <div className={css['background']} onClick={handleBackgroundClicked} />}
			<ProfileDropdownButton onOpenClicked={handleOpenClicked} onSignInClicked={handleSignInClicked} />
			<ul>
				{loggedIn && (
					<li onClick={handleBackgroundClicked}>
						<div className={css['profile']}>
							<div className={css['profile__icon']}>
								<Link to="/profile?modal=profile-picture">{icon}</Link>
							</div>
							<div className={css['profile__user']}>
								<h3>{name}</h3>
								<div className={css['profile__email']}>{email}</div>
							</div>
						</div>
					</li>
				)}
				{navItemElements}
				{itemElements}
			</ul>
		</div>
	)
}
