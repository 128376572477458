import { AddCircleOutlineIcon, BasicButton, DeleteIcon, Modal } from '@lynx/client-core/src/components'
import { hideModal, showModal } from '@lynx/client-core/src/store/modal'
import { OrganisationGroupUsersInterface, OrganisationGroupsInterface } from '@lynx/core/src/interfaces/Organisation'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { InvitePeopleModalContextType, ModalContext } from 'store/modal'
import { updateOrganisationGroupUsers } from 'store/organisations'
import { reduceOrganisationUserToGroupUser } from './ManageGroupModal.helpers'
import css from './ManageGroupModal.module.scss'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'

export const ManageGroupModal = (): React.ReactElement => {
	const organisation = useCurrentOrganisation()
	const dispatch = useDispatch()
	const [group, setGroup] = useState<OrganisationGroupsInterface | null>(null)
	const [groupedUsers, setGroupedUsers] = useState<OrganisationGroupUsersInterface[]>([])
	const [removedUserIds, setRemovedUserIds] = useState<string[]>([])
	const profile = useSelector((state: ApplicationState) => state.profile)
	const { t } = useTranslation()
	const dispatchThunk = useThunkDispatch()

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}

	const handleUpdateClicked = async (): Promise<void> => {
		if (!group?.users || !organisation?.id || !group.groupId) return

		const deltaAddedUsers = groupedUsers.map((gu) => gu.userId).filter((userId) => !group.users?.map((user) => user.userId).includes(userId))
		await dispatchThunk(updateOrganisationGroupUsers(organisation.id, group.groupId, removedUserIds, deltaAddedUsers))
		dispatch(hideModal())
	}

	const handleRemoveUserClicked = (userId: string): void => {
		setGroupedUsers((prev) => prev.filter((user) => user.userId !== userId))
		setRemovedUserIds((prev) => [...prev, userId])
	}

	const handleAddUserClicked = (userId: string): void => {
		const user = organisation?.users?.find((user) => user.userId === userId)
		if (!user || !group || !organisation) return

		setGroupedUsers((prev) => [...prev, reduceOrganisationUserToGroupUser(user, organisation, String(group.groupId))])
		setRemovedUserIds((prev) => prev.filter((id) => id !== userId))
	}

	const handleModalVisible = (context?: ModalContext): void => {
		if (context?.type !== 'ManageGroupModalContextType') return

		const group = organisation?.groups?.find((group) => group.groupId === context.groupId)
		if (!group?.users) return

		setGroup(group)
		setGroupedUsers([...(group.users || [])])
	}

	const organisationUsers = (organisation?.users || []).filter((user) => {
		const userIds = groupedUsers.map((user) => user.userId)
		return !userIds.includes(user.userId)
	})

	const handleModalHidden = (): void => {
		setGroup(null)
		setGroupedUsers([])
	}
	const inviteToGroup = (): void => {
		if (!group) return
		dispatch(showModal({ name: 'InvitePeopleModal', context: { type: InvitePeopleModalContextType, groupId: group.groupId } }))
	}

	return (
		<Modal name="ManageGroupModal" title={`Manage Group "${group?.groupName || ''}"`} onVisible={handleModalVisible} onHidden={handleModalHidden}>
			<div className={css['container']}>
				<h4>{t('pages.organisations.groupUsers')}</h4>
				<ul className={css['list']}>
					{groupedUsers.map((user) => (
						<li key={user.userId}>
							<div>{user.email}</div>
							<div>{organisation?.users.find((u) => u.userId === user.userId)?.isOwner ? 'admin' : ''}</div>
							<DeleteIcon
								style={{
									visibility: user.userId === profile.userId ? 'hidden' : 'visible'
								}}
								onClick={(): void => handleRemoveUserClicked(user.userId)}
							/>
						</li>
					))}
				</ul>
				<h4>{t('pages.organisations.organisationUsers')}</h4>
				<ul className={css['list']}>
					{organisationUsers.map((user) => (
						<li key={user.userId}>
							<div>{user.email}</div>
							<div>{user.isOwner ? 'admin' : ''}</div>
							<AddCircleOutlineIcon onClick={(): void => handleAddUserClicked(user.userId)} />
						</li>
					))}
				</ul>
				<div className={css['controls']}>
					<BasicButton onClick={inviteToGroup}>{t('pages.organisations.inviteUser')}</BasicButton>
					<div className={css['right-buttons']}>
						<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
						<BasicButton variant="danger" onClick={handleUpdateClicked}>
							{t('pages.organisations.update')}
						</BasicButton>
					</div>
				</div>
			</div>
		</Modal>
	)
}
