import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, CopyIcon, Modal } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContext, hideModal } from 'store/modal'
import css from './VerifyDomainModal.module.scss'
import { VerifyDomainModalContextType } from './VerifyDomainModalContextType'
import { useThunkDispatch } from 'hooks'
import { verifyDomain } from 'store/organisations'
import { useDispatch } from 'react-redux'

const encodeBase64 = (data: string): string => {
	return Buffer.from(data).toString('base64')
}

export const VerifyDomainModal = (): React.ReactElement | null => {
	const organisation = useCurrentOrganisation()
	const [domainId, setDomainId] = useState<number | null>(null)
	const [encodedDomain, setEncodedDomain] = useState<string>('')
	const dispatchThunk = useThunkDispatch()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleOpenFAQClicked = (): void => {
		window.open('/support/faq', '_blank')
	}

	const handleVerifyNowClicked = async (): Promise<void> => {
		if (!organisation || domainId == null) return
		try {
			const verified = await dispatchThunk(verifyDomain(organisation.id, domainId))
			if (verified) {
				window.alert('Domain verified successfully')
				dispatch(hideModal())
			} else {
				window.alert('Failed to verify domain')
			}
		} catch (e) {
			Logger.error(e)
			window.alert('Failed to verify domain')
		}
	}

	const handleModalVisible = async (context?: ModalContext): Promise<void> => {
		if (!context || !organisation?.id) return

		if (context.type !== VerifyDomainModalContextType) return

		const { domainId, emailDomain } = context
		setDomainId(domainId)

		const response = await APIRequest.Organisations.getVerificationSecret(organisation.id, emailDomain)
		if (!response?.data) return
		setEncodedDomain(response.data.secret)
	}

	const handleCopyClicked = (): void => {
		navigator.clipboard.writeText(encodedDomain)
	}

	return (
		<Modal name="VerifyDomainModal" title="Domain Verification" onVisible={handleModalVisible}>
			<div className={css['container']}>
				<h4>{t('pages.organisations.addThisRecord')}</h4>

				<div className={css['container__records']}>
					<b>{t('pages.organisations.recordType')}:</b>
					<span>TXT</span>
					<b>{t('pages.organisations.name')}:</b>
					<span>lynxauth</span>
					<b>{t('pages.organisations.value')}:</b>
					<span>
						<textarea value={encodedDomain} readOnly={true} /> <CopyIcon onClick={handleCopyClicked} />
					</span>
				</div>
				<p>{t('pages.organisations.dnsUpdate')}</p>
				<div>{t('pages.organisations.onceVerified')}</div>
				<div className={css['buttons']}>
					<BasicButton onClick={handleOpenFAQClicked}>{t('pages.organisations.open')} FAQ</BasicButton>
					<BasicButton variant="blue" onClick={handleVerifyNowClicked}>
						{t('pages.organisations.verifyNow')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
