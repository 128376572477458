import { Badge, CloseIcon, Input } from '@lynx/client-core/src/components'
import React, { useEffect, useRef, useState } from 'react'
import { MultiselectProps } from './interfaces'
import Styles from './Multiselect.module.css'

export const Multiselect = ({
	className,
	value,
	onChange,
	onChangeCurrent,
	autoFocus,
	placeholder = 'Type then enter',
	isError = false,
	disableBlur = false,
	forceValue = false,
	inputFilter,
	messages
}: MultiselectProps): React.ReactElement => {
	const [internalInputValue, setInternalInputValue] = useState('')
	const [items, setItems] = useState<string[]>(value || [])
	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		const onItemsChange = (): void => {
			onChange && onChange(items)
		}
		onItemsChange()
	}, [items])

	useEffect(() => {
		// On value change if forceValue is enabled then overwrite items
		if (forceValue && value) {
			setItems(value)
			valueChange('')
			return
		}

		const isValueSame = value && items?.reduce((acc, nextValue, index) => acc && nextValue === value[index], true)
		if (!isValueSame && value) {
			setItems(value)
		}
	}, [value])

	const handleContainerClick = (event: React.MouseEvent<EventTarget>): void => {
		event.preventDefault()
		if (event.currentTarget === event.target) focusInput()
	}

	const focusInput = (): void => {
		inputRef.current?.focus()
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		const isAlreadySelected = items.includes(internalInputValue)
		if (e.key === 'Enter' && !isAlreadySelected && internalInputValue) {
			valueChange('')
			if (inputFilter && !inputFilter(internalInputValue)) {
				return
			}

			setItems([...items, internalInputValue])
		}
	}

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => valueChange(e.target.value)

	const valueChange = (v: string): void => {
		setInternalInputValue(v)
		onChangeCurrent && onChangeCurrent(v)
	}

	const onRemoveClick = (indexItemToRemove: number): void => {
		setItems(items.filter((item, i) => i !== indexItemToRemove))
		focusInput()
	}

	const addInputValueToItems = (): void => {
		if (disableBlur) return

		const isAlreadySelected = items.includes(internalInputValue)
		if (!isAlreadySelected && internalInputValue) {
			valueChange('')
			setItems([...items, internalInputValue])
		}
	}

	const errorClass = isError && Styles.errorContainer

	return (
		<div className={`${Styles.container} ${className} ${errorClass}`} onClick={handleContainerClick}>
			<div className={Styles.elementsWrapper}>
				{items.map((item, index) => (
					<Badge className={Styles.badge} size="xs" variant="white" key={item + index}>
						<div className={Styles.badgeContent}>
							{item}{' '}
							<CloseIcon
								className={Styles.badgeIcon}
								onClick={(): void => {
									onRemoveClick(index)
								}}
							/>
						</div>
					</Badge>
				))}
				<Input
					messages={messages}
					variant="light"
					placeholder={placeholder}
					autoFocus={autoFocus}
					value={internalInputValue}
					onChange={handleOnChange}
					onKeyDown={handleKeyDown}
					autoComplete="off"
					onBlur={addInputValueToItems}
				/>
			</div>
		</div>
	)
}
