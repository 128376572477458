import React, { useEffect, useState } from 'react'
import { BasicButton } from '../'
import { deleteQueryParam, updateQueryParam } from '../../utils'
import { SearchIcon } from '../Icons'
import css from './SearchContainer.module.scss'
import { SearchContainerProps } from './interfaces'

export const SearchContainer = ({ handleSearchChange, searchText }: SearchContainerProps): React.ReactElement => {
	const paramKey = 'tableSearch'
	const [isSearchVisible, setIsSearchVisible] = useState(false)

	useEffect(() => {
		!isSearchVisible && handleSearchChange('')
	}, [isSearchVisible])

	const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		handleSearchChange(event.target.value)

		if (event.target.value) updateQueryParam({ [paramKey]: event.target.value })
		else deleteQueryParam(paramKey)
	}

	const loadParamValue = (): void => {
		const url = new URL(window.location.toString())
		const tableSearch = url.searchParams.get('tableSearch') as string
		if (tableSearch) {
			handleSearchChange(tableSearch)
			setIsSearchVisible(true)
		}
	}

	useEffect(() => {
		const mount = (): void => {
			loadParamValue()
		}
		mount()
	}, [])

	return (
		<div className={css['search-container']}>
			{isSearchVisible && <input autoFocus onChange={onSearchChange} value={searchText} />}
			<BasicButton
				size="sm"
				onClick={(): void => {
					setIsSearchVisible(!isSearchVisible)
				}}
				icon={SearchIcon}
			></BasicButton>
		</div>
	)
}
