import { AddCircleIcon, BasicButton, ListItem, SharePointIcon } from '@lynx/client-core/src/components'
import { useDebounce, usePrevious } from '@lynx/client-core/src/hooks'
import { AuthCookie, Logger } from '@lynx/client-core/src/modules'
import { useThunkDispatch } from 'hooks'
import update from 'immutability-helper'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { loadUserDrives, updateDriveVisibility, updateUserDrive, updateAllDriveVisibility, getDrivesSizes } from 'store/drive'
import { Card } from './Card'
import css from './CloudDrives.module.scss'
import { CloudDrivesCardBody, Item } from './CloudDrivesCardBody'
import { ItemTypes } from './ItemTypes'
import { APIRequest } from '@lynx/client-core/src/api'
import { DriveSettings, ProfileDriveItem } from '@lynx/core/src/interfaces/ObjectStore'
import { HomeDropdown } from 'pages/Drives/Controls/HomeDropdown'

export const CloudDrives = (): React.ReactElement => {
	const { drive } = useSelector((state: ApplicationState) => state)
	const [visibleCards, setVisibleCards] = useState<Item[]>(drive.drives?.visible || [])
	const [hiddenCards, setHiddenCards] = useState<Item[]>(drive.drives?.hidden || [])
	const prevVisibleCards = usePrevious(visibleCards)
	const [oneDriveSettings, setOneDriveSettings] = useState<DriveSettings | null>(null)
	const scrollToRef = useRef<HTMLDivElement | null>(null)
	const [isSharepointSwitchLoading, setIsSharepointSwitchLoading] = useState(false)
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const {
		drive: { drivesSizes }
	} = useSelector((state: ApplicationState) => state)

	const debounceUpdateDrives = useDebounce(async (updatedCards) => {
		await dispatchThunk(updateUserDrive(updatedCards))
	}, 1000)

	const moveCard = (dragIndex: number, hoverIndex: number): void => {
		setVisibleCards((prevCards: Item[]) => {
			const updatedCards = update(prevCards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevCards[dragIndex]]
				]
			})

			debounceUpdateDrives(updatedCards)
			return updatedCards
		})
	}

	useEffect(() => {
		setVisibleCards(drive.drives?.visible || [])
		setHiddenCards(drive.drives?.hidden || [])
	}, [drive.drives.visible])

	const handleHideDriveClicked = async (driveId: string): Promise<void> => {
		await dispatchThunk(updateDriveVisibility(driveId, true))
	}

	const handleUnhideDriveClicked = async (driveId: string): Promise<void> => {
		await dispatchThunk(updateDriveVisibility(driveId, false))
	}

	const handleHideAllDrivesClicked = async (): Promise<void> => {
		await dispatchThunk(updateAllDriveVisibility())
	}

	const renderCard = useCallback(
		(card: Item, index: number, isHidden = false) => {
			const driveUsage = drivesSizes.find(({ driveId, provider }) => (driveId ? driveId === card.driveId : true) && provider === card.provider)
			const usagePercentage = driveUsage ? (driveUsage.usedBytes / driveUsage.totalBytes) * 100 : null
			return (
				<Card id={card.driveId} key={card.driveId} index={index} moveCard={moveCard} itemType={ItemTypes.DRIVE} isDraggable={!isHidden}>
					<CloudDrivesCardBody
						drive={card}
						usagePercentage={usagePercentage}
						onHideDriveClicked={handleHideDriveClicked}
						onUnhideDriveClicked={handleUnhideDriveClicked}
						onUnlinked={fetchDrives}
						isHidden={isHidden}
					/>
				</Card>
			)
		},
		[drivesSizes]
	)

	const fetchDrives = async (): Promise<void> => {
		await dispatchThunk(loadUserDrives())
		!drivesSizes.length && (await dispatchThunk(getDrivesSizes()))
	}

	const getOneDriveSettings = async (): Promise<void> => {
		const oneDrive = getOneDriveData()
		if (!oneDrive) return
		const res = await APIRequest.ObjectStore.getDriveSettings('onedrive', oneDrive?.providerContainerId)
		if ('sharePointEnabled' in res) setOneDriveSettings(res)
	}

	useEffect(() => {
		fetchDrives()
	}, [])

	useEffect(() => {
		if (drive.drives && !oneDriveSettings) {
			getOneDriveSettings()
		}
	}, [drive.drives])

	useEffect(() => {
		if (prevVisibleCards?.length === 0 && visibleCards.length) {
			if (location.hash.substring(1) === 'cloud-drives') {
				scrollToRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'center'
				})
			}
		}
	}, [visibleCards])

	const getOneDriveData = (): ProfileDriveItem | undefined => {
		const findDrive = (drive: ProfileDriveItem): boolean => drive.provider === 'onedrive' && drive.type !== 'sharepoint'
		const oneDrive = drive.drives?.visible.find(findDrive) || drive.drives?.hidden.find(findDrive)
		return oneDrive
	}
	const enableSharepoint = async (status: boolean): Promise<void> => {
		const oneDrive = getOneDriveData()
		if (!oneDrive) return
		try {
			setIsSharepointSwitchLoading(true)
			await APIRequest.ObjectStore.updateDriveSettings('onedrive', oneDrive?.providerContainerId, { sharePointEnabled: status })
			setOneDriveSettings({ sharePointEnabled: status })
			await fetchDrives()
		} catch (err) {
			Logger.warn('error updating drive')
		} finally {
			setIsSharepointSwitchLoading(false)
		}
	}

	return (
		<ListItem header={t('pages.profile.linkedDrives')}>
			<div className={css['buttons']} ref={scrollToRef}>
				{getOneDriveData() && oneDriveSettings && (
					<BasicButton
						isLoading={isSharepointSwitchLoading}
						variant={'light'}
						onClick={(): Promise<void> => enableSharepoint(!oneDriveSettings.sharePointEnabled)}
					>
						<SharePointIcon />
						{(oneDriveSettings.sharePointEnabled ? 'Disable ' : 'Enable ') + 'SharePoint'}
					</BasicButton>
				)}
				<BasicButton variant="blue" onClick={handleHideAllDrivesClicked}>
					{t('pages.profile.hideAllDrives')}
				</BasicButton>
				<HomeDropdown buttonSize="md" />
			</div>
			<div className={css['container']}>{visibleCards.map((card, i) => renderCard(card, i, false))}</div>
			<h3 className={css['header']}>{t('pages.profile.hiddenDrives')}</h3>
			<div className={css['container']}>{hiddenCards.map((card, i) => renderCard(card, i, true))}</div>
		</ListItem>
	)
}
