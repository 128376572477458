import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, BulletPointItem, BulletPoints, CreateIcon, LynxLogoTextIcon } from '@lynx/client-core/src/components'
import { useBillingPlans } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { PlanFreeIcon, PlanOrgIcon, PlanProIcon } from 'components/PlanIcons/PlanIcons'
import { useBillingDetails } from 'hooks'
import { LynxCard } from 'pages/Pricing/components/LynxCard'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ApplicationState } from 'store'
import { setLoginModal } from 'store/profile'
import css from './Plans.module.scss'

enum ProState {
	hadPro,
	hasPro,
	noPro
}

interface PlansProps {
	currencyCode: string
}

export const Plans = ({ currencyCode }: PlansProps): React.ReactElement | null => {
	const { t } = useTranslation()
	const { isPro, hadPro } = useSelector((state: ApplicationState) => state.profile)
	const { billingPlans } = useBillingPlans(currencyCode)
	const profile = useSelector((state: ApplicationState) => state.profile)
	const { billingDetails } = useBillingDetails()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const getProState = (): ProState => {
		const active = billingDetails?.active ?? null
		if (hadPro) return ProState.hadPro
		if (isPro === false && active === null) return ProState.noPro
		return ProState.hasPro
	}

	const proState = getProState()

	const handleUpgradeToPro = async (withTrial = false): Promise<void> => {
		try {
			if (!billingPlans || !billingPlans['pro']?.stripePriceId) return

			const sessionResponse = await APIRequest.Billing.createCheckoutSession({
				lookup_key: billingPlans['pro'].stripePriceId,
				currency: currencyCode,
				withTrial
			})
			if (sessionResponse.url) {
				window.location.href = sessionResponse.url
			}
		} catch (e) {
			Logger.error('createCheckoutSession', e)
		}
	}

	const handleActionButton = (subscriptionType: string): void => {
		if (!billingPlans) return

		switch (subscriptionType) {
			case 'free': {
				if (!profile.loggedIn) {
					dispatch(setLoginModal(true))
					return
				}

				navigate('/more-downloads')
				break
			}
			case 'organisation':
				{
					const { redirect } = billingPlans[subscriptionType]
					if (!redirect) return
					navigate(redirect)
				}
				break
			case 'pro':
				if (!profile.loggedIn) {
					dispatch(setLoginModal(true))
					return
				}
				handleUpgradeToPro()
				break
			case 'pro-trial':
				if (!profile.loggedIn) {
					dispatch(setLoginModal(true))
					return
				}
				handleUpgradeToPro(true)
				break
			default:
				break
		}
	}

	if (!billingPlans) return null

	const classNames = [css['container']]

	return (
		<div className={classNames.join(' ')}>
			<LynxCard
				tintColor="#E7E7E7"
				header={
					<div>
						<div className={css['container__header']}>
							<LynxLogoTextIcon />
							<PlanFreeIcon />
						</div>
						<h3>{t('pages.pricing.free.description').toUpperCase()}</h3>
					</div>
				}
				footer={
					<BasicButton variant="light" size="lg" onClick={(): void => handleActionButton('free')}>
						{t('pages.pricing.buttonGetStarted')}
					</BasicButton>
				}
			>
				<div>
					<p>{t('pages.pricing.free.paragraph1')}:</p>
					<BulletPoints size="xs">
						{[
							{ icon: <CreateIcon fill="#979797" />, header: 'LYNX Whiteboard', text: t('pages.pricing.free.feature1') },
							{ icon: <CreateIcon fill="#979797" />, header: 'LYNX Activities', text: t('pages.pricing.free.feature2') },
							{
								icon: <CreateIcon fill="#979797" />,
								header: `${billingPlans['free'].storageLimitGB} GB`,
								text: `LYNX Cloud ${t('pages.pricing.free.feature3')}`
							}
						].map(({ header, icon, text }, key) => (
							<BulletPointItem key={key} header={header} icon={icon} text={text} />
						))}
					</BulletPoints>
				</div>
			</LynxCard>

			<LynxCard
				tintColor="#5632D4"
				header={
					<div>
						<div className={css['container__header']}>
							<LynxLogoTextIcon />
							<PlanProIcon />
						</div>
						<h3>
							{new Intl.NumberFormat('en-GB', {
								style: 'currency',
								currency: billingPlans['pro'].currency.toUpperCase()
							}).format(billingPlans['pro'].amount / 100)}
							<span>{` ${t('pages.pricing.perUser')}`}</span>
						</h3>
					</div>
				}
				footer={
					<div className={css['multiple-buttons']}>
						<BasicButton variant="pro" size="lg" onClick={(): void => handleActionButton('pro')} disabled={isPro}>
							Upgrade
						</BasicButton>
						{proState === ProState.noPro && (
							<>
								<BasicButton variant="light" size="lg" onClick={(): void => handleActionButton('pro-trial')} disabled={isPro}>
									{t('pages.pricing.tryItFree')}*
								</BasicButton>
								<div>*{t('pages.pricing.freeFor')}</div>
							</>
						)}
					</div>
				}
			>
				<div>
					<p>
						{t('pages.pricing.pro.paragraph1')} <strong>LYNX Free</strong>, {t('pages.pricing.pro.paragraph2')}:
					</p>
					<BulletPoints size="xs">
						{[
							{ icon: <CreateIcon fill="#5632D4" />, header: t('pages.pricing.pro.feature1'), text: t('pages.pricing.pro.feature2') },
							{ icon: <CreateIcon fill="#5632D4" />, header: t('pages.pricing.pro.feature3'), text: t('pages.pricing.pro.feature4') },
							{ icon: <CreateIcon fill="#5632D4" />, header: t('pages.pricing.pro.feature5'), text: t('pages.pricing.pro.feature6') },
							{
								icon: <CreateIcon fill="#5632D4" />,
								header: `${billingPlans['pro'].storageLimitGB} GB`,
								text: `LYNX Cloud ${t('pages.pricing.pro.feature7')}`
							}
						].map(({ header, icon, text }, key) => (
							<BulletPointItem key={key} header={header} icon={icon} text={text} />
						))}
					</BulletPoints>
				</div>
			</LynxCard>

			<LynxCard
				tintColor="#0063F7"
				header={
					<div>
						<div className={css['container__header']}>
							<LynxLogoTextIcon />
							<PlanOrgIcon />
						</div>
						<h3>{t('pages.pricing.org.description')}</h3>
					</div>
				}
				footer={
					<BasicButton variant="blue" size="lg" onClick={(): void => handleActionButton('organisation')}>
						{t('pages.pricing.buttonContactUs')}
					</BasicButton>
				}
			>
				<div>
					<p>
						{t('pages.pricing.org.paragraph1')} <strong>LYNX Pro</strong>, {t('pages.pricing.org.paragraph2')}:
					</p>
					<BulletPoints size="xs">
						{[
							{ icon: <CreateIcon fill="#0063F7" />, header: t('pages.pricing.org.feature1'), text: t('pages.pricing.org.feature2') },
							{
								icon: <CreateIcon fill="#0063F7" />,
								header: t('pages.pricing.org.feature3'),
								text: t('pages.pricing.org.feature4')
							},
							{
								icon: <CreateIcon fill="#0063F7" />,
								header: `${billingPlans['pro'].storageLimitGB} GB`,
								text: `${t('pages.pricing.org.userDrive')}`
							},
							{
								icon: <CreateIcon fill="#0063F7" />,
								header: `${billingPlans['organisation'].storageLimitGB} GB`,
								text: `${t('pages.pricing.org.organisationSharedDrive')}`
							}
						].map(({ header, icon, text }, key) => (
							<BulletPointItem key={key} header={header} icon={icon} text={text} />
						))}
					</BulletPoints>
				</div>
			</LynxCard>
		</div>
	)
}
