import { CloudIcon, LynxWhiteboardLogo } from '@lynx/client-core/src/components/Icons'
import {
	collectionShared,
	conversionCompleted,
	couldNotSendEmail,
	emailSentTo,
	fileDeleted,
	fileShared,
	fileUploaded,
	inviteEmailSendAction,
	linkCloudDrives,
	organisationHasBeenApplied,
	organisationInviteAccepted,
	organisationInviteSent,
	organisationNameNotFound,
	somethingWentWrong,
	subscribeNewsletter,
	translationRequestUpdate,
	logoutEverywhere,
	updateProfilePicture,
	enableOneDriveSharePoints,
	driveStorageLimitExceeded,
	driveStorageLimitNearlyFull
} from '@lynx/core/src/interfaces/Notifications'
import React from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteNotificationToast } from 'store/notifications'
import { NotificationVariantsProps } from './interfaces'
import css from './NotificationVariants.module.scss'
import { DefaultTemplate } from './NotificationVariantsDefaultTemplate'
import { NotificationVariantSubscribeNewsLetter } from './NotificationVariantSubscribeNewsLetter'
import { NotificationVariantEnableSharePointDrive } from './NotificationVariantsEnableSharePoints'
import { BasicButton } from '@lynx/client-core/src/components'

export const NotificationVariants = ({
	createdAt,
	notification,
	isViewed = false,
	onNotificationClick,
	isToast = false
}: NotificationVariantsProps): React.ReactElement => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { type, payload } = notification
	const defaultProps = {
		createdAt,
		notification,
		isViewed,
		isToast,
		onNotificationClick
	}

	switch (type) {
		case logoutEverywhere:
			return (
				<DefaultTemplate variant="information" {...defaultProps}>
					<div>{t('components.sessionList.youAreLoggedOutEverywhere')}</div>
				</DefaultTemplate>
			)
		case linkCloudDrives:
			return (
				<DefaultTemplate variant="information" customIcon={CloudIcon} {...defaultProps}>
					<div>
						{t('components.notifications.notificationVariants.clickHereTo')}
						<Link
							to="/drive"
							onClick={(): void => {
								ReactGA.event({
									action: `Link Cloud Drives [action:clicked]`,
									category: 'Notification'
								})
								isToast && dispatch(deleteNotificationToast(notification))
							}}
						>
							<b className={css.linkCloudDrivesLink}> {t('components.notifications.notificationVariants.linkCloudDrives')}</b>
						</Link>
						.
					</div>
				</DefaultTemplate>
			)

		case subscribeNewsletter:
			return <NotificationVariantSubscribeNewsLetter {...defaultProps} notification={notification} isToast={isToast} />

		case conversionCompleted:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						{t('components.notifications.notificationVariants.conversion')} <b>{payload.filename}</b>{' '}
						{t('components.notifications.notificationVariants.completed')}
					</div>
				</DefaultTemplate>
			)

		case fileUploaded:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						<b>{payload.filename}</b> {t('components.notifications.notificationVariants.hasFinishedUploading')}
					</div>
				</DefaultTemplate>
			)

		case fileDeleted:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						<b>{payload.filename}</b> {t('components.notifications.notificationVariants.hasBeenDeleted')}
					</div>
				</DefaultTemplate>
			)

		case fileShared:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						<b>{payload.filename}</b> {t('components.notifications.notificationVariants.hasBeenSharedWithYou')}
					</div>
				</DefaultTemplate>
			)

		case collectionShared:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						{t('components.notifications.notificationVariants.collection')} <b>{payload.collectionName}</b>{' '}
						{t('components.notifications.notificationVariants.collectionHasBeenSharedWithYou')}
					</div>
				</DefaultTemplate>
			)

		case organisationInviteSent:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						<b>{payload.title}</b> {t('components.notifications.notificationVariants.organisationSentYouAnInvite')}
					</div>
				</DefaultTemplate>
			)

		case organisationInviteAccepted:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						{payload.title} {payload.body}
					</div>
				</DefaultTemplate>
			)

		case emailSentTo:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						<span>
							{t('pages.login.emailSentTo')} {payload.email}
						</span>
					</div>
				</DefaultTemplate>
			)

		case couldNotSendEmail:
			return (
				<DefaultTemplate {...defaultProps} variant="error">
					<div>
						{t('pages.organisation.bulkCsv.couldNotSendEmail')}
						<b>{payload.email}</b>
					</div>
				</DefaultTemplate>
			)

		case somethingWentWrong:
			return (
				<DefaultTemplate {...defaultProps} variant="error">
					<div>{t('pages.organisation.bulkCsv.somethingWentWrong')}</div>
				</DefaultTemplate>
			)

		case inviteEmailSendAction:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						{t('pages.organisation.bulkCsv.inviteEmailTo')} <b>{payload.email}</b> {t('pages.organisation.bulkCsv.haveBeenSent')}
					</div>
				</DefaultTemplate>
			)

		case organisationHasBeenApplied:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						<b>{payload.organisationName}</b> {t('pages.organisation.hasBeenApplied')}
					</div>
				</DefaultTemplate>
			)

		case organisationNameNotFound:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>{t('pages.organisation.organisationNameNotFound')}</div>
				</DefaultTemplate>
			)

		case updateProfilePicture:
			return (
				<DefaultTemplate {...defaultProps} variant="information">
					<div>
						{t('components.notifications.notificationVariants.clickHereTo')}
						<Link
							to="/profile?modal=profile-picture"
							onClick={(): void => {
								ReactGA.event({
									action: `Profile picture redirect [action:clicked]`,
									category: 'Notification'
								})
								isToast && dispatch(deleteNotificationToast(notification))
							}}
						>
							<b className={css.linkCloudDrivesLink}> {t('components.notifications.notificationVariants.changeAvatar')}</b>
						</Link>
					</div>
				</DefaultTemplate>
			)
		case driveStorageLimitExceeded: {
			const { driveId, provider } = payload
			const query = driveId ? `?driveId=${driveId}` : ''
			const driveLabel = provider === 'lynxcloud' ? 'LYNX Cloud' : t('pages.organisation.organisation')
			const driveLink = (
				<span className={css.driveStorageLimitExceeded}>
					<BasicButton to={`/drive/${provider}${query}`} size="xs" variant="transparent">
						<LynxWhiteboardLogo height="22px" width="22px" className={css.icon} />
						{driveLabel}
					</BasicButton>
				</span>
			)
			return (
				<DefaultTemplate {...defaultProps} variant="error">
					<div className={css.driveStorageLimitExceededContainer}>
						{t('components.notifications.notificationVariants.driveStorageLimitExceeded1')}
						<b>{driveLink}</b> {t('components.notifications.notificationVariants.driveStorageLimitExceeded2')}
					</div>
				</DefaultTemplate>
			)
		}
		case driveStorageLimitNearlyFull: {
			const percentageOfUsedStorage = Math.round((payload.usedBytes / payload.totalBytes) * 100)
			return (
				<DefaultTemplate {...defaultProps} variant="warning">
					<div>
						<b>{percentageOfUsedStorage}%</b> {t('components.notifications.notificationVariants.driveStorageLimitNearlyFull')}
					</div>
				</DefaultTemplate>
			)
		}

		case enableOneDriveSharePoints:
			return <NotificationVariantEnableSharePointDrive {...defaultProps} notification={notification} isToast={isToast} />

		case translationRequestUpdate: {
			const {
				status,
				requestedTranslationsData: {
					0: { textFrom, textTo },
					length
				}
			} = payload
			return (
				<DefaultTemplate {...defaultProps} variant={status === 'pr-merged' ? 'success' : 'information'}>
					{length === 1 ? (
						<div>
							<div>
								{t('components.lynxTranslations.translationRequestStatus')}: <b>{status.replace('pr-', '')}</b>
							</div>
							<div>
								{t('components.lynxTranslations.from')}: <i>{textFrom}</i>
							</div>
							<div>
								{t('components.lynxTranslations.to')}: <i>{textTo}</i>
							</div>
						</div>
					) : (
						<div>
							{t('components.lynxTranslations.requestedTranslationStatusChangedTo')} <b>{status}</b>
						</div>
					)}
				</DefaultTemplate>
			)
		}
		default:
			return <div />
	}
}
