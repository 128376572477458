import React from 'react'
import { Label } from '../Label/Label'
import css from './FeatureItem.module.scss'

export interface FeatureItemProps {
	label: string
	accentColor?: string
	title: string
	body: string
	action: string
	onActionClicked?: () => void
	backgroundColor?: string
}

/**
 * Component: Container for text and interactions
 * @returns Feature
 */
export const FeatureItem = ({
	label,
	accentColor = '#e34f33',
	title,
	body,
	action,
	backgroundColor,
	onActionClicked
}: FeatureItemProps): React.ReactElement => {
	return (
		<div className={css['container']} style={{ backgroundColor }}>
			<Label accentColor={accentColor} text={label} />
			<h2 className={css['container__title']}>{title}</h2>
			<div className={css['container__body']}>{body}</div>
			{action && (
				<div className={css['container__action']} style={{ color: accentColor }} onClick={onActionClicked && onActionClicked}>
					{action}
				</div>
			)}
		</div>
	)
}
