import { AccessType } from '@lynx/client-core/src/api/interfaces'
import { DoneIcon } from '@lynx/client-core/src/components/Icons'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import i18next from 'i18next'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { updateAccessType } from 'store/sharing'
import Styles from './AccessPermissionsPopUp.module.css'
import { AccessPermissionsPopUpProps } from './interfaces'

export const AccessPermissionsPopUp = ({ isOpen }: AccessPermissionsPopUpProps): React.ReactElement => {
	const dispatchThunk = useThunkDispatch()
	const sharingState = useSelector((state: ApplicationState) => state.sharing)
	const classNames = [Styles['container']]
	isOpen && classNames.push(Styles['open'])
	const accessType = sharingState.share?.accessType || 'invited'

	const [categories, setCategoryOption] = React.useState<
		{ name: string; toggleable: boolean; options: { key: AccessType; selected: boolean; description: string }[] }[]
	>([
		{
			name: i18next.t('components.modals.sharingModal.whoAccess'),
			toggleable: false,
			options: [
				{
					key: 'anyone',
					selected: accessType === 'anyone',
					description: i18next.t('components.modals.sharingModal.anyone')
				},
				{
					key: 'invited',
					selected: accessType === 'invited',
					description: i18next.t('components.modals.sharingModal.invitedAccess')
				},
				{
					key: 'private',
					selected: accessType === 'private',
					description: i18next.t('components.modals.sharingModal.onlyMeAccess')
				}
			]
		},
		{
			name: i18next.t('components.modals.sharingModal.guestPermissions'),
			toggleable: true,
			options: [
				{
					key: 'private',
					selected: false,
					description: i18next.t('components.modals.sharingModal.allowEditing')
				}
			]
		}
	])

	const handleOptionClicked = async (e: React.MouseEvent<HTMLDivElement>, categoryIndex: number, optionIndex: number): Promise<void> => {
		e.stopPropagation()
		const updatedCategories = [...categories]
		const category = updatedCategories[categoryIndex]
		const { toggleable } = category

		let index = 0
		for (const option of category.options) {
			if (toggleable && option.selected && index === optionIndex) {
				option.selected = false
			} else {
				option.selected = index === optionIndex
				if (option.selected) {
					await dispatchThunk(updateAccessType(option.key))
				}
			}
			index++
		}

		setCategoryOption(updatedCategories)
	}

	const categoryComponents = categories.map((category, categoryIndex) => {
		const { name, options } = category

		const optionComponents = options.map((option, optionIndex) => {
			const { selected, description } = option

			const optionClassNames = [Styles['option']]
			selected && optionClassNames.push(Styles['option-selected'])

			return (
				<div
					key={optionIndex}
					className={optionClassNames.join(' ')}
					onClick={(e): Promise<void> => handleOptionClicked(e, categoryIndex, optionIndex)}
				>
					<DoneIcon style={{ visibility: selected ? 'visible' : 'hidden' }} /> {description}
				</div>
			)
		})
		return (
			<div key={name} className={Styles['title']}>
				{name}
				{optionComponents}
			</div>
		)
	})

	return <div className={classNames.join(' ')}>{categoryComponents}</div>
}
