import React from 'react'
import { useSelector } from 'react-redux'
import { useProfileIcon } from '../../hooks/useProfileIcon'
import { ApplicationState } from '../../store'
import css from './ProfileDropdownLoggedIn.button.module.scss'
import { ProBadgeIcon } from '../Icons'
import { TransitionSwitch } from '../Transition'

interface ProfileDropdownLoggedInButtonProps {
	onOpenClicked: () => void
}

export const ProfileDropdownLoggedInButton = ({ onOpenClicked }: ProfileDropdownLoggedInButtonProps): React.ReactElement => {
	const { profile } = useSelector((state: ApplicationState) => state)
	const { icon } = useProfileIcon()

	return (
		<button className={css['container']} onClick={onOpenClicked} aria-label="logout">
			<div className={css['container__image']}>{icon}</div>
			<TransitionSwitch elKey={String(profile.isPro)} className={css['container__badge']}>
				<>{profile.isPro && <ProBadgeIcon />}</>
			</TransitionSwitch>
		</button>
	)
}
