import { APIRequest } from '@lynx/client-core/src/api'
import { ArrowDropDownIcon, BasicButton, InputProps, LanguageDarkIcon, LinkIcon, SendIcon, Textarea } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { string } from '@lynx/core'
import { Multiselect } from 'components/Multiselect'
import { Section } from 'components/Sections'
import { ThirdPartyLinks } from 'components/ThirdPartyLinks'
import { InvitesSentPopUp } from 'components/modals/SharingModal/sections/components/InvitesSentPopUp'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { inviteShare } from 'store/sharing'
import css from './ShareSection.module.scss'
import { AccessPermissionsPopUp } from './components/AccessPermissionsPopUp'
import { ShareSectionProps } from './interfaces'
import { OrganisationGroupUsersInterface, OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'

interface FilteredGroup {
	groupName: string
	organisationName: string
	groupId: number | undefined
	organisationId: number
	users: OrganisationGroupUsersInterface[]
}

export const ShareSection = ({ navigate }: ShareSectionProps): React.ReactElement | null => {
	const [emailSentMessage, setEmailSentMessage] = useState<boolean>(false)
	const [linkCopied, setLinkCopied] = useState<boolean>(false)
	const [emails, setEmails] = useState<string[]>([])
	const [emailsInputValue, setEmailsInputValue] = useState<string>('')
	const [emailMessage, setEmailMessage] = useState<string>('')
	const [accessPermissionsVisible, setAccessPermissionsVisibility] = useState<boolean>(false)
	const dispatchThunk = useThunkDispatch()
	const sharingState = useSelector((state: ApplicationState) => state.sharing)
	const accessType = sharingState.share?.accessType || 'private'
	const { codeUrl } = sharingState
	const [organisations, setOrganisations] = useState<OrganisationRedux[]>([])
	const [filteredGroups, setFilteredGroups] = useState<FilteredGroup[]>([])
	const [inputMessages, setInputMessages] = useState<InputProps['messages']>([])

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const orgs = await APIRequest.Organisations.getOrganisationsByUser()
			if (!orgs?.data) return
			setOrganisations(orgs.data)
		}
		mount()
	}, [])

	const handlePermissionPopUp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault()
		e.stopPropagation()
		setAccessPermissionsVisibility(!accessPermissionsVisible)
	}

	const handleMessageChanged = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
		setEmailMessage(event.target.value)
	}

	const handleSectionClicked = (): void => {
		setAccessPermissionsVisibility(false)
	}

	const copyLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault()
		setLinkCopied(true)
		navigator.clipboard.writeText(codeUrl)
		setTimeout(() => {
			setLinkCopied(false)
		}, 1500)
	}

	const handleSendInviteClicked = async (): Promise<void> => {
		if (emailsInputValue) {
			setInputMessages([
				{
					type: 'error',
					message: i18next.t('components.modals.sharingModal.pressEnterToAdd')
				}
			])
			return
		} else {
			setInputMessages([])
		}

		for (const email of emails) {
			if (!string.isEmail(email)) {
				Logger.error(`invalid email: ${email}`)
				return
			}

			setEmailSentMessage(true)

			try {
				await dispatchThunk(inviteShare(email, 'viewer', emailMessage))
			} catch (e) {
				Logger.error(e)
			}
		}

		setEmails([])
		setEmailMessage('')

		setTimeout(() => {
			setEmailSentMessage(false)
		}, 1000)
	}

	const handleEmailInputChanged = (items: string[]): void => {
		setEmails(items)
	}

	const handleGroupEmailSearch = (item: string): void => {
		setEmailsInputValue(item)
		const groups: FilteredGroup[] = []

		if (item.length) {
			for (const o of organisations) {
				if (!o.groups?.length) continue
				if (o.organisationName.toLowerCase().startsWith(item.toLowerCase())) {
					for (const g of o.groups) {
						groups.push({
							groupName: g.groupName,
							organisationName: o.organisationName,
							groupId: g.groupId,
							organisationId: o.id,
							users: g.users || []
						})
					}
				}
			}
		}

		setFilteredGroups(groups)
	}

	const handleGroupSelected = (group: FilteredGroup): void => {
		const updatedEmails = [...emails]
		const groupUsersEmails = group.users.map((u) => u.email).filter((u) => u)

		setEmails(updatedEmails.concat(groupUsersEmails.filter((e) => emails.indexOf(e) === -1)))
		setFilteredGroups([])
	}

	if (!sharingState?.share || !sharingState?.driveItem) {
		return null
	}

	const accessTypeHeaderMap: { [key: string]: string } = {
		invited: i18next.t('components.modals.sharingModal.onlyInvited'),
		private: i18next.t('components.modals.sharingModal.privateOnly'),
		anyone: i18next.t('components.modals.sharingModal.anyone')
	}

	const { share, driveItem } = sharingState

	const GroupsSelect = (
		<div
			style={{
				display: filteredGroups.length ? 'block' : 'none'
			}}
			className={css['group-select']}
		>
			{filteredGroups.map((group, key) => {
				const { groupName, organisationName } = group
				return (
					<div key={key} onClick={(): void => handleGroupSelected(group)}>
						<h4>{groupName}</h4>
						<div>{organisationName}</div>
					</div>
				)
			})}
		</div>
	)

	return (
		<Section side="right" className={css['container']} onClick={handleSectionClicked}>
			<div className={css['container__body']}>
				<div className={css['permissions-link']}>
					<AccessPermissionsPopUp isOpen={accessPermissionsVisible} />
					<InvitesSentPopUp isOpen={emailSentMessage} />
				</div>

				<div className={css['container__content']}>
					<h3>
						{i18next.t('components.modals.sharingModal.share')} '{sharingState?.driveItem.name}'
					</h3>
					<BasicButton icon={LanguageDarkIcon} variant="light" onClick={handlePermissionPopUp} className={css['button-permissions']}>
						<div className={css['button-permissions__body']}>
							<div>{accessTypeHeaderMap[accessType]}</div>
							<ArrowDropDownIcon />
						</div>
					</BasicButton>
					<div>
						<div className={css['manage-people']}>
							<label>{i18next.t('components.modals.sharingModal.sendTo')}</label>
							<div
								style={{ cursor: 'pointer' }}
								onClick={(): void => {
									navigate('ManagePeopleSection', share)
								}}
							>
								{i18next.t('components.modals.sharingModal.managePeople')}
							</div>
						</div>
						<div className={css['multi-select-container']}>
							<Multiselect
								messages={inputMessages}
								disableBlur={true}
								value={emails}
								className={css['multi-select']}
								onChangeCurrent={handleGroupEmailSearch}
								onChange={handleEmailInputChanged}
								placeholder={i18next.t('components.modals.sharingModal.enterEmail')}
								forceValue={true}
								inputFilter={(internalInputValue): boolean => {
									return string.isEmail(internalInputValue)
								}}
							></Multiselect>
							{GroupsSelect}
						</div>
					</div>
					<div className={css['message-area']}>
						<Textarea
							value={emailMessage}
							placeholder={i18next.t('components.modals.sharingModal.message')}
							onChange={handleMessageChanged}
							maxLength={200}
							rows={5}
							cols={50}
							disableResize={true}
							variant="light"
						/>
					</div>

					{!linkCopied && (
						<div className={css['link-container']}>
							<BasicButton icon={LinkIcon} variant="light" onClick={copyLink}>
								<div>{i18next.t('components.modals.sharingModal.copyLink')}</div>
							</BasicButton>
							<BasicButton icon={SendIcon} variant="blue" onClick={handleSendInviteClicked}>
								<div>{i18next.t('components.modals.sharingModal.send')}</div>
							</BasicButton>
						</div>
					)}
					{linkCopied && (
						<div className={css['textfield-message']}>
							<div>{i18next.t('components.modals.sharingModal.linkCopied')}</div>
						</div>
					)}
				</div>
				<div>
					<div>{i18next.t('components.modals.sharingModal.socialSharing')}</div>
					<ThirdPartyLinks shareUrl={codeUrl} title="Social Sharing" />
				</div>
			</div>
		</Section>
	)
}
