import { APIRequest } from '../../../../../api'
import i18next from 'i18next'
import * as React from 'react'
import { useState } from 'react'
import { BasicButton, Input } from '../../../../'
import { Logger } from '../../../../../modules'
import { ArrowBackIcon, MailOutlineIcon } from '../../../../Icons'
import { Section } from '../../../../Sections'
import Styles from './RecoverPasswordSection.module.scss'
import { RecoverPasswordSectionProps } from './interfaces'

export const RecoverPasswordSection = ({ navigate: navigateSection }: RecoverPasswordSectionProps): React.ReactElement => {
	const [email, setEmail] = useState('')
	const [error, setError] = useState<'pages.login.invalidEmail' | ''>('')

	const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const email = event.target.value
		setEmail(email)
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault()
		event.stopPropagation()

		try {
			if (!isButtonAvailable()) {
				setError('pages.login.invalidEmail')
				return
			}
			setError('')

			await APIRequest.User.forgottenPassword(email)

			navigateSection('VerificationSection')
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleGoBack = (): void => {
		navigateSection('SignInSection')
	}

	const isButtonAvailable = (): boolean => {
		const validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
		return validEmail.test(email)
	}

	const resetButtonReady = isButtonAvailable()

	return (
		<Section
			side="right"
			title={
				<div className={Styles['title-container']}>
					<div className={Styles['back-arrow']} onClick={handleGoBack}>
						<ArrowBackIcon />
					</div>
					<div>{i18next.t('pages.login.forgotPassword')}</div>
				</div>
			}
			className={Styles['recover-section']}
		>
			<form className={Styles['form']} onSubmit={handleSubmit} autoComplete="off">
				<div className={Styles['enter-email-text']}>{i18next.t('pages.login.enterEmail')}</div>
				{error && <div className={Styles['error-message']}>{i18next.t(error)}</div>}
				<Input variant="light" type="text" name="email" placeholder="Email" onChange={handleEmailChanged} icon={MailOutlineIcon} />
				<BasicButton variant="blue" disabled={!resetButtonReady} elevation={true} type="submit">
					{i18next.t('pages.login.emailPassword')}
				</BasicButton>
			</form>
		</Section>
	)
}
