import { Disabled, Login } from 'pages'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { Root } from './App.root'

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		children: [
			{
				path: '',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Landing } = await import('pages/Landing/Landing')
					return { Component: Landing }
				}
			},
			{
				path: 'access-denied',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { AccessDenied } = await import('pages/AccessDenied/AccessDenied')
					return { Component: AccessDenied }
				}
			},
			{
				path: 'admin/*',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Admin } = await import('pages/Admin/Admin')
					return { Component: Admin }
				}
			},
			{
				path: 'auth/*',
				element: <Login />
			},
			{
				path: 'auth/logout',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Logout } = await import('pages/Logout/Logout')
					return { Component: Logout }
				}
			},
			{
				path: 'auth/logout/everywhere',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { LogoutEverywhere } = await import('pages/LogoutEverywhere/LogoutEverywhere')
					return { Component: LogoutEverywhere }
				}
			},
			{
				path: 'autologin',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { AutoLogin } = await import('pages/Login/AutoLogin')
					return { Component: AutoLogin }
				}
			},
			{
				path: 'cancel-membership',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { CancelMembership } = await import('pages/CancelMembership/CancelMembership')
					return { Component: CancelMembership }
				}
			},
			{
				path: 'change-logs/:product/:environment/:os',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Versions } = await import('pages/Versions/Versions')
					return { Component: Versions }
				}
			},
			{
				path: 'contact-us',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { ContactUs } = await import('pages/ContactUs/ContactUs')
					return { Component: ContactUs }
				}
			},
			{
				path: 'disabled',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					return { Component: Disabled }
				}
			},
			{
				path: 'download-activities',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { DownloadActivities } = await import('pages/DownloadActivities/DownloadActivities')
					return { Component: DownloadActivities }
				}
			},
			{
				path: 'download-screenshare',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { DownloadScreenShare } = await import('pages/DownloadScreenShare/DownloadScreenShare')
					return { Component: DownloadScreenShare }
				}
			},
			{
				path: 'download-recorder',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { DownloadScreenRecorder } = await import('pages/DownloadScreenRecorder/DownloadScreenRecorder')
					return { Component: DownloadScreenRecorder }
				}
			},
			{
				path: 'drive/open/:driveItemId',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Open } = await import('pages/Drives/Open')
					return { Component: Open }
				}
			},
			{
				path: 'drive/:provider?',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { DrivePage } = await import('pages/Drives/DrivePage')
					return { Component: DrivePage }
				}
			},
			{
				path: 'device-activity',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { DeviceActivity } = await import('pages/DeviceActivity/DeviceActivity')
					return { Component: DeviceActivity }
				}
			},
			{
				path: 'enquiry',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Enquiry } = await import('pages/Enquiry/Enquiry')
					return { Component: Enquiry }
				}
			},
			{
				path: 'error',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Error } = await import('@lynx/client-core/src/pages/Error/Error')
					return { Component: Error }
				}
			},
			{
				path: 'get-started',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { GetStarted } = await import('pages/GetStarted/GetStarted')
					return { Component: GetStarted }
				}
			},
			{
				path: 'goodbye',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Goodbye } = await import('pages/Goodbye/Goodbye')
					return { Component: Goodbye }
				}
			},
			{
				path: 'join',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Join } = await import('pages/Join/Join')
					return { Component: Join }
				}
			},
			{
				path: 'join/:code',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Join } = await import('pages/Join/Join')
					return { Component: Join }
				}
			},
			{
				path: 'missing-share',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { MissingShare } = await import('pages/MissingShare/MissingShare')
					return { Component: MissingShare }
				}
			},
			{
				path: 'more-downloads',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { MoreDownloads } = await import('pages/MoreDownloads/MoreDownloads')
					return { Component: MoreDownloads }
				}
			},
			{
				path: 'plans',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Pricing } = await import('pages/Pricing/Pricing')
					return { Component: Pricing }
				}
			},
			{
				path: 'notifications',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Notifications } = await import('pages/Notifications/Notifications')
					return { Component: Notifications }
				}
			},
			{
				path: 'organisation/:orgId/invite/:inviteId/organisationName/:organisationName', // deprecated can be removed at some point
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { OrganisationInvite } = await import('pages/OrganisationInvite/OrganisationInvite')
					return { Component: OrganisationInvite }
				}
			},
			{
				path: 'organisation/:orgId/invite/:inviteId',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { OrganisationInvite } = await import('pages/OrganisationInvite/OrganisationInvite')
					return { Component: OrganisationInvite }
				}
			},
			{
				path: 'organisations',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Organisations } = await import('pages/Organisations/Organisations')
					return { Component: Organisations }
				}
			},
			{
				path: 'organisations/:organisationId',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Organisations } = await import('pages/Organisations/Organisations')
					return { Component: Organisations }
				}
			},
			{
				path: 'privacy',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Privacy } = await import('pages/Privacy/Privacy')
					return { Component: Privacy }
				}
			},
			{
				path: 'profile',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Profile } = await import('pages/Profile/Profile')
					return { Component: Profile }
				}
			},
			{
				path: '/payment-history',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { PaymentHistory } = await import('pages/Profile/components/PaymentHistory')
					return { Component: PaymentHistory }
				}
			},
			{
				path: '/payment-method',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { PaymentMethod } = await import('pages/Profile/components/PaymentMethod')
					return { Component: PaymentMethod }
				}
			},
			{
				path: 'profile-delete',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { ProfileDelete } = await import('pages/ProfileDelete/ProfileDelete')
					return { Component: ProfileDelete }
				}
			},
			{
				path: 'registration-success',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { RegistrationSuccess } = await import('pages/RegistrationSuccess/RegistrationSuccess')
					return { Component: RegistrationSuccess }
				}
			},
			{
				path: 's/:code',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { ShortURLProxy } = await import('pages/ShortURLProxy/ShortURLProxy')
					return { Component: ShortURLProxy }
				}
			},
			{
				path: 'search',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const Search = await import('pages/Search/Search').then((module) => module.default)
					return { Component: Search }
				}
			},
			{
				path: 'portal',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const Search = await import('pages/Portal/Portal').then((module) => module.default)
					return { Component: Search }
				}
			},
			{
				path: 'features/:featureId',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const Search = await import('pages/Portal/PortalPage').then((module) => module.default)
					return { Component: Search }
				}
			},
			{
				path: 'share/:token/*',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Share } = await import('pages/Share/Share')
					return { Component: Share }
				}
			},
			{
				path: 'support',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Support } = await import('pages/Support/Support')
					return { Component: Support }
				}
			},
			{
				path: 'support/faq',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const FAQ = await import('pages/FAQ/FAQ').then((module) => module.default)
					return { Component: FAQ }
				}
			},
			{
				path: 'support/documents',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Documentation } = await import('pages/Documentation/Documentation')
					return { Component: Documentation }
				}
			},
			{
				path: 'support/license',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { License } = await import('pages/License/License')
					return { Component: License }
				}
			},
			{
				path: 'license',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { License } = await import('pages/License/License')
					return { Component: License }
				}
			},
			{
				path: 'support/privacy',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Privacy } = await import('pages/Privacy/Privacy')
					return { Component: Privacy }
				}
			},
			{
				path: 'support/terms',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Terms } = await import('pages/Terms/Terms')
					return { Component: Terms }
				}
			},
			{
				path: 'support/account-deletion',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { AccountDeletion } = await import('pages/AccountDeletion/AccountDeletion')
					return { Component: AccountDeletion }
				}
			},
			{
				path: 'authenticated',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { Authenticated } = await import('@lynx/client-core/src/pages/Authenticated/Authenticated')
					return { Component: Authenticated }
				}
			},
			{
				path: 'upgrade',
				async lazy(): Promise<{ Component: React.ComponentType }> {
					const { UpsellPage } = await import('pages/Upsell/Upsell')
					return { Component: UpsellPage }
				}
			},
			{
				path: '*',
				element: <Navigate to="/" replace />
			}
		]
	}
])
