import { BaseCall } from '../BaseCall'
import { APIConfig, Country } from '../interfaces'

export class Countries extends BaseCall {
	public get = async (): Promise<Country[]> => {
		try {
			const response = await this.client.get<Country[]>('/view/elmstone/countries')

			if (response.status === 200) {
				return response.data
			}

			return []
		} catch (e) {
			console.error(e)
			return []
		}
	}

	public fix = async (country: { badName: string; countryId: number }): Promise<boolean> => {
		try {
			const response = await this.client.post('/view/elmstone/country-fix', country)

			return response.status === 200
		} catch (e) {
			console.error(e)
			return false
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}