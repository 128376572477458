import { Document } from '@lynx/client-core/src/components'
import { showModal } from '@lynx/client-core/src/store/modal'
import { WasmModal } from 'components/modals/WasmModal/WasmModal'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { WasmModalContextType } from 'store/modal'

// to test this locally you need following files in public/wasm folder
// LYNX.wasm
// +
// removed comments from the headers 'packages/client-cloud/craco.config.js'
export const Wasm = (): React.ReactElement => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(showModal({ name: WasmModalContextType }))
	}, [])

	return (
		<Document title="Wasm" description="Wasm" keywords="Wasm, LYNX" auth={true} disableOverflow={true}>
			<WasmModal />
		</Document>
	)
}
