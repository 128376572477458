import { DeleteDarkIcon, EditDarkIcon, GrabHandleDotsIcon, OpenIcon, OrganisationsGroupIcon } from '@lynx/client-core/src/components'
import { AreYouSureModalContextType, showModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteOrganisation } from 'store/organisations'
import css from './OrganisationsCardBody.module.scss'
import { ProfileOverlay } from './ProfileOverlay'
import { OrganisationAvatar } from 'pages/Organisations/components/OrganisationAvatar'

export interface Item {
	id: string
	organisationId: number
	name: string
	userCount: number
	avatarUrl?: string
}

interface OrganisationCardBodyProps {
	organisation: Item
	onDeleted: () => void
}

export const OrganisationsCardBody = ({
	organisation: { name, userCount, organisationId, avatarUrl },
	onDeleted
}: OrganisationCardBodyProps): React.ReactElement => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatchThunk = useThunkDispatch()

	const handleDeleteOrganisation = async (): Promise<void> => {
		dispatch(
			showModal({
				name: 'AreYouSure',
				context: {
					type: AreYouSureModalContextType,
					confirmText: t('pages.organisation.deleteOrganisation'),
					confirmBody: t('pages.organisation.deleteOrganisationBody'),
					handleConfirm: async () => {
						await dispatchThunk(deleteOrganisation(organisationId))
						onDeleted()
					}
				}
			})
		)
	}

	const handleEditOrganisation = async (): Promise<void> => {
		navigate(`/organisation/${organisationId}`)
	}

	const handleOpenOrganisation = async (): Promise<void> => {
		navigate(`/organisation/${organisationId}`)
	}

	return (
		<div className={css['container']}>
			<GrabHandleDotsIcon />
			{avatarUrl && <OrganisationAvatar avatarUrl={avatarUrl} />}
			<div>{name}</div>
			<div className={css['container__users']}>
				<OrganisationsGroupIcon />
				<div>{userCount}</div>
			</div>
			<div className={css['container__open']}>
				<OpenIcon onClick={handleOpenOrganisation} />
			</div>
			<div className={css['container__ellipses']}>
				<ProfileOverlay>
					<ul>
						<li onClick={handleDeleteOrganisation}>
							<DeleteDarkIcon /> {t('pages.profile.delete')}
						</li>
						<li onClick={handleEditOrganisation}>
							<EditDarkIcon /> {t('pages.profile.edit')}
						</li>
					</ul>
				</ProfileOverlay>
			</div>
		</div>
	)
}
