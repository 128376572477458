import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import i18next from 'i18next'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { hideModal } from 'store/modal'
import Styles from './ResetPasswordModal.module.css'

export const ResetPasswordModal = (): React.ReactElement => {
	const dispatch = useDispatch()
	const { profile } = useSelector((state: ApplicationState) => state)

	const handleOkayClicked = (): void => {
		dispatch(hideModal())
	}

	return (
		<Modal name="ResetPasswordModal" title={i18next.t('pages.profile.ResetPasswordModal.emailSent')}>
			<ModalDefaultLayout
				title={''}
				body={
					<div className={Styles['body']}>
						<h4>{i18next.t('pages.profile.ResetPasswordModal.emailSent')}</h4>
						<div>
							{i18next.t('pages.profile.ResetPasswordModal.sentTo')} {`${profile.email}. `}
							{i18next.t('pages.profile.ResetPasswordModal.clickLink')}
						</div>
						<h4>{i18next.t('pages.profile.ResetPasswordModal.emailNotReceived')}</h4>
						<div>{i18next.t('pages.profile.ResetPasswordModal.emailsBlocked')}</div>
					</div>
				}
				footer={
					<BasicButton variant="blue" onClick={handleOkayClicked}>
						{i18next.t('pages.profile.ResetPasswordModal.okay')}
					</BasicButton>
				}
			></ModalDefaultLayout>
		</Modal>
	)
}
