export const isDaysOld = (date: Date, days: number): boolean => {
	const daysOld = new Date().getTime() - date.getTime()
	return daysOld > days * 24 * 60 * 60 * 1000
}

export const isHoursOld = (date: Date, hours: number): boolean => {
	const timeDiff = new Date().getTime() - date.getTime()
	return timeDiff > hours * 60 * 60 * 1000
}

export const getUnixTimestamp = (): number => {
	return Math.floor(new Date().getTime() / 1000)
}

export const getUnixTimestampFromDate = (date: Date): number => {
	return Math.floor(date.getTime() / 1000)
}

export const getUnixTimestampFromDateOrString = (date: Date | string): number => {
	if (typeof date === 'string') {
		return getUnixTimestampFromDate(new Date(date))
	} else {
		return getUnixTimestampFromDate(date)
	}
}

export const addDateSeconds = (d: string, seconds: number): string => {
	const date = new Date(d)
	date.setSeconds(date.getSeconds() + seconds)
	return date.toISOString()
}

export const getDateFromUnixTimestamp = (timestamp: number): Date => {
	return new Date(timestamp * 1000)
}

export const getCurrentDateStartOfDay = (): Date => {
	const date = new Date()
	date.setHours(0, 0, 0, 0)
	return date
}

export const getCurrentDateStartOfDayInTimestamp = (): number => {
	return getUnixTimestampFromDate(getCurrentDateStartOfDay())
}

export const getDaysBetweenDates = (date1: Date, date2: Date): number => {
	const diff = Math.abs(date1.getTime() - date2.getTime())
	return Math.ceil(diff / (1000 * 3600 * 24))
}

export const getDaysBetweenUnixTimestamps = (timestamp1: number, timestamp2: number): number => {
	const date1 = getDateFromUnixTimestamp(timestamp1)
	const date2 = getDateFromUnixTimestamp(timestamp2)
	return getDaysBetweenDates(date1, date2)
}

export const addDaysToDate = (date: Date, days: number): Date => {
	const newDate = new Date(date)
	newDate.setDate(newDate.getDate() + days)
	return newDate
}

export const addDaysToUnixTimestamp = (timestamp: number, days: number): number => {
	const date = getDateFromUnixTimestamp(timestamp)
	return getUnixTimestampFromDate(addDaysToDate(date, days))
}

export const getWeekNumber = (date: Date): number => {
	const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
	const dayNum = d.getUTCDay() || 7
	d.setUTCDate(d.getUTCDate() + 4 - dayNum)
	const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
	return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)
}

export const getDateISO = (date: Date | string | number): string => {
	if (typeof date === 'string') {
		return new Date(date).toISOString()
	} else if (typeof date === 'number') {
		return new Date(date).toISOString()
	} else {
		return date.toISOString()
	}
}

export const getCurrentDateISO = (): string => {
	return getDateISO(new Date())
}

export const dateToISOString = (dateToFormat: string | number | Date): string => {
	const date = new Date(dateToFormat)
	return date.toISOString()
}

export const subtractDaysFromDate = (date: Date, days: number): Date => {
	return addDaysToDate(date, -days)
}

export const subtractDaysFromUnixTimestamp = (timestamp: number, days: number): number => {
	return addDaysToUnixTimestamp(timestamp, -days)
}

export const getDate = (dateString: string | number | Date = new Date()): Date => {
	if (dateString) {
		return new Date(dateString)
	}

	return new Date()
}

export const subtractDaysFromNow = (days: number): Date => {
	return subtractDaysFromDate(new Date(), days)
}

export const isDateAfter = (date1: Date, date2: Date): boolean => {
	return date1.getTime() > date2.getTime()
}

export const isDateBefore = (date1: Date, date2: Date): boolean => {
	return date1.getTime() < date2.getTime()
}

export const formatVideoDuration = (duration: string): string => {
	const a = duration.match(/\d+/g)

	if (!a) {
		return ''
	}

	if (duration.indexOf('M') >= 0 && duration.indexOf('H') === -1 && duration.indexOf('S') === -1) {
		a[0] = '0'
	}

	if (duration.indexOf('H') >= 0 && duration.indexOf('M') === -1) {
		a[1] = '0'
	}

	if (duration.indexOf('H') >= 0 && duration.indexOf('M') === -1 && duration.indexOf('S') === -1) {
		a[1] = '0'
		a[2] = '0'
	}

	duration = ''

	if (a.length === 3) {
		duration = `${a[0]}:${a[1]}:${a[2]}`
	}

	if (a.length === 2) {
		duration = `${a[0]}:${a[1]}`
	}

	if (a.length === 1) {
		duration = `${a[0]}`
	}

	return duration
}

export const formatDateString = (date: Date, format: string): string => {
	return format
		.replace('YYYY', date.getFullYear().toString())
		.replace('MMM', date.toLocaleString('default', { month: 'short' }))
		.replace('ddd', date.toLocaleString('default', { weekday: 'short' }))
		.replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'))
		.replace('DD', date.getDate().toString().padStart(2, '0'))
		.replace('hh', date.getHours().toString().padStart(2, '0'))
		.replace('HH', date.getHours().toString().padStart(2, '0'))
		.replace('mm', date.getMinutes().toString().padStart(2, '0'))
		.replace('ss', date.getSeconds().toString().padStart(2, '0'))
		.replace('SS', date.getMilliseconds().toString().padStart(3, '0'))
		.replace('d', date.getDate().toString())
}

export const formatDateOrString = (date: Date | string, format: string): string => {
	if (typeof date === 'string') {
		date = new Date(date)
	}
	return formatDateString(date, format)
}

export const getCurrentDateInUTC = (): Date => {
	return new Date(Date.now())
}

export const getDateInUTC = (date: Date): Date => {
	return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}

export const subtractMonthsFromDate = (date: Date, months: number): Date => {
	const newDate = new Date(date)
	newDate.setMonth(newDate.getMonth() - months)
	return newDate
}

export const subtractMonthsFromNow = (months: number): Date => {
	return subtractMonthsFromDate(new Date(), months)
}

export const subtractMonthsFromNowInUnixTimestamp = (months: number): number => {
	return getUnixTimestampFromDate(subtractMonthsFromNow(months))
}

export const addDaysToNow = (days: number): Date => {
	return addDaysToDate(new Date(), days)
}

export const getCurrentDate = (): Date => {
	return getDate()
}
