import { BaseCall } from '../BaseCall'
import { AxiosResponse } from 'axios'
import { APIConfig } from '../interfaces'

interface CodeGenResult {
	generatedKeys: [{
		CombinedKey: string;
		GenDate: string;
		keyCount: number;
		licenseType: string;
		lynxVersion: number;
		PONumber: string;
		Prefix: string;
		startKey: number;
		Who: string;
	}];
	maxVersion: number;
	nextSeq: number;
}

export class CodeGen extends BaseCall {
	public getCodeGen(): Promise<CodeGenResult> {
		return this.client.get('/view/elmstone/code-gen')
			.then((response) => {
				return response.data
			})
	}

	public postCodeGen(data: any): Promise<AxiosResponse> {
		return this.client.post('/api-v2/elmstone/code-gen', data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
	}

	public setConfig(config: APIConfig) {
		this.setBaseUrl(config.API_SERVER)
	}
}