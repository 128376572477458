import React from 'react'
import { useTranslation } from 'react-i18next'
import { BasicButton } from '../BasicButton/BasicButton'
import { AccountWhiteIcon } from '../Icons'
import css from './ProfileDropdownLoggedOut.button.module.scss'
import { ProfileDropdownLoggedOutMobileButton } from './ProfileDropdownLoggedOutMobile.button'

interface ProfileDropdownLoggedOutButtonProps {
	onSignInClicked: () => void
	onOpenClicked: () => void
}

export const ProfileDropdownLoggedOutButton = ({ onSignInClicked, onOpenClicked }: ProfileDropdownLoggedOutButtonProps): React.ReactElement => {
	const { t } = useTranslation()

	return (
		<React.Fragment>
			<BasicButton aria-label="login" variant="blue" size="oval" icon={AccountWhiteIcon} onClick={onSignInClicked} className={css['desktop']}>
				<div>{t('components.profile.signIn')}</div>
			</BasicButton>
			<ProfileDropdownLoggedOutMobileButton onOpenClicked={onOpenClicked} className={css['mobile']} />
		</React.Fragment>
	)
}
