import React, { useState } from 'react'
import css from './StarRating.module.scss'
import { StarFeedbackIcon, StarSelectedIcon } from '../Icons'

const Star = ({ selected = false, onClick }: { selected?: boolean; onClick?: () => void }): React.ReactElement =>
	selected ? (
		<div onClick={onClick}>
			<StarSelectedIcon className={css['star']} />
		</div>
	) : (
		<div onClick={onClick}>
			<StarFeedbackIcon className={css['star']} />
		</div>
	)

const StarRating = ({
	totalStars = 5,
	initialRating = 0,
	onRating
}: {
	totalStars?: number
	initialRating?: number
	onRating?: (rating: number) => void
}): React.ReactElement => {
	const [rating, setRating] = useState(initialRating)

	const handleRating = (index: number): void => {
		setRating(index + 1)
		if (onRating) {
			onRating(index + 1)
		}
	}

	return (
		<div className={css['star-rating']}>
			{[...Array(totalStars)].map((_, i) => (
				<Star key={i} selected={i < rating} onClick={(): void => handleRating(i)} />
			))}
		</div>
	)
}

export default StarRating
