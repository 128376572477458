import { APIRequest } from '@lynx/client-core/src/api'
import { Alert, BasicButton, Checkbox } from '@lynx/client-core/src/components'
import ModalBase from '@lynx/client-core/src/components/Modal/Modal'
import { extractResponseError } from '@lynx/core'
import { CustomerSearch, Price } from '@lynx/core/src/interfaces'
import { SearchOrganisation } from '@lynx/core/src/interfaces/Organisation'
import { STRIPE_ERROR } from '@lynx/core/src/interfaces/ServerErrors'
import { OrganisationSearch } from 'pages/Admin/routes/elmstone/Subscriptions/OrganisationsList/OrganisationSubscription/OrganisationSearch'
import { OrganisationSubscriptionPrice } from 'pages/Admin/routes/elmstone/Subscriptions/OrganisationsList/OrganisationSubscription/OrganisationSubscriptionPrice'
import { SubscriptionCustomer } from 'pages/Admin/routes/elmstone/Subscriptions/OrganisationsList/OrganisationSubscription/SubscriptionCustomer'
import { CurrencyDropdown } from 'pages/Pricing/components'
import { useEffect, useState } from 'react'
import css from './OrganisationNewSubscription.module.scss'

interface Props {
	closeModal: () => void
}

export const OrganisationNewSubscription = ({ closeModal }: Props): React.ReactElement => {
	const [selectedCustomer, setSelectedCustomer] = useState<CustomerSearch | null>(null)
	const [selectedOrganisation, setSelectedOrganisation] = useState<SearchOrganisation | null>(null)
	const [selectedPrice, setSelectedPrice] = useState<Price | null>(null)
	const [userLicensesQuantity, setUserLicensesQuantity] = useState<number>(0)
	const [isSendInvoice, setIsSendInvoice] = useState<boolean>(false)
	const [currencyCode, setCurrencyCode] = useState('gbp')
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState(false)
	const [newSubscriptionId, setNewSubscriptionId] = useState('')

	const onSubscriptionSubmit = async (): Promise<void> => {
		if (!selectedCustomer || !selectedOrganisation || !selectedPrice) return

		setIsLoading(true)
		setErrorMessage(null)

		try {
			const res = await APIRequest.Billing.createOrganisationSubscription({
				customerId: selectedCustomer.id,
				organisationId: selectedOrganisation.id,
				priceId: selectedPrice.id,
				userLicensesQuantity: selectedPrice?.type === 'per_unit' ? 1 : userLicensesQuantity,
				isSendInvoice,
				currency: currencyCode,
				coupon: null
			})
			setNewSubscriptionId(res.subscriptionId)
		} catch (e: any) {
			const responseError = extractResponseError(e)
			const isDriveSizeExceededError = responseError?.name === STRIPE_ERROR
			if (isDriveSizeExceededError) setErrorMessage(responseError.options.stripeErrorMessage)
			else setErrorMessage(e?.message)
		} finally {
			setIsLoading(false)
		}
	}

	const onCustomerSelected = (customer: CustomerSearch | null): void => {
		setSelectedCustomer(customer)
	}

	const populateCustomer = async (organisation: SearchOrganisation): Promise<void> => {
		if (organisation.subscriptionData?.customerId) {
			try {
				const data = await APIRequest.Billing.getStripeCustomer(organisation.subscriptionData.customerId)
				if (data) setSelectedCustomer(data)
			} catch (e) {
				// do nothing
			}
		}
	}

	useEffect(() => {
		if (selectedOrganisation) populateCustomer(selectedOrganisation)
	}, [selectedOrganisation])

	return (
		<ModalBase name="New Subscription" title="New Subscription" uncontrolled onHidden={closeModal} innerContainerClasses={css.modalInnerContainer}>
			<div className={css.container}>
				<OrganisationSearch onOrganisationSelected={setSelectedOrganisation} />
				<SubscriptionCustomer onCustomerSelected={onCustomerSelected} selectedCustomer={selectedCustomer} />
				<OrganisationSubscriptionPrice onPriceSelected={setSelectedPrice} onUserLicensesQuantitySelected={setUserLicensesQuantity} />
				<div>
					<div className={css.currencyLabel}>Currency:</div>
					<CurrencyDropdown currencyCode={currencyCode} onItemClicked={(code): void => setCurrencyCode(code)} />
				</div>
				<div>
					Send Invoice
					<Checkbox checked={isSendInvoice} onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setIsSendInvoice(e.target.checked)} />
				</div>
				<div className={css.controlsContainer}>
					{Boolean(selectedOrganisation?.isPro && selectedOrganisation.subscriptionData) && (
						<Alert styleClass="warning" className={css.alertEl}>
							Organisation (ID: {selectedOrganisation?.id}) already has active subscription, new subscription will replace the existing one
						</Alert>
					)}
					{errorMessage && (
						<Alert styleClass="danger" className={css.alertEl}>
							{errorMessage}
						</Alert>
					)}
					{newSubscriptionId && (
						<Alert styleClass="success" className={css.alertEl}>
							Subscription has been created
						</Alert>
					)}
					<div className={css.btnContainer}>
						<BasicButton onClick={closeModal}>Cancel</BasicButton>
						<BasicButton
							variant="blue"
							onClick={onSubscriptionSubmit}
							isLoading={isLoading}
							disabled={!(selectedCustomer && selectedOrganisation && selectedPrice)}
						>
							Create
						</BasicButton>
					</div>
				</div>
			</div>
		</ModalBase>
	)
}
