import React from 'react'
import css from './FeatureSection.module.scss'

export interface FeatureSectionProps {
	children: React.ReactNode
	backgroundColor?: string
	align?: 'start' | 'center' | 'end'
	className?: string
	height?: 'auto' | 'full'
	inverted?: 'none' | 'always' | 'mobile'
	smoothImage?: boolean
}

/**
 * Component: Container to align and justify children
 * @returns FeatureSection
 */
export const FeatureSection = ({
	align = 'center',
	height = 'full',
	children,
	backgroundColor = '#ffffff',
	className,
	inverted = 'none',
	smoothImage = false
}: FeatureSectionProps): React.ReactElement => {
	const classNames = [css['container']]
	smoothImage && classNames.push(css['container__smooth-image'])
	className && classNames.push(className)

	height === 'auto' && classNames.push(css['container--auto-height'])

	return (
		<section className={classNames.join(' ')} style={{ backgroundColor }}>
			<div className={[css['container__grid'], inverted === 'always' && css['container--inverted']].join(' ')} style={{ alignItems: align }}>
				{children}
			</div>
		</section>
	)
}
