import { APIRequest } from '../../api'
import { BasicButton, BulletPointItem, BulletPoints, Card, CreateIcon } from '../../components'
import { ProUpgradeSrc } from '../Illustrations'
import { Logger } from '../../modules'
import { useBillingPlans } from '../../hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './Upsell.module.scss'

const bulletPointColor = '#5632D4'

interface UpsellProps {
	redirect?: { success_url: string; cancel_url: string }
}

export const Upsell = ({ redirect }: UpsellProps): React.ReactElement | null => {
	const { t } = useTranslation()
	const { billingPlans } = useBillingPlans()

	const handleTryItFreeClicked = async (): Promise<void> => {
		try {
			if (!billingPlans || !billingPlans['pro']?.stripePriceId) return
			const sessionResponse = await APIRequest.Billing.createCheckoutSession({ lookup_key: billingPlans['pro'].stripePriceId, redirect })
			if (sessionResponse.url) {
				window.location.href = sessionResponse.url
			}
		} catch (e) {
			Logger.error('createCheckoutSession', e)
		}
	}

	if (!billingPlans) {
		return null
	}

	return (
		<Card className={css['container']} enableBorder={false} enablePadding={false} style={{ maxWidth: 'unset' }}>
			<div className={css['container__grid']}>
				<div className={css['container__left']}>
					<div>
						<h3>{t('pages.billing.getUpgrade')}</h3>
						<p>{t('pages.billing.startMaking')}</p>
						<BulletPoints size="lg">
							{[
								{
									icon: <CreateIcon fill={bulletPointColor} />,
									header: t('pages.billing.aiQuiz'),
									text: t('pages.billing.flashCard')
								},
								{
									icon: <CreateIcon fill={bulletPointColor} />,
									header: t('pages.billing.additionalMediaSearches'),
									text: t('pages.billing.makeItEasier')
								},
								{
									icon: <CreateIcon fill={bulletPointColor} />,
									header: `${billingPlans['pro'].storageLimitGB} GB ${t('pages.billing.lynxCloudStorage')}`,
									text: t('pages.billing.plentyOfRoom')
								}
							].map(({ header, icon, text }, key) => (
								<BulletPointItem key={key} header={header} icon={icon} text={text} />
							))}
						</BulletPoints>
					</div>
					<div className={css['footer']}>
						<p>{t('pages.billing.cancelAnyTime')}</p>
						<BasicButton variant="blue" onClick={handleTryItFreeClicked}>
							{t('pages.pricing.startFreeTrial')}*
						</BasicButton>
						<div>*{t('pages.pricing.freeFor')}</div>
					</div>
				</div>
				<div className={css['container__right']}>
					<img src={ProUpgradeSrc} />
				</div>
			</div>
		</Card>
	)
}
