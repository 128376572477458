import { string } from '@lynx/core'
import React from 'react'

export const HighlightedText = ({ text = '', highlight = '' }): React.ReactElement => {
	if (!highlight) return <span>{text}</span>

	const regex = new RegExp(`(${string.escapeRegExp(highlight)})`, 'gi')
	const parts = text.split(regex)
	return <span>{parts.filter((part) => part).map((part, i) => (regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>))}</span>
}
