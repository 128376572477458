import { Action, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ApplicationState } from '../'
import { Config, ConfigActionTypes, READ_CONFIG } from './types'
import { APIRequest } from '../../api'
import { clientLogout } from '../profile'
import { Logger } from '../../modules'

const readConfigInternal = (config: Config): ConfigActionTypes => {
	return {
		type: READ_CONFIG,
		payload: config
	}
}

export const readConfig =
	(): ThunkAction<Promise<Config | null>, ApplicationState, null, Action<boolean>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<Config | null> => {
		try {
			const configJSON = await fetch('/config.json')
			const config: Config = await configJSON.json()

			if (config) {
				APIRequest.setConfig(config)
				dispatch(readConfigInternal(config))

				APIRequest.setRequestInterceptor(
					(response) => response,
					(error) => {
						if (error?.response) {
							const { status, data } = error.response

							if (status === 401 && data.name === 'UNAUTHORIZED_INVALID_SESSION') {
								clientLogout()(dispatch, getState, null)
							}
						}
						return Promise.reject(error)
					}
				)

				return config
			} else {
				Logger.error('Config not found')
				return null
			}
		} catch (e) {
			Logger.error(e)
			return null
		}
	}
