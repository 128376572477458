import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AsyncStorage, Logger } from '../modules'

export const usePersistedState = (name: string, defaultValue: string): [string, Dispatch<SetStateAction<string>>] => {
	const [value, setValue] = useState<string>(defaultValue)

	useEffect(() => {
		const mount = async (): Promise<void> => {
			try {
				const storedValue = await AsyncStorage.getItem(name)
				if (storedValue !== null) {
					setValue(storedValue)
					return
				}

				await AsyncStorage.setItem(name, defaultValue)
			} catch {
				setValue(defaultValue)
			}
		}
		mount()
	}, [])

	useEffect(() => {
		const update = async (): Promise<void> => {
			try {
				await AsyncStorage.setItem(name, value)
			} catch (e) {
				Logger.error(e)
			}
		}
		update()
	}, [value])

	return [value, setValue]
}
