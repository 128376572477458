export const ADD_BUSY = '@@busy/ADD_BUSY'
export const REMOVE_BUSY = '@@busy/REMOVE_BUSY'

export interface BusyState {
	readonly blockers: number
	readonly nonblockers: number
	readonly isBusy: boolean
}

interface AddBusyAction {
	type: typeof ADD_BUSY
	payload: { blocking: boolean }
}

interface RemoveBusyAction {
	type: typeof REMOVE_BUSY
	payload: { blocking: boolean }
}

export type BusyActionTypes = AddBusyAction | RemoveBusyAction
