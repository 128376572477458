import { ArrowBackIcon } from '../../../../Icons'
import { Section } from '../../../../Sections'
import * as QRCode from 'qrcode'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../store'
import * as qs from 'query-string'
import { QRSectionProps, QueryParams } from './interfaces'
import Styles from './QRSection.module.css'
import { APIRequest } from '../../../../../api'
import i18next from 'i18next'
import { loginWithTokens } from '../../../../../store/profile'
import { useThunkDispatch } from '../../../../../hooks/useThunkDispatch'
import { useNavigate } from 'react-router-dom'

export const QRSection = ({ navigate: navigateSection, context: { privacyChecked } }: QRSectionProps): React.ReactElement => {
	const [qrCodeUrl, setQRCodeUrl] = useState<string>('/assets/icons/loading-animation.gif')
	const { config: configState } = useSelector((state: ApplicationState) => state)
	const { API_WS_SERVER, LYNXCLOUD_CLIENT } = configState
	const dispatchThunk = useThunkDispatch()
	const navigate = useNavigate()

	interface WhiteboardMacParamInterface {
		// ex. http://localhost:3000/auth/login?qt=true&header=false&scheme=lynx-whiteboard-beta://
		scheme: string
		qt: string //(true)
	}
	interface WhiteboardDefaultParamInterface {
		// ex. http://localhost:3000/auth/login?header=false&footer=false&qt=true&code=5ff55ceb-de9e-4c6b-aec8-c04c89741c7e
		code: string
		qt: string //(true)
	}

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const ws = new WebSocket(`${API_WS_SERVER}/auth/qr`)
			const queryParams = qs.parse(location.search) as unknown as WhiteboardMacParamInterface | WhiteboardDefaultParamInterface
			const { qt } = queryParams
			const isWhiteboardLogin = Boolean(qt)

			let qrLoaded = false
			let qrCodeUrl = ''
			ws.onmessage = async (msg): Promise<void> => {
				const { code: codeId, success, userToken } = JSON.parse(msg.data)
				qrCodeUrl = `${LYNXCLOUD_CLIENT}/auth/login?remote=true&qrCode=${codeId}`

				if (codeId) {
					QRCode.toDataURL(qrCodeUrl, (error, qrCode) => {
						setQRCodeUrl(qrCode)
						qrLoaded = true
					})
				}

				if (success) {
					await APIRequest.setAuthorization(userToken)
					const tokens = await APIRequest.User.createToken(null)
					await dispatchThunk(loginWithTokens(tokens))

					if (isWhiteboardLogin) {
						const isMacWhiteboardLogin = 'scheme' in queryParams
						const isDefaultWhiteboardLogin = 'code' in queryParams
						const macParams = (isMacWhiteboardLogin && { scheme: queryParams.scheme }) || {}
						const windowsParams = (isDefaultWhiteboardLogin && { code: queryParams.code }) || {}

						const params = qs.stringify({ qr: true, qt, rememberMe: !privacyChecked, ...macParams, ...windowsParams })
						navigate(`/authenticated?${params}`)
					}
				}
			}
			setTimeout(() => {
				if (!qrLoaded) {
					QRCode.toDataURL(qrCodeUrl, (error, qrCode) => {
						setQRCodeUrl(qrCode)
						qrLoaded = true
					})
				}
			}, 3000)
		}
		mount()
	}, [])

	const handleBack = (): void => {
		navigateSection('SignInSection')
	}

	return (
		<Section
			side="right"
			title={
				<div className={Styles['title-container']}>
					<div className={Styles['back-arrow']} onClick={handleBack}>
						<ArrowBackIcon />
					</div>
					<div>{i18next.t('pages.login.qrSignin')}</div>
				</div>
			}
			className={Styles['qr-section']}
		>
			<img src={qrCodeUrl} alt="QR Code" />
			<p className={Styles['qr-text']}>{i18next.t('pages.login.qrCodeText')}</p>
		</Section>
	)
}
