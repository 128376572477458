import * as React from 'react'
import { Section } from '../../../../Sections'
import css from './ImageSection.module.scss'
import { LynxBannerLight } from '../../../../Icons'

export class ImageSection extends React.Component {
	render(): React.ReactNode {
		return (
			<Section side="left" className={css['imageSection']}>
				<div className={css['imageContainer']}>
					<div className={css['logo']}>
						<LynxBannerLight />
					</div>
					<div className={css['cover']}>
						<img src="/assets/images/signin-page/sign-in-image.png" alt="logo" />
					</div>
				</div>
			</Section>
		)
	}
}
