import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { ApplicationState } from 'store'
import { loadOrganisations, userAcceptedInvite } from 'store/organisations'
import { NotificationToast, pushNotification } from 'store/notifications'
import { READ_MESSAGE, UserMessage } from './types'
import {
	driveStorageLimitChanged,
	logoutEverywhere,
	notificationsTypes,
	organisationInviteAccepted,
	profileUpdated
} from '@lynx/core/src/interfaces/Notifications'
import { setUserSessionEnd } from '@lynx/client-core/src/store/profile'
import { updateDriveSize } from 'store/drive'
import { setIsPro, setUserPermissions } from 'store/profile'
import { per } from '@lynx/client-core/src/modules/Freehand/vec'

export const userMessagingNotification = (): Middleware => {
	const user: Middleware =
		({ dispatch }: MiddlewareAPI<ThunkDispatch<ApplicationState, null, Action>, ApplicationState>) =>
		(next: Dispatch) =>
		async (action: AnyAction): Promise<AnyAction> => {
			switch (action.type) {
				case READ_MESSAGE: {
					const message: UserMessage = JSON.parse(action.payload.message)

					switch (message.type) {
						case organisationInviteAccepted: {
							const {
								payload: { organisationId, inviteId }
							} = message
							dispatch(userAcceptedInvite({ organisationId, inviteId }))
							dispatch(pushNotification({ payload: message.payload, type: organisationInviteAccepted, notificationId: message.notificationId }))

							const isOrgPage = window.location.pathname.includes('/organisations')
							if (isOrgPage) dispatch(loadOrganisations())

							return next(action)
						}
						case driveStorageLimitChanged: {
							dispatch(updateDriveSize(message.payload.driveSize))
							return next(action)
						}
						case profileUpdated: {
							dispatch(setIsPro(message.payload))
							if (message.payload.permissions) dispatch(setUserPermissions({ permissions: message.payload.permissions }))
							return next(action)
						}
						default: {
							const isNotification = notificationsTypes.some((notifType) => notifType === message.type)
							if (isNotification) {
								const notification = {
									type: message.type,
									payload: message.payload,
									notificationId: message.notificationId
								} as NotificationToast
								dispatch(pushNotification(notification, message.type !== logoutEverywhere))

								// handle some action on notification
								switch (message.type) {
									case logoutEverywhere: {
										dispatch(setUserSessionEnd(true))
									}
								}
							}
							return next(action)
						}
					}
				}
				default: {
					return next(action)
				}
			}
		}

	return user
}
