import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Input, LockOpenIcon } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import i18next from 'i18next'
import { StrengthIndicator, strengthTranslations } from 'pages/Login/sections/components/StrengthIndicator'
import qs from 'query-string'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import Styles from './ResetPasswordSection.module.scss'
import { objKeys } from '@lynx/client-core/src/utils'

interface ResetPasswordSectionState {
	password: string
	strengthTypes: { [key: string]: boolean }
	confirmPassword: string
	error: string
}

export const ResetPasswordSection = (): React.ReactElement => {
	const location = useLocation()
	const navigate = useNavigate()
	const [state, setState] = useState<ResetPasswordSectionState>({
		password: '',
		confirmPassword: '',
		error: '',
		strengthTypes: {
			has8Characters: false,
			hasSmallLetter: false,
			hasCapitalLetter: false,
			hasNonLetter: false,
			hasNumber: false
		}
	})

	const resetStrength = (): void => {
		setState({
			...state,
			strengthTypes: {
				has8Characters: false,
				hasSmallLetter: false,
				hasCapitalLetter: false,
				hasNonLetter: false,
				hasNumber: false
			}
		})
	}

	const handlePasswordChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const password = event.target.value
		if (!password) {
			resetStrength()
			return
		}

		const lowercaseLetters = /[a-z]/
		const uppercaseLetters = /[A-Z]/
		const hasSymbols = /[^A-Za-z\d]+/
		const hasNumber = /\d/

		setState({
			...state,
			strengthTypes: {
				has8Characters: password.length >= 8,
				hasSmallLetter: lowercaseLetters.test(password),
				hasCapitalLetter: uppercaseLetters.test(password),
				hasNonLetter: hasSymbols.test(password),
				hasNumber: hasNumber.test(password)
			},
			password
		})
	}

	const handleConfirmPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const confirmPassword = event.target.value
		setState({ ...state, confirmPassword })
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault()
		event.stopPropagation()

		try {
			const { user, id } = qs.parse(location.search)
			if (!user) {
				setState({ ...state, error: i18next.t('pages.resetPassword.somethingWrong') })
				return
			}

			if (!id) {
				setState({ ...state, error: i18next.t('pages.resetPassword.somethingWrong') })
				return
			}

			if (state.password === '') {
				setState({ ...state, error: i18next.t('pages.resetPassword.enterPassword') })
				return
			}

			if (state.password !== state.confirmPassword) {
				setState({ ...state, error: i18next.t('pages.resetPassword.passwordNotMatch') })
				return
			}

			const resetPasswordResponse = await APIRequest.User.resetPassword(user as string, id as string, state.password)
			const { data } = resetPasswordResponse
			if (data.status === 'error') {
				setState({ ...state, error: data.message })
				return
			}

			navigate(`/auth/password-reset-success`)
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleBack = (): void => {
		navigate('/auth/login')
	}

	const isButtonAvailable = (): boolean => {
		const { password, confirmPassword } = state
		return password === confirmPassword && Object.values(state.strengthTypes).every((v) => v)
	}

	const resetButtonReady = isButtonAvailable()
	const strengthComponents = objKeys(state.strengthTypes).map((key) => {
		const applied = state.strengthTypes[key]
		const translation = strengthTranslations[key as keyof typeof strengthTranslations]
		const text = i18next.t(translation)
		return <StrengthIndicator key={key} text={text} applied={applied} />
	})

	return (
		<section className={Styles['container']}>
			<h3 className={Styles['title']}>{i18next.t('pages.login.resetPassword')}</h3>
			<form style={{ width: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit} autoComplete="off">
				<Input variant="light" type="password" name="resetpassword" placeholder="Password" onChange={handlePasswordChanged} icon={LockOpenIcon} />
				<div style={{ width: '100%', marginTop: '20px', marginLeft: '10px', marginBottom: '7px' }}>{strengthComponents}</div>
				<Input
					variant="light"
					type="password"
					name="resetpasswordconfirm"
					placeholder="Confirm Password"
					onChange={handleConfirmPasswordChanged}
					icon={LockOpenIcon}
				/>

				<BasicButton disabled={!resetButtonReady} type="submit" variant="blue">
					{i18next.t('pages.login.reset')}
				</BasicButton>
			</form>

			<div onClick={handleBack} className={Styles['back']}>
				{i18next.t('pages.login.backToSignIn')}
			</div>
		</section>
	)
}
