import { DriveItemType, Organisation, OrganisationGroup } from '@lynx/client-core/src/api/interfaces'
import { ModalState as DefaultModalState } from '@lynx/client-core/src/store/modal'
import {
	AreYouSureModalContext,
	AreYouSureModalContextType,
	BaseModalContext,
	ImageUpscaleModalContextType,
	ImageUpscaleModalContext
} from '@lynx/client-core/src/store/modal'
import { OrganisationEntryModalContext } from 'pages/Admin/routes/elmstone/Subscriptions/OrganisationsList/modals/OrganisationEntryModal/OrganisationEntryModalContext'
import { ConfirmAccountCreationModalContext } from 'pages/Organisations/modals/ConfirmAccountCreationModal/ConfirmAccountCreationModalContext'
import { DeleteGroupModalContext } from 'pages/Organisations/modals/DeleteGroupModal/DeleteGroupModalContext'
import { ManageGroupModalContext } from 'pages/Organisations/modals/ManageGroupModal/ManageGroupModalContext'
import { RenameGroupModalContext } from 'pages/Organisations/modals/RenameGroupModal/RenameGroupModalContext'
import { ResetOrganisationUserPasswordModalContext } from 'pages/Organisations/modals/ResetOrganisationUserPasswordModal/ResetOrganisationUserPasswordModalContext'
import { UserDeleteModalContext } from 'pages/Organisations/modals/UserDeleteModal/UserDeleteModalContext'
import { VerifyDomainModalContext } from 'pages/Organisations/modals/VerifyDomainModal/VerifyDomainModalContext'
import { UserDisableModalContext } from 'pages/Organisations/modals/UserDisableModal/UserDisableModalContext'
import { UserEnableModalContext } from 'pages/Organisations/modals/UserEnableModal/UserEnableModalContext'

export { AreYouSureModalContextType, ImageUpscaleModalContextType }
export const ShareModalContextType = 'ShareModalContextType'
export const PublishModalContextType = 'PublishModalContextType'
export const CreateNewFileModalContextType = 'CreateNewFileModalContextType'
export const CreateNewFolderModalContextType = 'CreateNewFolderModalContextType'
export const InformationModalContextType = 'InformationModalContextType'
export const WasmModalContextType = 'WasmModalContextType'
export const DeleteFileModalContextType = 'DeleteFileModalContextType'
export const RenameModalContextType = 'RenameModalContextType'
export const MoveModalContextType = 'MoveModalContextType'
export const CreateGroupModalContextType = 'CreateGroupModalContextType'
export const ManageGroupModalContextType = 'ManageGroupModalContextType'
export const EmailDomainsModalContextType = 'EmailDomainsModalContextType'
export const MakePublicModalContextType = 'MakePublicModalContextType'
export const EmailShareModalContextType = 'EmailShareModalContextType'
export const GroupShareModalContextType = 'GroupShareModalContextType'
export const ImagePreviewModalContextType = 'ImagePreviewModalContextType'
export const LynxPreviewModalContextType = 'LynxPreviewModalContextType'
export const PDFPreviewModalContextType = 'PDFPreviewModalContextType'
export const PowerPointPreviewModalContextType = 'PowerPointPreviewModalContextType'
export const VideoPreviewModalContextType = 'VideoPreviewModal'
export const GoogleClassroomModalContextType = 'GoogleClassroomModalContextType'
export const OrganisationEditModalContextType = 'OrganisationEditModalContextType'
export const AddToGroupModalContextType = 'AddToGroupModalContextType'
export const OrganisationNextCloudDomainModalContextType = 'OrganisationNextCloudDomainModalContextType'
export const OrganisationCreatedModalContextType = 'OrganisationCreatedModalContextType'
export const SharingModalContextType = 'SharingModalContextType'
export const OrganisationDepartedType = 'OrganisationDepartedType'
export const SamlSettingsModalContextType = 'SamlSettingsModalContextType'
export const OauthSettingsModalContextType = 'OauthSettingsModalContextType'
export const RelinkModalContextType = 'RelinkModalContextType'
export const ProfilePictureModalType = 'ProfilePictureModalType'
export const OrganisationUserProLicenseModalType = 'OrganisationUserProLicenseModal'
export const InvitePeopleModalContextType = 'InvitePeopleModalContextType'

export interface ProfilePictureModalContext extends BaseModalContext {
	type: typeof ProfilePictureModalType
}

export interface RelinkModalContextType extends BaseModalContext {
	type: typeof RelinkModalContextType
	provider: string
}

export interface OauthSettingsModalContextType extends BaseModalContext {
	type: typeof OauthSettingsModalContextType
	organisationId: string
	getOrganisationDetails: () => Promise<void>
}

export interface SamlSettingsModalContextType extends BaseModalContext {
	type: typeof SamlSettingsModalContextType
	entrypoint?: string
	issuer?: string
	organisationId: string
	getOrganisationDetails: () => Promise<void>
}

export interface OrganisationCreatedModalContext extends BaseModalContext {
	type: typeof OrganisationCreatedModalContextType
	organisation: Organisation
	handleManageEmailDomains: () => Promise<void>
}

export interface SharingModalContext extends BaseModalContext {
	type: typeof SharingModalContextType
	driveItem: DriveItemType
}

export interface PublishModalContext extends BaseModalContext {
	type: typeof PublishModalContextType
	driveItem: DriveItemType
}

export interface VideoPreviewModalContext extends BaseModalContext {
	type: typeof VideoPreviewModalContextType
	token: string
}
export interface UserInviteModalContext extends BaseModalContext {
	type: typeof InvitePeopleModalContextType
	groupId: number
}

export interface OrganisationEditModalContext extends BaseModalContext {
	type: typeof OrganisationEditModalContextType
	organisationId: string
	name: string
	address: string
	postcode: string
	nextCloudDomain: string
	country: number
	errorMessage: string
	countryName: string
	isOwner: boolean
	handleDeleteOrganisation: () => Promise<void>
	handleUpdateOrganisation: () => Promise<void>
}

export interface OrganisationNextCloudDomainModalContext extends BaseModalContext {
	type: typeof OrganisationNextCloudDomainModalContextType
	organisationName: string
	address: string
	nextCloudDomain: string
	nextCloudEmailDomains?: string[] | null
	nextCloudOrganisationId: string
}

export interface AddToGroupModalContext extends BaseModalContext {
	type: typeof AddToGroupModalContextType
	organisationId: string
	users: { email: string; userId: string }[]
	groups: OrganisationGroup[]
}

export interface PowerPointPreviewModalContext extends BaseModalContext {
	type: typeof PowerPointPreviewModalContextType
	token: string
}

export interface PDFPreviewModalContext extends BaseModalContext {
	type: typeof PDFPreviewModalContextType
	token: string
}
export interface LynxPreviewModalContext extends BaseModalContext {
	type: typeof LynxPreviewModalContextType
	token: string
}
export interface ImagePreviewModalContext extends BaseModalContext {
	type: typeof ImagePreviewModalContextType
	token: string
}
export interface MakePublicModalContext extends BaseModalContext {
	type: typeof MakePublicModalContextType
	token: string
}

export interface ShareModalContext extends BaseModalContext {
	type: typeof ShareModalContextType
	token: string
}

export interface OrganisationUserProLicenseModalContext extends BaseModalContext {
	type: typeof OrganisationUserProLicenseModalType
}

export interface RenameModalContext extends BaseModalContext {
	type: typeof RenameModalContextType
	driveItem: DriveItemType
}

export interface CreateGroupModalContext extends BaseModalContext {
	type: typeof CreateGroupModalContextType
	organisationId: string
	getOrgDetails: () => Promise<void>
}

export interface GoogleClassroomModalContext extends BaseModalContext {
	type: typeof GoogleClassroomModalContextType
	organisationId: string
	courseId: string
	courseName: string
}

export interface GroupShareModalContext extends BaseModalContext {
	type: typeof GroupShareModalContextType
	token: string
}

export interface EmailDomainsModalContext extends BaseModalContext {
	type: typeof EmailDomainsModalContextType
	organisationId: string
	nextCloudDomain?: string
	getOrganisationDetails: () => Promise<void>
	entrypoint?: string
	emailDomains: {
		id: number
		organisationId: number
		emailDomain: string
	}[]
}
export interface MoveModalContext extends BaseModalContext {
	type: typeof MoveModalContextType
	driveItem: DriveItemType
}

export interface CreateNewFolderModalContext extends BaseModalContext {
	type: typeof CreateNewFolderModalContextType
}

export interface OrganisationDepartedContext extends BaseModalContext {
	type: typeof OrganisationDepartedType
}

export interface WasmModalContext extends BaseModalContext {
	type: typeof WasmModalContextType
}

export interface InformationModalContext extends BaseModalContext {
	type: typeof InformationModalContextType
	title: string
	message: string
}

export interface DeleteFileModalContext extends BaseModalContext {
	type: typeof DeleteFileModalContextType
	handleConfirm: () => Promise<void>
	confirmText: string
	confirmBody: string
}

export interface EmailShareModalContext extends BaseModalContext {
	type: typeof EmailShareModalContextType
	token: string
}

export type ModalContext =
	| CreateNewFolderModalContext
	| AreYouSureModalContext
	| ImageUpscaleModalContext
	| DeleteFileModalContext
	| InformationModalContext
	| RenameModalContext
	| MoveModalContext
	| CreateGroupModalContext
	| EmailDomainsModalContext
	| MakePublicModalContext
	| ShareModalContext
	| EmailShareModalContext
	| GroupShareModalContext
	| ImagePreviewModalContext
	| LynxPreviewModalContext
	| PDFPreviewModalContext
	| PowerPointPreviewModalContext
	| VideoPreviewModalContext
	| GoogleClassroomModalContext
	| OrganisationEditModalContext
	| AddToGroupModalContext
	| OrganisationNextCloudDomainModalContext
	| OrganisationCreatedModalContext
	| SharingModalContext
	| PublishModalContext
	| OrganisationDepartedContext
	| RenameGroupModalContext
	| SamlSettingsModalContextType
	| ResetOrganisationUserPasswordModalContext
	| OauthSettingsModalContextType
	| RelinkModalContextType
	| ProfilePictureModalContext
	| UserDeleteModalContext
	| DeleteGroupModalContext
	| ManageGroupModalContext
	| VerifyDomainModalContext
	| ConfirmAccountCreationModalContext
	| OrganisationEntryModalContext
	| WasmModalContext
	| OrganisationUserProLicenseModalContext
	| UserDisableModalContext
	| UserEnableModalContext
	| UserInviteModalContext
	| undefined

export type ModalState = DefaultModalState<ModalContext>
