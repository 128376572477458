import { Alert, BasicButton, CancelFilledIcon, CheckCircleFilledIcon, DoNotDisturbFilledIcon, Input, LoadingSpinner } from '@lynx/client-core/src/components'
import React, { useEffect, useState } from 'react'
import css from './OrganisationChangeInvoiceStatus.module.scss'
import { APIRequest } from '@lynx/client-core/src/api'
import ModalBase from '@lynx/client-core/src/components/Modal/Modal'
import { ChangeInvoiceStatusPayload } from '@lynx/core/src/interfaces'
import { extractResponseError } from '@lynx/core'
import { STRIPE_ERROR } from '@lynx/core/src/interfaces/ServerErrors'

interface Props {
	onInvoiceStatusChanged: () => void
}

export const useOrganisationChangeInvoiceStatus = ({
	onInvoiceStatusChanged
}: Props): {
	changeInvoiceStatusModal: React.ReactElement | null
	getDropdownEl: (type: ChangeInvoiceStatusPayload['status'], invoiceId: string, organisationId: number) => React.ReactElement
} => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [inputText, setInputText] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [invoiceId, setInvoiceId] = useState('')
	const [organisationId, setOrganisationId] = useState<number>(0)
	const [type, setType] = useState<ChangeInvoiceStatusPayload['status']>('paid')
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		setErrorMessage('')
	}, [isModalOpen])

	const changeInvoiceStatus = async (status: ChangeInvoiceStatusPayload['status'], invoiceIdParam: string, orgId: number): Promise<void> => {
		setErrorMessage('')
		try {
			setIsLoading(true)
			await APIRequest.Billing.changeInvoiceStatus({ status, invoiceId: invoiceIdParam, internalNote: inputText, organisationId: orgId })
			onInvoiceStatusChanged()
			setIsModalOpen(false)
			setInputText('')
		} catch (e: any) {
			const responseError = extractResponseError(e)
			const isDriveSizeExceededError = responseError?.name === STRIPE_ERROR
			if (isDriveSizeExceededError) setErrorMessage(responseError.options.stripeErrorMessage)
			else setErrorMessage(e?.message)
		} finally {
			setIsLoading(false)
		}
	}

	const onOpenModal = (): void => {
		setIsModalOpen(true)
	}

	const options = {
		paid: {
			modalTitle: 'Payment Confirmation',
			bodyText: 'Confirming payment cannot be reversed. Please only confirm payment if payment has been received.',
			buttonText: 'Mark as Paid',
			variant: 'blue' as const,
			onClick: (invoiceId: string, organisationId: number, type: ChangeInvoiceStatusPayload['status']): void => {
				setOrganisationId(organisationId)
				setInvoiceId(invoiceId)
				setType(type)
				onOpenModal()
			},
			el: (
				<div className={[css.optionContainer, css.paid].join(' ')}>
					<CheckCircleFilledIcon className={css.markAsPaidIcon} /> Mark as Paid
				</div>
			)
		},
		void: {
			modalTitle: 'Void Invoice Confirmation',
			bodyText:
				'Void invoice cannot be reversed and will prevent any changes or payments from being made to this invoice. Are you sure you want to continue?',
			buttonText: 'Void Invoice',
			variant: 'danger' as const,
			onClick: (invoiceId: string, organisationId: number, type: ChangeInvoiceStatusPayload['status']): void => {
				setOrganisationId(organisationId)
				setInvoiceId(invoiceId)
				setType(type)
				onOpenModal()
			},
			el: (
				<div className={[css.optionContainer, css.void].join(' ')}>
					<CancelFilledIcon /> Void Invoice
				</div>
			)
		},
		uncollectible: {
			modalTitle: '',
			bodyText: '',
			buttonText: '',
			variant: 'danger' as const,
			onClick: (invoiceId: string, organisationId: number): Promise<void> => changeInvoiceStatus('uncollectible', invoiceId, organisationId),
			el: (
				<div className={[css.optionContainer, css.uncollectible].join(' ')}>
					<DoNotDisturbFilledIcon /> Mark as Uncollectible
				</div>
			)
		}
	}

	const { modalTitle, bodyText, variant, buttonText } = options[type as keyof typeof options]
	return {
		getDropdownEl: (type: ChangeInvoiceStatusPayload['status'], invoiceId: string, orgId: number): React.ReactElement => (
			<li onClick={(): Promise<void> | void => options[type].onClick(invoiceId, orgId, type)}>{isLoading ? <LoadingSpinner /> : options[type].el}</li>
		),
		changeInvoiceStatusModal: isModalOpen ? (
			<ModalBase name={modalTitle} title={modalTitle} uncontrolled onHidden={(): void => setIsModalOpen(false)} variant={variant}>
				<div className={css.modalContainer}>
					<div className={css.bodyText}>{bodyText}</div>
					{errorMessage && (
						<Alert styleClass={'danger'} className={css.error}>
							{errorMessage}
						</Alert>
					)}
					<div className={css.btnContainer}>
						<BasicButton onClick={(): void => setIsModalOpen(false)} variant="grey" disabled={isLoading}>
							Cancel
						</BasicButton>
						<BasicButton
							onClick={(): Promise<void> => changeInvoiceStatus(type, invoiceId, organisationId)}
							variant={variant}
							isLoading={isLoading}
						>
							{buttonText}
						</BasicButton>
					</div>
				</div>
			</ModalBase>
		) : null
	}
}
