import { OpenTDBQuestion } from '../OpenTDB.types'
import { BaseCall } from '../BaseCall'
import { APIConfig } from '../interfaces'
import { Logger } from '../../modules/Logger'
export class Ai extends BaseCall {
	public getQuizQuestions = async (prompt: string, difficulty: string, questionCount: number, languageCode: string): Promise<OpenTDBQuestion[]> => {
		try {
			const response = await this.client.post(`/ai/quiz/questions/${difficulty}/${questionCount}/${languageCode}`, {
				prompt
			})
			return response.data
		} catch (err) {
			Logger.error(err)
			throw new Error('Failed to get quiz questions')
		}
	}

	public getSpinnerWords = async (prompt: string, questionCount: number, langName: string): Promise<{ words: string[] }> => {
		try {
			const response = await this.client.get(`/ai/spinner/words/${prompt}/${questionCount}/${langName}`)
			return response.data
		} catch (err) {
			Logger.error(err)
			throw new Error('Failed to get spinner words')
		}
	}

	public generateImageFromPrompt = async (prompt: string, generationId: string): Promise<{ provider: string }> => {
		try {
			const res = await this.client.post(`/ai/generate-image?id=${generationId}`, {
				prompt
			})
			return res.data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}
	public saveBackground = async (url: string, imageNumber: number, split: '1x1' | '2x2'): Promise<{ shareUrl: string }> => {
		try {
			const res = await this.client.post(`/ai/generate-image/save-background`, { url, imageNumber, split })
			return res.data
		} catch (err) {
			Logger.error(err)
			throw new Error('Failed to save background')
		}
	}

	public upscaleImage = async (file: File): Promise<{ url: string }> => {
		try {
			const data = new FormData()
			data.append('file', file)

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}

			const res = await this.client.post(`/ai/upscale-image`, data, config)
			return res.data
		} catch (err) {
			Logger.error(err)
			throw new Error('Failed to upscale share')
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
