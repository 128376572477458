import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ModalContext } from 'store/modal'
import css from './ResetOrganisationUserPasswordModal.module.scss'
import { ResetOrganisationUserPasswordModalContext } from './ResetOrganisationUserPasswordModalContext'
import { useTranslation } from 'react-i18next'
import { APIRequest } from '@lynx/client-core/src/api'

export const ResetOrganisationUserPasswordModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [context, setContext] = useState<ResetOrganisationUserPasswordModalContext | null>(null)

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}

	const handleDeleteClicked = async (): Promise<void> => {
		if (!context) return
		await APIRequest.Organisations.resetPassword(Number(context.organisationId), context.userId)
		dispatch(hideModal())
	}

	const handleVisible = async (context?: ModalContext): Promise<void> => {
		if (context?.type !== 'ResetOrganisationUserPasswordModalContextType') {
			return
		}

		setContext(context)
	}

	const email = context?.email || ''

	return (
		<Modal name="ResetOrganisationUserPasswordModal" title={t('pages.organisations.resetPassword')} onVisible={handleVisible} variant="yellow">
			<div className={css['container']}>
				<div>
					{t('pages.organisations.sendPasswordEmailFor')} {email}?
				</div>
				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
					<BasicButton variant="danger" onClick={handleDeleteClicked}>
						{t('pages.organisations.send')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
