import { APIRequest } from '@lynx/client-core/src/api'
import { SharesInfo } from '@lynx/client-core/src/api/interfaces'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import { ControlBar } from 'pages/Share/components/ControlBar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ApplicationState } from 'store'
import { requestDocument } from 'store/document'
import { LynxPreviewModalContextType, showModal } from 'store/modal'
import css from './Lynx.module.scss'

export const Lynx = (): React.ReactElement => {
	const [pageNumber, setPageNumber] = useState<number>(0)
	const [thumbnails, setThumbnails] = useState<string[]>([])
	const [shareDetails, setShareDetails] = useState<SharesInfo | null>(null)
	const { profile: profileState, modal: modalState, document: documentState } = useSelector((state: ApplicationState) => state)
	const { ready, fileId } = documentState
	const previousFileId = usePrevious(fileId)

	const params = useParams<{ token: string }>()
	const { token } = params
	const navigate = useNavigate()
	const dispatchThunk = useThunkDispatch()
	const dispatch = useDispatch()
	const previousDocumentReady = usePrevious(ready)

	const getThumbnails = async (options: { lowResolution: boolean }): Promise<void> => {
		if (!token) return
		const shareResponse = await APIRequest.Sharing.thumbnails(token, options)
		if (!shareResponse?.data) return
		if (!shareResponse.data.thumbnails) {
			navigate('/missing-share')
			return
		}

		const thumbnails = shareResponse.data.thumbnails.map((slide: Buffer) => `data:image/png;base64,${Buffer.from(slide).toString('base64')}`)
		setThumbnails(thumbnails)
	}

	useEffect(() => {
		const update = async (): Promise<void> => {
			const isFileIdUpdated = fileId && previousFileId && fileId !== previousFileId // assuming another version of thumbnails created
			if (isFileIdUpdated) await getThumbnails({ lowResolution: false })
		}
		update()
	}, [fileId, previousFileId])

	useEffect(() => {
		const mount = async (): Promise<void> => {
			try {
				if (!token) return

				const shareInfo = await APIRequest.Sharing.info(token)
				if (!shareInfo?.data) {
					navigate('/missing-share')
					return
				}

				const limit350Kb = 350 * 1000
				const isHeavyFile = shareInfo?.data?.size > limit350Kb

				await dispatchThunk(
					requestDocument({
						fileId: shareInfo.data.id,
						resolution: '2048',
						download: false,
						conversionType: 'thumbnails',
						shareToken: token
					})
				)

				await getThumbnails({ lowResolution: isHeavyFile })
				setShareDetails(shareInfo.data)
				if (!profileState.loggedIn && !modalState.name) {
					showModal({ name: 'SignUpModal' })
				}
			} catch (e) {
				Logger.error(e)
				navigate('/missing-share')
			}
		}

		mount()
	}, [])

	useEffect(() => {
		const update = async (): Promise<void> => {
			if (!previousDocumentReady && documentState.ready && !thumbnails) {
				const { fileId } = documentState
				const buffer = await APIRequest.Sharing.downloadBuffer(fileId)
				if (!buffer) {
					Logger.error('empty buffer')
					return
				}

				// Convert Buffer to Zip
				const { default: JSZip } = await import('jszip')
				const zipData = await JSZip.loadAsync(buffer)
				const pngs = []
				for (const singleFile of Object.keys(zipData.files)) {
					const zipFileData = await zipData.file(singleFile)
					if (zipFileData) pngs.push(`data:image/png;base64,${await zipFileData.async('base64')}`)
				}

				await setThumbnails(pngs)
			}
		}
		update()
	}, [previousDocumentReady, documentState])

	const handlePageForwardClicked = (): void => {
		if (pageNumber + 1 < thumbnails.length) {
			setPageNumber(pageNumber + 1)
		}
	}

	const handlePageBackClicked = (): void => {
		if (pageNumber === 0) {
			return
		}

		setPageNumber(pageNumber - 1)
	}

	const handleDownload = async (): Promise<void> => {
		if (!token) return
		const blobLinkProperties = await APIRequest.Sharing.getObjectUrl(token)
		if (!blobLinkProperties) {
			navigate('/missing-share')
		}

		const link = document.createElement('a')
		link.href = blobLinkProperties.link
		document.body.appendChild(link)
		link.download = shareDetails?.name || 'download'
		link.click()
		document.body.removeChild(link)
	}

	const handleViewClicked = (): void => {
		dispatch(showModal({ name: 'LynxPreviewModal', context: { type: LynxPreviewModalContextType, token } }))
	}

	const slide = <img alt={'loading'} src={thumbnails[pageNumber]} />
	const hasPage = !!thumbnails[pageNumber]

	return (
		<div className={css['container']}>
			{hasPage && <React.Fragment>{slide}</React.Fragment>}
			{!thumbnails.length && <div style={{ width: '1024px', height: '546px' }}></div>}
			{shareDetails && (
				<ControlBar
					onPageBackClicked={handlePageBackClicked}
					onPageForwardClicked={handlePageForwardClicked}
					onDownloadClicked={handleDownload}
					onViewClicked={handleViewClicked}
					shareDetails={shareDetails}
					token={token || ''}
					pageNumber={pageNumber}
					totalPages={thumbnails.length}
				/>
			)}
		</div>
	)
}
