import { ArrowForwardIosIcon, BasicButton, Dropdown, EditIcon, LaserIcon, ProBadgeIcon, Tooltip, UploadIcon } from '@lynx/client-core/src/components'
import { isLynx } from '@lynx/client-core/src/utils'
import { splitToken } from '@lynx/core'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import { Board } from 'pages/Share/components/Board'
import extensionsData from 'pages/Share/data.json'
import qs from 'query-string'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { requestDocument } from 'store/document'
import { showDriveBrowser } from 'store/drive'
import css from './ControlBar.module.scss'
import { ControlBarProps } from './interfaces'
import { FileDownloadIcon } from '@lynx/client-core/src/components/Icons'

export const ControlBar = ({
	shareDetails,
	token,
	pageNumber = 0,
	totalPages = 0,
	onPageBackClicked,
	onPageForwardClicked,
	onDownloadClicked,
	upscaleImage
}: ControlBarProps): React.ReactElement => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const [penActive, setPenActive] = useState<boolean>(false)
	const {
		config: configState,
		profile: { loggedIn, userId }
	} = useSelector((state: ApplicationState) => state)

	if (!shareDetails) {
		return <div />
	}

	const handleConversionClicked = async (item: string): Promise<void> => {
		await dispatchThunk(
			requestDocument({
				fileId: shareDetails.id,
				download: true,
				conversionType: item.replace('.', ''),
				shareToken: token
			})
		)
	}

	const handleEdit = async (): Promise<void> => {
		if (!shareDetails) {
			return
		}

		const parsedUrl = qs.parseUrl(location.search)
		const { id, name } = shareDetails
		const { paths } = parsedUrl.query
		const tokenDetails = splitToken(token)

		window.open(
			`${configState.LYNXVNC_CLIENT}?file=${id}&shareId=${token}&shareOwnerId=${tokenDetails.fileOwnerId}&name=${name}&userId=${userId}&provider=lynxcloud&paths=${paths}`,
			'_blank'
		)
	}

	const handleShowDriveBrowser = (): void => {
		dispatch(showDriveBrowser())
	}

	const handleEditClicked = (): void => {
		setPenActive(!penActive)
	}

	const extension = shareDetails.name.split('.').pop()
	const lowerCaseExtension = (extension || '').toLowerCase() as keyof typeof extensionsData
	const savesToOptions = extensionsData[lowerCaseExtension] ? extensionsData[lowerCaseExtension].savesTo : []

	const penClassNames = [css['pen']]
	penActive && penClassNames.push(css['pen--active'])

	const isLastPage = pageNumber + 1 === totalPages
	const isFirstPage = pageNumber == 0

	return (
		<div className={css['container']} style={{ gridTemplateColumns: totalPages > 0 ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)' }}>
			<div className={css['details']}>{shareDetails.name}</div>
			{totalPages > 0 && (
				<React.Fragment>
					<div className={css['pagination']}>
						<ArrowForwardIosIcon style={{ fill: isFirstPage ? '#808080' : 'white' }} onClick={onPageBackClicked} />
						<div className={css['pagination__page_numbers']}>{`${pageNumber + 1}`}</div>
						<div>/ </div>
						<div className={css['pagination__page_numbers']}>{`${totalPages}`}</div>
						<ArrowForwardIosIcon style={{ fill: isLastPage ? '#808080' : 'white' }} onClick={onPageForwardClicked} />
					</div>
				</React.Fragment>
			)}
			<div className={css['controls']}>
				<Tooltip data-tooltip={t('controlbar.pen')} showOnHover>
					<LaserIcon className={penClassNames.join(' ')} onClick={handleEditClicked} />
				</Tooltip>
				{loggedIn && (
					<Tooltip data-tooltip={t('pages.gallery.saveAs')} showOnHover>
						<UploadIcon onClick={handleShowDriveBrowser} className={css.icon} />
					</Tooltip>
				)}

				{savesToOptions.length !== 0 && loggedIn && (
					<Dropdown
						title={
							<Tooltip data-tooltip={t('pages.gallery.downloadAs')} showOnHover>
								<FileDownloadIcon className={css.icon} />
							</Tooltip>
						}
						direction="up"
					>
						{onDownloadClicked && (
							<li
								style={{
									borderBottom: '1px solid #808080'
								}}
								onClick={onDownloadClicked}
							>
								.{lowerCaseExtension} {t('pages.gallery.originalFile')}
							</li>
						)}
						{savesToOptions.map((item) => (
							<li
								key={item}
								onClick={(): void => {
									handleConversionClicked(item)
								}}
							>
								.{item}
							</li>
						))}
					</Dropdown>
				)}
				{extension === 'lynx' && !isLynx && (
					<Tooltip data-tooltip={t('pages.gallery.edit')} showOnHover>
						<EditIcon onClick={handleEdit} className={css.icon} />
					</Tooltip>
				)}
				{upscaleImage && (
					<BasicButton onClick={upscaleImage} className={css.upscaleBtn}>
						{t('components.modals.upscaleModal.upscale')} <ProBadgeIcon />
					</BasicButton>
				)}
			</div>
			<Board active={penActive} />
		</div>
	)
}
