import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import { Logger } from '@lynx/client-core/src/modules'
import '@lynx/client-core/src/modules/Database/LocalStorage'
import * as Buffer from 'buffer'
import { createRoot } from 'react-dom/client'
import { pdfjs } from 'react-pdf'
import { Provider } from 'react-redux'
import { App } from './components/App'
import configureStore from './configureStore'
import '@lynx/client-core/src/index.css'

window.Buffer = Buffer.Buffer

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const store = configureStore()
const container = document.getElementById('root')

if (!container) Logger.error('root element not found')
else {
	const root = createRoot(container)
	root.render(
		<Provider store={store}>
			<App />
		</Provider>
	)
}
