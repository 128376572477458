import { combineReducers, Reducer } from 'redux'
import { busyReducer, BusyState } from './busy'
import { configReducer, ConfigState } from './config'
import { documentReducer, DocumentState } from './document'
import { driveReducer, DriveState } from './drive'
import { feedbackReducer, FeedbackState } from './feedback'
import { messagingReducer, MessagingState } from './messaging'
import { modalReducer, ModalState } from './modal'
import { profileReducer, ProfileState } from './profile'
import { sharingReducer, SharingState } from './sharing'
import { organisationsReducer, OrganisationsState } from './organisations'
import { notificationsReducer, NotificationsState } from './notifications'
import { layoutReducer, LayoutState } from './layout'

export interface ApplicationState {
	config: ConfigState
	document: DocumentState
	drive: DriveState
	profile: ProfileState
	modal: ModalState
	busy: BusyState
	messaging: MessagingState
	feedback: FeedbackState
	sharing: SharingState
	organisations: OrganisationsState
	notifications: NotificationsState
	layout: LayoutState
}

export const createRootReducer = (): Reducer<ApplicationState> =>
	combineReducers({
		config: configReducer,
		document: documentReducer,
		drive: driveReducer,
		profile: profileReducer,
		modal: modalReducer,
		busy: busyReducer,
		messaging: messagingReducer,
		feedback: feedbackReducer,
		sharing: sharingReducer,
		organisations: organisationsReducer,
		notifications: notificationsReducer,
		layout: layoutReducer
	})
