import { DriveItemType } from '@lynx/client-core/src/api/interfaces'
import { DeleteIcon, EditIcon, FileDownloadIcon, FolderMoveIcon, PreviewIcon, ShareIcon } from '@lynx/client-core/src/components/Icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DriveItemActionType } from 'pages/Drives/DriveList/Drive.types'

export interface FileMenuItems {
	name: string
	img: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	key: DriveItemActionType

	isVisible: boolean
}

export const useItemMenuActions = (driveItem: DriveItemType): { actions: FileMenuItems[] } => {
	const { t } = useTranslation()
	if (!driveItem.capabilities) return { actions: [] }
	const { canDelete, canRemove, canDownload, canEdit, canMoveItemWithinDrive, canOpen, canShare, canRename } = driveItem.capabilities

	const menuItems: FileMenuItems[] = [
		{
			key: 'open',
			img: PreviewIcon,
			name: t('components.drive.gridBrowserFile.preview'),
			isVisible: canOpen
		},
		{
			key: 'edit',
			img: EditIcon,
			name: `LYNX ${t('components.drive.gridBrowserFile.live')}`,
			isVisible: canEdit
		},
		{
			key: 'share',
			img: ShareIcon,
			name: t('components.drive.gridBrowserFile.share'),
			isVisible: canShare
		},
		{
			key: 'move',
			img: FolderMoveIcon,
			name: t('components.drive.gridBrowserFile.move'),
			isVisible: canMoveItemWithinDrive
		},
		{
			key: 'rename',
			img: EditIcon,
			name: t('components.drive.gridBrowserFile.rename'),
			isVisible: canRename
		},
		{
			key: 'remove',
			img: DeleteIcon,
			name: t('components.drive.gridBrowserFile.remove'),
			isVisible: canRemove
		},
		{
			key: 'delete',
			img: DeleteIcon,
			name: t('components.drive.gridBrowserFile.delete'),
			isVisible: canDelete
		},
		{
			key: 'download',
			img: FileDownloadIcon,
			name: t('components.drive.gridBrowserFile.download'),
			isVisible: canDownload
		}
	]

	return {
		actions: menuItems.filter(({ isVisible: isCapable }) => isCapable)
	}
}
