import React, { useRef } from 'react'
import css from './TransitionSwitch.module.scss'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
interface Props {
	elKey: string
	children: React.ReactElement | null
	className?: string
}

export const TransitionSwitch = ({ className = css.container, children, elKey }: Props): React.ReactElement => {
	const defaultContainerRef = useRef<HTMLDivElement | null>(null)
	const classes = [className]

	return (
		<SwitchTransition mode={'out-in'}>
			<CSSTransition
				key={elKey}
				nodeRef={defaultContainerRef}
				addEndListener={(done: () => void): void => {
					defaultContainerRef?.current?.addEventListener('transitionend', done, false)
				}}
				classNames={{
					enter: css.enter,
					enterActive: css.enterActive,
					exit: css.exit,
					exitActive: css.exitActive
				}}
			>
				<div ref={defaultContainerRef} className={classes.join(' ')}>
					{children}
				</div>
			</CSSTransition>
		</SwitchTransition>
	)
}
