import { APIRequest } from '@lynx/client-core/src/api'
import { Logger } from '@lynx/client-core/src/modules'
import * as qs from 'query-string'
import * as React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ApplicationState } from 'store'

export const ShortURLProxy = (): React.ReactElement | null => {
	const params = useParams<{ code: string }>()
	const navigate = useNavigate()
	const { config } = useSelector((state: ApplicationState) => state)
	const { code } = params

	const namespaceMap: { [key: string]: string } = {
		activities: config.LYNXACTIVITIES_CLIENT
	}

	useEffect(() => {
		const mount = async (): Promise<void> => {
			if (!code) {
				return
			}

			const urlGetResponse = await APIRequest.Url.get(code)
			const responseData = urlGetResponse?.data
			if (!responseData) {
				navigate('/')
				return
			}

			// If we have a URL then open it
			if (responseData.type === 'redirect') {
				window.location.href = responseData.url
				return
			}

			const { namespace } = responseData

			// If we have a namespace then reroute to that to let them deal with it
			const route = namespaceMap[namespace]
			if (!route) {
				Logger.error('missing route for namespace: ' + namespace)
				return
			}

			// Rebuild the URL with the code query param
			const parsedUrl = qs.parseUrl(route)
			parsedUrl.query.code = code

			const { url, query } = parsedUrl

			const rebuiltRoute = qs.stringifyUrl({ url, query })

			if (route.includes('http')) {
				window.location.href = rebuiltRoute
				return
			}

			navigate(rebuiltRoute)
		}
		mount()
	}, [])
	return null
}
