import {
	BasicButton,
	BulletPointItem,
	BulletPoints,
	CreateIcon,
	Document,
	DownloadButton,
	FeatureItem,
	FeatureSection,
	FooterItem,
	FooterLinksItem,
	GroupedButtons,
	HeroItem,
	ScreenShareIllustration1Src,
	ScreenShareIllustration2Src,
	ScreenShareIllustration3Src
} from '@lynx/client-core/src/components'
import { useDownloadLinks } from '@lynx/client-core/src/hooks'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { VideoRenderer } from 'components/VideoRenderer'

export const videoOptions = { fluid: true, loop: false, controls: false, muted: true }

export const DownloadScreenShare = (): React.ReactElement => {
	const { icon, to, text } = useDownloadLinks('screenshare')
	const { t } = useTranslation()

	return (
		<Document title={t('pages.landing.title')}>
			<FeatureSection className={'hero-background'} backgroundColor="#272727">
				<HeroItem
					title={t('pages.landing.screenSharingSolutions')}
					body={
						<BulletPoints>
							{[
								{ icon: <CreateIcon fill="#AF66FF" />, text: t('pages.landing.shareYourScreen') },
								{ icon: <CreateIcon fill="#00C5FF" />, text: t('pages.landing.manageParticipants') },
								{ icon: <CreateIcon fill="#28C283" />, text: t('pages.landing.compatibleWith') }
							].map(({ icon, text }, key) => (
								<BulletPointItem key={key} icon={icon} text={text} />
							))}
						</BulletPoints>
					}
					footer={''}
					actions={
						<GroupedButtons>
							<DownloadButton icon={icon} label={t('pages.landing.downloadOn')} text={text} to={to} />
							<BasicButton size="xl" variant="transparent" to="/more-downloads">
								{t('pages.landing.moreDownloads')}
							</BasicButton>
						</GroupedButtons>
					}
				/>
				<VideoRenderer blob="/assets/images/LSS-video.webm" implicitExtension={true} options={videoOptions} />
			</FeatureSection>

			<FeatureSection>
				<FeatureItem
					label={t('pages.landing.remoteWorking')}
					accentColor="#AF66FF"
					title={t('pages.landing.shareYourScreen')}
					body={t('pages.landing.enablesParticipants')}
					action={t('pages.landing.learnMore')}
				/>
				<img src={ScreenShareIllustration1Src} alt="ScreenShareIllustration1" />
			</FeatureSection>

			<FeatureSection backgroundColor="#F6F6F6">
				<img src={ScreenShareIllustration2Src} alt="ScreenShareIllustration2" />
				<FeatureItem
					label={t('pages.landing.secureControls')}
					accentColor="#00C5FF"
					title={t('pages.landing.manageParticipants')}
					body={t('pages.landing.viewParticipants')}
					action={t('pages.landing.learnMore')}
				/>
			</FeatureSection>

			<FeatureSection backgroundColor="#FCFCFC">
				<FeatureItem
					label={t('pages.landing.workTogether')}
					accentColor="#28C283"
					title={t('pages.landing.worksWith')}
					body={t('pages.landing.shareBetween')}
					action={t('pages.landing.learnMore')}
				/>
				<img src={ScreenShareIllustration3Src} alt="ScreenShareIllustration3" />
			</FeatureSection>

			<FeatureSection backgroundColor="#F6F6F6" height="auto">
				<FooterItem />
				<FooterLinksItem />
			</FeatureSection>
		</Document>
	)
}
