import * as React from 'react'
import css from './Toggle.module.scss'

interface ToggleProps {
	id?: string
	checked?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Toggle: React.FunctionComponent<ToggleProps> = ({ id, checked, onChange }) => {
	return (
		<label htmlFor={id} className={css['toggle']}>
			<input id={id} type="checkbox" checked={checked} onChange={onChange} aria-label="toggle" />
			<span className={css['toggle-slider']}></span>
		</label>
	)
}
