import * as React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { NotificationToast } from './NotificationToast'
import { NotificationToastPortal } from './NotificationToastPortal'
import css from './NotificationToastContainer.module.scss'

export const NotificationToastContainer = (): React.ReactElement => {
	const { notificationToasts } = useSelector((state: ApplicationState) => state.notifications)

	return (
		<NotificationToastPortal>
			<div className={css['toast-container']}>
				{notificationToasts.map((notificationToast) => (
					<div key={notificationToast.toastId} className={css.toastAnimation}>
						<NotificationToast notification={notificationToast} />
					</div>
				))}
			</div>
		</NotificationToastPortal>
	)
}
