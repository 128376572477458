import { BaseCall } from '../BaseCall'
import { APIConfig, APIResponse } from '../interfaces'

export class Url extends BaseCall {
	public get = async (code: string): Promise<APIResponse<{ type: 'redirect'; url: string } | { type: 'data'; data: string; namespace: string } | null>> => {
		try {
			const response = await this.client.get<APIResponse>(`/url/${code}`)
			if (response.status !== 200) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return null
			}

			return response.data
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
