import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteOrganisation } from 'store/organisations'
import css from './OrganisationDeleteModal.module.scss'

export const OrganisationDeleteModal = (): React.ReactElement | null => {
	const organisation = useCurrentOrganisation()
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()

	if (!organisation) return null

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}

	const handleDeleteClicked = async (): Promise<void> => {
		if (!organisation) return
		await dispatchThunk(deleteOrganisation(organisation.id))
		dispatch(hideModal())
	}

	return (
		<Modal name="OrganisationDeleteModal" title="Delete Organisation" variant="danger">
			<div className={css['container']}>
				<div>
					{organisation.organisationName} {t('pages.organisations.organisationWillBeDeleted')}
				</div>
				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
					<BasicButton variant="danger" onClick={handleDeleteClicked}>
						{t('pages.organisations.delete')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
