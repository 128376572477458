import { BaseCall } from '../BaseCall'
import { APIConfig, APIResponse } from '../interfaces'

export class Newsletter extends BaseCall {
	public subscribe = async (list?: string): Promise<APIResponse | null> => {
		try {
			const response = await this.client.put<APIResponse>('/newsletter/subscribe', {
				list
			})

			return response?.data || null
		} catch (e) {
			console.error(e)
			return null
		}
	}

	public subscribed = async (): Promise<APIResponse<{ newsletters: string[] }> | null> => {
		try {
			const response = await this.client.get<APIResponse<{ newsletters: string[] }>>('/newsletter/list')

			return response?.data || null
		} catch (e) {
			console.error(e)
			return null
		}
	}
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	public unsubscribe = async (list?: string): Promise<APIResponse> | null => {
		try {
			const response = await this.client.post<APIResponse>('/newsletter/unsubscribe', {
				list
			})

			return response?.data || null
		} catch (e) {
			console.error(e)
			return null
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
