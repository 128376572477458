import { APIRequest } from '@lynx/client-core/src/api'
import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { ApplicationState } from 'store'
import { clearDownload, documentAvailable } from 'store/document'
import { CONVERSION_READY, READ_MESSAGE, UserMessagingActionTypes } from './types'
import { NotificationTypes } from '@lynx/core/src/interfaces/Notifications'

export const userMessaging = (): Middleware => {
	const user: Middleware =
		({ getState }: MiddlewareAPI<ThunkDispatch<ApplicationState, null, Action>, ApplicationState>) =>
		(next: Dispatch) =>
		async (action: AnyAction): Promise<AnyAction> => {
			switch (action.type) {
				case READ_MESSAGE: {
					const message: UserMessagingActionTypes | NotificationTypes<'parsed'> = JSON.parse(action.payload.message)
					switch (message.type) {
						case CONVERSION_READY: {
							const documentState = getState().document
							if (message.channelId === documentState.channelId) {
								// Check if we should automatically download it
								if (documentState.autoDownload) {
									await APIRequest.Sharing.download(message.payload.shareToken)
									return next(clearDownload())
								} else {
									await APIRequest.Channels.delete(message.channelId)
									return next(documentAvailable(message.payload.shareToken))
								}
							}
							return next(action)
						}

						default: {
							return next(action)
						}
					}
				}
				default: {
					return next(action)
				}
			}
		}

	return user
}
