import * as React from 'react'
import { CheckboxProps } from './CheckboxProps'
import Styles from './index.module.css'

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({ className, onChange, checked, disabled, value, ...rest }) => {
	const classNames = [Styles['container'], disabled && Styles.disabled, className].filter((c) => c)

	return (
		<div className={classNames.join(' ')}>
			<input type="checkbox" className={Styles.input} value={value} disabled={disabled} onChange={onChange} checked={checked} {...rest} />
		</div>
	)
}
