import { APIRequest } from '@lynx/client-core/src/api'
import { SharesInfo } from '@lynx/client-core/src/api/interfaces'
import { FileIcon } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { ControlBar } from 'pages/Share/components/ControlBar'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Styles from './Standard.module.css'

interface StandardState {
	shareDetails: SharesInfo | null
	token: string
}

export const Standard = (): React.ReactElement => {
	const [state, setState] = useState<StandardState>({
		token: '',
		shareDetails: null
	})
	const params = useParams<{ token: string }>()
	const navigate = useNavigate()

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const { token } = params
			if (!token) {
				return
			}

			const shareInfo = await APIRequest.Sharing.info(token)
			if (!shareInfo?.data) {
				navigate('/missing-share')
				return
			}

			setState({ ...state, shareDetails: shareInfo.data, token })
		}

		mount()
	}, [])

	const handleViewClicked = (): void => {
		Logger.log('view clicked')
	}

	const handleDownload = async (): Promise<void> => {
		const blobLinkProperties = await APIRequest.Sharing.getObjectUrl(state.token)

		if (!blobLinkProperties) {
			navigate('/missing-share')
		}

		const link = document.createElement('a')
		link.href = blobLinkProperties.link
		document.body.appendChild(link)
		link.download = state.shareDetails?.name || 'download'
		link.click()
		document.body.removeChild(link)
	}

	return (
		<div className={Styles['container']}>
			<div className={Styles['description-container']}>
				<FileIcon />
			</div>
			<ControlBar onDownloadClicked={handleDownload} onViewClicked={handleViewClicked} shareDetails={state.shareDetails} token={state.token} />
		</div>
	)
}
