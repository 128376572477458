import { ApplicationState } from '../'
import * as actions from '@lynx/client-core/src/store/messaging/actions'
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { CreateChannelResponse } from '@lynx/client-core/src/api/interfaces'

export const wsConnect = actions.wsConnect
export const wsDisconnect = actions.wsDisconnect
export const wsMessage = actions.wsMessage

type deleteChannel = (...args: Parameters<typeof actions.deleteChannel>) => ThunkAction<void, ApplicationState, null, Action<string>>
export const deleteChannel = actions.deleteChannel as unknown as deleteChannel

type subscribe = (...args: Parameters<typeof actions.subscribe>) => ThunkAction<void, ApplicationState, null, Action<string>>
export const subscribe = actions.subscribe as unknown as subscribe

type subscribeChannel = (...args: Parameters<typeof actions.subscribeChannel>) => ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>>
export const subscribeChannel = actions.subscribeChannel as unknown as subscribeChannel

type subscribedChannels = (...args: Parameters<typeof actions.subscribedChannels>) => ThunkAction<Promise<void>, ApplicationState, null, Action<string>>
export const subscribedChannels = actions.subscribedChannels as unknown as subscribedChannels

type unsubscribeChannel = (...args: Parameters<typeof actions.unsubscribeChannel>) => ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>>
export const unsubscribeChannel = actions.unsubscribeChannel as unknown as unsubscribeChannel

type createChannel = (
	...args: Parameters<typeof actions.createChannel>
) => ThunkAction<Promise<CreateChannelResponse | undefined>, ApplicationState, null, Action<string>>
export const createChannel = actions.createChannel as unknown as createChannel
