import { useThunkDispatch } from 'hooks/useThunkDispatch'
import { DrivePath } from '@lynx/client-core/src/api/interfaces'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { changeDrivePath } from 'store/drive'
import Styles from './Breadcrumbs.module.css'

export interface Breadcrumb {
	name: string
}

export const Breadcrumbs = (): React.ReactElement => {
	const { drive: driveState } = useSelector((state: ApplicationState) => state)
	const dispatchThunk = useThunkDispatch()

	const handleBreadcrumbClicked = async (id: string): Promise<void> => {
		if (driveState.provider === null) return
		await dispatchThunk(changeDrivePath(driveState.provider, id))
	}

	const breadcrumbs = driveState.paths.map((path: DrivePath, key: number): React.ReactElement => {
		return (
			<div key={key}>
				{key > 0 && <span className={Styles['separator']}>{'>'}</span>}
				<span
					onClick={(): void => {
						handleBreadcrumbClicked(path.id)
					}}
					className={Styles['link']}
				>
					{path.name.toLowerCase()}
				</span>
			</div>
		)
	})

	return <div className={Styles['container']}>{breadcrumbs}</div>
}
