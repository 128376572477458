import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Plan, PlansResponse } from '../api/Billing/Billing'
import { Logger } from '../modules'
import { ApplicationState } from '../store'

interface UseBillingPlansReturn {
	billingPlans: { [key: string]: Plan } | null
	refetchBillingPlans: () => Promise<void>
}

export const useBillingPlans = (currencyCode = 'usd'): UseBillingPlansReturn => {
	const [billingPlans, setBillingPlans] = useState<{ [key: string]: Plan } | null>(null)
	const config = useSelector((state: ApplicationState) => state.config)

	const uri = new URL(`${config.API_SERVER}/billing/plans`)
	currencyCode && uri.searchParams.append('currencyCode', currencyCode)

	const getBillingPlans = async (): Promise<void> => {
		try {
			const billingPlans = (await axios.get<PlansResponse>(uri.toString())).data
			const reducedPlans = billingPlans.reduce((acc: { [key: string]: Plan }, curr) => {
				acc[curr.subscriptionType] = curr
				return acc
			}, {})

			setBillingPlans(reducedPlans)
		} catch (e) {
			Logger.error(e)
		}
	}

	useEffect(() => {
		getBillingPlans()
	}, [currencyCode])

	return { billingPlans, refetchBillingPlans: getBillingPlans }
}
