import * as React from 'react'
import Styles from './FileInput.module.css'
import { FileInputProps } from './interfaces'

class FileInput extends React.Component<FileInputProps> {
	private fileInput: React.RefObject<HTMLInputElement> = React.createRef()

	private handleUpload = (evt: React.ChangeEvent<HTMLInputElement>): void => {
		if (!evt.target.files) {
			return
		}

		const { files } = evt.target
		const numberOfFiles = files.length

		for (const file of Array.from(files)) {
			this.props.onChange(file, numberOfFiles)
		}
	}

	render(): React.ReactNode {
		const { disabled, children, accept, multiple } = this.props
		const mimeTypeAccept = accept || '*'
		return (
			<div className={Styles['container']}>
				<input
					disabled={!!disabled}
					accept={mimeTypeAccept}
					multiple={!!multiple}
					type="file"
					name="FilePickerComponent"
					onChange={this.handleUpload}
					ref={this.fileInput}
				/>
				{React.cloneElement(children, {
					onClick: () => this.fileInput.current && this.fileInput.current.click()
				})}
			</div>
		)
	}
}

export default FileInput
