import { Document } from '@lynx/client-core/src/components'
import React from 'react'
import css from './MissingShare.module.scss'
import { useTranslation } from 'react-i18next'

export const MissingShare = (): React.ReactElement => {
	const { t } = useTranslation()

	return (
		<Document title="Missing Share">
			<div className={css['container']}>
				<div>{t('pages.missingShare.warning')}</div>
			</div>
		</Document>
	)
}
