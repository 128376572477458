import { Reducer } from 'redux'
import { initialModalState } from './initialState'
import { HIDE_MODAL, ModalActionTypes, ModalState, SHOW_MODAL, SET_MODAL_CONTEXT } from './types'
const reducerProvider = <MC>(): Reducer<ModalState<MC>, ModalActionTypes<MC>> => {
	const reducer = (state: ModalState<MC> = initialModalState(), action: ModalActionTypes<MC>): ModalState<MC> => {
		switch (action.type) {
			case SHOW_MODAL: {
				const { name, context, sticky } = action.payload
				return {
					...state,
					...{
						name,
						context,
						sticky: sticky === undefined ? false : true
					}
				}
			}
			case HIDE_MODAL: {
				return {
					...state,
					...{
						name: '',
						context: undefined,
						sticky: false
					}
				}
			}
			case SET_MODAL_CONTEXT: {
				return {
					...state,
					context: action.payload.context
				}
			}
			default: {
				return state
			}
		}
	}
	return reducer
}

export { reducerProvider }
