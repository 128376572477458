import { Action, AnyAction, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ApplicationState } from '../'
import { connect as websocketConnect, disconnect, send } from '../../middleware/reduxwebsockets'
import { CREATE_CHANNEL, MessagingActionTypes, SUBSCRIBED_CHANNELS, SUBSCRIBE_CHANNEL, UNSUBSCRIBE_CHANNEL, WEBSOCKET_PREFIX } from './types'
import { Channel, CreateChannelResponse } from '../../api/interfaces'
import { APIRequest } from '../../api'

export const wsConnect = (url: string): AnyAction => {
	return websocketConnect(url, WEBSOCKET_PREFIX)
}

export const wsDisconnect = (): AnyAction => {
	return disconnect(WEBSOCKET_PREFIX)
}

export const wsMessage = (body: unknown): AnyAction => {
	return send(body, WEBSOCKET_PREFIX)
}

const createChannelInternal = (createChannelResponse: CreateChannelResponse): MessagingActionTypes => {
	return {
		type: CREATE_CHANNEL,
		payload: {
			channel: createChannelResponse
		}
	}
}

const subscribeInternal = (channelId: string): MessagingActionTypes => {
	return {
		type: SUBSCRIBE_CHANNEL,
		payload: {
			channelId
		}
	}
}

const subscribedChannelsInternal = (channels: Channel[]): MessagingActionTypes => {
	return {
		type: SUBSCRIBED_CHANNELS,
		payload: {
			channels
		}
	}
}

export const deleteChannel =
	(channelId: string): ThunkAction<void, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			await APIRequest.Channels.delete(channelId)

			const channels = await APIRequest.Channels.subscribed()

			if (channels) {
				dispatch(subscribedChannelsInternal(channels))
			}
		} catch (e) {
			console.error(e)
			return undefined
		}
	}

const subscribeChannelInternal = (channelId: string): MessagingActionTypes => {
	return {
		type: SUBSCRIBE_CHANNEL,
		payload: {
			channelId
		}
	}
}

export const subscribeChannel =
	(channelId: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<boolean> => {
		try {
			const subscribed = await APIRequest.Channels.subscribe(channelId)

			if (subscribed) {
				dispatch(subscribeChannelInternal(channelId))
			}

			return subscribed
		} catch (e) {
			console.error(e)
			return false
		}
	}

const unsubscribeChannelInternal = (channelId: string): MessagingActionTypes => {
	return {
		type: UNSUBSCRIBE_CHANNEL,
		payload: {
			channelId
		}
	}
}

export const unsubscribeChannel =
	(channelId: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<boolean> => {
		try {
			const unsubscribed: boolean = await APIRequest.Channels.unsubscribe(channelId)

			if (unsubscribed) {
				dispatch(unsubscribeChannelInternal(channelId))
			}

			return unsubscribed
		} catch (e) {
			console.error(e)
			return false
		}
	}

export const createChannel =
	(channelType: string, channelName: string, id?: string): ThunkAction<Promise<CreateChannelResponse | undefined>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<CreateChannelResponse | undefined> => {
		try {
			const channel = await APIRequest.Channels.create(channelType, channelName, id)

			if (channel) {
				dispatch(createChannelInternal(channel))
			} else {
				throw new Error('Channel not created')
			}

			return channel
		} catch (e) {
			console.error(e)
			return undefined
		}
	}

export const subscribe =
	(channelId: string): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const subscribed = await APIRequest.Channels.subscribe(channelId)

			if (subscribed) {
				dispatch(subscribeInternal(channelId))
			}
		} catch (e) {
			console.error(e)
			return undefined
		}
	}

export const subscribedChannels =
	(): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const channels: Channel[] | undefined = await APIRequest.Channels.subscribed()

			if (channels) {
				dispatch(subscribedChannelsInternal(channels))
			}
		} catch (e) {
			console.error(e)
			return undefined
		}
	}
