import React from 'react'
import css from './Label.module.scss'

export type LabelProps = React.InputHTMLAttributes<HTMLLabelElement> & {
	accentColor?: string
	color?: string
	text: string
	uppercase?: boolean
}

export const Label = ({ text, accentColor, uppercase = false, color = '#545454', ...rest }: LabelProps): React.ReactElement => (
	<div className={css['container']}>
		{accentColor && <div className={css['container__accent']} style={{ backgroundColor: accentColor }} />}
		<label style={{ color }} {...rest}>
			{uppercase ? text.toUpperCase() : text}
		</label>
	</div>
)
