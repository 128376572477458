import React from 'react'
import css from './NavigationBar.module.scss'
import { NavigationItem, NavigationItemProps } from './NavigationItem'

export interface NavigationBarProps {
	items: NavigationItemProps[]
	hidden?: boolean
	orientation?: 'horizontal' | 'vertical'
}

export const NavigationBar = ({ hidden = false, items, orientation = 'horizontal' }: NavigationBarProps): React.ReactElement | null => {
	const navigationItemsElements = items
		.filter((item) => item.visible ?? true)
		.map((args, key) => (
			<li key={key}>
				<NavigationItem {...args} />
			</li>
		))

	if (hidden) return null

	const classNames = [css['container']]
	orientation === 'vertical' && classNames.push(css['container__vertical'])

	return (
		<nav className={classNames.join(' ')}>
			<ul>{navigationItemsElements}</ul>
		</nav>
	)
}
