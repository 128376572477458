import { BasicButton, Input, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ModalContext } from 'store/modal'
import { disableOrganisationUser } from 'store/organisations'
import css from './UserDisableModal.module.scss'
import { UserDisableModalContext } from './UserDisableModalContext'
import { UserDisableModalContextType } from './UserDisableModalContextType'

export const UserDisableModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const [userContext, setUserContext] = useState<UserDisableModalContext | null>(null)
	const [reason, setReason] = useState<string>('')
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()

	const organisation = useCurrentOrganisation()
	if (!organisation) return null

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
		setUserContext(null)
	}

	const handleDeleteClicked = async (): Promise<void> => {
		if (!userContext) return

		await dispatchThunk(disableOrganisationUser(organisation.id, userContext.userId, reason))
		dispatch(hideModal())
	}

	const handleVisible = (context?: ModalContext): void => {
		if (context?.type !== UserDisableModalContextType) {
			return
		}

		setUserContext(context)
	}

	const handleReasonChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setReason(e.target.value)
	}

	return (
		<Modal name="UserDisableModal" title={t('pages.organisations.disableUser')} onVisible={handleVisible} variant="danger">
			<div className={css['container']}>
				<div>{`${t('pages.organisations.account')} ${userContext?.email} ${t('pages.organisations.willBeDisabled')}`}</div>
				<Input label={t('pages.organisations.reasonMax')} onChange={handleReasonChanged} />
				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
					<BasicButton variant="danger" disabled={!reason?.length} onClick={handleDeleteClicked}>
						{'Disable'}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
