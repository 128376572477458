import { BasicButton, Document, LynxWhiteboardLogo } from '@lynx/client-core/src/components'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import i18next from 'i18next'
import * as qs from 'query-string'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { ApplicationState } from 'store'
import { logout } from 'store/profile'
import css from './AutoLogin.module.scss'
import UserButtonItem from '@lynx/client-core/src/components/UserButtonItem/UserButtonItem'

export const AutoLogin = (): React.ReactElement => {
	const { profile: profileState } = useSelector((state: ApplicationState) => state)
	const navigate = useNavigate()
	const dispatchThunk = useThunkDispatch()
	const location = useLocation()
	const queryObj = qs.parse(location.search) as { [key: string]: string }

	const showLogin = async (): Promise<void> => {
		const query = qs.stringify(queryObj)
		await dispatchThunk(logout())
		navigate(`/auth/login?${query}`)
	}

	const alreadyAuthenticate = (): void => {
		const query = qs.stringify(queryObj)
		navigate(`/authenticated?${query}`)
	}

	return (
		<Document title={i18next.t('pages.pageTitles.login')} description="Login" keywords="Login">
			<div className={css.container}>
				<div className={css.contentContainer}>
					<LynxWhiteboardLogo />
					<h3>{i18next.t('pages.login.chooseAnAccount')}</h3>
					<div className={css.buttonsContainer}>
						<UserButtonItem profile={profileState} handleClick={alreadyAuthenticate} />
						<div className={css.buttonsFooter}>
							<BasicButton variant="light" onClick={showLogin}>
								{i18next.t('pages.login.useAnotherAccount')}
							</BasicButton>
						</div>
					</div>
				</div>
			</div>
		</Document>
	)
}
