import axios from 'axios'
import { OpenTDBResponse } from './OpenTDB.types'

export class OpenTDB {
	public get = async ({
		amount,
		difficulty,
		category = 0
	}: {
		category?: number
		amount: number
		difficulty: 'easy' | 'medium' | 'hard' | string
	}): Promise<OpenTDBResponse | null> => {
		const url = `https://opentdb.com/api.php?category=${category}&amount=${amount}&difficulty=${difficulty}&encode=url3986`

		try {
			const response = await axios.get<OpenTDBResponse>(url)
			if (response.data.response_code !== 0) {
				console.error('opentdb returned none 0 response_code (' + response.data.response_code + ')')
				return null
			}
			return response.data
		} catch (error) {
			console.error(error)
			return null
		}
	}
}
