import { ApplicationState } from '../'
import * as actions from '@lynx/client-core/src/store/profile/actions'
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { ProfileReturn } from '@lynx/client-core/src/api/interfaces'
// todo some generic typescript function for this dry stuff
export const { setLang } = actions
export const { setZoom } = actions
export const { setIsPro } = actions
export const { setUserPermissions } = actions
export const { setLoginModal } = actions
export const { changeProfileContextMenu } = actions

type clientLogout = (...args: Parameters<typeof actions.clientLogout>) => ThunkAction<Promise<void>, ApplicationState, null, Action<void>>
export const clientLogout = actions.clientLogout as unknown as clientLogout

type logout = (...args: Parameters<typeof actions.logout>) => ThunkAction<Promise<string>, ApplicationState, null, Action<string>>
export const logout = actions.logout as unknown as logout

type logoutEverywhere = (...args: Parameters<typeof actions.logoutEverywhere>) => ThunkAction<Promise<string>, ApplicationState, null, Action<string>>
export const logoutEverywhere = actions.logoutEverywhere as unknown as logoutEverywhere

type setEmail = (...args: Parameters<typeof actions.setEmail>) => ThunkAction<Promise<ProfileReturn>, ApplicationState, null, Action<string>>
export const setEmail = actions.setEmail as unknown as setEmail

type setProfilePicture = (...args: Parameters<typeof actions.setProfilePicture>) => ThunkAction<Promise<ProfileReturn>, ApplicationState, null, Action<string>>
export const setProfilePicture = actions.setProfilePicture as unknown as setProfilePicture

type setDisplayName = (...args: Parameters<typeof actions.setDisplayName>) => ThunkAction<Promise<ProfileReturn>, ApplicationState, null, Action<string>>
export const setDisplayName = actions.setDisplayName as unknown as setDisplayName

type login = (...args: Parameters<typeof actions.login>) => ThunkAction<Promise<string>, ApplicationState, null, Action<string>>
export const login = actions.login as unknown as login

type loginCookie = (...args: Parameters<typeof actions.loginCookie>) => ThunkAction<Promise<string>, ApplicationState, null, Action<string>>
export const loginCookie = actions.loginCookie as unknown as loginCookie

type loginWithTokenId = (...args: Parameters<typeof actions.loginWithTokenId>) => ThunkAction<Promise<string>, ApplicationState, null, Action<string>>
export const loginWithTokenId = actions.loginWithTokenId as unknown as loginWithTokenId
