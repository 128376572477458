import {
	BasicButton,
	EntraIdIcon,
	IServIcon,
	LynxWhiteboardLogo,
	NextcloudLogoWhiteIcon,
	SamlIcon,
	UcsIcon,
	UniventionIcon
} from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React from 'react'
import { useDispatch } from 'react-redux'
import { hideModal } from 'store/modal'
import css from './OrganisationTypeSelector.module.scss'

export interface OrganisationTypeSelectorProps {
	hideModal: typeof hideModal
	navigate: (
		section: string,
		context?: {
			isUCS: boolean
			isIserv: boolean
		}
	) => void
}

export const OrganisationTypeSelector = ({ navigate }: OrganisationTypeSelectorProps): React.ReactElement => {
	const dispatch = useDispatch()

	const selection = (
		type: 'OrganisationDetails' | 'NextCloudDomain' | 'SamlIntroduction' | 'OauthSetup' | 'EntraIdSetup',
		isUCS = false,
		isIserv = false
	): void => {
		navigate(type, { isUCS, isIserv })
	}

	const types: { name: string; icon: React.ReactElement; onClick: () => void; isBeta?: boolean }[] = [
		{
			name: 'LYNX Cloud',
			icon: <LynxWhiteboardLogo />,
			onClick: (): void => {
				selection('OrganisationDetails')
			}
		},
		{
			name: 'NextCloud',
			icon: <NextcloudLogoWhiteIcon />,
			onClick: (): void => {
				selection('NextCloudDomain')
			}
		},
		{
			name: 'SAML',
			icon: <SamlIcon />,
			onClick: (): void => {
				selection('SamlIntroduction')
			}
		},
		{
			name: 'Univention Corporate Server',
			icon: <UcsIcon />,
			onClick: (): void => {
				selection('SamlIntroduction', true)
			}
		},
		{
			name: 'iServ',
			icon: <IServIcon />,
			onClick: (): void => {
				selection('OauthSetup', true)
			}
		},
		{
			name: 'Microsoft EntraId',
			icon: <EntraIdIcon />,
			onClick: (): void => {
				selection('EntraIdSetup', true)
			}
		}
	]

	const list = types.map((item) => {
		const { name, icon, isBeta } = item

		return (
			<div key={name} onClick={item.onClick} className={css['selection']}>
				<div className={css['selection__icon']}>{icon}</div>
				<div className={css['selection__title']}>{name}</div>
				{isBeta && (
					<div className={css['selection__beta']}>
						<span>{i18next.t('pages.organisations.createModal.beta')}</span>
					</div>
				)}
			</div>
		)
	})

	return (
		<div className={css['container']}>
			<div>
				<p>{i18next.t('pages.organisations.createModal.selectType')}</p>
				{list}
			</div>
			<div className={css['button-container']}>
				<BasicButton
					onClick={(): void => {
						dispatch(hideModal())
					}}
					className={css['cancel-btn']}
				>
					{i18next.t('pages.organisations.createModal.cancel')}
				</BasicButton>
			</div>
		</div>
	)
}
