import { AddCircleOutlineIcon, BasicButton } from '@lynx/client-core/src/components'
import { showModal } from '@lynx/client-core/src/store/modal'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import css from './Landing.module.scss'

export const Landing = (): React.ReactElement => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleNewOrganisationClicked = (): void => {
		dispatch(showModal({ name: 'OrganisationCreateModal' }))
	}

	return (
		<div className={css['container']}>
			<div>{t('pages.organisations.start')}</div>
			<BasicButton icon={AddCircleOutlineIcon} variant="blue" onClick={handleNewOrganisationClicked}>
				{t('pages.organisations.newOrganisation')}
			</BasicButton>
		</div>
	)
}
