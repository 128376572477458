import { AxiosResponse } from 'axios'
import { BaseCall } from '../BaseCall'
import { APIConfig, DriveItemType, Group } from '../interfaces'
import { Providers } from '@lynx/core/src/interfaces/ObjectStore'
import { Logger } from '../../modules/Logger'
export class Groups extends BaseCall {
	public shareGroupToken = async (groupId: string, token: string): Promise<boolean> => {
		try {
			const response = await this.client.post<boolean>(`/share/group/${groupId}/token/${token}`)

			if (response.status === 200) {
				return response.data
			}

			return false
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public shareGroupCode = async (groupId: string, code: string): Promise<boolean> => {
		try {
			const response = await this.client.post<boolean>(`/share/group/${groupId}/code/${code}`)

			if (response.status === 200) {
				return response.data
			}

			return false
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public shareEmailCode = async (email: string, code: string): Promise<boolean> => {
		try {
			const response = await this.client.post<boolean>(`/share/email/${email}/code/${code}`)

			if (response.status === 200) {
				return response.data
			}

			return false
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public shareEmailToken = async (email: string, token: string): Promise<boolean> => {
		try {
			const response = await this.client.post<boolean>(`/share/email/${email}/token/${token}`)

			if (response.status === 200) {
				return response.data
			}

			return false
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

	public shareThumbnails = async (token: string): Promise<{ thumbs: Buffer[] } | null> => {
		try {
			const response = await this.client.get<{ thumbs: Buffer[] }>(`share/${token}/thumbnails`)

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public getGroups = async (): Promise<{ groups: Group[] } | null> => {
		try {
			const response = await this.client.get<{ groups: Group[] }>('/share/groups')

			if (response.status === 200) {
				return response.data
			}

			Logger.error(`${response.status}:${response.statusText}`)
			return null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public getGroupDetails = async (groupsId: string): Promise<void> => {
		const res = await this.client.get(`/share/groups/group/${groupsId}`)
		return res.data
	}

	public createGroup = async (groupName: string): Promise<void> => {
		const res = await this.client.post('/share/groups/new', { groupName })
		return res.data
	}

	public deleteGroup = async (groupId: string): Promise<void> => {
		const res = await this.client.post(`/share/groups/delete/${groupId}`, {})
		return res.data
	}

	public addEmailToGroup = async (email: string, groupId: string): Promise<void> => {
		const res = await this.client.post(`/share/groups/${groupId}/add`, { email })
		return res.data
	}

	public updateEmailToGroup = async (email: string, newEmail: string, groupId: string, deleteIt: boolean): Promise<void> => {
		const res = await this.client.post(`/share/groups/${groupId}/email/update`, { email, newEmail, deleteIt })
		return res.data
	}

	public shareGroupFile = async (groupId: string, file: DriveItemType | null, providerName: Providers | null): Promise<void> => {
		const res = await this.client.post('/share/groups', { groupId, file, providerName })
		return res.data
	}

	public getShareLink = async (driveItemId: string, provider: Providers | null, expiresInDays = 14): Promise<AxiosResponse> => {
		return await this.client.post('/share/link', { fileId: driveItemId, provider, expiresInDays })
	}

	public shareEmailFile = async (email: string, file: DriveItemType | null, providerName: Providers | null): Promise<void> => {
		const res = await this.client.post('/share/email', { email, file, providerName })
		return res.data
	}

	public getSharedFileDetails = async (token: string): Promise<{ file: DriveItemType; result: any } | undefined> => {
		try {
			const response = await this.client.get(`/share/file/short/${token}/details`)

			if (response.status === 200) {
				return response.data
			}
		} catch (e) {
			Logger.error(e)
		}
	}

	public downloadSharedFile = async (token: string): Promise<void> => {
		this.client.get(`/share/file/short/${token}`, { responseType: 'blob' }).then((response) => {
			let downloadLink = ''
			downloadLink = this.getContentDispositionHeader(response)
			if (!downloadLink) {
				return
			}

			const blob = new Blob([response.data], { type: response.headers['content-type'] })
			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = downloadLink
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		})
	}

	public downloadSharedLink = async (token: string): Promise<{ link: string; extension: string } | null | undefined> => {
		const response = await this.client.get(`/share/file/short/${token}`, { responseType: 'blob' })

		if (response.status !== 200) {
			return null
		}

		let downloadLink = ''
		downloadLink = this.getContentDispositionHeader(response)
		if (!downloadLink) {
			return null
		}

		const extension = downloadLink.split('.').pop()
		if (!extension) {
			return
		}

		const blob = new Blob([response.data], { type: response.headers['content-type'] })

		return { link: window.URL.createObjectURL(blob), extension }
	}

	public downloadSharedFileBlob = async (token: string): Promise<Blob | undefined> => {
		try {
			const response = await this.client.get(`/share/file/short/${token}`, { responseType: 'blob' })

			if (response.status === 200) {
				const blob = new Blob([response.data], { type: response.headers['content-type'] })

				return blob
			}
		} catch (e) {
			Logger.error(e)
		}
	}

	public downloadSharedFileBuffer = async (token: string): Promise<Buffer | void> => {
		try {
			const response = await this.client.get(`/share/file/short/${token}`, { responseType: 'arraybuffer' })

			if (response.status === 200) {
				return Buffer.from(response.data)
			}
		} catch (e) {
			Logger.error(e)
		}
	}

	public uploadSharedFile = async (token: string, userId: string): Promise<{ status: string; result?: DriveItemType }> => {
		try {
			const result = await this.client.put(`/share/file/short/${token}/upload/${userId}`)
			return result.data
		} catch (err) {
			Logger.error(err)
			return { status: 'failed' }
		}
	}

	public changeSharePrivacy = async (token: string, makePublic: boolean): Promise<boolean> => {
		try {
			const result = await this.client.patch(`/share/file/${token}/${makePublic.toString()}`)

			return result.status === 200
		} catch (err) {
			Logger.error(err)
			return false
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
