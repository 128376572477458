import React, { useEffect, useState, ReactElement, ChangeEvent } from 'react'
import {
	BasicButton,
	Document,
	ImageSearchColorIcon,
	SearchColorIcon,
	TextField,
	Tabs,
	FeatureSection,
	FooterItem,
	FooterLinksItem
} from '@lynx/client-core/src/components'
import css from './Portal.module.scss'
import SectionTitle from './components/SectionTitle/SectionTitle'
import FeaturesCarousel from './components/FeaturesCarousel/FeaturesCarousel'
import { APIRequest } from '@lynx/client-core/src/api'
import { ChangeLogs } from '@lynx/client-core/src/api/Logs'
import data from './data.json'
import SectionItem from './components/SectionItem/SectionItem'
import { ApplicationState } from '@lynx/client-core/src/store'
import { useSelector } from 'react-redux'
import i18next from 'i18next'

export const Portal = (): ReactElement | null => {
	const { config } = useSelector((state: ApplicationState) => state)

	const [search, setSearch] = useState('')
	const [selectedTab, setSelectedTab] = useState<string>('lynx-cloud')

	const [changeLogs, setChangeLogs] = useState<ChangeLogs | undefined>()
	const [loadingLogs, setLoadingLogs] = useState<boolean>(false)

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setSearch(event.target.value)
	}

	const searchGoogle = (): void => {
		window.location.href = `https://www.google.com/search?q=${search}`
	}

	const searchGoogleImages = (): void => {
		window.location.href = `https://www.google.com/search?q=${search}&tbm=isch`
	}

	const loadReleaseNotes = async (product: string): Promise<void> => {
		let environment: 'current' | 'releases' = 'releases'
		let os = 'win64'

		if (selectedTab === 'lynx-cloud') {
			product = 'cloud'
			environment = 'current'
			os = 'win64'
		}

		setChangeLogs(undefined)
		setLoadingLogs(true)

		try {
			const changeLogsData = await APIRequest.Logs.getChangeLogs(product, environment === 'current' ? 'production' : environment, os)
			if (!changeLogsData) {
				return
			}

			setChangeLogs(changeLogsData)
		} finally {
			setLoadingLogs(false)
		}
	}

	useEffect(() => {
		loadReleaseNotes(selectedTab)
	}, [selectedTab])

	return (
		<Document title="LYNX Portal" description="LYNX Portal" keywords="LYNX Portal">
			<div className={css['container']}>
				<div className={css['search-container']}>
					<div className={css['search-content']}>
						<div className={css['search']}>
							<TextField
								autoFocus={true}
								name="search"
								onChange={handleChange}
								onKeyDown={(e): void => {
									if (e.key === 'Enter') {
										searchGoogle()
									}
								}}
								placeholder={i18next.t('pages.portal.search')}
								className={`${css['textfield']} ${css['textfield-button']}`}
								adornment={
									<div className={css['search-adornment']}>
										<div onClick={searchGoogle}>
											<SearchColorIcon />
										</div>
										<div onClick={searchGoogleImages}>
											<ImageSearchColorIcon />
										</div>
									</div>
								}
								justifyAdornment="end"
								value={search}
							/>
						</div>
						<div className={css['search-suggestions']}>
							<div className={css['intro']}>{i18next.t('pages.portal.clevertouchNote')}</div>
							<div className={css['powered-by-container']}>
								<img alt="powered by" src={'/assets/icons/powered_by_google_on_non_white_hdpi.png'} />
							</div>
						</div>
					</div>
				</div>
				<div className={css['content-container']}>
					<div className={css['sidebar']}>
						<SectionTitle title={i18next.t('pages.portal.whatsNew')} />
						<div className={css['sidebar-container']}>
							<div
								style={{
									height: 200
								}}
							>
								<FeaturesCarousel items={data.features} />
							</div>
							<div className={css['tabs-container']}>
								<Tabs
									tabs={[
										{
											label: 'LYNX Cloud',
											key: 'lynx-cloud'
										},
										{
											label: 'LYNX Whiteboard',
											key: 'lynx-whiteboard'
										}
									]}
									selectedTab={selectedTab}
									setSelectedTab={setSelectedTab}
								/>
							</div>
							<div className={css['release-notes-container']}>
								<div className={css['release-notes']}>
									{loadingLogs && <div>{i18next.t('pages.portal.loading')}</div>}
									{!loadingLogs && !changeLogs && <div>{i18next.t('pages.portal.noResults')}</div>}
									{changeLogs?.versions.map((changeLog, index) => (
										<div key={index}>
											<div className={css['release-notes-day']}>{changeLog.versionDate}</div>
											<ul className={css['release-notes-list']}>
												{changeLog.changes.map((change, index) => (
													<li key={index} className={css['release-notes-description']}>
														- {change.description}
													</li>
												))}
											</ul>
										</div>
									))}
								</div>
							</div>
							<div className={css['button-more-container']}>
								<BasicButton
									variant="grey"
									size="xs"
									onClick={(): void => {
										window.location.href = '/change-logs/cloud/current/web'
									}}
								>
									{i18next.t('pages.portal.viewReleaseNotes')}
								</BasicButton>
							</div>
						</div>
					</div>
					<div className={css['content']}>
						<SectionTitle title={i18next.t('pages.portal.newActivities')} />
						<div className={css['section-container']}>
							<div className={css['tiles-container']}>
								{data.activities.map((activity, index) => (
									<SectionItem key={index} title={activity.title} image={activity.image} url={config.LYNXACTIVITIES_CLIENT} />
								))}
							</div>
						</div>
						<SectionTitle title={i18next.t('pages.portal.helpAndTutorials')} />
						<div className={css['section-container']}>
							<div className={css['tiles-container']}>
								{data.tutorials.map((tutorial, index) => (
									<SectionItem key={index} title={tutorial.title} image={tutorial.image} url={tutorial.url} />
								))}
							</div>
						</div>
						<SectionTitle title={i18next.t('pages.portal.stemClassroomTechnology')} />
						<div className={css['section-container']}>
							<div className={css['tiles-container']}>
								{data.thirdParty.map((thirdParty, index) => (
									<SectionItem key={index} title={thirdParty.title} image={thirdParty.image} url={thirdParty.url} />
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<FeatureSection backgroundColor="#171717" height="auto" className={css['portalCustomFooter']}>
				<FooterItem variant="dark" />
				<FooterLinksItem variant="dark" />
			</FeatureSection>
		</Document>
	)
}

export default Portal
