import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

export const RegReportByCountry = (): React.ReactElement => {
	const navigate = useNavigate()
	const [reportCountry, setReportCountry] = useState([])
	const columns = ['Country', 'UsageCount']

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const data = await APIRequest.Reports.getRegReportByCountry()
			setReportCountry(data.reportCountries)
		}
		mount()
	}, [])

	const goToDetails = (data: any): void => {
		navigate('/reports/reg-report-detail-country/' + data.Country)
	}

	return (
		<Document
			title="Registration Report - By Country"
			description="Registration Report - By Country"
			keywords="LYNX, Registration, By Country, Report"
			auth={true}
			disableOverflow={true}
		>
			<Card>
				<Table
					onRowClick={goToDetails}
					title={'Reg Report By Country'}
					headers={columns.map((v) => ({ label: v, valueKey: v }))}
					items={reportCountry}
				/>
			</Card>
		</Document>
	)
}
