import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Input, Modal } from '@lynx/client-core/src/components'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { Drive } from 'pages/Drives/Drive'
import { SavingModal } from 'pages/Drives/Modals/SavingModal'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { ApplicationState } from 'store'
import { changeDrive, hideDriveBrowser, makeDriveReadonly, makeDriveWriteable } from 'store/drive'
import { hideModal, showModal } from 'store/modal'
import css from './SaveAsModal.module.scss'
import { useTranslation } from 'react-i18next'
import { useThunkDispatch } from 'hooks'

export const SaveAsModal = (): React.ReactElement | null => {
	const [filename, setFilename] = useState('')
	const [originalFilename, setOriginalFilename] = useState('')
	const { drive: driveState } = useSelector((state: ApplicationState) => state)
	const params = useParams<{ token: string }>()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const dispatchThunk = useThunkDispatch()
	const previousDrivePopup = usePrevious(driveState.popup)
	const previousSelectedDriveItem = usePrevious(driveState.selectedDriveItem)

	const handleVisible = async (): Promise<void> => {
		dispatch(makeDriveReadonly())
		const { token } = params
		if (!token) {
			return
		}

		try {
			const details = await APIRequest.Sharing.info(token)
			const filename = details.data?.name || ''
			setFilename(filename)
			setOriginalFilename(filename)
		} catch (e) {
			Logger.error(e)
			setFilename('')
		}
	}

	useEffect(() => {
		if (driveState.popup && !previousDrivePopup) {
			handleVisible()
		}

		const selectedItem = driveState.selectedDriveItem

		if (previousSelectedDriveItem?.id !== selectedItem?.id) {
			if (!selectedItem?.name || selectedItem.size === 0) {
				setFilename(originalFilename || '')
				return
			}

			setFilename(selectedItem?.name)
		}
	}, [driveState.popup, previousDrivePopup, previousSelectedDriveItem, driveState.selectedDriveItem])

	const handleHidden = (): void => {
		dispatch(makeDriveWriteable())
		dispatch(hideDriveBrowser())
	}

	const handleFileNameChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setFilename(e.target.value)
	}

	const handleSave = async (): Promise<void> => {
		// Need to get current path, and make sure it's valid
		const { paths, provider, currentPath } = driveState
		if (!provider) return
		if (paths[0].name === 'Home') {
			return
		}

		if (!filename) {
			return
		}

		// Need to get share token
		const { token } = params
		if (!token) {
			return
		}

		try {
			dispatch(showModal({ name: 'SavingModal' }))
			await APIRequest.Sharing.save(token, provider, currentPath.id, filename)
			dispatch(hideModal())
		} catch (e) {
			Logger.error(e)
		}
		handleHidden()
	}

	if (!driveState.popup) {
		return null
	}

	const { currentPath } = driveState
	return (
		<React.Fragment>
			<Modal
				name="Save As"
				title={t('components.drive.saveAs')}
				uncontrolled={true}
				className={css['container']}
				onVisible={handleVisible}
				onHidden={handleHidden}
			>
				<div className={css['content']}>
					<Drive initialProvider={'lynxcloud'} isHomeButton={false} />
					<div className={css['footer']}>
						<Input value={filename} onChange={handleFileNameChanged} placeholder={t('components.drive.filename')} />
						<BasicButton variant="blue" onClick={handleSave} disabled={currentPath.name === 'Home'}>
							{t('components.drive.save')}
						</BasicButton>
					</div>
				</div>
			</Modal>
			<SavingModal />
		</React.Fragment>
	)
}
