import { Reducer } from 'redux'
import { initialSharingState } from './initialState'
import {
	LOAD_ORGANISATIONS,
	LOAD_ORGANISATION_DETAILS,
	OrganisationsActionTypes,
	OrganisationsState,
	ORGANISATION_AVATAR_UPDATED,
	UPDATE_ORGANISATION_DATA,
	USER_ACCEPTED_INVITE
} from './types'

const reducer: Reducer<OrganisationsState, OrganisationsActionTypes> = (state = initialSharingState, action: OrganisationsActionTypes) => {
	switch (action.type) {
		case LOAD_ORGANISATION_DETAILS: {
			return {
				...state,
				activeOrganisationId: action.payload
			}
		}
		case ORGANISATION_AVATAR_UPDATED: {
			return {
				...state,
				organisations: state.organisations.map((org) => {
					if (org.id === action.payload) {
						return {
							...org,
							avatarUrl: org.avatarUrl + '&v=' + new Date().getTime()
						}
					}
					return org
				})
			}
		}
		case LOAD_ORGANISATIONS: {
			return {
				...state,
				organisations: action.payload,
				isLoaded: true
			}
		}
		case USER_ACCEPTED_INVITE: {
			return {
				...state,
				userAcceptedInvite: action.payload
			}
		}
		case UPDATE_ORGANISATION_DATA: {
			return {
				...state,
				organisations: state.organisations.map((organisation) => {
					if (organisation.id === action.payload.id) return action.payload
					return organisation
				})
			}
		}
		default: {
			return state
		}
	}
}

export { reducer as organisationsReducer }
