import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import i18next from 'i18next'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { DeleteFileModalContext, DeleteFileModalContextType, hideModal, ModalContext } from 'store/modal'

export const DeleteFileModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const [state, setState] = React.useState<DeleteFileModalContext>({
		type: DeleteFileModalContextType,
		confirmBody: '',
		confirmText: '',
		handleConfirm: async (): Promise<void> => {
			return
		}
	})

	const handleVisible = (context?: ModalContext): void => {
		if (context?.type !== DeleteFileModalContextType) {
			return
		}

		setState(context)
	}

	return (
		<Modal name="DeleteFileModal" onVisible={handleVisible} variant="danger" hideCloseButton>
			<ModalDefaultLayout
				title={i18next.t('pages.drive.modals.deleteModal.delete')}
				body={state.confirmBody}
				footer={
					<React.Fragment>
						<BasicButton onClick={(): ReturnType<typeof hideModal> => dispatch(hideModal())} variant="transparent">
							{i18next.t('pages.drive.modals.deleteModal.cancel')}
						</BasicButton>
						<BasicButton
							onClick={(): void => {
								state.handleConfirm()
								dispatch(hideModal())
							}}
							variant="danger"
						>
							{state.confirmText}
						</BasicButton>
					</React.Fragment>
				}
			/>
		</Modal>
	)
}
