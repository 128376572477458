import { APIRequest } from '@lynx/client-core/src/api'
import { Input, LoadingSpinner } from '@lynx/client-core/src/components'
import { SearchOrganisation } from '@lynx/core/src/interfaces/Organisation'
import { useEffect, useState } from 'react'
import css from './OrganisationUserLicensesCol.module.scss'
import { InputMessages } from '@lynx/client-core/src/components/Input/InputMessages'

interface Props {
	orgItem: SearchOrganisation
	isLoading: boolean
	editedLicenses: number | null
	setEditedLicenses: (value: number | null) => void
}

export const OrganisationUserLicensesCol = ({ orgItem, editedLicenses, setEditedLicenses }: Props): React.ReactElement => {
	const { userLicensesLimit, usersCount, subscriptionData } = orgItem
	useEffect(() => {
		setEditedLicenses(userLicensesLimit || subscriptionData?.usersProQuantity || null)
	}, [])

	const noTierPricingStripe = <InputMessages messages={[{ message: 'No tier pricing in stripe', type: 'info' }]} />
	const tierPricing = subscriptionData?.usersProQuantity ? `Stripe qty: ${subscriptionData.usersProQuantity}` : noTierPricingStripe
	return (
		<div className={css.container}>
			{usersCount} /
			<div className={css.inputContainer}>
				<Input value={String(editedLicenses)} className={css.input} type="number" onChange={(e): void => setEditedLicenses(Number(e.target.value))} />
				{tierPricing}
			</div>
		</div>
	)
}
