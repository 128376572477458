export interface DownloadsInterface {
	[os: string]: {
		name: string
		downloadUrl32?: string
		downloadUrl64?: string
		version: string
		description: string
		changeLog?: string
		icon: string
		productionLynx?: boolean
		type: 'dev' | 'beta' | 'prod'
	}[]
}

export const downloads: DownloadsInterface = {
	windows: [
		{
			name: 'LYNX Whiteboard',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-whiteboard/releases/win64/lynx-whiteboard.msi',
			version: 'https://downloads.lynxcloud.app/lynx-whiteboard/releases/win64/version.txt',
			description: 'components.manifest.lynxWhiteboard64Bit',
			changeLog: '/change-logs/lynx-whiteboard/releases/win64/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			productionLynx: true,
			type: 'prod'
		},
		{
			name: 'LYNX Screen Recorder',
			downloadUrl32: 'https://downloads.lynxcloud.app/lynxsr/production/win32/LynxScreenRecorder.msi',
			version: 'https://downloads.lynxcloud.app/lynxsr/production/win32/version.txt',
			description: 'components.manifest.screenRecorder',
			changeLog: '/change-logs/lynxsr/production/win32/',
			icon: 'https://downloads.lynxcloud.app/launcher-data/icons/lynx-screen-recorder.svg',
			type: 'prod'
		},
		{
			name: 'LYNX Whiteboard 64 Bit Beta',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-whiteboard/beta/win64/lynx-whiteboard-beta.msi',
			version: 'https://downloads.lynxcloud.app/lynx-whiteboard/beta/win64/version.txt',
			description: 'components.manifest.lynxWhiteboard64Bit',
			changeLog: '/change-logs/lynx-whiteboard/beta/win64/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			type: 'beta'
		},
		{
			name: 'LYNX Screen Share Beta',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-screen-share/beta/win64/lynx-screen-share-beta.msi',
			version: 'https://downloads.lynxcloud.app/lynx-screen-share/beta/win64/version.txt',
			description: 'components.manifest.lss',
			changeLog: '/change-logs/lynx-screen-share/beta/win64/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			type: 'dev'
		}
	],
	macos: [
		{
			name: 'LYNX Whiteboard',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-whiteboard/releases/macx/lynx-whiteboard.pkg',
			version: 'https://downloads.lynxcloud.app/lynx-whiteboard/releases/macx/version.txt',
			description: 'components.manifest.lynxWhiteboardMacOs',
			changeLog: '/change-logs/lynx-whiteboard/releases/macx/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			productionLynx: true,
			type: 'prod'
		},
		{
			name: 'LYNX Screen Recorder',
			downloadUrl32: 'https://downloads.lynxcloud.app/lynxsr/production/macx/LynxScreenRecorder.pkg',
			version: 'https://downloads.lynxcloud.app/lynxsr/production/macx/version.txt',
			description: 'components.manifest.lynxWhiteboardMacOs',
			changeLog: '/change-logs/lynxsr/production/macx/',
			icon: 'https://downloads.lynxcloud.app/launcher-data/icons/lynx-screen-recorder.svg',
			type: 'prod'
		},
		{
			name: 'LYNX Whiteboard Beta',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-whiteboard/beta/macx/lynx-whiteboard-beta.pkg',
			version: 'https://downloads.lynxcloud.app/lynx-whiteboard/beta/macx/version.txt',
			description: 'components.manifest.lynxWhiteboardMacOs',
			changeLog: '/change-logs/lynx-whiteboard/beta/macx/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			type: 'beta'
		}
	],
	linux: [
		{
			name: 'LYNX Whiteboard',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-whiteboard/releases/linux64/lynx-whiteboard.AppImage',
			version: 'https://downloads.lynxcloud.app/lynx-whiteboard/releases/linux64/version.txt',
			description: 'components.manifest.lynxWhiteboardLinux',
			changeLog: '/change-logs/lynx-whiteboard/releases/linux64/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			productionLynx: true,
			type: 'prod'
		},
		{
			name: 'LYNX Whiteboard Beta',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-whiteboard/beta/linux64/lynx-whiteboard-beta.AppImage',
			version: 'https://downloads.lynxcloud.app/lynx-whiteboard/beta/linux64/version.txt',
			description: 'components.manifest.lynxWhiteboardLinux',
			changeLog: '/change-logs/lynx-whiteboard/beta/linux64/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			type: 'beta'
		}
	],
	android: [
		{
			name: 'LYNX Whiteboard',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-whiteboard/releases/android/lynx-whiteboard.apk',
			version: 'https://downloads.lynxcloud.app/lynx-whiteboard/releases/android/version.txt',
			description: 'components.manifest.androidApp',
			changeLog: '/change-logs/lynx-whiteboard/releases/android/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			type: 'prod'
		},
		{
			name: 'LYNX Screen Recorder',
			downloadUrl32: 'https://downloads.lynxcloud.app/lynxsr/production/android/LynxRecorderAPK.apk',
			version: 'https://downloads.lynxcloud.app/lynxsr/production/macx/version.txt',
			description: 'components.manifest.androidApp',
			icon: 'https://downloads.lynxcloud.app/launcher-data/icons/lynx-screen-recorder.svg',
			type: 'prod'
		},
		{
			name: 'LYNX Activities',
			downloadUrl32: 'https://downloads.lynxcloud.app/LynxActivities/production/android/LynxActivities.apk',
			version: 'https://downloads.lynxcloud.app/LynxActivities/production/android/version.txt',
			description: 'components.manifest.androidApp',
			icon: 'https://downloads.lynxcloud.app/LynxActivities/production/android/games-icon.svg',
			type: 'prod'
		},
		{
			name: 'LYNX Whiteboard Beta',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-whiteboard/beta/android/lynx-whiteboard-beta.apk',
			version: 'https://downloads.lynxcloud.app/lynx-whiteboard/beta/android/version.txt',
			description: 'components.manifest.androidApp',
			changeLog: '/change-logs/lynx-whiteboard/beta/android/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			type: 'beta'
		},
		{
			name: 'LYNX Activities Beta',
			downloadUrl32: 'https://downloads.lynxcloud.app/LynxActivities/beta/android/LynxActivities.apk',
			version: 'https://downloads.lynxcloud.app/LynxActivities/beta/android/version.txt',
			description: 'components.manifest.androidApp',
			icon: 'https://downloads.lynxcloud.app/LynxActivities/beta/android/games-icon.svg',
			type: 'beta'
		},
		{
			name: 'LYNX Screen Share Beta',
			downloadUrl64: 'https://downloads.lynxcloud.app/lynx-screen-share/beta/android/lynx-screen-share-beta.apk',
			version: 'https://downloads.lynxcloud.app/lynx-screen-share/beta/android/version.txt',
			description: 'components.manifest.lss',
			changeLog: '/change-logs/lynx-screen-share/beta/android/',
			icon: '/assets/icons/lynx-whiteboard-logo.svg',
			type: 'dev'
		}
	]
}
