import { Dropdown } from '@lynx/client-core/src/components'
import css from './FlagDropdown.module.scss'
import { Flag, flagsSource, useFlags } from '@lynx/client-core/src/hooks'
import { useState } from 'react'

interface FlagDropdownProps {
	onItemClicked: (code: string) => void
}

export const FlagDropdown = ({ onItemClicked }: FlagDropdownProps): React.ReactElement => {
	const [selectedFlag, setSelectedFlag] = useState<Flag>(flagsSource[229])
	const { flags } = useFlags()

	const handleFlagClicked = (flag: Flag): void => {
		setSelectedFlag(flag)
		onItemClicked(flag.code)
	}

	return (
		<div className={css['container']}>
			<img src={selectedFlag.url} height={selectedFlag.height} />
			<Dropdown
				variant="light"
				justify="left"
				title={`+${selectedFlag.code}`}
				className={css['dropdown']}
				openedClassName={css['dropdown--opened']}
				chevron={true}
			>
				{flags.map((flag, index: number) => {
					const { name, url, height } = flag
					return (
						<li key={index} className={css['item']} onClick={(): void => handleFlagClicked(flag)}>
							<img src={url} height={height} />
							<span>{name}</span>
						</li>
					)
				})}
			</Dropdown>
		</div>
	)
}
