// https://github.com/tldraw/tldraw/blob/31f0f02adf58b909f59764f62de09e97542eb2b1/packages/core/src/utils/utils.ts
const average = (a: number, b: number): number => (a + b) / 2

/**
 * Turn an array of points into a path of quadradic curves.
 *
 * @param points The points returned from perfect-freehand
 * @param closed Whether the stroke is closed
 */
export function getSvgPathFromStroke(points: number[][], closed = true): string {
	const len = points.length

	if (len < 4) {
		return ``
	}

	let a = points[0]
	let b = points[1]
	const c = points[2]

	let result = `M${a[0].toFixed(2)},${a[1].toFixed(2)} Q${b[0].toFixed(2)},${b[1].toFixed(2)} ${average(b[0], c[0]).toFixed(2)},${average(b[1], c[1]).toFixed(
		2
	)} T`

	for (let i = 2, max = len - 1; i < max; i++) {
		a = points[i]
		b = points[i + 1]
		result += `${average(a[0], b[0]).toFixed(2)},${average(a[1], b[1]).toFixed(2)} `
	}

	if (closed) {
		result += 'Z'
	}

	return result
}
