/* eslint-disable */
import React, { Component } from 'react'
import DocumentContext from './DocumentContext'
import Message from './Message'
import PageCanvas from './PageCanvas'
import PageContext from './PageContext'
import { PageInternalProps } from './PageInternalProps'
import { cancelRunningTask, isCancelException, isProvided, makeCancellable } from './shared/utils'

const defaultScale = 1.0

interface PageInternalState {
	page: any
}

interface ChildContextProps {
	customTextRenderer: any
	onGetAnnotationsError: any
	onGetAnnotationsSuccess: any
	onGetTextError: any
	onGetTextSuccess: any
	onRenderAnnotationLayerError: any
	onRenderAnnotationLayerSuccess: any
	onRenderError: any
	onRenderSuccess: any
	onRenderStart: any
	renderInteractiveForms: any
}

export class PageInternal extends Component<PageInternalProps, PageInternalState> {
	static defaultProps = {
		error: 'Failed to load the page.',
		loading: 'Loading page…',
		noData: 'No page specified.',
		renderInteractiveForms: false,
		scale: defaultScale
	}

	runningTask: any
	ref: any

	constructor(props: PageInternalProps) {
		super(props)

		this.state = {
			page: null
		}
	}

	componentDidMount(): void {
		const { pdf } = this.props

		if (!pdf) {
			throw new Error('Attempted to load a page, but no document was specified.')
		}

		this.loadPage()
	}

	componentDidUpdate(prevProps: PageInternalProps): void {
		const { pdf } = this.props

		if ((prevProps.pdf && pdf !== prevProps.pdf) || this.props.pageNumber !== prevProps.pageNumber) {
			const { unregisterPage } = this.props

			unregisterPage && unregisterPage(this.getPageIndex(prevProps))

			this.loadPage()
		}
	}

	componentWillUnmount = (): void => {
		const { unregisterPage } = this.props
		unregisterPage && unregisterPage(this.getPageIndex())
		cancelRunningTask(this.runningTask)
	}

	get childContextProps(): ChildContextProps | Record<string, unknown> {
		const { page } = this.state

		if (!page) {
			return {}
		}

		const { customTextRenderer, onGetTextError, onGetTextSuccess, onRenderError, onRenderSuccess, onRenderStart, renderInteractiveForms } = this.props

		return {
			customTextRenderer,
			onGetTextError,
			onGetTextSuccess,
			onRenderError,
			onRenderSuccess,
			onRenderStart,
			page,
			renderInteractiveForms,
			rotate: this.rotate,
			scale: this.scale
		}
	}

	/**
	 * Called when a page is loaded successfully
	 */
	onLoadSuccess = (): void => {
		const { onLoadSuccess } = this.props
		onLoadSuccess && onLoadSuccess()
	}

	/**
	 * Called when a page failed to load
	 */
	onLoadError = (error: any): void => {
		if (isCancelException(error)) {
			return
		}

		console.error(error)

		const { onLoadError } = this.props
		onLoadError && onLoadError(error)
	}

	getPageIndex(props = this.props): number | null {
		return props.pageNumber - 1
	}

	get rotate(): any {
		const { rotate } = this.props
		const { page } = this.state

		if (isProvided(rotate)) {
			return rotate
		}

		if (!page) {
			return null
		}

		return page.rotate
	}

	get scale(): number | null {
		const { page } = this.state

		if (!page) {
			return null
		}

		const { scale, width, height } = this.props
		const { rotate } = this

		// Be default, we'll render page at 100% * scale width.
		let pageScale = 1

		// Passing scale explicitly null would cause the page not to render
		const scaleWithDefault = scale === null ? defaultScale : scale

		// If width/height is defined, calculate the scale of the page so it could be of desired width.
		if (width || height) {
			const viewport = page.getViewport({ scale: 1, rotation: rotate })
			pageScale = width ? width / viewport.width : height / viewport.height
		}

		return scaleWithDefault * pageScale
	}

	get pageKey(): string {
		const { page } = this.state

		return `${page.pageIndex}@${this.scale}/${this.rotate}`
	}

	loadPage = async () => {
		const { pdf } = this.props

		const pageNumber = this.props.pageNumber

		if (!pageNumber) {
			return
		}

		this.setState((prevState) => {
			if (!prevState.page) {
				return null
			}
			return { page: null }
		})

		try {
			const cancellable = makeCancellable(pdf.getPage(pageNumber))
			this.runningTask = cancellable
			const page = await cancellable.promise
			this.setState({ page }, this.onLoadSuccess)
		} catch (error) {
			this.setState({ page: false })
			this.onLoadError(error)
		}
	}

	renderChildren() {
		const { children } = this.props

		return (
			<PageContext.Provider value={this.childContextProps as any}>
				<PageCanvas st key={`${this.pageKey}_canvas`} />
				{children}
			</PageContext.Provider>
		)
	}

	renderContent() {
		const { pdf } = this.props
		const { page } = this.state

		if (pdf === null || page === null) {
			const { loading } = this.props

			return <Message type="loading">{typeof loading === 'function' ? loading() : loading}</Message>
		}

		if (pdf === false || page === false) {
			const { error } = this.props

			return <Message type="error">{typeof error === 'function' ? error() : error}</Message>
		}

		return this.renderChildren()
	}

	render(): React.ReactNode {
		const { pageNumber } = this.props

		return (
			<div
				className={`${this.props.className} ${'react-pdf__Page'}`}
				ref={(ref) => {
					const { inputRef } = this.props
					inputRef && inputRef(ref)
					this.ref = ref
				}}
				style={{ position: 'relative' }}
				data-page-number={pageNumber}
			>
				{this.renderContent()}
			</div>
		)
	}
}

function Page(props: any, ref: any) {
	return <DocumentContext.Consumer>{(context: any) => <PageInternal ref={ref} {...context} {...props} />}</DocumentContext.Consumer>
}

export default React.forwardRef(Page)
