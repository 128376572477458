import { useThunkDispatch } from 'hooks'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout } from 'store/profile'

export const Logout = (): React.ReactElement | null => {
	const dispatchThunk = useThunkDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		const mount = async (): Promise<void> => {
			await dispatchThunk(logout())
			navigate('/')
		}
		mount()
	}, [])

	return null
}
