import { PlayIcon } from '../Icons'
import React from 'react'
import Styles from './TableHeader.module.css'
import { TableHeaderProps } from './interfaces'

export const TableHeader = ({ gridTemplate, headers, setSortField, sortField, setSortDirection, sortDirection }: TableHeaderProps): React.ReactElement => {
	const onHeaderClick = (valueKey: string): void => {
		const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC'
		setSortField(valueKey)
		setSortDirection(direction)
	}

	return (
		<div role="row" className={Styles.headerContainer} style={{ gridTemplateColumns: gridTemplate.gridTemplateColumns }}>
			{headers.map(({ label, valueKey }) => (
				<div className={`${Styles.cellPadding} ${Styles.headerCell}`} key={label} onClick={(): void => onHeaderClick(valueKey)}>
					{label}
					{sortField === valueKey && sortDirection && (
						<PlayIcon className={Styles.sortArrow} style={{ transform: `rotate(${sortDirection === 'ASC' ? 90 : -90}deg)` }} />
					)}
				</div>
			))}
		</div>
	)
}
