export const componentToHex = (c: number): string => {
	const hex = c.toString(16)
	return hex.length == 1 ? '0' + hex : hex
}

export const rgbToHex = (rgb: number[], unit = true): string => {
	const mult = unit ? 255 : 1
	return '#' + componentToHex(rgb[0] * mult) + componentToHex(rgb[1] * mult) + componentToHex(rgb[2] * mult)
}

export const hex2rgb = (hex: string, unit = false): number[] => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	const div = unit ? 255 : 1
	return result ? [parseInt(result[1], 16) / div, parseInt(result[2], 16) / div, parseInt(result[3], 16) / div] : [0, 0, 0]
}

const getRandomColour = (): string => {
	const letters = '0123456789ABCDEF'.split('')
	let colour = '#'
	for (let i = 0; i < 6; i++) {
		colour += letters[Math.floor(Math.random() * 16)]
	}
	return colour
}

export const getRandomColorsPerItem = (count: number): string[] => {
	if (count <= 0) {
		return []
	}

	const randomColours = []

	while (randomColours.length <= count) {
		const randomColour = getRandomColour()

		if (randomColours.indexOf(randomColour) === -1) {
			randomColours.push(randomColour)
		}
	}

	return randomColours
}
