import { promiseAllSettled } from '@lynx/core'
import axios from 'axios'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'
import css from './MoreDownloadsTable.module.scss'
import { Downloads, MoreDownloadsItem, MoreDownloadsState, OtherDownloads } from './MoreDownloadsTable.types'
import { downloads } from './manifest'
import { BasicButton } from '../../BasicButton/BasicButton'
import { LynxActivitiesLogo, LynxScreenRecorderLogo, LynxScreenShareLogo, LynxWhiteboardLogo } from '../../Icons'

export function MoreDownloadsTable(): React.ReactElement {
	const { profile } = useSelector((state: ApplicationState) => state)

	const [state, setState] = React.useState<MoreDownloadsState>({
		otherOutput: {
			windows: [],
			macos: [],
			linux: [],
			android: []
		}
	})

	const [activeTab, setActiveTab] = React.useState<'windows' | 'macos' | 'linux' | 'android'>('windows')

	const getAvailableFiles = async (): Promise<void> => {
		const other: OtherDownloads[] = []
		const allowedList: { [key: string]: string[] } = {
			prod: ['prod'],
			beta: ['prod', 'beta', 'trans'],
			dev: ['prod', 'beta', 'dev']
		}

		for (const dist in downloads) {
			for (const product of downloads[dist]) {
				const { icon, name, downloadUrl32, downloadUrl64, type, version, changeLog } = product
				const { accessType } = profile
				if (!allowedList[accessType].includes(type)) {
					continue
				}

				other.push({
					icon,
					name,
					downloadUrl32,
					downloadUrl64,
					dist,
					version,
					changeLog
				})
			}
		}

		const otherOutput = await formatDists(other)

		setState((currentState) => ({
			...currentState,
			otherOutput
		}))
	}

	const formatDists = async (other: Downloads[]): Promise<{ [dist: string]: MoreDownloadsItem[] }> => {
		const otherOutput: { [dist: string]: MoreDownloadsItem[] } = {
			windows: [],
			macos: [],
			linux: [],
			android: []
		}

		const versions = await promiseAllSettled(other.map((download) => axios.get<string>(download.version)))

		for (const otherDist of other) {
			const { icon, name, downloadUrl32, downloadUrl64, version, changeLog } = otherDist
			const versionOutput = versions.find((ver) => ver.status === 'fulfilled' && ver.value.config.url === version)
			const currentVersion = versionOutput && versionOutput.status === 'fulfilled' ? versionOutput.value.data : '-'

			otherOutput[otherDist.dist].push({
				changeLog: changeLog,
				icon: icon,
				name: name,
				arch32Link: downloadUrl32,
				arch64Link: downloadUrl64,
				version: currentVersion
			})
		}

		return otherOutput
	}

	const renderButton = (download: MoreDownloadsItem): React.ReactElement | null => {
		const { arch64Link, arch32Link } = download
		if (arch64Link && arch32Link) {
			return (
				<div className={css['tabContentItemDownloadGrayButton']}>
					<BasicButton size="lg" variant="light" className={css['ctaButton']} to={arch64Link}>
						64-bit
					</BasicButton>
					<BasicButton size="lg" variant="light" className={css['ctaButton']} to={arch32Link}>
						32-bit
					</BasicButton>
				</div>
			)
		} else if (arch64Link) {
			return (
				<BasicButton size="lg" variant="light" className={css['ctaButton']} to={arch64Link}>
					{i18next.t('pages.moreDownloads.download')}
				</BasicButton>
			)
		} else if (arch32Link) {
			return (
				<BasicButton size="lg" variant="light" className={css['ctaButton']} to={arch32Link}>
					{i18next.t('pages.moreDownloads.download')}
				</BasicButton>
			)
		}

		return null
	}

	const loadData = async (): Promise<void> => {
		await getAvailableFiles()
	}

	const handleTabChange = (tab: 'windows' | 'macos' | 'linux' | 'android'): void => {
		setActiveTab(tab)
	}

	const renderIcon = (icon: string): React.ReactElement | null => {
		if (icon.includes('whiteboard')) {
			return <LynxWhiteboardLogo />
		} else if (icon.includes('games')) {
			return <LynxActivitiesLogo />
		} else if (icon.includes('screen-share')) {
			return <LynxScreenShareLogo />
		} else if (icon.includes('screen-recorder')) {
			return <LynxScreenRecorderLogo />
		} else {
			return null
		}
	}

	useEffect(() => {
		loadData()
	}, [])

	return (
		<div className={css['legacyDownloadsRowAuto']}>
			<div className={css['tabs']}>
				<div className={[css['tab'], activeTab === 'windows' && css['active']].join(' ')} onClick={(): void => handleTabChange('windows')}>
					Windows
				</div>
				<div className={[css['tab'], activeTab === 'macos' && css['active']].join(' ')} onClick={(): void => handleTabChange('macos')}>
					macOS
				</div>
				<div className={[css['tab'], activeTab === 'linux' && css['active']].join(' ')} onClick={(): void => handleTabChange('linux')}>
					Linux
				</div>
				{profile.loggedIn && (
					<div className={[css['tab'], activeTab === 'android' && css['active']].join(' ')} onClick={(): void => handleTabChange('android')}>
						Android
					</div>
				)}
			</div>

			<div className={css['tabContent']}>
				<table>
					<thead>
						<tr>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{state.otherOutput[activeTab].map((download, index: number) => {
							return (
								<tr key={index} className={css['tabContentItem']}>
									<td className={css['tabContentItemIcon']}>{renderIcon(download.icon)}</td>
									<td className={css['tabContentItemDetails']}>
										<div className={css['tabContentItemName']}>{download.name}</div>
										<div className={css['tabContentItemVersion']}>{download.version}</div>
									</td>
									<td className={css['tabContentItemDownload']}>{renderButton(download)}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}
