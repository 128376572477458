// https://github.com/steveruizok/perfect-freehand/tree/main/packages/perfect-freehand/src
/**
 * Compute a radius based on the pressure.
 * @param size
 * @param thinning
 * @param pressure
 * @param easing
 * @internal
 */
export function getStrokeRadius(size: number, thinning: number, pressure: number, easing: (t: number) => number = (t): number => t): number {
	return size * easing(0.5 - thinning * (0.5 - pressure))
}
