import { Document, List } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React from 'react'
import css from './PaymentMethod.module.scss'
import { CreditCardForm } from './CreditCardForm'

export const PaymentMethod = (): React.ReactElement => {
	return (
		<Document
			title={i18next.t('pages.pageTitles.paymentHistory')}
			description="Payment History"
			keywords="Payment History"
			auth={true}
			className={css['container']}
		>
			<div>
				<List>
					<CreditCardForm />
				</List>
			</div>
		</Document>
	)
}
