import React from 'react'
import css from './SectionItem.module.scss'
import i18next from 'i18next'

export const SectionItem = ({
	title,
	image,
	url,
	cover
}: {
	title?: Parameters<typeof i18next.t>[1]
	image: string
	url: string
	cover?: boolean
}): React.ReactElement | null => {
	return (
		<div
			onClick={(): void => {
				window.location.href = url
			}}
			className={css['tile']}
			style={{
				backgroundImage: `url(${image})`,
				backgroundSize: cover ? 'cover' : 'auto 100%'
			}}
		>
			{title && <div className={css['tile-title']}>{i18next.t(title)}</div>}
		</div>
	)
}

export default SectionItem
