import i18next from 'i18next'
import * as React from 'react'
import { drives } from 'data/drives'
import { UnavailableDriveProps } from './interfaces'
import Styles from './UnavailableDrive.module.css'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { AuthCookie } from '@lynx/client-core/src/modules'

export const UnavailableDrive: React.FunctionComponent<UnavailableDriveProps> = (props) => {
	const { provider } = props
	if (!provider || !drives[provider]) {
		return null
	}

	return (
		<div
			style={{
				margin: '0 auto',
				paddingTop: '50px',
				textAlign: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			{provider === 'organisation' ? <OrganisationUnavailableDrive {...props} /> : <LinkDrive {...props} />}
		</div>
	)
}

const OrganisationUnavailableDrive = ({ provider }: UnavailableDriveProps): React.ReactElement => {
	const { title, icon } = drives[provider]
	return (
		<React.Fragment>
			<div className={Styles['logo']}>
				<img className={Styles.logo} src={icon} alt={title} />
			</div>
			<div>{i18next.t('pages.unavailableDrive.organisationDriveNotFound')}</div>
		</React.Fragment>
	)
}

const LinkDrive = ({ provider }: UnavailableDriveProps): React.ReactElement => {
	const { title, logo } = drives[provider]
	const {
		config: { API_SERVER, ENVIRONMENT }
	} = useSelector((state: ApplicationState) => state)
	const accessToken = AuthCookie.getAccessToken(ENVIRONMENT)
	return (
		<React.Fragment>
			<div className={Styles['alert']}>
				{i18next.t('pages.unavailableDrive.p1')} {title} {i18next.t('pages.unavailableDrive.p2')}
				{title} {i18next.t('pages.unavailableDrive.p3')}
				<a href={'/privacy#' + title} style={{ textDecoration: 'underline' }}>
					{i18next.t('pages.unavailableDrive.privacyPolicy')}
				</a>{' '}
				{i18next.t('pages.unavailableDrive.p4')}
			</div>
			<img className={Styles.logo} alt={title + ' Logo'} src={logo} />
			<br />
			<br />
			<h3>
				Link Your {title} {i18next.t('pages.unavailableDrive.toContinue')}
			</h3>
			<br />
			<br />
			<a target="_blank" href={`${API_SERVER}/auth/${provider}/link/${accessToken}`}>
				{i18next.t('pages.unavailableDrive.link')} {title}
			</a>
		</React.Fragment>
	)
}
