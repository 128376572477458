import { useThunkDispatch } from 'hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { loadDriveNextPage, maxElementsCount } from 'store/drive'

export const useNextPageTrigger = (): { lastBookElementRef: (el: HTMLDivElement) => void } => {
	const [pageNum, setPageNum] = useState(1)
	const lastElRef = React.useRef<IntersectionObserver | null>(null)
	const { drive } = useSelector((state: ApplicationState) => state)
	const dispatchThunk = useThunkDispatch()
	const { maxElements } = maxElementsCount(drive.style)

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const isMinItemsToTrigger = drive.data.children.length >= maxElements
			if (pageNum > 1 && isMinItemsToTrigger) await dispatchThunk(loadDriveNextPage())
		}
		mount()
	}, [pageNum])

	const hasMore = drive.data.nextPageToken

	const lastBookElementRef = useCallback(
		(el: HTMLDivElement) => {
			if (drive.isNextPageLoading) return
			if (lastElRef.current) lastElRef.current.disconnect()

			lastElRef.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) setPageNum((prev) => prev + 1)
			})

			if (el) lastElRef?.current?.observe(el)
		},
		[drive.isNextPageLoading, hasMore]
	)

	return { lastBookElementRef }
}
