import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteOrganisationDrive, organisationAvatarUpdated } from 'store/organisations'
import css from './OrganisationDeleteDriveModal.module.scss'

export const OrganisationDeleteDriveModal = (): React.ReactElement | null => {
	const organisation = useCurrentOrganisation()
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()

	if (!organisation) return null

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}

	const handleDeleteClicked = async (): Promise<void> => {
		if (!organisation) return
		await dispatchThunk(deleteOrganisationDrive(organisation.id))
		dispatch(organisationAvatarUpdated(organisation.id))
		dispatch(hideModal())
	}

	return (
		<Modal name="OrganisationDeleteDriveModal" title="Delete Organisation Drive" variant="danger">
			<div className={css['container']}>
				<div>{t('pages.organisations.thisWillBePermanent')}</div>

				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
					<BasicButton variant="danger" onClick={handleDeleteClicked}>
						{t('pages.organisations.yesDeleteFiles')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
