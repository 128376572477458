import { APIRequest } from '@lynx/client-core/src/api'
import { useEffect, useState } from 'react'

export const useBillingConfiguration = (): { isLoading: boolean; isBillingTrialPeriodForced: boolean } => {
	const [billingConfiguration, setBillingConfiguration] = useState<{ isBillingTrialPeriodForced: boolean }>({ isBillingTrialPeriodForced: false })
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const getBillingConfiguration = async (): Promise<void> => {
			const billingConfiguration = await APIRequest.Billing.getBillingConfiguration()

			setBillingConfiguration(billingConfiguration)
			setIsLoading(false)
		}
		getBillingConfiguration()
	}, [])

	return { isLoading, isBillingTrialPeriodForced: billingConfiguration.isBillingTrialPeriodForced }
}
