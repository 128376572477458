import { DocumentState } from './types'

export const initialDocumentState: DocumentState = {
	extension: '',
	blobLink: '',
	page: 1,
	numPages: 0,
	fileId: '',
	token: '',
	channelId: '',
	play: false,
	download: '',
	ready: false,
	autoDownload: false
}
