import { NotData2 } from '@lynx/client-core/src/components'
import { INotification } from '@lynx/core/src/interfaces/Notifications'
import { useThunkDispatch } from 'hooks'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { setNotificationViewed } from 'store/notifications'
import css from './NotificationsList.module.css'
import { NotificationVariants } from './NotificationVariants'

export const NotificationsList = ({ maxItemsCount = 999 }: { maxItemsCount?: number }): React.ReactElement => {
	const { notificationsData: notifications } = useSelector((state: ApplicationState) => state.notifications)
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()

	return (
		<React.Fragment>
			{notifications.length > 0 ? (
				notifications.slice(0, maxItemsCount).map((notification) => {
					const parsedNotification: INotification<'parsed'> = {
						...notification,
						payload: JSON.parse(notification.payload)
					}
					const { createdAt, isViewed, ...rest } = parsedNotification

					const onNotificationClick = async (): Promise<void> => {
						if (!isViewed) {
							await dispatchThunk(setNotificationViewed({ ...notification, isViewed: true }))
						}
					}
					return (
						<div key={notification.id}>
							<NotificationVariants
								onNotificationClick={onNotificationClick}
								notification={{ ...rest, notificationId: rest.id }}
								isViewed={isViewed}
								createdAt={createdAt}
							/>
						</div>
					)
				})
			) : (
				<div className={css.noDataContainer}>
					<NotData2 className={css.noDataIcon} />
					<div className={css.noDataText}>{t('components.notifications.noNotifications')}</div>
				</div>
			)}
		</React.Fragment>
	)
}
