import * as React from 'react'
import css from './Card.module.css'
import { CardProps } from './interfaces'

export const Card = ({ children, padding = '1.8rem', innerPadding = '1vmax', className = '' }: CardProps): React.ReactElement => (
	<div style={{ padding }}>
		<div className={`${css['card']} ${className}`} style={{ padding: innerPadding, borderRadius: '5px' }}>
			{children}
		</div>
	</div>
)
