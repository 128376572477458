import { ArrowBackIcon, LockOpenIcon, MailOutlineIcon } from '../../../../Icons'
import { Section } from '../../../../Sections'
import { BasicButton, Input } from '../../../../'
import i18next from 'i18next'
import { APIRequest } from '../../../../../api'
import { StrengthIndicator, strengthTranslations } from '../../components/StrengthIndicator'
import * as React from 'react'
import { useState } from 'react'
import { SignUpSectionProps, SignUpSectionState } from './interfaces'
import css from './SignUpSection.module.css'
import { string } from '@lynx/core'
import { objKeys } from '../../../../../utils'

export const SignUpSection = ({ navigate: navigateSection }: SignUpSectionProps): React.ReactElement => {
	const [state, setState] = useState<SignUpSectionState>({
		strengthTypes: {
			has8Characters: false,
			hasSmallLetter: false,
			hasCapitalLetter: false,
			hasNonLetter: false,
			hasNumber: false
		},
		email: '',
		password: '',
		confirmPassword: ''
	})

	const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const email = event.target.value
		setState({ ...state, email })
	}

	const resetStrength = (): void => {
		setState({
			...state,
			strengthTypes: {
				has8Characters: false,
				hasSmallLetter: false,
				hasCapitalLetter: false,
				hasNonLetter: false,
				hasNumber: false
			}
		})
	}

	const handlePasswordChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const password = event.target.value
		if (!password) {
			resetStrength()
			return
		}

		const lowercaseLetters = /[a-z]/
		const uppercaseLetters = /[A-Z]/
		const hasSymbols = /[^A-Za-z\d]+/
		const hasNumber = /\d/

		setState({
			...state,
			strengthTypes: {
				has8Characters: password.length >= 8,
				hasSmallLetter: lowercaseLetters.test(password),
				hasCapitalLetter: uppercaseLetters.test(password),
				hasNonLetter: hasSymbols.test(password),
				hasNumber: hasNumber.test(password)
			},
			password
		})
	}

	const handleConfirmPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const confirmPassword = event.target.value

		setState({ ...state, confirmPassword })
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault()
		event.stopPropagation()

		if (!string.isEmail(state.email)) {
			setState({ ...state, error: i18next.t('pages.login.invalidEmail') })
			return
		}

		if (!isPasswordValid()) {
			setState({ ...state, error: i18next.t('pages.login.invalidPassword') })
			return
		}

		const { email, password } = state

		const registerResponse = await APIRequest.User.register(email, password)
		if (registerResponse.status !== 200) {
			setState({ ...state, error: registerResponse.statusText })
			return
		}

		navigateSection('VerificationSection')
	}

	const handleBack = (): void => {
		navigateSection('SignInSection')
	}

	const isButtonAvailable = (): boolean => {
		return string.isEmail(state.email) && isPasswordValid()
	}

	const isPasswordValid = (): boolean => {
		const { password, confirmPassword } = state
		return password === confirmPassword && Object.values(state.strengthTypes).every((v) => v)
	}

	const strengthComponents = objKeys(state.strengthTypes).map((key) => {
		const applied = state.strengthTypes[key]
		const translation = strengthTranslations[key as keyof typeof strengthTranslations]
		return <StrengthIndicator key={key} text={i18next.t(translation)} applied={applied} />
	})

	const createButtonReady = isButtonAvailable()

	return (
		<Section
			side="right"
			title={
				<div className={css['title-container']}>
					<div className={css['back-arrow']} onClick={handleBack}>
						<ArrowBackIcon />
					</div>
					<div>{i18next.t('pages.login.createAccount')}</div>
				</div>
			}
			className={css['signup-section']}
		>
			<form style={{ width: '100%' }} onSubmit={handleSubmit} autoComplete="off">
				<Input
					type="text"
					variant="light"
					name="signupemail"
					className={css.input}
					placeholder={i18next.t('pages.login.email')}
					onChange={handleEmailChanged}
					icon={MailOutlineIcon}
				/>
				<Input
					type="password"
					name="signuppassword"
					placeholder={i18next.t('pages.login.password')}
					onChange={handlePasswordChanged}
					icon={LockOpenIcon}
					variant="light"
				/>

				<div className={css['strength-indicator']}>{strengthComponents}</div>

				<Input
					type="password"
					className={css.confirmPasswordInput}
					name="confirmpassword"
					placeholder={i18next.t('pages.register.confirmPassword')}
					onChange={handleConfirmPasswordChanged}
					icon={LockOpenIcon}
					variant="light"
				/>

				<div className={css['terms']}>
					{i18next.t('pages.login.agree')}{' '}
					<a href="/terms" target="_blank">
						{i18next.t('pages.login.terms')}
					</a>
				</div>

				{state.error && <div className={css['error-message']}>{state.error}</div>}
				<div className={css.buttonContainer}>
					<BasicButton type="submit" disabled={!createButtonReady} variant="blue" elevation={true}>
						{i18next.t('pages.login.createAccount')}
					</BasicButton>
				</div>
			</form>
		</Section>
	)
}
