import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import css from './OrganisationEntry.module.scss'

interface OrganisationEntryProps {
	organisation: OrganisationRedux
}

export const OrganisationEntry = ({
	organisation: { organisationName, address, countryName, domains, isPro, groups, invites, users, provider }
}: OrganisationEntryProps): React.ReactElement => {
	return (
		<div className={css['container']}>
			<div>
				<div aria-label="Organisation Name" id="organisation-entry-name">
					Organisation Name
				</div>
				<div aria-labelledby="organisation-entry-name">{organisationName}</div>
			</div>
			<div>
				<div aria-label="Admins" id="organisation-entry-admins">
					Admins
				</div>
				<div aria-labelledby="organisation-entry-admins">
					{users
						.filter((u) => u.isOwner)
						.map((u) => u.email)
						.join(', ')}
				</div>
			</div>
			<div>
				<div aria-label="Organisation Type" id="organisation-entry-type">
					Type
				</div>
				<div aria-labelledby="organisation-entry-type">{provider}</div>
			</div>
			<div>
				<div aria-label="Is Pro" id="organisation-entry-pro">
					Pro
				</div>
				<div aria-labelledby="organisation-entry-pro">{String(isPro)}</div>
			</div>
			<div>
				<div aria-label="Address" id="organisation-entry-address">
					Address
				</div>
				<div aria-labelledby="organisation-entry-address">{address}</div>
			</div>
			<div>
				<div aria-label="Country" id="organisation-entry-country">
					Country
				</div>
				<div aria-labelledby="organisation-entry-country">{countryName}</div>
			</div>
			<div>
				<div aria-label="Domains" id="organisation-entry-domains">
					Domains ({domains.length})
				</div>
				<div aria-labelledby="organisation-entry-domains">{domains.map((d) => d.emailDomain).join(', ')}</div>
			</div>
			<div>
				<div aria-label="Groups" id="organisation-entry-groups">
					Groups ({groups.length})
				</div>
				<div aria-labelledby="organisation-entry-groups">{groups.map((g) => g.groupName).join(', ')}</div>
			</div>
			<div>
				<div aria-label="Invites" id="organisation-entry-invites">
					Invites ({invites.length})
				</div>
				<div aria-labelledby="organisation-entry-invites">{invites.map((i) => i.email).join(', ')}</div>
			</div>
			<div>
				<div aria-label="Users" id="organisation-entry-users">
					Users ({users.length})
				</div>
				<div aria-labelledby="organisation-entry-users">{users.map((u) => u.email).join(', ')}</div>
			</div>
		</div>
	)
}
