import {
	BasicButton,
	BulletPointItem,
	BulletPoints,
	CreateIcon,
	Document,
	DownloadButton,
	FeatureItem,
	FeatureSection,
	FooterItem,
	FooterLinksItem,
	GroupedButtons,
	HeroItem,
	LandingIllustration0Src,
	LandingIllustration1Src,
	LandingIllustration2Src,
	LandingIllustration3Src
} from '@lynx/client-core/src/components'
import { useDownloadLinks } from '@lynx/client-core/src/hooks'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export const Landing = (): React.ReactElement => {
	const { to, text, icon } = useDownloadLinks('whiteboard')
	const { t } = useTranslation()
	const containerRef = useRef<HTMLDivElement>(null)
	const scrollTop = (): void => {
		containerRef?.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
	}

	return (
		<Document title={t('pages.landing.title')} ref={containerRef}>
			<FeatureSection className={'hero-background'} backgroundColor="#272727" inverted="always" smoothImage={true}>
				<HeroItem
					title={t('pages.landing.engagingPresentations')}
					body={
						<BulletPoints>
							{[
								{ icon: <CreateIcon fill="#AF66FF" />, text: t('pages.landing.shareIdeas') },
								{ icon: <CreateIcon fill="#00C5FF" />, text: t('pages.landing.engageAudience') },
								{ icon: <CreateIcon fill="#28C283" />, text: t('pages.landing.collaborate') }
							].map(({ icon, text }, key) => (
								<BulletPointItem key={key} icon={icon} text={text} />
							))}
						</BulletPoints>
					}
					footer={t('pages.landing.trusted')}
					actions={
						<GroupedButtons>
							<DownloadButton icon={icon} label={t('pages.landing.downloadOn')} text={text} to={to} />
							<BasicButton size="xl" variant="transparent" to="/more-downloads">
								{t('pages.landing.moreDownloads')}
							</BasicButton>
						</GroupedButtons>
					}
				/>
				<img src={LandingIllustration0Src} alt="LandingIllustration0" loading="lazy" />
			</FeatureSection>

			<FeatureSection backgroundColor="#FCFCFC" inverted="always">
				<FeatureItem
					label={t('pages.landing.generateIdeas')}
					accentColor="#AF66FF"
					title={t('pages.landing.shareIdeas')}
					body={t('pages.landing.createPresentations')}
					action={t('pages.landing.learnMore')}
				/>
				<img src={LandingIllustration1Src} alt="LandingIllustration1" />
			</FeatureSection>

			<FeatureSection backgroundColor="#F6F6F6">
				<img src={LandingIllustration2Src} alt="LandingIllustration2" loading="lazy" />
				<FeatureItem
					label={t('pages.landing.keepEngaged')}
					accentColor="#00C5FF"
					title={t('pages.landing.engageAudience')}
					body={t('pages.landing.hundredActivities')}
					action={t('pages.landing.learnMore')}
				/>
			</FeatureSection>

			<FeatureSection backgroundColor="#FCFCFC" inverted="always">
				<FeatureItem
					label={t('pages.landing.workTogether')}
					accentColor="#28C283"
					title={t('pages.landing.collaborate')}
					body={t('pages.landing.supportsMultitouch')}
					action={t('pages.landing.learnMore')}
				/>
				<img src={LandingIllustration3Src} alt="LandingIllustration3" loading="lazy" />
			</FeatureSection>

			<FeatureSection backgroundColor="#F6F6F6" height="auto" inverted="always">
				<div>
					<FooterItem />
				</div>
				<div>
					<FooterLinksItem scrollTop={scrollTop} />
				</div>
			</FeatureSection>
		</Document>
	)
}
