import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, ListItem, ProBadgeIcon } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { ApplicationState } from '@lynx/client-core/src/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import css from './YourPlanPro.module.scss'
import { useBillingConfiguration, useBillingDetails } from 'hooks'
import { useNavigate } from 'react-router-dom'

export const YourPlanPro = (): React.ReactElement | null => {
	const navigate = useNavigate()
	const { profile } = useSelector((state: ApplicationState) => state)
	const { t } = useTranslation()
	const { billingDetails } = useBillingDetails()
	const { isBillingTrialPeriodForced } = useBillingConfiguration()

	const { isPro } = profile

	const handleManagePlanClicked = async (): Promise<void> => {
		try {
			const response = await APIRequest.Billing.getManageSubscriptionPortalURL()
			window.location.href = response.url
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleCancelProClicked = async (): Promise<void> => {
		try {
			navigate('/cancel-membership')
		} catch (e) {
			Logger.error(e)
		}
	}

	const getPlanPeriodMessage = (): React.ReactElement | null => {
		if (!billingDetails) {
			return null
		}

		const formattedPeriodEnd = new Date(billingDetails.current_period_end * 1000).toLocaleDateString()

		if (billingDetails.cancel_at_period_end === false) {
			return (
				<div>
					{t('pages.profile.yourPlanWillAutorenew')} <b>{formattedPeriodEnd}</b>
				</div>
			)
		} else {
			return (
				<div>
					{t('pages.profile.yourPlanWillEnd')} <b>{formattedPeriodEnd}</b>
				</div>
			)
		}
	}

	const planPeriodMessage = getPlanPeriodMessage()

	return (
		<ListItem header={t('pages.profile.yourPlan')} hidden={!isPro || isBillingTrialPeriodForced || billingDetails?.isProManuallyAssigned}>
			<div className={css['container']}>
				<div className={css['container__description']}>
					<h3>
						LYNX <ProBadgeIcon />
					</h3>

					<div>{planPeriodMessage}</div>
				</div>
				<div className={css['container__actions']}>
					<BasicButton className={css['button']} variant="blue" onClick={handleManagePlanClicked}>
						{t('pages.profile.managePlan')}
					</BasicButton>
					<BasicButton className={css['button']} variant="danger" onClick={handleCancelProClicked}>
						{t('pages.profile.cancelPro')}
					</BasicButton>
				</div>
			</div>
		</ListItem>
	)
}
