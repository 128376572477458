import * as React from 'react'
import css from './ThirdPartyLinks.module.css'
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	LivejournalIcon,
	LivejournalShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
	WorkplaceIcon,
	WorkplaceShareButton
} from 'react-share'

interface ThirdPartyLinksProps {
	shareUrl: string
	title: string
}

export const ThirdPartyLinks = ({ shareUrl, title }: ThirdPartyLinksProps): React.ReactElement => (
	<div className={css['third-party']}>
		<FacebookShareButton url={shareUrl} quote={title}>
			<FacebookIcon size={32} round />
		</FacebookShareButton>
		<TwitterShareButton url={shareUrl} title={title}>
			<TwitterIcon size={32} round />
		</TwitterShareButton>
		<TelegramShareButton url={shareUrl} title={title}>
			<TelegramIcon size={32} round />
		</TelegramShareButton>
		<WhatsappShareButton url={shareUrl} title={title} separator=":: ">
			<WhatsappIcon size={32} round />
		</WhatsappShareButton>
		<LinkedinShareButton url={shareUrl}>
			<LinkedinIcon size={32} round />
		</LinkedinShareButton>
		<LivejournalShareButton url={shareUrl} title={title} description={shareUrl}>
			<LivejournalIcon size={32} round />
		</LivejournalShareButton>
		<WorkplaceShareButton url={shareUrl} quote={title}>
			<WorkplaceIcon size={32} round />
		</WorkplaceShareButton>
	</div>
)
