import { ShareType, SharingInvite } from '@lynx/client-core/src/api/interfaces'
import { EditIcon, MarkEmailReadIcon, MarkEmailUnReadIcon, VisibilityIcon } from '@lynx/client-core/src/components'
import { Overlay } from '@lynx/client-core/src/components/Popup/Overlay'
import { PermissionsText } from 'components/modals/SharingModal/helpers'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import i18next from 'i18next'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { removeShareUser, updateSharePermission } from 'store/sharing'
import { Resend } from './components'
import css from './People.module.css'

const icons: { [key: string]: React.ReactElement } = {
	viewer: <VisibilityIcon className={css['viewer']} />,
	admin: <EditIcon className={css['admin']} />,
	editor: <EditIcon className={css['admin']} />,
	inviteUnread: <MarkEmailUnReadIcon className={css['unread']} />,
	inviteRead: <MarkEmailReadIcon className={css['read']} />
}

export const People = (): React.ReactElement => {
	const dispatchThunk = useThunkDispatch()
	const { sharing: sharingState } = useSelector((state: ApplicationState) => state)
	const container = useRef<HTMLUListElement>(null)

	const onViewerClicked = async (userId: string): Promise<void> => {
		await dispatchThunk(updateSharePermission(userId, 'viewer'))
	}

	const onAdminClicked = async (userId: string): Promise<void> => {
		await dispatchThunk(updateSharePermission(userId, 'admin'))
	}

	const onEditorClicked = async (userId: string): Promise<void> => {
		await dispatchThunk(updateSharePermission(userId, 'editor'))
	}

	const onRemoveClicked = async (userId: string): Promise<void> => {
		await dispatchThunk(removeShareUser(userId))
	}

	const getIcon = (person: { id: string; shareType: ShareType; email: string; invite: SharingInvite }): React.ReactElement => {
		switch (person.invite.state) {
			case 'read':
				return icons['inviteRead']
			case 'sent':
				return icons['inviteUnread']
			case 'accepted':
				return icons[person.shareType]
		}
	}

	const dropdownDefinitions = [
		{
			key: 'viewer',
			action: onViewerClicked,
			text: i18next.t('components.modals.sharingModal.viewer')
		},
		{
			key: 'admin',
			action: onAdminClicked,
			text: i18next.t('components.modals.sharingModal.admin')
		},
		{
			key: 'editor',
			action: onEditorClicked,
			text: i18next.t('components.modals.sharingModal.editor')
		},
		{
			key: 'remove',
			action: onRemoveClicked,
			text: i18next.t('components.modals.sharingModal.remove')
		}
	]

	let peopleElements: React.ReactElement[] = []
	if (sharingState.share?.sharedWith) {
		peopleElements = sharingState.share.sharedWith.map((person, key) => {
			const { email, shareType, id: userId } = person

			const dropdownOptions = dropdownDefinitions.map(({ text, action, key }) => {
				const dropdownOptionsClassnames = []
				shareType === text.toLowerCase() && dropdownOptionsClassnames.push(css['dropdown-current-item'])
				return (
					<li key={key} className={dropdownOptionsClassnames.join(' ')} onClick={(): Promise<void> => action(userId)}>
						{text}
					</li>
				)
			})

			const icon = getIcon(person)

			return (
				<li key={key}>
					{icon}
					<div>
						<div>{email}</div>
						<Overlay title={PermissionsText[shareType]} className="" popUpClassName={css['dropdown']} scrollableContainer={container}>
							<ul>{dropdownOptions}</ul>
						</Overlay>
					</div>
				</li>
			)
		})
	}

	return (
		<div className={css['container']}>
			<div>
				<label>{i18next.t('components.modals.sharingModal.sharedWith')}</label>
				<Resend />
			</div>
			<ul ref={container}>{peopleElements}</ul>
		</div>
	)
}
