import React from 'react'
import css from './MoreDownloadsItem.module.scss'
import { MoreDownloadsIconType, MoreDownloadsItemProps } from './MoreDownloadsItem.types'
import { CreateIcon, LynxActivitiesLogo, LynxScreenShareLogo, LynxWhiteboardLogo, LynxScreenRecorderLogo } from '../../Icons'
import { BasicButton } from '../../BasicButton/BasicButton'
import { BulletPointItem, BulletPoints } from '../../BulletPoints'
import i18next from 'i18next'
import { useDownloadLinks } from '../../../hooks'

const renderIcon = (icon: MoreDownloadsIconType): React.ReactElement | null => {
	switch (icon) {
		case 'whiteboard':
			return <LynxWhiteboardLogo />
		case 'activities':
			return <LynxActivitiesLogo />
		case 'share':
			return <LynxScreenShareLogo />
		case 'recorder':
			return <LynxScreenRecorderLogo />
		default:
			return null
	}
}

export const MoreDownloadsItem = ({
	icon,
	headerTitle,
	headerImage,
	bulletPoints = [],
	bulletPointsTintColor = '#000000',
	ctaButtonClick,
	application,
	downloadLink
}: MoreDownloadsItemProps): React.ReactElement => {
	const { to } = useDownloadLinks(application)

	return (
		<div
			className={css['card']}
			style={{
				borderTopColor: bulletPointsTintColor
			}}
		>
			<div className={css['headerIconRow']}>
				<div className={css['headerIcon']}>{renderIcon(icon)}</div>
			</div>

			<div className={css['cardContent']}>
				<div>
					<h2>{headerTitle}</h2>
				</div>

				{headerImage && (
					<div className={css.imageContainer}>
						<img src={headerImage} className={css.image} alt={headerTitle} />
					</div>
				)}

				{bulletPoints.length > 0 && (
					<div className={css.bulletPointsContainer}>
						<BulletPoints size="md">
							{bulletPoints.map((text, index) => (
								<BulletPointItem key={index} icon={<CreateIcon fill={bulletPointsTintColor} />} text={text} />
							))}
						</BulletPoints>
					</div>
				)}
			</div>
			<div className={css['buttonRow']}>
				<BasicButton variant="blue" size="lg" className={css['ctaButton']} to={to || downloadLink}>
					{i18next.t('pages.moreDownloads.tryItNow')}
				</BasicButton>
				<BasicButton size="lg" variant="light" className={css['ctaButton']} onClick={ctaButtonClick}>
					{i18next.t('pages.moreDownloads.learnMore')}
				</BasicButton>
			</div>
		</div>
	)
}
