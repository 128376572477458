import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Input, Modal } from '@lynx/client-core/src/components'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { Logger } from '@lynx/client-core/src/modules'
import i18next from 'i18next'
import QueryString from 'qs'
import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import { DriveState, refreshDrivePath } from 'store/drive'
import { hideModal } from 'store/modal'
import { ProfileState } from 'store/profile'
import { CreateNewFileProps } from './interfaces'

export interface CreateNewFileState {
	name: string
}

class CreateNewFileModal extends React.Component<CreateNewFileProps, CreateNewFileState> {
	state: CreateNewFileState = {
		name: ''
	}

	private handleSubmit = async (): Promise<void> => {
		const { provider, currentPath } = this.props.drive
		const { name } = this.state

		try {
			const query = QueryString.parse(location.search.substring(1)) as { driveId?: string }
			await APIRequest.ObjectStore.createLynxFile(provider, name, currentPath.id, query.driveId)

			await this.props.refreshDrivePath()
		} catch (e) {
			Logger.error(e)
		}

		this.props.hideModal()
	}

	private handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		this.setState({ name: event.target.value })
	}

	render(): React.ReactNode {
		return (
			<Modal name="CreateNewFileModal" hideCloseButton>
				<ModalDefaultLayout
					title={i18next.t('components.modals.createNewFileModal.createNewFile')}
					body={
						<React.Fragment>
							<Input
								variant="dark"
								label={i18next.t('components.modals.createNewFileModal.name')}
								onChange={this.handleChange}
								value={this.state.name || ''}
								autoFocus
							/>
						</React.Fragment>
					}
					footer={
						<BasicButton onClick={this.handleSubmit} variant="blue">
							{i18next.t('components.modals.createNewFileModal.create')}
						</BasicButton>
					}
				/>
			</Modal>
		)
	}
}

const mapStateToProps = (state: ApplicationState): { profile: ProfileState; drive: DriveState } => ({
	profile: state.profile,
	drive: state.drive
})

export default connect(mapStateToProps, { hideModal, refreshDrivePath })(CreateNewFileModal)
