import i18next from 'i18next'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setLoginModal } from 'store/profile'
import Styles from './ResetPasswordSuccessSection.module.css'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

export const ResetPasswordSuccessSection = (): React.ReactElement => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(setLoginModal(false))
	}, [])

	const handleBack = (): void => {
		navigate('/auth/login', { replace: true })
		dispatch(setLoginModal(true))
	}

	return (
		<section className={Styles['reset-section']}>
			<div>
				<div>
					<div className={Styles['title']}>{i18next.t('pages.login.passwordReset')}</div>
				</div>
				<span style={{ fontSize: '16px' }}>{i18next.t('pages.login.passwordResetMessage')}</span>{' '}
				<Link to="/auth/login">
					<strong>
						<span style={{ fontSize: '16px' }}>{i18next.t('pages.login.login')}</span>
					</strong>
				</Link>
			</div>
			<div onClick={handleBack} className={Styles['back']}>
				{i18next.t('pages.login.backToSignIn')}
			</div>
		</section>
	)
}
