import { BaseCall } from '../BaseCall'
import { APIConfig, APIResponse } from '../interfaces'
import { MediaResponse } from './interfaces'

export class Search extends BaseCall {
	public images = async (keyword: string, config: { provider?: string; count?: number; pageToken?: number }): Promise<APIResponse<MediaResponse>> => {
		const { provider, count, pageToken } = config

		let uri = `/search/images?keyword=${keyword}`
		provider && (uri += `&provider=${provider}`)
		count && (uri += `&count=${count}`)
		pageToken && (uri += `&pageToken=${pageToken}`)

		try {
			const response = await this.client.get<APIResponse<MediaResponse>>(uri)
			if (!response.data?.error) {
				return response.data
			}

			console.error(response.data)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		} catch (e) {
			console.error(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return null
		}
	}

	public getRelatedKeywords = async (keywords: string): Promise<any> => {
		try {
			const response = await this.client.get(`/api-v1/search/related?keyWords=${keywords}`)
			return response.data
		} catch (err) {
			console.error(err)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return err.response.data
		}
	}

	public searchUnsplash = async (keywords: string): Promise<any> => {
		try {
			const response = await this.client.get(`/api-v1/search/unsplash?keyWords=${keywords}`)
			return response.data
		} catch (err) {
			console.error(err)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return err.response.datax
		}
	}

	public getUnsplashImage = async (id: string, size: 'full' | 'hd' | 'small' | 'thumb' = 'full'): Promise<any> => {
		try {
			const response = await this.client.get(`/api-v1/search/unsplash/${id}?size=${size}`, { responseType: 'blob' })
			return response.data
		} catch (err) {
			console.error(err)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return err.response.data
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
