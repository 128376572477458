export const NAV_PAIRED = 'nav:paired'
export const NAV_MEDIA = 'nav:media'
export const NAV_BACK = 'nav:back'
export const NAV_FORWARD = 'nav:forward'
export const NAV_PLAY = 'nav:play'
export const NAV_STOP = 'nav:stop'
export const NAV_EXIT = 'nav:exit'

interface NavPairedAction {
	type: typeof NAV_PAIRED
	channelId: string
	sender: string
}

interface NavMediaAction {
	type: typeof NAV_MEDIA
	channelId: string
	payload: {
		stream: string
		media: string
	}
}

interface NavBackAction {
	type: typeof NAV_BACK
	channelId: string
}

interface NavForwardAction {
	type: typeof NAV_FORWARD
	channelId: string
}

interface NavPlayAction {
	type: typeof NAV_PLAY
	channelId: string
}

interface NavStopAction {
	type: typeof NAV_STOP
	channelId: string
}

interface NavExitAction {
	type: typeof NAV_EXIT
	channelId: string
}

export type NavActionTypes = NavMediaAction | NavPairedAction | NavBackAction | NavForwardAction | NavPlayAction | NavStopAction | NavExitAction
