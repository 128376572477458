import { Dropdown } from '@lynx/client-core/src/components'
import { useFlags } from '@lynx/client-core/src/hooks'
import { useState } from 'react'
import css from './CountryDropdown.module.scss'

interface CountryDropdownProps {
	onItemClicked: (code: string) => void
}

export const CountryDropdown = ({ onItemClicked }: CountryDropdownProps): React.ReactElement => {
	const [selectedCountry, setSelectedCountry] = useState<string>('United Kingdom')
	const { flags } = useFlags()

	const handleCountryClicked = (country: string): void => {
		setSelectedCountry(country)
		onItemClicked(country)
	}

	return (
		<div className={css['container']}>
			<Dropdown
				variant="light"
				justify="right"
				title={`${selectedCountry}`}
				className={css['dropdown']}
				openedClassName={css['dropdown--opened']}
				chevron={true}
			>
				{flags.map((flag, index: number) => {
					const { name } = flag
					return (
						<li key={index} className={css['item']} onClick={(): void => handleCountryClicked(name)}>
							<span>{name}</span>
						</li>
					)
				})}
			</Dropdown>
		</div>
	)
}
