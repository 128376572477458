import { AxiosResponse } from 'axios'
import { BaseCall } from '../BaseCall'
import { APIConfig, Channel, Orphan } from '../interfaces'

export class ChannelsAdmin extends BaseCall {
	public channel = async (channelId: string): Promise<Channel | undefined> => {
		try {
			const response: AxiosResponse<Channel> = await this.client.get(`/admin/channel?channel=${channelId}`)

			if (response.status === 200) {
				return response.data
			}
		} catch (e) {
			console.error(e)
		}
	}

	public channels = async (): Promise<string[] | undefined> => {
		try {
			const response: AxiosResponse<string[]> = await this.client.get(`/admin/channels`)

			if (response.status === 200) {
				return response.data
			}
		} catch (e) {
			console.error(e)
		}
	}

	public orphans = async (): Promise<{ channels: Orphan[]; subscriptions: Orphan[]; subscribers: Orphan[], owners: Orphan[] } | undefined> => {
		try {
			const response: AxiosResponse<{ channels: Orphan[]; subscriptions: Orphan[]; subscribers: Orphan[], owners: Orphan[] }> = await this.client.get(`/admin/orphans`)

			if (response.status === 200) {
				return response.data
			}
		} catch (e) {
			console.error(e)
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.MESSAGING_API_SERVER)
	}
}