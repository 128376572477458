import { Reducer } from 'redux'
import { initialSharingState } from './initialState'
import { SharingActionTypes, SharingState, LOAD_SHARE, UNLOAD_SHARES, LOAD_CODE_URL } from './types'

const reducer: Reducer<SharingState, SharingActionTypes> = (state = initialSharingState, action: SharingActionTypes) => {
	switch (action.type) {
		case LOAD_SHARE: {
			const { driveItem, share } = action.payload
			return {
				...state,
				driveItem,
				share
			}
		}
		case LOAD_CODE_URL: {
			const { codeUrl } = action.payload
			return {
				...state,
				codeUrl
			}
		}
		case UNLOAD_SHARES: {
			return initialSharingState
		}
		default: {
			return state
		}
	}
}

export { reducer as sharingReducer }
