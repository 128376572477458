import { APIRequest } from '@lynx/client-core/src/api'
import { Alert, BasicButton, Input, Textarea } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { string } from '@lynx/core'
import { organisationHasBeenApplied, organisationNameNotFound } from '@lynx/core/src/interfaces/Notifications'
import { useThunkDispatch } from 'hooks'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OrganisationNextCloudDomainModalContextType, setModalContext } from 'store/modal'
import { pushNotification } from 'store/notifications'
import css from './NextCloudDomain.module.css'
import { useTranslation } from 'react-i18next'

export interface NextCloudDomainProps {
	navigate: (section: string) => void
}

export const NextCloudDomain = (props: NextCloudDomainProps): React.ReactElement => {
	const [nextCloudDomainsOptions, setNextCloudDomainsOptions] = useState<string[]>([])
	const [domain, setDomain] = useState('')
	const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null)
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const [errorMessage, setErrorMessage] = useState('')
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = useState(false)

	const onChangeDomain = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
		setDomain(event.target.value)
	}

	useEffect(() => {
		return (): void => {
			intervalId && clearInterval(intervalId)
		}
	}, [intervalId])

	const getOrganisationInitialDetails = async (nextCloudTemporaryAccessId: string): Promise<void> => {
		const userNextCloudDetails = await APIRequest.Organisations.nextCloudOrganisationGetInitialDetails(nextCloudTemporaryAccessId)

		if (!userNextCloudDetails) {
			return
		}

		const organisationName = userNextCloudDetails.data?.organisationName || ''
		dispatch(
			setModalContext({
				context: {
					type: OrganisationNextCloudDomainModalContextType,
					nextCloudOrganisationId: nextCloudTemporaryAccessId,
					organisationName,
					address: userNextCloudDetails.data?.address || '',
					nextCloudDomain: domain,
					nextCloudEmailDomains: nextCloudDomainsOptions
				}
			})
		)
		if (organisationName) {
			await dispatchThunk(pushNotification({ type: organisationHasBeenApplied, payload: { organisationName } }))
		} else {
			await dispatchThunk(pushNotification({ type: organisationNameNotFound, payload: null }))
		}
		props.navigate('OrganisationDetails')
	}

	const initAuth = async (): Promise<string | undefined> => {
		setErrorMessage('')
		try {
			const res = await APIRequest.Organisations.nextCloudOrganisationInitAuth(domain)

			if (!res) {
				return
			}

			window.open(res.data?.loginUrl, '_blank')
			return res.data?.nextCloudTemporaryAccessId
		} catch (err) {
			setErrorMessage(`${domain} ${t('pages.organisation.organisationNameNotFound')}`)
			setIsLoading(false)
		}
	}

	const tryConfirmAccess = (nextCloudTemporaryAccessId: string): void => {
		const intervalTryTime = 5000
		let retry = 0
		const interval = setInterval(async () => {
			++retry
			if (retry === 15) {
				clearInterval(interval)
			}
			try {
				setIsLoading(true)
				await APIRequest.Organisations.nextCloudOrganisationConfirmAccess(nextCloudTemporaryAccessId)
				clearInterval(interval)
				await getOrganisationInitialDetails(nextCloudTemporaryAccessId)
				setIsLoading(false)
			} catch (err) {
				Logger.log(err)
			}
		}, intervalTryTime)
		setIntervalId(interval)
	}

	const handleDomainSelected = async (): Promise<void> => {
		setIsLoading(true)
		const nextCloudTemporaryAccessId = await initAuth()
		if (!nextCloudTemporaryAccessId) return
		tryConfirmAccess(nextCloudTemporaryAccessId)
	}

	return (
		<div className={css['container']}>
			{errorMessage && (
				<p>
					<Alert styleClass="danger">{errorMessage}</Alert>
				</p>
			)}
			<p>{t('pages.organisation.nextCloudUrl')}</p>
			<Input disabled={isLoading} autoFocus value={domain} onChange={onChangeDomain} />
			<p>{t('pages.organisations.nextCloudDomainsPerLine')}</p>
			<Textarea
				disabled={isLoading}
				onChange={(e): void => {
					setNextCloudDomainsOptions(e.target.value.split('\n'))
				}}
			/>
			<div className={css['buttons']}>
				<BasicButton
					onClick={(): void => {
						props.navigate('OrganisationTypeSelector')
					}}
				>
					{t('pages.organisations.createModal.back')}
				</BasicButton>
				<BasicButton
					isLoading={isLoading}
					variant="blue"
					disabled={isLoading || !string.isUrl(domain) || Boolean(!nextCloudDomainsOptions[0]?.length)}
					onClick={handleDomainSelected}
				>
					{t('pages.organisations.createModal.next')}
				</BasicButton>
			</div>
		</div>
	)
}
