import React, { useState } from 'react'
import { BasicButton, Input, ListItem } from '@lynx/client-core/src/components'
import { useTranslation } from 'react-i18next'
import css from './CreditCardForm.module.scss'
import { Message } from '@lynx/client-core/src/components/Input/Input.types'

export const CreditCardForm = (): React.ReactElement => {
	const [inputStates, setInputStates] = useState<{ [key: string]: string }>({})
	const [messages] = useState<{ [inputKey: string]: Message[] }>({})
	const { t } = useTranslation()

	const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setInputStates((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value
		}))
	}

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault()
		event.stopPropagation()
	}

	return (
		<ListItem header={t('pages.profile.changePaymentMethod')}>
			<div className={css['container']}>
				<form onSubmit={handleFormSubmit} className={css['form']}>
					<Input
						label={t('pages.profile.creditCardNumber')}
						variant="light"
						onChange={handleInputChanged}
						name="creditCardNumber"
						value={inputStates['creditCardNumber']}
						messages={messages['creditCardNumber']}
						autoComplete="cc-number"
						inputMode="numeric"
						pattern="[0-9\s]{13,19}"
						maxLength={19}
						placeholder="XXXX XXXX XXXX XXXX"
					/>

					<div className={css['form__row']}>
						<div className={css['form__column']}>
							<Input
								label={t('pages.profile.creditCardExpirationMonth')}
								variant="light"
								onChange={handleInputChanged}
								name="creditCardExpirationMonth"
								value={inputStates['creditCardExpirationMonth']}
								messages={messages['creditCardExpirationMonth']}
								autoComplete="cc-exp-month"
								inputMode="numeric"
								pattern="[0-9\s]{2}"
								maxLength={2}
							/>
						</div>

						<div className={css['form__column']}>
							<Input
								label={t('pages.profile.creditCardExpirationYear')}
								variant="light"
								onChange={handleInputChanged}
								name="creditCardExpirationYear"
								value={inputStates['creditCardExpirationYear']}
								messages={messages['creditCardExpirationYear']}
								autoComplete="cc-exp-year"
								inputMode="numeric"
								pattern="[0-9\s]{2}"
								maxLength={2}
							/>
						</div>
					</div>

					<div className={css['form__row']}>
						<div className={css['form__column']}>
							<Input
								label={t('pages.profile.creditCardCVC')}
								variant="light"
								onChange={handleInputChanged}
								name="creditCardCVC"
								value={inputStates['creditCardCVC']}
								messages={messages['creditCardCVC']}
								autoComplete="cc-csc"
								inputMode="numeric"
								pattern="[0-9\s]{3,4}"
								maxLength={4}
							/>
						</div>
						<div className={css['form__column']} />
					</div>

					<div className={css['form__row']}>
						<div className={css['form__column']}>
							<BasicButton to="/profile" variant="light">
								{t('pages.profile.backToAccountDetails')}
							</BasicButton>
						</div>
						<div className={css['form__column']}>
							<BasicButton variant="blue" type="submit">
								{t('pages.profile.updatePaymentMethod')}
							</BasicButton>
						</div>
					</div>
				</form>
			</div>
		</ListItem>
	)
}
