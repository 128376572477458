import * as React from 'react'
import { useTranslation } from 'react-i18next'
import css from './InvitesSentPopUp.module.css'
import { InvitesSentPopUpProps } from './interfaces'

export const InvitesSentPopUp = ({ isOpen }: InvitesSentPopUpProps): React.ReactElement => {
	const { t } = useTranslation()
	const classNames = [css['container']]
	isOpen && classNames.push(css['open'])

	return (
		<div className={classNames.join(' ')}>
			<h3>{t('pages.share.shareModal.invitesSent')}</h3>
			<div>{t('pages.share.shareModal.willReceiveEmail')}</div>
		</div>
	)
}
