import { APIRequest } from '@lynx/client-core/src/api'
import { SharesInfo } from '@lynx/client-core/src/api/interfaces'
import { ControlBar } from 'pages/Share/components/ControlBar'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ImagePreviewModalContextType, showModal } from 'store/modal'
import css from './Image.module.scss'
import { PreviewModal } from './modals/PreviewModal'
import { ImageUpscaleModal } from '@lynx/client-core/src/components'
import { ImageUpscaleModalContextType } from '@lynx/client-core/src/store/modal'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'

interface ImageState {
	shareDetails: SharesInfo | null
	token: string
	blobLink: string
}

export const Image = (): React.ReactElement => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const params = useParams<{ token: string }>()
	const { isPro } = useSelector((state: ApplicationState) => state.profile)
	const [state, setState] = useState<ImageState>({
		shareDetails: null,
		token: '',
		blobLink: ''
	})

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const { token } = params
			if (!token) {
				return
			}
			const shareInfo = await APIRequest.Sharing.info(token)
			if (!shareInfo?.data) {
				navigate('/missing-share')
				return
			}

			const blobLinkProperties = await APIRequest.Sharing.getObjectUrl(token)
			if (!blobLinkProperties) {
				navigate('/missing-share')
			}

			setState({ ...state, token, blobLink: blobLinkProperties.link, shareDetails: shareInfo.data })
		}
		mount()
	}, [])

	const handleViewClicked = (): void => {
		dispatch(showModal({ name: 'ImagePreviewModal', context: { type: ImagePreviewModalContextType, token: state.token } }))
	}

	const upscaleImage = async (): Promise<void> => {
		if (!isPro) {
			dispatch(showModal({ name: 'UpsellModal' }))
			return
		}
		const replaceImage = async (imgBlob: Blob): Promise<void> => {
			if (!state.shareDetails) return
			const file: File = new File([imgBlob], state.shareDetails.name)
			await APIRequest.Sharing.updateShare(state.token, file)

			const blobLinkProperties = await APIRequest.Sharing.getObjectUrl(state.token)
			setState((prevState) => ({ ...prevState, blobLink: blobLinkProperties.link }))
		}

		if (!params.token || !state.shareDetails) return
		dispatch(
			showModal({
				name: 'ImageUpscaleModalContextType',
				context: { type: ImageUpscaleModalContextType, url: state.blobLink, name: state.shareDetails.name, replaceImage }
			})
		)
	}

	const handleDownload = (): void => {
		const link = document.createElement('a')
		link.href = state.blobLink
		document.body.appendChild(link)
		link.download = state.shareDetails?.name || 'download'
		link.click()
		document.body.removeChild(link)
	}

	return (
		<div className={css['container']}>
			{state.blobLink && <img src={state.blobLink} alt="Image" />}
			{state.shareDetails && (
				<ControlBar
					onDownloadClicked={handleDownload}
					onViewClicked={handleViewClicked}
					shareDetails={state.shareDetails}
					token={state.token}
					upscaleImage={upscaleImage}
				/>
			)}
			<ImageUpscaleModal />
			<PreviewModal />
		</div>
	)
}
