import { APIRequest } from '@lynx/client-core/src/api'
import { CheckCircleOutlineIcon, LoadingSpinner, MailOutlineIcon } from '@lynx/client-core/src/components'
import { sleepAsync } from '@lynx/core'
import React from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteNotification, deleteNotificationToast } from 'store/notifications'
import { NotificationVariantsProps } from './interfaces'
import css from './NotificationVariants.module.scss'
import { DefaultTemplate } from './NotificationVariantsDefaultTemplate'

type ResponseKey = 'isYes' | 'isNo' | 'isAskMeLater'

export const NotificationVariantSubscribeNewsLetter = ({
	createdAt,
	notification,
	isViewed = false,
	onNotificationClick,
	isToast = false
}: NotificationVariantsProps): React.ReactElement => {
	const dispatch = useDispatch()
	const [keyLoading, setKeyLoading] = React.useState<null | ResponseKey>(null)
	const [isSuccessIcon, setIsSuccessIcon] = React.useState(false)

	const { notificationId } = notification
	const { t } = useTranslation()
	const notificationCleanUp = async (): Promise<void> => {
		if (notificationId) {
			await APIRequest.Notifications.deleteNotification(notificationId)
			dispatch(deleteNotification(notificationId))
		}
		isToast && dispatch(deleteNotificationToast(notification))
	}

	const finalAnimation = async (): Promise<void> => {
		setIsSuccessIcon(true)
		await sleepAsync(5000)
	}

	const onBtnClick = async (
		cb: typeof updateNotificationHandler | typeof APIRequest.Newsletter.unsubscribe | typeof APIRequest.Newsletter.subscribe,
		clickKey: ResponseKey
	): Promise<void> => {
		try {
			setKeyLoading(clickKey)
			await cb(clickKey)
			await finalAnimation()
		} finally {
			setKeyLoading(null)
			await notificationCleanUp()
		}
	}

	const updateNotificationHandler = async (loadingKey: ResponseKey): Promise<void> => {
		ReactGA.event({
			action: `SubscribeNewsLetter [action:response${loadingKey}]`,
			category: 'Notification'
		})
		notificationId &&
			(await APIRequest.Notifications.updateNotification({
				id: notificationId,
				isViewed: true,
				payload: { isNo: loadingKey === 'isNo', isYes: loadingKey === 'isYes', isAskMeLater: loadingKey === 'isAskMeLater' }
			}))
	}
	const defaultProps = {
		createdAt,
		isToast,
		isViewed,
		onNotificationClick
	}
	const buttons: { translationKey: string; textKey: ResponseKey; onClick: () => void }[] = [
		{
			translationKey: 'components.notifications.notificationVariants.isAskMeLater',
			textKey: 'isAskMeLater',
			onClick: async (): Promise<void> => {
				if (!keyLoading) onBtnClick(updateNotificationHandler, 'isAskMeLater')
			}
		},
		{
			translationKey: 'components.notifications.notificationVariants.isNo',
			textKey: 'isNo',
			onClick: async (): Promise<void> => {
				if (!keyLoading) onBtnClick(APIRequest.Newsletter.unsubscribe, 'isNo')
			}
		},
		{
			translationKey: 'components.notifications.notificationVariants.isYes',
			textKey: 'isYes',
			onClick: async (): Promise<void> => {
				if (!keyLoading) onBtnClick(APIRequest.Newsletter.subscribe, 'isYes')
			}
		}
	]
	return (
		<DefaultTemplate
			{...defaultProps}
			variant="information"
			customIcon={MailOutlineIcon}
			isNotificationClickDisable={true}
			notification={notification}
			isAlignCenter={false}
		>
			<div>
				{t('components.notifications.notificationVariants.emailMeWithLatestUpdates')}
				<div className={css.subscribeNewsletterButtonsContainer}>
					{isSuccessIcon ? (
						<CheckCircleOutlineIcon className={css.successIcon} />
					) : (
						buttons.map(({ textKey, onClick, translationKey }) => (
							<div key={textKey} onClick={onClick} className={keyLoading && keyLoading !== textKey ? css.disabledButton : ''}>
								{keyLoading === textKey ? <LoadingSpinner /> : t(translationKey)}
							</div>
						))
					)}
				</div>
			</div>
		</DefaultTemplate>
	)
}
