import { useEffect, useState } from 'react'

export const useWindowDimensions = (): { width: number; height: number } => {
	const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 })

	useEffect(() => {
		const handleResize = (): void => {
			setWindowDimensions({
				width: window.innerWidth,
				height: window.innerHeight
			})
		}

		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowDimensions
}
