import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'

export const USER_ACCEPTED_INVITE = '@@organisations/USER_ACCEPTED_INVITE'
export const LOAD_ORGANISATIONS = '@@organisations/LOAD_ORGANISATIONS'
export const LOAD_ORGANISATION_DETAILS = '@@organisations/LOAD_ORGANISATION_DETAILS'
export const UPDATE_ORGANISATION_DATA = '@@organisations/UPDATE_ORGANISATION_DATA'
export const ORGANISATION_AVATAR_UPDATED = '@@organisations/ORGANISATION_AVATAR_UPDATED'

export interface OrganisationsState {
	userAcceptedInvite: { organisationId: number; inviteId: number } | null
	organisations: OrganisationRedux[]
	activeOrganisationId: number
	isLoaded: boolean
}

interface UserAcceptedInviteAction {
	type: typeof USER_ACCEPTED_INVITE
	payload: OrganisationsState['userAcceptedInvite']
}

interface LoadOrganisationsAction {
	type: typeof LOAD_ORGANISATIONS
	payload: OrganisationRedux[]
}

interface LoadOrganisationDetailsAction {
	type: typeof LOAD_ORGANISATION_DETAILS
	payload: number
}

interface UpdateOrganisationDataAction {
	type: typeof UPDATE_ORGANISATION_DATA
	payload: OrganisationRedux
}

interface OrganisationAvatarUpdatedAction {
	type: typeof ORGANISATION_AVATAR_UPDATED
	payload: number
}

export type OrganisationsActionTypes =
	| UserAcceptedInviteAction
	| LoadOrganisationsAction
	| LoadOrganisationDetailsAction
	| OrganisationAvatarUpdatedAction
	| UpdateOrganisationDataAction
