import * as React from 'react'
import { PopupPortal } from './PopupPortal'
import css from './Popup.module.css'

interface PopupProps {
	children: React.ReactNode
	show?: boolean
	onClickOutside?: () => void
	isOutside?: boolean
}

export const Popup = ({ isOutside, children, show, onClickOutside }: PopupProps): React.ReactElement | null => {
	/**
	 * Check whether the Overlay is telling us the click was outside of it, if so then let the overlay know it can close the popup
	 */
	const handleClickOutside = (): void => {
		if (isOutside) {
			onClickOutside && onClickOutside()
		}
	}
	if (!show) {
		return null
	}
	return (
		<PopupPortal>
			<div onTouchMove={handleClickOutside} onMouseDown={handleClickOutside} onWheel={handleClickOutside} className={css['container']}>
				{children}
			</div>
		</PopupPortal>
	)
}
