import { AddCircleOutlineIcon, BasicButton, CreateNewFolderIcon, Dropdown, FileAddIcon, FilePicker, UploadIcon } from '@lynx/client-core/src/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './Controls.module.scss'

interface DriveDropdownProps {
	buttonSize: 'xs' | 'sm' | 'md'
	onUploadClicked: (file: File) => void
	onCreateFolderClicked: () => void
	onCreateClicked: () => void
}

export const DriveDropdown = ({ buttonSize, onUploadClicked, onCreateFolderClicked, onCreateClicked }: DriveDropdownProps): React.ReactElement => {
	const { drive: driveState } = useSelector((state: ApplicationState) => state)
	const { writeable } = driveState
	const { t } = useTranslation()

	return (
		<Dropdown
			title={
				<BasicButton size={buttonSize} icon={AddCircleOutlineIcon} variant="blue" aria-label="new">
					{t('pages.drive.newButton')}
				</BasicButton>
			}
			openedClassName={css.alignToLeft}
		>
			<li className={css.newListItem}>
				<FilePicker multiple onChange={onUploadClicked} disabled={!writeable}>
					<div className={css.newListItem}>
						<UploadIcon /> <span>{t('pages.drive.controls.newItemList.fileUpload')}</span>
					</div>
				</FilePicker>
			</li>
			<li className={css.newListItem} onClick={onCreateFolderClicked}>
				<CreateNewFolderIcon /> <span>{t('pages.drive.controls.newItemList.newFolder')}</span>
			</li>

			<li className={css.newListItem} onClick={onCreateClicked}>
				<FileAddIcon /> <span>{t('pages.drive.controls.newItemList.newLynxfile')}</span>
			</li>
		</Dropdown>
	)
}
