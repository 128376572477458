import { setUserSessionEnd } from '@lynx/client-core/src/store/profile'
import { useThunkDispatch } from 'hooks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ApplicationState } from 'store'
import { clientLogout } from 'store/profile'

export const SessionEndWatch = (): React.ReactElement | null => {
	const {
		profile: { userSessionEnd }
	} = useSelector((state: ApplicationState) => state)
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		if (userSessionEnd) {
			const clearSession = async (): Promise<void> => {
				dispatch(setUserSessionEnd(false))
				await dispatchThunk(clientLogout())
				navigate('/')
			}
			clearSession()
		}
	}, [userSessionEnd])

	return null
}
