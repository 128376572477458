import { BasicButton, DeleteIcon, FileDownloadIcon, FilePicker, MagicWandIcon, Modal, ProBadgeIcon, SkeletonBone, UploadIcon } from '../..'
import React, { useEffect, useState, ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { hideModal, ImageUpscaleModalContext, ImageUpscaleModalContextType, ModalContext } from '../../../store/modal'
import css from './ImageUpscaleModal.module.scss'
import { Logger } from '../../../modules'
import { ModalDefaultLayout } from '../../Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { InputMessages } from '../../Input/InputMessages'
import { InputMessagesProps } from '../../Input/Input.types'
import { APIRequest } from '../../../api'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'

export const ImageUpscaleModal = (): ReactElement | null => {
	const dispatch = useDispatch()
	const [state, setState] = useState<ImageUpscaleModalContext>({
		type: ImageUpscaleModalContextType,
		url: '',
		name: '',
		replaceImage: undefined
	})
	const { t } = useTranslation()
	const [upscaledImgLoaded, setUpscaledImgLoaded] = useState<boolean>(false)
	const [imgBlob, setImgBlob] = useState<Blob | null>(null)
	const [oldImageRes, setOldImageRes] = useState<{ width: number; height: number } | null>(null)
	const [upscaledImageRes, setUpscaledImageRes] = useState<{ width: number; height: number } | null>(null)

	const [upscaledUrl, setUpscaledUrl] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isError, setIsError] = useState<boolean>(false)

	useEffect(() => {
		onUrlChange()
	}, [state.url])

	useEffect(() => {
		if (upscaledUrl) onUpscaledUrlChange()
	}, [upscaledUrl])

	const getImgData = async (url: string): Promise<Blob> => {
		const res = await fetch(url)
		const blob = await res.blob()
		return blob
	}

	const clearState = (): void => {
		setState({ ...state, name: '', url: '', replaceImage: undefined })
		setImgBlob(null)
		setUpscaledUrl(null)
		setIsLoading(false)
		setIsError(false)
		setOldImageRes(null)
		setUpscaledImageRes(null)
		setUpscaledImgLoaded(false)
	}

	const getImgResolution = async (blob: Blob): Promise<{ width: number; height: number }> => {
		return new Promise((resolve, reject) => {
			const img = new Image()
			img.onload = (): void => {
				resolve({ width: img.width, height: img.height })
			}
			img.onerror = (): void => {
				reject()
			}
			img.src = URL.createObjectURL(blob)
		})
	}

	const onUrlChange = async (): Promise<void> => {
		if (state.url) {
			try {
				const imgBlob = await getImgData(state.url)
				setImgBlob(imgBlob)
				setOldImageRes(await getImgResolution(imgBlob))
			} catch (err) {
				Logger.log(err)
			}
		}
	}

	const onUpscaledUrlChange = async (): Promise<void> => {
		if (!upscaledUrl) return
		try {
			setUpscaledImageRes(await getImgResolution(await getImgData(upscaledUrl)))
		} catch (err) {
			Logger.log(err)
		}
	}

	const handleVisible = (context?: ModalContext): void => {
		if (context?.type !== ImageUpscaleModalContextType) {
			return
		}

		setState(context)
	}

	const upscaleImg = async (): Promise<void> => {
		if (!imgBlob) return
		ReactGA.event({ category: 'ai', action: 'Upscale Image - Click upscale' })
		setIsError(false)
		setUpscaledImgLoaded(false)
		try {
			setIsLoading(true)
			const file: File = new File([imgBlob], state.name)
			const { url } = await APIRequest.Ai.upscaleImage(file)
			setUpscaledUrl(url)
		} catch (err) {
			setIsError(true)
			Logger.log(err)
		} finally {
			setIsLoading(false)
		}
	}

	const onFileSelect = (file: File): void => {
		setState({ ...state, name: file.name, url: URL.createObjectURL(file) })
	}

	const onClearClicked = (): void => {
		setState({ ...state, name: '', url: '' })
	}

	const onDownloadClick = async (): Promise<void> => {
		if (!upscaledUrl) return
		ReactGA.event({ category: 'ai', action: 'Upscale Image - Download' })
		const [fileName, extension] = state.name.split('.')
		const downloadFileName = `${fileName}_upscale-2x.${extension}`
		const link = document.createElement('a')
		link.href = URL.createObjectURL(await getImgData(upscaledUrl))
		link.download = downloadFileName
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	const replaceImageHandler = async (): Promise<void> => {
		if (!upscaledUrl) return
		ReactGA.event({ category: 'ai', action: 'Upscale Image - Replace' })
		try {
			setIsLoading(true)
			const upscaledImgBlob = await getImgData(upscaledUrl)
			await state.replaceImage?.(upscaledImgBlob)
			dispatch(hideModal())
		} catch (err) {
			Logger.log(err)
		} finally {
			setIsLoading(false)
		}
	}

	const messages: InputMessagesProps['messages'] = [
		{
			type: 'info',
			message: `${t('components.modals.upscaleModal.poweredBy')} DeepAi`
		}
	]
	if (isError) {
		messages.push({
			type: 'error',
			message: t('components.modals.upscaleModal.failedToUpscale')
		})
	}

	const isMaxUpscaled = oldImageRes?.width === 3200 || oldImageRes?.height === 3200
	const initialImage = (
		<div className={css.container}>
			{state.url ? (
				<div className={css.initialImageContainer}>
					<div className={css.imageContainer}>
						<img src={state.url} />
					</div>
					<div className={css.controls}>
						<div className={css.firstRow}>
							<div className={css.fileName}>{state.name}</div>
							<BasicButton size="xs" className={css.button} icon={DeleteIcon} onClick={onClearClicked} disabled={isLoading} />
						</div>

						{isMaxUpscaled ? (
							<div className={css.notUpscaled}>
								<InputMessages messages={[{ type: 'error', message: t('components.modals.upscaleModal.upscaleUnavailable') }]} />
							</div>
						) : (
							<>
								<div className={css.secondRow}>
									<BasicButton isLoading={isLoading} size="lg" variant="blue" onClick={upscaleImg} icon={MagicWandIcon}>
										{t('components.modals.upscaleModal.upscale')}
									</BasicButton>
								</div>
								<div className={css.messagesContainer}>
									<InputMessages messages={messages} />
								</div>
							</>
						)}
					</div>
				</div>
			) : (
				<FilePicker onChange={onFileSelect} accept=".png,.gif,.bmp,.jpg,.jpeg">
					<div>
						<BasicButton icon={UploadIcon}>{t('components.modals.upscaleModal.uploadImage')}</BasicButton>
					</div>
				</FilePicker>
			)}
		</div>
	)

	const isUpscaled =
		upscaledImageRes &&
		oldImageRes &&
		upscaledUrl &&
		(upscaledImageRes.width > oldImageRes.width || upscaledImageRes.height > oldImageRes.height) && // check if upscaled image is actually upscaled
		upscaledImageRes?.width !== oldImageRes?.width &&
		upscaledImageRes?.height !== oldImageRes?.height

	const upscaledImage = (
		<div className={css.container}>
			<div className={css.upscaledContainer}>
				{upscaledUrl && (
					<div className={css.upscaledImageContainer}>
						{!upscaledImgLoaded && <SkeletonBone className={css.imgLoader} style={oldImageRes ? { height: `${oldImageRes.height}px` } : {}} />}
						<img
							style={{ display: upscaledImgLoaded ? 'block' : 'none' }}
							className={css.img}
							onLoad={(): void => {
								setUpscaledImgLoaded(true)
							}}
							src={upscaledUrl}
						/>
					</div>
				)}

				{upscaledImageRes && (
					<div>
						{isUpscaled ? (
							<div>
								<div className={css.upscaledResult}>
									<span className={css.old}>
										{oldImageRes?.width} x {oldImageRes?.height}
									</span>
									<span className={css.new}>
										{upscaledImageRes?.width} x {upscaledImageRes?.height}
									</span>
								</div>
								<div className={css.upscaledControls}>
									{state.replaceImage && (
										<BasicButton onClick={replaceImageHandler} isLoading={isLoading}>
											{t('components.modals.upscaleModal.replace')}
										</BasicButton>
									)}
									<BasicButton icon={FileDownloadIcon} onClick={onDownloadClick} />
								</div>
							</div>
						) : (
							<div className={css.notUpscaled}>
								<InputMessages messages={[{ type: 'error', message: t('components.modals.upscaleModal.couldNotUpscale') }]} />
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)

	return (
		<Modal name={ImageUpscaleModalContextType} onVisible={handleVisible} hideCloseButton={false} onHidden={clearState}>
			<ModalDefaultLayout
				title={
					<div className={css.titleContainer}>
						{t('components.modals.upscaleModal.title')} <ProBadgeIcon />
					</div>
				}
				body={upscaledUrl ? upscaledImage : initialImage}
				footer={<div />}
			/>
		</Modal>
	)
}
