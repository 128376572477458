import { Popup, Shortcut, Tooltip } from '@lynx/client-core/src/components'
import { driveUsagePercentMedium, driveUsagePercentHigh } from '@lynx/core/src/constants'
import { formatBytes } from '@lynx/client-core/src/utils'
import React, { ForwardedRef, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './DriveItemTemplate.module.scss'
import { useTranslation } from 'react-i18next'

interface DefaultProps {
	thumbnail: React.ReactElement | null
	dropdown?: React.ReactElement | null
	size?: number
	fileProviderLogo?: JSX.Element
	isFavourite?: boolean
	isShortcut?: boolean
	handleItemClick?: () => void
	handleFavouriteClick?: () => void
	extra?: {
		tooltip: string
		icon: React.ReactElement
		onClick: () => void
	}
	usagePercentage?: number | null
}

interface PropsCaseOne extends DefaultProps {
	text: string | JSX.Element
	subText: string | JSX.Element
}

interface PropsCaseTwo extends DefaultProps {
	footer: JSX.Element
}

type DriveItemTemplateProps = PropsCaseOne | PropsCaseTwo
export const DriveItemTemplate = forwardRef((props: DriveItemTemplateProps, ref: ForwardedRef<HTMLDivElement> | undefined): React.ReactElement => {
	const { extra, thumbnail, dropdown, handleItemClick, size, fileProviderLogo, usagePercentage, isShortcut } = props
	const { style } = useSelector((state: ApplicationState) => state.drive)
	const { t } = useTranslation()
	const classes = [style === 'table' ? css.driveItemTable : css.driveItem, css.driveItemSharedStyles]

	const usageLevelVariants = [
		{
			upToPercentage: driveUsagePercentMedium,
			className: css['usageIndicator__low']
		},
		{
			upToPercentage: driveUsagePercentHigh,
			className: css['usageIndicator__medium']
		},
		{
			upToPercentage: 100,
			className: css['usageIndicator__high']
		}
	]
	const usageLevel =
		usageLevelVariants.find((variant) => usagePercentage && usagePercentage < variant.upToPercentage) || usageLevelVariants[usageLevelVariants.length - 1]

	const footer =
		'footer' in props ? (
			props.footer
		) : (
			<div>
				<div className={css.driveItemTextContainer}>
					<div className={css.driveItemText}>{props.text}</div>
					{!!size && <div className={css.driveItemSize}>{formatBytes(size)}</div>}
				</div>
				<div className={css.driveItemSubText}>{props.subText}</div>
			</div>
		)

	const shortcutEl = isShortcut && (
		<div className={css.shortcutEl} title={t('components.drive.shortcut')}>
			<Shortcut />
		</div>
	)

	return (
		<div className={classes.join(' ')} ref={ref}>
			{shortcutEl}
			<div className={css.dropdownContainer}>{dropdown}</div>
			<div className={css.contentContainer} onClick={handleItemClick}>
				{fileProviderLogo && <div className={css.fileProviderLogo}>{fileProviderLogo}</div>}
				<div className={css.thumbnailContainer}>{thumbnail}</div>
				{footer}
			</div>
			{extra && (
				<Tooltip data-tooltip={extra.tooltip} showOnHover className={css['extra__icon']}>
					<div onClick={extra.onClick}>{extra.icon}</div>
				</Tooltip>
			)}
			{typeof usagePercentage === 'number' && (
				<div className={css.usageIndicatorContainer}>
					<div className={css.percentageText}>
						{t('pages.profile.usage')} {Math.round(usagePercentage)}%
					</div>
					<div style={{ width: `${usagePercentage > 100 ? 100 : usagePercentage}%` }} className={`${css.usageIndicator} ${usageLevel?.className}`} />
				</div>
			)}
		</div>
	)
})
