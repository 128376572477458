import { APIRequest } from '@lynx/client-core/src/api'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { AuthCookie, Logger } from '@lynx/client-core/src/modules'
import { useThunkDispatch } from 'hooks'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { createChannel, subscribe, wsConnect } from 'store/messaging'

export const WSWatcher = (): React.ReactElement | null => {
	const dispatchThunk = useThunkDispatch()
	const dispatch = useDispatch()
	const {
		profile: { loggedIn, userId },
		config: { MESSAGING_WS_SERVER, ENVIRONMENT }
	} = useSelector((state: ApplicationState) => state)
	const wasLoggedIn = usePrevious(loggedIn)

	useEffect(() => {
		const mount = async (): Promise<void> => {
			if (wasLoggedIn || !loggedIn) {
				return
			}

			try {
				const exists = await APIRequest.Channels.exists(userId)
				if (!exists) {
					await dispatchThunk(createChannel('user', userId, userId))
				} else {
					await dispatchThunk(subscribe(userId))
				}
			} catch (e) {
				Logger.error(e)
			}

			const accessToken = AuthCookie.getAccessToken(ENVIRONMENT)
			dispatch(wsConnect(`${MESSAGING_WS_SERVER}/?token=${accessToken}`))
		}
		mount()
	}, [wasLoggedIn, loggedIn])

	return null
}
