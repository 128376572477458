import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { APIRequest } from '../../../../api'
import { BasicButton, Modal } from '../../../../components'
import { hideModal } from '../../../../store/modal'
import { acceptedTerms, logoutEverywhere } from '../../../../store/profile'
import css from './AcceptTermsModal.module.scss'
import { TermsCard } from './TermsCard'
import { useThunkDispatch } from '../../../../hooks/useThunkDispatch'

export const AcceptTermsModal = (): React.ReactElement => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()

	const handleAgreeClicked = async (): Promise<void> => {
		const accepted = await APIRequest.User.acceptLatestTerms()
		if (accepted) {
			dispatch(acceptedTerms())
			dispatch(hideModal())
		}
	}

	const handleLogoutClicked = async (): Promise<void> => {
		dispatch(hideModal())
		await dispatchThunk(logoutEverywhere())
	}

	return (
		<Modal name="AcceptTermsModal" title={'Accept Terms and Conditions'} innerContainerClasses={css['modal']} hideCloseButton={true}>
			<div className={css['container']}>
				<TermsCard />
				<div className={css['buttons']}>
					<BasicButton variant="danger" onClick={handleLogoutClicked}>
						{t('components.modals.AcceptTermsModal.logout')}
					</BasicButton>
					<BasicButton variant="blue" onClick={handleAgreeClicked}>
						{t('components.modals.AcceptTermsModal.agree')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
