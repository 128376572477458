import {
	BasicButton,
	CopyIcon,
	EditIcon,
	EntraIdIcon,
	IServIcon,
	Input,
	LynxWhiteboardLogo,
	NextCloudLogoIcon,
	SamlIcon,
	SettingsIcon,
	UcsIcon
} from '@lynx/client-core/src/components'
import { showModal } from '@lynx/client-core/src/store/modal'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { VerifyDomainModalContextType } from 'pages/Organisations/modals/VerifyDomainModal/VerifyDomainModalContextType'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './OrganisationConfiguration.module.scss'

const iconMap: { [key: string]: React.ReactElement } = {
	lynx: <LynxWhiteboardLogo />,
	saml: <SamlIcon />,
	ucs: <UcsIcon />,
	nextcloud: <NextCloudLogoIcon />,
	iserv: <IServIcon />,
	entraId: <EntraIdIcon />
}

const configurationMap: { [key: string]: { modalName: string | null } } = {
	lynx: { modalName: null },
	ucs: { modalName: 'SAMLConfigurationModal' },
	saml: { modalName: 'SAMLConfigurationModal' },
	nextcloud: { modalName: 'NextcloudConfigurationModal' },
	oauth: { modalName: 'OAUTHConfigurationModal' },
	iserv: { modalName: 'OAUTHConfigurationModal' },
	entraId: { modalName: 'EntraIdConfigurationModal' }
}

const providerMap: {
	[key: string]: 'oauth' | 'saml'
} = {
	oauth: 'oauth',
	iserv: 'oauth',
	saml: 'saml',
	ucs: 'saml'
}

const getCallbackUri = (provider: string, organisationId: number, apiServer: string): string => {
	return `${apiServer}/auth/${providerMap[provider]}:${organisationId}/callback`
}

export const OrganisationConfiguration = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { config } = useSelector((state: ApplicationState) => state)

	const organisation = useCurrentOrganisation()
	if (!organisation) return null

	const { provider } = organisation

	const handleSettingsClicked = (): void => {
		const { modalName: name } = configurationMap[provider]
		if (!name) return
		dispatch(showModal({ name }))
	}

	const handleDomainsSettingsClicked = (): void => {
		dispatch(showModal({ name: 'ManageDomainsModal' }))
	}

	const handleCopyClicked = (): void => {
		window.navigator.clipboard.writeText(getCallbackUri(provider, organisation.id, config.API_SERVER))
	}

	const handleUnverifiedClicked = (domainId: number, emailDomain: string): void => {
		dispatch(showModal({ name: 'VerifyDomainModal', context: { type: VerifyDomainModalContextType, domainId, emailDomain } }))
	}

	if (!organisation?.domains) return null

	const showCallback = Boolean(providerMap[provider])

	const domains = organisation.domains.map(({ emailDomain, id, verified }) => (
		<li key={id}>
			<div>{emailDomain}</div>
			{!verified && (
				<BasicButton variant="danger" onClick={(): void => handleUnverifiedClicked(id, emailDomain)}>
					{t('pages.organisations.unverified')}
				</BasicButton>
			)}
		</li>
	))

	return (
		<div className={css['container']}>
			<div className={css['container__header']}>
				<div className={css['container__header__title']}>
					{iconMap[provider]}
					<div>{provider.toUpperCase()}</div>
				</div>
				{organisation.isOwner && configurationMap[provider].modalName && <SettingsIcon onClick={handleSettingsClicked} />}
			</div>
			<hr />
			<div className={css['container__domains']}>
				<div>
					<h4>{t('pages.organisations.emailDomains')}</h4>
					{organisation.isOwner && <EditIcon onClick={handleDomainsSettingsClicked} />}
				</div>
				<ul className={css['list']}>{domains}</ul>
			</div>
			{showCallback && (
				<div className={css['container__callback']}>
					<h5>{t('pages.organisations.callback')}</h5>
					<div className={css['container__callback__value']}>
						<Input value={getCallbackUri(provider, organisation.id, config.API_SERVER)} readOnly={true} />
						<CopyIcon onClick={handleCopyClicked} />
					</div>
				</div>
			)}
		</div>
	)
}
