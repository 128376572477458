import { CalculateIcon, CloudQueueIcon, Document, EmailVerifiedIcon } from '@lynx/client-core/src/components'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ApplicationState } from 'store'
import { login } from 'store/profile'
import Styles from './RegistrationSuccess.module.css'

export const RegistrationSuccess = (): React.ReactElement => {
	const { config: configState, profile: profileState } = useSelector((state: ApplicationState) => state)
	const dispatchThunk = useThunkDispatch()
	const navigate = useNavigate()

	useEffect((): void => {
		const mount = async (): Promise<void> => {
			if (!profileState.loggedIn) {
				await dispatchThunk(login(null))
			}
		}
		mount()
	}, [])

	const handleGamesClicked = (): void => {
		const { LYNXACTIVITIES_CLIENT } = configState
		if (profileState.loggedIn) {
			window.location.href = LYNXACTIVITIES_CLIENT
			return
		}

		navigate(`/auth/login?redirect=${LYNXACTIVITIES_CLIENT}&bannerId=games`)
	}

	const handleLynxCloudClicked = (): void => {
		navigate(`/`)
	}

	return (
		<Document title={i18next.t('pages.pageTitles.emailVerificationSuccess')} description="Email Verification Success" keywords="Email Verification Success">
			<div className={Styles['container']}>
				<div className={Styles['prompt']}>
					<div className={Styles['mail-svg']}>
						<EmailVerifiedIcon />
					</div>
					<div className={Styles['title']}>{i18next.t('pages.registrationSuccess.title')}</div>
					<div>
						<span className={Styles['body']}>{i18next.t('pages.registrationSuccess.message')}</span>
					</div>
					<div className={Styles['button-container']}>
						<button onClick={handleGamesClicked} className={Styles['games']} style={!profileState.loggedIn ? { marginRight: '20px' } : {}}>
							<CalculateIcon /> {i18next.t('components.header.activities')}
						</button>
						<button onClick={handleLynxCloudClicked} className={Styles['lynxcloud']}>
							<CloudQueueIcon className={Styles['cloud-svg']} />
							LYNX Cloud
						</button>
					</div>
				</div>
			</div>
		</Document>
	)
}
