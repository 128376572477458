import { ClientProviders, DriveItemType } from '@lynx/client-core/src/api/interfaces'
import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './DriveList/DriveList.module.scss'
import { DriveItem } from './DriveItem/DriveItem'
import { APIRequest } from '@lynx/client-core/src/api'
import { useTranslation } from 'react-i18next'
import { DriveItemActionType } from './DriveList/Drive.types'

interface DriveITemRecentFilesProps {
	handleItemClick: (action: DriveItemActionType, item: DriveItemType) => void
}

export const DriveItemRecentFiles = ({ handleItemClick }: DriveITemRecentFilesProps): ReactElement => {
	const {
		drive: { style, provider }
	} = useSelector((state: ApplicationState) => state)
	const [recentDriveItems, setRecentDriveItems] = useState<DriveItemType[]>([])
	const { t } = useTranslation()

	const deleteRecentFile = async (driveItem: DriveItemType): Promise<void> => {
		await APIRequest.ObjectStore.deleteRecentFile(driveItem)
		setRecentDriveItems((prev) => prev.filter((item) => item.id !== driveItem.id))
	}

	const onItemClick = (action: DriveItemActionType, item: DriveItemType): void => {
		switch (action) {
			case 'delete':
				deleteRecentFile(item)
				break

			default:
				handleItemClick(action, item)
		}
	}

	const recentFileItemToElement = (item: DriveItemType, i: number): JSX.Element => (
		<DriveItem item={item} key={item.id + i} handleItemClick={onItemClick} isDisplayProviderLogo />
	)

	const getRecentFiles = async (): Promise<void> => {
		const res = await APIRequest.ObjectStore.getRecentFiles()
		setRecentDriveItems(res)
	}
	useEffect(() => {
		if (!provider) getRecentFiles()
	}, [provider])

	return (
		<>
			{Boolean(recentDriveItems.length) && (
				<div className={[css.sectionContainer, style === 'table' && css.sectionContainerTableView].join(' ')}>
					<div>{t('components.drive.recentFiles')}</div>
				</div>
			)}
			{recentDriveItems.map((item, i) => recentFileItemToElement(item, i))}
		</>
	)
}
