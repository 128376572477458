import { ArrowBackIcon } from '../../../../Icons'
import { Section } from '../../../../Sections'
import i18next from 'i18next'
import * as React from 'react'
import Styles from './VerificationSection.module.css'

interface VerificationSectionProps {
	navigate: (section: string) => void
}

export const VerificationSection = ({ navigate: navigateSection }: VerificationSectionProps): React.ReactElement => {
	const handleGoBack = (): void => {
		navigateSection('SignInSection')
	}

	return (
		<Section
			side="right"
			title={
				<div className={Styles['title-container']}>
					<div className={Styles['back-arrow']} onClick={handleGoBack}>
						<ArrowBackIcon />
					</div>
					<div>{i18next.t('pages.login.emailVerification')}</div>
				</div>
			}
			className={Styles['verification-section']}
		>
			<div>
				<div className={Styles['paragraph']}>
					<div>{i18next.t('pages.login.emailSent')}</div>
					<div>
						{i18next.t('pages.login.anEmailSent')}. {i18next.t('pages.login.pleaseClickLink')}
					</div>
				</div>
				<div className={Styles['paragraph']}>
					<div>{i18next.t('pages.login.emailNotReceived')}</div>
					<div>{i18next.t('pages.login.emailBlocked')}</div>
				</div>
			</div>
		</Section>
	)
}
