import { Reducer } from 'redux'
import { initialMessagingState } from './initialState'
import { NAV_PAIRED } from '../../middleware/remote/types'
import {
	MessagingActionTypes,
	MessagingState,
	REDUX_WEBSOCKET_BROKEN,
	REDUX_WEBSOCKET_CLOSED,
	REDUX_WEBSOCKET_CONNECT,
	REDUX_WEBSOCKET_MESSAGE,
	REDUX_WEBSOCKET_OPEN,
	REDUX_WEBSOCKET_RECONNECT_ABORTED,
	CREATE_CHANNEL
} from './types'

const reducer: Reducer<MessagingState, MessagingActionTypes> = (state = initialMessagingState, action: MessagingActionTypes) => {
	switch (action.type) {
		case NAV_PAIRED: {
			return {
				...state,
				...{
					channel: {
						id: state.channel.id,
						paired: true
					}
				}
			}
		}
		case CREATE_CHANNEL: {
			return {
				...state,
				channel: {
					id: action.payload.channel.id,
					paired: false
				}
			}
		}

		case REDUX_WEBSOCKET_CONNECT: {
			return {
				...state
			}
		}
		case REDUX_WEBSOCKET_OPEN: {
			return {
				...state,
				connected: true
			}
		}
		case REDUX_WEBSOCKET_RECONNECT_ABORTED: {
			return {
				...state
			}
		}
		case REDUX_WEBSOCKET_BROKEN:
		case REDUX_WEBSOCKET_CLOSED:
			return {
				...state,
				connected: false
			}
		case REDUX_WEBSOCKET_MESSAGE: {
			return {
				...state
			}
		}
		default: {
			return state
		}
	}
}

export { reducer as messagingReducer }
