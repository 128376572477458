import { HIDE_MODAL, Modal, SET_MODAL_CONTEXT, SHOW_MODAL } from './types'

export const showModal = <MC>(modal: Modal<MC>): { type: typeof SHOW_MODAL; payload: Modal<MC> } => {
	return {
		type: SHOW_MODAL,
		payload: modal
	}
}

export const hideModal = (): { type: typeof HIDE_MODAL } => {
	return {
		type: HIDE_MODAL
	}
}

export const setModalContext = <MC>(modal: Modal<MC>): { type: typeof SET_MODAL_CONTEXT; payload: Modal<MC> } => {
	return {
		type: SET_MODAL_CONTEXT,
		payload: modal
	}
}
