import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { useBillingPlans } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { hideModal } from '@lynx/client-core/src/store/modal'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export const StorageLimitWarningModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { billingPlans } = useBillingPlans()
	const [usedBytes, setUsedBytes] = useState<string>('0')

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const { usedBytes } = await APIRequest.ObjectStore.getDriveSize('lynxcloud')
			if (!usedBytes) {
				Logger.error('Failed to get drive size')
				return
			}
			const usedBytesInGB = (usedBytes / 1024 / 1024 / 1024).toFixed(1)
			setUsedBytes(`${usedBytesInGB}GB`)
		}
		mount()
	}, [])

	const handleContinueClicked = async (): Promise<void> => {
		try {
			const response = await APIRequest.Billing.getManageSubscriptionPortalURL()
			window.location.href = response.url
		} catch (e) {
			Logger.error(e)
		}
	}

	if (!billingPlans) {
		return null
	}

	return (
		<Modal name="StorageLimitWarningModal" variant="yellow" hideCloseButton>
			<ModalDefaultLayout
				title={'Storage Limit Warning'}
				body={`Your current usage is at ${usedBytes}, which is beyond the ${billingPlans['free'].storageLimitGB}GB limit provided with LYNX Free. You will have 30 days from cancellation to save files
					to another location. After that, older files will be deleted to bring you within the ${billingPlans['free'].storageLimitGB}GB limit.`}
				footer={
					<React.Fragment>
						<BasicButton onClick={(): ReturnType<typeof hideModal> => dispatch(hideModal())} variant="transparent">
							{t('components.modals.areYouSureModal.cancel')}
						</BasicButton>
						<BasicButton
							onClick={(): void => {
								dispatch(hideModal())
								handleContinueClicked()
							}}
							variant="danger"
						>
							Continue
						</BasicButton>
					</React.Fragment>
				}
			/>
		</Modal>
	)
}
