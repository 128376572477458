import * as React from 'react'
import * as ReactDOM from 'react-dom'

const popupRoot = document.getElementById('popup-root')

interface PopupPortalProps {
	children: React.ReactNode
}

export class PopupPortal extends React.Component<PopupPortalProps> {
	popupRootElement: HTMLDivElement

	constructor(props: PopupPortalProps) {
		super(props)
		this.popupRootElement = document.createElement('div')
	}

	componentDidMount(): void {
		if (popupRoot) {
			popupRoot.appendChild(this.popupRootElement)
		}
	}

	componentWillUnmount(): void {
		if (popupRoot) {
			popupRoot.removeChild(this.popupRootElement)
		}
	}

	render(): React.ReactPortal {
		return ReactDOM.createPortal(this.props.children, this.popupRootElement)
	}
}
