import { BasicButton, Document, Dropdown, Table } from '@lynx/client-core/src/components'
import { Card } from 'components/elmstone/Card'
import * as React from 'react'
import { useEffect } from 'react'
import Styles from './CodeGen.module.css'
import { CodeGenItem } from './interfaces'
import { useCodeGen } from './useCodeGen'

const prefixOptions = [{ value: 'CPS', label: 'CPS' }]
const licenseOptions = [{ value: 'OEM', label: 'OEM' }]

export const CodeGen = (): React.ReactElement => {
	const {
		handleGenerateClicked,
		fetchData,
		handleSubmit,
		handleChange,
		setFieldTouched,
		setFieldValue,
		isSubmitting,
		initialValues,
		state,
		values,
		touched,
		errors
	} = useCodeGen()

	const generateButton = (item: CodeGenItem): React.ReactElement => (
		<BasicButton onClick={(): Promise<void> => handleGenerateClicked(item)}>generate</BasicButton>
	)

	const headers = [
		{ valueKey: 'Date', label: 'Date' },
		{ valueKey: 'PO Number', label: 'PO Number' },
		{ valueKey: 'Who', label: 'Who' },
		{ valueKey: 'First Key', label: 'First Key' },
		{ valueKey: 'Count', label: 'Count' },
		{ valueKey: 'action', label: 'Generate', customBodyRender: generateButton }
	]

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<Document title={'Code Generator'} description="Code Generator" keywords="Code Generator" auth={true} disableOverflow={true}>
			<Card>
				<form onSubmit={handleSubmit} className={Styles['form']}>
					{/* Prefix */}
					<div>
						<label>Prefix</label>
						<Dropdown title={<div>{prefixOptions[0].label}</div>} className={Styles['genericSelectList']} chevron isFullWidth>
							{prefixOptions.map((c, index) => {
								return (
									<li key={index} className={Styles['dropdownOptionLight']} onClick={(): void => handleChange('prefix', c.value)}>
										{c.label}
									</li>
								)
							})}
						</Dropdown>
					</div>
					{/* Version number */}
					<div>
						<label>Version number{errors.versionNumber && touched.versionNumber && <span>{errors.versionNumber}</span>}</label>
						<input
							name="versionNumber"
							type="number"
							onChange={(element): void => handleChange('versionNumber', element.currentTarget.value)}
							onBlur={(): void => setFieldTouched('versionNumber', true)}
							value={values.versionNumber || initialValues.versionNumber}
						/>
					</div>
					{/* License Type */}
					<div>
						<label>License type</label>
						<Dropdown title={<div>{licenseOptions[0].label}</div>} className={Styles['genericSelectList']} chevron isFullWidth>
							{licenseOptions.map((c, index) => {
								return (
									<li key={index} className={Styles['dropdownOptionLight']} onClick={(): void => handleChange('licenseType', c.value)}>
										{c.label}
									</li>
								)
							})}
						</Dropdown>
					</div>
					{/* Start number */}
					<div>
						<label>Start number{errors.startNumber && touched.startNumber && <span>{errors.startNumber}</span>}</label>
						<input
							name="startNumber"
							type="number"
							onChange={(element): void => handleChange('startNumber', element.currentTarget.value)}
							onBlur={(): void => setFieldTouched('startNumber', true)}
							value={values.startNumber || initialValues.startNumber}
						/>
					</div>
					{/* Number required */}
					<div>
						<label>Number required{errors.numberRequired && touched.numberRequired && <span>{errors.numberRequired}</span>}</label>
						<input
							name="numberRequired"
							type="number"
							onChange={(element): void => handleChange('numberRequired', element.currentTarget.value)}
							onBlur={(): void => setFieldTouched('numberRequired', true)}
							value={values.numberRequired || initialValues.numberRequired}
						/>
					</div>
					{/* Purchase Order Number */}
					<div>
						<label>Purchase Order number</label>
						<input
							name="purchaseOrderNumber"
							type="number"
							onChange={(element): void => setFieldValue('purchaseOrderNumber', element.currentTarget.value)}
							onBlur={(): void => setFieldTouched('purchaseOrderNumber', true)}
							value={values.purchaseOrderNumber || initialValues.purchaseOrderNumber}
						/>
					</div>
					{/* Who requested these keys */}
					<div>
						<label>Who requested these keys{errors.who && touched.who && <span>{errors.who}</span>}</label>
						<input
							name="who"
							onChange={(element): void => handleChange('who', element.currentTarget.value)}
							onBlur={(): void => setFieldTouched('who', true)}
							value={values.who || initialValues.who}
						/>
					</div>
					<BasicButton type="submit" disabled={isSubmitting}>
						{isSubmitting ? 'Submitting' : 'Save'}
					</BasicButton>
				</form>
			</Card>
			<Card>
				<Table initialSortHeaderKey="Date" items={state.generatedKeys} csvFileName="codes.csv" headers={headers} />
			</Card>
		</Document>
	)
}
