import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

export const RegReportRecentMultipleUsage = (): React.ReactElement => {
	const [reportUsages, setReportUsages] = useState([])
	const columns = ['FirstRegistration', 'LogLastUpdatedDate', 'LynxVersion', 'Lynx4Code', 'Name', 'Email', 'Organisation', 'Address', 'Postcode', 'AppOS']
	const navigate = useNavigate()

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const data = await APIRequest.Reports.getRegReportRecentMultipleUsage()
			setReportUsages(data.reportUsages)
		}
		mount()
	}, [])

	const goToDetails = (data: any): void => {
		navigate('/reports/reg-report-detail/' + data.Lynx4Code)
	}

	return (
		<Document
			title="Registration Report - Recent Multiple Usage"
			description="Registration Report - Recent Multiple Usage"
			keywords="Registration Report, Report, Registration, LYNX"
			auth={true}
			disableOverflow={true}
		>
			<Card>
				<p>In the last seven days, the following licence codes have been registered multiple times:</p>
				<Table
					onRowClick={goToDetails}
					title={'Reg Report Recent Multiple Usage'}
					headers={columns.map((v) => ({ label: v, valueKey: v }))}
					items={reportUsages}
				/>
			</Card>
		</Document>
	)
}
