/* eslint-disable */
import React, { Component } from 'react'
import PageContext from './PageContext'
import { getPixelRatio, isCancelException, makePageCallback } from './shared/utils'

interface PageCanvasInternalProps {
	page: any
	renderInteractiveForms: any
	onRenderSuccess: any
	rotate: number
	scale: number
	onRenderError: any
	onRenderStart: any
}

export class PageCanvasInternal extends Component<PageCanvasInternalProps> {
	canvasLayer: any
	renderer: any

	componentDidMount(): void {
		this.drawPageOnCanvas()
	}

	componentDidUpdate(prevProps: PageCanvasInternalProps): void {
		const { page, renderInteractiveForms } = this.props
		if (renderInteractiveForms !== prevProps.renderInteractiveForms) {
			// Ensures the canvas will be re-rendered from scratch. Otherwise all form data will stay.
			page.cleanup()
			this.drawPageOnCanvas()
		}
	}

	componentWillUnmount(): void {
		this.cancelRenderingTask()

		/*
		 * Zeroing the width and height cause most browsers to release graphics
		 * resources immediately, which can greatly reduce memory consumption.
		 */
		if (this.canvasLayer) {
			this.canvasLayer.width = 0
			this.canvasLayer.height = 0
			this.canvasLayer = null
		}
	}

	cancelRenderingTask(): void {
		if (this.renderer && this.renderer._internalRenderTask.running) {
			this.renderer._internalRenderTask.cancel()
		}
	}

	/*
	 * Called when a page is rendered successfully.
	 */
	onRenderSuccess = (): void => {
		this.renderer = null

		const { onRenderSuccess, page, scale } = this.props
		onRenderSuccess && onRenderSuccess(makePageCallback(page, scale))
	}

	onRenderStart = (): void => {
		const { onRenderStart } = this.props
		onRenderStart && onRenderStart()
	}

	/**
	 * Called when a page fails to render.
	 */
	onRenderError = (error: any): void => {
		if (isCancelException(error)) {
			return
		}

		console.error(error)

		const { onRenderError } = this.props
		onRenderError && onRenderError(error)
	}

	get renderViewport() {
		const { page, rotate, scale } = this.props

		const pixelRatio = getPixelRatio()

		return page.getViewport({ scale: scale * pixelRatio, rotation: rotate })
	}

	get viewport() {
		const { page, rotate, scale } = this.props

		return page.getViewport({ scale, rotation: rotate })
	}

	drawPageOnCanvas = () => {
		this.onRenderStart()
		const { canvasLayer: canvas } = this

		if (!canvas) {
			return null
		}

		const { renderViewport, viewport } = this
		const { page, renderInteractiveForms } = this.props

		canvas.width = renderViewport.width
		canvas.height = renderViewport.height

		canvas.style.width = `${Math.floor(viewport.width)}px`
		canvas.style.height = `${Math.floor(viewport.height)}px`

		const renderContext = {
			get canvasContext() {
				return canvas.getContext('2d')
			},
			viewport: renderViewport,
			renderInteractiveForms
		}

		// If another render is in progress, let's cancel it
		this.cancelRenderingTask()

		this.renderer = page.render(renderContext)

		return this.renderer.promise.then(this.onRenderSuccess).catch(this.onRenderError)
	}

	render(): React.ReactNode {
		return (
			<canvas
				className="react-pdf__Page__canvas"
				style={{
					display: 'block',
					userSelect: 'none'
				}}
				ref={(ref): void => {
					this.canvasLayer = ref
				}}
			/>
		)
	}
}

export default function PageCanvas(props: any) {
	return <PageContext.Consumer>{(context: any) => <PageCanvasInternal {...context} {...props} />}</PageContext.Consumer>
}
