export interface TranslationItem {
	key: string
	englishText: string
	targetLangText: string
}

export const langKeys = [
	'af',
	'sq',
	'ar',
	'bg',
	'ca',
	'hr',
	'cs',
	'da',
	'nl',
	'en',
	'et',
	'fo',
	'fi',
	'fr',
	'gl',
	'de',
	'el',
	'he',
	'hu',
	'is',
	'ga',
	'it',
	'kk',
	'ky',
	'lv',
	'lt',
	'mk',
	'nb',
	'pl',
	'pt',
	'ro',
	'ru',
	'sr',
	'sk',
	'sl',
	'es',
	'sv',
	'zh',
	'tg',
	'tr',
	'th',
	'tk',
	'uk',
	'cy',
	'uz'
] as const

export const isLangKey = (key: string): key is (typeof langKeys)[number] => langKeys.includes(key as any)
export type LangKeysType = (typeof langKeys)[number]

export interface SubmitTranslationInterface {
	key: string
	textFrom: string
	textTo: string
}
export interface SubmitTranslationReqInterface {
	translations: SubmitTranslationInterface[]
}
export interface TranslationRequestData {
	id: string
	status: 'pr-pending' | 'pr-active'
	lang: string
	requestedTranslations: SubmitTranslationInterface[]
}
