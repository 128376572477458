import { DrivePath } from '@lynx/client-core/src/api/interfaces'
import { Reducer } from 'redux'
import { initialDriveState } from './initialState'
import {
	CHANGE_DRIVE,
	CHANGE_DRIVE_STYLE,
	SELECT_DRIVEITEM,
	DRIVE_RESPONSE,
	DriveActionTypes,
	DriveState,
	POP_DRIVE_PATH,
	PUSH_DRIVE_PATH,
	REFRESH_DRIVE_PATH,
	MAKE_DRIVE_READONLY,
	MAKE_DRIVE_WRITEABLE,
	SHOW_DRIVE_BROWSER,
	HIDE_DRIVE_BROWSER,
	DESELECT_DRIVEITEM,
	SET_HOME_DRIVE,
	CLEAR_DRIVE,
	UPDATE_DRIVE_ITEMS,
	UPDATE_NEXT_PAGE_STATE,
	UPDATE_DRIVE_ITEM,
	DELETE_DRIVE_ITEM,
	ADD_UPLOADING_ITEM,
	DELETE_UPLOADING_ITEM,
	UPDATE_UPLOADING_ITEM,
	UPDATE_DRIVE_SIZE,
	UPDATE_UPLOADING_ITEMS,
	SET_DRIVES_SIZES,
	SET_FAVOURITES,
	ADD_FAVOURITE,
	REMOVE_FAVOURITE,
	SET_DRIVES,
	SET_DISALLOWED_LINK_PROVIDERS
} from './types'

const reducer: Reducer<DriveState, DriveActionTypes> = (state: DriveState = initialDriveState, action: DriveActionTypes) => {
	switch (action.type) {
		case MAKE_DRIVE_READONLY: {
			return {
				...state,
				writeable: false
			}
		}
		case MAKE_DRIVE_WRITEABLE: {
			return {
				...state,
				writeable: true
			}
		}
		case SET_HOME_DRIVE: {
			return {
				...state,
				...action.payload
			}
		}
		case HIDE_DRIVE_BROWSER: {
			return {
				...state,
				popup: false
			}
		}
		case SHOW_DRIVE_BROWSER: {
			return {
				...state,
				popup: true
			}
		}
		case DESELECT_DRIVEITEM: {
			return {
				...state,
				selectedDriveItem: null
			}
		}
		case SELECT_DRIVEITEM: {
			return {
				...state,
				selectedDriveItem: action.payload.driveItem
			}
		}
		case DRIVE_RESPONSE: {
			return {
				...state,
				response: {
					OK: action.payload.OK,
					message: action.payload.message
				},
				provider: action.payload.provider
			}
		}
		case CHANGE_DRIVE: {
			const { data, driveStyle } = action.payload
			const { id, name, children, nextPageToken } = data

			return {
				...state,
				...{
					response: {
						OK: true,
						message: 'OK'
					},
					provider: action.payload.provider,
					currentPath: { name, id },
					paths: [{ name, id }],
					data: { id, name, children, nextPageToken },
					style: driveStyle
				}
			}
		}
		case CHANGE_DRIVE_STYLE: {
			const style = action.payload
			return {
				...state,
				style
			}
		}
		case PUSH_DRIVE_PATH: {
			const { provider } = action.payload
			const { id, name, children, nextPageToken, additionalData } = action.payload.data
			const paths: DrivePath[] = [...state.paths]
			paths.push({ name: name, id: id, additionalData })

			return {
				...state,
				...{
					response: {
						OK: true,
						message: 'OK'
					},
					provider,
					currentPath: { name, id },
					paths: paths,
					data: { id, name, children, nextPageToken }
				}
			}
		}
		case POP_DRIVE_PATH: {
			const { paths } = action.payload
			const { name, id, children, nextPageToken } = action.payload.data

			return {
				...state,
				...{
					response: {
						OK: true,
						message: 'OK'
					},
					provider: action.payload.provider,
					currentPath: { name, id },
					paths,
					data: { id, name, children, nextPageToken }
				}
			}
		}
		case REFRESH_DRIVE_PATH: {
			const drive = { ...state, ...{ data: action.payload.data } }
			return {
				...state,
				...drive
			}
		}

		case CLEAR_DRIVE: {
			return {
				...state,
				...initialDriveState
			}
		}

		case UPDATE_DRIVE_ITEMS: {
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			}
		}

		case UPDATE_DRIVE_ITEM: {
			const { item, originalId } = action.payload

			const updatedChildren = state.data.children.map((mapItem) => {
				if (mapItem.id === originalId) return { ...mapItem, ...item }
				return mapItem
			})

			return {
				...state,
				data: {
					...state.data,
					children: updatedChildren
				}
			}
		}

		case DELETE_DRIVE_ITEM: {
			const updatedChildren = state.data.children.filter((item) => item.id !== action.payload.id)

			return {
				...state,
				data: {
					...state.data,
					children: updatedChildren
				}
			}
		}

		case UPDATE_NEXT_PAGE_STATE: {
			return {
				...state,
				...action.payload
			}
		}

		case UPDATE_DRIVE_SIZE: {
			const { provider, driveId } = action.payload

			const updatedDriveUsage = state.drivesSizes.map((item) => {
				if (driveId ? item.driveId === driveId : provider === item.provider) return action.payload
				return item
			})

			return {
				...state,
				drivesSizes: updatedDriveUsage
			}
		}
		case SET_DRIVES_SIZES: {
			return {
				...state,
				drivesSizes: action.payload
			}
		}

		case SET_FAVOURITES: {
			return {
				...state,
				favourites: action.payload
			}
		}

		case ADD_FAVOURITE: {
			const updatedFavourites = [...state.favourites, action.payload]
			return {
				...state,
				favourites: updatedFavourites
			}
		}

		case REMOVE_FAVOURITE: {
			const updatedFavourites = state.favourites.filter((item) => item.itemId !== action.payload.itemId)
			return {
				...state,
				favourites: updatedFavourites
			}
		}

		case DELETE_UPLOADING_ITEM: {
			const updatedUploadingItems = state.uploadingItems.filter((item) => item.id !== action.payload.id)

			return {
				...state,
				uploadingItems: updatedUploadingItems
			}
		}

		case UPDATE_UPLOADING_ITEM: {
			const updatedUploadingItems = state.uploadingItems.map(({ id, ...rest }) =>
				id === action.payload.id ? { ...rest, ...action.payload } : { id, ...rest }
			)
			return {
				...state,
				uploadingItems: updatedUploadingItems
			}
		}

		case UPDATE_UPLOADING_ITEMS: {
			return {
				...state,
				uploadingItems: action.payload
			}
		}

		case ADD_UPLOADING_ITEM: {
			return {
				...state,
				uploadingItems: [action.payload, ...state.uploadingItems]
			}
		}

		case SET_DRIVES: {
			return {
				...state,
				drives: action.payload
			}
		}

		case SET_DISALLOWED_LINK_PROVIDERS: {
			return {
				...state,
				disallowedLinkProviders: action.payload
			}
		}

		default: {
			return state
		}
	}
}

export { reducer as driveReducer }
