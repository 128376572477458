import css from './LoadingSpinner.module.scss'
import React from 'react'
import { LoadingSpinnerProps } from './interfaces'

export const LoadingSpinner = ({ size = 'md', variant = 'default', className }: LoadingSpinnerProps): React.ReactElement => {
	const sizeMap = {
		xs: 'xs',
		sm: 'sm',
		md: 'md',
		lg: 'lg'
	}
	const classes = [css.container, css[sizeMap[size]], css[variant]]
	if (className) classes.push(className)

	return <div className={classes.join(' ')} />
}
