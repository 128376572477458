import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, CheckCircleOutlineIcon, SharePointIcon } from '@lynx/client-core/src/components'
import { sleepAsync } from '@lynx/core'
import React from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteNotification, deleteNotificationToast } from 'store/notifications'
import { NotificationVariantsProps } from './interfaces'
import css from './NotificationVariants.module.scss'
import { DefaultTemplate } from './NotificationVariantsDefaultTemplate'
import { useThunkDispatch } from 'hooks'
import { changeDrive } from 'store/drive'
import { enableOneDriveSharePoints } from '@lynx/core/src/interfaces'
import { Link } from 'react-router-dom'
import { Logger } from '@lynx/client-core/src/modules'

export const NotificationVariantEnableSharePointDrive = ({
	createdAt,
	notification,
	isViewed = false,
	onNotificationClick,
	isToast = false
}: NotificationVariantsProps): React.ReactElement => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const [isSuccessIcon, setIsSuccessIcon] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(false)
	const [showRedirectLink, setShowRedirectLink] = React.useState(false)
	const isDrivePage = window.location.pathname === '/drive'

	const { notificationId } = notification
	const payload = notification.type === enableOneDriveSharePoints ? notification.payload : null

	const { t } = useTranslation()
	const notificationCleanUp = async (): Promise<void> => {
		if (notificationId) {
			await APIRequest.Notifications.deleteNotification(notificationId)
			dispatch(deleteNotification(notificationId))
		}
		isToast && dispatch(deleteNotificationToast(notification))
	}

	const finalAnimation = async (): Promise<void> => {
		setIsSuccessIcon(true)
		await sleepAsync(6000)
	}

	const enableSharePoints = async (): Promise<void> => {
		if (!payload) return
		ReactGA.event({
			action: `Enable SharePoint Drives [action:clicked]`,
			category: 'Notification'
		})
		try {
			await APIRequest.ObjectStore.updateDriveSettings('onedrive', payload.oneDriveId, { sharePointEnabled: true })
		} catch (err) {
			Logger.warn('error updating drive')
		}
		if (isDrivePage) await dispatchThunk(changeDrive(null))
	}

	const onBtnClick = async (): Promise<void> => {
		try {
			setIsLoading(true)
			await enableSharePoints()
			if (!isDrivePage) setShowRedirectLink(true)
			await finalAnimation()
		} finally {
			setIsLoading(false)
			await notificationCleanUp()
		}
	}

	const onDriveRootNav = async (): Promise<void> => {
		if (window.location.pathname.startsWith('/drive')) {
			await dispatchThunk(changeDrive(null))
		}
	}

	const defaultProps = {
		createdAt,
		isToast,
		isViewed,
		onNotificationClick
	}

	return (
		<DefaultTemplate
			{...defaultProps}
			variant="information"
			customIcon={SharePointIcon}
			isNotificationClickDisable={true}
			notification={notification}
			isAlignCenter={false}
		>
			<div className={css.enableSharePointsContainer}>
				{t('components.notifications.notificationVariants.accessSharepoints')}
				<br />
				{t('components.notifications.notificationVariants.clickEnableSharepoint')}
				<div className={css.controlsWrapper}>
					<div>
						{showRedirectLink && (
							<Link to="/drive" className={css.link} onClick={onDriveRootNav}>
								{t('components.notifications.notificationVariants.seeDrives')}
							</Link>
						)}
					</div>
					<div className={css.buttons}>
						{isSuccessIcon ? (
							<CheckCircleOutlineIcon className={css.successIcon} />
						) : (
							<BasicButton isLoading={isLoading} variant="light" size="xs" onClick={onBtnClick}>
								{t('components.notifications.notificationVariants.enable')}
							</BasicButton>
						)}
						<BasicButton disabled={isLoading} onClick={notificationCleanUp} size="xs" className={css.cancelBtn}>
							{t('components.notifications.notificationVariants.cancel')}
						</BasicButton>
					</div>
				</div>
			</div>
		</DefaultTemplate>
	)
}
