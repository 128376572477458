import * as React from 'react'
import Styles from './PrivacyNotice.module.css'
import i18next from 'i18next'

export class PrivacyNotice extends React.Component {
	render(): React.ReactNode {
		return (
			<div className={Styles['container']}>
				<div>
					<div className={Styles['header']}>
						<span>{i18next.t('pages.login.radioShared')}</span>
						<span> {`(${i18next.t('pages.login.checked')})`}</span>
					</div>
					{i18next.t('pages.login.sharedMessage')}
				</div>
				<div>
					<div className={Styles['header']}>
						<span>{i18next.t('pages.login.radioPrivate')}</span>
						<span> {`(${i18next.t('pages.login.unchecked')})`}</span>
					</div>
					{i18next.t('pages.login.privateMessage')}
				</div>
			</div>
		)
	}
}
