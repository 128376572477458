import { Input } from '@lynx/client-core/src/components'
import React from 'react'
import css from './ListShared.module.scss'
import { Plan } from '@lynx/client-core/src/api/Billing/Billing'

export const renderStorageElement = ({
	id,
	storageLimitGB,
	editableRowId,
	setEditedStorage,
	isLoading,
	planStorageLimit,
	editedStorage
}: {
	id: number | string
	storageLimitGB: number | null
	editableRowId: number | string | null
	planStorageLimit: number | undefined
	isLoading: boolean
	editedStorage: number | null
	setEditedStorage: (value: number) => void
}): React.ReactNode => {
	if (id === editableRowId) {
		return (
			<Input
				className={css.input}
				disabled={isLoading}
				type="number"
				value={String(editedStorage || storageLimitGB || planStorageLimit)}
				onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setEditedStorage(Number(e.target.value))}
			/>
		)
	}
	const isDifferentThenDefault = typeof planStorageLimit === 'number' && typeof storageLimitGB === 'number' && storageLimitGB !== planStorageLimit
	return (
		<div style={{ color: isDifferentThenDefault ? 'yellow' : '' }}>
			{storageLimitGB || planStorageLimit} GB {!isDifferentThenDefault && <span className={css.defaultStorageValue}>(default)</span>}
		</div>
	)
}

export const useSubscriptionPlan = (
	plans: Plan[],
	type: 'pro' | 'organisation'
): {
	getPlanStorageLimit: (isPro: boolean) => number
} => {
	const proPlan = plans.find((p) => p.subscriptionType === type)
	const freePlan = plans.find((p) => p.subscriptionType === 'free')
	const proPlanStorageLimit = proPlan?.storageLimitGB
	const freePlanStorageLimit = freePlan?.storageLimitGB
	const getPlanStorageLimit = (isPro: boolean): number => (isPro ? proPlanStorageLimit : freePlanStorageLimit) || 0

	return { getPlanStorageLimit }
}

export const getTableSearchParam = (): string => {
	const url = new URL(window.location.toString())
	return url.searchParams.get('tableSearch') || ''
}

export const OptionsContainer = ({ children }: { children: React.ReactNode }): React.ReactElement => {
	return <div className={css.optionsContainer}>{children}</div>
}
