import * as React from 'react'
import { Popup } from './Popup'
import css from './Overlay.module.css'
import { useState, useEffect } from 'react'
import { OverlayProps } from './interfaces'

const localToGlobal = (_el: HTMLDivElement, scrollTop: number): any => {
	const target = _el,
		target_width = target.offsetWidth,
		target_height = target.offsetHeight

	let rect = {}
	let gleft = 0
	let gtop = 0

	const traverseParents = function (_parent: any): void {
		if (_parent) {
			gleft += _parent.offsetLeft
			gtop += _parent.offsetTop - scrollTop / 2

			traverseParents(_parent.offsetParent)
		} else {
			rect = {
				top: target.offsetTop + gtop,
				left: target.offsetLeft + gleft,
				bottom: target.offsetTop + gtop + target_height,
				right: target.offsetLeft + gleft + target_width
			}
		}
	}

	traverseParents(target.offsetParent)
	return rect
}

export const Overlay = ({
	className,
	scrollableContainer,
	title,
	onMouseOut,
	children,
	popUpClassName,
	useMousePosition = false,
	icon
}: OverlayProps): React.ReactElement => {
	const [open, setOpen] = useState<boolean>(false)
	const [outside, setOutside] = useState<boolean>(false)
	const [location, setLocation] = useState<{ screenX: number; screenY: number } | null>(null)
	const classNames = []
	className && classNames.push(className)
	const popUpClassNames = [css['popup-container']]
	popUpClassName && popUpClassNames.push(popUpClassName)

	const close = (): void => {
		setOpen(false)
	}

	useEffect(() => {
		window.addEventListener('resize', close)

		return (): void => {
			window.removeEventListener('resize', close)
		}
	}, [])

	const handleToggleOpen = (evt: React.MouseEvent<HTMLDivElement>): void => {
		evt.preventDefault()
		evt.stopPropagation()
		setOpen(!open)

		const { currentTarget } = evt

		if (useMousePosition) {
			setLocation({ screenX: evt.clientX, screenY: evt.clientY })
			return
		}

		const rect = localToGlobal(currentTarget, scrollableContainer?.current?.scrollTop || 0)
		const clientX = rect.left + (rect.right - rect.left) / 2
		const clientY = rect.top + (rect.bottom - rect.top)

		setLocation({ screenX: clientX, screenY: clientY })
	}

	const handleClickOutside = (): void => {
		setOpen(false)
	}

	const handleMouseOver = (): void => {
		setOutside(false)
	}

	const handleMouseLeave = (): void => {
		onMouseOut && onMouseOut()
		setOutside(true)
	}

	const handleContentClicked = (e: React.MouseEvent<HTMLDivElement>): void => {
		e.stopPropagation()
		e.preventDefault()
		setOpen(false)
	}

	const handleContentScrolled = (e: React.MouseEvent<HTMLDivElement>): void => {
		e.stopPropagation()
	}

	return (
		<div className={classNames.join(' ')}>
			<div style={{ cursor: 'pointer', width: '100%' }} onClick={handleToggleOpen}>
				{icon ? icon : title}
			</div>
			<Popup isOutside={outside} show={open} onClickOutside={handleClickOutside}>
				<div
					onClick={handleContentClicked}
					onWheel={handleContentScrolled}
					onMouseEnter={handleMouseOver}
					style={{ top: location?.screenY, left: location?.screenX }}
					className={popUpClassNames.join(' ')}
					onDrag={onMouseOut}
					onMouseLeave={handleMouseLeave}
				>
					{children}
				</div>
			</Popup>
		</div>
	)
}
