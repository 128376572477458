import { APIConfig } from '../interfaces'
import { BaseCall } from '../BaseCall'
import { Logger } from '../../modules/Logger'
import { Favourite } from '@lynx/core/src/interfaces/ObjectStore'

export class Favourites extends BaseCall {
	public async getFavourites(): Promise<{ favourites: Favourite[] }> {
		try {
			const response = await this.client.get<{ favourites: Favourite[] }>('/favourites')

			return response.data
		} catch (error) {
			Logger.error(error)
			return { favourites: [] }
		}
	}

	public async addFavourite(itemId: string): Promise<boolean> {
		try {
			await this.client.post<boolean>('/favourites', { itemId })

			return true
		} catch (error) {
			Logger.error(error)
			return false
		}
	}

	public async removeFavourite(itemId: string): Promise<boolean> {
		try {
			await this.client.delete<boolean>(`/favourites/${itemId}`)

			return true
		} catch (error) {
			Logger.error(error)
			return false
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
