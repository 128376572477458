import { BaseCall } from '../BaseCall'
import { APIConfig, APIResponse } from '../interfaces'
import { Site } from '@lynx/core/src/interfaces/Sites'
import { Logger } from '../../modules/Logger'
import { APIQueryParams } from '@lynx/core/src/interfaces'

export class Sites extends BaseCall {
	public createSite = async (data: Site): Promise<APIResponse<Site> | null> => {
		const response = await this.client.post<APIResponse<Site> | null>(`/sites`, data)

		return response.data
	}

	public updateSite = async (key: string, data: Site): Promise<APIResponse | null> => {
		try {
			const response = await this.client.put<APIResponse>(`/sites/${key}`, data)

			return response.data
		} catch (error) {
			Logger.error(error)
			return null
		}
	}

	public deleteSite = async (id: string): Promise<APIResponse | null> => {
		try {
			const response = await this.client.delete<APIResponse>(`/sites/${id}`)

			return response.data
		} catch (error) {
			Logger.error(error)
			return null
		}
	}

	public getAll = async (): Promise<APIResponse<Site[]>> => {
		const response = await this.client.get<APIResponse<Site[]>>('/sites/all', {})
		return response.data
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
