import css from './SkeletonBone.module.scss'
import React, { HTMLAttributes } from 'react'

interface Props {
	className?: string
	style?: HTMLAttributes<HTMLDivElement>['style']
}

export const SkeletonBone = ({ className, style }: Props): React.ReactElement => {
	const classNames = [css.skeletonBone]
	className && classNames.push(className)

	return <div className={classNames.join(' ')} style={style} />
}
