import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setProfilePicture } from '../store/profile'
import { AuthCookie } from '../modules'

export const useFetchAvatar = (): void => {
	const { profile, config } = useSelector((state: ApplicationState) => state)
	const { gravatarUrl, avatarBlobUrl } = profile
	const dispatch = useDispatch()

	const fetchAvatar = async (): Promise<void> => {
		if (gravatarUrl && !avatarBlobUrl) {
			const { ENVIRONMENT } = config
			const accessToken = AuthCookie.getAccessToken(ENVIRONMENT)

			const res = await fetch(gravatarUrl, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
			const isDefaultAvatar = res.headers.get('Content-Disposition')?.endsWith('default-avatar.png')
			if (isDefaultAvatar) return
			const blob = await res.blob()
			const bloburl = URL.createObjectURL(blob)
			dispatch(setProfilePicture({ avatarBlobUrl: bloburl, url: gravatarUrl }))
		}
	}

	useEffect(() => {
		fetchAvatar()
	}, [gravatarUrl, avatarBlobUrl])
}
