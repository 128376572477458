import {
	Document,
	FeatureSection,
	FooterItem,
	FooterLinksItem,
	MoreDownloadsItem,
	MoreDownloadsItemProps,
	MoreDownloadsLaSrc,
	MoreDownloadsLsrSrc,
	MoreDownloadsLssSrc,
	MoreDownloadsLwSrc,
	MoreDownloadsTable
} from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ApplicationState } from 'store'
import css from './MoreDownloads.module.scss'

export const MoreDownloads = (): React.ReactElement => {
	const navigate = useNavigate()
	const { config } = useSelector((state: ApplicationState) => state)

	const cards: MoreDownloadsItemProps[] = [
		{
			icon: 'whiteboard',
			application: 'whiteboard',
			headerTitle: i18next.t('pages.moreDownloads.card1.headerTitle'),
			headerImage: MoreDownloadsLwSrc,
			bulletPoints: [
				i18next.t('pages.moreDownloads.card1.bulletPoints.a'),
				i18next.t('pages.moreDownloads.card1.bulletPoints.b'),
				i18next.t('pages.moreDownloads.card1.bulletPoints.c')
			],
			bulletPointsTintColor: '#AF66FF',
			downloadLink: '',
			ctaButtonClick: () => navigate('/')
		},
		{
			icon: 'activities',
			application: 'activities',
			headerTitle: i18next.t('pages.moreDownloads.card2.headerTitle'),
			headerImage: MoreDownloadsLaSrc,
			bulletPoints: [
				i18next.t('pages.moreDownloads.card2.bulletPoints.a'),
				i18next.t('pages.moreDownloads.card2.bulletPoints.b'),
				i18next.t('pages.moreDownloads.card2.bulletPoints.c')
			],
			bulletPointsTintColor: '#00C5FF',
			downloadLink: config.LYNXACTIVITIES_CLIENT,
			ctaButtonClick: () => navigate('/download-activities')
		},
		{
			icon: 'share',
			application: 'screenshare',
			headerTitle: i18next.t('pages.moreDownloads.card3.headerTitle'),
			headerImage: MoreDownloadsLssSrc,
			bulletPoints: [
				i18next.t('pages.moreDownloads.card3.bulletPoints.a'),
				i18next.t('pages.moreDownloads.card3.bulletPoints.b'),
				i18next.t('pages.moreDownloads.card3.bulletPoints.c')
			],
			bulletPointsTintColor: '#28C283',
			downloadLink: '',
			ctaButtonClick: () => navigate('/download-screenshare')
		},
		{
			icon: 'recorder',
			application: 'recorder',
			headerTitle: i18next.t('pages.moreDownloads.card4.headerTitle'),
			headerImage: MoreDownloadsLsrSrc,
			bulletPoints: [
				i18next.t('pages.moreDownloads.card4.bulletPoints.a'),
				i18next.t('pages.moreDownloads.card4.bulletPoints.b'),
				i18next.t('pages.moreDownloads.card4.bulletPoints.c')
			],
			bulletPointsTintColor: '#28C283',
			downloadLink: '',
			ctaButtonClick: () => navigate('/download-recorder')
		}
	]

	return (
		<Document title={i18next.t('pages.pageTitles.downloads')} stableScrollBarGutter={true}>
			<div className={[css['containerMoreDownloads'], ''].join(' ')}>
				<div className={css['pageTitleRow']}>
					<h1 className={css['pageTitle']}>{i18next.t('pages.moreDownloads.pageTitle')}</h1>
				</div>

				<div className={css['cards']}>
					{cards
						.filter((c) => c.icon !== 'share')
						.map((card, index) => (
							<MoreDownloadsItem key={index} {...card} />
						))}
				</div>

				<div className={css['pageTitleRow']}>
					<h1 className={css['pageTitle']}>{i18next.t('pages.moreDownloads.allDownloads')}</h1>
				</div>

				<MoreDownloadsTable />
			</div>
			<FeatureSection backgroundColor="#F6F6F6" height="auto">
				<FooterItem />
				<FooterLinksItem />
			</FeatureSection>
		</Document>
	)
}
