import { AutocompleteInput, BasicButton, Input, Modal, SendIcon } from '@lynx/client-core/src/components'
import { showModal } from '@lynx/client-core/src/store/modal'
import { OrganisationGroupsInterface } from '@lynx/core/src/interfaces/Organisation'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { InvitePeopleModalContextType, ModalContext } from 'store/modal'
import { sendInvite } from 'store/organisations'
import css from './InvitePeopleModal.module.scss'

export const InvitePeopleModal = (): React.ReactElement => {
	const [email, setEmail] = useState<string>('')
	const [message, setMessage] = useState<string>('')
	const organisation = useCurrentOrganisation()
	const [selectedGroup, setSelectedGroup] = useState<OrganisationGroupsInterface | null>(null)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const handleSendInviteClicked = async (): Promise<void> => {
		if (!organisation) return
		setIsLoading(true)
		await dispatchThunk(sendInvite(organisation.id, email, selectedGroup?.id || null, message))

		dispatch(showModal({ name: 'InviteSentModal' }))
		setIsLoading(false)
	}

	const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setEmail(e.target.value)
	}

	const handleMessageChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setMessage(e.target.value)
	}

	const handleModalHidden = (): void => {
		setSelectedGroup(null)
		setEmail('')
		setMessage('')
	}

	const handleBulkCreateAccountsClicked = (): void => {
		dispatch(showModal({ name: 'BulkImportPeopleModal' }))
	}

	const handleVisible = (context: ModalContext): void => {
		const targetGroup = (context?.type === InvitePeopleModalContextType && organisation?.groups.find((g) => g.id === context.groupId)) || null
		setSelectedGroup(targetGroup)
	}

	return (
		<Modal name="InvitePeopleModal" title="Invite People" onHidden={handleModalHidden} onVisible={handleVisible}>
			<div className={css['container']}>
				<Input
					label={t('pages.organisations.sendTo')}
					placeholder={t('components.modals.sharingModal.typeEmailThenEnter')}
					onChange={handleEmailChanged}
					value={email}
				/>
				<AutocompleteInput
					keyPath="groupName"
					selectedItem={selectedGroup}
					items={organisation?.groups || []}
					inputLabel={t('components.modals.sharingModal.group')}
					onItemSelected={setSelectedGroup}
				/>
				<div className={css['container__message']}>
					<Input
						placeholder={t('components.modals.sharingModal.typeYourMessage')}
						onChange={handleMessageChanged}
						label={t('pages.organisations.message')}
					/>
					<BasicButton variant="blue" onClick={handleSendInviteClicked} isLoading={isLoading} icon={SendIcon} />
				</div>
				<hr />
				<div className={css['bulk']}>
					<h4>{t('pages.organisations.createLots')}</h4>
					<BasicButton variant="grey" onClick={handleBulkCreateAccountsClicked}>
						{t('pages.organisations.bulkCreate')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
