import { languages } from './languagesList'
import i18next from 'i18next'
import { APIRequest } from '../../api'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { setLang } from '../../store/profile'
import { Dropdown } from '../'
import css from './LanguageDropdown.module.scss'

interface LanguageDropdownProps {
	openedClassName?: string
}
i18next.on('languageChanged', (lng: string) => {
	APIRequest.setDefaultHeaders({ 'accept-language': lng })
})

export const LanguageDropdown = ({ openedClassName }: LanguageDropdownProps): React.ReactElement => {
	const dispatch = useDispatch()
	const profileState = useSelector((state: ApplicationState) => state.profile)

	const selectLang = async (lang: string): Promise<void> => {
		i18next.changeLanguage(lang) // Set i18next
		dispatch(setLang(lang))

		if (profileState.loggedIn) {
			await APIRequest.User.changeLang(lang)
			APIRequest.setDefaultHeaders({ 'accept-language': lang })
		}
	}

	const onSelectLanguage = async (value: string): Promise<void> => {
		const v = value || 'en'
		await selectLang(v)
	}

	const getBrowserLanguage = (): string => {
		const lang = profileState.lang || navigator.language || 'en'

		if (lang.includes('-')) {
			return lang.split('-')[0]
		}
		return lang
	}

	const languageLabel = languages.find((l) => l.value === getBrowserLanguage())?.label.split(' ')[0] || 'English'
	const items = languages.map((lang: { label: string; value: string }, key: number) => {
		return (
			<li
				key={key}
				value={lang.value}
				onClick={(): void => {
					onSelectLanguage(lang.value)
				}}
			>
				{lang.label}
			</li>
		)
	})

	const openedClassNameClasses = [css.openedClassName]
	openedClassName && openedClassNameClasses.push(openedClassName)
	return (
		<Dropdown direction="up" className={css.genericSelectList} title={languageLabel} chevron openedClassName={openedClassNameClasses.join(' ')}>
			{items}
		</Dropdown>
	)
}
