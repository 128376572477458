import { APIRequest } from '@lynx/client-core/src/api'
import { AccessType, DriveItemType, ShareType, DriveItemShare } from '@lynx/client-core/src/api/interfaces'
import { Logger } from '@lynx/client-core/src/modules'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ApplicationState } from 'store'
import { LOAD_SHARE, LOAD_CODE_URL, SharingActionTypes, UNLOAD_SHARES } from './types'

export const loadShareInternal = (driveItem: DriveItemType, share: DriveItemShare): SharingActionTypes => {
	return {
		type: LOAD_SHARE,
		payload: {
			driveItem,
			share
		}
	}
}

export const loadCodeUrl = (codeUrl: string): SharingActionTypes => {
	return {
		type: LOAD_CODE_URL,
		payload: {
			codeUrl
		}
	}
}

export const unloadShares = (): SharingActionTypes => {
	return {
		type: UNLOAD_SHARES
	}
}

export const removeShareUser =
	(userId: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch, getState: () => ApplicationState): Promise<boolean> => {
		try {
			const { sharing } = getState()
			if (!sharing.driveItem?.id) {
				throw new Error('drive item on sharing state not set')
			}
			const { driveItem } = sharing

			const response = await APIRequest.Sharing.removeUser(driveItem.id, userId)
			if (!response?.data) {
				throw new Error('missing share data')
			}

			const share = response.data

			dispatch(loadShareInternal(driveItem, share))
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const inviteShare =
	(email: string, shareType: ShareType, message?: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch, getState: () => ApplicationState): Promise<boolean> => {
		try {
			const { sharing } = getState()
			if (!sharing.driveItem?.id) {
				throw new Error('drive item on sharing state not set')
			}

			const { driveItem } = sharing

			const response = await APIRequest.Sharing.invite(driveItem.id, email, shareType, message)
			if (!response?.data) {
				throw new Error('missing share data')
			}

			const share = response.data

			dispatch(loadShareInternal(driveItem, share))
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const updateAccessType =
	(accessType: AccessType): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch, getState: () => ApplicationState): Promise<boolean> => {
		try {
			const { sharing } = getState()
			if (!sharing.driveItem?.id) {
				throw new Error('drive item on sharing state not set')
			}

			const response = await APIRequest.Sharing.updateAccessType(sharing.driveItem.id, accessType)
			if (!response?.data) {
				throw new Error('missing share data')
			}

			const share = response.data
			dispatch(loadShareInternal(sharing.driveItem, share))

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const loadShare =
	(driveItem: DriveItemType): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			const response = await APIRequest.Sharing.get(driveItem.id)
			if (!response?.data) {
				throw new Error('missing share data')
			}
			const share = response.data

			const codeUrlResponse = await APIRequest.Sharing.getCode(share.token)
			codeUrlResponse?.data && dispatch(loadCodeUrl(codeUrlResponse.data.url))

			dispatch(loadShareInternal(driveItem, share))

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const updateSharePermission =
	(userId: string, permission: ShareType): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch, getState: () => ApplicationState): Promise<boolean> => {
		try {
			const { sharing } = getState()
			if (!sharing.driveItem?.id) {
				throw new Error('drive item on sharing state not set')
			}
			const response = await APIRequest.Sharing.updatePermission(sharing.driveItem?.id, userId, permission)
			if (!response?.data) {
				throw new Error('missing share data')
			}

			const share = response.data
			dispatch(loadShareInternal(sharing.driveItem, share))

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}
