import { BasicButton, Textarea } from '@lynx/client-core/src/components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './SamlDomain.module.css'

interface SamlProps {
	saml: {
		issuer: string
		certificate: string
		entrypoint: string
		domains?: string[]
	}
	isUCS: boolean
}

export interface SamlDomainProps {
	navigate: (section: string, props: SamlProps | null) => void
	context: SamlProps
}

export const SamlDomain = (props: SamlDomainProps): React.ReactElement => {
	const [domains, setDomains] = useState<string[]>([])
	const { t } = useTranslation()

	const handleNext = (): void => {
		const { saml, isUCS } = props.context
		props.navigate('OrganisationDetails', {
			saml: {
				...saml,
				domains
			},
			isUCS
		})
	}

	return (
		<div className={css['container']}>
			<p>{t('pages.organisation.samlDomain')}</p>
			<Textarea
				onChange={(e): void => {
					setDomains(e.target.value.split('\n'))
				}}
			/>
			<div className={css['buttons']}>
				<BasicButton
					onClick={(): void => {
						props.navigate('SamlSetup', {
							saml: props.context.saml,
							isUCS: props.context.isUCS
						})
					}}
				>
					{t('pages.organisations.createModal.back')}
				</BasicButton>
				<BasicButton variant="blue" disabled={!domains} onClick={handleNext}>
					{t('pages.organisations.createModal.next')}
				</BasicButton>
			</div>
		</div>
	)
}
