import * as React from 'react'
import css from './FileDropper.module.css'

export interface FileDropperProps {
	onDropped: (files: FileList) => void
	active: boolean
	onEnded: () => void
	onDropNoFiles?: (e: React.DragEvent<HTMLDivElement>) => void
}

export const FileDropper = ({ onDropped, active, onEnded, onDropNoFiles }: FileDropperProps): React.ReactElement => {
	const handleDrag = (e: React.DragEvent<HTMLDivElement>): void => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDragIn = (e: React.DragEvent<HTMLDivElement>): void => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDragOut = (e: React.DragEvent<HTMLDivElement>): void => {
		e.preventDefault()
		e.stopPropagation()
		onEnded()
	}

	const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
		e.preventDefault()
		e.stopPropagation()
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			onDropped(e.dataTransfer.files)
			onEnded()
		} else {
			onDropNoFiles && onDropNoFiles(e)
		}
	}

	return (
		<div
			className={css['container']}
			style={active ? { display: 'block' } : { display: 'none' }}
			onDragEnter={handleDragIn}
			onDragLeave={handleDragOut}
			onDragOver={handleDrag}
			onDrop={handleDrop}
		></div>
	)
}
