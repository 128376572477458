import { ArrowBackIcon } from '../../../../Icons'
import { Section } from '../../../../Sections'
import { BasicButton, Input } from '../../../../'
import { useThunkDispatch } from '../../../../../hooks/useThunkDispatch'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import * as qs from 'query-string'
import * as React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { login } from '../../../../../store/profile'
import css from './EmailSection.module.css'
import { QueryParams } from './interfaces'
import { EmailSectionProps } from './interfaces/EmailSectionProps'
import { APIRequest } from '../../../../../api'
import { useSearchParams } from 'react-router-dom'

export const EmailSection = ({ navigate: navigateSection, context }: EmailSectionProps): React.ReactElement => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const [state, setState] = useState({
		email: '',
		password: '',
		queryParams: qs.parse(location.search) as any,
		error: '',
		nextStep: false,
		privacyChecked: context.privacyChecked
	})
	const [showPassword, setShowPassword] = useState(false)
	const [query] = useSearchParams()

	const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const email = event.target.value
		setState({ ...state, email })
	}

	const handlePasswordChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const password = event.target.value
		setState({ ...state, password })
	}

	const handleSignUp = (): void => {
		navigateSection('SignUpSection')
	}

	const handleGoBack = (): void => {
		navigateSection('SignInSection')
	}

	const handlePrevious = (): void => {
		setState({ ...state, nextStep: false, error: '' })
	}

	const handleRecoverPassword = (): void => {
		navigateSection('RecoverPasswordSection')
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault()
		event.stopPropagation()
		const username = state.email
		const { password } = state

		if (!username || !password) {
			return
		}

		const result = await dispatchThunk(
			login({ username, password, scheme: state.queryParams?.scheme, code: state.queryParams?.code, rememberMe: !state.privacyChecked })
		)

		switch (result) {
			case 'A user already exists using the email address provided':
				break
		}

		// check if user have network connection
		if (!navigator.onLine) {
			setState({ ...state, error: i18next.t('pages.login.noInternetConnection') })
			return
		}

		if (!result || result === 'Invalid password' || result === 'User does not exist' || result === 'Cannot authenticate user') {
			setState({ ...state, error: i18next.t('pages.login.usernamePasswordIncorrect') })
			return
		}

		if (result === 'Email has not been verified') {
			setState({ ...state, error: i18next.t('pages.login.notVerified') })
			return
		}

		const { redirect, qt, code, qrCode, scheme } = state.queryParams

		// Handle redirects if necessary
		if (redirect?.length) {
			if (redirect.includes('http')) {
				window.location.href = redirect
				return
			}
			navigate(redirect)
			return
		}

		// Handle qt redirect
		if (qt || qrCode) {
			const queryObj: QueryParams = {
				header: false,
				footer: false,
				rememberMe: !state.privacyChecked,
				code,
				scheme
			}

			if (qrCode) {
				queryObj.qrCode = qrCode
				queryObj.remote = true
			}
			const qrParams = qs.stringify(queryObj)
			navigate(`/authenticated?${qrParams}`)
			return
		}

		if (location.pathname === '/auth/login') {
			navigate('/')
		}
	}

	const nextCloudLogin = async (pollToken: string, endpoint: string, authUrl: string): Promise<void> => {
		window.open(authUrl, '_blank')
		let count = 0
		const interval = setInterval(async (): Promise<void> => {
			const result = await APIRequest.User.NextCloudPoll(pollToken, endpoint)
			++count
			if (count > 10) {
				clearInterval(interval)
			}
			if (!result.error) {
				clearInterval(interval)
				await dispatchThunk(login(null))

				const { redirect, qt, code, qrCode } = state.queryParams

				if (redirect?.length) {
					if (redirect.includes('http')) {
						window.location.href = redirect
						return
					}
					navigate(redirect)
					return
				}

				if (qt?.length) {
					const queryObj: QueryParams = {
						header: false,
						footer: false,
						rememberMe: !state.privacyChecked,
						code
					}

					if (qrCode) {
						queryObj.remote = true
						queryObj.qrCode = qrCode
					}

					const qrParams = qs.stringify(queryObj)
					navigate(`/authenticated?${qrParams}`)
					return
				}

				if (location.pathname === '/auth/login') {
					navigate('/')
				}
			}
		}, 5000)
	}

	const getQueryParams = (): string => {
		// Empty object to add new query params
		const queryObj: { [key: string]: string } = {}
		queryObj.rememberMe = String(Boolean(state.privacyChecked))
		queryObj.qrCode = query.get('qrCode') || ''
		const code = query.get('code') || ''
		queryObj.ws = query.get('qt') && code ? code : ''
		queryObj.redirect = window.location.href.replace(window.location.origin, '')
		queryObj.scheme = query.get('scheme') || ''
		return `?${qs.stringify(queryObj)}`
	}

	const check = async (): Promise<void> => {
		try {
			const username = state.email

			if (!username) {
				return
			}

			const result = await APIRequest.User.check(username)
			const { data } = result

			if (!result.error) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const { user, url, type } = data
				switch (type) {
					case 'nextcloud':
						await nextCloudLogin(user.poll.token, user.poll.endpoint, user.login)
						return
					case 'saml':
					case 'oauth':
					case 'entraid':
						window.location.href = `${url}${getQueryParams()}`
						return
				}
				setShowPassword(!showPassword)
			}
		} catch (e) {
			setShowPassword(!showPassword)
		}
	}

	const { error } = state
	const errorClass = error ? css['input-error'] : ''

	return (
		<Section
			side="right"
			title={
				<div className={css['title-container']}>
					<div className={css['back-arrow']} onClick={handleGoBack}>
						<ArrowBackIcon />
					</div>
					<div>{t('pages.login.emailSignIn')}</div>
				</div>
			}
			className={css['email-section']}
		>
			<form className={css['email-section-form']} onSubmit={handleSubmit}>
				{error && <div className={css['error-message']}>{error}</div>}
				<div className={css['inputfields']}>
					<div className={`${showPassword ? css['hidden'] : css['visible']}`}>
						<label>{t('pages.login.email')}</label>
						<Input type="email" name="email" variant="light" autoFocus={true} placeholder={t('pages.login.email')} onChange={handleEmailChanged} />
						<div className={css.buttonContainer}>
							<BasicButton onClick={check} variant="blue">
								{t('pages.login.next')}
							</BasicButton>
						</div>
					</div>
					<div className={`${showPassword ? css['visible'] : css['hidden']}`}>
						<label>{t('pages.login.password')}</label>
						<Input
							type="password"
							name="password"
							variant="light"
							placeholder={t('pages.login.password')}
							className={errorClass}
							onChange={handlePasswordChanged}
						/>
						<div className={css.buttonContainer}>
							<BasicButton type="submit" variant="blue">
								{t('pages.login.signIn')}
							</BasicButton>
						</div>
					</div>
				</div>
				<div className={css['recover-password']}>
					<div
						onClick={handlePrevious}
						className={css['back']}
						style={{
							visibility: state.nextStep ? 'visible' : 'hidden'
						}}
					>
						<ArrowBackIcon />
						<div>{t('pages.login.back')}</div>
					</div>
					<div onClick={handleRecoverPassword} className={css['recover-password-text']}>
						{t('pages.login.forgotPassword')}
					</div>
				</div>
			</form>

			<div className={css['signup']}>
				<div className={css['no-account-text']}>{t('pages.login.noAccount')}</div>
				<div onClick={handleSignUp} className={css['no-account-link']}>
					{t('pages.login.signUpFree')}
				</div>
			</div>
		</Section>
	)
}
