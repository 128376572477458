import { BasicButton, LoadingSpinner, Tabs, TransitionSwitch } from '@lynx/client-core/src/components'
import css from './Body.module.scss'
import { GroupsTable, InvitesTable, UsersTable } from './components'
import { ActiveOrganisation } from './components'
import { AllOrganisations } from './components'
import { OrganisationConfiguration } from './components'
import { useCurrentOrganisation } from './hooks'
import { useEffect, useState } from 'react'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { useDispatch } from 'react-redux'
import { showModal } from '@lynx/client-core/src/store/modal'
import { OrganisationUserProLicenseModalType } from 'store/modal'
import { OrganisationUserProLicenseModal } from './modals/OrganisationUserProLicenseModal/OrganisationUserProLicenseModal'
import { APIRequest } from '@lynx/client-core/src/api'
import { OrganisationSubscriptionPricing } from '@lynx/core/src/interfaces'
import { useTranslation } from 'react-i18next'
import { loadOrganisations } from 'store/organisations'
import { useThunkDispatch } from 'hooks'

export const Body = (): React.ReactElement | null => {
	const [selectedTab, setSelectedTab] = useState<string>('users')
	const organisation = useCurrentOrganisation()
	const previousOrganisation = usePrevious(organisation)
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [subscriptionPricing, setSubscriptionPricing] = useState<OrganisationSubscriptionPricing | null>(null)

	useEffect(() => {
		if (previousOrganisation && previousOrganisation.id !== organisation?.id) {
			setSelectedTab('users')
		}

		if (organisation?.isPro && organisation?.isOwner) getData()
		else setSubscriptionPricing(null)
	}, [organisation])

	if (!organisation) return null

	const { users, groups, invites } = organisation

	const currentUserCount = users.length ? `(${users.length})` : ''
	const currentGroupsCount = groups.length ? `(${groups.length})` : ''
	const currentInvitesCount = invites.length ? `(${invites.length})` : ''

	const onButMoreClicked = (): void => {
		dispatch(showModal({ name: OrganisationUserProLicenseModalType }))
	}

	const getData = async (): Promise<void> => {
		try {
			const data = await APIRequest.Billing.getOrganisationPricing(organisation.id)
			setSubscriptionPricing(data)
		} catch (e) {
			setSubscriptionPricing(null)
		}
	}

	const updateOrganisationPricing = async (quantity: number): Promise<void> => {
		const data = await APIRequest.Billing.updateOrganisationPricing(organisation.id, { quantity })
		setSubscriptionPricing(data)
	}

	const orgProUsersCount = organisation.users.filter((user) => user.isAssignedPro).length
	const maxProUserLicenses = organisation.userLicensesLimit || subscriptionPricing?.quantity
	const proLicensesCalcs = `${orgProUsersCount} / ${maxProUserLicenses}`
	const canAddProLicenses = (licensesCount: number): boolean => {
		if (!maxProUserLicenses) return false
		return orgProUsersCount + licensesCount < maxProUserLicenses
	}
	const canAddMoreProLicenses = canAddProLicenses(0)
	const potentialNewProUsersCount = organisation.users.length - orgProUsersCount
	const canAddLicensesForAllUsers = Boolean(potentialNewProUsersCount && canAddProLicenses(potentialNewProUsersCount))

	const handleUpgradeAllToPro = async (groupId: number | null = null): Promise<void> => {
		setIsLoading(true)
		await APIRequest.Organisations.updateAllOrganisationUsers(organisation.id, { groupId })
		await dispatchThunk(loadOrganisations())
		setIsLoading(false)
	}

	return (
		<div className={css['container']}>
			<div className={css['container__left-container']}>
				<ActiveOrganisation />
				<OrganisationConfiguration />
				<AllOrganisations />
			</div>

			<div className={css['container__right-container']}>
				<div className={css['container__tabs']}>
					<div className={css['container__tabs__header']}>
						<Tabs
							selectedTab={selectedTab}
							setSelectedTab={setSelectedTab}
							tabs={[
								{ label: `Users ${currentUserCount}`, key: 'users' },
								{ label: `Groups ${currentGroupsCount}`, key: 'groups' },
								{ label: `Invites sent ${currentInvitesCount}`, key: 'invites' }
							].filter((tab) => {
								// Hide the invites tab if there are no invites
								if (invites.length === 0 && tab.key === 'invites') return false

								// Hide the groups and invites tab if the user is not the owner
								if (['invites', 'groups'].includes(tab.key)) {
									return organisation?.isOwner
								}

								return true
							})}
						/>
						{organisation.isPro && organisation.isOwner && (subscriptionPricing || organisation.userLicensesLimit) && (
							<div className={css['container__tabs__header__buyMoreContainer']}>
								<TransitionSwitch elKey={proLicensesCalcs}>
									<div>{proLicensesCalcs}</div>
								</TransitionSwitch>
								<span>{t('components.userLicenseLimit.proLicensesUsed')}</span>
								<BasicButton variant="blue-gradient" disabled={Boolean(organisation.userLicensesLimit)} onClick={onButMoreClicked}>
									{t('components.userLicenseLimit.buyMore')}
								</BasicButton>
							</div>
						)}
					</div>
					{isLoading && (
						<div className={css['container__loading']}>
							<LoadingSpinner className={css['container__loading-state__icon']} />
						</div>
					)}
					<div style={{ display: selectedTab === 'groups' ? 'block' : 'none' }}>
						<GroupsTable upgradeGroupPro={handleUpgradeAllToPro} canAddProLicenses={canAddProLicenses} />
					</div>
					<div style={{ display: selectedTab === 'users' ? 'block' : 'none' }}>
						<UsersTable
							canAddMoreProLicenses={canAddMoreProLicenses}
							upgradeAllToProClicked={canAddLicensesForAllUsers ? handleUpgradeAllToPro : undefined}
						/>
					</div>
					<div style={{ display: selectedTab === 'invites' ? 'block' : 'none' }}>
						<InvitesTable />
					</div>
				</div>
			</div>
			{subscriptionPricing && (
				<OrganisationUserProLicenseModal
					updateOrganisationPricing={updateOrganisationPricing}
					subscriptionPricing={subscriptionPricing}
					organisation={organisation}
				/>
			)}
		</div>
	)
}
