import { APIRequest } from '@lynx/client-core/src/api'
import { Modal } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import Styles from 'pages/Share/Modals/ShareModal.module.css'
import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import { EmailShareModalContextType, hideModal, ModalState } from 'store/modal'

interface EmailShareModalProps {
	hideModal: typeof hideModal
	modal: ModalState
}

interface EmailShareModalState {
	email: string
}

class EmailShareModal extends React.Component<EmailShareModalProps, EmailShareModalState> {
	state = {
		email: ''
	}

	private handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		e.preventDefault()

		const { email } = this.state

		if (!email) {
			return
		}

		const { context } = this.props.modal
		if (context?.type !== EmailShareModalContextType) {
			return
		}

		const { token } = context

		if (!token) {
			Logger.error('missing token')
			return
		}

		await APIRequest.Groups.shareEmailToken(email, token)

		this.props.hideModal()
	}

	private handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		this.setState({
			email: event.target.value
		})
	}

	render(): React.ReactNode {
		return (
			<Modal name="EmailShareModal">
				<form className={Styles['container']} onSubmit={this.handleEmailSubmit}>
					<p>Please enter an email address to send to.</p>
					<input className="form-control" name="groupName" type="text" onChange={this.handleEmailChange} value={this.state.email || ''} />
					<br />
					<button className={Styles.button} type="submit">
						Send To Email
					</button>
				</form>
			</Modal>
		)
	}
}

const mapStateToProps = (state: ApplicationState): { modal: ModalState } => ({
	modal: state.modal
})

export default connect(mapStateToProps, { hideModal })(EmailShareModal)
