import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ModalContext } from 'store/modal'
import { deleteGroup } from 'store/organisations'
import css from './DeleteGroupModal.module.scss'
import { DeleteGroupModalContext } from './DeleteGroupModalContext'
import { useTranslation } from 'react-i18next'

export const DeleteGroupModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const [context, setContext] = useState<DeleteGroupModalContext | null>(null)

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}

	const handleDeleteClicked = async (): Promise<void> => {
		if (!context) return
		await dispatchThunk(deleteGroup(context.organisationId, context.groupId))
		dispatch(hideModal())
	}

	const handleVisible = async (context?: ModalContext): Promise<void> => {
		if (context?.type !== 'DeleteGroupModalContextType') {
			return
		}

		setContext(context)
	}

	const groupName = context?.groupName || null

	return (
		<Modal name="DeleteGroupModal" title="Delete Group" onVisible={handleVisible} variant="danger">
			<div className={css['container']}>
				<div>
					{groupName} {t('pages.organisations.groupWillBeDeleted')}
				</div>

				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
					<BasicButton variant="danger" onClick={handleDeleteClicked}>
						{t('pages.organisations.delete')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
