import { Document, SupportScreenShareIcon, SupportWhiteboardIcon, SystemRequirementsIllustration } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React from 'react'
import css from './Documentation.module.scss'

const documents = [
	{
		icon: <SupportWhiteboardIcon />,
		translationKey: 'pages.documentation.userManualLynxWhiteboard' as const,
		linkEnglish: 'https://downloadsaharasupport.b-cdn.net/Documents/Lynx_Whiteboard_Training_guide_v1.pdf',
		linkGerman: 'https://downloadsaharasupport.b-cdn.net/Documents/Lynx8Handbuchdeutsch-2024-05-17.pdf',
		linkLatvian: 'https://downloadsaharasupport.b-cdn.net/Documents/Lynx_Whiteboard_Training_guide_v1.lv.pdf'
	},
	{
		icon: <SupportScreenShareIcon />,
		translationKey: 'pages.documentation.userManual' as const,
		linkEnglish: 'https://download.saharasupport.com/Documents/LynxHowToGuide.pdf',
		linkGerman: 'https://download.saharasupport.com/Documents/Lynx_6_Anleitung.pdf',
		linkLatvian: 'https://download.saharasupport.com/Documents/LynxHowToGuide.lv1.pdf',
		iconGuideTranslationKey: 'pages.documentation.iconGuide',
		iconGuideLinkEnglish: 'https://download.saharasupport.com/Documents/Lynx_Icon_Guide.pdf',
		iconGuideLinkGerman: 'https://download.saharasupport.com/Documents/Lynx_Icon_Guide.de.pdf'
	},
	{
		icon: <SystemRequirementsIllustration />,
		translationKey: 'pages.documentation.systemRequirements' as const,
		linkEnglish: 'https://download.saharasupport.com/Documents/Lynx5%20Spec%20Sheet.pdf',
		linkGerman: 'https://download.saharasupport.com/Documents/Lynx6_Plattform%C3%BCbersicht.pdf'
	}
]

export const Documentation = (): React.ReactElement => {
	const tiles = documents.map((doc, index) => {
		return (
			<div key={index} className={css.tile}>
				<div>{doc.icon}</div>
				<h2>{i18next.t(doc.translationKey)}</h2>
				<div className={css.buttonContainer}>
					<div>{i18next.t('pages.documentation.manual')}:</div>
					<div className={css.buttonContainerOptions}>
						{doc.linkEnglish && (
							<a href={doc.linkEnglish} target="_blank" rel="noreferrer noopener">
								English
							</a>
						)}
						{doc.linkGerman && (
							<React.Fragment>
								<span className={css.divider}>/</span>
								<a href={doc.linkGerman} target="_blank" rel="noreferrer noopener">
									Deutsch
								</a>
							</React.Fragment>
						)}
						{doc.linkLatvian && (
							<React.Fragment>
								<span className={css.divider}>/</span>
								<a href={doc.linkLatvian} target="_blank" rel="noreferrer noopener">
									Latviešu
								</a>
							</React.Fragment>
						)}
					</div>
				</div>
				{doc.iconGuideTranslationKey && (
					<div className={css.buttonContainer}>
						<div>{i18next.t('pages.documentation.iconGuide')}:</div>
						<div className={css.buttonContainerOptions}>
							{doc.iconGuideLinkEnglish && (
								<a href={doc.iconGuideLinkEnglish} target="_blank" rel="noreferrer noopener">
									English
								</a>
							)}
							{doc.iconGuideLinkGerman && (
								<React.Fragment>
									<span className={css.divider}>/</span>
									<a href={doc.iconGuideLinkGerman} target="_blank" rel="noreferrer noopener">
										Deutsch
									</a>
								</React.Fragment>
							)}
						</div>
					</div>
				)}
			</div>
		)
	})
	return (
		<Document
			title="Browse LYNX Documents"
			description="View LYNX specific documentation such as the help guide or system requirements"
			keywords="Documentation, LYNX, System Requirements"
		>
			<h1 className={css['heading']}>{i18next.t('pages.documentation.title')}</h1>
			<div className={css['container']}>
				<div>{tiles}</div>
			</div>
		</Document>
	)
}
