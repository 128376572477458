import { BasicButton } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React from 'react'
import css from './FooterButtons.module.scss'
import { FooterButtonsProps } from './interfaces'

export const FooterButtons = ({ onBackClicked, onSendInvitesClicked }: FooterButtonsProps): React.ReactElement => {
	return (
		<div className={css['container']}>
			<BasicButton variant="grey" onClick={onBackClicked}>
				{i18next.t('components.modals.sharingModal.back')}
			</BasicButton>
			<BasicButton variant="blue" onClick={onSendInvitesClicked}>
				{i18next.t('components.modals.sharingModal.sendInvites')}
			</BasicButton>
		</div>
	)
}
