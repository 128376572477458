import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Card, Document, Input } from '@lynx/client-core/src/components'
import { Message } from '@lynx/client-core/src/components/Input/Input.types'
import { Logger } from '@lynx/client-core/src/modules'
import { string } from '@lynx/core'
import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import { useThunkDispatch } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ApplicationState } from 'store'
import { loadOrganisations } from 'store/organisations'
import css from './ContactUs.module.scss'
import { CountryDropdown, FlagDropdown, OrganisationDropdown } from './components'

const keys = [
	'name',
	'email',
	'phoneCountryCode',
	'phoneNumber',
	'lynxOrganisation',
	'organisationName',
	'organisationUrl',
	'city',
	'postcode',
	'country',
	'numberOfPeople',
	'additionalInformation'
]

const optionalKeys: string[] = ['additionalInformation', 'lynxOrganisation']

interface FormElements extends HTMLFormControlsCollection {
	name: HTMLInputElement
	email: HTMLInputElement
	lynxOrganisation: HTMLInputElement
	organisationName: HTMLInputElement
	organisationUrl: HTMLInputElement
	city: HTMLInputElement
	postcode: HTMLInputElement
	country: HTMLInputElement
	numberOfPeople: HTMLInputElement
	additionalInformation: HTMLInputElement
	phoneCountryCode: HTMLInputElement
	phoneNumber: HTMLInputElement
}

interface ContactFormElement extends HTMLFormElement {
	readonly elements: FormElements
}

const requiredMessage: Message = {
	type: 'error',
	message: 'Required field'
}

export const ContactUs = (): React.ReactElement => {
	const { profile } = useSelector((state: ApplicationState) => state)
	const [missingFields, setMissingFields] = useState<string[]>([])
	const [countrySelected, setCountrySelected] = useState<string>('United Kingdom')
	const [organisationSelected, setOrganisationSelected] = useState<OrganisationRedux | null>(null)
	const [flagSelectedCode, setFlagSelectedCode] = useState<string>('+44')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate()

	useEffect(() => {
		const mount = async (): Promise<void> => {
			profile.loggedIn && (await dispatchThunk(loadOrganisations()))
		}
		mount()
	}, [profile.loggedIn])

	const handleBackClicked = (): void => {
		navigate('/upgrade')
	}

	const handleFormSubmitted = async (e: React.FormEvent<ContactFormElement>): Promise<void> => {
		e.preventDefault()
		e.stopPropagation()

		setIsLoading(true)

		const missing: string[] = []
		const formValues = keys.map((key: string, nKey: number) => {
			const element = e.currentTarget.elements[nKey] as HTMLInputElement
			if (!element.value) {
				missing.push(key)
			}

			// special cases
			switch (key) {
				case 'email': {
					if (!string.isEmail(element.value)) {
						missing.push(key)
					}
					break
				}
				case 'name': {
					if (!string.isName(element.value)) {
						missing.push(key)
					}
					break
				}
				case 'organisationUrl': {
					if (!string.isUrl(element.value)) {
						missing.push(key)
					}
					break
				}
				case 'numberOfPeople': {
					if (element.value.length > 7) {
						element.value = '9999999'
					}
				}
			}
			return { [key]: element.value }
		})

		setMissingFields(missing)

		// return if any element in the missing array are not in the optionalkeys array
		if (missing.some((m) => !optionalKeys.includes(m))) {
			setIsLoading(false)
			return
		}

		try {
			await APIRequest.Billing.sendEnquiryForm(formValues)
			navigate('/enquiry')
		} catch (e) {
			Logger.error(e)
		}
		setIsLoading(false)
	}

	const getMissingMessage = (field: string): Message[] => {
		return missingFields.includes(field) ? [requiredMessage] : []
	}

	const handleFlagDropdownItemClicked = (code: string): void => {
		setFlagSelectedCode(code)
	}

	const handleCountryDropdownItemClicked = (country: string): void => {
		setCountrySelected(country)
	}

	const handleOrganisationItemClick = (organisation: OrganisationRedux): void => {
		setOrganisationSelected(organisation)
	}

	return (
		<Document title={t('pages.billing.contactUs')} description={t('pages.billing.contactUs')} keywords={'contact'} className={css['document']}>
			<Card>
				<form className={css['form']} onSubmit={handleFormSubmitted}>
					<ul>
						<h3>{t('pages.billing.contactDetails')}</h3>
						<li>
							<label>
								{t('pages.billing.name')}
								<Input id="name" variant="light" placeholder="First and Last Name" messages={getMissingMessage('name')} />
							</label>
						</li>
						<li>
							<label>
								{t('pages.billing.email')}
								<Input id="email" variant="light" placeholder="email@email.com" messages={getMissingMessage('email')} />
							</label>
						</li>
						<li>
							<label>{t('pages.billing.phoneNumber')}</label>
							<div className={css['phone']}>
								<FlagDropdown onItemClicked={handleFlagDropdownItemClicked} />
								<input type="hidden" id="phoneCountryCode" value={flagSelectedCode} />
								<Input id="phoneNumber" type="number" variant="light" placeholder="01234555555" messages={getMissingMessage('phoneNumber')} />
							</div>
						</li>
						<hr />
						<h3>{t('pages.billing.organisation')}</h3>
						<li>
							<label>
								LYNX {t('pages.billing.organisation')} ( optional )
								<OrganisationDropdown onItemClicked={handleOrganisationItemClick} />
								<input type="hidden" id="lynxOrganisation" value={organisationSelected?.id || ''} />
							</label>
						</li>
						<li>
							<label>
								{t('pages.billing.organisationName')}
								<Input
									id="organisationName"
									variant="light"
									placeholder="School or business name"
									messages={getMissingMessage('organisationName')}
								/>
							</label>
						</li>
						<li>
							<label>
								{t('pages.billing.organisationUrl')}
								<Input id="organisationUrl" variant="light" placeholder="Website" messages={getMissingMessage('organisationUrl')} />
							</label>
						</li>
						<li className={css['horizontal']}>
							<div>
								<label>
									{t('pages.billing.city')} <Input id="city" variant="light" placeholder="London" messages={getMissingMessage('city')} />
								</label>
							</div>
							<div>
								<label>
									{t('pages.billing.postCode')}
									<Input id="postcode" variant="light" placeholder="WB1 2GT" messages={getMissingMessage('postcode')} />
								</label>
							</div>
						</li>
						<li>
							<label>
								{t('pages.billing.country')}
								<CountryDropdown onItemClicked={handleCountryDropdownItemClicked} />
								<input type="hidden" id="country" value={countrySelected} />
							</label>
						</li>
						<hr />
						<h3>LYNX Plan</h3>
						<li>
							<label>
								{t('pages.billing.howMany')}
								<Input
									id="numberOfPeople"
									type="number"
									maxLength={7}
									variant="light"
									placeholder="e.g. 100"
									messages={getMissingMessage('numberOfPeople')}
								/>
							</label>
						</li>
						<li>
							<label>
								{t('pages.billing.additionalInformation')}
								<Input id="additionalInformation" variant="light" placeholder="Add any additional details here" />
							</label>
						</li>
					</ul>

					<div className={css['buttons']}>
						<BasicButton variant="light" onClick={handleBackClicked}>
							{t('pages.billing.back')}
						</BasicButton>
						<BasicButton type="submit" variant="pro" isLoading={isLoading}>
							{t('pages.billing.submit')}
						</BasicButton>
					</div>
				</form>
			</Card>
		</Document>
	)
}
