import { BaseCall } from '../BaseCall'
import { APIConfig } from '../interfaces'

export class Reports extends BaseCall {

	public getRegReport = (params: any) => {
		return this.client.get('/api-v2/reports/reg-report/data', {
			params: params
		}).then((response => {
			return response.data
		}))
	}

	public getRegReportByMonth = () => {
		return this.client.get('/view/reports/reg-report-by-month')
			.then((response) => {
				return response.data
			})
	}

	public getRegReportByCountry = () => {
		return this.client.get('/view/reports/reg-report-country')
			.then((response) => {
				return response.data
			})
	}

	public getRegReportByOs = () => {
		return this.client.get('/view/reports/reg-report-os')
			.then((response) => {
				return response.data
			})
	}

	public getRegReportByVersion = () => {
		return this.client.get('/view/reports/reg-report-by-version')
			.then((response) => {
				return response.data
			})
	}

	public getRegReportRecentMultipleUsage = () => {
		return this.client.get('/view/reports/reg-report-recent-multiple-usage')
			.then((response) => {
				return response.data
			})
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}