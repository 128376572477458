import * as React from 'react'
import css from './Alert.module.scss'
import { AlertProps } from './interfaces'
import { ErrorIcon, InfoIcon, WarningIcon } from '../Icons'

export const Alert = ({ children, styleClass, className }: AlertProps): React.ReactElement => {
	const getIcon = (type: AlertProps['styleClass']): React.ReactElement | undefined => {
		const iconClass = css[`${type}__icon`]
		switch (type) {
			case 'danger':
				return <ErrorIcon className={iconClass} />
			case 'info':
				return <InfoIcon className={iconClass} />
			case 'warning':
				return <WarningIcon className={iconClass} />
		}
	}

	const classes = `${css['alert']} ${css[styleClass]} ${className}`
	return (
		<div className={classes}>
			<div>{getIcon(styleClass)}</div>
			{children}
		</div>
	)
}
