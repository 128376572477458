import { MoreVertNewIcon } from '@lynx/client-core/src/components'
import { Overlay } from '@lynx/client-core/src/components/Popup/Overlay'
import React from 'react'
import css from './SubscriptionsOverlay.module.scss'

interface SubscriptionsOverlayProps {
	children: React.ReactNode
	icon?: React.ReactElement
}

export const SubscriptionsOverlay = ({ children, icon = <MoreVertNewIcon /> }: SubscriptionsOverlayProps): React.ReactElement => {
	return (
		<Overlay icon={icon} title={'title'} className={css['dropdown']} popUpClassName={css['popup']} useMousePosition>
			{children}
		</Overlay>
	)
}
