import Page from 'components/ReactPDFts/Page'
import * as React from 'react'
import { SidebarProps } from './interfaces'
import css from './Sidebar.module.scss'

export const Sidebar = ({ pageCount, changePage, currentPage }: SidebarProps): React.ReactElement => {
	const itemElements = []
	for (let i = 1; i < pageCount + 1; i++) {
		itemElements.push(
			<li
				key={i}
				onClick={(): void => {
					changePage(i)
				}}
			>
				<Page width={150} key={i} pageNumber={i} className={currentPage === i ? css['active'] : css['inactive']} />
				<label>{i}</label>
			</li>
		)
	}

	return (
		<div className={css['container']}>
			<ul>{itemElements}</ul>
		</div>
	)
}
