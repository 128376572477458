import { NavigateOptions, To, useNavigate, useSearchParams } from 'react-router-dom'

/*
	Preserves query params between navigation calls without having to explicitly add them
*/
export const useNavigatePreserve = (): { (to: To, options?: NavigateOptions): void } => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const currentParams = new URLSearchParams(searchParams)

	return (to: To, options?: NavigateOptions): void => {
		const newParams = new URLSearchParams(currentParams)

		if (typeof to === 'string') {
			if (newParams.toString().length) {
				navigate(`${to}?${newParams.toString()}`)
			} else {
				navigate(to)
			}
			return
		}

		if (to.search) {
			const newSearchParams = new URLSearchParams(to.search)
			newSearchParams.forEach((value, key) => {
				newParams.set(key, value)
			})
		}

		if (!newParams.toString()) {
			navigate(to, options)
		} else {
			navigate({ ...to, search: newParams.toString() }, options)
		}
	}
}
