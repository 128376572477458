import { AddCircleIcon, BasicButton, Dropdown } from '@lynx/client-core/src/components'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './Controls.module.scss'
import { driveProviders } from 'pages/Profile/components/CloudDrives.data'
import { AuthCookie } from '@lynx/client-core/src/modules'
import { useThunkDispatch } from 'hooks'
import { setDisallowedLinkProviders } from 'store/drive'
import { APIRequest } from '@lynx/client-core/src/api'

interface HomeDropdownProps {
	buttonSize: 'xs' | 'sm' | 'md'
}

export const HomeDropdown = ({ buttonSize }: HomeDropdownProps): React.ReactElement => {
	const { drive } = useSelector((state: ApplicationState) => state)
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = React.useState(false)
	const {
		config: { ENVIRONMENT, API_SERVER },
		drive: { disallowedLinkProviders }
	} = useSelector((state: ApplicationState) => state)

	const dispatchThunk = useThunkDispatch()

	const getData = async (): Promise<void> => {
		if (disallowedLinkProviders === null && !isLoading) {
			setIsLoading(true)
			const data = await APIRequest.ObjectStore.getDisallowedLinkProviders()
			await dispatchThunk(setDisallowedLinkProviders(data))
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [disallowedLinkProviders, isLoading])

	const accessToken = AuthCookie.getAccessToken(ENVIRONMENT)

	const handleProviderClicked = (e: React.MouseEvent<HTMLLIElement>): void => {
		const provider = e.currentTarget.getAttribute('data-provider')
		const link = document.createElement('a')
		link.href = `${API_SERVER}/auth/${provider}/link/${accessToken}`
		link.click()
	}

	const driveProviderElements = driveProviders
		.filter(({ provider }) => {
			const isGoogle = provider === 'google'
			const isNotVisible = !drive.drives.visible.some((c) => c.provider === provider)
			const isProviderAllowed = disallowedLinkProviders ? !disallowedLinkProviders.includes(provider) : true
			return (isGoogle || isNotVisible) && isProviderAllowed
		})

		.map(({ provider, name }) => {
			return (
				<li key={provider} data-provider={provider} onClick={handleProviderClicked}>
					{name}
				</li>
			)
		})

	return (
		<Dropdown
			title={
				<BasicButton variant="blue" icon={AddCircleIcon} size={buttonSize} disabled={!driveProviderElements.length}>
					{t('pages.profile.addDrive')}
				</BasicButton>
			}
			openedClassName={css.alignToLeft}
		>
			{driveProviderElements}
		</Dropdown>
	)
}
