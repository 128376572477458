import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '../Icons'
import { Dropdown } from '../'
import css from './TablePagination.module.scss'
import React from 'react'
import { TablePaginationProps } from './interfaces'
import i18next from 'i18next'

export const TablePagination = ({ pageSizes, totalCount, pageSize, setPageSize, currentPage, setCurrentPage }: TablePaginationProps): React.ReactElement => {
	const lastPageNum = Math.ceil(totalCount / pageSize)
	const isNextPageButtonDisabled = lastPageNum === currentPage || !totalCount
	const isPreviousPageButtonDisabled = 1 === currentPage || !totalCount

	const previousPage = (): void => {
		!isPreviousPageButtonDisabled && setCurrentPage(currentPage - 1)
	}
	const nextPage = (): void => {
		!isNextPageButtonDisabled && setCurrentPage(currentPage + 1)
	}

	const pageIndex = currentPage - 1
	const getPageStart = (pageSize: number, pageNr: number): number => pageSize * pageNr

	const start = totalCount ? Math.max(getPageStart(pageSize, pageIndex), 0) + 1 : 0
	const end = Math.min(getPageStart(pageSize, pageIndex + 1), totalCount)

	const pageSelection = items(8, lastPageNum, currentPage)

	const onPageSelectionClick = (item: number | string): void => {
		if (typeof item === 'number') {
			setCurrentPage(item)
		}
	}

	return (
		<div className={css.container}>
			<div className={css.leftContainer}>
				<span>
					Showing
					<b>
						{start} - {end}
					</b>
					{i18next.t('components.table.of')} <b>{totalCount}</b>
				</span>
			</div>
			<div className={css.rightContainer}>
				<div className={css.rowsPerPage}>
					<span>{i18next.t('components.table.rowsPerPage')}</span>
					<div>
						<Dropdown
							title={
								<div className={css.dropDownContent}>
									{pageSize}
									<ChevronDownIcon />
								</div>
							}
						>
							{pageSizes.map((item) => (
								<li
									key={item}
									onClick={(): void => {
										setCurrentPage(1)
										setPageSize(item)
									}}
								>
									{item}
								</li>
							))}
						</Dropdown>
					</div>
				</div>
				<div className={css.resultCountContainer}>
					<ChevronLeftIcon
						className={`${css.paginationArrow} ${css.arrowLeft} ${isPreviousPageButtonDisabled && css.disabledPaginationArrow}`}
						onClick={previousPage}
					/>
					<ChevronRightIcon className={`${css.paginationArrow} ${isNextPageButtonDisabled && css.disabledPaginationArrow}`} onClick={nextPage} />
				</div>
				<div className={css.pageSelection}>
					{pageSelection.map((item: string | number) => {
						const itemClasses = [css.pageItem]
						if (item === currentPage) itemClasses.push(css.currentPage)
						if (typeof item === 'string') itemClasses.push(css.dots)
						return (
							<div key={item} className={itemClasses.join(' ')} onClick={(): void => onPageSelectionClick(item)}>
								{item}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

const range = (from: number, to: number): number[] => {
	const range = []
	from = from > 0 ? from : 1
	for (let i = from; i <= to; i++) {
		range.push(i)
	}
	return range
}

const items = (totalVisible: number, pagesCount: number, currentPage: number): Array<string | number> => {
	if (pagesCount <= totalVisible || totalVisible < 1) {
		return range(1, pagesCount)
	}
	const dots = '...'

	const even = totalVisible % 2 === 0 ? 1 : 0
	const left = Math.floor(totalVisible / 2)
	const right = pagesCount - left + 1 + even

	if (currentPage > left && currentPage < right) {
		const start = currentPage - left + 2
		const end = currentPage + left - 2 - even

		return [1, dots, ...range(start, end), dots, pagesCount]
	} else if (currentPage === left) {
		const end = currentPage + left - 1 - even
		return [...range(1, end), dots, pagesCount]
	} else if (currentPage === right) {
		const start = currentPage - left + 1
		return [1, dots, ...range(start, pagesCount)]
	} else {
		return [...range(1, left), dots, ...range(right, pagesCount)]
	}
}
