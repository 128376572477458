import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import websocket from '@lynx/client-core/src/middleware/reduxwebsockets'
import { userMessagingNotification } from 'store/middleware/userMessaging'
import { userMessaging } from 'store/middleware/userMessaging/middleware'
import { ApplicationState, createRootReducer } from './store'
import { WEBSOCKET_PREFIX } from './store/messaging'

const websocketMiddleware = websocket({
	prefix: WEBSOCKET_PREFIX,
	onOpen: (socket: WebSocket) => {
		window.__socket = socket
	},
	reconnectOnClose: true,
	reconnectInterval: 10000
})

export default function configureStore(): Store<ApplicationState> {
	const composeEnhancers = composeWithDevTools({})

	return createStore(
		createRootReducer(),
		composeEnhancers(applyMiddleware(thunkMiddleware, websocketMiddleware, userMessaging(), userMessagingNotification()))
	)
}
