import {
	AppleLogoIcon,
	ClassLinkIcon,
	CleverLiveIcon,
	CleverLogoIcon,
	DropboxLogoIcon,
	GoogleClassroomLogoIcon,
	GoogleLogoIcon,
	IServDarkIcon,
	MicrosoftLogoIcon,
	NextCloudLogoIcon,
	QRCodeLogoIcon,
	SamlIcon,
	UcsIcon
} from '@lynx/client-core/src/components'

export const authenticationProviderIcons = [
	{ name: 'Google', icon: <GoogleLogoIcon /> },
	{ name: 'Microsoft', icon: <MicrosoftLogoIcon /> },
	{ name: 'Apple', icon: <AppleLogoIcon /> },
	{ name: 'Google Classroom', icon: <GoogleClassroomLogoIcon /> },
	{ name: 'Dropbox', icon: <DropboxLogoIcon /> },
	{ name: 'QR', icon: <QRCodeLogoIcon /> },
	{ name: 'Cleverlive', icon: <CleverLiveIcon /> },
	{ name: 'Clever', icon: <CleverLogoIcon /> },
	{ name: 'Univention Corporate Server (UCS)', icon: <UcsIcon /> },
	{ name: 'ClassLink', icon: <ClassLinkIcon /> },
	{ name: 'Nextcloud', icon: <NextCloudLogoIcon /> },
	{ name: 'SAML', icon: <SamlIcon /> },
	{ name: 'iServ', icon: <IServDarkIcon /> }
]
