import { Document } from '@lynx/client-core/src/components'
import React from 'react'
import { Upsell } from '@lynx/client-core/src/components/Upsell/Upsell'
import css from './Upsell.module.scss'

export const UpsellPage = (): React.ReactElement | null => {
	return (
		<Document title={'Upgrade'} description={'Upgrade'} keywords={'upgrade'} className={css['document']}>
			<Upsell />
		</Document>
	)
}
