import { APIRequest } from '@lynx/client-core/src/api'
import { Button, Document, Table } from '@lynx/client-core/src/components'
import { Card } from 'components/elmstone/Card'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { TranslationFiles } from './interfaces'

export const Translations = (): React.ReactElement => {
	const { config: configState, profile: profileState } = useSelector((state: ApplicationState) => state)

	const [languageFiles, setLanguageFiles] = useState<TranslationFiles[]>([])

	const customBodyRender = (item: TranslationFiles): React.ReactNode => (
		<div>
			<Button
				onClick={(): void => {
					window.open(`${configState.LYNXTRANSLATOR_CLIENT}/?fileName=${item.fileName}&userId=${profileState.userId}`, '_blank')
				}}
			>
				{i18next.t('pages.translations.editTranslation')}
			</Button>
		</div>
	)

	const headers = [
		{ valueKey: 'language', label: 'Language' },
		{ valueKey: 'title', label: 'Title', customBodyRender }
	]

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const data = await APIRequest.Pages.getTranslations()
			setLanguageFiles(data.languageFiles)
		}

		mount()
	}, [])

	return (
		<Document
			title="Translations"
			description="Translations"
			keywords="Translations, Manage LYNX Translations, Translate, LYNX"
			auth={true}
			disableOverflow={true}
		>
			<Card>
				<h1>{i18next.t('pages.translations.title')}</h1>
				<p className="my-2">
					{i18next.t('pages.translations.forHelp')}{' '}
					<a href="https://support.clevertouch.com/support-cases/create">https://support.clevertouch.com/support-cases/create</a>
				</p>
				<p className="my-2">{i18next.t('pages.translations.qtLinguist')}</p>
				<p className="my-2">{i18next.t('pages.translations.onceSaved')}</p>
				<Table title={i18next.t('pages.translations.translations')} headers={headers} items={languageFiles} />
			</Card>
		</Document>
	)
}
