import { APIRequest } from '@lynx/client-core/src/api'
import { Plan } from '@lynx/client-core/src/api/Billing/Billing'
import { useEffect, useState } from 'react'
import css from './GlobalStorageConfiguration.module.scss'
import { BasicButton, EditIcon, Input } from '@lynx/client-core/src/components'
import { useBillingPlans } from '@lynx/client-core/src/hooks'
import { PlanFreeIcon, PlanOrgIcon, PlanProIcon } from 'components/PlanIcons/PlanIcons'

const planConfigs = [
	{
		key: 'free',
		icon: <PlanFreeIcon />,
		isEditable: false,
		newValue: ''
	},
	{
		key: 'pro',
		icon: <PlanProIcon />,
		isEditable: false,
		newValue: ''
	},
	{
		key: 'organisation',
		icon: <PlanOrgIcon />,
		isEditable: false,
		newValue: ''
	}
]

interface Props {
	setPlans: (plans: Plan[]) => void
}

export const GlobalStorageConfiguration = ({ setPlans }: Props): React.ReactElement => {
	const [plansConfigs, setPlansConfigs] = useState<(Plan & { key: string; isEditable: boolean; newValue: string; icon: JSX.Element })[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const { billingPlans, refetchBillingPlans } = useBillingPlans()

	const updatePlansConfig = async (): Promise<void> => {
		if (!billingPlans) return
		const plansList = Object.values(billingPlans)
		setPlans(plansList)
		const getValues = (subType: string): Plan => {
			const planData = plansList.find((p) => p.subscriptionType === subType)
			if (!planData) throw new Error('Plan not found')
			return planData
		}

		setPlansConfigs(planConfigs.map((p) => ({ ...p, ...getValues(p.key) })))
	}

	useEffect(() => {
		if (billingPlans) updatePlansConfig()
	}, [billingPlans])

	const toggleEdit = (key: string): void => {
		const newPlans = plansConfigs.map((p) => {
			if (p.key === key) {
				return { ...p, isEditable: !p.isEditable }
			}
			return p
		})
		setPlansConfigs(newPlans)
	}

	const onSaveClicked = async (key: string): Promise<void> => {
		setIsLoading(true)

		try {
			const plan = plansConfigs.find((p) => p.key === key)
			if (!plan) return
			await APIRequest.Billing.updateSubscriptionPlan(plan.id, Number(plan.newValue))
			await refetchBillingPlans()
		} finally {
			setIsLoading(false)
		}
	}

	const onChangedValue = (value: string, key: string): void => {
		const newPlans = plansConfigs.map((p) => {
			if (p.key === key) {
				return { ...p, newValue: value }
			}
			return p
		})
		setPlansConfigs(newPlans)
	}

	return (
		<div className={css.container}>
			<div>
				<h1>Default Storage Allowance</h1>
				<div className={css.plansContainer}>
					{plansConfigs.map(({ icon, key, storageLimitGB, newValue, subscriptionType, isEditable }) => {
						return (
							<div className={css.singlePlanContainer} key={subscriptionType}>
								<div className={css.pillContainer}>{icon}</div>
								<div className={css.storageSizeContainer}>
									<div>{storageLimitGB} GB</div>
									<EditIcon className={css.editIcon} onClick={(): void => toggleEdit(key)} />
								</div>
								{isEditable && (
									<div className={css.editableContainer}>
										<Input type="number" value={newValue || storageLimitGB} onChange={(e): void => onChangedValue(e.target.value, key)} />
										<BasicButton onClick={(): void => toggleEdit(key)}>Cancel</BasicButton>
										<BasicButton
											variant="blue"
											disabled={!newValue || newValue === String(storageLimitGB) || isLoading}
											onClick={(): Promise<void> => onSaveClicked(key)}
										>
											Save
										</BasicButton>
									</div>
								)}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
