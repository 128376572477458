import { DownArrowIcon, Dropdown } from '@lynx/client-core/src/components'
import { useField } from 'formik'
import css from './CountryDropdown.module.scss'
import { useEffect } from 'react'

interface CountryDropdownProps {
	countries: { id: string; name: string }[]
	countryName: string
	handleCountryChanged: (id: string, name: string) => void
}

export const CountryDropdown = ({ countries }: CountryDropdownProps): React.ReactElement => {
	const [countryField, _, helpers] = useField('country')

	const handleItemClicked = (id: string | number | null): void => {
		helpers.setValue(id)
	}

	useEffect(() => {
		if (!countries?.length) return
		helpers.setValue(countries.find((c) => c.name === 'United Kingdom')?.id)
	}, [countries])

	return (
		<div className={css['container']}>
			<Dropdown
				className={`${css['dropdown-component']} ${css['country-selector']}`}
				title={
					<div className={css['country-name']}>
						{countries.find((c) => c.id === countryField.value)?.name || 'United Kingdom'}
						<DownArrowIcon className={css['dropdown-icon']} />
					</div>
				}
			>
				{countries &&
					countries.map((item: { id: string; name: string }) => (
						<li
							onClick={(): void => {
								handleItemClicked(item.id)
							}}
							key={item.id}
						>
							{item.name}
						</li>
					))}
			</Dropdown>
		</div>
	)
}
