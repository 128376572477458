import { DriveFolderItem } from '@lynx/client-core/src/api/interfaces'
import { DriveState } from './types'

export const initialDriveState: DriveState = {
	drives: { visible: [], hidden: [] },
	homeDrives: null,
	response: {
		OK: true,
		message: 'uninitialised'
	},
	provider: null,
	currentPath: {
		name: '',
		id: ''
	},
	paths: [
		{
			name: '',
			id: ''
		}
	],
	data: {
		id: '',
		name: '',
		children: []
	},
	selectedDriveItem: null,
	style: 'grid',
	writeable: true,
	popup: false,
	isNextPageLoading: false,
	loadingItemsCount: 0,
	uploadingItems: [],
	drivesSizes: [],
	providerDetails: {},
	favourites: [],
	disallowedLinkProviders: null
}

type DefaultHomeDriveItemType = Omit<DriveFolderItem, 'provider'> & { provider: null }
export const defaultHomeDriveItem: DefaultHomeDriveItemType = {
	children: [],
	dateModified: 0,
	id: '',
	mimeType: 'application/provider',
	name: 'Home',
	provider: null,
	size: 0,
	thumbnail: ''
}
