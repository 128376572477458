import * as React from 'react'
import { SectionNavigatorProps, SectionNavigatorState } from './interfaces'

export class SectionNavigator extends React.Component<SectionNavigatorProps, SectionNavigatorState> {
	state = {
		component: null
	}

	componentDidMount(): void {
		const { section } = this.props
		this.navigate(section)
	}
	// eslint-disable-next-line
	private navigate = (section: string, context?: any /* Needs to be an any as can literally be anything */): void => {
		const { config } = this.props
		const sectionConfig = config[section]
		if (!sectionConfig) {
			return
		}

		const component = React.createElement(sectionConfig, { navigate: this.navigate, context }, null)
		this.setState({
			component
		})
	}

	render(): React.ReactNode {
		const { component } = this.state
		return component
	}
}
