import React, { useState } from 'react'
import { DefaultInputProps } from '../Input/Input.types'
import inputCss from '../Input/Input.module.scss'
import css from './Textarea.module.scss'
import { InputMessages } from '../Input/InputMessages'

export type TextareaProps = DefaultInputProps &
	React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
		disableResize?: boolean
	}

export const Textarea = (props: TextareaProps): React.ReactElement => {
	const { className, messages, sizeInput = 'md', variant = 'dark', label, inError = false, icon, disableResize, onBlur, onFocus, ...rest } = props
	const [isFocused, setIsFocused] = useState(false)
	const { disabled } = rest

	const containerClasses = [inputCss.container, inputCss[`container--${variant}`], inputCss[`container--${sizeInput}`]]
	const internalId = rest.id || 'textarea-' + Math.random().toString(36).substr(2, 9)
	const isErrorMessage = messages?.some((message) => message.type === 'error')

	Boolean(inError || isErrorMessage) && containerClasses.push(inputCss['container--error'])
	disabled && containerClasses.push(inputCss['container--disabled'])
	isFocused && containerClasses.push(inputCss['container--focused'])
	disableResize && containerClasses.push(css['container--disabled-resize'])
	className && containerClasses.push(className)

	const onBlurHandler = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
		onBlur && onBlur(event)
		setIsFocused(false)
	}

	const onFocusHandler = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
		onFocus && onFocus(event)
		setIsFocused(true)
	}

	return (
		<div className={containerClasses.join(' ')}>
			{label && (
				<label className={inputCss.label} htmlFor={internalId}>
					{label}
				</label>
			)}
			<div className={inputCss.inputContainer}>
				<textarea className={inputCss.inputEl} id={internalId} onFocus={onFocusHandler} onBlur={onBlurHandler} {...rest} />
			</div>
			<InputMessages messages={messages} />
		</div>
	)
}
