import { APIRequest } from '@lynx/client-core/src/api'
import { ArrowBackIcon, Modal } from '@lynx/client-core/src/components'
import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import { DocumentState } from 'store/document'
import { hideModal, ImagePreviewModalContextType, ModalContext } from 'store/modal'
import Styles from './PreviewModal.module.css'

interface PreviewModalProps {
	hideModal: typeof hideModal
}

interface PreviewModalState {
	src: string
}

class PreviewModal extends React.Component<PreviewModalProps, PreviewModalState> {
	state = {
		src: ''
	}

	private handleVisible = async (context: ModalContext): Promise<void> => {
		if (context?.type !== ImagePreviewModalContextType) {
			return
		}

		const { token } = context
		const doc = await APIRequest.Sharing.getObjectUrl(token)

		this.setState({
			src: doc.link
		})
	}

	private hidePreview = async (): Promise<void> => {
		this.props.hideModal()
	}

	render(): React.ReactNode {
		const { src } = this.state

		return (
			<Modal name="ImagePreviewModal" onVisible={this.handleVisible}>
				<div className={Styles.container}>
					<div className={Styles.header}>
						<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={this.hidePreview}>
							<ArrowBackIcon style={{ marginLeft: '10px' }} />
						</div>
					</div>
					<div className={Styles['preview-container']}>
						<React.Fragment>{src && <img src={src} alt="Preview" />}</React.Fragment>
					</div>
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = (state: ApplicationState): { document: DocumentState } => ({
	document: state.document
})

export default connect(mapStateToProps, { hideModal })(PreviewModal)
