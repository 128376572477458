import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { NotificationToastPortalProps } from './interfaces'

const toastRoot = document.getElementById('toast-root')

export class NotificationToastPortal extends React.Component<NotificationToastPortalProps> {
	toastRootElement: HTMLDivElement

	constructor(props: NotificationToastPortalProps) {
		super(props)
		this.toastRootElement = document.createElement('div')
	}

	componentDidMount(): void {
		if (toastRoot) {
			toastRoot.appendChild(this.toastRootElement)
		}
	}

	componentWillUnmount(): void {
		if (toastRoot) {
			toastRoot.removeChild(this.toastRootElement)
		}
	}

	render(): React.ReactNode {
		return ReactDOM.createPortal(this.props.children, this.toastRootElement)
	}
}
