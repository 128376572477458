/* eslint-disable */

export class Logger {
	public static error = (message?: any, ...args: any[]): void => {
		console.error(message, ...args)
	}

	public static info = (message?: any, ...args: any[]): void => {
		console.info(message, ...args)
	}

	public static log = (message?: any, ...args: any[]): void => {
		console.log(message, ...args)
	}

	public static warn = (message?: any, ...args: any[]): void => {
		console.warn(message, ...args)
	}
}
