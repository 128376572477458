import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'

export const useCurrentOrganisation = (): (OrganisationRedux & { isOwner: boolean }) | null => {
	const [organisation, setOrganisation] = useState<(OrganisationRedux & { isOwner: boolean }) | null>(null)
	const {
		organisations: { organisations, activeOrganisationId },
		profile
	} = useSelector((state: ApplicationState) => state)

	useEffect(() => {
		if (!organisations) {
			setOrganisation(null)
			return
		}
		const currentOrganisation = organisations.find((org) => org.id === activeOrganisationId)
		if (!currentOrganisation) {
			setOrganisation(null)
			return
		}

		const isOwner = currentOrganisation.users.some((user) => user.userId === profile.userId && user.isOwner)

		setOrganisation({ ...currentOrganisation, isOwner })
	}, [organisations, activeOrganisationId])

	return organisation
}
