import { APIRequest } from '@lynx/client-core/src/api'
import { Country } from '@lynx/client-core/src/api/interfaces'
import { Button, Modal, TextField } from '@lynx/client-core/src/components'
import * as React from 'react'
import { useState } from 'react'
import { default as ReactSelectAsync } from 'react-select/async'
import Styles from './AddNewCountryModal.module.css'
import { AddNewCountryModalState } from './interfaces'

export const AddNewCountryModal = (): React.ReactElement => {
	const [state, setState] = useState<AddNewCountryModalState>({ badName: '', countryId: -1 })

	const loadOptions = async (inputValue: string, callback: (options: { value: number | undefined; label: string }[]) => void): Promise<void> => {
		const countries = await APIRequest.Countries.get()

		callback(
			countries
				.filter((country: Country) => {
					return country.name.toLowerCase().startsWith(inputValue.toLowerCase())
				})
				.map((country: Country) => {
					return { value: country.id, label: country.name }
				})
		)
	}

	const handleBadNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setState({ ...state, badName: event.target.value })
	}

	const handleGoodNameChange = (countryId: number): void => {
		setState({ ...state, countryId: countryId })
	}

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		e.preventDefault()
		const { badName, countryId } = state

		if (!badName || countryId < 0) {
			return
		}

		const countryData = {
			badName,
			countryId
		}

		await APIRequest.Countries.fix(countryData)
	}

	const { badName } = state

	return (
		<Modal name="AddNewCountryModal" title="Add Bad Country">
			<form className={Styles.form} onSubmit={handleSubmit}>
				<TextField placeholder="Bad Name" type="text" name="badName" onChange={handleBadNameChange} value={badName} />
				<ReactSelectAsync
					id="goodName"
					loadOptions={loadOptions}
					name="goodName"
					matchPos="start"
					labelKey="type"
					matchProp="value"
					noOptionsMessage={(): string => 'Start typing..'}
					isClearable={true}
					onChange={(country: any): void => {
						handleGoodNameChange(country ? country.value : '')
					}}
				/>
				<Button className={Styles['button']} type="submit">
					Save
				</Button>
			</form>
		</Modal>
	)
}
