import { APIRequest } from '@lynx/client-core/src/api'
import { Logger } from '@lynx/client-core/src/modules'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ApplicationState } from 'store'
import { OrganisationNextCloudDomainModalContext } from 'store/modal'
import {
	LOAD_ORGANISATIONS,
	LOAD_ORGANISATION_DETAILS,
	OrganisationsActionTypes,
	OrganisationsState,
	ORGANISATION_AVATAR_UPDATED,
	UPDATE_ORGANISATION_DATA,
	USER_ACCEPTED_INVITE
} from './types'
import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'

export const userAcceptedInvite = (userInvitePayload: OrganisationsState['userAcceptedInvite']): OrganisationsActionTypes => {
	return {
		type: USER_ACCEPTED_INVITE,
		payload: userInvitePayload
	}
}

const loadOrganisationsInternal = (organisations: OrganisationRedux[]): OrganisationsActionTypes => {
	return {
		type: LOAD_ORGANISATIONS,
		payload: organisations
	}
}

export const updateOrganisationData = (organisation: OrganisationRedux): OrganisationsActionTypes => {
	return {
		type: UPDATE_ORGANISATION_DATA,
		payload: organisation
	}
}

export const setActiveOrganisation = (orgId: number): OrganisationsActionTypes => {
	return {
		type: LOAD_ORGANISATION_DETAILS,
		payload: orgId
	}
}

export const loadOrganisations =
	(activeOrgId?: number): ThunkAction<Promise<OrganisationRedux[]>, ApplicationState, null, Action<string>> =>
	async (dispatch, getState): Promise<OrganisationRedux[]> => {
		try {
			const response = await APIRequest.Organisations.getOrganisationsByUser()
			if (!response?.data) {
				return []
			}

			dispatch(loadOrganisationsInternal(response.data))
			const { organisations, activeOrganisationId } = getState().organisations
			if (organisations.length || activeOrgId) {
				const orgId = activeOrganisationId || activeOrgId || organisations[0].id
				dispatch(setActiveOrganisation(orgId))
			}
			return response.data
		} catch (e) {
			Logger.error(e)
			return []
		}
	}

interface CreateOrganisationOptions {
	name: string
	address: string
	postcode: string
	country: string
	termsAgree: boolean
	nextCloudOrganisationInitialData: OrganisationNextCloudDomainModalContext | null
	entrypoint: string
	issuer: string
	certificate: string
	samlDomains: string[]
	isUCS: boolean
	isIserv: boolean
	oauthClientIdStr: string
	oauthClientSecretStr: string
	oauthAuthorizationUrlStr: string
	oauthTokenUrlStr: string
	oauthUserUrlStr: string
	oauthDomainsStr: string[]
	isEntraId: boolean
	entraIdTenantId: string
	entraIdDomainsStr: string[]
}

export const createOrganisation =
	(organisationOptions: CreateOrganisationOptions): ThunkAction<Promise<OrganisationRedux[]>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<OrganisationRedux[]> => {
		try {
			const {
				name,
				address,
				postcode,
				country,
				termsAgree,
				nextCloudOrganisationInitialData,
				entrypoint,
				issuer,
				certificate,
				samlDomains,
				isUCS,
				isIserv,
				oauthClientIdStr,
				oauthClientSecretStr,
				oauthAuthorizationUrlStr,
				oauthTokenUrlStr,
				oauthUserUrlStr,
				oauthDomainsStr,
				isEntraId,
				entraIdTenantId,
				entraIdDomainsStr
			} = organisationOptions

			const response = await APIRequest.Organisations.createOrganisation(
				name,
				address,
				postcode,
				country,
				termsAgree,
				nextCloudOrganisationInitialData?.nextCloudDomain,
				nextCloudOrganisationInitialData?.nextCloudEmailDomains || [],
				nextCloudOrganisationInitialData?.nextCloudOrganisationId,
				entrypoint,
				issuer,
				certificate,
				samlDomains,
				isUCS,
				isIserv,
				oauthClientIdStr,
				oauthClientSecretStr,
				oauthAuthorizationUrlStr,
				oauthTokenUrlStr,
				oauthUserUrlStr,
				oauthDomainsStr,
				isEntraId,
				entraIdTenantId,
				entraIdDomainsStr
			)
			if (!response?.data) {
				return []
			}

			const organisations = await dispatch(loadOrganisations())

			response.data.id && dispatch(setActiveOrganisation(Number(response.data.id)))
			return organisations
		} catch (e) {
			Logger.error(e)
			return []
		}
	}

export const deleteOrganisation =
	(organisationId: number): ThunkAction<Promise<OrganisationRedux[]>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<OrganisationRedux[]> => {
		try {
			await APIRequest.Organisations.deleteOrganisation(organisationId)

			const organisations = await dispatch(loadOrganisations())

			return organisations
		} catch (e) {
			Logger.error(e)
			return []
		}
	}

export const deleteOrganisationDrive =
	(organisationId: number): ThunkAction<Promise<OrganisationRedux[]>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<OrganisationRedux[]> => {
		try {
			await APIRequest.Organisations.deleteDrive(organisationId)

			return await dispatch(loadOrganisations())
		} catch (e) {
			Logger.error(e)
			return []
		}
	}

export const createOrganisationDrive =
	(organisationId: number): ThunkAction<Promise<OrganisationRedux[]>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<OrganisationRedux[]> => {
		try {
			await APIRequest.Organisations.createDrive(organisationId)

			return await dispatch(loadOrganisations())
		} catch (e) {
			Logger.error(e)
			return []
		}
	}

export const updateOrganisation =
	(
		id: number,
		organisationName: string,
		address: string,
		postCode: string,
		country: string
	): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<void> => {
		try {
			await APIRequest.Organisations.updateOrganisation(id, organisationName, address, postCode, country, '')
			await dispatch(loadOrganisations())
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

export const organisationAvatarUpdated = (organisationId: number): OrganisationsActionTypes => {
	return {
		type: ORGANISATION_AVATAR_UPDATED,
		payload: organisationId
	}
}

export const deleteOrganisationUser =
	(organisationId: number, userId: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.deleteOrganisationUser(organisationId, userId)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const disableOrganisationUser =
	(organisationId: number, userId: string, reason: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.disableOrganisationUser(organisationId, userId, reason)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const enableOrganisationUser =
	(organisationId: number, userId: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.enableOrganisationUser(organisationId, userId)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const createGroup =
	(organisationId: number, groupName: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.addGroup(organisationId, groupName)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const renameGroup =
	(organisationId: number, groupId: string, groupName: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.renameGroup(organisationId, groupId, groupName)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const deleteGroup =
	(organisationId: number, groupId: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.deleteGroup(organisationId, groupId)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const removeInvite =
	(organisationId: number, inviteId: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.declineOrganisationInvite(organisationId, inviteId)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const sendInvite =
	(organisationId: number, email: string, groupId: number | null, message: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.inviteOrganisationUser(organisationId, email, groupId, undefined, message)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const addEmailDomain =
	(organisationId: number, domain: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch, getState: () => ApplicationState): Promise<boolean> => {
		const organisation = getState().organisations.organisations.find((org) => org.id === organisationId)
		if (!organisation) return false
		const { provider } = organisation

		try {
			const response = await APIRequest.Organisations.addEmailDomain(
				Number(organisationId),
				domain,
				provider === 'nextcloud',
				provider === 'saml',
				provider === 'oauth',
				provider === 'entraId'
			)

			if (!response.error) {
				await dispatch(loadOrganisations())
				return true
			}
			return false
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const removeEmailDomain =
	(organisationId: number, domainId: number): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.removeEmailDomain(Number(organisationId), domainId)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const updateOrganisationGroupUsers =
	(
		organisationId: number,
		groupId: number,
		removedUserIds: string[],
		addedUserIds: string[]
	): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			if (!removedUserIds.length && !addedUserIds.length) return false
			for (const userId of removedUserIds) {
				await APIRequest.Organisations.deleteUserInGroup(organisationId, String(groupId), userId)
			}

			for (const userId of addedUserIds) {
				await APIRequest.Organisations.addUserToGroup(organisationId, String(groupId), userId, true, false, false)
			}
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const verifyDomain =
	(organisationId: number, domainId: number): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			const response = await APIRequest.Organisations.verifyDomain(organisationId, domainId)
			if (response.data) {
				await dispatch(loadOrganisations())
				return true
			} else {
				return false
			}
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const updateOrgUser =
	(
		organisationId: number,
		userId: string,
		payload: { isOwner?: boolean; isAssignedPro?: boolean }
	): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			await APIRequest.Organisations.updateOrganisationUser(organisationId, userId, payload)
			await dispatch(loadOrganisations())
			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}
