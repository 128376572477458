import { DriveItemType } from '@lynx/client-core/src/api/interfaces'
import React from 'react'
import { useItemMenuActions } from 'pages/Drives/hooks/useItemMenuActions'
import css from './DriveDropdownItems.module.scss'
import { DriveItemActionType } from './DriveList/Drive.types'
interface DriveDropdownItemsProps {
	driveItem: DriveItemType
	handleItemClick: (action: DriveItemActionType, driveItem: DriveItemType) => void
	classes: string
}

export const DriveDropdownItems = ({ driveItem, handleItemClick, classes }: DriveDropdownItemsProps): React.ReactElement => {
	const { actions } = useItemMenuActions(driveItem)
	return (
		<React.Fragment>
			{actions.map((menuItem) => {
				const { key, img, name } = menuItem
				const imgInstance = React.createElement(img)
				return (
					<li
						key={key}
						className={classes}
						onClick={(): void => {
							handleItemClick(key, driveItem)
						}}
					>
						{imgInstance}
						<div className={css.itemText}>{name}</div>
					</li>
				)
			})}
		</React.Fragment>
	)
}
