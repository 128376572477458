import { Logger } from '../modules/Logger'
import { Cookie } from './Cookie'

enum Tokens {
	refreshToken = 0,
	accessToken = 1
}

export class AuthCookie extends Cookie {
	public static getDomain = (environment: string): string => {
		let domain = 'localhost'
		switch (environment) {
			case 'dev':
				domain = 'localhost'
				break
			case 'nightly':
				domain = '.nightly.lynxcloud.app'
				break
			case 'beta':
				domain = '.beta.lynxcloud.app'
				break
			case 'production':
				domain = '.lynxcloud.app'
				break
		}
		return domain
	}

	public static create(environment: string, accessToken: string, refreshToken: string): void {
		const domain = AuthCookie.getDomain(environment)
		const bridge = refreshToken + ' ' + accessToken + ' ' + environment

		AuthCookie.set('lynxbridge', btoa(bridge), {
			SameSite: 'Strict',
			Path: '/',
			Domain: domain
		})
	}

	public static getToken(environment: string, token: Tokens): string {
		const cookie = Cookie.get('lynxbridge', environment)
		if (!cookie || cookie === 'LOGGED_OUT') {
			return ''
		}

		const tokens = atob(cookie).split(' ')

		if (tokens.length < 2) {
			Logger.error('token length error')
			return ''
		}

		return tokens[token]
	}

	public static getRefreshToken(environment: string): string {
		return AuthCookie.getToken(environment, Tokens.refreshToken)
	}

	public static getAccessToken(environment: string): string {
		return AuthCookie.getToken(environment, Tokens.accessToken)
	}
}
