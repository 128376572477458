import { Modal } from '@lynx/client-core/src/components'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { SectionNavigator } from 'components/Sections'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import {
	EntraIdDomains,
	EntraIdSetup,
	NextCloudDomain,
	OauthDomains,
	OauthSetup,
	OrganisationDetails,
	OrganisationTypeSelector,
	SamlDomain,
	SamlIntroduction,
	SamlSetup
} from './components'

const SectionNavigatorConfig = {
	EntraIdSetup,
	EntraIdDomains,
	NextCloudDomain,
	OrganisationDetails,
	SamlIntroduction,
	SamlSetup,
	SamlDomain,
	OauthSetup,
	OauthDomains,
	OrganisationTypeSelector
}

export const OrganisationCreateModal = (): React.ReactElement => {
	const { profile: profileState } = useSelector((state: ApplicationState) => state)
	const previousShowLoginModal = usePrevious(profileState.showLoginModal)
	const [section, setSection] = useState('OrganisationTypeSelector')
	const { t } = useTranslation()

	useEffect(() => {
		if (!previousShowLoginModal && profileState.showLoginModal) {
			handleVisible()
		}
	}, [profileState.showLoginModal])

	const handleVisible = async (context?: unknown): Promise<void> => {
		if (!context) {
			return
		}

		const ctx = context as { section: string }
		const { section } = ctx

		setSection(section)
	}

	return (
		<Modal name="OrganisationCreateModal" title={t('pages.organisations.createOrganisation')}>
			<SectionNavigator config={SectionNavigatorConfig} section={section} />
		</Modal>
	)
}
