import { APIRequest } from '@lynx/client-core/src/api'
import { ClientProviders } from '@lynx/client-core/src/api/interfaces'
import { BasicButton, Input, Modal } from '@lynx/client-core/src/components'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { Logger } from '@lynx/client-core/src/modules'
import { useThunkDispatch } from 'hooks'
import i18next from 'i18next'
import QueryString from 'qs'
import React, { ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ApplicationState } from 'store'
import { refreshDrivePath } from 'store/drive'
import { hideModal } from 'store/modal'

interface CreateNewFolderState {
	currentPathId: string
	name: string
	provider: ClientProviders
}

export const CreateNewFolderModal = (): React.ReactElement => {
	const { drive } = useSelector((state: ApplicationState) => state)
	const [state, setState] = useState<CreateNewFolderState>({
		currentPathId: '',
		name: '',
		provider: null
	})
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()

	const handleSubmit = async (): Promise<void> => {
		const { name } = state
		const { provider, currentPath } = drive

		try {
			const query = QueryString.parse(window.location.search.substring(1)) as { driveId?: string }
			await APIRequest.ObjectStore.createFolder(provider, currentPath.id, name, query.driveId)

			await dispatchThunk(refreshDrivePath())
			dispatch(hideModal())
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setState((prevState) => ({
			...prevState,
			name: event.target.value
		}))
	}

	return (
		<Modal name="CreateNewFolderModal" hideCloseButton>
			<ModalDefaultLayout
				title={i18next.t('components.modals.createNewFolderModal.title')}
				body={<Input onChange={handleChange} value={state.name || ''} label={i18next.t('components.modals.createNewFileModal.name')} autoFocus />}
				footer={
					<BasicButton onClick={handleSubmit} variant="blue">
						{i18next.t('components.modals.createNewFileModal.create')}
					</BasicButton>
				}
			/>
		</Modal>
	)
}
