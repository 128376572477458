import { ImageSection } from './Sections'
import { SectionNavigator } from '../../Sections'
import { Modal } from '../../Modal'
import { usePrevious } from '../../../hooks'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { ApplicationState } from '../../../store'
import { setLoginModal } from '../../../store/profile'
import css from './LoginModal.module.css'
import { RecoverPasswordSection, SignInSection, SignUpSection, VerificationSection, EmailSection, QRSection, NextCloudSection } from './Sections'

const SectionNavigatorConfig = {
	SignInSection: SignInSection,
	SignUpSection: SignUpSection,
	RecoverPasswordSection: RecoverPasswordSection,
	VerificationSection: VerificationSection,
	EmailSection: EmailSection,
	QRSection: QRSection,
	NextCloudSection: NextCloudSection
}

export const LoginModal = (): React.ReactElement | null => {
	const [section, setSection] = useState('SignInSection')
	const params = useParams<{ bannerId: string }>()
	const { profile: profileState } = useSelector((state: ApplicationState) => state)
	const previousShowLoginModal = usePrevious(profileState.showLoginModal)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!previousShowLoginModal && profileState.showLoginModal) {
			handleVisible()
		}
	}, [profileState.showLoginModal])

	const handleVisible = async (context?: unknown): Promise<void> => {
		if (!context) {
			return
		}

		const ctx = context as { section: string }
		const { section } = ctx

		setSection(section)
	}

	const handleHidden = async (): Promise<void> => {
		if (profileState.stickyLoginModal) {
			return
		}
		dispatch(setLoginModal(false))
	}

	if (!profileState.showLoginModal) {
		return null
	}

	const modalClasses = [css.modal]
	if (params.bannerId) modalClasses.push(css['modal-plus'])

	const isWindowTaller = window.innerHeight > 600
	if (isWindowTaller) modalClasses.push(css.modalsMargin)

	return (
		<Modal uncontrolled={true} name="LoginModal" variant="purple" className={modalClasses.join(' ')} onVisible={handleVisible} onHidden={handleHidden}>
			<div className={css['container']}>
				<ImageSection />
				<SectionNavigator config={SectionNavigatorConfig} section={section} />
			</div>
		</Modal>
	)
}
