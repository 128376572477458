import React, { useState, forwardRef, ForwardedRef } from 'react'
import { useSelector } from 'react-redux'
import css from './DriveItem.module.scss'
import { FileIcon, FileImageIcon, ImageIcon } from '../'
import { DriveItemType } from '../../api/interfaces'
import { AuthCookie } from '../../modules'
import { ApplicationState } from '../../store'

interface Props {
	item: DriveItemType
}

export const DriveItemDefaultThumbnail = forwardRef(function DriveItemDefaultThumbnail(
	{ item }: Props,
	ref: ForwardedRef<HTMLImageElement> | undefined
): React.ReactElement {
	const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 })
	const { thumbnail: thumbnailUrl } = item
	const { config: configState } = useSelector((state: ApplicationState) => state)
	const [thumbnailError, setThumbnailError] = useState(false)

	const { ENVIRONMENT } = configState
	const accessToken = AuthCookie.getAccessToken(ENVIRONMENT)

	const attachTokenToUrl = (url: string): string => {
		switch (item.provider) {
			case 'lynxcloud':
			case 'organisation': {
				const urlObject = new URL(url)
				urlObject.searchParams.append('t', accessToken)
				return urlObject.toString()
			}
			default:
				return url
		}
	}
	const defaultIcon = item.mimeType.includes('image') ? <FileImageIcon className={css.thumbnailIcon} /> : <FileIcon className={css.thumbnailIcon} />

	const onDragStart = (e: React.DragEvent<HTMLImageElement>): void => {
		if (!thumbnailUrl) return
		e.dataTransfer.setData('text/plain', 'null')
		e.dataTransfer.setData('text/uri-list', 'null')
		e.dataTransfer.setData(
			'text/html',
			`<img src="${attachTokenToUrl(thumbnailUrl)}" width="${imageDimensions.width}" height="${imageDimensions.height}" />`
		)
	}

	const onImageImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>): void => {
		const target = e.target as HTMLImageElement
		setImageDimensions({ width: target.naturalWidth, height: target.naturalHeight })
	}

	const thumbnail = thumbnailUrl ? (
		<img
			onDragStart={onDragStart}
			ref={ref}
			draggable={true}
			className={css.fileThumbnail}
			width="120"
			height="80"
			onLoad={onImageImageLoad}
			alt="thumbnail"
			src={attachTokenToUrl(thumbnailUrl)}
			onError={(): void => setThumbnailError(true)}
		/>
	) : (
		defaultIcon
	)

	return thumbnailError ? <ImageIcon className={[css.thumbnailIcon, css.thumbnailFileIcon].join(' ')} /> : thumbnail
})
