import { BaseCall } from '../BaseCall'
import { APIConfig } from '../interfaces'

export class LynxVnc extends BaseCall {
	public apply = async (
		fileId: string,
		provider: string,
		linuxUser: string,
		shareId?: string,
		shareOwnerId?: string,
		paths?: string
	): Promise<{ data: { file: string; auth: string } }> => {
		const result = await this.client.post('/lynxvnc/apply', {
			fileId,
			provider,
			linuxUser,
			shareId,
			shareOwnerId,
			paths
		})
		return result.data
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
