import { BasicButton, EditIcon, MapPinIcon, ProBadgeIcon } from '@lynx/client-core/src/components'
import { showModal } from '@lynx/client-core/src/store/modal'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import css from './ActiveOrganisation.module.scss'
import { DriveUsage } from './components'
import { useTranslation } from 'react-i18next'
import { OrganisationAvatar } from 'pages/Organisations/components/OrganisationAvatar'

interface ActiveOrganisationProps {
	className?: string
}

export const ActiveOrganisation = ({ className }: ActiveOrganisationProps): React.ReactElement | null => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const organisation = useCurrentOrganisation()
	if (!organisation) return null

	const { organisationName, countryName } = organisation

	const handleEditOrganisationClicked = (): void => {
		dispatch(
			showModal({
				name: 'OrganisationEditModal'
			})
		)
	}

	const handleViewPlansClicked = (): void => {
		navigate('/plans')
	}

	const handleUpgradeClicked = (): void => {
		navigate(`/contact-us?organisationId=${organisation.id}`)
	}

	const classNames = [css['container']]
	className && classNames.push(className)

	const { isPro, isOwner, avatarUrl } = organisation

	return (
		<div className={classNames.join(' ')}>
			<div className={css['container__view']}>
				<div className={css['container__view__icon']}>
					{avatarUrl && <OrganisationAvatar avatarUrl={avatarUrl} />}
					{isPro && <ProBadgeIcon className={css['container__view__icon__pro']} />}
				</div>
				<h4>{organisationName}</h4>
				<div className={css['container__view__location']}>
					<div>
						<MapPinIcon />
						<span>{countryName}</span>
					</div>
					{isOwner && <EditIcon onClick={handleEditOrganisationClicked} />}
				</div>
			</div>
			<hr />
			<DriveUsage />
			<div className={css['buttons']} style={{ display: isPro ? 'none' : 'flex' }}>
				<BasicButton onClick={handleViewPlansClicked}>View Plans</BasicButton>
				<BasicButton variant="pro" onClick={handleUpgradeClicked}>
					{t('pages.organisations.upgrade')}
				</BasicButton>
			</div>
		</div>
	)
}
