import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

export const RegReport = (): React.ReactElement => {
	const navigate = useNavigate()
	const [report, setReport] = useState([])
	const columns = ['Lynx4Code', 'UsageCount']

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const data = await APIRequest.Reports.getRegReport({
				draw: '1',
				'columns[0][data]': 'Lynx4Code',
				'columns[0][name]': '',
				'columns[0][searchable]': 'true',
				'columns[0][orderable]': 'true',
				'columns[0][search][value]': '',
				'columns[0][search][regex]': 'false',
				'columns[1][data]': 'UsageCount',
				'columns[1][name]': '',
				'columns[1][searchable]': 'true',
				'columns[1][orderable]': 'true',
				'columns[1][search][value]': '',
				'columns[1][search][regex]': 'false',
				'order[0][column]': '1',
				'order[0][dir]': 'desc',
				start: '0',
				length: '10',
				'search[value]': '',
				'search[regex]': 'false'
			})
			setReport(data.data)
		}
		mount()
	}, [])

	const goToDetails = (data: any): void => {
		navigate('/reports/reg-report-detail/' + data.Lynx4Code)
	}

	return (
		<Document title="Registration Report" description="Registration Report" keywords="Registration Report" disableOverflow={true}>
			<Card>
				<Table onRowClick={goToDetails} title={'Reg Report'} headers={columns.map((v) => ({ label: v, valueKey: v }))} items={report} />
			</Card>
		</Document>
	)
}
