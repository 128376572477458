import { Document } from '@lynx/client-core/src/components'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Route, Routes } from 'react-router-dom'
import { ApplicationState } from 'store'
import Styles from './Admin.module.scss'
import { ElmstonePages } from './routes/elmstone/ElmstonePages'
import { ReportsPages } from './routes/reports/ReportsPages'
import { SaharaPages } from './routes/sahara/SaharaPages'
import { useSearchParams } from 'react-router-dom'
import { BoxLightCustomerServicePermission } from '@lynx/core/src/constants'

const panels = [
	{
		title: 'Reports',
		permissions: [],
		roles: [0, 1, 2],
		links: [
			{
				name: 'Reg Report',
				uri: '/admin/reports/reg-report',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'Reg Report by Month',
				uri: '/admin/reports/reg-report-by-month',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'Reg Report by Version',
				uri: '/admin/reports/reg-report-by-version',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'Reg Report by Country',
				uri: '/admin/reports/reg-report-by-country',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'Reg Report by OS',
				uri: '/admin/reports/reg-report-by-os',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'Reg Report by Recent Usage',
				uri: '/admin/reports/reg-report-recent-multiple-usage',
				permissions: [],
				roles: [0, 1, 2]
			}
		]
	},
	{
		title: 'Sahara',
		permissions: [],
		roles: [0, 1, 2, 4],
		links: [
			{
				name: 'Charts',
				uri: '/admin/sahara/charts',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'LYNX Registrations',
				uri: '/admin/sahara/registrations',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'Organisation Report',
				uri: '/admin/sahara/organisation-report',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'Mailing List',
				uri: '/admin/sahara/mailing-list',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'Whiteboard Translations',
				uri: '/admin/sahara/translations',
				permissions: [],
				roles: [0, 1, 2, 4]
			},
			{
				name: 'Website Translations',
				uri: '/admin/sahara/lynxTranslations',
				permissions: [],
				roles: [0, 1, 2, 4]
			},
			{
				name: 'Subscriptions',
				uri: '/admin/elmstone/subscriptions?hideList=true',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'Payment Audit Trail',
				uri: '/admin/elmstone/payment-audit-trail?hideList=true',
				permissions: [BoxLightCustomerServicePermission.dbValue],
				roles: [0, 1, 2]
			},
			{
				name: 'Enquiries',
				uri: '/admin/elmstone/enquiries',
				permissions: [],
				roles: [0, 1, 2]
			},
			{
				name: 'MyScript Report',
				uri: '/admin/sahara/myscript',
				permissions: [],
				roles: [0, 1, 2]
			}
		]
	},
	{
		title: 'Elmstone',
		permissions: [],
		roles: [0],
		links: [
			{
				name: 'Recent Sahara Users',
				uri: '/admin/elmstone/recent-sahara-users',
				permissions: [],
				roles: [0]
			},
			{
				name: 'Country Fix',
				uri: '/admin/elmstone/country-fix',
				permissions: [],
				roles: [0]
			},
			{
				name: 'LYNX 6 Code Generator',
				uri: '/admin/elmstone/code-gen',
				permissions: [],
				roles: [0]
			},
			{
				name: 'Feedback Log',
				uri: '/admin/elmstone/feedback',
				permissions: [],
				roles: [0]
			},
			{
				name: 'Sites',
				uri: '/admin/elmstone/sites',
				permissions: [],
				roles: [0]
			},
			{
				name: 'LYNX WASM',
				uri: '/admin/sahara/wasm?hideList=true',
				isFullReload: true,
				permissions: [],
				roles: [0]
			},

			{
				name: 'Base64 Decoder',
				uri: '/admin/elmstone/base64',
				permissions: [],
				roles: [0]
			}
		]
	}
]
export const Admin = (): React.ReactElement => {
	const { role, permissions: userPermissions } = useSelector((state: ApplicationState) => state.profile)

	const [searchParams] = useSearchParams()
	const isHideList = !(searchParams.get('hideList') === 'true')

	const isAccessible = (placePermissions: number[], placeRoles: number[]): boolean => {
		const hasRole = placeRoles.includes(role)
		const hasPermission = placePermissions.length
			? placePermissions.some((permission) => userPermissions.some((userPermission) => userPermission === permission))
			: true
		return hasRole && hasPermission
	}
	const panelElements = panels
		.filter((panel) => isAccessible(panel.permissions, panel.roles))
		.map((panel) => {
			const { links, title } = panel
			const uriElements = links
				.filter((link) => isAccessible(link.permissions, link.roles))
				.map((uriDefinition, key) => {
					const { uri, name } = uriDefinition
					const isFullReload = 'isFullReload' in uriDefinition && uriDefinition.isFullReload
					return <li key={key}>{isFullReload ? <a href={uri}>{name}</a> : <NavLink to={uri}>{name}</NavLink>}</li>
				})
			return (
				<div key={title}>
					<div>
						<h3>{title}</h3>
						<ul>{uriElements}</ul>
					</div>
				</div>
			)
		})
	return (
		<Document title="Admin">
			<div className={Styles.versionContainer}>Version {process.env.REACT_APP_BUILD_VERSION}</div>
			{isHideList && <div className={Styles['cards']}>{panelElements}</div>}
			<Routes>
				<Route path="elmstone/*" element={<ElmstonePages />} />
				<Route path="sahara/*" element={<SaharaPages />} />
				<Route path="reports/*" element={<ReportsPages />} />
			</Routes>
		</Document>
	)
}
