import { APIRequest } from '@lynx/client-core/src/api'
import { GetSamlOrganisationData } from '@lynx/client-core/src/api/interfaces'
import { BasicButton, Input, Modal, Textarea } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { ErrorMessage, Form, Formik } from 'formik'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { hideModal } from 'store/modal'
import { object, string } from 'yup'
import css from './SAMLConfigurationModal.module.scss'

export const samlOrganisationSchema = object({
	issuer: string().required('Issuer is a required field'),
	entryPoint: string().required('Entrypoint is a required field')
})

export const SAMLConfigurationModal = (): ReactElement => {
	const dispatch = useDispatch()
	const organisation = useCurrentOrganisation()
	const [samlData, setSamlData] = useState<GetSamlOrganisationData['samlDetails'] | null>(null)

	const { t } = useTranslation()

	const handleVisible = async (): Promise<void> => {
		if (!organisation?.id) {
			return
		}

		const samlDetails = await APIRequest.Organisations.getSamlOrganisationDetails(organisation.id)
		if (!samlDetails?.data) {
			Logger.error('no saml data')
			return
		}

		setSamlData(samlDetails.data.samlDetails)
	}

	const handleFormSubmitted = async ({ issuer, entryPoint, cert }: { issuer: string; entryPoint: string; cert?: string }): Promise<void> => {
		if (!organisation) return
		await APIRequest.Organisations.updateSamlSettings(organisation.id, entryPoint, issuer, cert)
		dispatch(hideModal())
	}

	return (
		<Modal name="SAMLConfigurationModal" onVisible={handleVisible} title={t('pages.organisation.samlSettings')}>
			<Formik
				initialValues={{ issuer: samlData?.issuer || '', entryPoint: samlData?.loginUrl || '', cert: '' }}
				validationSchema={samlOrganisationSchema}
				onSubmit={(values, actions): void => {
					handleFormSubmitted(values)
					actions.setSubmitting(false)
				}}
				enableReinitialize={true}
			>
				{({ isSubmitting, handleChange, values, errors, touched, isValid, dirty }): React.ReactNode => (
					<Form className={css['container']}>
						<div>
							<label htmlFor="entryPoint">{t('pages.organisation.samlEntrypoint')}</label>
							<Input
								value={values.entryPoint}
								type="search"
								name="entryPoint"
								onChange={handleChange}
								inError={(errors.entryPoint && touched.entryPoint) || false}
							/>
							<ErrorMessage name="entryPoint" component="span" />
						</div>
						<div>
							<label htmlFor="issuer">{t('pages.organisation.samlIssuer')}</label>
							<Input
								value={values.issuer}
								type="search"
								name="issuer"
								onChange={handleChange}
								inError={(errors.issuer && touched.issuer) || false}
							/>
							<ErrorMessage name="issuer" component="span" />
						</div>
						<div>
							<label htmlFor="issuer">{t('pages.organisation.samlCert')}</label>
							<Textarea name="cert" placeholder="Hidden for security reasons" value={values.cert} onChange={handleChange} />
							<ErrorMessage name="cert" component="span" />
						</div>
						<div className={css['buttons']}>
							<BasicButton
								onClick={(): void => {
									dispatch(hideModal())
								}}
							>
								{t('pages.organisations.createModal.back')}
							</BasicButton>
							<BasicButton variant={isValid ? 'blue' : 'transparent'} disabled={isSubmitting || !dirty} type="submit">
								{t('pages.organisations.save')}
							</BasicButton>
						</div>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}
