import React from 'react'

export const FreeBadgeIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="77" height="37" viewBox="0 0 77 37" fill="none" {...props}>
			<path
				d="M19.8999 0.481445H57.2999C68.0699 0.481445 76.7999 8.53944 76.7999 18.4814C76.7999 28.4234 68.0699 36.4814 57.2999 36.4814H19.8999C9.1299 36.4814 0.399902 28.4234 0.399902 18.4814C0.399902 8.53944 9.1299 0.481445 19.8999 0.481445Z"
				fill="#E7E7E7"
			/>
			<path d="M23.1999 9.8584V12.6484H16.0169V17.0654H21.5169V19.8034H16.0169V27.0854H12.5569V9.8584H23.1999Z" fill="black" />
			<path
				d="M34.5259 27.0922L30.7259 20.3802H29.0979V27.0922H25.6379V9.85724H32.103C33.2759 9.82274 34.4405 10.0642 35.5029 10.5622C36.3781 10.9727 37.1115 11.634 37.6099 12.4622C38.1165 13.3482 38.3589 14.3606 38.3084 15.3799C38.2579 16.3992 37.9166 17.3827 37.325 18.2142C36.6005 19.1734 35.5583 19.8434 34.3849 20.1042L38.5099 27.0942H34.5099L34.5259 27.0922ZM29.0959 17.7842H31.9839C32.3616 17.8144 32.7416 17.7697 33.1019 17.6526C33.4623 17.5356 33.796 17.3486 34.0839 17.1022C34.322 16.8511 34.506 16.5538 34.6246 16.2287C34.7433 15.9036 34.7941 15.5577 34.7739 15.2122C34.7957 14.8746 34.7454 14.5363 34.6264 14.2196C34.5075 13.9029 34.3226 13.6151 34.0839 13.3752C33.4938 12.896 32.7418 12.6621 31.9839 12.7222H29.0959V17.7842Z"
				fill="black"
			/>
			<path d="M44.9209 12.6494V16.9674H50.7259V19.7074H44.9209V24.2744H51.4609V27.0874H41.4609V9.8374H51.4609V12.6504H44.9209V12.6494Z" fill="black" />
			<path d="M58.0518 12.6494V16.9674H63.8568V19.7074H58.0518V24.2744H64.5918V27.0874H54.5918V9.8374H64.5918V12.6504H58.0518V12.6494Z" fill="black" />
		</svg>
	)
}
