import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Charts, MailingList, OrganisationsReport, LynxTranslations, Registrations, Translations, Wasm, MyScriptReport } from '.'

export const SaharaPages = (): React.ReactElement => {
	return (
		<Routes>
			<Route path="charts" element={<Charts />} />
			<Route path="mailing-list" element={<MailingList />} />
			<Route path="registrations" element={<Registrations />} />
			<Route path="organisation-report" element={<OrganisationsReport />} />
			<Route path="translations" element={<Translations />} />
			<Route path="lynxTranslations" element={<LynxTranslations />} />
			<Route path="wasm" element={<Wasm />} />
			<Route path="myscript" element={<MyScriptReport />} />
		</Routes>
	)
}
