import css from './LynxPill.module.scss'

export const Pill = ({
	backColor,
	foreColor = '#000000',
	text,
	size = 'sm',
	bold = false
}: {
	backColor: string
	foreColor?: string
	text: string
	size?: 'xs' | 'sm' | 'md' | 'lg'
	bold?: boolean
}): React.ReactElement => {
	return (
		<div className={[css['pill'], css[size]].join(' ')} style={{ backgroundColor: backColor, color: foreColor, fontWeight: bold ? 'bold' : 'normal' }}>
			{text}
		</div>
	)
}
