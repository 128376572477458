import { useOutsideClick } from '@lynx/client-core/src/hooks'
import { useThunkDispatch } from 'hooks'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ApplicationState } from 'store'
import { getNotifications } from 'store/notifications'
import { NotificationsList } from '..'
import { NotificationsIcon } from '../'
import css from './NotificationMenuItem.module.css'

export const NotificationMenuItem = (): React.ReactElement => {
	const {
		notifications: { notificationsData },
		profile
	} = useSelector((state: ApplicationState) => state)
	const childrenContainerRef = React.useRef<HTMLDivElement>(null)
	const dispatchThunk = useThunkDispatch()

	const mount = async (): Promise<void> => {
		profile.loggedIn && (await dispatchThunk(getNotifications(6)))
	}

	useEffect(() => {
		mount()
	}, [])

	const [isListVisible, setIsListVisible] = useState(false)

	useOutsideClick(childrenContainerRef, () => {
		setIsListVisible(false)
	})

	return (
		<div className={css.menuContainer} ref={childrenContainerRef}>
			<NotificationsIcon
				onClick={(): void => {
					setIsListVisible((isVisible) => !isVisible)
				}}
			/>
			{isListVisible && (
				<div className={css.container}>
					<div className={css.listContainer}>
						<NotificationsList maxItemsCount={6} />
						{Boolean(notificationsData.length) && (
							<Link
								to="/notifications"
								className={css.seeRecentActivity}
								onClick={(): void => {
									setIsListVisible(false)
								}}
							>
								{i18next.t('components.notifications.seeRecentActivity')}
							</Link>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
