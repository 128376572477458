import { LynxBanner } from '@lynx/client-core/src/components/Icons'
import * as React from 'react'
import Styles from './Logo.module.scss'

interface LogoProps {
	onClick: () => void
}

export const Logo = ({ onClick }: LogoProps): React.ReactElement => {
	return (
		<div className={Styles['container']} onClick={onClick}>
			<LynxBanner />
		</div>
	)
}
