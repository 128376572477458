import { JoinSession, LynxBannerLight } from '@lynx/client-core/src/components'
import React from 'react'
import css from './Join.module.scss'
import { JoinSection } from './JoinSection'

export const Join = (): React.ReactElement | null => {
	return (
		<div className={css['container']}>
			<div className={css['container__grid']}>
				<div className={css['container__image']}>
					<LynxBannerLight />
					<JoinSession />
				</div>

				<JoinSection />
			</div>
		</div>
	)
}
