export const homeTitlesMapping = {
	google: {
		name: 'Google Drive'
	},
	dropbox: {
		name: 'Dropbox'
	},
	onedrive: {
		name: 'OneDrive'
	},
	home: {
		name: 'Home'
	},
	amazon: {
		name: 'Amazon Drive'
	},
	lynxcloud: {
		name: 'LYNX Cloud'
	},
	nextcloud: {
		name: 'NextCloud'
	},
	organisation: {
		name: 'Organisation'
	}
}
