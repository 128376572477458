import React from 'react'

export const ProOrgBadgeIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
	const randomId = `id-${Math.random().toString(16).slice(2)}`
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="212" height="36" viewBox="0 0 212 36" fill="none" {...props}>
			<path
				d="M19.5 0H191.9C202.67 0 211.4 8.058 211.4 18C211.4 27.942 202.67 36 191.9 36H19.5C8.73 36 0 27.942 0 18C0 8.058 8.73 0 19.5 0Z"
				fill={`url(#${randomId})`}
			/>
			<path
				d="M17.3349 25.5826C16.0195 24.8538 14.9258 23.7826 14.1699 22.4826C13.3815 21.1209 12.9772 19.5709 12.9999 17.9976C12.9772 16.4268 13.3816 14.8794 14.1699 13.5206C14.9271 12.2216 16.0205 11.1507 17.3349 10.4206C18.6826 9.69204 20.1905 9.31055 21.7224 9.31055C23.2544 9.31055 24.7623 9.69204 26.1099 10.4206C27.4225 11.1452 28.5097 12.2179 29.2519 13.5206C30.0283 14.8833 30.4247 16.4294 30.3999 17.9976C30.4227 19.5698 30.0213 21.1192 29.2379 22.4826C28.4925 23.7832 27.4057 24.8552 26.0949 25.5826C24.7487 26.311 23.2421 26.6925 21.7114 26.6925C20.1807 26.6925 18.6742 26.311 17.3279 25.5826H17.3349ZM24.9779 21.5706C25.8262 20.5791 26.2633 19.3009 26.1999 17.9976C26.2656 16.6877 25.8281 15.4024 24.9769 14.4046C24.5639 13.9585 24.059 13.6074 23.497 13.3755C22.935 13.1436 22.3294 13.0365 21.7219 13.0616C21.1125 13.0355 20.5046 13.1406 19.9392 13.3697C19.3739 13.5988 18.8643 13.9466 18.4449 14.3896C17.5885 15.3871 17.1503 16.6768 17.2219 17.9896C17.1514 19.2998 17.5895 20.5866 18.4449 21.5816C18.8614 22.0293 19.3701 22.3812 19.9359 22.6131C20.5017 22.845 21.1111 22.9512 21.7219 22.9246C22.331 22.9521 22.9387 22.8446 23.5014 22.6098C24.0641 22.3751 24.568 22.0188 24.9769 21.5666L24.9779 21.5706Z"
				fill="white"
			/>
			<path
				d="M41.3571 26.5216L37.8241 20.1116H36.8341V26.5216H32.7021V9.5416H39.6391C40.8181 9.50568 41.9891 9.74537 43.0591 10.2416C43.9423 10.6526 44.6799 11.3222 45.1741 12.1616C45.6454 12.9908 45.8886 13.9299 45.8791 14.8836C45.8945 15.9673 45.5607 17.0272 44.9271 17.9066C44.226 18.8386 43.2305 19.5064 42.1021 19.8016L46.0171 26.5216H41.3521H41.3571ZM36.8341 17.1836H39.4021C40.0198 17.23 40.6308 17.0305 41.1021 16.6286C41.2997 16.4189 41.4522 16.171 41.5502 15.9001C41.6482 15.6292 41.6897 15.3411 41.6721 15.0536C41.6917 14.7739 41.6509 14.4933 41.5525 14.2307C41.4542 13.9681 41.3006 13.7297 41.1021 13.5316C40.6302 13.1309 40.0197 12.9315 39.4021 12.9766H36.8371L36.8341 17.1836Z"
				fill="white"
			/>
			<path
				d="M59.74 14.9159C59.4441 14.3597 58.9829 13.909 58.42 13.6259C57.7945 13.3134 57.102 13.1589 56.403 13.1759C55.8034 13.1516 55.2057 13.257 54.6505 13.4848C54.0953 13.7126 53.5958 14.0575 53.186 14.4959C52.3453 15.4687 51.9128 16.7288 51.979 18.0129C51.9391 18.6763 52.0305 19.3411 52.2479 19.9691C52.4653 20.5972 52.8045 21.1761 53.246 21.6729C53.7049 22.1236 54.2527 22.4738 54.8545 22.701C55.4562 22.9283 56.0987 23.0277 56.741 22.9929C57.6602 23.0235 58.564 22.752 59.314 22.2199C60.0485 21.6583 60.5865 20.8788 60.851 19.9929H55.601V16.9479H64.594V20.7959C64.2746 21.8517 63.7438 22.8315 63.034 23.6759C62.2692 24.585 61.3177 25.319 60.244 25.8279C59.051 26.3906 57.745 26.6728 56.426 26.6529C54.8609 26.6819 53.3152 26.3028 51.941 25.5529C50.6504 24.8424 49.5868 23.7817 48.873 22.4929C48.1232 21.1241 47.744 19.5833 47.773 18.0229C47.745 16.4578 48.1241 14.9123 48.873 13.5379C49.5835 12.2472 50.6442 11.1837 51.933 10.4699C53.304 9.7192 54.8473 9.34 56.41 9.36987C58.2353 9.31027 60.033 9.82705 61.548 10.8469C62.9397 11.8251 63.9245 13.2795 64.316 14.9349H59.748L59.74 14.9159Z"
				fill="white"
			/>
			<path
				d="M77.4632 23.522H71.1252L70.1122 26.522H65.7852L71.9282 9.54199H76.7132L82.8562 26.522H78.4752L77.4632 23.522ZM76.3982 20.322L74.2982 14.105L72.2212 20.322H76.4062H76.3982Z"
				fill="white"
			/>
			<path d="M99.9027 26.522H95.7697L88.8547 16.052V26.522H84.7227V9.54199H88.8557L95.7707 20.064V9.54199H99.9037L99.9027 26.522Z" fill="white" />
			<path d="M107.043 9.54199V26.522H102.91V9.54199H107.043Z" fill="white" />
			<path
				d="M112.84 26.094C111.903 25.7267 111.087 25.105 110.485 24.299C109.881 23.4715 109.555 22.4735 109.555 21.449H113.955C113.983 21.994 114.216 22.5084 114.607 22.889C115.01 23.2308 115.527 23.4076 116.055 23.384C116.571 23.4088 117.08 23.261 117.502 22.964C117.659 22.8289 117.787 22.6634 117.878 22.4774C117.969 22.2914 118.022 22.0888 118.032 21.882C118.043 21.6751 118.011 21.4683 117.939 21.274C117.867 21.0798 117.757 20.9022 117.614 20.752C117.313 20.4629 116.959 20.2338 116.572 20.077C115.998 19.8443 115.412 19.6417 114.817 19.47C113.898 19.199 113.001 18.8604 112.132 18.457C111.425 18.1049 110.811 17.591 110.34 16.957C109.81 16.2018 109.546 15.2916 109.59 14.37C109.557 13.6587 109.696 12.95 109.995 12.3038C110.294 11.6575 110.744 11.0927 111.307 10.657C112.604 9.7146 114.183 9.24104 115.785 9.31396C117.4 9.2414 118.993 9.71406 120.307 10.656C120.874 11.1003 121.334 11.6668 121.653 12.3135C121.971 12.9602 122.139 13.6703 122.145 14.391H117.667C117.664 14.1449 117.609 13.9023 117.504 13.6797C117.399 13.4571 117.248 13.2597 117.06 13.101C116.656 12.7795 116.151 12.6122 115.635 12.629C115.197 12.6099 114.768 12.7522 114.428 13.029C114.259 13.1955 114.131 13.3989 114.053 13.6233C113.976 13.8476 113.952 14.0868 113.982 14.3222C114.013 14.5575 114.097 14.7826 114.229 14.9798C114.361 15.177 114.537 15.341 114.743 15.459C115.511 15.8722 116.321 16.204 117.158 16.449C118.07 16.7449 118.962 17.1007 119.828 17.514C120.525 17.8647 121.133 18.3699 121.605 18.991C122.13 19.712 122.394 20.59 122.355 21.481C122.37 22.411 122.115 23.3254 121.62 24.113C121.097 24.9263 120.358 25.5776 119.485 25.994C118.45 26.4832 117.315 26.7246 116.17 26.699C115.028 26.7207 113.893 26.514 112.832 26.091L112.84 26.094Z"
				fill="white"
			/>
			<path
				d="M135.476 23.522H129.138L128.126 26.522H123.798L129.938 9.54199H134.723L140.865 26.522H136.485L135.476 23.522ZM134.411 20.322L132.311 14.105L130.238 20.327H134.423L134.411 20.322Z"
				fill="white"
			/>
			<path d="M154.945 9.54199V12.857H150.445V26.522H146.313V12.857H141.813V9.54199H154.945Z" fill="white" />
			<path d="M161.16 9.54199V26.522H157.03V9.54199H161.16Z" fill="white" />
			<path
				d="M167.8 25.5826C166.484 24.8538 165.391 23.7826 164.635 22.4826C163.846 21.1209 163.442 19.5709 163.465 17.9976C163.442 16.4268 163.846 14.8794 164.635 13.5206C165.392 12.2216 166.485 11.1507 167.8 10.4206C169.147 9.69204 170.655 9.31055 172.187 9.31055C173.719 9.31055 175.227 9.69204 176.575 10.4206C177.888 11.1451 178.975 12.2178 179.718 13.5206C180.494 14.8834 180.89 16.4295 180.865 17.9976C180.888 19.5698 180.486 21.1192 179.703 22.4826C178.957 23.7832 177.871 24.8552 176.56 25.5826C175.213 26.3112 173.707 26.6928 172.176 26.6928C170.645 26.6928 169.138 26.3112 167.792 25.5826H167.8ZM175.442 21.5706C176.29 20.5792 176.728 19.301 176.665 17.9976C176.73 16.6877 176.293 15.4024 175.442 14.4046C175.029 13.9585 174.524 13.6074 173.962 13.3755C173.4 13.1436 172.794 13.0365 172.187 13.0616C171.577 13.0355 170.969 13.1406 170.404 13.3697C169.839 13.5988 169.329 13.9466 168.91 14.3896C168.053 15.3871 167.615 16.6768 167.687 17.9896C167.619 19.2991 168.059 20.5844 168.915 21.5776C169.331 22.0253 169.84 22.3772 170.406 22.6091C170.972 22.841 171.581 22.9473 172.192 22.9206C172.801 22.9481 173.409 22.8406 173.971 22.6058C174.534 22.3711 175.038 22.0148 175.447 21.5626L175.442 21.5706Z"
				fill="white"
			/>
			<path d="M198.355 26.522H194.222L187.307 16.052V26.522H183.175V9.54199H187.308L194.223 20.064V9.54199H198.356L198.355 26.522Z" fill="white" />
			<defs>
				<linearGradient id={randomId} x1="0" y1="36" x2="11.9156" y2="-33.9709" gradientUnits="userSpaceOnUse">
					<stop stopColor="#3C38F0" />
					<stop offset="1" stopColor="#007D99" />
				</linearGradient>
			</defs>
		</svg>
	)
}
