import React, { useEffect, useState } from 'react'
import { mapUserAgentToPlatform } from '../utils'
import { AppleLogoIcon, GooglePlayLogoIcon, LynxWhiteboardLogo, MicrosoftLogoIcon } from '../components/Icons'

interface DownloadInfo {
	icon: React.ReactElement
	to: string
	text: string
}

const downloads: { [key: string]: { [key: string]: DownloadInfo } } = {
	whiteboard: {
		MacOS: {
			icon: <AppleLogoIcon />,
			to: 'https://downloads.lynxcloud.app/lynx-whiteboard/releases/macx/lynx-whiteboard.pkg',
			text: 'Mac App Store'
		},
		Windows: {
			icon: <MicrosoftLogoIcon />,
			to: 'https://www.microsoft.com/store/productId/9NP33M4M3R35#',
			text: 'Windows Store'
		},
		Android: {
			icon: <GooglePlayLogoIcon />,
			to: 'https://play.google.com/store/apps/details?id=com.clevertouch.lynx',
			text: 'Google Play'
		},
		iOS: {
			icon: <AppleLogoIcon />,
			to: 'https://apps.apple.com/gb/app/lynx-whiteboard/id1260946498',
			text: 'App Store'
		}
	},
	screenshare: {
		MacOS: {
			icon: <LynxWhiteboardLogo />,
			to: 'https://downloads.lynxcloud.app/lynx-screen-share/beta/win64/lynx-screen-share-beta.msi',
			text: 'LYNX Store'
		},
		Windows: {
			icon: <LynxWhiteboardLogo />,
			to: 'https://downloads.lynxcloud.app/lynx-screen-share/beta/win64/lynx-screen-share-beta.msi',
			text: 'LYNX Store'
		},
		Android: {
			icon: <LynxWhiteboardLogo />,
			to: 'https://downloads.lynxcloud.app/lynx-screen-share/beta/android/lynx-screen-share-beta.apk',
			text: 'LYNX Store'
		},
		iOS: {
			icon: <LynxWhiteboardLogo />,
			to: 'https://downloads.lynxcloud.app/lynx-screen-share/beta/win64/lynx-screen-share-beta.msi',
			text: 'LYNX Store'
		}
	},
	activities: {
		MacOS: {
			icon: <LynxWhiteboardLogo />,
			to: '',
			text: 'LYNX Store'
		},
		Windows: {
			icon: <LynxWhiteboardLogo />,
			to: '',
			text: 'LYNX Store'
		},
		Android: {
			icon: <LynxWhiteboardLogo />,
			to: 'https://downloads.lynxcloud.app/LynxActivities/production/android/LynxActivities.apk',
			text: 'LYNX Store'
		},
		iOS: {
			icon: <LynxWhiteboardLogo />,
			to: '',
			text: 'LYNX Store'
		}
	},
	recorder: {
		MacOS: {
			icon: <LynxWhiteboardLogo />,
			to: 'https://downloads.lynxcloud.app/lynxsr/production/macx/LynxScreenRecorder.pkg',
			text: 'LYNX Store'
		},
		Windows: {
			icon: <LynxWhiteboardLogo />,
			to: 'https://downloads.lynxcloud.app/lynxsr/production/win32/LynxScreenRecorder.msi',
			text: 'LYNX Store'
		},
		Android: {
			icon: <LynxWhiteboardLogo />,
			to: 'https://downloads.lynxcloud.app/lynxsr/production/android/LynxRecorderAPK.apk',
			text: 'LYNX Store'
		},
		iOS: {
			icon: <LynxWhiteboardLogo />,
			to: 'https://downloads.lynxcloud.app/lynxsr/production/win32/LynxScreenRecorder.msi',
			text: 'LYNX Store'
		}
	}
}

export const useDownloadLinks = (application: string): DownloadInfo => {
	const [downloadInfo, setDownloadInfo] = useState<DownloadInfo>(downloads[application]['Windows'])

	useEffect(() => {
		const agentDownloads = downloads[application]
		const agentPlatform = mapUserAgentToPlatform(window.navigator.userAgent) || 'Windows'

		const downloadInfo = agentDownloads[agentPlatform]
		if (downloadInfo) {
			setDownloadInfo(downloadInfo)
		} else {
			setDownloadInfo(agentDownloads['Windows'])
		}
	}, [])

	return downloadInfo
}
