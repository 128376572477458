import * as React from 'react'
import css from './RadioButton.module.scss'
interface Props<V> {
	name?: string
	label: string
	value: V
	selected?: boolean
	required?: boolean
	isChecked: boolean
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	className?: string
}

export const RadioButton = <V,>({ label, name, value, required, isChecked, handleChange, className }: Props<V>): React.ReactElement => {
	const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
	return (
		<div className={`${css.container} ${className}`}>
			<input
				className={css.input}
				id={id}
				name={name}
				value={JSON.stringify(value)}
				required={required}
				type="radio"
				checked={isChecked}
				onChange={handleChange}
			/>
			<label className={`${css.labelContainer} ${isChecked && css.labelContainerChecked}`} htmlFor={id}>
				<svg className={css.icon} height="24" width="24" viewBox="0 0 30 30">
					<circle className={css.radioOutline} cx="15" cy="15" r="13" strokeWidth="3" />
					<circle className={css.radioDot} cx="15" cy="15" r="6" />
				</svg>

				<span className={css.label}>{label}</span>
			</label>
		</div>
	)
}
