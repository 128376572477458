import { ADD_BUSY, BusyActionTypes, REMOVE_BUSY } from './types'

export const addBusy = (blocking = false): BusyActionTypes => {
	return {
		type: ADD_BUSY,
		payload: {
			blocking
		}
	}
}

export const removeBusy = (blocking = false): BusyActionTypes => {
	return {
		type: REMOVE_BUSY,
		payload: {
			blocking
		}
	}
}
