export const READ_CONFIG = '@@config/READ_CONFIG'

export interface ConfigState {
	ENVIRONMENT: string
	SCHEME: string
	SEARCH_HOST: string
	API_SERVER: string
	API_WS_SERVER: string
	MESSAGING_API_SERVER: string
	MESSAGING_WS_SERVER: string
	LYNXCLOUD_CLIENT: string
	LYNXADMIN_CLIENT: string
	LYNXREMOTE_CLIENT: string
	LYNXACTIVITIES_CLIENT: string
	LYNXVNC_CLIENT: string
	LYNXTRANSLATOR_CLIENT: string
	LYNXZOOM_CLIENT: string
	SENTRY_DSN: string
}

export interface Config {
	ENVIRONMENT: string
	SCHEME: string
	SEARCH_HOST: string
	API_SERVER: string
	API_WS_SERVER: string
	MESSAGING_API_SERVER: string
	MESSAGING_WS_SERVER: string
	LYNXCLOUD_CLIENT: string
	LYNXADMIN_CLIENT: string
	LYNXREMOTE_CLIENT: string
	LYNXACTIVITIES_CLIENT: string
	LYNXVNC_CLIENT: string
	LYNXTRANSLATOR_CLIENT: string
	LYNXZOOM_CLIENT: string
	SENTRY_DSN: string
}

interface ReadConfigAction {
	type: typeof READ_CONFIG
	payload: Config
}

export type ConfigActionTypes = ReadConfigAction
