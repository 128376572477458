import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { ProfileDropdownLoggedInButton } from './ProfileDropdownLoggedIn.button'
import { ProfileDropdownLoggedOutButton } from './ProfileDropdownLoggedOut.button'

interface ProfileDropdownButtonProps {
	onOpenClicked: () => void
	onSignInClicked: () => void
}

export const ProfileDropdownButton = ({ onOpenClicked, onSignInClicked }: ProfileDropdownButtonProps): React.ReactElement => {
	const {
		profile: { loggedIn }
	} = useSelector((state: ApplicationState) => state)

	return (
		<React.Fragment>
			{!loggedIn && <ProfileDropdownLoggedOutButton onSignInClicked={onSignInClicked} onOpenClicked={onOpenClicked} />}
			{loggedIn && <ProfileDropdownLoggedInButton onOpenClicked={onOpenClicked} />}
		</React.Fragment>
	)
}
