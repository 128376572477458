export const SHOW_MODAL = '@@modal/SHOW_MODAL'
export const HIDE_MODAL = '@@modal/HIDE_MODAL'
export const SET_MODAL_CONTEXT = '@@modal/SET_MODAL_CONTEXT'

export const AreYouSureModalContextType = 'AreYouSureModalContextType'
export const ImageUpscaleModalContextType = 'ImageUpscaleModalContextType'

export interface BaseModalContext {
	type: string
}
export interface AreYouSureModalContext extends BaseModalContext {
	type: typeof AreYouSureModalContextType
	handleConfirm: () => Promise<void>
	confirmText: string
	confirmBody: string
}

export interface ImageUpscaleModalContext extends BaseModalContext {
	type: typeof ImageUpscaleModalContextType
	url: string
	name: string
	replaceImage?: (upscaledImageBlob: Blob) => Promise<void>
}

export type ModalContext = ImageUpscaleModalContext | AreYouSureModalContext | undefined

export interface ModalState<C> {
	name?: string
	context?: C
	sticky: boolean
}

export interface Modal<C> {
	name?: string
	context?: C
	sticky?: boolean
}

interface ShowModalAction<M> {
	type: typeof SHOW_MODAL
	payload: Modal<M>
}

interface HideModalAction {
	type: typeof HIDE_MODAL
}

interface SetModalContextAction<M> {
	type: typeof SET_MODAL_CONTEXT
	payload: Modal<M>
}

export type ModalActionTypes<M> = ShowModalAction<M> | HideModalAction | SetModalContextAction<M>
