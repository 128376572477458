import { ChevronDownIcon, Document, Dropdown } from '@lynx/client-core/src/components'
import { APIRequest } from '@lynx/client-core/src/api'
import React, { useState, useEffect, createRef } from 'react'
import { LanguageTranslations } from './LanguageTranslations'
import { langKeys, LangKeysType, SubmitTranslationInterface, TranslationRequestData } from '@lynx/core/src/interfaces/Translation'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './LynxTranslations.module.scss'
import { PreSubmittedTranslations } from './PreSubmittedTranslations'
import { usePopupBeforeExit } from '@lynx/client-core/src/hooks'
import { languages } from '@lynx/client-core/src/components/LanguageDropdown/languagesList'

const editableLanguages = langKeys.filter((value) => value !== 'en')

export const LynxTranslations = (): React.ReactElement => {
	const [editedTranslations, setEditedTranslations] = useState<SubmitTranslationInterface[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [translationRequestStatuses, setTranslationRequestStatuses] = useState<TranslationRequestData[]>([])
	const PreSubmittedTransListRef = createRef<HTMLDivElement>()
	const { profile } = useSelector((state: ApplicationState) => state)
	const defaultLang = editableLanguages.find((val) => val === profile?.lang) || editableLanguages[0]
	const [lang, setLang] = useState<LangKeysType>(defaultLang) // default it to current one

	const [isTranslationSubmitted, setIsTranslationSubmitted] = useState<boolean>(false)
	usePopupBeforeExit(Boolean(editedTranslations.length))

	useEffect(() => {
		if (editedTranslations.length) {
			setIsTranslationSubmitted(false)
		}
	}, [editedTranslations])

	const onSubmitTranslations = async (): Promise<void> => {
		setIsTranslationSubmitted(true)
		await APIRequest.Translation.submitTranslations(lang, editedTranslations)
		setEditedTranslations([])
		await fetchTranslationStatuses()
	}
	const fetchTranslationStatuses = async (): Promise<void> => {
		const data = await APIRequest.Translation.getTranslationRequestStatuses(lang)
		data && setTranslationRequestStatuses(data.translationsRequestData)
	}

	useEffect(() => {
		const mount = async (): Promise<void> => {
			await fetchTranslationStatuses()
		}
		mount()
	}, [])

	const addTranslation = (translation: SubmitTranslationInterface): void => {
		setEditedTranslations([...editedTranslations, translation])
	}

	const removeTranslation = (key: SubmitTranslationInterface['key']): void => {
		!isLoading && setEditedTranslations(editedTranslations.filter((editedTranslation) => editedTranslation.key !== key))
	}
	const dropdownTitle = (
		<div
			className={`${css.dropdownTitle} ${editedTranslations.length && css.disabledDropdown}`}
			title={editedTranslations.length ? t('components.lynxTranslations.disabledDueTo') : ''}
		>
			{languages.find(({ value }) => value === lang)?.label || ''} <ChevronDownIcon />
		</div>
	)
	return (
		<Document title="LYNX Translations" keywords="LYNX Translations" auth={true} disableOverflow={true}>
			<div
				className={css.mobileEditedTranslationsLink}
				onClick={(): void => {
					PreSubmittedTransListRef.current?.scrollIntoView({ behavior: 'smooth' })
				}}
			>
				{editedTranslations.length}
			</div>
			<div className={css.translationDropdown}>
				<Dropdown title={dropdownTitle} disabled={Boolean(editedTranslations.length)} openedClassName={css.openedClassName}>
					{editableLanguages.map((key) => (
						<li
							key={key}
							value={key + '-dropdown'}
							onClick={(): void => {
								setLang(key)
							}}
						>
							{languages.find(({ value }) => value === key)?.label}
						</li>
					))}
				</Dropdown>
			</div>
			<div className={css.container}>
				<LanguageTranslations
					translationRequestStatuses={translationRequestStatuses}
					editedTranslations={editedTranslations}
					addTranslation={addTranslation}
					removeTranslation={removeTranslation}
					lang={lang}
					isLoading={isLoading}
				/>
				<h1 id="pre-submitted-translations" className={css.preSubTransHeader}>
					{t('components.lynxTranslations.translationChanges')}
				</h1>
				<PreSubmittedTranslations
					mRef={PreSubmittedTransListRef}
					setIsLoading={setIsLoading}
					isTranslationSubmitted={isTranslationSubmitted}
					editedTranslations={editedTranslations}
					onSubmitTranslations={onSubmitTranslations}
					removeTranslation={removeTranslation}
					isLoading={isLoading}
				/>
			</div>
		</Document>
	)
}
