import { Modal } from '@lynx/client-core/src/components'
import css from './OnlineConversionsHelpModal.module.scss'
import { useTranslation } from 'react-i18next'

enum Colours {
	image = '#F08000',
	movie = '#D22',
	audio = '#60C',
	documents = '#7C7C7C'
}

const formats = [
	{
		text: 'JPG',
		colour: Colours.image
	},
	{ text: 'PNG', colour: Colours.image },
	{
		text: 'BMP',
		colour: Colours.image
	},
	{
		text: 'GIF',
		colour: Colours.image
	},
	{
		text: 'WEBP',
		colour: Colours.image
	},
	{
		text: 'MOV',
		colour: Colours.movie
	},
	{
		text: 'MP4',
		colour: Colours.movie
	},
	{
		text: 'AVI',
		colour: Colours.movie
	},
	{
		text: 'M4V',
		colour: Colours.movie
	},
	{
		text: 'MPG',
		colour: Colours.movie
	},
	{
		text: 'WMV',
		colour: Colours.movie
	},
	{
		text: 'WAV',
		colour: Colours.audio
	},
	{
		text: 'MP3',
		colour: Colours.audio
	},
	{
		text: 'WMA',
		colour: Colours.audio
	},
	{
		text: 'WEBM',
		colour: Colours.audio
	},
	{
		text: 'OGG',
		colour: Colours.audio
	},
	{
		text: 'OGA',
		colour: Colours.audio
	},
	{
		text: 'FLAC',
		colour: Colours.audio
	},
	{
		text: 'MPEG',
		colour: Colours.audio
	},
	{
		text: 'WAV',
		colour: Colours.audio
	},
	{
		text: 'LYNX',
		colour: Colours.documents
	},
	{
		text: 'PDF',
		colour: Colours.documents
	}
]

export const OnlineConversionsHelpModal = (): React.ReactElement => {
	const { t } = useTranslation()

	const tags = formats.map((format) => <div style={{ backgroundColor: format.colour }}>{format.text}</div>)
	return (
		<Modal name="OnlineConversionsHelpModal" title={t('pages.pricing.onlineConversionsFormats')} variant="transparent" className={css['modal']}>
			<div className={css['container']}>{tags}</div>
		</Modal>
	)
}
