import {
	BasicButton,
	BulletPointItem,
	BulletPoints,
	CreateIcon,
	Document,
	DownloadButton,
	FeatureItem,
	FeatureSection,
	FooterItem,
	FooterLinksItem,
	GroupedButtons,
	HeroItem,
	ScreenRecorderIllustration0Src,
	ScreenRecorderIllustration1,
	ScreenRecorderIllustration2,
	ScreenRecorderIllustration3
} from '@lynx/client-core/src/components'
import { useDownloadLinks } from '@lynx/client-core/src/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const videoOptions = { fluid: true, loop: false, controls: false, muted: true }

export const DownloadScreenRecorder = (): React.ReactElement => {
	const { icon, to, text } = useDownloadLinks('screenshare')
	const { t } = useTranslation()

	return (
		<Document title={t('pages.landing.title')}>
			<FeatureSection className={'hero-background'} backgroundColor="#272727">
				<HeroItem
					title={t('pages.landing.screenRecorder')}
					body={
						<BulletPoints>
							{[
								{ icon: <CreateIcon fill="#AF66FF" />, text: t('pages.moreDownloads.card4.bulletPoints.a') },
								{ icon: <CreateIcon fill="#00C5FF" />, text: t('pages.moreDownloads.card4.bulletPoints.b') },
								{ icon: <CreateIcon fill="#28C283" />, text: t('pages.moreDownloads.card4.bulletPoints.c') }
							].map(({ icon, text }, key) => (
								<BulletPointItem key={key} icon={icon} text={text} />
							))}
						</BulletPoints>
					}
					footer={''}
					actions={
						<GroupedButtons>
							<DownloadButton icon={icon} label={t('pages.landing.downloadOn')} text={text} to={to} />
							<BasicButton size="xl" variant="transparent" to="/more-downloads">
								{t('pages.landing.moreDownloads')}
							</BasicButton>
						</GroupedButtons>
					}
				/>
				<img src={ScreenRecorderIllustration0Src} alt="ScreenRecorderIllustration0" />
			</FeatureSection>

			<FeatureSection>
				<FeatureItem
					label={t('pages.landing.teaching')}
					accentColor="#AF66FF"
					title={t('pages.landing.professionalVideos')}
					body={t('pages.landing.effortlesslyCreateVideos')}
					action={t('pages.landing.learnMore')}
				/>
				<ScreenRecorderIllustration1 />
			</FeatureSection>

			<FeatureSection backgroundColor="#F6F6F6">
				<ScreenRecorderIllustration2 />
				<FeatureItem
					label={t('pages.landing.presenting')}
					accentColor="#00C5FF"
					title={t('pages.landing.sharePresentations')}
					body={t('pages.landing.transformYourPresentations')}
					action={t('pages.landing.learnMore')}
				/>
			</FeatureSection>

			<FeatureSection>
				<FeatureItem
					label={t('pages.landing.reviewing')}
					accentColor="#28C283"
					title={t('pages.landing.recordMeetings')}
					body={t('pages.landing.captureDetail')}
					action={t('pages.landing.learnMore')}
				/>
				<ScreenRecorderIllustration3 />
			</FeatureSection>

			<FeatureSection backgroundColor="#F6F6F6" height="auto">
				<FooterItem />
				<FooterLinksItem />
			</FeatureSection>
		</Document>
	)
}
