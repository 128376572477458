import { BasicButton } from '@lynx/client-core/src/components'
import { getStroke, getSvgPathFromStroke } from '@lynx/client-core/src/modules/Freehand'
import React, { useState } from 'react'
import { penColour, pens } from './Board.data'
import css from './Board.module.scss'
import { useTranslation } from 'react-i18next'

type PointerState = 'started' | 'moving' | 'finished'

interface BoardProps {
	active: boolean
}

export const Board = ({ active = false }: BoardProps): React.ReactElement | null => {
	const { t } = useTranslation()
	// The Svg in progress whilst mouse is down
	const [points, setPoints] = useState<number[][]>([])
	// All Svg Paths currently being drawn
	const [paths, setPaths] = useState<string[]>([])
	// Has started to draw
	const [pointerState, setPointerState] = useState<PointerState>('finished')

	/**
	 * On pointer down initiate the start of drawing a path
	 * @param e
	 */
	function handlePointerDown(e: React.PointerEvent<SVGSVGElement | Element>): void {
		;(e.target as Element).setPointerCapture(e.pointerId) // eslint-disable-line
		setPoints([[e.pageX, e.pageY, e.pressure]])
		setPointerState('started')
	}

	function handlePointerMove(e: React.PointerEvent<SVGSVGElement>): void {
		if (pointerState === 'finished') return
		if (e.buttons !== 1) return
		setPoints([...points, [e.pageX, e.pageY, e.pressure]])
	}

	function handleClearClicked(): void {
		setPaths([])
		setPoints([])
	}

	/**
	 * On Pointer Up store the current stroke in the currentPaths state
	 */
	function handlePointerUp(): void {
		const currentPaths = [...paths]
		currentPaths.push(pathData)
		setPaths(currentPaths)
		setPointerState('finished')
	}

	if (!active) {
		return null
	}

	/**
	 * Transform string svg paths to svg elements
	 */
	const svgs = paths.map((p, key) => (
		<div className={css['pen-container']}>
			<svg
				key={key}
				className={css['pen']}
				style={{
					fill: penColour
				}}
			>
				<path d={p} />
			</svg>
		</div>
	))

	const stroke = getStroke(points, pens['brush'])
	const pathData = getSvgPathFromStroke(stroke)

	return (
		<div className={css['container']}>
			<BasicButton variant="blue" onClick={handleClearClicked}>
				{t('controlbar.clear')}
			</BasicButton>
			{svgs}
			<svg
				onPointerDown={handlePointerDown}
				onPointerMove={handlePointerMove}
				onPointerUp={handlePointerUp}
				className={css['pen']}
				style={{
					fill: penColour
				}}
			>
				{points && <path d={pathData} />}
			</svg>
		</div>
	)
}
