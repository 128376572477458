import React from 'react'
import css from './BulletPointItem.module.scss'

export interface BulletPointItemProps {
	icon?: React.ReactNode
	text: string
	header?: string
}

export const BulletPointItem = ({ icon, text, header }: BulletPointItemProps): React.ReactElement => {
	return (
		<li className={css['container']}>
			<div className={css['container__bullet']}>{icon}</div>
			<div className={css['container__text']}>
				{header && <strong>{header} </strong>}
				<span>{text}</span>
			</div>
		</li>
	)
}
