import {
	Document,
	FeatureSection,
	FooterItem,
	FooterLinksItem,
	SupportFaqIcon,
	SupportGettingStartedIcon,
	SupportLicensesIcon,
	SupportPrivacyPolicyIcon,
	SupportSoftwareManualsIcon,
	SupportVideoTutorialsIcon
} from '@lynx/client-core/src/components'
import * as React from 'react'
import { Link } from 'react-router-dom'
import css from './Support.module.scss'
import { useTranslation } from 'react-i18next'

const tiles = [
	{
		name: 'pages.info.gettingStarted',
		description: 'pages.info.gettingStartedDescription',
		icon: <SupportGettingStartedIcon />,
		to: 'https://gettingstarted.clevertouch.com/'
	},
	{
		name: 'pages.info.videoTutorials',
		description: 'pages.info.videoTutorialsDescription',
		icon: <SupportVideoTutorialsIcon />,
		to: 'https://www.youtube.com/user/myclevertouch'
	},
	{ name: 'pages.info.faq', icon: <SupportFaqIcon />, to: '/support/faq', description: 'pages.info.faqDescription' },
	{ name: 'pages.info.softwareManual', icon: <SupportSoftwareManualsIcon />, to: '/support/documents', description: 'pages.info.softwareManualDescription' },
	{ name: 'pages.info.licenses', icon: <SupportLicensesIcon />, to: '/support/license', description: 'pages.info.licensesDescription' },
	{ name: 'pages.info.privacyPolicy', icon: <SupportPrivacyPolicyIcon />, to: '/support/privacy', description: 'pages.info.privacyPolicyDescription' }
] as const

export const Support = (): React.ReactElement => {
	const { t } = useTranslation()
	const description = 'support'
	const tileComponents = tiles.map(({ to, name, icon, description }) => {
		const content = (
			<div key={to} className={css['tile']}>
				{icon}
				<div className={css['name']}>{t(name)}</div>
				<div className={css['description']}>{t(description)}</div>
			</div>
		)
		return to.includes('http') ? (
			<a key={to} href={to} rel="noreferrer" target="_blank">
				{content}
			</a>
		) : (
			<Link key={to} to={to}>
				{content}
			</Link>
		)
	})

	return (
		<Document title={t('pages.info.title')} description={description} keywords={description}>
			<div className={css.mainContainer}>
				<div>
					<div className={css['title']}>{t('pages.info.howCanWeHelp')}</div>
					<div className={css['container']}>
						<div className={css['tile-icons']}>{tileComponents}</div>
					</div>
				</div>
				<div>
					<FeatureSection backgroundColor="#F6F6F6" height="auto">
						<FooterItem />
						<FooterLinksItem />
					</FeatureSection>
				</div>
			</div>
		</Document>
	)
}
