import { APIRequest } from '@lynx/client-core/src/api'
import { AutorenewIcon, FileIcon } from '@lynx/client-core/src/components'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import i18next from 'i18next'
import { ControlBar } from 'pages/Share/components/ControlBar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ApplicationState } from 'store'
import { requestDocument } from 'store/document'
import { PowerPointPreviewModalContextType, showModal } from 'store/modal'
import { DirectionArrows } from './components'
import { ThumbnailsExportState } from './interfaces'
import Styles from './ThumbnailsExport.module.css'

export const ThumbnailsExport = (): React.ReactElement => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const navigate = useNavigate()
	const { profile: profileState, modal: modalState, document: documentState } = useSelector((state: ApplicationState) => state)
	const params = useParams<{ token: string }>()
	const [state, setState] = useState<ThumbnailsExportState>({
		page: 0,
		pngsBase64: [],
		shareDetailsData: null,
		token: ''
	})
	const previousDocumentReady = usePrevious(documentState.ready)

	useEffect(() => {
		const mount = async (): Promise<void> => {
			try {
				// Get share token from url params
				const { token } = params
				if (!token) {
					return
				}

				// Retrieve share data from API
				const shareInfo = await APIRequest.Sharing.info(token)
				if (!shareInfo?.data) {
					navigate('/missing-share')
					return
				}

				// Set up listening channel and request conversion
				await dispatchThunk(
					requestDocument({
						fileId: shareInfo.data.id,
						resolution: '2048',
						download: false,
						conversionType: 'thumbnails',
						shareToken: token
					})
				)

				// Set the share data on local state
				setState({ ...state, shareDetailsData: shareInfo.data, token })

				// If we're not logged in and there's no other modals showing then
				// prompt to potentially log in
				if (!profileState.loggedIn && !modalState.name) {
					dispatch(showModal({ name: 'SignUpModal' }))
				}
			} catch (e) {
				Logger.error(e)
				navigate('/missing-share')
			}
		}
		mount()
	}, [])

	useEffect(() => {
		const update = async (): Promise<void> => {
			if (!previousDocumentReady && documentState.ready) {
				const { fileId } = documentState
				const buffer = await APIRequest.Sharing.downloadBuffer(fileId)
				if (!buffer) {
					Logger.error('empty buffer')
					return
				}

				// Convert Buffer to Zip
				const { default: JSZip } = await import('jszip')
				const zipData = await JSZip.loadAsync(buffer)
				const pngsBase64 = []
				for (const singleFile of Object.keys(zipData.files)) {
					const zipFileData = await zipData.file(singleFile)
					if (zipFileData) pngsBase64.push(`data:image/png;base64,${await zipFileData.async('base64')}`)
				}

				setState({ ...state, pngsBase64 })
			}
		}
		update()
	}, [previousDocumentReady, documentState])

	const onHandleForward = (): void => {
		const { pngsBase64 } = state
		let { page } = state
		page++

		if (page < pngsBase64.length) {
			setState({ ...state, page })
		}
	}

	const onHandleBack = (): void => {
		const { page } = state

		if (page === 0) {
			return
		}

		setState({ ...state, page: page - 1 })
	}

	const handleDownload = async (): Promise<void> => {
		const { token, shareDetailsData } = state

		const blobLinkProperties = await APIRequest.Sharing.getObjectUrl(token)
		if (!blobLinkProperties) {
			navigate('/missing-share')
			return
		}

		const link = document.createElement('a')
		link.href = blobLinkProperties.link
		document.body.appendChild(link)
		link.download = shareDetailsData?.name || 'download'
		link.click()
		document.body.removeChild(link)
	}

	const handleViewClicked = (): void => {
		dispatch(showModal({ name: 'PowerPointPreviewModal', context: { type: PowerPointPreviewModalContextType, token: state.token } }))
	}

	const { loggedIn } = profileState
	const { pngsBase64, page, shareDetailsData, token } = state
	const slide = <img alt={'loading'} src={pngsBase64[page]} />
	const hasPage = !!pngsBase64[page]
	const pageCount = pngsBase64.length

	return (
		<div className={Styles['container']}>
			{hasPage && (
				<React.Fragment>
					{slide}
					<DirectionArrows pageCount={pageCount} page={page} onBackClicked={onHandleBack} onForwardClicked={onHandleForward} />
				</React.Fragment>
			)}
			{!pageCount && (
				<div
					style={{
						width: '1024px',
						height: '100%',
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						fontSize: '19px'
					}}
				>
					{loggedIn && (
						<React.Fragment>
							<div>{i18next.t('pages.share.loading')}</div>
							<AutorenewIcon className={Styles['update']} />
						</React.Fragment>
					)}
					{!loggedIn && (
						<div className={Styles['require-signin']}>
							<FileIcon />
							<div>{i18next.t('pages.share.requireSignin')}</div>
						</div>
					)}
				</div>
			)}
			<ControlBar onDownloadClicked={handleDownload} onViewClicked={handleViewClicked} shareDetails={shareDetailsData} token={token} />
		</div>
	)
}
