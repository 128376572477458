import { Button } from '@lynx/client-core/src/components'
import { APIRequest } from '@lynx/client-core/src/api'
import React, { useState, useEffect } from 'react'
import { LangKeysType, SubmitTranslationInterface, TranslationItem, TranslationRequestData } from '@lynx/core/src/interfaces/Translation'
import { Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './LanguageTranslations.module.scss'
import { languages } from '@lynx/client-core/src/components/LanguageDropdown/languagesList'

interface RequestedTranslationCompData extends SubmitTranslationInterface {
	status: TranslationRequestData['status']
	id: TranslationRequestData['id']
}
export const LanguageTranslations = ({
	editedTranslations,
	addTranslation,
	removeTranslation,
	isLoading,
	lang,
	translationRequestStatuses
}: {
	editedTranslations: SubmitTranslationInterface[]
	addTranslation: (translation: SubmitTranslationInterface) => void
	removeTranslation: (key: SubmitTranslationInterface['key']) => void
	isLoading: boolean
	lang: LangKeysType
	translationRequestStatuses: TranslationRequestData[]
}): React.ReactElement => {
	const { profile } = useSelector((state: ApplicationState) => state)
	const [translations, setTranslations] = useState<TranslationItem[]>([])
	const [justAddedForTranslationKeys, setJustAddedForTranslationKeys] = useState<string[]>([])
	const [rowInEditMode, setRowInEditMode] = useState<SubmitTranslationInterface | null>(null)

	const previousRowInEditMode = usePrevious(rowInEditMode)
	const previousProfileState = usePrevious(profile)
	const langTranslationRequestStatuses = translationRequestStatuses.reduce(
		(acc: RequestedTranslationCompData[], { id, status, lang: transLang, requestedTranslations }: TranslationRequestData) => {
			if (transLang !== lang) return acc
			const requestedTranslationsData = requestedTranslations.map((data: SubmitTranslationInterface) => ({ ...data, id, status }))
			return [...acc, ...requestedTranslationsData]
		},
		[]
	)

	useEffect(() => {
		const isNew = rowInEditMode && previousRowInEditMode && previousRowInEditMode?.key !== rowInEditMode?.key
		const isChanged = previousRowInEditMode?.textFrom !== previousRowInEditMode?.textTo
		if (isNew && isChanged) addTranslationInternal(previousRowInEditMode)
	}, [previousRowInEditMode, rowInEditMode])

	useEffect(() => {
		if (isLoading) {
			setRowInEditMode(null)
		}
	}, [isLoading])

	const addTranslationInternal = (row: SubmitTranslationInterface): void => {
		setJustAddedForTranslationKeys((prevState) => [...prevState, row.key])
		const animationDuration = 3000
		setTimeout((): void => setJustAddedForTranslationKeys((prevState) => prevState.filter((key) => key !== row.key)), animationDuration)
		addTranslation(row)
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (!rowInEditMode) return
		setRowInEditMode({ ...rowInEditMode, textTo: e.target.value })
	}

	const handleSave = async (): Promise<void> => {
		if (rowInEditMode) {
			addTranslationInternal(rowInEditMode)
			setRowInEditMode(null)
		}
	}

	const columns: TableHeaders = [
		{
			valueKey: 'key',
			label: t('components.lynxTranslations.translationKey'),
			cellAdditionalStyles: { alignItems: 'center' }
		},
		{
			valueKey: 'englishText',
			label: t('components.lynxTranslations.english'),
			cellAdditionalStyles: { alignItems: 'center' }
		},
		{
			valueKey: 'targetLangText',
			label: languages.find(({ value }) => value == lang)?.label || lang,
			customBodyRender: (item: TranslationItem, _: number, { highlightedText }: { highlightedText: React.ReactElement }) =>
				rowInEditMode?.key === item.key ? (
					<input type="text" className={css.inputText} value={String(rowInEditMode.textTo)} onChange={handleChange} />
				) : (
					<div>{highlightedText}</div>
				),
			cellAdditionalStyles: { alignItems: 'center' }
		},
		{
			valueKey: '__',
			label: t('components.lynxTranslations.actions'),
			isSortable: false,
			customBodyRender: (item: TranslationItem): React.ReactElement => {
				const { key, targetLangText } = item
				const rowRequestChange = langTranslationRequestStatuses.find((translationRequestStatus) => translationRequestStatus.key === key)
				const rowPreRequestSubmitChange = editedTranslations.find((translationRequestStatus) => translationRequestStatus.key === key)
				const isRowInEditMode = rowInEditMode?.key === key
				const isRowJustAdded = justAddedForTranslationKeys.includes(key)
				if (rowRequestChange && !rowPreRequestSubmitChange) {
					return (
						<div className={css.itemUpdate}>
							<div>
								{t('components.lynxTranslations.requested')}:
								<span className={css.textTo}>
									<b>{rowRequestChange.textTo}</b>
								</span>
							</div>
							<div className={css.waitingText}>
								<span>
									{t('components.lynxTranslations.waitingForApproval')} ({rowRequestChange.status.replace('pr-', '')})
								</span>
							</div>
						</div>
					)
				}
				return (
					<div className={css.actions}>
						{isRowJustAdded && <div className={css.justAdded}>{t('components.lynxTranslations.justAdded')}</div>}
						{!isRowJustAdded && isRowInEditMode && (
							<Button
								disabled={isLoading || !(rowInEditMode.textTo && rowInEditMode.textFrom !== rowInEditMode.textTo)}
								variant="blue"
								className={css.saveBtn}
								onClick={(): Promise<void> => handleSave()}
							>
								{t('components.lynxTranslations.save')}
							</Button>
						)}
						{!isRowJustAdded && (isRowInEditMode || rowPreRequestSubmitChange) && (
							<Button
								disabled={isLoading}
								variant="danger"
								onClick={(): void => (rowPreRequestSubmitChange ? removeTranslation(item.key) : setRowInEditMode(null))}
							>
								{t('components.lynxTranslations.cancel')}
							</Button>
						)}
						{!isRowJustAdded && !isRowInEditMode && !rowPreRequestSubmitChange && (
							<Button disabled={isLoading} onClick={(): void => setRowInEditMode({ key: key, textFrom: targetLangText, textTo: targetLangText })}>
								{t('components.lynxTranslations.edit')}
							</Button>
						)}
					</div>
				)
			}
		}
	]

	const fetchTranslations = async (): Promise<void> => {
		try {
			const responseData = await APIRequest.Translation.get(lang)
			responseData && setTranslations(responseData.translations)
		} catch (err) {
			Logger.error(err)
		}
	}

	useEffect(() => {
		const isJustLoggedIn = !previousProfileState?.loggedIn && profile.loggedIn
		if (isJustLoggedIn) fetchTranslations()
	}, [profile.loggedIn])

	useEffect(() => {
		fetchTranslations()
	}, [lang])

	return <Table title={'Translations'} headers={columns} items={translations || []} />
}
