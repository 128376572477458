import React, { useState } from 'react'
import css from './Slider.module.scss'
import { DefaultInputProps } from './Slider.types'

export type SliderProps = DefaultInputProps & {
	onChange: (value: number) => void
	value: number
	min?: number
	max?: number
	hideSliderValue?: boolean
	disabled?: boolean
}
export const Slider = (props: SliderProps): React.ReactElement => {
	const { onChange, value, label, min = 1, max, hideSliderValue = true, disabled = false } = props
	const handleQuestionCountChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		onChange && onChange(Number(event.target.value))
	}
	const internalId = 'slider-' + Math.random().toString(36).substr(2, 9)
	return (
		<div className={css.container}>
			{label && <label htmlFor={internalId}>{label}</label>}
			<div className={css.inputWrapper}>
				<input
					id={internalId}
					disabled={disabled}
					onChange={handleQuestionCountChanged}
					type={'range'}
					min={min}
					max={max}
					step={1}
					value={value}
					className={css.input}
				/>
				{!hideSliderValue && <div className={css.valueContainer}>{value}</div>}
			</div>
		</div>
	)
}
