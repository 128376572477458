import { StrokeOptions } from '@lynx/client-core/src/modules/Freehand'

export const pens: { [key: string]: StrokeOptions } = {
	brush: {
		size: 16,
		smoothing: 0.5,
		streamline: 0.5,
		easing: (t) => t,
		start: {
			easing: (t) => t,
			cap: true
		},
		end: {
			easing: (t) => t,
			cap: true
		}
	}
}

export const penColour = 'rgba(230, 0, 0, 1)'
