import { SessionsList } from 'pages/Profile/components/SessionsList'
import { useTranslation } from 'react-i18next'
import React from 'react'
import css from './DeviceActivity.module.scss'

export const DeviceActivity = (): React.ReactElement => {
	const { t } = useTranslation()
	return (
		<div className={css.container}>
			<div className={css.title}>{t('components.sessionList.deviceActivity')}</div>
			<SessionsList />
		</div>
	)
}
