import {
	ProviderContainerId,
	ProvidersData,
	Providers,
	DriveSize,
	ProfileDriveItem,
	PreUploadResponseType,
	PreUploadParamsType,
	PreUploadQueryType,
	PostUploadParamsType,
	PostUploadBodyType,
	DriveSettings
} from '@lynx/core/src/interfaces/ObjectStore'
import { objectStoreRoutes } from '@lynx/core/src/entity/ObjectStore/contants'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import * as buffer from 'buffer'
import { BaseCall } from '../BaseCall'
import { APIConfig, DriveExtraData, DriveFolderItem, DriveItemType, ClientProviders } from '../interfaces'
import { Logger } from '../../modules/Logger'

interface ObjectStoreFile {
	name: string
	id: string
	provider: string
	mimeType: string
	size: number
	dateModified: number
}

export class ObjectStore extends BaseCall {
	public linkDrive(strategy: string): Promise<AxiosResponse> {
		return this.client.get(`auth/${strategy}/link`)
	}

	public async getDriveSize(
		provider: ClientProviders,
		providerContainerId?: ProviderContainerId
	): Promise<{ usedBytes: number | null; totalBytes: number | null }> {
		const query = providerContainerId ? { providerContainerId } : {}
		const params = { provider }

		const uri = this.resolveTemplatedString(objectStoreRoutes.GET_DRIVE_SIZE, params, query)
		try {
			const response = await this.client.get(uri)
			return response.data
		} catch (err) {
			Logger.error(err)
			return { usedBytes: null, totalBytes: null }
		}
	}

	public async getDisallowedLinkProviders(): Promise<string[]> {
		try {
			const uri = '/object-store/disallowedProviders'
			const response = await this.client.get(uri)
			return response.data
		} catch (e) {
			Logger.log(e)
			throw e
		}
	}

	public async getDrivesSizes(): Promise<{ drivesSizes: DriveSize[] }> {
		const uri = this.resolveTemplatedString(objectStoreRoutes.GET_DRIVES_SIZES, {})
		const response = await this.client.get(uri)
		return response.data
	}

	public async getRecentFiles(): Promise<DriveItemType[]> {
		const uri = this.resolveTemplatedString(objectStoreRoutes.RECENT_FILES, {})
		const response = await this.client.get(uri)
		return response.data.webData
	}

	public async deleteRecentFile(item: DriveItemType): Promise<void> {
		const { provider, id: driveItemId, additionalData } = item

		const query: { [x: string]: string } = {}
		if (additionalData?.driveId) query.providerContainerId = additionalData.driveId

		const uri = this.resolveTemplatedString(objectStoreRoutes.DELETE_RECENT_FILE, { provider, driveItemId }, query)
		await this.client.delete(uri)
	}

	public async extra(p: Providers, driveItemId: string, providerContainerId?: ProviderContainerId): Promise<DriveExtraData | null> {
		const uri = this.resolveTemplatedString(
			objectStoreRoutes.EXTRA,
			{
				driveItemId,
				provider: p
			},
			providerContainerId ? { providerContainerId } : {}
		)

		try {
			const response = await this.client.get<DriveExtraData>(uri)

			return response.data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public list(
		provider: ClientProviders,
		driveId?: string,
		queryData?: {
			providerContainerId?: ProviderContainerId
			nextPageToken?: string
			pageSize?: number
			excludeSharedFolder?: boolean
		}
	): Promise<AxiosResponse<DriveFolderItem>> {
		const { nextPageToken, pageSize, providerContainerId, excludeSharedFolder } = queryData || {}
		const query: {
			pageSize?: number
			nextPageToken?: string
			q?: ['exclude-shared-folder']
			providerContainerId?: string
		} = {}

		const isProviderUsingPagination = provider && ['google', 'onedrive'].includes(provider)
		if (nextPageToken && isProviderUsingPagination) query.nextPageToken = nextPageToken
		if (pageSize && isProviderUsingPagination) query.pageSize = pageSize
		excludeSharedFolder && (query.q = ['exclude-shared-folder'])
		if (providerContainerId) query.providerContainerId = providerContainerId

		if (driveId) {
			const uri = this.resolveTemplatedString(objectStoreRoutes.LIST, { provider, id: driveId }, query)
			return this.client.get<DriveFolderItem>(uri)
		}

		const uri = this.resolveTemplatedString(objectStoreRoutes.LISTROOT, { provider }, query)

		return this.client.get(uri)
	}

	public delete = async (provider: Providers, id: string, providerContainerId?: string): Promise<DriveItemType | null> => {
		const uri = this.resolveTemplatedString(
			objectStoreRoutes.DELETE,
			{
				id,
				provider
			},
			providerContainerId ? { providerContainerId } : {}
		)

		try {
			const response = await this.client.delete<DriveItemType>(uri)
			return response.data
		} catch (e) {
			Logger.log(e)
			return null
		}
	}

	public download = async (
		provider: Providers,
		id: string,
		name?: string,
		queryData?: { driveId?: string; providerContainerId?: string | undefined },
		options?: { isReturnBlob?: boolean }
	): Promise<Blob | void> => {
		const { providerContainerId } = queryData || {}
		const query: { driveId?: string; providerContainerId?: string; addRecentFiles: boolean } = { addRecentFiles: false }
		if (providerContainerId || queryData?.driveId) query.providerContainerId = queryData?.driveId || providerContainerId

		const uri = this.resolveTemplatedString(objectStoreRoutes.DOWNLOAD, { provider, id }, query)

		try {
			const response = await this.client.get(uri, { responseType: 'blob' })

			let downloadLink = ''
			downloadLink = name || this.getContentDispositionHeader(response)
			if (!downloadLink) {
				return
			}

			const contentType = response.headers['content-type'] || ''

			const isLynxFile = downloadLink.endsWith('.lynx')

			const blobType = isLynxFile ? 'application/octet-stream' : contentType

			const blob = new Blob([response.data], {
				type: blobType
			})
			if (options?.isReturnBlob) return blob

			const link = document.createElement('a')
			link.setAttribute('download', downloadLink)
			link.setAttribute('type', contentType)
			link.href = window.URL.createObjectURL(blob)
			link.download = downloadLink
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		} catch (err) {
			Logger.error(err)
		}
	}

	public async downloadLink(provider: string, id: string, name?: string, providerContainerId?: string): Promise<{ link: string; extension: string } | void> {
		const uri = this.resolveTemplatedString(
			objectStoreRoutes.DOWNLOAD,
			{
				provider,
				id
			},
			providerContainerId ? { providerContainerId } : {}
		)

		const response: AxiosResponse = await this.client.get(uri, {
			responseType: 'blob'
		})

		let downloadLink = ''
		downloadLink = name || this.getContentDispositionHeader(response)
		if (!downloadLink) {
			return
		}

		const extension = downloadLink.split('.').pop()
		if (!extension) {
			return
		}

		const blob = new Blob([response.data])

		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(blob)

		return { link: window.URL.createObjectURL(blob), extension }
	}

	public async downloadBuffer(provider: string, id: string, providerContainerId?: string): Promise<Buffer | void> {
		try {
			const uri = this.resolveTemplatedString(
				objectStoreRoutes.DOWNLOAD,
				{
					provider,
					id
				},
				providerContainerId ? { providerContainerId } : {}
			)

			const response: AxiosResponse = await this.client.get(uri, {
				responseType: 'arraybuffer'
			})

			return buffer.Buffer.from(response.data)
		} catch (err) {
			Logger.error(err)
		}
	}

	public async downloadLynxThumbnails(provider: string, id: string, providerContainerId?: string): Promise<any> {
		try {
			const uri = this.resolveTemplatedString(
				objectStoreRoutes.DOWNLOAD_LYNX_THUMBNAILS,
				{
					provider,
					id
				},
				providerContainerId ? { providerContainerId } : {}
			)

			const response = await this.client.get(uri)

			return response.data
		} catch (err) {
			Logger.error(err)
		}
	}

	public async getAvailableDrives(): Promise<ProvidersData['webProviders'] | void> {
		try {
			const { data } = await this.client.get('/object-store/providers')
			if (typeof data === 'object' && 'webProviders' in data) return data.webProviders
		} catch (err) {
			Logger.error(err)
		}
	}

	public async getDrives(): Promise<{ visible: ProfileDriveItem[]; hidden: ProfileDriveItem[] } | null> {
		try {
			const { data } = await this.client.get<ProfileDriveItem[]>('/object-store/drives?includeHidden=true')
			if (!data) return null
			const visible = data.filter((d) => !d.hidden)
			const hidden = data.filter((d) => d.hidden)
			return { visible, hidden }
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public async preUpload(params: PreUploadParamsType, query: PreUploadQueryType): Promise<PreUploadResponseType> {
		try {
			const url = this.resolveTemplatedString(objectStoreRoutes.PRE_UPLOAD, params, query)
			const { data } = await this.client.get(url)
			return data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public async postUpload(params: PostUploadParamsType, body: PostUploadBodyType & { thumbnail: File | null }): Promise<PreUploadResponseType> {
		try {
			const { thumbnail, fileName, path } = body
			const formData = new FormData()
			formData.append('fileName', fileName)
			thumbnail && formData.append('thumbnail', thumbnail)
			formData.append('path', path)

			const url = this.resolveTemplatedString(objectStoreRoutes.POST_UPLOAD, params)

			const { data } = await this.client.post(url, formData)
			return data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public async hideDrive(driveId: string): Promise<null> {
		try {
			const { data } = await this.client.post(`/object-store/drives/${driveId}`, {
				hidden: true
			})
			return data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public async showDrive(driveId: string): Promise<null> {
		try {
			const { data } = await this.client.post(`/object-store/drives/${driveId}`, {
				hidden: false
			})
			return data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public async updateDrives(items: ProfileDriveItem[]): Promise<ProfileDriveItem[] | null> {
		try {
			const { data } = await this.client.patch<ProfileDriveItem[]>('/object-store/drives', {
				type: 'order',
				items
			})
			return data
		} catch (err) {
			Logger.error(err)
			return null
		}
	}

	public async updateDriveSettings(provider: 'onedrive', providerContainerId: string, settings: DriveSettings): Promise<void> {
		try {
			await this.client.patch<ProfileDriveItem[]>(`/object-store/driveSettings/${provider}?providerContainerId=${providerContainerId}`, settings)
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public async getDriveSettings(provider: 'onedrive', providerContainerId: string): Promise<DriveSettings> {
		try {
			const res = await this.client.get<DriveSettings>(`/object-store/driveSettings/${provider}?providerContainerId=${providerContainerId}`)
			return res.data
		} catch (err) {
			Logger.error(err)
			throw err
		}
	}

	public upload = async (
		providerName: string,
		file: File,
		id: string,
		shareDuration = 0,
		queryOptions?: { providerContainerId?: string; toastOnSuccess?: boolean },
		axiosConfig?: Partial<AxiosRequestConfig>
	): Promise<DriveItemType | null> => {
		let uri = ''
		if (id) {
			uri = this.resolveTemplatedString(objectStoreRoutes.UPLOAD, { id, provider: providerName }, queryOptions)
		} else {
			uri = this.resolveTemplatedString(objectStoreRoutes.UPLOADROOT, { provider: providerName }, queryOptions)
		}

		const data = new FormData()
		data.append('file', file)
		shareDuration && data.append('createShare', shareDuration.toString())

		const config = {
			...axiosConfig,
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		try {
			const response = await this.client.put(uri, data, config)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public createFolder(providerName: ClientProviders, id: string, name = 'New Folder', providerContainerId?: ProviderContainerId): Promise<any> {
		let uri = ''

		if (id) {
			uri = this.resolveTemplatedString(
				objectStoreRoutes.CREATEFOLDER,
				{
					id,
					provider: providerName
				},
				providerContainerId ? { providerContainerId } : {}
			)
		} else {
			uri = this.resolveTemplatedString(
				objectStoreRoutes.CREATEFOLDERROOT,
				{
					provider: providerName
				},
				providerContainerId ? { providerContainerId } : {}
			)
		}

		return this.client.post(uri, {
			name
		})
	}

	public move(item: DriveItemType, name: string, parentId?: string, providerContainerId?: string): Promise<AxiosResponse> {
		parentId = parentId || ''
		const uri = this.resolveTemplatedString(
			objectStoreRoutes.UPDATE,
			{
				id: item.id,
				provider: item.provider
			},
			providerContainerId ? { providerContainerId } : {}
		)

		return this.client.patch(uri, {
			name,
			parentId
		})
	}

	public rename = async (item: DriveItemType, name: string, providerContainerId?: string): Promise<DriveItemType | null> => {
		const query: { [x: string]: string } = { previousName: item.name }
		if (providerContainerId) query.providerContainerId = providerContainerId

		const uri = this.resolveTemplatedString(objectStoreRoutes.UPDATE, { id: item.id, provider: item.provider }, query)

		try {
			const response = await this.client.patch<DriveItemType>(uri, { name })
			return response.data
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public unlink(providerName: Providers, providerContainerId?: string): Promise<AxiosResponse> {
		const queryParams = providerContainerId ? `?providerContainerId=${providerContainerId}` : ''
		const uri = `/auth/${providerName}/delete${queryParams}`
		return this.client.get(uri)
	}

	public convertFile = async (file: DriveItemType | null, provider: ClientProviders | null, convertTo: string, channelId: string): Promise<boolean> => {
		if (!file) {
			return false
		}
		try {
			await this.client.post(`/convert/${convertTo}`, {
				channelId,
				fileId: file.id,
				provider
			})
			return true
		} catch (err) {
			return false
		}
	}

	public createLynxFile = async (
		providerName: ClientProviders,
		fileName: string,
		id: string,
		providerContainerId?: string
	): Promise<ObjectStoreFile | boolean> => {
		if (!fileName) {
			return false
		}
		let uri = ''
		try {
			if (id) {
				uri = this.resolveTemplatedString(
					objectStoreRoutes.CREATELYNXFILE,
					{
						id,
						provider: providerName
					},
					providerContainerId ? { providerContainerId } : {}
				)
			} else {
				uri = this.resolveTemplatedString(
					objectStoreRoutes.CREATELYNXFILEROOT,
					{
						provider: providerName
					},
					providerContainerId ? { providerContainerId } : {}
				)
			}

			const result = await this.client.put(uri, {
				name: fileName
			})

			return result.data
		} catch (err) {
			return false
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
