export const drives: { [key: string]: { logo: string; title: string; uri: string; unlinkUri: string; icon: string } } = {
	dropbox: {
		logo: '/assets/icons/logo-dropbox-large.png',
		title: 'Dropbox',
		uri: '/auth/dropbox',
		unlinkUri: '/auth/dropbox/delete',
		icon: '/assets/icons/logo-dropbox.svg'
	},
	google: {
		logo: '/assets/icons/logo-google-drive-large.png',
		title: 'Google Drive',
		uri: '/auth/google',
		unlinkUri: '/auth/google/delete',
		icon: '/assets/icons/logo-google-drive.svg'
	},
	nextcloud: {
		logo: '',
		title: 'Next Cloud',
		uri: '',
		unlinkUri: '',
		icon: '/assets/icons/logo-nextcloud.svg'
	},
	lynxcloud: {
		logo: '',
		title: 'LYNX Cloud',
		uri: '',
		unlinkUri: '',
		icon: '/assets/icons/logo-lynx-cloud.png'
	},
	onedrive: {
		logo: '/assets/icons/logo-onedrive-large.png',
		title: 'OneDrive',
		uri: '/auth/onedrive',
		unlinkUri: '/auth/onedrive/delete',
		icon: '/assets/icons/logo-onedrive.svg'
	},
	organisation: {
		logo: '',
		title: 'Organisation',
		uri: '',
		unlinkUri: '',
		icon: '/assets/icons/logo-organisation.svg'
	}
}
