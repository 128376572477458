import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Label } from '../Label/Label'
import css from './FooterLinksItem.module.scss'
import { t } from 'i18next'

export const FooterLinksItem = ({ scrollTop, variant = 'light' }: { scrollTop?: () => void; variant?: 'light' | 'dark' }): React.ReactElement => {
	const dispatch = useDispatch()

	const handleFeedbackClicked = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.preventDefault()
		dispatch({ type: '@@feedback/CHANGE_VIEW', payload: 'intro' })
		dispatch({ type: '@@feedback/CHANGE_TOKEN', payload: 1 })
	}

	const textColor = variant === 'dark' ? '#ffffff' : '#575757'

	return (
		<div className={[css['container'], variant === 'dark' ? css['container--dark'] : css['container--light']].join(' ')}>
			<Label text={t('components.footer.products')} accentColor="#06C270" color={textColor} />
			<Label text={t('components.footer.company')} accentColor="#06C270" color={textColor} />
			<NavLink to="/" onClick={scrollTop}>
				LYNX Whiteboard
			</NavLink>
			<NavLink to="https://www.clevertouch.com">Clevertouch.com</NavLink>
			<NavLink to="/download-screenshare">LYNX Screen Share</NavLink>
			<NavLink to="/privacy">{t('components.footer.privacyPolicy')}</NavLink>
			<NavLink to="/download-recorder">LYNX Screen Recorder</NavLink>
			<NavLink to="" onClick={handleFeedbackClicked}>
				{t('components.footer.giveFeedback')}
			</NavLink>
			<NavLink to="/">LYNX Cloud</NavLink>
			<NavLink to="https://support.clevertouch.com/support-cases">{t('components.footer.contactUs')}</NavLink>
			<NavLink to="/download-activities">LYNX Activities</NavLink>
			<NavLink to="/change-logs/cloud/current/web">{t('components.footer.releaseNotes')}</NavLink>
		</div>
	)
}
