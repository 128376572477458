import { Card } from '../../../../components'
import css from './TermsCard.module.scss'
import React from 'react'

export const TermsCard = (): React.ReactElement => {
	return (
		<Card className={css['container']} enableBorder={false}>
			<h3>PLEASE READ CAREFULLY BEFORE INSTALLING ANY SOFTWARE: </h3>

			<p>
				This licence agreement (<b>Licence</b>) is a legal agreement between you (<b>Licensee</b> or <b>you</b>) and Sahara Presentation Systems Ltd
				trading as Clevertouch whose company number is 01335211 of Unit 4 Blueprint, Church Manorway, Erith, England, DA8 1DG (<b>Licensor</b>,{' '}
				<b>us</b> or <b>we</b>) for this Lynx Whiteboard computer software product for use on Windows 10, Linux or macOS operating systems
				(https://www.lynxcloud.app/) (<b>Software</b>), which includes the data supplied with the software, the associated media, printed materials and
				electronic documentation (<b>Documents</b>).
			</p>

			<p>
				We license use of the Software and Documents to you on the basis of this Licence. We do not sell the Software or Documents to you. We remain the
				owners of the Software and Documents at all times.
			</p>

			<p>
				<b>IMPORTANT NOTICE TO ALL USERS:</b>
			</p>

			<p>
				· BY CLICKING ON THE &quot;AGREE&quot; BUTTON BELOW YOU AGREE TO THE TERMS OF THIS LICENCE WHICH WILL BIND YOU AND YOUR EMPLOYEES. THE TERMS OF
				THIS LICENCE INCLUDE, IN PARTICULAR, LIMITATIONS ON LIABILITY IN CLAUSE 11 AND CLAUSE 12.
			</p>

			<p>
				· IF YOU DO NOT AGREE TO THE TERMS OF THIS LICENCE, WE ARE UNWILLING TO LICENCE THE SOFTWARE TO YOU AND YOU MUST CLICK ON THE &quot;LOGOUT&quot;
				BUTTON BELOW AND DISCONTINUE INSTALLATION OF THE SOFTWARE NOW.
			</p>

			<p>
				· THIS LICENCE IS INTENDED FOR BUSINESS-TO-BUSINESS (B2B) END USERS OF THE SOFTWARE ONLY. THIS LICENCE IS NOT INTENDED FOR USE IN A
				BUSINESS-TO-CONSUMER (B2C) ARRANGEMENT. PLEASE NOTE YOU MUST CLICK ON THE &quot;REJECT&quot; BUTTON BELOW AND DISCONTINUE INSTALLATION OF THE
				SOFTWARE NOW IF YOU ARE A CONSUMER.
			</p>

			<p>
				<b>You should print a copy of this Licence for future reference.</b>
			</p>

			<h3>1. Grant and scope of licence</h3>

			<p>
				1.1 In consideration of you agreeing to abide by the terms of this Licence, the Licensor hereby grants to you a non-exclusive, non-transferable,
				revocable and limited licence to use the Software and the Documents on the terms of this Licence.
			</p>

			<p>1.2 The Software is designed for interactive displays and is a lesson-building app for effective student collaboration.</p>

			<p>1.3 You may:</p>

			<p>
				1.3.1 download, install and use the Software for your internal business purposes only on any screen which is utilising an interactive whiteboard
				where such interactive whiteboard has been produced by the Licensor (Clevertouch Screen);
			</p>

			<p>
				1.3.2 download, install and use the Software for your internal business purposes only on any device which is utilising an interactive whiteboard
				purchased before this Licence is entered into, whether or not such interactive whiteboard has been produced by the Licensor (including but not
				limited to iPads, iPhones, Android devices, Windows PCs and Mac);
			</p>

			<p>
				1.3.3 receive and use any free supplementary software code or updates of the Software incorporating &quot;patches&quot; and corrections of
				errors as well as adding to and improving the functionality of the Software as may be provided by us from time to time but at all times this is
				subject to the restrictions contained in clause 2.1;
			</p>

			<p>1.3.4 use any Documents in support of the use permitted under clause 1.1.</p>

			<p>
				1.4 This Licence shall apply to the Software including if there is an upgrade, update, enhancement, or improvement to the Software supplied /
				released by the Licensor from time to time.
			</p>

			<h3>2. Restrictions</h3>

			<p>2.1 Except as expressly set out in this Licence or as permitted by any local law, you undertake:</p>

			<p>
				2.1.1 not to copy the Software or Documents except where such copying is incidental to normal use of the Software, or where it is necessary for
				the purpose of back-up or operational security;
			</p>

			<p>2.1.2 not to rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the Software or Documents;</p>

			<p>
				2.1.3 not to make alterations to, or modifications of, the whole or any part of the Software, nor permit the Software or any part of it to be
				combined with, or become incorporated in, any other programs;
			</p>

			<p>
				2.1.4 not to disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the Software nor attempt to
				do any such thing except to the extent that (by virtue of section 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be
				prohibited because they are essential for the purpose of achieving inter-operability of the Software with another software program, and provided
				that the information obtained by you during such activities:
			</p>

			<p>2.1.4.1 is used only for the purpose of achieving inter-operability of the Software with another software program; and</p>

			<p>2.1.4.2 is not unnecessarily disclosed or communicated without our prior written consent to any third party; and</p>

			<p>2.1.4.3 is not used to create any software which is substantially similar to the Software;</p>

			<p>
				2.1.5 to keep all copies of the Software secure and to maintain accurate and up-to-date records of the number and locations of all copies of the
				Software;
			</p>

			<p>
				2.1.6 to supervise and control use of the Software and ensure that the Software is used by your employees and representatives in accordance with
				the terms of this Licence;
			</p>

			<p>2.1.7 to include our copyright notice on all entire and partial copies you make of the Software on any medium;</p>

			<p>
				2.1.8 not to provide or otherwise make available the Software in whole or in part (including but not limited to program listings, object and
				source program listings, object code and source code), in any form to any person without prior written consent from the Licensor;{' '}
			</p>

			<p>2.1.9 to comply with all applicable technology control or export laws and regulations; </p>

			<p>2.1.10 not to upload or create any material when using the Software which violates our Acceptable Use Policy;</p>

			<p>2.1.11 to be bound and abide by any additional terms to this Licence by way of accepting and installing an update to the Software.</p>

			<p>
				2.2 You must permit the Licensor and its representatives, at all reasonable times and on reasonable advance notice, to inspect and have access
				to any premises, and to the computer equipment located there, at which the Software or the Documentation is being kept or used, and any records
				kept pursuant to this Licence, for the purpose of ensuring that you are complying with the terms of this Licence.
			</p>

			<p>
				2.3 We only supply the Software and Documents for internal use by your business, and you agree not to use the Software or Documents for any
				re-sale purposes.
			</p>

			<h3>3. Subscriptions and invoicing information</h3>

			<p>
				3.1 You can purchase a standard upgrade of the Software for £30 per licence per user per year (<b>Licence Fee</b>). Please note that strictly no
				sharing of user logins is permitted.
			</p>

			<p>
				3.2 If you cancel your subscription you will not receive a refund of the annual Licence Fee, but you will continue to receive your subscription
				content for the remainder of the current billing period, regardless of the cancellation date. Your cancellation will be effective after the
				current billing period has passed.
			</p>

			<p>
				3.3 We may offer you a free trial of the Software upgrades at our discretion from time to time. If you accept the free trial, your trial will
				convert to a paid subscription after [14] days, and will then be subject to the Licence Fee. You may cancel your subscription before the expiry
				of the free trial if you do not want to convert to a paid subscription.
			</p>

			<p>
				3.4 We only accept payment by direct debit and credit card. Payment is managed via our third party payment provider, Stripe Payments Europe
				Limited and Stripe Payments UK Ltd (<b>Stripe</b>). When you use a payment method, you accept and agree to the Stripe terms applicable to that
				payment method. To understand how Stripe will handle your personal data, please refer to their privacy policy on their website:
				https://stripe.com.
			</p>

			<h3>4. Registration</h3>

			<p>
				4.1 To use the Software, the Licensee must register the use of the Software. The Licensee must complete the registration form during the
				installation process once prompted and provide the information necessary for the creation of your account.{' '}
			</p>

			<h3>5. Support</h3>

			<p>
				5.1 If you register the purchase of the physical media on which the Software is recorded or fixed pursuant to clause 4 above, the Licensor`s
				technical support staff will endeavour to answer by any queries which you, as the original registered purchaser, may have regarding the use or
				application of the Software during the Warranty Period.{' '}
			</p>

			<p>
				5.2 For support please contact us here: <a href="https://www.clevertouch.com/uk/support">https://www.clevertouch.com/uk/support</a> or by
				telephone at +44 (0) 208 319 7705.{' '}
			</p>

			<p>5.3 Our team are available Monday through Friday from 9:00 to 5:00 GMT (excluding public holidays).</p>

			<h3>6. AI functionality</h3>

			<p>
				6.1 Any AI generated media is to be used for educational purposes and the results of compilations are not the responsibility of Clevertouch.
				Third party image generator software is used under license for the same educational purposes.
			</p>

			<h3>7. Handwriting recognition disclaimers</h3>

			<p>
				7.1{' '}
				<i>
					Clevertouch is not responsible for the software being unable to detect some handwritten script and the software should not be used as a
					replacement for using standard text.
				</i>
			</p>

			<h3>8. Storage</h3>

			<p>8.1 All storage of media created within the software is held in cloud based servers compliant with GDPR.</p>

			<h3>9. Intellectual property rights</h3>

			<p>
				9.1 You acknowledge that all intellectual property rights in the Software and the Documents anywhere in the world belong to us, that rights in
				the Software are licensed (not sold) to you, and that you have no rights in, or to, the Software or the Documents other than the right to use
				them in accordance with the terms of this Licence.
			</p>

			<p>9.2 You acknowledge that you have no right to have access to the Software in source code form.</p>

			<p>
				9.3 You shall indemnify and hold the Licensor harmless against any loss, liability or damage which it may suffer or incur as a result of your
				breach of any third party intellectual property rights howsoever arising.
			</p>

			<h3>10. Publicity</h3>

			<p>
				10.1 You hereby grant the Licensor the non-exclusive right to list you as a customer and use your logo on our website, on publicly available
				customer lists, and in media releases.{' '}
			</p>

			<h3>11. Limited warranty</h3>

			<p>
				11.1 If you are using the free version of the Software, the Licensor shall not in any circumstances provide any warranty as to the performance
				of the Software and you acknowledge that the Software is provided “AS IS”.
			</p>

			<p>11.2 If you have purchased a Licence from us, we warrant that:</p>

			<p>
				11.2.1 the Software will, when properly used and on an operating system for which it was designed, perform substantially in accordance with the
				functions described in the Documents; and
			</p>

			<p>11.2.2 that the Documents correctly describe the operation of the Software in all material respects,</p>

			<p>
				for a period of [90] days from the date of your installation of the Software (<b>Warranty Period</b>).
			</p>

			<p>
				11.3 You acknowledge that the Software has not been developed to meet your individual requirements, including any particular cybersecurity
				requirements you might be subject to under law or otherwise, and that it is therefore your responsibility to ensure that the facilities and
				functions of the Software as described in the Documents meet your requirements.
			</p>

			<p>11.4 Where you are using the free version of the Software, you acknowledge that the Software is provided “AS IS”.</p>

			<p>
				11.5 You acknowledge that the Software may not be free of bugs or errors and you agree that the existence of any minor errors shall not
				constitute a breach of this Licence.
			</p>

			<p>
				11.6 Where you have purchased a Licence from us if, within the Warranty Period, you notify us in writing of any defect or fault in the Software
				as a result of which it fails to perform substantially in accordance with the Documents, we will, at our sole option, either repair or replace
				the Software, provided that you make available all the information that may be necessary to help us to remedy the defect or fault, including
				sufficient information to enable us to recreate the defect or fault.
			</p>

			<p>11.7 The warranty does not apply:</p>

			<p>11.7.1 if the defect or fault in the Software results from you having altered or modified the Software; and</p>

			<p>11.7.2 if the defect or fault in the Software results from you having used the Software in breach of the terms of this Licence.</p>

			<p>
				11.8 The Licensor does not accept responsibility for any content that you display on the interactive screens or for content which is stored on
				our servers. Please see our Acceptable Use Policy for further details.
			</p>

			<h3>12. Limitation of liability</h3>

			<p>
				12.1 Subject to clause 12.3, if you are using the free version of the Software, the Licensor shall not in any circumstances have any liability
				for any losses or liabilities howsoever incurred or suffered by you arising out of or in connection with this Licence.
			</p>

			<p>12.2 Where you are using a paid-for Licence version of the Software, the following clauses 12.3 to 12.7 apply.</p>

			<p>12.3 Nothing in this Licence shall limit or exclude our liability for:</p>

			<p>12.3.1 death or personal injury resulting from our negligence;</p>

			<p>12.3.2 fraud or fraudulent misrepresentation;</p>

			<p>12.3.3 any other liability that cannot be excluded or limited by law.</p>

			<p>
				12.4 Subject to clause 12.3, the Licensor shall not in any circumstances have any liability for losses, liabilities, fines, costs, penalties or
				expenses suffered by you arising out of or in connection with this Licence (or any person claiming under or through the Licensee), whether the
				same are suffered directly or indirectly or are immediate or consequential, and whether the same arise in contract, tort (including negligence),
				or otherwise howsoever, which fall within any of the following categories:
			</p>

			<p>12.4.1 loss of profits, sales, business, or revenue;</p>

			<p>12.4.2 business interruption;</p>

			<p>12.4.3 loss of anticipated savings;</p>

			<p>12.4.4 wasted expenditure;</p>

			<p>12.4.5 loss or corruption of data or information;</p>

			<p>12.4.6 loss of business opportunity, goodwill or reputation.</p>

			<p>
				12.5 If you use the Software in accordance with clause 1.3.2 on hardware which has not been produced by Clevertouch, you accept that this is at
				your own risk and the Licensor shall not be responsible for any loss or damage to your property as a result of such use of the Software.
			</p>

			<p>
				12.6 Subject to clauses 12.3 and 12.4, our maximum aggregate liability under or in connection with this Licence whether in contract, tort
				(including negligence) or otherwise, shall in all circumstances be limited to a sum not exceeding the Licence Fees paid.
			</p>

			<p>
				12.7 This Licence sets out the full extent of our obligations and liabilities in respect of the supply of the Software and Documents. Except as
				expressly stated in this Licence, there are no conditions, warranties, representations or other terms, express or implied, that are binding on
				us. Any condition, warranty, representation or other term concerning the supply of the Software and Documents which might otherwise be implied
				into, or incorporated in, this Licence whether by statute, common law or otherwise, is excluded to the fullest extent permitted by law.
			</p>

			<h3>13. Termination</h3>

			<p>13.1 The Licensor may terminate this Licence immediately by written notice to you if:</p>

			<p>
				13.1.1 you commit a material or persistent breach of this Licence which you fail to remedy (if remediable) within 14 days after the service of
				written notice requiring you to do so; or
			</p>

			<p>
				13.1.2 the Licensee (where it is a company) becomes insolvent or unable to pay its debts (within the meaning of section 123 of the Insolvency
				Act 1986), enters into liquidation, whether voluntary or compulsory (other than for reasons of bona fide amalgamation or reconstruction), passes
				a resolution for its winding-up, has a receiver or administrator manager, trustee, liquidator or similar officer appointed over the whole or any
				part of its assets, makes any composition or arrangement with its creditors or takes or suffers any similar action in consequence of its debt.
			</p>

			<p>13.2 On termination for any reason:</p>

			<p>13.2.1 all rights granted to you under this Licence shall cease;</p>

			<p>13.2.2 you must immediately cease all activities authorised by this Licence;</p>

			<p>13.2.3 you must immediately pay to the Licensor any sums due to the Licensor under this Licence; and </p>

			<p>
				13.2.4 you must immediately and permanently delete or remove the Software from all computer equipment in your possession, and immediately
				destroy or return to us (at our option) all copies of the Software and Documents then in your possession, custody or control and, in the case of
				destruction, certify to us that you have done so.
			</p>

			<h3>14. Communications between us</h3>

			<p>
				14.1 We may update the terms of this Licence at any time on notice to you in accordance with this clause 14. Your continued use of the Software
				and Documents following the deemed receipt and service of the notice under clause 14.3 shall constitute your acceptance to the terms of this
				Licence, as varied. If you do not wish to accept the terms of the Licence (as varied) you must immediately stop using and accessing the Software
				and Document on the deemed receipt and service of the notice.
			</p>

			<p>
				14.2 If we have to contact you, we will do so by email or by post to the address you provided in accordance with your registration of the
				Software.
			</p>

			<p>14.3 Note that any notice:</p>

			<p>
				14.3.1 given by us to you will be deemed received and properly served 24 hours after it is first posted on our website, 24 hours after an email
				is sent, or three days after the date of posting of any letter; and
			</p>

			<p>
				14.3.2 given by you to us will be deemed received and properly served 24 hours after an email is sent, or three days after the date of posting
				of any letter.
			</p>

			<p>
				14.4 In proving the service of any notice, it will be sufficient to prove, in the case of posting on our website, that the website was generally
				accessible to the public for a period of 24 hours after the first posting of the notice; in the case of a letter, that such letter was properly
				addressed, stamped and placed in the post to the address of the recipient given for these purposes; and, in the case of an email, that such
				email was sent to the email address of the recipient given for these purposes.
			</p>

			<h3>15. Events outside our control</h3>

			<p>
				15.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under this Licence that
				is caused by an Event Outside Our Control. An Event Outside Our Control is defined below in clause 15.2.
			</p>

			<p>
				15.2 An <b>Event Outside Our Control</b> means any act or event beyond our reasonable control, including without limitation failure of public or
				private telecommunications networks.
			</p>

			<p>15.3 If an Event Outside Our Control takes place that affects the performance of our obligations under this Licence:</p>

			<p>
				15.3.1 our obligations under this Licence will be suspended and the time for performance of our obligations will be extended for the duration of
				the Event Outside Our Control; and
			</p>

			<p>
				15.3.2 we will use our reasonable endeavours to find a solution by which our obligations under this Licence may be performed despite the Event
				Outside Our Control.
			</p>

			<h3>16. Data protection</h3>

			<p>
				16.1 Under data protection legislation, we are required to provide you with certain information about who we are, how we process the personal
				data of those individuals who use the Software and the Documents and for what purposes and those individuals` rights in relation to their
				personal data and how to exercise them. This information is provided in{' '}
				<a href="https://support.clevertouch.com/privacy">https://support.clevertouch.com/privacy</a> and it is important that you read that
				information.
			</p>

			<p>
				16.2 You acknowledge that our Software is not designed for the storage of personal data and you will indemnify us in respect of any breaches in
				relation to the incidental processing by us of any personal data that you store on our Software or in any storage provided to you by us.
			</p>

			<h3>17. Other important terms</h3>

			<p>
				17.1 We may transfer our rights and obligations under this Licence to another organisation if we are sold or sell our business, but this will
				not affect your rights or our obligations under this Licence.
			</p>

			<p>17.2 You may only transfer your rights or your obligations under this Licence to another person if we agree in writing.</p>

			<p>
				17.3 This Licence and any document expressly referred to in it constitutes the entire agreement between us and supersedes and extinguishes all
				previous and contemporaneous agreements, promises, assurances and understandings between us, whether written or oral, relating to its subject
				matter.
			</p>

			<p>
				17.4 You acknowledge that in entering into this Licence you do not rely on and shall have no remedies in respect of any statement,
				representation, assurance or warranty (whether made innocently or negligently) that is not set out in this Licence or any document expressly
				referred to in it.
			</p>

			<p>
				17.5 You agree that you shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in this
				Licence or any document expressly referred to in it.
			</p>

			<p>
				17.6 A waiver of any right or remedy is only effective if given in writing and shall not be deemed a waiver of any subsequent right or remedy.
			</p>

			<p>
				17.7 A delay or failure to exercise, or the single or partial exercise of, any right or remedy shall not waive that or any other right or
				remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy.
			</p>

			<p>
				17.8 Each of the clauses of this Licence operates separately. If any court or competent authority decides that any of them are unlawful or
				unenforceable, the remaining clauses will remain in full force and effect.
			</p>

			<p>
				17.9 This Licence, its subject matter and its formation (and any non-contractual disputes or claims) are governed by English law. We both
				irrevocably agree to the exclusive jurisdiction of the courts of England.
			</p>
		</Card>
	)
}
