import React from 'react'
import css from './HeroItem.module.scss'

export interface HeroItemProps {
	title: string
	body: React.ReactNode
	actions: React.ReactNode
	footer: string
}

export const HeroItem = ({ title, body, actions, footer }: HeroItemProps): React.ReactElement => {
	return (
		<div className={css['container']}>
			<h2 className={css['container__title']}>{title}</h2>
			<div className={css['container__body']}>{body}</div>
			<div className={css['container__actions']}>{actions}</div>
			<div className={css['container__footer']}>{footer}</div>
		</div>
	)
}
