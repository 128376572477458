import { INotification, RequestSendNotificationPayload, UpdateNotificationPayload } from '@lynx/core/src/interfaces/Notifications'
import { BaseCall } from '../BaseCall'
import { APIConfig, APIResponse } from '../interfaces'

export class Notifications extends BaseCall {
	public deleteAllNotifications = async (): Promise<APIResponse | null> => {
		try {
			const res = await this.client.delete('/notifications')
			return res.data
		} catch (err) {
			console.error(err)
			return null
		}
	}

	public deleteNotification = async (notificationId: number): Promise<APIResponse | null> => {
		try {
			const res = await this.client.delete(`/notifications/${notificationId}`)
			return res.data
		} catch (err) {
			console.error(err)
			return null
		}
	}

	public getNotifications = async (count?: number): Promise<APIResponse<INotification[]> | null> => {
		try {
			const res = await this.client.get(`/notifications/all/${count}`)
			return res.data
		} catch (err) {
			console.error(err)
			return null
		}
	}

	public getNotification = async (id: number): Promise<INotification | null> => {
		try {
			const res = await this.client.get(`/notifications/${id}`)
			return res.data
		} catch (err) {
			console.error(err)
			return null
		}
	}

	public sendNotification = async (notification: RequestSendNotificationPayload): Promise<APIResponse<INotification> | null> => {
		try {
			const res = await this.client.post('/notifications/send', notification)
			return res.data
		} catch (err) {
			console.error(err)
			return null
		}
	}

	public updateNotification = async (notification: UpdateNotificationPayload): Promise<APIResponse<null> | null> => {
		try {
			const res = await this.client.post(`/notifications/${notification.id}`, notification)
			return res.data
		} catch (err) {
			console.error(err)
			return null
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
