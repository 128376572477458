import { CheckCircleOutlineIcon, ErrorIcon, InfoIcon, WarningIcon } from '@lynx/client-core/src/components/Icons'
import { timeSince } from '@lynx/client-core/src/utils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { deleteNotificationToast } from 'store/notifications'
import { DefaultTemplateProps } from './interfaces'
import css from './NotificationVariants.module.scss'

export const variants = {
	information: InfoIcon,
	error: ErrorIcon,
	success: CheckCircleOutlineIcon,
	warning: WarningIcon
}

export const DefaultTemplate = ({
	variant,
	children,
	isNotificationClickDisable = false,
	onNotificationClick,
	isViewed,
	customIcon,
	isToast,
	createdAt,
	notification,
	isAlignCenter = true
}: DefaultTemplateProps): React.ReactElement => {
	const { timeout: defaultTimeout } = useSelector((state: ApplicationState) => state.notifications)
	const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

	const dispatch = useDispatch()

	const notificationClickHandler = (): void => {
		if (isNotificationClickDisable) return
		onNotificationClick && onNotificationClick()
	}

	const fireTimeout = (): void => {
		const internalTimer = setTimeout(() => {
			dispatch(deleteNotificationToast(notification))
		}, defaultTimeout)
		setTimer(internalTimer)
	}

	useEffect(() => {
		const mount = (): void => {
			if (isToast) fireTimeout()
		}

		mount()

		return () => {
			if (isToast && timer) clearTimeout(timer)
		}
	}, [])

	const onMouseEnter = (): void => {
		isToast && timer && clearTimeout(timer)
	}
	const onMouseLeave = (): void => {
		isToast && fireTimeout()
	}
	const classes = [css.container, css[variant]]

	isToast && classes.push(css.toast)
	isAlignCenter && classes.push(css.containerCentered)
	!isNotificationClickDisable && !isToast && classes.push(isViewed ? css.viewed : css.notViewed)

	const Icon = customIcon || variants[variant]
	return (
		<div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={classes.join(' ')} onClick={notificationClickHandler}>
			<Icon className={css.icon} />
			<div className={css.childrenContainer}>{children}</div>
			{createdAt && <div className={css.timeContainer}>{timeSince(new Date(createdAt))}</div>}
		</div>
	)
}
