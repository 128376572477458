import * as qs from 'query-string'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Toggle } from '../../../../'
import { ApplicationState } from '../../../../../store'
import {
	AppleLogoIcon,
	ClassLinkIcon,
	CleverLiveIcon,
	CleverLogoIcon,
	DropboxLogoIcon,
	EmailLogoIcon,
	GoogleClassroomLogoIcon,
	GoogleLogoIcon,
	IServDarkIcon,
	InfoOutlinedIcon,
	MailOutlineIcon,
	MicrosoftLogoIcon,
	NextCloudLogoIcon,
	QRCodeLogoIcon,
	SamlIcon,
	UcsIcon
} from '../../../../Icons'
import { Section } from '../../../../Sections'
import { PrivacyNotice } from './PrivacyNotice'
import css from './SignInSection.module.scss'
import { SignInSectionProps } from './interfaces/SignInSectionProps'

const ProviderLogoMap: { [key: string]: React.ReactNode } = {
	google: <GoogleLogoIcon />,
	googleClassroom: <GoogleClassroomLogoIcon />,
	apple: <AppleLogoIcon />,
	microsoft: <MicrosoftLogoIcon />,
	dropbox: <DropboxLogoIcon />,
	nextCloud: <NextCloudLogoIcon className={css.nextCloudLogo} />,
	email: <EmailLogoIcon />,
	qr: <QRCodeLogoIcon />,
	clever: <CleverLogoIcon />,
	cleverlive: <CleverLiveIcon />,
	sso: <MailOutlineIcon />,
	saml: <SamlIcon />,
	ucs: <UcsIcon />,
	iserv: <IServDarkIcon />,
	classlink: <ClassLinkIcon />
}

const ProviderButton = ({
	provider,
	label,
	href,
	navigate,
	navigateSection,
	isPrivate = true,
	onClick
}: {
	provider: string
	href?: string
	label: string
	onClick?: () => void
	navigateSection?: (section: string, context: { privacyChecked: boolean; remote: boolean }) => void
	navigate?: string
	isPrivate?: boolean
}): React.ReactElement => (
	<button
		id={`SignInSection-${provider}-Login`}
		className={css['provider-button']}
		onClick={(): void => {
			if (navigateSection && navigate) {
				const privacyChecked = isPrivate
				const queryParams = qs.parse(location.search) as { [key: string]: string }
				const remote = queryParams.qt && queryParams.remote ? true : false
				navigateSection(navigate, { privacyChecked, remote })
			} else if (href) {
				window.location.href = href
			} else if (onClick) onClick()
		}}
	>
		<div className={css['provider-title']}>
			<div className={css.iconContainer}>{ProviderLogoMap[provider]}</div>
			<div className={css.labelContainer}>{label}</div>
		</div>
	</button>
)

export const SignInSection = ({ navigate: navigateSection }: SignInSectionProps): React.ReactElement => {
	const location = useLocation()
	const { t } = useTranslation()
	const { config: configState } = useSelector((state: ApplicationState) => state)
	const query = qs.parse(location.search) as { [key: string]: string }
	const [state, setState] = useState({
		email: '',
		password: '',
		error: '',
		nextStep: false,
		privacyChecked: !!query.qt,
		showInfo: false
	})

	const handleSignUp = (): void => {
		navigateSection('SignUpSection')
	}

	const handleEmail = (): void => {
		navigateSection('EmailSection', { privacyChecked: state.privacyChecked })
	}

	const handlePrivacyChanged = (): void => {
		setState({ ...state, privacyChecked: !state.privacyChecked })
	}

	const handleInfoClicked = (): void => {
		setState({ ...state, showInfo: !state.showInfo })
	}

	/**
	 * Get query params to send to the API
	 */
	const getQueryParams = (): string => {
		// Empty object to add new query params
		const queryObj: { [key: string]: string } = {}
		queryObj.rememberMe = privacyChecked ? 'false' : 'true'
		queryObj.qrCode = query.qrCode
		queryObj.ws = query?.qt && query?.code ? query.code : ''
		queryObj.redirect = window.location.href.replace(window.location.origin, '')
		queryObj.scheme = query.scheme
		const str = `?${qs.stringify(queryObj)}`

		return str
	}

	const { API_SERVER } = configState
	const { privacyChecked, showInfo } = state
	const { remote } = query

	const providerQueryParams = getQueryParams()

	const ssoProviders = (
		<React.Fragment>
			<div className={css.ssoDivider}>{t('pages.login.supported')}&nbsp;Single Sign-Ons</div>
			<ProviderButton provider="ucs" label="UCS" onClick={handleEmail} />
			<ProviderButton provider="nextCloud" label="Next Cloud" onClick={handleEmail} />
			<ProviderButton provider="saml" label="SAML" onClick={handleEmail} />
			<ProviderButton provider="iserv" label="iServ" onClick={handleEmail} />
		</React.Fragment>
	)

	return (
		<Section side="right" className={css['login-section']}>
			<div className={css['modal-right-container']}>
				<h1>{t('pages.login.signIn')}</h1>
				{showInfo && <PrivacyNotice />}
				<div style={{ color: '#494F5D', textAlign: 'start', width: '100%', fontSize: '16px', marginTop: '10px' }}>
					{t('pages.login.alreadyHaveAccount')}
				</div>
				<div className={css['provider-button-container']}>
					{remote && (
						<ProviderButton provider="email" label="Email" navigateSection={navigateSection} navigate="EmailSection" isPrivate={privacyChecked} />
					)}
					{!remote && (
						<ProviderButton
							provider="sso"
							label={`${t('pages.login.emailOr')}Single Sign-on (SSO)`}
							navigateSection={navigateSection}
							navigate="EmailSection"
							isPrivate={privacyChecked}
						/>
					)}
					{!remote && <ProviderButton provider="qr" label="QR" navigateSection={navigateSection} navigate="QRSection" isPrivate={privacyChecked} />}
					<ProviderButton provider="cleverlive" label="CleverLive" href={`${API_SERVER}/auth/cleverlive/login${providerQueryParams}`} />
					<ProviderButton provider="google" label="Google" href={`${API_SERVER}/auth/google/login${providerQueryParams}`} />
					<ProviderButton provider="microsoft" label="Microsoft" href={`${API_SERVER}/auth/onedrive/login${providerQueryParams}`} />
					<ProviderButton provider="apple" label="Apple" href={`${API_SERVER}/auth/apple/login${providerQueryParams}`} />
					<ProviderButton provider="dropbox" label="Dropbox" href={`${API_SERVER}/auth/dropbox/login${providerQueryParams}`} />
					<ProviderButton
						provider="googleClassroom"
						label="Google Classroom"
						href={`${API_SERVER}/auth/googleClassroom/login${providerQueryParams}`}
					/>
					<ProviderButton provider="clever" label="Clever" href={`${API_SERVER}/auth/clever/login${providerQueryParams}`} />
					{configState.ENVIRONMENT != 'production' && (
						<ProviderButton provider="classlink" label="ClassLink" href={`${API_SERVER}/auth/classlink/login${providerQueryParams}`} />
					)}
					{!remote && ssoProviders}
				</div>
				<div className={css['privacy-container']} style={query.qt ? { display: 'flex' } : { display: 'none' }}>
					<div className={css['privacy']}>
						<div className={css['privacy-row']}>
							<div>{t('pages.login.deviceShared')}</div>
							<div>
								<InfoOutlinedIcon onClick={handleInfoClicked} />
							</div>
						</div>
						<div className={css['privacy-toggle']}>
							<Toggle checked={privacyChecked} onChange={handlePrivacyChanged} />
						</div>
					</div>
				</div>
				<div className={css['signup']}>
					<div className={css['no-account-text']}>{t('pages.login.noAccountMessage')}</div>
					<div onClick={handleSignUp} className={css['text-button']}>
						{t('pages.login.signUpHere')}
					</div>
				</div>
			</div>
		</Section>
	)
}
