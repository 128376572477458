import { Document } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React from 'react'
import css from './AccessDenied.module.css'

export const AccessDenied = (): React.ReactElement => {
	const accessDeniedTitle = i18next.t('pages.accessDenied.accessDenied')
	return (
		<Document title={accessDeniedTitle}>
			<div className={css['container']}>
				<div className={css['card']}>
					<h1>{accessDeniedTitle}</h1>
					<div>{i18next.t('pages.accessDenied.body')}</div>
				</div>
			</div>
		</Document>
	)
}
