import { APIRequest } from '@lynx/client-core/src/api'
import { Logger } from '@lynx/client-core/src/modules'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ApplicationState } from 'store'
import {
	ADD_DOWNLOAD,
	CHANGE_PAGE,
	CLEAR_DOWNLOAD,
	DocumentActionTypes,
	DOCUMENT_AVAILABLE,
	DOCUMENT_CLEAR,
	DOCUMENT_LOAD,
	DOCUMENT_LOADED,
	DOCUMENT_REQUEST,
	RequestDocument,
	SHARED_DOCUMENT_LOAD
} from './types'

const loadDocumentInternal = (fileId: string, doc: { link: string; extension: string }): DocumentActionTypes => {
	return {
		type: DOCUMENT_LOAD,
		payload: {
			fileId,
			extension: doc.extension,
			blobLink: doc.link
		}
	}
}

const loadSharedDocumentInternal = (token: string, doc: { link: string; extension: string }): DocumentActionTypes => {
	return {
		type: SHARED_DOCUMENT_LOAD,
		payload: {
			token,
			extension: doc.extension,
			blobLink: doc.link
		}
	}
}

const documentLoadedInternal = (fileId: string, numPages: number): DocumentActionTypes => {
	return {
		type: DOCUMENT_LOADED,
		payload: {
			fileId,
			numPages
		}
	}
}

const changePageInternal = (page: number): DocumentActionTypes => {
	return {
		type: CHANGE_PAGE,
		payload: page
	}
}

export const addDownloadInternal = (channel: string): DocumentActionTypes => {
	return {
		type: ADD_DOWNLOAD,
		payload: channel
	}
}

export const addDownload =
	(channel: string): ThunkAction<void, ApplicationState, null, Action<string>> =>
	(dispatch): void => {
		dispatch(addDownloadInternal(channel))
	}

export const clearDownload = (): DocumentActionTypes => {
	return {
		type: CLEAR_DOWNLOAD
	}
}

export const changePage =
	(page: number): ThunkAction<void, ApplicationState, null, Action<string>> =>
	(dispatch): void => {
		dispatch(changePageInternal(page))
	}

export const documentLoaded =
	(fileId: string, numPages: number): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			dispatch(documentLoadedInternal(fileId, numPages))

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const loadSharedDocument =
	(token: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			const doc = await APIRequest.Groups.downloadSharedLink(token)

			if (!doc) {
				throw new Error('document is undefined')
			}

			if (doc) {
				dispatch(loadSharedDocumentInternal(token, doc))
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

export const loadDocument =
	(fileId: string): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			const doc = await APIRequest.ObjectStore.downloadLink('lynxcloud', fileId)

			if (!doc) {
				throw new Error('document is undefined')
			}

			if (doc) {
				dispatch(loadDocumentInternal(fileId, doc))
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

const requestDocumentInternal = (channelId: string, fileId: string, download: boolean, shareToken: string): DocumentActionTypes => {
	return {
		type: DOCUMENT_REQUEST,
		payload: {
			channelId,
			fileId,
			autoDownload: download,
			shareToken
		}
	}
}

export const documentAvailable = (fileId: string): DocumentActionTypes => {
	return {
		type: DOCUMENT_AVAILABLE,
		payload: {
			fileId
		}
	}
}

export const requestDocument =
	(doc: RequestDocument): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			const { fileId, download, resolution, conversionType, shareToken } = doc

			// Create a new channel to listen on
			const createChannelRequest = await APIRequest.Channels.create('conversions', 'Lynx Conversion')
			if (!createChannelRequest) {
				Logger.error('channel not created')
				return false
			}

			// Store request type
			dispatch(requestDocumentInternal(createChannelRequest.id, fileId, download || false, shareToken))

			if (!download) {
				const hasSent = await APIRequest.Convert.convertSharedFile(createChannelRequest.id, shareToken, conversionType, resolution)
				if (!hasSent) {
					Logger.error('conversion not sent')
					return false
				}
			} else {
				const hasSent = await APIRequest.Convert.convertSharedFile(createChannelRequest.id, shareToken, conversionType)
				if (!hasSent) {
					Logger.error('conversion not sent')
					return false
				}
			}

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}

const clearDocumentInternal = (): DocumentActionTypes => {
	return {
		type: DOCUMENT_CLEAR
	}
}

export const clearDocument =
	(): ThunkAction<Promise<boolean>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<boolean> => {
		try {
			dispatch(clearDocumentInternal())

			return true
		} catch (e) {
			Logger.error(e)
			return false
		}
	}
