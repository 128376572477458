import { ArrowForwardIosIcon } from '@lynx/client-core/src/components'
import * as React from 'react'
import Styles from './DirectionArrows.module.css'

interface DirectionArrowsProps {
	page: number
	onBackClicked: () => void
	onForwardClicked: () => void
	pageCount: number
}

export const DirectionArrows = ({ onBackClicked, onForwardClicked, page, pageCount }: DirectionArrowsProps): React.ReactElement => {
	const isLastPage = page + 1 === pageCount
	const isFirstPage = page + 1 === 1

	return (
		<div className={Styles['container']}>
			<ArrowForwardIosIcon style={{ transform: 'scaleX(-1)', fill: isFirstPage ? '#808080' : 'white' }} onClick={onBackClicked} />
			<div style={{ width: '25px', textAlign: 'center' }}>{`${page + 1}`}</div>
			<div style={{ borderLeft: '1px solid white', height: '20px' }}> </div>
			<div style={{ width: '25px', textAlign: 'center' }}>{`${pageCount}`}</div>
			<ArrowForwardIosIcon style={{ fill: isLastPage ? '#808080' : 'white' }} onClick={onForwardClicked} />
		</div>
	)
}
