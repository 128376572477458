import { APIRequest } from '@lynx/client-core/src/api'
import { Organisation } from '@lynx/client-core/src/api/interfaces'
import { Modal } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import Styles from 'pages/Share/Modals/ShareModal.module.css'
import * as React from 'react'
import { connect } from 'react-redux'
import { OptionTypeBase, StylesConfig } from 'react-select'
import { default as ReactSelectAsync } from 'react-select/async'
import { ApplicationState } from 'store'
import { GroupShareModalContextType, hideModal, ModalState } from 'store/modal'

interface GroupShareModalProps {
	hideModal: typeof hideModal
	modal: ModalState
}

interface GroupShareModalState {
	selectedGroupId: string
	organisations: Organisation[]
	groups: { value: string; label: string }[]
}

const customStyles: StylesConfig<OptionTypeBase, true> = {
	option: (provided, state) => {
		return {
			...provided,
			borderBottom: '1px dotted darkgray',
			color: state.isSelected ? 'white' : 'black',
			padding: 10
		}
	}
}

class GroupShareModal extends React.Component<GroupShareModalProps, GroupShareModalState> {
	state = {
		organisations: [],
		selectedGroupId: '',
		groups: []
	}

	async componentDidMount(): Promise<void> {
		const result = await APIRequest.Organisations.getAllUsersGroups()
		if (!result || !Array.isArray(result)) {
			return
		}

		const groups: { value: string; label: string }[] = []
		for (const organisation of result) {
			if (!organisation.groups) {
				continue
			}
			for (const group of organisation.groups) {
				groups.push({
					value: `${organisation.id}/groups/${group.id}`,
					label: `${group.groupName} (${organisation.organisationName})`
				})
			}
		}

		this.setState({
			organisations: result,
			groups: groups
		})
	}

	private handleGroupSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		e.preventDefault()

		const { selectedGroupId } = this.state
		if (!selectedGroupId) {
			Logger.error('no group selected')
			return
		}

		const { context } = this.props.modal
		if (context?.type !== GroupShareModalContextType) {
			return
		}

		const { token } = context
		if (!token) {
			Logger.error('missing token')
			return
		}

		await APIRequest.Groups.shareGroupToken(selectedGroupId, token)

		this.props.hideModal()
	}

	private loadOptions = async (inputValue: string, callback: (options: { value: string; label: string }[]) => void): Promise<void> => {
		callback(this.state.groups)
	}

	private handleChange = (option: any): void => {
		this.setState({
			selectedGroupId: option.value
		})
	}

	render(): React.ReactNode {
		return (
			<Modal name="GroupShareModal">
				<form className={Styles['container']} onSubmit={this.handleGroupSubmit}>
					<p>Search a group to send to.</p>
					<ReactSelectAsync
						id="groupSelect"
						styles={customStyles}
						loadOptions={this.loadOptions}
						name="groupSelect"
						matchPos="start"
						labelKey="type"
						matchProp="value"
						noOptionsMessage={(): string | null => 'Start typing..'}
						isClearable={true}
						defaultOptions={this.state.groups}
						onChange={this.handleChange}
					/>
					<br />
					<button className={Styles.button} type="submit">
						Send To Group
					</button>
				</form>
			</Modal>
		)
	}
}

const mapStateToProps = (state: ApplicationState): { modal: ModalState } => ({
	modal: state.modal
})

export default connect(mapStateToProps, { hideModal })(GroupShareModal)
