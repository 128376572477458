import { BasicButton } from '@lynx/client-core/src/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './SamlIntroduction.module.css'

export interface SamlIntroductionProps {
	navigate: (section: string, { isUCS, isIserv }?: { isUCS: boolean; isIserv: boolean }) => void
	context: { isUCS: boolean; isIserv: boolean }
}

export const SamlIntroduction = (props: SamlIntroductionProps): React.ReactElement => {
	const { t } = useTranslation()

	const handleNext = (): void => {
		props.navigate('SamlSetup', props.context)
	}

	return (
		<div className={css['container']}>
			<div className={css['intro-text']}>
				<p>{t('pages.organisation.samlIntroduction1')}</p>
				<p>{t('pages.organisation.samlIntroduction2')}</p>
				<p>{t('pages.organisation.samlIntroduction3')}</p>
				<p>{t('pages.organisation.samlIntroduction4')}</p>
				<p>{t('pages.organisation.samlIntroduction5')}</p>
				<p>{t('pages.organisation.samlIntroduction6')}</p>
				<p>{t('pages.organisation.samlIntroduction7')}</p>
				<p>{t('pages.organisation.samlIntroduction8')}</p>
			</div>
			<div className={css['buttons']}>
				<BasicButton
					onClick={(): void => {
						props.navigate('OrganisationTypeSelector')
					}}
				>
					{t('pages.organisations.createModal.back')}
				</BasicButton>
				<BasicButton variant="blue" onClick={handleNext}>
					{t('pages.organisations.createModal.next')}
				</BasicButton>
			</div>
		</div>
	)
}
