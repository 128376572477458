import {
	ActivitiesIllustration1Src,
	ActivitiesIllustration2Src,
	ActivitiesIllustration3Src,
	ActivitiesIllustration4Src,
	ActivitiesIllustration5Src,
	ActivitiesSpinnerSrc,
	BasicButton,
	BulletPointItem,
	BulletPoints,
	CreateIcon,
	Document,
	FeatureItem,
	FeatureSection,
	FooterItem,
	FooterLinksItem,
	GroupedButtons,
	HeroItem
} from '@lynx/client-core/src/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './DownloadActivities.module.scss'

export const DownloadActivities = (): React.ReactElement => {
	const { config } = useSelector((state: ApplicationState) => state)
	const { t } = useTranslation()

	return (
		<Document title={t('pages.landing.title')}>
			<FeatureSection className={'hero-background'} backgroundColor="#272727">
				<HeroItem
					title={t('pages.landing.improveRetention')}
					body={
						<BulletPoints>
							{[
								{ icon: <CreateIcon fill="#AF66FF" />, text: t('pages.landing.freeActivities') },
								{ icon: <CreateIcon fill="#00C5FF" />, text: t('pages.landing.customSpinners') },
								{ icon: <CreateIcon fill="#28C283" />, text: t('pages.landing.liveChat') }
							].map(({ icon, text }, key) => (
								<BulletPointItem key={key} icon={icon} text={text} />
							))}
						</BulletPoints>
					}
					footer={t('pages.landing.trusted')}
					actions={
						<GroupedButtons>
							<BasicButton size="xl" variant="blue" to={config.LYNXACTIVITIES_CLIENT}>
								{t('pages.landing.tryNow')}
							</BasicButton>
							<BasicButton size="xl" variant="transparent" to="https://youtu.be/BiEVxEMurqM" target="__blank">
								{t('pages.landing.watchVideo')}
							</BasicButton>
						</GroupedButtons>
					}
				/>
				<img src={ActivitiesSpinnerSrc} className={css['rotate-anim']} alt="ActivitiesIllustration0" />
			</FeatureSection>

			<FeatureSection>
				<FeatureItem
					label={t('pages.landing.interactiveLearning')}
					accentColor="#AF66FF"
					title={t('pages.landing.overHundredActivities')}
					body={t('pages.landing.withOverHundredActivities')}
					action={t('pages.landing.learnMore')}
				/>
				<img src={ActivitiesIllustration1Src} alt="ActivitiesIllustration1" />
			</FeatureSection>

			<FeatureSection backgroundColor="#F6F6F6">
				<img src={ActivitiesIllustration2Src} alt="ActivitiesIllustration2" />
				<FeatureItem
					label={t('pages.landing.teamBuilding')}
					accentColor="#00C5FF"
					title={t('pages.landing.customSpinners')}
					body={t('pages.landing.createCustomQuizzes')}
					action={t('pages.landing.learnMore')}
				/>
			</FeatureSection>

			<FeatureSection>
				<FeatureItem
					label={t('pages.landing.revising')}
					accentColor="#28C283"
					title={t('pages.landing.flashcardsRevision')}
					body={t('pages.landing.flashcardsAidRevision')}
					action={t('pages.landing.learnMore')}
				/>
				<img src={ActivitiesIllustration3Src} alt="ActivitiesIllustration3" />
			</FeatureSection>

			<FeatureSection backgroundColor="#F6F6F6">
				<img src={ActivitiesIllustration4Src} alt="ActivitiesIllustration4" />
				<FeatureItem
					label={t('pages.landing.teamBuilding')}
					accentColor="#28C283"
					title={t('pages.landing.liveChat')}
					body={t('pages.landing.makeExperience')}
					action={t('pages.landing.learnMore')}
				/>
			</FeatureSection>

			<FeatureSection>
				<FeatureItem
					label={t('pages.landing.collaborating')}
					accentColor="#28C283"
					title={t('pages.landing.shareExperiences')}
					body={t('pages.landing.inviteStudents')}
					action={t('pages.landing.learnMore')}
				/>
				<img src={ActivitiesIllustration5Src} alt="ActivitiesIllustration5" />
			</FeatureSection>

			<FeatureSection backgroundColor="#F6F6F6" height="auto">
				<FooterItem />
				<FooterLinksItem />
			</FeatureSection>
		</Document>
	)
}
