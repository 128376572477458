import React from 'react'
import { AccountCircleIcon } from '../components'
import { ApplicationState } from '../store'
import { useSelector } from 'react-redux'
import { ProfileState } from '../store/profile'

export const useProfileIcon = ({ profile }: { profile?: ProfileState } = {}): { icon: React.ReactElement } => {
	const { profile: currentUserProfile } = useSelector((state: ApplicationState) => state)

	const selectedProfile = profile || currentUserProfile

	return selectedProfile.avatarBlobUrl ? { icon: <img src={selectedProfile.avatarBlobUrl} alt="avatar" /> } : { icon: <AccountCircleIcon /> }
}
