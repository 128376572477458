import * as React from 'react'
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'
import { VideoRendererProps } from './interfaces'
import Styles from './VideoRenderer.module.css'

export class VideoRenderer extends React.Component<VideoRendererProps> {
	private player: VideoJsPlayer | undefined
	private videoNode: HTMLVideoElement | undefined

	componentDidMount = (): void => {
		const options: VideoJsPlayerOptions = {
			...{
				autoplay: true,
				controls: true,
				// controlBar: false,
				poster: '',
				preload: 'auto',
				nativeControlsForTouch: false,
				fluid: false,
				height: this.props.height || 600,
				sources: [
					{
						src: this.getMediaName(this.props.blob, 'mp4'),
						type: 'video/mp4'
					},
					{
						src: this.getMediaName(this.props.blob, 'webm'),
						type: 'video/webm'
					}
				]
			},
			...this.props.options
		}

		if (!this.videoNode) {
			return
		}
		this.player = videojs(this.videoNode, options)
	}

	componentWillUnmount = (): void => {
		if (this.player) {
			this.player.dispose()
		}
	}

	private getMediaName(path: string, extension: string): string {
		if (!this.props.implicitExtension) {
			return path
		}
		if (path.includes(`.${extension}`)) {
			return path
		}
		return `${path.split('.').slice(0, -1).join('.')}.${extension}`
	}

	render(): React.ReactNode {
		return (
			<div className={Styles.container}>
				<div className={Styles['video-container']}>
					<div data-vjs-player id="vplayer">
						<video
							ref={(node: HTMLVideoElement): HTMLVideoElement => (this.videoNode = node)}
							className="video-js vj-fluid vjs-default-skin"
						></video>
					</div>
				</div>
			</div>
		)
	}
}
