import { BasicButton, Modal } from '../..'
import i18next from 'i18next'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { AreYouSureModalContext, AreYouSureModalContextType, hideModal, ModalContext } from '../../../store/modal'
import { ModalDefaultLayout } from '../../Modal/templates/ModalDefaultLayout/ModalDefaultLayout'

export const AreYouSure = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const [state, setState] = React.useState<AreYouSureModalContext>({
		type: AreYouSureModalContextType,
		confirmBody: '',
		confirmText: '',
		handleConfirm: async (): Promise<void> => {
			// eslint-disable-next-line no-useless-return
			return
		}
	})

	const handleVisible = (context?: ModalContext): void => {
		if (context?.type !== AreYouSureModalContextType) {
			return
		}

		setState(context)
	}

	return (
		<Modal name="AreYouSure" onVisible={handleVisible} hideCloseButton>
			<ModalDefaultLayout
				title={i18next.t('components.modals.areYouSureModal.title')}
				body={state.confirmBody}
				footer={
					<React.Fragment>
						<BasicButton variant="transparent" onClick={(): ReturnType<typeof hideModal> => dispatch(hideModal())}>
							Cancel
						</BasicButton>
						<BasicButton
							variant="danger"
							onClick={(): void => {
								state.handleConfirm()
								dispatch(hideModal())
							}}
						>
							{state.confirmText}
						</BasicButton>
					</React.Fragment>
				}
			/>
		</Modal>
	)
}
