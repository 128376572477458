import { APIRequest } from '@lynx/client-core/src/api'
import { Channels } from '@lynx/client-core/src/api/Channels'
import { BasicButton, Input } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import i18next from 'i18next'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ApplicationState } from 'store'
import css from './JoinSection.module.scss'

export const JoinSection = (): React.ReactElement | null => {
	const { config: configState } = useSelector((state: ApplicationState) => state)
	const navigate = useNavigate()
	const params = useParams<{ code: string }>()
	const [code, setCode] = useState<string>(params.code || '')
	const [secret, setSecret] = useState<string>('')
	const [hasSecret, setHasSecret] = useState<boolean>(false)

	const generateDisplayName = async (): Promise<string> => {
		const generated = await APIRequest.DisplayName.generate({
			categories: ['colours', 'animals'],
			split: '_'
		})
		return generated.data?.names[0] || ''
	}

	const handleCodeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setCode(event.target.value)
	}

	const handleSecretInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setSecret(event.target.value)
	}

	useEffect(() => {
		// Check if secret is required when code is in param
		const mount = async (): Promise<void> => {
			if (!params.code) return

			const codeInfo = await APIRequest.Channels.codeInfo(code)
			if (!codeInfo?.exists) {
				return
			}

			setHasSecret(codeInfo.secret)
		}
		mount()
	}, [])

	const handleConnectClicked = async (): Promise<void> => {
		// Routes or methods we redirect by based on the channel's tag
		const tagRoutes: { [key: string]: string } = {
			activities: `${configState.LYNXACTIVITIES_CLIENT}/join`,
			vnc: `${configState.LYNXVNC_CLIENT}`,
			share: 'url'
		}

		const codeInfo = await APIRequest.Channels.codeInfo(code)
		if (!codeInfo?.exists) {
			return
		}

		if (codeInfo.secret && !secret) {
			setHasSecret(codeInfo.secret)
			return
		}

		// Rather than use the pre-authenticated Channels API we'll have to new up our own
		const channelsAPI = new Channels()
		channelsAPI.setBaseUrl(configState.MESSAGING_API_SERVER)

		// Get the response from redeeming the code
		const redeemResponse = await channelsAPI.redeemCode(code, secret.trim())
		if (!redeemResponse) {
			return
		}

		const { accessToken, channelId } = redeemResponse

		// Further ops require authenticating with the returned access token
		channelsAPI.setAuthorization(accessToken)

		// Call the get route on the channel to retrieve the type of channel
		const channelDefinition = await channelsAPI.get(channelId)
		if (!channelDefinition) {
			Logger.error('missing channel definition')
			return
		}

		// We want to use the tag to determine the route or method it should take
		const { tag } = channelDefinition.type
		if (!tag) {
			return
		}

		// If the route is url then the url to navigate to is in the channel's
		// attachment
		const route = tagRoutes[tag]
		if (route === 'url') {
			const attachment = await channelsAPI.getAttachment(channelId)
			if (!attachment) {
				return
			}
			const { url } = JSON.parse(attachment)
			const uri = url.replace(configState.LYNXCLOUD_CLIENT, '')

			const parsedUrl = qs.parseUrl(uri)
			parsedUrl.query.code = code

			navigate(qs.stringifyUrl({ url: parsedUrl.url, query: parsedUrl.query }))
			return
		}

		const displayName = await generateDisplayName()

		// Otherwise navigate directly to the url
		window.location.href = `${route}?accessToken=${accessToken}&channelId=${channelId}&displayName=${displayName}`
	}

	return (
		<div className={css['container']}>
			<h3>{i18next.t('pages.join.joinSession')}</h3>
			<h4>{i18next.t('pages.join.joinDesc')}</h4>
			<div>
				<label>{i18next.t('pages.join.code')}</label>
				<Input type="text" variant="light" placeholder={i18next.t('pages.join.code')} value={code} onChange={handleCodeInput} />
			</div>
			{hasSecret && (
				<div>
					<label>{i18next.t('pages.join.secret')}</label>
					<Input type="text" variant="light" placeholder={i18next.t('pages.join.secret')} value={secret} onChange={handleSecretInput} />
				</div>
			)}
			<BasicButton size="md" variant="blue" onClick={handleConnectClicked}>
				{i18next.t('pages.join.connect')}
			</BasicButton>
		</div>
	)
}
