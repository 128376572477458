import React, { useEffect, useState } from 'react'
import { SharesInfo } from '@lynx/client-core/src/api/interfaces'
import { useNavigate, useParams } from 'react-router-dom'
import { APIRequest } from '@lynx/client-core/src/api'
import css from './Mesh.module.scss'
import { ControlBar } from 'pages/Share/components/ControlBar'
import { Logger } from '@lynx/client-core/src/modules/Logger'

interface MeshState {
	shareDetails: SharesInfo | null
	token: string
	blobLink: string
}

export const Mesh = (): React.ReactElement => {
	const navigate = useNavigate()
	const params = useParams<{ token: string }>()
	const [state, setState] = useState<MeshState>({
		shareDetails: null,
		token: '',
		blobLink: ''
	})

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const { token } = params
			if (!token) {
				return
			}
			const shareInfo = await APIRequest.Sharing.info(token)
			if (!shareInfo?.data) {
				navigate('/missing-share')
				return
			}

			const blobLinkProperties = await APIRequest.Sharing.getObjectUrl(token)
			if (!blobLinkProperties) {
				navigate('/missing-share')
			}

			setState({ ...state, token, blobLink: blobLinkProperties.link, shareDetails: shareInfo.data })
		}
		mount()
	}, [])

	const handleViewClicked = (): void => {
		Logger.log('view clicked')
	}

	return (
		<div className={css['container']}>
			{state.blobLink && <model-viewer autoplay={true} src={state.blobLink} camera-controls={true}></model-viewer>}
			{state.shareDetails && <ControlBar onViewClicked={handleViewClicked} shareDetails={state.shareDetails} token={state.token} />}
		</div>
	)
}
