import React from 'react'
import { NavLink } from 'react-router-dom'
import { AppleLogoIcon } from '../Icons'
import css from './DownloadButton.module.scss'

export interface DownloadButtonProps {
	icon: React.ReactElement
	label: string
	text: string
	to: string
}

export const DownloadButton = ({ icon = <AppleLogoIcon />, label = 'label', text = 'text', to = '/' }: DownloadButtonProps): React.ReactElement => {
	return (
		<NavLink to={to} className={css['container']}>
			<div className={css['container__body']}>
				<div className={css['container__icon']}>{icon}</div>
				<div className={css['container__content']}>
					<div className={css['container__label']}>{label}</div>
					<div className={css['container__text']}>{text}</div>
				</div>
			</div>
		</NavLink>
	)
}
