import Document from 'components/ReactPDFts/Document'
import Page from 'components/ReactPDFts/Page'
import * as React from 'react'
import { useState } from 'react'
import { PDFRendererProps, PDFRendererState } from './interfaces'
import Styles from './PDFRenderer.module.scss'
import { Sidebar } from './Sidebar'

export const PDFRenderer = ({ blobSrc }: PDFRendererProps): React.ReactElement => {
	const [state, setState] = useState<PDFRendererState>({
		numPages: 0,
		width: 1024,
		height: 768,
		pdfWidth: 0,
		pdfHeight: 0,
		rendering: true,
		currentPage: 0
	})

	const onDocumentLoadSuccess = async (document: { _pdfInfo: { numPages: number } }): Promise<void> => {
		setState({ ...state, numPages: document._pdfInfo.numPages, currentPage: 1 })
	}

	const onRenderStart = (): void => {
		setState({ ...state, rendering: true })
	}

	const onRenderSuccess = (dimensions: { width: number; height: number }): void => {
		setState({ ...state, rendering: false, pdfWidth: dimensions.width + 12, pdfHeight: dimensions.height + 12 })
	}

	const handleChangePage = (currentPage: number): void => {
		setState({ ...state, currentPage })
	}

	const display = state.rendering ? 'none' : 'flex'

	return (
		<div style={{ display: 'flex' }}>
			<Document file={blobSrc} onLoadSuccess={onDocumentLoadSuccess}>
				<div className={Styles.container}>
					<Sidebar pageCount={state.numPages} currentPage={state.currentPage} changePage={handleChangePage} height={state.pdfHeight} />
					<div style={{ display, padding: '6px', backgroundColor: 'transparent', position: 'relative' }}>
						<Page
							scale={1}
							rotate={0}
							onRenderStart={onRenderStart}
							onRenderSuccess={onRenderSuccess}
							width={state.width - 212}
							pageNumber={state.currentPage}
							style={display}
						/>
					</div>
				</div>
			</Document>
		</div>
	)
}
