import { BasicButton, ListItem } from '@lynx/client-core/src/components'
import React from 'react'
import css from './Payments.module.scss'
import { useTranslation } from 'react-i18next'

const data = [
	{
		date: '10/10/2023',
		plan: 'PRO',
		amount: '5.99'
	},
	{
		date: '10/10/2023',
		plan: 'PRO',
		amount: '5.99'
	}
]

export const Payments = (): React.ReactElement => {
	const { t } = useTranslation()

	return (
		<ListItem header={t('pages.profile.paymentHistory')}>
			<div className={css['container']}>
				<div className={css['container__description']}>
					<table className={css['striped']}>
						<tbody>
							{data.map((d, i) => (
								<tr key={i}>
									<td>{d.date}</td>
									<td>{d.plan}</td>
									<td>{d.amount}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className={css['container__actions']}>
					<BasicButton to="/profile" variant="light">
						{t('pages.profile.backToAccountDetails')}
					</BasicButton>
				</div>
			</div>
		</ListItem>
	)
}
