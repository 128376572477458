import { APIRequest } from '@lynx/client-core/src/api'
import { AreYouSure, Document } from '@lynx/client-core/src/components'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { useThunkDispatch } from 'hooks'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ApplicationState } from 'store'
import { AreYouSureModalContextType, showModal } from 'store/modal'
import { logout } from 'store/profile'

export const ProfileDelete = (): React.ReactElement => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const navigate = useNavigate()
	const { modal } = useSelector((state: ApplicationState) => state)
	const modalPrevious = usePrevious(modal)

	useEffect(() => {
		handleDeleteAccountClicked()
	}, [])

	useEffect(() => {
		if (modalPrevious?.name && !modal.name) {
			navigate('/')
		}
	}, [modal.name, modalPrevious?.name])

	const handleDeleteAccountClicked = (): void => {
		dispatch(
			showModal({
				name: 'AreYouSure',
				context: {
					type: AreYouSureModalContextType,
					handleConfirm: async (): Promise<void> => {
						const res = await APIRequest.User.requestDelete()
						if (res?.error === false) {
							await dispatchThunk(logout())
							navigate('/goodbye')
						}
					},
					confirmText: i18next.t('pages.profile.delete'),
					confirmBody: i18next.t('pages.profile.deleteAccountBody')
				}
			})
		)
	}

	return (
		<Document title={i18next.t('pages.pageTitles.profile')} description="Profile" keywords="Profile" auth={true}>
			<AreYouSure />
		</Document>
	)
}
