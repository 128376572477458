import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { useDebounce } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { FeedbackAnswer } from '@lynx/core/src/interfaces/Feedback'
import { pad } from '@lynx/core/src/string'
import { Card } from 'components/elmstone/Card'
import React, { ReactElement, useEffect, useState } from 'react'
import { FeedbackState } from './interfaces'

const renderRating = (item: FeedbackAnswer): React.ReactNode => {
	switch (item.optionIndex) {
		case 4:
			return '★'
		case 3:
			return '★★'
		case 2:
			return '★★★'
		case 1:
			return '★★★★'
		case 0:
			return '★★★★★'
		default:
			return 'N/A'
	}
}

const formatDate = (date: Date): string => {
	const d = new Date(date)
	const month = d.getMonth() + 1
	const day = d.getDate()
	const year = d.getFullYear()
	const hours = pad(d.getHours())
	const minutes = pad(d.getMinutes())

	return `${day}/${month}/${year} ${hours}:${minutes}`
}

const formatDescription = (text: string): ReactElement => {
	const lines = text.split('\n')
	return (
		<div>
			{lines.map((line, index) => (
				<React.Fragment key={index}>
					{line}
					{index !== lines.length - 1 && <br />}
				</React.Fragment>
			))}
		</div>
	)
}

const columns: TableHeaders = [
	{ valueKey: 'email', label: 'Email' },
	{
		valueKey: 'extra',
		label: 'Description',
		customBodyRender: (item: FeedbackAnswer): React.ReactNode => <div className="feedback-description">{formatDescription(item.extra)}</div>
	},
	{
		valueKey: 'optionIndex',
		label: 'Rate',
		customBodyRender: renderRating
	},
	{ valueKey: 'createdDate', label: 'Created date', customBodyRender: (item: FeedbackAnswer): React.ReactNode => formatDate(item.createdDate) }
]

export const Feedback = (): React.ReactElement => {
	const [state, setState] = useState<FeedbackState>({
		data: [],
		totalCount: 0,
		searchText: '',
		page: 0,
		rowsPerPage: 30
	})

	const { searchText, rowsPerPage, totalCount, data } = state

	useEffect(() => {
		getFeedback(1, '', rowsPerPage)
	}, [])

	const changePage = (page: number): void => {
		getFeedback(page, searchText, false, rowsPerPage)
	}

	const changeSearchText = (text: string): void => {
		setState({ ...state, searchText: text })
		getFeedback(1, text, false, rowsPerPage)
	}

	const getFeedback = useDebounce(async (pageNumber: number, searchValue: string, clearSearch = false, perPage = rowsPerPage) => {
		try {
			const responseData = await APIRequest.Feedback.getAll({
				start: pageNumber,
				length: perPage,
				searchText: clearSearch ? '' : searchValue
			})

			setState({
				...state,
				page: pageNumber,
				data: responseData.data || [],
				totalCount: responseData.totalCount || 0
			})
		} catch (err) {
			Logger.error(err)
		}
	}, 300)

	return (
		<Document title="Feedback Log" keywords="Feedback" auth={true} disableOverflow={true}>
			<Card>
				<Table
					pageSizes={[rowsPerPage]}
					onPageChange={changePage}
					totalCount={totalCount}
					onTableSearchChange={changeSearchText}
					title={'Feedback Log'}
					headers={columns}
					items={data}
					initialSortHeaderKey="createdDate"
					isCustomSearchRender={true}
				/>
			</Card>
		</Document>
	)
}
