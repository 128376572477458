import { BaseCall } from '../BaseCall'
import { AxiosResponse } from 'axios'
import { APIConfig } from '../interfaces'

export class Convert extends BaseCall {
	public async convertSharedFile(channelId: string, shareToken: string, type: string, size = '1024'): Promise<boolean> {
		try {
			const data = new FormData()
			data.append('shareToken', shareToken)
			data.append('channelId', channelId)
			data.append('size', size)

			const response = await this.client.post(`/convert/${type}/shared`, data)

			if (response.status !== 200) {
				console.error(response.statusText)
				return false
			}

			return true
		} catch (e) {
			console.error(e)
			return false
		}
	}

	public async convertFile(channelId: string, type: string, file: File, lynxVersion = '6'): Promise<boolean> {
		try {
			const data = new FormData()
			data.append('file', file)
			data.append('channelId', channelId)
			data.append('lynxVersion', lynxVersion)

			const response = await this.client.post(`/convert/${type}/true`, data)

			if (response.status !== 200) {
				console.error(response.statusText)
				return false
			}

			return true
		} catch (e) {
			console.error(e)
			return false
		}
	}

	public async convertFileByfileId(channelId: string, type: string, fileId: string, provider: string, lynxVersion = '6', size = '500x500'): Promise<boolean> {
		try {
			const data = new FormData()
			data.append('fileId', fileId)
			data.append('provider', provider)
			data.append('channelId', channelId)
			data.append('lynxVersion', lynxVersion)
			data.append('size', size)

			const response = await this.client.post(`/convert/${type}/true`, data)

			if (response.status !== 200) {
				console.error(response.statusText)
				return false
			}

			return true
		} catch (e) {
			console.error(e)
			return false
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}