export const SHARED_DOCUMENT_LOAD = '@@document/SHARED_DOCUMENT_LOAD'
export const DOCUMENT_LOADED = '@@document/DOCUMENT_LOADED'
export const DOCUMENT_LOAD = '@@document/DOCUMENT_LOAD'
export const DOCUMENT_REQUEST = '@@document/DOCUMENT_REQUEST'
export const CHANGE_PAGE = '@@document/CHANGE_PAGE'
export const REDUX_WEBSOCKET_MESSAGE = 'REDUX_WEBSOCKET::MESSAGE' // 'REDUX_WEBSOCKET::MESSAGE'//`${WEBSOCKET_PREFIX}::${WEBSOCKET_MESSAGE}`
export const MESSAGE_RECEIVED = '@@document/MESSAGE_RECEIVED'
export const DOCUMENT_CLEAR = '@@document/DOCUMENT_CLEAR'
export const ADD_DOWNLOAD = '@@document/ADD_DOWNLOAD'
export const CLEAR_DOWNLOAD = '@@document/CLEAR_DOWNLOAD'
export const DOCUMENT_AVAILABLE = '@@document/DOCUMENT_AVAILABLE'

export interface DocumentState {
	readonly extension: string
	readonly blobLink: string
	readonly page: number
	readonly numPages: number
	readonly fileId: string
	readonly channelId: string
	readonly play: boolean
	readonly token: string
	readonly download: string
	readonly ready: boolean
	readonly autoDownload: boolean
}

export interface RequestDocument {
	fileId: string
	resolution?: string
	download: boolean
	conversionType: string
	shareToken: string
}

interface ChangePageAction {
	type: typeof CHANGE_PAGE
	payload: number
}

interface SharedDocumentLoadAction {
	type: typeof SHARED_DOCUMENT_LOAD
	payload: {
		token: string
		extension: string
		blobLink: string
	}
}

interface AddDownloadAction {
	type: typeof ADD_DOWNLOAD
	payload: string
}

interface ClearDownloadAction {
	type: typeof CLEAR_DOWNLOAD
}

interface DocumentLoadAction {
	type: typeof DOCUMENT_LOAD
	payload: {
		fileId: string
		extension: string
		blobLink: string
	}
}

interface DocumentRequestAction {
	type: typeof DOCUMENT_REQUEST
	payload: {
		channelId: string
		fileId: string
		autoDownload: boolean
		shareToken: string
	}
}

interface DocumentAvailableAction {
	type: typeof DOCUMENT_AVAILABLE
	payload: {
		fileId: string
	}
}

interface DocumentLoadedAction {
	type: typeof DOCUMENT_LOADED
	payload: {
		fileId: string
		numPages: number
	}
}

interface DocumentClearAction {
	type: typeof DOCUMENT_CLEAR
}

interface ReceivedMessageAction {
	type: typeof REDUX_WEBSOCKET_MESSAGE
	payload: {
		message: string
	}
}

export type DocumentActionTypes =
	| DocumentAvailableAction
	| ClearDownloadAction
	| AddDownloadAction
	| SharedDocumentLoadAction
	| DocumentLoadAction
	| DocumentLoadedAction
	| ChangePageAction
	| ReceivedMessageAction
	| DocumentRequestAction
	| DocumentClearAction
