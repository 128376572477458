import { Activities } from './Activities'
import { BaseCall } from './BaseCall'
import { Channels } from './Channels'
import { ChannelsAdmin } from './ChannelsAdmin'
import { CodeGen } from './CodeGen'
import { Countries } from './Countries'
import { Groups } from './Groups'
import { APIConfig, AdditionalHeaders } from './interfaces'
import { LynxVnc } from './LynxVnc'
import { Logs } from './Logs'
import { ObjectStore } from './ObjectStore'
import { Organisations } from './Organisations'
import { Pages } from './Pages'
import { Profanity } from './Profanity'
import { Reports } from './Reports'
import { Search } from './Search'
import { User } from './User'
import { UserSessions } from './UserSessions'
import { Translator } from './Translator'
import { Translation } from './Translation'
import { Convert } from './Convert'
import { Share } from './Share'
import { Zoom } from './Zoom'
import { Feedback } from './Feedback'
import { DisplayName } from './DisplayName'
import { Newsletter } from './Newsletter'
import { Sharing } from './Sharing'
import { Url } from './Url/Url'
import { Notifications } from './Notifications'
import { OpenTDB } from './OpenTDB'
import { Sites } from './Sites'
import { Ai } from './Ai'
import { Favourites } from './Favourites'
import { Billing } from './Billing/Billing'
import axios from 'axios'

class Requests {
	protected activities: Activities = new Activities()
	protected ai: Ai = new Ai()
	protected basecall: BaseCall = new BaseCall()
	protected billing: Billing = new Billing()
	protected channels: Channels = new Channels()
	protected channelsadmin: ChannelsAdmin = new ChannelsAdmin()
	protected codegen: CodeGen = new CodeGen()
	protected convert: Convert = new Convert()
	protected countries: Countries = new Countries()
	protected displayName: DisplayName = new DisplayName()
	protected feedback: Feedback = new Feedback()
	protected groups: Groups = new Groups()
	protected logs: Logs = new Logs()
	protected lynxVnc: LynxVnc = new LynxVnc()
	protected newsletter: Newsletter = new Newsletter()
	protected notifications: Notifications = new Notifications()
	protected objectstore: ObjectStore = new ObjectStore()
	protected openTDB: OpenTDB = new OpenTDB()
	protected organisation: Organisations = new Organisations()
	protected pages: Pages = new Pages()
	protected profanity: Profanity = new Profanity()
	protected reports: Reports = new Reports()
	protected search: Search = new Search()
	protected share: Share = new Share()
	protected sharing: Sharing = new Sharing()
	protected sites: Sites = new Sites()
	protected translation: Translation = new Translation()
	protected translator: Translator = new Translator()
	protected url: Url = new Url()
	protected user: User = new User()
	protected userSessions: UserSessions = new UserSessions()
	protected zoom: Zoom = new Zoom()
	protected favourites: Favourites = new Favourites()

	public setAuthorization(token: string): void {
		this.activities.setAuthorization(token)
		this.ai.setAuthorization(token)
		this.billing.setAuthorization(token)
		this.channels.setAuthorization(token)
		this.channelsadmin.setAuthorization(token)
		this.codegen.setAuthorization(token)
		this.convert.setAuthorization(token)
		this.countries.setAuthorization(token)
		this.displayName.setAuthorization(token)
		this.feedback.setAuthorization(token)
		this.groups.setAuthorization(token)
		this.logs.setAuthorization(token)
		this.lynxVnc.setAuthorization(token)
		this.newsletter.setAuthorization(token)
		this.notifications.setAuthorization(token)
		this.objectstore.setAuthorization(token)
		this.organisation.setAuthorization(token)
		this.pages.setAuthorization(token)
		this.profanity.setAuthorization(token)
		this.reports.setAuthorization(token)
		this.search.setAuthorization(token)
		this.share.setAuthorization(token)
		this.sharing.setAuthorization(token)
		this.sites.setAuthorization(token)
		this.translation.setAuthorization(token)
		this.translator.setAuthorization(token)
		this.url.setAuthorization(token)
		this.user.setAuthorization(token)
		this.UserSessions.setAuthorization(token)
		this.zoom.setAuthorization(token)
		this.favourites.setAuthorization(token)
	}

	public setConfig(config: APIConfig): void {
		this.activities.setConfig(config)
		this.ai.setConfig(config)
		this.billing.setConfig(config)
		this.channels.setConfig(config)
		this.channelsadmin.setConfig(config)
		this.codegen.setConfig(config)
		this.convert.setConfig(config)
		this.countries.setConfig(config)
		this.displayName.setConfig(config)
		this.feedback.setConfig(config)
		this.groups.setConfig(config)
		this.logs.setConfig(config)
		this.lynxVnc.setConfig(config)
		this.newsletter.setConfig(config)
		this.notifications.setConfig(config)
		this.objectstore.setConfig(config)
		this.organisation.setConfig(config)
		this.pages.setConfig(config)
		this.profanity.setConfig(config)
		this.reports.setConfig(config)
		this.search.setConfig(config)
		this.share.setConfig(config)
		this.sharing.setConfig(config)
		this.sites.setConfig(config)
		this.translation.setConfig(config)
		this.translator.setConfig(config)
		this.url.setConfig(config)
		this.user.setConfig(config)
		this.userSessions.setConfig(config)
		this.zoom.setConfig(config)
		this.favourites.setConfig(config)
	}

	public setDefaultHeaders(additionalHeaders: AdditionalHeaders): void {
		this.activities.setDefaultHeaders(additionalHeaders)
		this.ai.setDefaultHeaders(additionalHeaders)
		this.billing.setDefaultHeaders(additionalHeaders)
		this.channels.setDefaultHeaders(additionalHeaders)
		this.channelsadmin.setDefaultHeaders(additionalHeaders)
		this.codegen.setDefaultHeaders(additionalHeaders)
		this.convert.setDefaultHeaders(additionalHeaders)
		this.countries.setDefaultHeaders(additionalHeaders)
		this.displayName.setDefaultHeaders(additionalHeaders)
		this.feedback.setDefaultHeaders(additionalHeaders)
		this.groups.setDefaultHeaders(additionalHeaders)
		this.logs.setDefaultHeaders(additionalHeaders)
		this.lynxVnc.setDefaultHeaders(additionalHeaders)
		this.newsletter.setDefaultHeaders(additionalHeaders)
		this.notifications.setDefaultHeaders(additionalHeaders)
		this.objectstore.setDefaultHeaders(additionalHeaders)
		this.organisation.setDefaultHeaders(additionalHeaders)
		this.pages.setDefaultHeaders(additionalHeaders)
		this.profanity.setDefaultHeaders(additionalHeaders)
		this.reports.setDefaultHeaders(additionalHeaders)
		this.search.setDefaultHeaders(additionalHeaders)
		this.share.setDefaultHeaders(additionalHeaders)
		this.sharing.setDefaultHeaders(additionalHeaders)
		this.sites.setDefaultHeaders(additionalHeaders)
		this.translation.setDefaultHeaders(additionalHeaders)
		this.translator.setDefaultHeaders(additionalHeaders)
		this.url.setDefaultHeaders(additionalHeaders)
		this.user.setDefaultHeaders(additionalHeaders)
		this.userSessions.setDefaultHeaders(additionalHeaders)
		this.zoom.setDefaultHeaders(additionalHeaders)
		this.favourites.setDefaultHeaders(additionalHeaders)
	}

	public setRequestInterceptor(...interceptor: Parameters<typeof axios.interceptors.response.use>): void {
		this.activities.setRequestInterceptor(...interceptor)
		this.ai.setRequestInterceptor(...interceptor)
		this.billing.setRequestInterceptor(...interceptor)
		this.channels.setRequestInterceptor(...interceptor)
		this.channelsadmin.setRequestInterceptor(...interceptor)
		this.codegen.setRequestInterceptor(...interceptor)
		this.convert.setRequestInterceptor(...interceptor)
		this.countries.setRequestInterceptor(...interceptor)
		this.displayName.setRequestInterceptor(...interceptor)
		this.feedback.setRequestInterceptor(...interceptor)
		this.groups.setRequestInterceptor(...interceptor)
		this.logs.setRequestInterceptor(...interceptor)
		this.lynxVnc.setRequestInterceptor(...interceptor)
		this.newsletter.setRequestInterceptor(...interceptor)
		this.notifications.setRequestInterceptor(...interceptor)
		this.objectstore.setRequestInterceptor(...interceptor)
		this.organisation.setRequestInterceptor(...interceptor)
		this.pages.setRequestInterceptor(...interceptor)
		this.profanity.setRequestInterceptor(...interceptor)
		this.reports.setRequestInterceptor(...interceptor)
		this.search.setRequestInterceptor(...interceptor)
		this.share.setRequestInterceptor(...interceptor)
		this.sharing.setRequestInterceptor(...interceptor)
		this.sites.setRequestInterceptor(...interceptor)
		this.translation.setRequestInterceptor(...interceptor)
		this.translator.setRequestInterceptor(...interceptor)
		this.url.setRequestInterceptor(...interceptor)
		this.user.setRequestInterceptor(...interceptor)
		this.userSessions.setRequestInterceptor(...interceptor)
		this.zoom.setRequestInterceptor(...interceptor)
		this.favourites.setRequestInterceptor(...interceptor)
	}

	get Activities(): Activities {
		return this.activities
	}

	get Billing(): Billing {
		return this.billing
	}

	get Convert(): Convert {
		return this.convert
	}

	get LynxVnc(): LynxVnc {
		return this.lynxVnc
	}

	get Countries(): Countries {
		return this.countries
	}

	get Sharing(): Sharing {
		return this.sharing
	}

	get Url(): Url {
		return this.url
	}

	get User(): User {
		return this.user
	}

	get Favourites(): Favourites {
		return this.favourites
	}

	get Feedback(): Feedback {
		return this.feedback
	}

	get Sites(): Sites {
		return this.sites
	}

	get Groups(): Groups {
		return this.groups
	}

	get Logs(): Logs {
		return this.logs
	}

	get Profanity(): Profanity {
		return this.profanity
	}

	get CodeGen(): CodeGen {
		return this.codegen
	}

	get ObjectStore(): ObjectStore {
		return this.objectstore
	}

	get Organisations(): Organisations {
		return this.organisation
	}

	get Pages(): Pages {
		return this.pages
	}

	get Reports(): Reports {
		return this.reports
	}

	get Search(): Search {
		return this.search
	}

	get Share(): Share {
		return this.share
	}

	get Channels(): Channels {
		return this.channels
	}

	get ChannelsAdmin(): ChannelsAdmin {
		return this.channelsadmin
	}

	get Translator(): Translator {
		return this.translator
	}

	get Zoom(): Zoom {
		return this.zoom
	}

	get DisplayName(): DisplayName {
		return this.displayName
	}

	get Newsletter(): Newsletter {
		return this.newsletter
	}

	get Notifications(): Notifications {
		return this.notifications
	}

	get OpenTDB(): OpenTDB {
		return this.openTDB
	}

	get Ai(): Ai {
		return this.ai
	}

	get Translation(): Translation {
		return this.translation
	}
	get UserSessions(): UserSessions {
		return this.userSessions
	}
}

export const APIRequest = new Requests()
APIRequest.setDefaultHeaders({})
