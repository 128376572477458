import { APIRequest } from '@lynx/client-core/src/api'
import { DriveItemType } from '@lynx/client-core/src/api/interfaces'
import { ArrowBackIcon, BasicButton, FolderIcon, Modal } from '@lynx/client-core/src/components'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { Logger } from '@lynx/client-core/src/modules'
import { useThunkDispatch } from 'hooks'
import i18next from 'i18next'
import QueryString from 'qs'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { refreshDrivePath } from 'store/drive'
import { hideModal, ModalContext, MoveModalContextType } from 'store/modal'
import Styles from './MoveModal.module.css'

interface MoveModalState {
	item: DriveItemType | undefined
	currentFolder: DriveItemType | undefined
	folders: DriveItemType[]
	folderName: string
	previousFolder: DriveItemType | undefined
	path: DriveItemType[]
}

export const MoveModal = (): React.ReactElement => {
	const [state, setState] = useState<MoveModalState>({
		item: undefined,
		currentFolder: undefined,
		folders: [],
		folderName: 'root',
		previousFolder: undefined,
		path: []
	})
	const dispatchThunk = useThunkDispatch()
	const dispatch = useDispatch()
	const { drive } = useSelector((state: ApplicationState) => state)

	const handleSubmit = async (): Promise<void> => {
		const { item, currentFolder } = state

		if (!item) {
			return
		}

		const query = QueryString.parse(location.search.substring(1)) as { driveId?: string }
		try {
			await APIRequest.ObjectStore.move(item, item.name, currentFolder && currentFolder.id, query.driveId)
			await dispatchThunk(refreshDrivePath())

			dispatch(hideModal())
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleVisible = async (context?: ModalContext): Promise<void> => {
		if (context?.type !== MoveModalContextType) {
			return
		}

		const { driveItem } = context

		try {
			const query = QueryString.parse(location.search.substring(1)) as { driveId?: string }

			const list = await APIRequest.ObjectStore.list(drive.provider, '', { providerContainerId: query.driveId, excludeSharedFolder: true })

			if (list.data.children) {
				const folders = list.data.children.filter((item: DriveItemType) => item.mimeType === 'application/folder')

				setState((prevState) => ({
					...prevState,
					item: driveItem,
					currentFolder: undefined,
					folders,
					folderName: 'root'
				}))
			}
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleFolderClicked = async (folder: DriveItemType): Promise<void> => {
		try {
			await changeFolder(true, folder)
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleBackClicked = async (): Promise<void> => {
		await changeFolder(false, state.path[state.path.length - 2])
	}

	const changeFolder = async (pushed: boolean, folder?: DriveItemType): Promise<void> => {
		const query = QueryString.parse(location.search.substring(1)) as { driveId?: string }
		try {
			let list
			if (!folder) {
				list = await APIRequest.ObjectStore.list(drive.provider, '', { providerContainerId: query.driveId })
			} else {
				list = await APIRequest.ObjectStore.list(drive.provider, folder.id, { providerContainerId: query.driveId })
			}

			if (list.data.children) {
				const folders = list.data.children.filter((item: DriveItemType) => {
					return item.mimeType === 'application/folder' && item.id !== 'sharedwithme'
				})

				let newPath: DriveItemType[] = []
				if (pushed && folder) {
					newPath = [...state.path]
					newPath.push(folder)
				} else {
					newPath = [...state.path]
					newPath.pop()
				}

				setState((prevState) => ({
					...prevState,
					currentFolder: folder,
					folders,
					folderName: folder ? folder.name : 'root',
					previousFolder: state.currentFolder,
					path: newPath
				}))
			}
		} catch (e) {
			Logger.error(e)
		}
	}

	const folders = state.folders.map((item: DriveItemType, index: number) => {
		return (
			<div key={index} className={Styles.item} onClick={(): Promise<void> => handleFolderClicked(item)}>
				<FolderIcon />
				<span>{item.name}</span>
			</div>
		)
	})

	const { folderName } = state

	return (
		<Modal name="MoveModal" onVisible={handleVisible} hideCloseButton>
			<ModalDefaultLayout
				title={i18next.t('components.modals.moveModal.title')}
				body={
					<div>
						<div className={Styles['item']}>
							{folderName !== 'root' && <ArrowBackIcon onClick={handleBackClicked} />}
							<span>{folderName}</span>
						</div>
						<div>{folders}</div>
					</div>
				}
				footer={
					<React.Fragment>
						<BasicButton
							onClick={(): void => {
								dispatch(hideModal())
							}}
							variant="transparent"
						>
							{i18next.t('pages.drive.modals.deleteModal.cancel')}
						</BasicButton>
						<BasicButton onClick={handleSubmit} variant="blue">
							{i18next.t('components.modals.informationModal.move')}
						</BasicButton>
					</React.Fragment>
				}
			/>
		</Modal>
	)
}
