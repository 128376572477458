import { APIRequest } from '@lynx/client-core/src/api'
import { Logger } from '@lynx/client-core/src/modules'
import { DriveSize } from '@lynx/core/src/interfaces/ObjectStore'
import { useEffect, useState } from 'react'

export const useOrganisationDriveSize = (driveId: string | undefined): { driveSize: DriveSize | null; isLoading: boolean } => {
	const [driveSize, setDriveSize] = useState<DriveSize | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		if (!driveId) {
			setIsLoading(false)
			setDriveSize(null)
			return
		}

		const mount = async (): Promise<void> => {
			try {
				const data = await APIRequest.Organisations.getDriveSize(driveId)
				setDriveSize(data)
				setIsLoading(false)
			} catch (error) {
				Logger.log(error)
			}
		}
		mount()
	}, [driveId])

	return { driveSize, isLoading }
}
