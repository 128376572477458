import { APIRequest } from '@lynx/client-core/src/api'
import {
	CancelFilledIcon,
	CheckCircleFilledIcon,
	DoNotDisturbFilledIcon,
	Document,
	InvoiceIcon,
	OrganisationsIcon,
	ProBadgeIcon,
	SendIcon,
	Table,
	UserPro
} from '@lynx/client-core/src/components'
import { OrganisationAuditPaymentActionSearch } from '@lynx/core/src/interfaces/Organisation'
import { useDataWithPaginationAndSearch } from 'hooks/useDataWithPaginationAndSearch'
import css from './PaymentAuditTrail.module.scss'
import { providersData } from '@lynx/core/src/constants'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { getStripeDashboardPath } from '@lynx/core'

export const PaymentAuditTrail = (): React.ReactElement => {
	const config = useSelector((state: ApplicationState) => state.config)
	const fetchData = async ({
		start,
		length,
		searchText
	}: {
		start: number
		length: number
		searchText: string
	}): Promise<{ data: OrganisationAuditPaymentActionSearch[]; totalCount: number }> => {
		const res = await APIRequest.Organisations.getOrganisationAuditTrail({ start, length, searchText })
		if (!res) {
			return { data: [], totalCount: 0 }
		}
		return { data: res.audits, totalCount: res.totalCount }
	}

	const getAuditActionElement = (item: OrganisationAuditPaymentActionSearch): React.ReactNode => {
		switch (item.auditActionMessage) {
			case 'sendInvoice':
				return (
					<>
						<SendIcon className={css.icon} /> Sent invoice
					</>
				)
			case 'markAsPaid':
				return (
					<>
						<CheckCircleFilledIcon className={`${css.markAsPaidIcon} ${css.icon}`} /> Marked as paid
					</>
				)
			case 'markAsVoid':
				return (
					<>
						<CancelFilledIcon className={css.icon} /> Marked as void
					</>
				)
			case 'markAsUncollectible':
				return (
					<>
						<DoNotDisturbFilledIcon className={css.icon} /> Marked as uncollectible
					</>
				)
			case 'createSubscription':
				return (
					<>
						<ProBadgeIcon className={css.icon} /> Created subscription
					</>
				)
			case 'changeStorageLimit':
				return (
					<>
						<img className={css.icon} src={providersData.lynxcloud.getIconUrl(config.LYNXCLOUD_CLIENT)} />
						Storage limit from {item.auditData.from} GB to {item.auditData.to} GB
					</>
				)
			case 'changeUserLicensesLimit':
				return (
					<>
						<UserPro className={css.icon} />
						User limit from {item.auditData.from} to {item.auditData.to}
					</>
				)
			case 'changeUserLicensesLimitAdmin':
				return (
					<>
						<OrganisationsIcon className={css.icon} />
						ADMIN User limit from {item.auditData.from} to {item.auditData.to} (Stripe: {item.auditData.stripeQuantity})
					</>
				)
			default:
				return 'Unknown'
		}
	}

	const {
		state: { data, totalCount, rowsPerPage, page },
		changeSearchText,
		changePage
	} = useDataWithPaginationAndSearch<OrganisationAuditPaymentActionSearch>({ fetchData, initialPageSize: 30 })
	const columns = [
		{
			valueKey: 'organisationId',
			label: 'Organisation',
			customBodyRender: (item: OrganisationAuditPaymentActionSearch): React.ReactNode => (
				<div>
					<div>{item.organisationName}</div>
					<div>ID: {item.organisationId}</div>
				</div>
			)
		},
		{
			valueKey: 'invoiceId',
			label: 'Invoice',
			customBodyRender: (item: OrganisationAuditPaymentActionSearch): React.ReactNode => {
				if (!item.invoiceId.trim()) return <div />

				const invoiceUrl = `${getStripeDashboardPath(config.ENVIRONMENT === 'production')}/invoices/${item.invoiceId}`
				return (
					<div>
						<a href={invoiceUrl} target="_blank" onClick={(): Window | null => window.open(invoiceUrl, '_blank')} rel="noreferrer">
							<InvoiceIcon />
						</a>
					</div>
				)
			}
		},
		{
			valueKey: 'auditAction',
			label: 'Action',
			customBodyRender: (item: OrganisationAuditPaymentActionSearch): React.ReactNode => (
				<div className={css.auditActionContainer} title={item.auditActionMessage}>
					{getAuditActionElement(item)}
				</div>
			)
		},
		{
			valueKey: 'userDisplayName',
			label: 'Who'
		},
		{
			valueKey: 'createdDate',
			label: 'Date',
			customBodyRender: (item: OrganisationAuditPaymentActionSearch): React.ReactNode => (
				<div>
					{new Date(item.createdDate).toLocaleDateString('en-GB', {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric'
					})}
				</div>
			)
		}
	]

	return (
		<Document title="Payment Audit Trail" keywords="payment audit trail" auth={true} disableOverflow={true}>
			<div className={css.container}>
				<Table
					pageSizes={[rowsPerPage]}
					onPageChange={changePage}
					totalCount={totalCount}
					onTableSearchChange={changeSearchText}
					title={'Payment Audit Trail'}
					headers={columns}
					items={data}
					isCustomSearchRender={true}
				/>
			</div>
		</Document>
	)
}
