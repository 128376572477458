import i18next from 'i18next'
import React from 'react'
import ReactToPrint from 'react-to-print'
import { Dropdown, SearchContainer } from '..'
import { DownloadIcon, MoreVertIcon, PrintIcon } from '../Icons'
import css from './TableToolbar.module.scss'
import { TableToolbarProps } from './interfaces'

export const TableToolbar = ({
	setTableSearchValue,
	tableSearchValue,
	onDownloadCsv,
	tableRef,
	toolBarItems = [],
	showSearch
}: TableToolbarProps): React.ReactElement => {
	const dropdownItems = [
		{
			component: (
				<div onClick={onDownloadCsv}>
					<DownloadIcon />
					<div>{i18next.t('components.table.downloadCsv')}</div>
				</div>
			)
		},
		{
			component: (
				<ReactToPrint
					trigger={(): React.ReactElement => (
						<div>
							<PrintIcon />
							<div>{i18next.t('components.table.print')}</div>
						</div>
					)}
					content={(): HTMLDivElement | null => tableRef && tableRef.current}
				/>
			)
		},
		...toolBarItems
	]

	return (
		<div className={css.toolbarContainer}>
			{showSearch && (
				<SearchContainer
					handleSearchChange={(value: string): void => {
						setTableSearchValue(value)
					}}
					searchText={tableSearchValue}
				/>
			)}
			<Dropdown title={<MoreVertIcon />} className={css['dropdown']}>
				{dropdownItems.map(({ component }, i) => (
					<li key={`${i}dropdownItem`}>{component}</li>
				))}
			</Dropdown>
		</div>
	)
}
