import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

export const RegReportByVersion = (): React.ReactElement => {
	const navigate = useNavigate()
	const [reportVersions, setReportVersions] = useState([])
	const columns = ['VersionCombined', 'UsageCount']

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const data = await APIRequest.Reports.getRegReportByVersion()
			setReportVersions(data.reportVersions)
		}
		mount()
	}, [])

	const goToDetails = (data: any): void => {
		navigate('/reports/reg-report-detail-by-version/' + data.VersionCombined)
	}

	return (
		<Document
			title="Registration Report - By Version"
			description="Registration Report - By Version"
			keywords="Registration Report - By Version"
			auth={true}
			disableOverflow={true}
		>
			<Card>
				<Table
					onRowClick={goToDetails}
					title={'Reg Report By Version'}
					headers={columns.map((v) => ({ label: v, valueKey: v }))}
					items={reportVersions}
				/>
			</Card>
		</Document>
	)
}
