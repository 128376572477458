import { Alert, BasicButton, FilePicker, Modal } from '@lynx/client-core/src/components'
import { FileDropper } from '@lynx/client-core/src/components/FileDropper'
import { showModal } from '@lynx/client-core/src/store/modal'
import { loadFromFile } from 'components/ReactPDFts/shared/utils'
import { ConfirmAccountCreationModalContextType } from 'pages/Organisations/modals/ConfirmAccountCreationModal/ConfirmAccountCreationModalContextType'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import css from './BulkImportPeopleModal.module.scss'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'

export const BulkImportPeopleModal = (): React.ReactElement => {
	const [file, setFile] = useState<File | null>(null)
	const organisation = useCurrentOrganisation()
	const [fileData, setFileData] = useState<{ email: string }[] | null>([])
	const [errorMessage, setErrorMessage] = useState('')
	const [isDragging, isSetDragging] = useState(false)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		const loadAndValidateFile = async (): Promise<void> => {
			const formatData = (dataString: string): { email: string }[] => {
				try {
					// Filter out empty rows and those that don't have a correct domain
					if (!organisation) throw new Error('Unknown Error')

					const dataRows = dataString
						.split(/\r\n|\n/)
						.filter((v) => v)
						.filter((row) => row.includes('@'))
						.filter((row) => organisation.domains.some((d) => d.emailDomain.includes(row.split('@')[1])))
					const formattedRows = dataRows.map((row) => {
						const [email] = row.split(',')
						if (!email) throw new Error()
						return { email: email.trim() }
					})

					if (!formattedRows.length) throw new Error()

					return formattedRows
				} catch (err) {
					throw new Error(t('pages.organisations.invalidCsv'))
				}
			}

			try {
				const reader = await loadFromFile(file)
				const stringFileData = new TextDecoder().decode(reader)
				const formattedData = formatData(stringFileData)

				setFileData(formattedData)
			} catch (err) {
				if (err instanceof Error) setErrorMessage(err.message)
			}
		}

		setErrorMessage('')
		setFileData(null)
		file && loadAndValidateFile()
	}, [file])

	const onFileUploaded = (file: File): void => {
		setFile(file)
	}

	const onDropped = (files: FileList): void => {
		setFile(files[0])
	}

	const handleDownloadCSVClicked = (): void => {
		const example = t('pages.organisation.bulkCsv.example')

		const csvFileData = [[`${example}1@${example}.com`], [`${example}2@${example}.com`]]

		const csv = csvFileData.reduce((acc, nextRow) => `${acc}${nextRow.join(',')}\n`, '')

		const hiddenElement = document.createElement('a')
		hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
		hiddenElement.target = '_blank'
		hiddenElement.download = `${t('pages.organisation.bulkCsv.template')}.csv`
		hiddenElement.click()
	}

	const handleUploadClicked = (): void => {
		if (fileData?.length) {
			dispatch(showModal({ name: 'ConfirmAccountCreationModal', context: { type: ConfirmAccountCreationModalContextType, fileData } }))
		}
	}

	const handleModalHidden = (): void => {
		setFile(null)
		setErrorMessage('')
	}

	const handleModalVisible = (): void => {
		if (organisation?.domains.length === 0) {
			setErrorMessage(t('pages.organisations.noDomains'))
		}
	}

	const fileDisplay = (
		<div className={css['text']}>
			{file ? (
				file.name
			) : (
				<div>
					{' '}
					{t('pages.organisation.bulkCsv.dragCsv')} <span className={css['highlight']}> {t('pages.organisation.bulkCsv.selectFile')}</span>
				</div>
			)}
		</div>
	)

	return (
		<Modal name="BulkImportPeopleModal" title="Bulk Import People" onVisible={handleModalVisible} onHidden={handleModalHidden}>
			<div className={css['container']}>
				<FilePicker onChange={onFileUploaded}>
					<div className={css['file-container']}>
						<div
							className={`${css['file-selector']} ${file ? 'active' : ''}`}
							onDragEnter={(): void => {
								isSetDragging(true)
							}}
						>
							{fileDisplay}
							<FileDropper
								onDropped={onDropped}
								active={isDragging}
								onEnded={(): void => {
									isSetDragging(false)
								}}
							></FileDropper>
						</div>
					</div>
				</FilePicker>
				<div>{t('pages.organisation.bulkCsv.onlyEmailsMatching')}</div>
				<div className={css['alert']}>{errorMessage && <Alert styleClass="danger">{errorMessage}</Alert>}</div>
			</div>
			<div className={css['buttons']}>
				<BasicButton onClick={handleDownloadCSVClicked}>{t('pages.organisations.downloadTemplate')}</BasicButton>
				<BasicButton variant="blue" disabled={!fileData?.length || organisation?.domains.length == 0} onClick={handleUploadClicked}>
					{t('pages.organisation.bulkCsv.upload')}
				</BasicButton>
			</div>
		</Modal>
	)
}
