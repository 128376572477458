import React from 'react'
import { LynxBannerLight, LynxBanner } from '../Icons'
import css from './FooterItem.module.scss'

export const FooterItem = ({ variant = 'light' }: { variant?: 'light' | 'dark' }): React.ReactElement => (
	<div className={[css['container'], variant === 'dark' ? css['container--dark'] : css['container--light']].join(' ')}>
		<div>
			{variant === 'dark' ? <LynxBanner /> : <LynxBannerLight />}
			<a>&copy; Clevertouch {new Date().getFullYear()}. All rights reserved.</a>
		</div>
	</div>
)
