import { PhotoCameraFilledWhiteIcon } from '@lynx/client-core/src/components'
import React, { useEffect } from 'react'
import css from './AccountDetailsProfilePicture.module.scss'
import { useDispatch } from 'react-redux'
import { showModal } from '@lynx/client-core/src/store/modal'
import { ProfilePictureModalType } from 'store/modal'
import { useProfileIcon } from '@lynx/client-core/src/hooks/useProfileIcon'
import { deleteQueryParam } from '@lynx/client-core/src/utils'

export const AccountDetailsProfilePicture = (): React.ReactElement => {
	const { icon } = useProfileIcon()
	const dispatch = useDispatch()
	const url = window.location.search
	useEffect(() => {
		const isUrlQuery = url.includes('modal=profile-picture')
		if (isUrlQuery) {
			dispatch(showModal({ name: ProfilePictureModalType }))
			deleteQueryParam('modal')
		}
	}, [url])

	const handleOpenGravatarClicked = (): void => {
		dispatch(showModal({ name: ProfilePictureModalType }))
	}

	return (
		<div className={css['container']} onClick={handleOpenGravatarClicked}>
			{icon}
			<PhotoCameraFilledWhiteIcon className={css.photoCameraIcon} />
		</div>
	)
}
