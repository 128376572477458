import { APIRequest } from '@lynx/client-core/src/api'
import { Document, ImageSearchIcon, SearchIcon, TextField } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import { ConfigState } from 'store/config'
import { ProfileState } from 'store/profile'
import { ActivitiesGraphic } from './components/ActivitiesGraphic/ActivitiesGraphic'
import { SearchProps, SearchState } from './interfaces'
import css from './Search.module.css'

class Search extends Component<SearchProps, SearchState> {
	private editor: any = undefined // eslint-disable-line
	private timer: NodeJS.Timeout | undefined

	state = {
		search: '',
		suggestions: [],
		drawDeleteDisplay: false
	}

	private searchGoogle = (): void => {
		const value = this.state.search
		window.location.href = `https://www.google.com/search?q=${value}`
	}

	private searchGoogleImages = (): void => {
		const value = this.state.search
		window.location.href = `https://www.google.com/search?q=${value}&tbm=isch`
	}

	private handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		const targetValue = event.target.value
		if (targetValue && targetValue.length > 2) {
			if (this.timer) {
				clearTimeout(this.timer)
			}

			this.timer = setTimeout(async () => {
				const related = await APIRequest.Search.getRelatedKeywords(targetValue)
				this.setState({
					suggestions: related.related
				})
			}, 1000)
		}

		this.setState({
			search: targetValue
		})
	}

	render(): React.ReactElement {
		return (
			<Document title="LYNX Web Search" description="LYNX Web Search" keywords="LYNX Web Search">
				<div className={css['container']}>
					<div className={css['container-search']}>
						<div className={css['search']}>
							<TextField
								autoFocus={true}
								name="search"
								onChange={this.handleChange}
								placeholder=""
								className={`${css['textfield']} ${css['textfield-button']}`}
								adornment={
									<div className={css['search-adornment']}>
										<div onClick={this.searchGoogleImages}>
											<ImageSearchIcon />
										</div>
										<div onClick={this.searchGoogle}>
											<SearchIcon />
										</div>
									</div>
								}
								justifyAdornment="end"
								value={this.state.search}
							/>

							<div className={css['powered-by-container']}>
								<img alt="powered by" src={'/assets/icons/powered_by_google_on_non_white_hdpi.png'} />
							</div>

							<div className={css['activities-card']}>
								<ActivitiesGraphic />
								<div className={css['activities-text']}>
									<div>{i18next.t('pages.search.lynxGames')}</div>
									<div>{i18next.t('pages.search.gamesAndActivities')}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Document>
		)
	}
}

const mapStateToProps = (state: ApplicationState): { config: ConfigState; profile: ProfileState } => ({
	config: state.config,
	profile: state.profile
})

export default connect(mapStateToProps)(Search)
