import { APIRequest } from '@lynx/client-core/src/api'
import { Alert, BasicButton, LoadingSpinner, LynxWhiteboardLogo, Modal } from '@lynx/client-core/src/components'
import { usePopupBeforeExit } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { sleepAsync } from '@lynx/core'
import { formatDateOrString, getDate } from '@lynx/core/src/date'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { WasmModalContextType } from 'store/modal'
import css from './WasmModal.module.scss'

export const WasmModal = (): React.ReactElement => {
	const { profile, config } = useSelector((state: ApplicationState) => state)
	const scriptContainerRef = useRef<HTMLDivElement>(null)
	const canvasContainerRef = useRef<HTMLDivElement>(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isRenderDom, setIsRenderDom] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string>('')
	const [qtInstance, setQtInstance] = useState<any | null>(null) // entire function is in qtLoader.js generated by Emscripten
	const isPopupExitActive = qtInstance && !isLoading && !errorMessage
	usePopupBeforeExit(isPopupExitActive)

	const onHidden = (): void => {
		window.location.href = '/' // this is on purpose to get rid of wasm process from memory (temporary work around until programmatically unloading wasm is implemented)
	}

	const filesDir = `${config.LYNXCLOUD_CLIENT}/wasm`

	const init = async (): Promise<void> => {
		setErrorMessage('')
		setIsLoading(true)

		const loadScript = (fileName: string): Promise<void> => {
			return new Promise((resolve, reject) => {
				const script = document.createElement('script')
				script.src = `${filesDir}/${fileName}`
				script.onload = (): void => resolve()
				if (scriptContainerRef.current === null) return reject()
				scriptContainerRef.current.appendChild(script)
			})
		}

		try {
			setIsRenderDom(true)
			await sleepAsync(500) // wait for dom to render
			await loadScript('LYNX.js')
			await loadScript('qtloader.js')
			await initQtLoader()
		} catch (error) {
			Logger.error('Error loading wasm', error)
		}
	}

	const initQtLoader = async (): Promise<void> => {
		const qtLoader = await qtLoad({
			locateFile: (file: string, scriptDirectory: string): string => `${filesDir}/${file}`,
			qt: {
				entryFunction: window.LYNX_entry,
				containerElements: [canvasContainerRef.current],
				showError: (errorText: string): void => {
					setIsLoading(false)
					setErrorMessage(errorText)
				},
				onExit: (res: { text: string; crashed: boolean }): void => {
					setIsLoading(false)
					setErrorMessage(res.text)
				},
				onLoaded: async (): Promise<void> => {
					setIsLoading(false)

					const { accessToken, refreshToken, expireAt, sessionId } = await APIRequest.User.createToken('whiteboard')
					const { userId } = profile
					const obj = {
						web_user: {
							accessToken: accessToken,
							expiry: formatDateOrString(getDate(expireAt), 'ddd MMM d hh:mm:ss YYYY'),
							refreshToken: refreshToken,
							sessionId,
							status: 2,
							tokensEncrypted: false,
							userId: userId
						}
					}

					const setTokenWithDelay = (): void => {
						// settimeout is used due to lack of callback when app is loaded
						// qt guys are working on it
						setTimeout(() => {
							qtLoader.setLynxSessionJSON(JSON.stringify(obj))
						}, 1000)
					}

					setTokenWithDelay()
				}
			}
		})
		setQtInstance(qtLoader)
	}

	const dragoverHandler = (ev: React.DragEvent<HTMLDivElement>): void => {
		ev.preventDefault()
		ev.dataTransfer.dropEffect = 'move'
	}

	const dropHandler = async (ev: React.DragEvent<HTMLDivElement>): Promise<void> => {
		ev.preventDefault()
		const { files } = ev.dataTransfer

		for (let i = 0; i < files.length; i++) {
			const file = files[i]

			const _data = await file.arrayBuffer()

			const contentArray = new Uint8Array(_data)
			const contentSize = _data.byteLength

			const heapPointer = qtInstance.wasmExports['malloc'](contentSize)
			const heapBytes = new Uint8Array(qtInstance.HEAPU8.buffer, heapPointer, contentSize)
			heapBytes.set(contentArray)

			qtInstance.ccall('wasmFileDataDropCallback', null, ['number', 'number', 'string'], [heapPointer, contentSize, file.name])
		}
	}

	const cleanUp = (): void => {
		scriptContainerRef.current?.remove()
	}

	useEffect(() => {
		if (profile.userId) {
			init()
			return cleanUp
		}
	}, [profile.userId])

	return (
		<Modal name={WasmModalContextType} innerContainerClasses={css.innerContainerClass} uncontrolled onHidden={onHidden}>
			{profile.loggedIn && isRenderDom && (
				<div className={css.container}>
					<div ref={scriptContainerRef} />

					{(isLoading || errorMessage) && (
						<div className={css.statusContainer}>
							<LynxWhiteboardLogo className={css.logoicon} />

							{isLoading && <LoadingSpinner className={css.spinnerIcon} />}
							{errorMessage && (
								<>
									<div className={css.errorContainer}>
										<Alert styleClass="danger">{errorMessage}</Alert>
										<div className={css.btnContainer}>
											<BasicButton className={css.retryBtn} onClick={(): void => window.location.reload()}>
												Retry
											</BasicButton>
										</div>
									</div>
								</>
							)}
						</div>
					)}
					<div ref={canvasContainerRef} onDrop={dropHandler} onDragOver={dragoverHandler} className={css.canvasContainer} />
				</div>
			)}
		</Modal>
	)
}
