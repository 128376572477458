export const driveUsagePercentMedium = 80
export const driveUsagePercentHigh = 90

export const providersData = {
	google: {
		label: 'Google Drive',
		key: 'google' as const,
		getIconUrl: (clientUrl: string): string => `${clientUrl}/assets/icons/logo-google-drive.svg`
	},
	dropbox: {
		label: 'Dropbox',
		key: 'dropbox' as const,
		getIconUrl: (clientUrl: string): string => `${clientUrl}/assets/icons/logo-dropbox-drive.svg`
	},
	onedrive: {
		label: 'OneDrive',
		key: 'onedrive' as const,
		getIconUrl: (clientUrl: string): string => `${clientUrl}/assets/icons/logo-onedrive-drive.svg`
	},
	sharepoint: {
		label: 'SharePoint',
		key: 'sharepoint' as const,
		getIconUrl: (clientUrl: string): string => `${clientUrl}/assets/icons/logo-sharepoint-drive.svg`
	},
	lynxcloud: {
		label: 'LYNX Cloud',
		key: 'lynxcloud' as const,
		getIconUrl: (clientUrl: string): string => `${clientUrl}/assets/icons/logo-lynxcloud-drive.svg`
	},
	nextcloud: {
		label: 'NextCloud',
		key: 'nextcloud' as const,
		getIconUrl: (clientUrl: string): string => `${clientUrl}/assets/icons/logo-nextcloud.svg`
	},
	organisation: {
		label: 'Organisation',
		key: 'organisation' as const,
		getIconUrl: (clientUrl: string): string => '' // custom icon per organisation
	}
}

export const disallowedProvidersMap = [
	{
		provider: providersData.google.key,
		label: providersData.google.label,
		value: 1
	},
	{
		provider: providersData.dropbox.key,
		label: providersData.dropbox.label,
		value: 2
	},
	{
		provider: providersData.onedrive.key,
		label: providersData.onedrive.label,
		value: 3
	}
]
