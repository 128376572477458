import Dexie from 'dexie'
import { Logger } from '../Logger'

export interface KVP {
	key: string
	value: string
}

class AsyncStorageDB extends Dexie {
	private storage: Dexie.Table<KVP, string>

	public get length(): Promise<number> {
		return this.storage.count()
	}

	constructor() {
		super('AsyncStorage')
		this.version(1).stores({
			AsyncStorage: 'key, value'
		})

		this.storage = this.table('AsyncStorage')
	}

	/**
	 * Clears all entries
	 */
	clear = async (): Promise<void> => {
		await this.storage.clear()
	}

	/**
	 * Gets entries value
	 * @param key
	 * @returns string (value)
	 */
	getItem = async (key: string): Promise<string | null> => {
		try {
			const entry = await this.storage.get(key)
			return entry?.value || null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	/**
	 * Removes entry by key
	 * @param key
	 */
	removeItem = async (key: string): Promise<void> => {
		return this.storage.delete(key)
	}

	/**
	 * Sets entry overwriting any existing value for key (upsert), returns key
	 * @param key
	 * @param value
	 * @returns string (key)
	 */
	setItem = async (key: string, value: string): Promise<void> => {
		try {
			await this.storage.put({ key, value })
		} catch (e) {
			Logger.error(e)
		}
	}
}

export const AsyncStorage = new AsyncStorageDB()
