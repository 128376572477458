import React from 'react'
import css from './LynxCard.module.scss'

interface LynxCardProps {
	children: React.ReactNode
	header?: React.ReactNode
	footer?: React.ReactNode
	tintColor?: string
}

export const LynxCard = ({ children, header, footer, tintColor = '#AF66FF' }: LynxCardProps): React.ReactElement => {
	return (
		<div
			className={css['lynxCard']}
			style={{
				borderTopColor: tintColor
			}}
		>
			<div className={css['cardContent']}>
				{header && <div className={css['header']}>{header}</div>}

				<div className={css['cardBody']}>{children}</div>
			</div>
			{footer && <div className={css['buttonRow']}>{footer}</div>}
		</div>
	)
}
