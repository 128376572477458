export const objectStoreRoutes = {
	LIST: '/object-store/{{provider}}/{{id}}/children',
	LISTROOT: '/object-store/{{provider}}/children-root',
	GET_DRIVE_SIZE: '/object-store/driveSize/{{provider}}',
	GET_DRIVES_SIZES: '/object-store/drivesSizes',
	GET: '/object-store/{{provider}}/{{id}}',
	DOWNLOAD: '/object-store/{{provider}}/{{id}}/download',
	DOWNLOAD_LYNX_THUMBNAILS: '/object-store/{{provider}}/{{id}}/lynx-thumbnails',
	UPLOAD: '/object-store/{{provider}}/{{id}}',
	PRE_UPLOAD: '/object-store/pre-upload/{{provider}}/{{providerContainerId}}',
	POST_UPLOAD: '/object-store/post-upload/{{provider}}/{{providerContainerId}}',
	UPLOADROOT: '/object-store/{{provider}}',
	DELETE: '/object-store/{{provider}}/{{id}}',
	CREATEFOLDER: '/object-store/{{provider}}/{{id}}/createFolder',
	CREATEFOLDERROOT: '/object-store/{{provider}}/createFolder',
	CREATELYNXFILE: '/object-store/{{provider}}/{{id}}/createFile',
	CREATELYNXFILEROOT: '/object-store/{{provider}}/createFile',
	UPDATE: '/object-store/{{provider}}/{{id}}',
	EXTRA: '/object-store/{{provider}}/{{driveItemId}}/extra',

	RECENT_FILES: '/object-store/recent-files',
	DELETE_RECENT_FILE: '/object-store/recent-files/{{provider}}/{{driveItemId}}'
}
