import { ProfileState } from './types'

export const initialState: ProfileState = {
	userId: '',
	email: '',
	displayName: '',
	role: 4,
	loggedIn: false,
	gravatarUrl: '',
	avatarBlobUrl: null,
	location: window.location.pathname,
	lang: null,
	accessType: 'prod',
	permissions: [],
	profileContextMenuOpen: false,
	showLoginModal: false,
	stickyLoginModal: false,
	showZoom: false,
	userSessionEnd: false,
	isPro: false,
	hadPro: false,
	isTermsAccepted: false
}
