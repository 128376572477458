import { APIRequest } from '@lynx/client-core/src/api'
import { SharesInfo } from '@lynx/client-core/src/api/interfaces'
import { AutorenewIcon } from '@lynx/client-core/src/components'
import { VideoRenderer } from 'components/VideoRenderer'
import i18next from 'i18next'
import { ControlBar } from 'pages/Share/components/ControlBar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ApplicationState } from 'store'
import { showModal, VideoPreviewModalContextType } from 'store/modal'
import css from './Video.module.css'

interface VideoState {
	token: string
	blobLink: string
	shareDetails: SharesInfo | null
}

export const Video = (): React.ReactElement => {
	const params = useParams<{ token: string }>()
	const dispatch = useDispatch()
	const { profile: profileState } = useSelector((state: ApplicationState) => state)
	const navigate = useNavigate()
	const [state, setState] = useState<VideoState>({
		shareDetails: null,
		token: '',
		blobLink: ''
	})

	const handleDownload = (): void => {
		const link = document.createElement('a')
		link.href = state.blobLink
		document.body.appendChild(link)
		link.download = state.shareDetails?.name || 'download'
		link.click()
		document.body.removeChild(link)
	}

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const { token } = params
			if (!token) {
				return
			}

			const shareInfo = await APIRequest.Sharing.info(token)
			if (!shareInfo?.data) {
				navigate('/missing-share')
				return
			}

			const blobLinkProperties = await APIRequest.Sharing.getObjectUrl(token)
			if (!blobLinkProperties) {
				navigate('/missing-share')
			}

			setState({ ...state, token, blobLink: blobLinkProperties.link, shareDetails: shareInfo.data })
		}
		mount()
	}, [])

	const handleViewClicked = (): void => {
		dispatch(showModal({ name: 'VideoPreviewModal', context: { type: VideoPreviewModalContextType, token: state.token } }))
	}

	return (
		<div className={css['container']}>
			{!state.blobLink.length && (
				<div
					style={{
						width: '1024px',
						height: '100%',
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						fontSize: '19px'
					}}
				>
					{profileState.loggedIn && (
						<React.Fragment>
							<div>{i18next.t('pages.share.loading')}</div>
							<AutorenewIcon />
						</React.Fragment>
					)}
				</div>
			)}
			{state.blobLink?.length > 0 && (
				<VideoRenderer
					blob={state.blobLink}
					height={0}
					options={{ fluid: false, fill: true, loop: false, controls: true, muted: false, autoplay: false }}
				/>
			)}
			<ControlBar onDownloadClicked={handleDownload} onViewClicked={handleViewClicked} shareDetails={state.shareDetails} token={state.token} />
		</div>
	)
}
