import { APIRequest } from '@lynx/client-core/src/api'
import { Plan } from '@lynx/client-core/src/api/Billing/Billing'
import {
	AddCircleOutlineIcon,
	InvoiceIcon,
	BasicButton,
	CancelFilledIcon,
	EditIcon,
	LoadingSpinner,
	SaveIcon,
	SendIcon,
	Table,
	Badge
} from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { showModal } from '@lynx/client-core/src/store/modal'
import { SearchOrganisation } from '@lynx/core/src/interfaces/Organisation'
import { PlanFreeIcon, PlanOrgIcon } from 'components/PlanIcons/PlanIcons'
import { Card } from 'components/elmstone/Card'
import { useDataWithPaginationAndSearch } from 'hooks/useDataWithPaginationAndSearch'
import { OptionsContainer, getTableSearchParam, renderStorageElement, useSubscriptionPlan } from 'pages/Admin/routes/elmstone/Subscriptions/ListShared'
import { SubscriptionsOverlay } from 'pages/Admin/routes/elmstone/Subscriptions/SubscriptionsOverlay'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import css from './OrganisationList.module.scss'
import { OrganisationNewSubscription } from './OrganisationSubscription/OrganisationNewSubscription'
import { OrganisationUserLicensesCol } from './OrganisationUserLicensesCol'
import { OrganisationEntryModal, OrganisationEntryModalContextType } from './modals'
import { useOrganisationChangeInvoiceStatus } from './OrganisationSubscription/OrganisationMarkInvoice'
import { getStripeDashboardPath, hasUserPermission } from '@lynx/core'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { BoxLightCustomerServicePermission } from '@lynx/core/src/constants'
interface Props {
	plans: Plan[]
}

export const OrganisationList = ({ plans }: Props): React.ReactElement => {
	const [editedStorage, setEditedStorage] = useState<number | null>(null)
	const [editedLicenses, setEditedLicenses] = useState<number | null>(null)
	const [isNewSubscriptionModalOpen, setIsNewSubscriptionModalOpen] = useState(false)
	const { getPlanStorageLimit } = useSubscriptionPlan(plans, 'organisation')
	const [sendingInvoiceId, setSendingInvoiceId] = useState<number | null>(null)
	const { profile, config } = useSelector((state: ApplicationState) => state)

	const dispatch = useDispatch()
	const fetchData = async ({
		start,
		length,
		searchText
	}: {
		start: number
		length: number
		searchText: string
	}): Promise<{ data: SearchOrganisation[]; totalCount: number }> => {
		const response = await APIRequest.Organisations.searchOrganisation({ start, length, searchText })
		return { data: response.organisations, totalCount: response.totalCount }
	}

	const {
		state: { data, totalCount, rowsPerPage, page },
		isLoading,
		setIsLoading,
		editableRowId,
		setEditableRowId,
		getData,
		changeSearchText,
		changePage
	} = useDataWithPaginationAndSearch<SearchOrganisation>({ fetchData, initialPageSize: 15 })

	const updateUserStorageLimit = async (item: SearchOrganisation, isStorageLimitModified: boolean, isUserLicensesModified: boolean): Promise<void> => {
		if (editableRowId) {
			try {
				setIsLoading(true)
				isStorageLimitModified && editedStorage && (await APIRequest.Organisations.updateUserStorageLimit(item.id, editedStorage, item.storageLimitGB))
				isUserLicensesModified && editedLicenses && (await APIRequest.Organisations.updateUserLicensesLimit(item.id, editedLicenses))
				setEditableRowId(null)
				setEditedStorage(null)
				setEditedLicenses(null)
				getData(page, getTableSearchParam(), rowsPerPage)
			} finally {
				setIsLoading(false)
			}
		}
	}

	const onEditRowClicked = (item: SearchOrganisation): void => {
		setEditableRowId(editableRowId !== item.id ? item.id : null)
		setEditedStorage(item.storageLimitGB ?? getPlanStorageLimit(item.isPro))
	}

	const handleRowClicked = (item: SearchOrganisation): void => {
		dispatch(showModal({ name: 'OrganisationEntryModal', context: { type: OrganisationEntryModalContextType, id: item.id } }))
	}

	const handleViewStripeSubscription = async (item: SearchOrganisation): Promise<void> => {
		const stripeAccount = await APIRequest.Billing.getAccountUrl('organisation', item.id)
		if (stripeAccount?.url) {
			window.open(stripeAccount.url, '_blank')
			return
		}

		dispatch(showModal({ name: 'NoStripeAccountModal' }))
	}

	const onSendInvoiceClicked = async (item: SearchOrganisation): Promise<void> => {
		try {
			setSendingInvoiceId(item.id)
			await sendInvoice(item)
			getData(page, getTableSearchParam(), rowsPerPage)
		} finally {
			setSendingInvoiceId(null)
		}
	}

	const getSendInvoiceIcon = (item: SearchOrganisation, hideText: boolean): React.ReactElement => {
		const icon =
			sendingInvoiceId === item.id ? (
				<LoadingSpinner />
			) : (
				<SendIcon className={css.sendInvoiceIcon} onClick={(): Promise<void> => onSendInvoiceClicked(item)} />
			)

		return (
			<>
				{icon}
				{!hideText && <div onClick={(): Promise<void> => onSendInvoiceClicked(item)}>Send Invoice</div>}
			</>
		)
	}

	const { getDropdownEl, changeInvoiceStatusModal } = useOrganisationChangeInvoiceStatus({
		onInvoiceStatusChanged: (): void => getData(page, getTableSearchParam(), rowsPerPage)
	})

	const sendInvoice = async (item: SearchOrganisation): Promise<void> => {
		await APIRequest.Billing.sendInvoice(item.id)
	}

	const subscriptionColumns = [
		{
			valueKey: 'paymentMethodType',
			label: 'Payment Source',
			customBodyRender: (item: SearchOrganisation) => <div>{item.subscriptionData?.paymentMethodType || ''}</div>
		},
		{
			valueKey: 'userLicensesLimit',
			label: 'User Licenses',
			customBodyRender: (item: SearchOrganisation): React.ReactElement => {
				if (!item.isPro) return <></>
				const { id, userLicensesLimit, usersCount, subscriptionData } = item
				const isEditable = editableRowId === id
				if (isEditable)
					return (
						<OrganisationUserLicensesCol
							orgItem={item}
							isLoading={isLoading}
							editedLicenses={editedLicenses}
							setEditedLicenses={setEditedLicenses}
						/>
					)
				return (
					<div>
						{usersCount} /{' '}
						{userLicensesLimit === null ? (
							Number(subscriptionData?.usersProQuantity)
						) : (
							<span className={css.userLicensesLimitCustomValue}>{userLicensesLimit}</span>
						)}
					</div>
				)
			}
		},
		{
			label: 'Payment Due',
			valueKey: 'paymentDue',
			customBodyRender: (item: SearchOrganisation): React.ReactElement => {
				const { subscriptionData } = item
				const paymentDue = subscriptionData?.paymentDue
				if (!paymentDue) return <></>
				const date = new Date(paymentDue * 1000)
				return <div>{paymentDue ? date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }) : ''}</div>
			}
		},
		{
			valueKey: 'invoiceStatus',
			label: 'Invoice Status',
			customBodyRender: (item: SearchOrganisation): React.ReactElement => {
				const status = item.subscriptionData?.invoiceStatus
				const color = {
					draft: 'blue',
					open: 'blue',
					paid: 'green',
					uncollectible: 'grey',
					void: 'grey',
					past_due: 'red'
				} as const
				const statusMapping = {
					draft: 'AWAITING INVOICE',
					open: 'AWAITING PAYMENT',
					paid: 'PAID',
					uncollectible: 'UNCOLLECTIBLE',
					void: 'VOID',
					past_due: 'OVERDUE'
				}
				const statusText = statusMapping[status as keyof typeof statusMapping]
				const badgeVariant = color[status as keyof typeof color]
				const badge = (
					<Badge variant={badgeVariant} size="xs">
						<div className={css.badgeStatus}>{statusText}</div>
					</Badge>
				)

				return <div className={css.statusContainer}>{status ? <div title={status}>{badge}</div> : <></>}</div>
			}
		}
	]

	const columns: TableHeaders = [
		{
			valueKey: 'id',
			label: 'Id',
			customBodyRender: (item: SearchOrganisation) => (
				<div className={css.idColumnContainer}>
					<div>{item.id}</div>
					{item.subscriptionData?.isReseller && (
						<Badge variant="teal" size="xs" className={css.badge}>
							RESELLER
						</Badge>
					)}
				</div>
			)
		},
		{ valueKey: 'organisationName', label: 'Name' },
		{
			valueKey: 'isPro',
			label: 'Plan',
			customBodyRender: (item: SearchOrganisation) => (item.isPro ? <PlanOrgIcon /> : <PlanFreeIcon />)
		},
		{
			valueKey: 'storageLimitGB',
			label: 'Storage',
			customBodyRender: (item: SearchOrganisation) =>
				renderStorageElement({
					id: item.id,
					storageLimitGB: item.storageLimitGB,
					editableRowId,
					setEditedStorage,
					isLoading,
					planStorageLimit: getPlanStorageLimit(item.isPro),
					editedStorage
				})
		},
		...(hasUserPermission(BoxLightCustomerServicePermission, profile.permissions) ? subscriptionColumns : []),
		{
			valueKey: '__edit',
			label: '',
			customBodyRender: (item: SearchOrganisation): React.ReactElement => {
				const { subscriptionData, id } = item
				const isStorageLimitModified = (item.storageLimitGB || getPlanStorageLimit(item.isPro)) !== editedStorage

				const isUserLicensesModifiedSameAsStripe = item.userLicensesLimit && item.subscriptionData?.usersProQuantity === editedLicenses // if yes then it will set to db null and use by default stripe value
				const isUserLicensesModified = Boolean(
					(item.userLicensesLimit || item.subscriptionData?.usersProQuantity) !== editedLicenses || isUserLicensesModifiedSameAsStripe
				)

				const invoiceId = subscriptionData?.invoiceId
				const customerId = subscriptionData?.customerId

				const canSendInvoice = item.subscriptionData?.invoiceStatus === 'open' || item.subscriptionData?.invoiceStatus === 'draft'
				const isOpenStatus = item.subscriptionData?.invoiceStatus === 'open'
				const isUncollectibleStatus = item.subscriptionData?.invoiceStatus === 'uncollectible'
				const isPastDueStatus = item.subscriptionData?.invoiceStatus === 'past_due'

				const canMarkAsPaid = isOpenStatus || isUncollectibleStatus
				const canMarkAsVoid = isOpenStatus || isUncollectibleStatus
				const canMarkAsUncollectible = isOpenStatus || isPastDueStatus

				const isDropdownVisible = canSendInvoice || customerId
				const invoiceUrl = `${getStripeDashboardPath(config.ENVIRONMENT === 'production')}/invoices/${invoiceId}`
				return (
					<OptionsContainer>
						{canSendInvoice && getSendInvoiceIcon(item, true)}
						{editableRowId !== item.id && Boolean(item.isPro) && <EditIcon onClick={(): void => onEditRowClicked(item)} />}
						{editableRowId === item.id && <CancelFilledIcon onClick={(): boolean | void => !isLoading && setEditableRowId('')} />}
						{editableRowId === item.id && (isStorageLimitModified || isUserLicensesModified) && (
							<SaveIcon
								onClick={(): boolean | Promise<void> =>
									!isLoading && updateUserStorageLimit(item, isStorageLimitModified, isUserLicensesModified)
								}
							/>
						)}
						{invoiceId && (
							<a href={invoiceUrl} target="_blank" onClick={(): Window | null => window.open(invoiceUrl, '_blank')} rel="noreferrer">
								<InvoiceIcon />
							</a>
						)}

						{isDropdownVisible && (
							<SubscriptionsOverlay>
								<ul>
									{customerId && (
										<li
											onClick={(): void => {
												handleViewStripeSubscription(item)
											}}
										>
											View Stripe Account
										</li>
									)}
									{canSendInvoice && <li>{getSendInvoiceIcon(item, false)}</li>}
									{canMarkAsPaid && invoiceId && getDropdownEl('paid', invoiceId, id)}
									{canMarkAsUncollectible && invoiceId && getDropdownEl('uncollectible', invoiceId, id)}
									{canMarkAsVoid && invoiceId && getDropdownEl('void', invoiceId, id)}
								</ul>
							</SubscriptionsOverlay>
						)}
					</OptionsContainer>
				)
			}
		}
	]

	const title = hasUserPermission(BoxLightCustomerServicePermission, profile.permissions) ? (
		<div className={css.titleContainer}>
			<h1>Organisations</h1>
			<div className={css.btnContainer}>
				<BasicButton variant="blue" icon={AddCircleOutlineIcon} onClick={(): void => setIsNewSubscriptionModalOpen(true)} size="sm">
					New Subscription
				</BasicButton>
			</div>
		</div>
	) : (
		'Organisations'
	)

	return (
		<>
			<Card>
				<Table
					pageSizes={[rowsPerPage]}
					onPageChange={changePage}
					totalCount={totalCount}
					onTableSearchChange={changeSearchText}
					title={title}
					headers={columns}
					items={data}
					isCustomSearchRender={true}
					onRowClick={handleRowClicked}
				/>
			</Card>
			{isNewSubscriptionModalOpen && <OrganisationNewSubscription closeModal={(): void => setIsNewSubscriptionModalOpen(false)} />}
			<OrganisationEntryModal />
			{changeInvoiceStatusModal}
		</>
	)
}
