import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

export const RegReportByOs = (): React.ReactElement => {
	const navigate = useNavigate()
	const [reportOS, setReportOs] = useState([])
	const columns = ['AppOS', 'UsageCount']

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const data = await APIRequest.Reports.getRegReportByOs()
			setReportOs(data.reportOS)
		}
		mount()
	}, [])

	const goToDetails = (data: any): void => {
		navigate('/reports/reg-report-detail-os/' + data.AppOS)
	}

	return (
		<Document
			title="Registration Report - By Version"
			description="Registration Report - By Version"
			keywords="Registration Report - By Version"
			auth={true}
			disableOverflow={true}
		>
			<Card>
				<Table onRowClick={goToDetails} title={'Reg Report By OS'} headers={columns.map((v) => ({ label: v, valueKey: v }))} items={reportOS} />
			</Card>
		</Document>
	)
}
