import React from 'react'
import css from './ModalDefaultLayout.module.scss'

interface Props {
	title: React.ReactNode | string
	body?: React.ReactNode | string
	footer: React.ReactNode
	children?: React.ReactNode
}

export const ModalDefaultLayout = ({ title, body, footer, children }: Props): React.ReactElement => {
	return (
		<div className={css.container}>
			<div className={css.titleContainer}>{title}</div>
			<div className={css.bodyContainer}>{body}</div>
			{children}
			<div className={css.footerContainer}>{footer}</div>
		</div>
	)
}
