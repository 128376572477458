import React from 'react'
import css from './ListItem.module.scss'

export interface ListItemProps {
	header?: string
	children?: React.ReactNode
	hidden?: boolean
	id?: string
}

export const ListItem = ({ children, header, hidden = false, id }: ListItemProps): React.ReactElement => {
	const classNames = [css['container']]
	hidden && classNames.push(css['container--hidden'])

	return (
		<li className={classNames.join(' ')} id={id}>
			<hr />
			<div className={css['container__body']}>
				{header && <label className={css['container__header']}>{header}</label>}
				{children}
			</div>
		</li>
	)
}
