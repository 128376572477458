import { ConfigState } from './types'

export const initialConfigState: ConfigState = {
	ENVIRONMENT: '',
	SCHEME: '',
	SEARCH_HOST: '',
	API_SERVER: '',
	API_WS_SERVER: '',
	MESSAGING_API_SERVER: '',
	MESSAGING_WS_SERVER: '',
	LYNXCLOUD_CLIENT: '',
	LYNXADMIN_CLIENT: '',
	LYNXREMOTE_CLIENT: '',
	LYNXACTIVITIES_CLIENT: '',
	LYNXVNC_CLIENT: '',
	LYNXTRANSLATOR_CLIENT: '',
	LYNXZOOM_CLIENT: '',
	SENTRY_DSN: ''
}
