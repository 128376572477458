export const currencySource = [
	{
		name: 'USD',
		url: '/assets/icons/currencies/usd.svg',
		code: 'usd',
		height: 13
	},
	{
		name: 'CHF',
		url: '/assets/icons/currencies/chf.svg',
		code: 'chf',
		height: 13
	},
	{
		name: 'EUR',
		url: '/assets/icons/currencies/eur.svg',
		code: 'eur',
		height: 13
	},
	{
		name: 'GBP',
		url: '/assets/icons/currencies/gbp.svg',
		code: 'gbp',
		height: 13
	},
	{
		name: 'SEK',
		url: '/assets/icons/currencies/sek.svg',
		code: 'sek',
		height: 13
	}
]
