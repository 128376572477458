export const SHOW_FEEDBACK = '@@feedback/SHOW_FEEDBACK'
export const HIDE_FEEDBACK = '@@feedback/HIDE_FEEDBACK'
export const SET_FEEDBACK_CHANCE = '@@feedback/SET_FEEDBACK_CHANCE'
export const CHANGE_VIEW = '@@feedback/CHANGE_VIEW'
export const CHANGE_TOKEN = '@@feedback/CHANGE_TOKEN'

export type FeedbackView = 'hidden' | 'intro' | 'questions' | 'thankyou'

export interface FeedbackState {
	readonly isVisible: boolean
	readonly view: FeedbackView
	readonly chance: number
	readonly token: number
}

interface ShowFeedbackAction {
	type: typeof SHOW_FEEDBACK
}

interface HideFeedbackAction {
	type: typeof HIDE_FEEDBACK
}

interface SetFeedbackChanceAction {
	type: typeof SET_FEEDBACK_CHANCE
	payload: number
}

interface ChangeFeedbackViewAction {
	type: typeof CHANGE_VIEW
	payload: FeedbackView
}

interface ChangeFeedbackTokenAction {
	type: typeof CHANGE_TOKEN
	payload: number
}

export type FeedbackActionTypes = ChangeFeedbackTokenAction | ChangeFeedbackViewAction | SetFeedbackChanceAction | ShowFeedbackAction | HideFeedbackAction
