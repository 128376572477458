import React, { useState } from 'react'
import css from './ProfilePictureModal.module.scss'
import { ModalDefaultLayout } from '@lynx/client-core/src/components/Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { Alert, BasicButton, FilePicker, Modal } from '@lynx/client-core/src/components'
import { ProfilePictureModalType, hideModal } from 'store/modal'
import { useDispatch } from 'react-redux'
import { APIRequest } from '@lynx/client-core/src/api'
import { resizeImage } from '@lynx/client-core/src/utils'
import { setProfilePicture as setProfilePictureAction } from '@lynx/client-core/src/store/profile'
import { Logger } from '@lynx/client-core/src/modules'
import { useTranslation } from 'react-i18next'
import { FileDropper } from '@lynx/client-core/src/components/FileDropper'

const root = '/assets/images/profile-images/200x200/'
const imagesNames = ['dog', 'cat', 'cockerel', 'elephant', 'giraffe', 'hamster', 'highland-cow', 'horse', 'lion', 'rabbit', 'sheep', 'tiger']
const images = imagesNames.map((name) => `${root}${name}.png`)

export const ProfilePictureModal = (): React.ReactElement => {
	const dispatch = useDispatch()
	const [uploadedFile, setUploadedFile] = useState<File | null>(null)
	const [selectedAvatar, setSelectedAvatar] = useState<null | { id: string; url: string }>(null)
	const [providersAvatars, setProvidersAvatars] = useState<{ url: string; email: string }[]>([])
	const [error, setError] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isDragging, setIsDragging] = useState<boolean>(false)
	const { t } = useTranslation()

	const handleUpload = async (file: File): Promise<void> => {
		setUploadedFile(file)
		selectedAvatar && setSelectedAvatar(null)
	}

	const onVisible = async (): Promise<void> => {
		await mount()
	}

	const mount = async (): Promise<void> => {
		const res = await APIRequest.User.getProviderAvatars()
		setProvidersAvatars(res)
	}

	const downloadFile = async (url: string): Promise<File> => {
		const res = await fetch(url)
		const blob = await res.blob()
		const file = new File([blob], 'file-name', { type: 'image/jpg' })
		return file
	}

	const getSelectedFile = async (): Promise<File | null> => {
		if (uploadedFile) {
			const file = await resizeImage(uploadedFile, 200, 200)
			return file
		}
		if (selectedAvatar) {
			return await downloadFile(selectedAvatar.url)
		}
		return null
	}

	const setProfilePicture = async (): Promise<void> => {
		try {
			setIsLoading(true)
			setError(false)
			const file = await getSelectedFile()
			if (!file) return
			const { url } = await APIRequest.User.setProfilePicture(file)
			dispatch(setProfilePictureAction({ url }))
			dispatch(hideModal())
		} catch (error) {
			Logger.error(error)
			setError(true)
		} finally {
			setIsLoading(false)
		}
	}

	const onHidden = (): void => {
		setUploadedFile(null)
		setSelectedAvatar(null)
	}

	const selectAvatar = (id: string, url: string): void => {
		setSelectedAvatar({ id, url })
		setUploadedFile(null)
	}

	const isSaveDisabled = !(uploadedFile || selectedAvatar)
	const handleFileDropper = async (files: FileList): Promise<void> => {
		const firstFile = files[0]
		await handleUpload(firstFile)
	}

	return (
		<Modal name={ProfilePictureModalType} innerContainerClasses={css.innerModalContainer} onVisible={onVisible} variant="purple" onHidden={onHidden}>
			<ModalDefaultLayout
				title={<div className={css.titleContainer}>{t('components.modals.ProfilePictureModal.chooseProfileImage')}</div>}
				body={
					<div className={css.container}>
						<FilePicker onChange={handleUpload} accept=".png,.bmp,.jpg,.jpeg">
							<div
								className={css.uploadContainer}
								onDragEnter={(): void => {
									setIsDragging(true)
								}}
							>
								<FileDropper
									onDropped={handleFileDropper}
									active={isDragging}
									onEnded={(): void => {
										setIsDragging(false)
									}}
								/>
								{uploadedFile && (
									<div className={css.imgContainer}>
										<img src={URL.createObjectURL(uploadedFile)} />
									</div>
								)}
								<div className={css.upload}>
									<BasicButton variant="blue">{t('components.modals.ProfilePictureModal.upload')}</BasicButton>
									<div>{t('components.modals.ProfilePictureModal.orDragImageHere')}</div>
								</div>
								<div className={css.sizeRecommended}>{t('components.modals.ProfilePictureModal.recommendedSize')}</div>
							</div>
						</FilePicker>
						<div className={css.avatarSelectionContainer}>
							<div className={css.selectAvatarText}>{t('components.modals.ProfilePictureModal.orSelectAvatar')}</div>
							<div className={css.avatarList}>
								{providersAvatars.map(({ url }, i) => (
									<div
										key={url}
										className={[css.avatarContainer, 'provider' + i === selectedAvatar?.id && css.selectedAvatar].join(' ')}
										onClick={(): void => selectAvatar('provider' + i, url)}
									>
										<img src={url}></img>
									</div>
								))}
							</div>
							<div className={css.divider} />
							<div className={css.avatarList}>
								{images.map((image, i) => (
									<div
										key={image}
										className={[css.avatarContainer, 'static' + i === selectedAvatar?.id && css.selectedAvatar].join(' ')}
										onClick={(): void => selectAvatar('static' + i, image)}
									>
										<img src={image}></img>
									</div>
								))}
							</div>
						</div>
						{error && <Alert styleClass="danger">{t('components.modals.ProfilePictureModal.somethingWentWrong')}</Alert>}
					</div>
				}
				footer={
					<React.Fragment>
						<BasicButton
							variant="grey"
							onClick={(): void => {
								dispatch(hideModal())
							}}
						>
							{t('components.modals.ProfilePictureModal.cancel')}
						</BasicButton>
						<BasicButton
							isLoading={isLoading}
							disabled={isSaveDisabled}
							variant="blue"
							onClick={(): void => {
								setProfilePicture()
							}}
						>
							{t('components.modals.ProfilePictureModal.save')}
						</BasicButton>
					</React.Fragment>
				}
			></ModalDefaultLayout>
		</Modal>
	)
}
