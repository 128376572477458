export const UPDATE_LAYOUT_STATE = '@@layout/UPDATE_LAYOUT_STATE'

export interface LayoutState {
	readonly footerHeight: number
}

interface UpdateLayoutStateAction {
	type: typeof UPDATE_LAYOUT_STATE
	payload: Partial<LayoutState>
}

export type LayoutActionTypes = UpdateLayoutStateAction
