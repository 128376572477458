import { Button, Modal, TextField } from '@lynx/client-core/src/components'
import { Overlay } from '@lynx/client-core/src/components/Popup/Overlay'
import React from 'react'
import { connect } from 'react-redux'
import { hideModal, ModalContext } from 'store/modal'
import { PublishModalProps, PublishModalState } from './interfaces'
import css from './PublishModal.module.css'

class PublishModal extends React.Component<PublishModalProps, PublishModalState> {
	constructor(props: PublishModalProps) {
		super(props)
		this.state = {
			itemname: '',
			item: undefined
		}
	}

	private handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		e.preventDefault()

		const { item, itemname } = this.state
		if (!item || !itemname) {
			return
		}

		this.props.hideModal()
	}

	private handleVisible = async (context?: ModalContext): Promise<void> => {
		if (context?.type !== 'PublishModalContextType') {
			return
		}

		const { driveItem } = context

		this.setState({
			item: driveItem,
			itemname: driveItem.name
		})
	}

	private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		this.setState({
			itemname: event.target.value
		})
	}

	render(): React.ReactNode {
		return (
			<Modal name="PublishModal" onVisible={this.handleVisible} title={'Publish File'}>
				<div className={css['container']}>
					<form className={css['form']} onSubmit={this.handleSubmit}>
						<div className={css['name']}>Name</div>
						<TextField name="itemname" className={css['textfield']} onChange={this.handleChange} value={this.state.itemname || ''} />
						<div className={css['description']}>Description</div>
						<TextField name="itemname" className={css['textfield']} onChange={this.handleChange} value={this.state.itemname || ''} />
						<div className={css['description']} style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ marginRight: '15px' }}>Availability:</div>
							<Overlay title="Public" className={css['dropdown']}>
								<ul>
									<li>Private</li>
									<li>Organisation</li>
									<li>Public</li>
								</ul>
							</Overlay>
						</div>
						<div className={css['description']} style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ marginRight: '15px' }}>Category:</div>
							<Overlay title="Politics" className={css['dropdown']}>
								<ul>
									<li>Private</li>
									<li>Organisation</li>
									<li>Public</li>
								</ul>
							</Overlay>
						</div>
						<Button type="submit" className={css['button']}>
							Publish
						</Button>
					</form>
				</div>
			</Modal>
		)
	}
}

export default connect(undefined, { hideModal })(PublishModal)
