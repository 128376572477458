import { BaseCall } from '../BaseCall'
import { APIConfig } from '../interfaces'

let cache: { words: string[]; unixdatetime: number } = {
	words: [],
	unixdatetime: 0
}

export class Profanity extends BaseCall {
	public async list(): Promise<string[]> {
		try {
			// If we already have cached values and it's within a day old then return that
			const { words, unixdatetime } = cache
			if (words.length && unixdatetime > new Date().getTime() - 24 * 60 * 60 * 1000) {
				return words
			}

			const response = await this.client.get<string[]>(`/profanity`)
			if (response.data?.length) {
				cache = {
					words: response.data,
					unixdatetime: new Date().getTime()
				}
			}
			return response.data
		} catch (e) {
			console.error(e)
			return []
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
