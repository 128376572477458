import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { RegReport, RegReportByCountry, RegReportByMonth, RegReportByOs, RegReportByVersion, RegReportRecentMultipleUsage } from '.'

export const ReportsPages = (): React.ReactElement => {
	return (
		<Routes>
			<Route path="reg-report" element={<RegReport />} />
			<Route path="reg-report-by-country" element={<RegReportByCountry />} />
			<Route path="reg-report-by-month" element={<RegReportByMonth />} />
			<Route path="reg-report-by-os" element={<RegReportByOs />} />
			<Route path="reg-report-by-version" element={<RegReportByVersion />} />
			<Route path="reg-report-recent-multiple-usage" element={<RegReportRecentMultipleUsage />} />
		</Routes>
	)
}
