import { APIRequest } from '@lynx/client-core/src/api'
import { BillingDetails } from '@lynx/client-core/src/api/Billing/Billing'
import { useEffect, useState } from 'react'

export const useBillingDetails = (): { billingDetails: BillingDetails | null } => {
	const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(null)
	useEffect(() => {
		const getBillingDetails = async (): Promise<void> => {
			const billingDetails = await APIRequest.Billing.getBillingDetails()
			if (billingDetails) {
				setBillingDetails(billingDetails)
			}
		}
		getBillingDetails()
	}, [])

	return { billingDetails }
}
