import { NotificationTypes } from '@lynx/core/src/interfaces/Notifications'

export const NOTIFICATION_ERROR = 'notification:error'
export const CONVERSION_READY = 'conversion:ready'

interface ConversionReadyAction {
	type: typeof CONVERSION_READY
	channelId: string
	sender: string
	payload: { filename: string; downloadUrl: string; fileId: string; shareToken: string }
}

interface NotificationErrorAction {
	type: typeof NOTIFICATION_ERROR
	channelId: string
	sender: string
	payload: { title: string; body: string }
}

export type UserMessage = (UserMessagingActionTypes | NotificationTypes<'parsed'>) & { notificationId: number }

export const READ_MESSAGE = 'REDUX_WEBSOCKET::MESSAGE'

export type UserMessagingActionTypes = ConversionReadyAction | NotificationErrorAction
