import { BasicButton, Textarea } from '@lynx/client-core/src/components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './OauthDomains.module.scss'

interface OauthProps {
	oauth: {
		authorizationUrl: string
		tokenEndpoint: string
		userEndpoint: string
		clientId: string
		clientSecret: string
		domains?: string[]
	}
	isIserv: boolean
}

export interface OauthDomainProps {
	navigate: (section: string, props: OauthProps | null) => void
	context: OauthProps
}

export const OauthDomains = (props: OauthDomainProps): React.ReactElement => {
	const [domains, setDomains] = useState<string[]>([])
	const { t } = useTranslation()
	const handleNext = (): void => {
		const { oauth, isIserv } = props.context
		props.navigate('OrganisationDetails', {
			oauth: {
				...oauth,
				domains
			},
			isIserv
		})
	}

	const back = (): void => {
		const { oauth, isIserv } = props.context
		props.navigate('OauthSetup', {
			oauth,
			isIserv
		})
	}

	return (
		<div className={css['container']}>
			<p>{t('pages.organisation.oauthDomain')}</p>
			<Textarea
				onChange={(e): void => {
					setDomains(e.target.value.split('\n'))
				}}
			/>
			<div className={css['buttons']}>
				<BasicButton onClick={back}>{t('pages.organisations.createModal.back')}</BasicButton>
				<BasicButton variant="blue" disabled={!domains} onClick={handleNext}>
					{t('pages.organisations.createModal.next')}
				</BasicButton>
			</div>
		</div>
	)
}
