import { BaseCall } from '../BaseCall'
import { APIConfig } from '../interfaces'
import { LangKeysType, SubmitTranslationReqInterface, TranslationItem, TranslationRequestData } from '@lynx/core/src/interfaces/Translation'

export class Translation extends BaseCall {
	public get = async (lang: LangKeysType): Promise<{ translations: TranslationItem[]; language: LangKeysType } | null> => {
		try {
			const res = await this.client.get(`/translation/${lang}`)
			return res.data
		} catch (err) {
			console.error(err)
			return null
		}
	}

	public getTranslationRequestStatuses = async (lang: LangKeysType): Promise<{ translationsRequestData: TranslationRequestData[] } | null> => {
		try {
			const res = await this.client.get(`/translation/${lang}/requestStatuses`)
			return res.data
		} catch (err) {
			console.error(err)
			return null
		}
	}

	public submitTranslations = async (lang: LangKeysType, translations: SubmitTranslationReqInterface['translations']): Promise<null> => {
		const res = await this.client.post(`/translation/${lang}`, { translations })
		return res.data
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
