import { APIRequest } from '@lynx/client-core/src/api'
import { Document } from '@lynx/client-core/src/components'
import { colors } from '@lynx/core'
import React, { useEffect, useState } from 'react'
import { Bar, Doughnut } from 'react-chartjs-2'
import Styles from './Charts.module.scss'
import { ReactChart } from './interfaces/ReactChart'
import { changeLogKeys, changeLogYears, options } from './options'
import { useTranslation } from 'react-i18next'

export const Charts = (): React.ReactElement => {
	const { t } = useTranslation()
	const [charts, setCharts] = useState<
		{
			title: string
			labels: string[]
			data: number[]
			type: string
		}[]
	>([])
	const [changeLogCharts, setChangeLogCharts] = useState<ReactChart[]>([])

	const chartDefinitions = [
		{
			tab: 'pages.charts.version',
			title: 'pages.charts.versionTitle1MonthMajor' as const,
			type: 'bar',
			url: '/api-v2/analytics/major/1',
			enabled: true
		},
		{
			indexLabel: '{label} - #percent%',
			tab: 'pages.charts.contacted',
			title: 'pages.charts.contactedTitle1Month' as const,
			type: 'doughnut',
			url: '/api-v2/analytics/cpu/1',
			enabled: true
		},
		{
			indexLabel: '{label} - #percent%',
			tab: 'pages.charts.contacted',
			title: 'pages.charts.contactedTitleOperatingSystem1Month' as const,
			type: 'doughnut',
			url: '/api-v2/analytics/os/12',
			enabled: true
		},
		{
			indexLabel: '{label} - #percent%',
			tab: 'pages.charts.contacted',
			title: 'pages.charts.contactedTitleCountry12Month' as const,
			type: 'doughnut',
			url: '/api-v2/analytics/country/12',
			enabled: true
		},
		{
			indexLabel: '{label} - #percent%',
			tab: 'pages.charts.contacted',
			title: 'pages.charts.contactedTitlePlatform12Month' as const,
			type: 'doughnut',
			url: '/api-v2/analytics/platform/12',
			enabled: true
		},
		{
			tab: 'pages.charts.registrations',
			title: 'pages.charts.registrationsTitleMonth' as const,
			type: 'doughnut',
			url: '/api-v2/analytics/reg-month',
			enabled: false
		},
		{
			tab: 'pages.charts.active',
			title: 'pages.charts.activeTitle1Month' as const,
			type: 'bar',
			url: '/api-v2/analytics/active/1',
			enabled: true
		},
		{
			tab: 'pages.charts.active',
			title: 'pages.charts.activeTitle' as const,
			type: 'bar',
			url: '/api-v2/analytics/active/12',
			enabled: true
		},
		{
			tab: 'pages.charts.active',
			title: 'pages.charts.activeTitle' as const,
			type: 'bar',
			url: '/api-v2/analytics/active/36',
			enabled: true
		},
		{
			tab: 'pages.charts.active',
			title: 'pages.charts.activeTitle' as const,
			type: 'bar',
			url: '/api-v2/analytics/active/120',
			enabled: true
		}
	]

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const ch = []
			for (const changeLogKey of changeLogKeys) {
				const { product, environment, os } = changeLogKey

				// Get the change log data from API
				const changeLogsData = await APIRequest.Logs.getChangeLogs(product, environment, os)
				if (!changeLogsData) {
					continue
				}

				const { versions } = changeLogsData

				const data = {
					labels: changeLogYears,
					datasets: [
						{
							label: 'Fixes',
							data: [0],
							backgroundColor: '#FF5370'
						},
						{
							label: 'Improvement',
							data: [0],
							backgroundColor: '#3A4'
						},
						{
							label: 'Feature',
							data: [0],
							backgroundColor: '#4099ff'
						}
					]
				}

				for (let i = 0; i < data.labels.length; i++) {
					const year = data.labels[i]
					const yearVersions = versions.filter((v) => v.versionDate.includes(year))
					for (const version of yearVersions) {
						for (const change of version.changes) {
							const dataset = data.datasets.find((l) => (l.label === 'Fixes' ? 'Bug' : l.label) === change.changeType)
							if (!dataset) continue
							if (!dataset.data[i]) {
								dataset.data[i] = 0
							}
							dataset.data[i] += 1
						}
					}
				}

				ch.push(data)
			}
			setChangeLogCharts(ch)

			for (const chart of chartDefinitions) {
				const chartAPIResponse = await APIRequest.Pages.get(chart.url)
				const { data: chartData } = chartAPIResponse
				const labels: string[] = []
				const data: number[] = []

				for (const item of chartData) {
					labels.push(item.label)
					data.push(item.y)
				}

				const title = t(chart.title)
				setCharts((prevState) => [...prevState, { title, labels, data, type: chart.type }])
			}
		}

		mount()
	}, [])

	const chartComponents = charts
		.filter((chart) => chart.data.length)
		.map((chart, index: number) => {
			const data = {
				labels: chart.labels,
				datasets: [
					{
						label: chart.title,
						data: chart.data,
						backgroundColor: colors.getRandomColorsPerItem(chart.data.length)
					}
				]
			}

			switch (chart.type) {
				case 'bar': {
					return (
						<div key={`bar${index}`} style={{ width: 500 }} draggable={true}>
							<Bar key={index} data={data} width={500} height={500} />
						</div>
					)
				}
				case 'doughnut': {
					return (
						<div key={`doughnut${index}`} style={{ width: 500 }}>
							<Doughnut key={index} data={data} width={500} height={500} />
						</div>
					)
				}
			}
		})

	const changeLogs = changeLogCharts.map((c, index) => (
		<Bar key={index} style={{ backgroundColor: 'white' }} options={options[index]} data={c} width={500} height={500} />
	))

	return (
		<Document title="Charts" auth={true} disableOverflow={true}>
			<div className={Styles['container']}>
				<div className={Styles['container__charts']}>
					{changeLogs}
					{chartComponents}
				</div>
			</div>
		</Document>
	)
}
