import { Reducer } from 'redux'
import { initialDocumentState } from './initialState'
import {
	ADD_DOWNLOAD,
	DOCUMENT_AVAILABLE,
	CHANGE_PAGE,
	CLEAR_DOWNLOAD,
	DocumentActionTypes,
	DocumentState,
	DOCUMENT_CLEAR,
	DOCUMENT_LOAD,
	DOCUMENT_LOADED,
	DOCUMENT_REQUEST,
	SHARED_DOCUMENT_LOAD
} from './types'

const reducer: Reducer<DocumentState, DocumentActionTypes> = (state: DocumentState = initialDocumentState, action: DocumentActionTypes) => {
	switch (action.type) {
		case ADD_DOWNLOAD: {
			return {
				...state,
				...{ channel: action.payload }
			}
		}
		case CLEAR_DOWNLOAD: {
			return {
				...state,
				...{
					blobLink: '',
					extension: '',
					page: 1,
					numPages: 1,
					fileId: '',
					token: '',
					channelId: '',
					play: false,
					download: '',
					ready: false,
					autoDownload: false
				}
			}
		}
		case CHANGE_PAGE: {
			return {
				...state,
				...{
					page: action.payload
				}
			}
		}
		case DOCUMENT_CLEAR: {
			return {
				blobLink: '',
				extension: '',
				page: 1,
				numPages: 1,
				fileId: '',
				token: '',
				channelId: '',
				play: false,
				download: '',
				ready: false,
				autoDownload: false
			}
		}
		case DOCUMENT_LOAD: {
			const { blobLink, extension, fileId } = action.payload
			return {
				...state,
				...{
					blobLink,
					extension,
					page: 1,
					fileId,
					token: '',
					play: false,
					autoDownload: false
				}
			}
		}
		case SHARED_DOCUMENT_LOAD: {
			const { blobLink, extension, token } = action.payload
			return {
				...state,
				...{
					blobLink,
					extension,
					page: 1,
					token,
					fileId: '',
					play: false,
					autoDownload: false
				}
			}
		}
		case DOCUMENT_REQUEST: {
			const { channelId, fileId, autoDownload, shareToken } = action.payload
			return {
				...state,
				...{
					channelId,
					fileId,
					ready: false,
					autoDownload,
					token: shareToken
				}
			}
		}
		case DOCUMENT_AVAILABLE: {
			const { fileId } = action.payload
			return {
				...state,
				...{
					ready: true,
					fileId
				}
			}
		}
		case DOCUMENT_LOADED: {
			const { numPages } = action.payload
			return {
				...state,
				...{ numPages }
			}
		}
		default: {
			return state
		}
	}
}

export { reducer as documentReducer }
