import { BasicButton, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ModalContext } from 'store/modal'
import { enableOrganisationUser } from 'store/organisations'
import css from './UserEnableModal.module.scss'
import { UserEnableModalContext } from './UserEnableModalContext'
import { UserEnableModalContextType } from './UserEnableModalContextType'

export const UserEnableModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const [userContext, setUserContext] = useState<UserEnableModalContext | null>(null)
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()

	const organisation = useCurrentOrganisation()
	if (!organisation) return null

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
		setUserContext(null)
	}

	const handleDeleteClicked = async (): Promise<void> => {
		if (!userContext) return

		await dispatchThunk(enableOrganisationUser(organisation.id, userContext.userId))
		dispatch(hideModal())
	}

	const handleVisible = (context?: ModalContext): void => {
		if (context?.type !== UserEnableModalContextType) {
			return
		}

		setUserContext(context)
	}

	return (
		<Modal name="UserEnableModal" title={t('pages.organisations.reinstateUser')} onVisible={handleVisible} variant="danger">
			<div className={css['container']}>
				<div>
					{userContext?.email} {t('pages.organisations.canResume')}
				</div>

				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
					<BasicButton variant="danger" onClick={handleDeleteClicked}>
						{t('pages.organisations.reinstate')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
