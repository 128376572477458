import { Reducer } from 'redux'

import { initialFeedbackState } from './initialState'
import { CHANGE_TOKEN, CHANGE_VIEW, FeedbackActionTypes, FeedbackState, HIDE_FEEDBACK, SET_FEEDBACK_CHANCE, SHOW_FEEDBACK } from './types'

const reducer: Reducer<FeedbackState, FeedbackActionTypes> = (state: FeedbackState = initialFeedbackState, action: FeedbackActionTypes) => {
	switch (action.type) {
		case CHANGE_TOKEN: {
			return {
				...state,
				token: action.payload
			}
		}
		case CHANGE_VIEW: {
			return {
				...state,
				view: action.payload
			}
		}
		case SHOW_FEEDBACK: {
			return {
				...state,
				isVisible: true
			}
		}
		case HIDE_FEEDBACK: {
			return {
				...state,
				isVisible: false
			}
		}
		case SET_FEEDBACK_CHANCE: {
			return {
				...state,
				chance: action.payload
			}
		}
		default: {
			return state
		}
	}
}

export { reducer as feedbackReducer }
