import { INotification, NotificationTypes } from '@lynx/core/src/interfaces/Notifications'
export const SET_NOTIFICATIONS = '@@notifications/SET_NOTIFICATIONS'
export const UPDATE_NOTIFICATIONS = '@@notifications/UPDATE_NOTIFICATIONS'
export const PUSH_NOTIFICATION_TOAST = '@@toast/PUSH_NOTIFICATION_TOAST'
export const DELETE_NOTIFICATION_TOAST = '@@toast/DELETE_NOTIFICATION_TOAST'
export const DELETE_NOTIFICATION = '@@toast/DELETE_NOTIFICATION'

export type NotificationToast = NotificationTypes<'parsed'> & {
	toastId?: string
	notificationId?: number
}
export interface NotificationsState {
	notificationsData: INotification[]
	notificationToasts: NotificationToast[]
	timeout: number
}

interface SetNotificationsAction {
	type: typeof SET_NOTIFICATIONS
	payload: NotificationsState['notificationsData']
}

interface UpdateNotificationsAction {
	type: typeof UPDATE_NOTIFICATIONS
	payload: NotificationsState['notificationsData']
}

interface ShowNotificationToastAction {
	type: typeof PUSH_NOTIFICATION_TOAST
	payload: NotificationTypes<'parsed'>
}

interface DeleteNotificationToastAction {
	type: typeof DELETE_NOTIFICATION_TOAST
	payload: NotificationToast
}

interface DeleteNotificationAction {
	type: typeof DELETE_NOTIFICATION
	payload: { id: number }
}

export type NotificationsActionTypes =
	| SetNotificationsAction
	| UpdateNotificationsAction
	| ShowNotificationToastAction
	| DeleteNotificationToastAction
	| DeleteNotificationAction
