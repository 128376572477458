import React from 'react'
import css from './FeaturesCarousel.module.scss'
import { PortalFeature } from 'pages/Portal/Portal.types'
import i18next from 'i18next'
import { ChevronLeftIcon, ChevronRightIcon } from '@lynx/client-core/src/components'

export const FeaturesCarousel = ({ items, mode = 'default' }: { items: PortalFeature[]; mode?: 'default' | 'article' }): React.ReactElement | null => {
	const [currentItem, setCurrentItem] = React.useState<number>(0)

	const interval = React.useRef<NodeJS.Timeout | null>(null)

	const handleNext = (): void => {
		clearInterval(interval.current as NodeJS.Timeout)

		setCurrentItem((currentItem) => {
			if (currentItem === items.length - 1) {
				return 0
			} else {
				return currentItem + 1
			}
		})
	}

	const handlePrevious = (): void => {
		clearInterval(interval.current as NodeJS.Timeout)

		setCurrentItem((currentItem) => {
			if (currentItem === 0) {
				return items.length - 1
			} else {
				return currentItem - 1
			}
		})
	}

	React.useEffect(() => {
		interval.current = setInterval(() => {
			setCurrentItem((currentItem) => {
				if (currentItem === items.length - 1) {
					return 0
				} else {
					return currentItem + 1
				}
			})
		}, 4000)
		return (): void => clearInterval(interval.current as NodeJS.Timeout)
	}, [items.length])

	return (
		<div className={[css['carousel'], mode === 'article' ? css['carousel-article'] : ''].join(' ')}>
			<div className={css['carousel-badge']}>{i18next.t('pages.portal.featureHighlight')}</div>
			<div
				className={css['carousel-item']}
				onClick={(): string => (window.location.href = items[currentItem].url)}
				style={{
					backgroundImage: `url(${items[currentItem].image})`,
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover'
				}}
			>
				{mode === 'default' && items[currentItem].title && (
					<div className={mode === 'default' ? css['carousel-item-title'] : css['carousel-item-title-article']}>
						{i18next.t(items[currentItem].title)}
					</div>
				)}
			</div>

			{items.length > 1 && (
				<div className={css['carousel-arrow']}>
					<div className={css['carousel-arrow-left']} onClick={handlePrevious}>
						<ChevronLeftIcon />
					</div>
					<div className={css['carousel-arrow-right']} onClick={handleNext}>
						<ChevronRightIcon />
					</div>
				</div>
			)}
		</div>
	)
}

export default FeaturesCarousel
