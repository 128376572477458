import { CheckCircleOutlineIcon } from '../../../../Icons'
import * as React from 'react'

export const StrengthIndicator = ({ text, applied }: { text: string; applied: boolean }): React.ReactElement => (
	<div style={{ color: 'black', display: 'flex', alignItems: 'center', width: '100%' }}>
		<CheckCircleOutlineIcon style={applied ? { fill: '#06c270' } : { opacity: '0.3' }} />
		<div style={{ color: '#4F5864', marginLeft: '5px' }}>{text}</div>
	</div>
)

export const strengthTranslations = {
	has8Characters: 'pages.login.has8Characters',
	hasSmallLetter: 'pages.login.hasSmallLetter',
	hasCapitalLetter: 'pages.login.hasCapitalLetter',
	hasNonLetter: 'pages.login.hasNonLetter',
	hasNumber: 'pages.login.hasNumber'
} as const
