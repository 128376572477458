import { ApplicationState } from 'store'
import { useSelector, useDispatch } from 'react-redux'
import { getRandomId } from '@lynx/core/src/string'
import { UploadingItem, addUploadingItem, deleteUploadingItem, refreshDrivePath, updateDriveSize, updateUploadingItem } from 'store/drive'
import { CancelToken } from '@lynx/client-core/src/api/BaseCall'
import { Logger } from '@lynx/client-core/src/modules'
import { APIRequest } from '@lynx/client-core/src/api'
import { useDebounce } from '@lynx/client-core/src/hooks'
import { useState, useEffect } from 'react'
import { DataLakeServiceClient } from '@azure/storage-file-datalake'
import { resizeImage } from '@lynx/client-core/src/utils'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import { extractResponseError } from '@lynx/core'
import { DRIVE_SIZE_LIMIT_EXCEEDED } from '@lynx/core/src/interfaces/ServerErrors'

export const useProviderUpload = (): { upload: (file: File) => Promise<void> } => {
	const { drive: driveState, profile: profileState } = useSelector((state: ApplicationState) => state)
	const { provider, currentPath } = driveState
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const [refreshItemsCount, setRefreshItemsCount] = useState<number>(0)
	const queryParams = new URLSearchParams(location.search)
	const driveId = queryParams.get('driveId') || ''

	const refreshDrivePathDebounce = useDebounce(async (count: number) => {
		await dispatchThunk(refreshDrivePath(count))
		setRefreshItemsCount(0)
	}, 700)

	useEffect(() => {
		if (refreshItemsCount) {
			refreshDrivePathDebounce(refreshItemsCount)
		}
	}, [refreshItemsCount])

	const uploadProgress = (id: string, fileSize: number, progressBytes: number): void => {
		const percentage = Math.floor((progressBytes / fileSize) * 100)
		const percentageLeft = percentage > 100 ? 100 : percentage
		dispatch(updateUploadingItem({ id, progress: percentageLeft, status: percentageLeft === 100 ? 'processing' : 'uploading' }))
	}

	const providerUpload: { [key: string]: (file: File, initialUploadedItem: UploadingItem) => Promise<void> } = {
		lynxcloud: async (file: File, initialUploadedItem: UploadingItem): Promise<void> => {
			const getThumbnail = async (file: File): Promise<File | null> => {
				try {
					return await resizeImage(file, 300)
				} catch (e) {
					Logger.error(e)
					return null
				}
			}

			if (!driveState.provider || !file) return

			const abortSignal = {
				aborted: false,
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				addEventListener: (): void => {},
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				removeEventListener: (): void => {}
			}
			const uploadCancelHandler = (): void => {
				abortSignal.aborted = true
			}

			dispatch(addUploadingItem({ ...initialUploadedItem, uploadCancelHandler }))
			const providerContainerId = driveId || profileState.userId

			const { url, fileName } = await APIRequest.ObjectStore.preUpload(
				{ provider: 'lynxcloud', providerContainerId },
				{ fileName: file.name, fileSizeBytes: String(file.size) }
			)

			const fileData = await file.arrayBuffer()
			const buffer = Buffer.from(fileData)

			const datalakeServiceClient = new DataLakeServiceClient(url)
			const fileSystemClient = datalakeServiceClient.getFileSystemClient(profileState.userId)
			const directoryClient = fileSystemClient.getDirectoryClient(decodeURIComponent(atob(driveState.currentPath.id)))
			const fileClient = directoryClient.getFileClient(fileName)

			await fileClient.upload(buffer, {
				onProgress: (progress: { loadedBytes: number }) => uploadProgress(initialUploadedItem.id, file.size, progress.loadedBytes),
				abortSignal: abortSignal,
				pathHttpHeaders: {
					contentType: file.type
				}
			})

			await APIRequest.ObjectStore.postUpload(
				{ provider: driveState.provider, providerContainerId },
				{ fileName, path: driveState.currentPath.id, thumbnail: await getThumbnail(file) }
			)
		},
		default: async (file: File, initialUploadedItem: UploadingItem): Promise<void> => {
			if (!provider) return
			let uploadCancelHandler: null | ((msg?: string) => void) = null

			const axiosConfig = {
				onUploadProgress: (progressEvent: { loaded: number }): void => uploadProgress(initialUploadedItem.id, file.size, progressEvent.loaded),
				cancelToken: new CancelToken((cancel: (msg?: string) => void) => {
					uploadCancelHandler = cancel
				})
			}

			const uploadQueryParams = {
				...(driveId ? { providerContainerId: driveId } : {}),
				toastOnSuccess: false
			}

			dispatch(addUploadingItem({ ...initialUploadedItem, uploadCancelHandler }))
			await APIRequest.ObjectStore.upload(provider, file, currentPath.id, 0, uploadQueryParams, axiosConfig)
		}
	}

	const upload = async (file: File, isDefaultUpload?: boolean): Promise<void> => {
		const refId = getRandomId()
		const selectedProvider = driveState.provider
		const uploadStrategy = isDefaultUpload ? providerUpload.default : (selectedProvider && providerUpload[selectedProvider]) || providerUpload.default

		const initialUploadedItem: UploadingItem = {
			name: file.name,
			id: refId,
			progress: 0,
			size: file.size,
			status: 'uploading',
			uploadCancelHandler: null
		}

		try {
			await uploadStrategy(file, initialUploadedItem)
			dispatch(updateUploadingItem({ id: refId, status: 'completed' }))
			setRefreshItemsCount((prev) => prev + 1)
		} catch (err) {
			const responseError = extractResponseError(err)
			const isDriveSizeExceededError = responseError?.name === DRIVE_SIZE_LIMIT_EXCEEDED

			const isFallbackUpload = Boolean(selectedProvider && providerUpload[selectedProvider])
			if (isFallbackUpload && !isDriveSizeExceededError) {
				dispatch(deleteUploadingItem(refId))
				await upload(file, true)

				return
			}
			dispatch(updateUploadingItem({ id: refId, status: 'failed', message: isDriveSizeExceededError ? responseError.message : '' }))
			Logger.error(err)
		}
	}

	return {
		upload
	}
}
