import { Dropdown } from '@lynx/client-core/src/components'
import { useState } from 'react'
import css from './CurrencyDropdown.module.scss'
import { currencySource as currenciesList } from '@lynx/core/src/constants'
export interface Currency {
	name: string
	url: string
	code: string
	height: number
}
interface CurrencyDropdownProps {
	onItemClicked: (code: string) => void
	currencyCode: string
	currenciesList?: typeof currenciesList
	disabled?: boolean
}

export const CurrencyDropdown = ({ onItemClicked, currencyCode, currenciesList: currenciesListProps, disabled }: CurrencyDropdownProps): React.ReactElement => {
	const currenciesData = currenciesListProps || currenciesList
	const [selectedCurrency, setSelectedCurrency] = useState<Currency>(currenciesData.find((c) => c.code === currencyCode) || currenciesData[0])

	const handleCurrencyClicked = (currency: Currency): void => {
		setSelectedCurrency(currency)
		onItemClicked(currency.code)
	}

	return (
		<div className={css['container']}>
			<img src={selectedCurrency.url} height={selectedCurrency.height} />
			<Dropdown
				disabled={disabled}
				variant="light"
				justify="left"
				title={selectedCurrency.name}
				className={css['dropdown']}
				openedClassName={css['dropdown--opened']}
				chevron={true}
			>
				{currenciesData.map((currency, index: number) => {
					const { name, url, height } = currency
					return (
						<li key={index} className={css['item']} onClick={(): void => handleCurrencyClicked(currency)}>
							<img src={url} height={height} />
							<span>{name}</span>
						</li>
					)
				})}
			</Dropdown>
		</div>
	)
}
