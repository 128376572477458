import { Reducer } from 'redux'
import { initialLayoutState } from './initialState'
import { LayoutActionTypes, LayoutState, UPDATE_LAYOUT_STATE } from './types'

const reducer: Reducer<LayoutState, LayoutActionTypes> = (state: LayoutState = initialLayoutState, action: LayoutActionTypes) => {
	switch (action.type) {
		case UPDATE_LAYOUT_STATE: {
			return {
				...state,
				...action.payload
			}
		}
		default: {
			return state
		}
	}
}

export { reducer as layoutReducer }
