import { DriveFolderItem, DriveItemType, DrivePath, ClientProviders } from '@lynx/client-core/src/api/interfaces'
import { ClearDriveAction } from '@lynx/client-core/src/store/drive/types'
import { DriveSize, ProvidersObject, Favourite, ProfileDriveItem } from '@lynx/core/src/interfaces/ObjectStore'

export * from '@lynx/client-core/src/store/drive/types'

export const UPDATE_NEXT_PAGE_STATE = '@@drive/UPDATE_NEXT_PAGE_STATE'
export const CHANGE_DRIVE = '@@drive/CHANGE_DRIVE'
export const SET_HOME_DRIVE = '@@drive/SET_HOME_DRIVE'
export const PUSH_DRIVE_PATH = '@@drive/PUSH_DRIVE_PATH'
export const POP_DRIVE_PATH = '@@drive/POP_DRIVE_PATH'
export const REFRESH_DRIVE_PATH = '@@drive/REFRESH_DRIVE_PATH'
export const DRIVE_RESPONSE = '@@drive/DRIVE_RESPONSE'
export const CHANGE_DRIVE_STYLE = '@@drive/CHANGE_DRIVE_STYLE'
export const SELECT_DRIVEITEM = '@@drive/SELECT_ITEM'
export const MAKE_DRIVE_READONLY = '@@drive/MAKE_DRIVE_READONLY'
export const MAKE_DRIVE_WRITEABLE = '@@drive/MAKE_DRIVE_WRITEABLE'
export const SHOW_DRIVE_BROWSER = '@@drive/SHOW_DRIVE_BROWSER'
export const HIDE_DRIVE_BROWSER = '@@drive/HIDE_DRIVE_BROWSER'
export const DESELECT_DRIVEITEM = '@@drive/DESELECT_DRIVEITEM'
export const UPDATE_DRIVE_ITEMS = '@@drive/UPDATE_DRIVE_ITEMS'
export const UPDATE_DRIVE_ITEM = '@@drive/UPDATE_DRIVE_ITEM'
export const DELETE_DRIVE_ITEM = '@@drive/DELETE_DRIVE_ITEM'
export const ADD_UPLOADING_ITEM = '@@drive/ADD_UPLOADING_ITEM'
export const UPDATE_UPLOADING_ITEMS = '@@drive/UPDATE_UPLOADING_ITEMS'
export const UPDATE_UPLOADING_ITEM = '@@drive/UPDATE_UPLOADING_ITEM'
export const DELETE_UPLOADING_ITEM = '@@drive/DELETE_UPLOADING_ITEM'
export const UPDATE_DRIVE_SIZE = '@@drive/UPDATE_DRIVE_SIZE'
export const SET_DRIVES_SIZES = '@@drive/SET_DRIVES_SIZES'
export const SET_FAVOURITES = '@@drive/SET_FAVOURITES'
export const ADD_FAVOURITE = '@@drive/ADD_FAVOURITE'
export const REMOVE_FAVOURITE = '@@drive/REMOVE_FAVOURITE'
export const SET_DRIVES = '@@drive/SET_DRIVE'
export const SET_DISALLOWED_LINK_PROVIDERS = '@@drive/SET_DISALLOWED_LINK_PROVIDERS'

export interface DriveState {
	drives: { visible: ProfileDriveItem[]; hidden: ProfileDriveItem[] }
	homeDrives: DriveFolderItem | null
	readonly response: {
		readonly OK: boolean
		readonly message: string
	}
	readonly provider: ClientProviders
	readonly currentPath: {
		readonly name: string
		readonly id: string
	}
	readonly paths: DrivePath[]
	readonly data: {
		id: string
		name: string
		children: DriveItemType[]
		nextPageToken?: string
	}
	readonly selectedDriveItem: DriveItemType | null
	readonly style: 'grid' | 'table'
	readonly writeable: boolean
	readonly popup: boolean
	readonly isNextPageLoading: boolean
	readonly loadingItemsCount: number
	readonly drivesSizes: DriveSize[]
	readonly uploadingItems: UploadingItem[]
	readonly providerDetails: ProvidersObject
	readonly favourites: Favourite[]
	readonly disallowedLinkProviders: null | string[]
}

export interface UploadingItem {
	name: string
	id: string
	// bytes
	size: number
	progress: null | number
	status: 'completed' | 'failed' | 'processing' | 'uploading'
	message?: string
	uploadCancelHandler: null | ((msg?: string) => void)
}
interface ChangeDriveAction {
	type: typeof CHANGE_DRIVE
	payload: {
		provider: ClientProviders
		data: DriveFolderItem
		driveStyle: 'grid' | 'table'
	}
}

interface SetHomeDriveAction {
	type: typeof SET_HOME_DRIVE
	payload: {
		homeDrives: DriveFolderItem
		providerDetails: ProvidersObject
	}
}

interface DriveResponseAction {
	type: typeof DRIVE_RESPONSE
	payload: {
		provider: ClientProviders
		OK: boolean
		message: string
	}
}

interface MakeDriveReadonlyAction {
	type: typeof MAKE_DRIVE_READONLY
}

interface MakeDriveWriteableAction {
	type: typeof MAKE_DRIVE_WRITEABLE
}

interface ShowDriveBrowserAction {
	type: typeof SHOW_DRIVE_BROWSER
}

interface HideDriveBrowserAction {
	type: typeof HIDE_DRIVE_BROWSER
}

interface DeselectDriveItemAction {
	type: typeof DESELECT_DRIVEITEM
}

interface PushDrivePathAction {
	type: typeof PUSH_DRIVE_PATH
	payload: {
		provider: ClientProviders
		data: DriveFolderItem
	}
}

interface PopDrivePathAction {
	type: typeof POP_DRIVE_PATH
	payload: {
		provider: ClientProviders
		data: DriveFolderItem
		paths: DrivePath[]
	}
}

interface SelectDriveItemAction {
	type: typeof SELECT_DRIVEITEM
	payload: {
		driveItem: DriveItemType
	}
}

interface RefreshDrivePathAction {
	type: typeof REFRESH_DRIVE_PATH
	payload: {
		provider: ClientProviders
		data: DriveFolderItem
	}
}

interface UpdateDriveItemsAction {
	type: typeof UPDATE_DRIVE_ITEMS
	payload: Partial<DriveFolderItem>
}

interface UpdateDriveItemAction {
	type: typeof UPDATE_DRIVE_ITEM
	payload: { item: Partial<DriveItemType> & { id: string }; originalId: string }
}
interface DeleteDriveItemAction {
	type: typeof DELETE_DRIVE_ITEM
	payload: { id: string }
}

interface ChangeDriveStyleAction {
	type: typeof CHANGE_DRIVE_STYLE
	payload: 'grid' | 'table'
}

interface UpdateNextPageStateAction {
	type: typeof UPDATE_NEXT_PAGE_STATE
	payload: {
		isNextPageLoading: boolean
		loadingItemsCount: number
	}
}
interface UpdateUploadingItem {
	type: typeof UPDATE_UPLOADING_ITEM
	payload: Partial<UploadingItem> & { id: UploadingItem['id'] }
}
interface UpdateUploadingItems {
	type: typeof UPDATE_UPLOADING_ITEMS
	payload: UploadingItem[]
}
interface AddUploadingItems {
	type: typeof ADD_UPLOADING_ITEM
	payload: UploadingItem
}
interface DeleteUploadingItems {
	type: typeof DELETE_UPLOADING_ITEM
	payload: { id: string }
}

interface UpdateDriveSizeAction {
	type: typeof UPDATE_DRIVE_SIZE
	payload: DriveSize
}
interface SetDriveSizeAction {
	type: typeof SET_DRIVES_SIZES
	payload: DriveSize[]
}

interface SetDrivesAction {
	type: typeof SET_DRIVES
	payload: { visible: ProfileDriveItem[]; hidden: ProfileDriveItem[] }
}

interface AddFavouritesAction {
	type: typeof ADD_FAVOURITE
	payload: Favourite
}

interface RemoveFavouritesAction {
	type: typeof REMOVE_FAVOURITE
	payload: Favourite
}
interface SetFavouritesAction {
	type: typeof SET_FAVOURITES
	payload: Favourite[]
}

interface SetDisallowedLinkProvidersAction {
	type: typeof SET_DISALLOWED_LINK_PROVIDERS
	payload: string[]
}

export type DriveActionTypes =
	| ChangeDriveStyleAction
	| DriveResponseAction
	| ChangeDriveAction
	| PushDrivePathAction
	| PopDrivePathAction
	| RefreshDrivePathAction
	| DeselectDriveItemAction
	| SelectDriveItemAction
	| MakeDriveReadonlyAction
	| MakeDriveWriteableAction
	| ShowDriveBrowserAction
	| HideDriveBrowserAction
	| SetHomeDriveAction
	| ClearDriveAction
	| UpdateDriveItemsAction
	| UpdateDriveItemAction
	| UpdateNextPageStateAction
	| DeleteDriveItemAction
	| UpdateUploadingItem
	| UpdateUploadingItems
	| DeleteUploadingItems
	| AddUploadingItems
	| UpdateDriveSizeAction
	| SetDriveSizeAction
	| AddFavouritesAction
	| RemoveFavouritesAction
	| SetFavouritesAction
	| SetDrivesAction
	| SetDisallowedLinkProvidersAction
