import { Badge, DeleteIcon, DisableIcon, FreeBadgeIcon, LoadingSpinner, PersonAddIcon, ProBadgeIcon, Table, UserPro } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { showModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { UserDeleteModalContextType } from 'pages/Organisations/modals/UserDeleteModal/UserDeleteModalContextType'
import { UserDisableModalContextType } from 'pages/Organisations/modals/UserDisableModal/UserDisableModalContextType'
import { UserEnableModalContextType } from 'pages/Organisations/modals/UserEnableModal/UserEnableModalContextType'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { updateOrgUser } from 'store/organisations'
import { EditUserEntryOverlay } from './EditUserEntryOverlay'
import css from './UsersTable.module.scss'
import { ResetOrganisationUserPasswordModalContextType } from 'pages/Organisations/modals/ResetOrganisationUserPasswordModal/ResetOrganisationUserPasswordModalContextType'
import { OrganisationUsersInterface } from '@lynx/core/src/interfaces/Organisation'

interface Props {
	canAddMoreProLicenses: boolean
	upgradeAllToProClicked?: () => Promise<void>
}
export const UsersTable = ({ canAddMoreProLicenses, upgradeAllToProClicked }: Props): React.ReactElement | null => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()
	const organisation = useCurrentOrganisation()

	const profile = useSelector((state: ApplicationState) => state.profile)
	if (!organisation) return null

	const handleRemoveUserClicked = ({ userId, email }: OrganisationUsersInterface): void => {
		dispatch(showModal({ name: 'UserDeleteModal', context: { type: UserDeleteModalContextType, userId, email } }))
	}

	const handleToggleDisableUserClicked = ({ disabled, userId, email }: OrganisationUsersInterface): void => {
		if (disabled) {
			dispatch(showModal({ name: 'UserEnableModal', context: { type: UserEnableModalContextType, userId, email } }))
			return
		}
		dispatch(showModal({ name: 'UserDisableModal', context: { type: UserDisableModalContextType, userId, email } }))
	}

	const handleToggleOwnerClicked = async ({ userId, isOwner }: OrganisationUsersInterface): Promise<void> => {
		await dispatchThunk(updateOrgUser(organisation.id, userId, { isOwner: !isOwner }))
	}

	const handleToggleProClicked = async ({ userId, isAssignedPro }: OrganisationUsersInterface): Promise<void> => {
		await dispatchThunk(updateOrgUser(organisation.id, userId, { isAssignedPro: !isAssignedPro }))
	}

	const handleResetPasswordClicked = ({ email, userId }: OrganisationUsersInterface): void => {
		dispatch(
			showModal({
				name: 'ResetOrganisationUserPasswordModal',
				context: { type: ResetOrganisationUserPasswordModalContextType, userId, organisationId: organisation.id, email }
			})
		)
	}

	const columns: TableHeaders = [
		{
			valueKey: 'displayName',
			label: 'NAME',
			cellAdditionalStyles: { alignItems: 'center' },
			customBodyRender: ({ displayName, isOwner }: OrganisationUsersInterface): React.ReactElement => {
				const displayNameText = displayName.includes('@') ? displayName.split('@')[0] : displayName || 'Unknown'
				return (
					<div className={css.displayNameCol}>
						<span>{displayNameText}</span>
						{isOwner && (
							<Badge variant={'teal'} size="xs">
								<div>Admin</div>
							</Badge>
						)}
					</div>
				)
			}
		},
		{
			valueKey: 'email',
			label: 'EMAIL',
			customBodyRender: (item: OrganisationUsersInterface): React.ReactElement => {
				return <div>{item.email}</div>
			},
			cellAdditionalStyles: { alignItems: 'center' }
		},
		{
			valueKey: 'disabled',
			label: '',
			customBodyRender: (item: OrganisationUsersInterface): React.ReactElement => {
				return <div className={css.iconCol}>{item.disabled && <DisableIcon />}</div>
			},
			cellAdditionalStyles: { alignItems: 'center' }
		},
		{
			valueKey: 'isPro',
			label: ' ',
			customBodyRender: (item: OrganisationUsersInterface): React.ReactElement => {
				return <div className={css.iconCol}>{organisation.isPro && item.isAssignedPro && <ProBadgeIcon />}</div>
			},
			cellAdditionalStyles: { alignItems: 'center' }
		},
		{
			valueKey: '__edit',
			label: '  ',
			customBodyRender: (item: OrganisationUsersInterface): React.ReactElement => {
				const { isOwner, email, isAssignedPro } = item

				const dropdownOptions: { visible: boolean; optionEl: () => React.ReactNode }[] = [
					{
						// remove user
						optionEl: () => (
							<li onClick={(): void => handleRemoveUserClicked(item)} className={css.dropdownIem}>
								<DeleteIcon className={css.icon} /> {t('pages.organisations.remove')}
							</li>
						),
						visible: organisation.isOwner && !isOwner
					},
					{
						// toggle owner
						optionEl: () => (
							<li onClick={(): Promise<void> => handleToggleOwnerClicked(item)} className={css.dropdownIem}>
								<UserPro /> {t('pages.organisations.toggleAdmin')}
							</li>
						),
						visible: organisation.isOwner && item.email !== profile.email
					},
					{
						// toggle disabled
						optionEl: () => (
							<li onClick={(): void => handleToggleDisableUserClicked(item)} className={css.dropdownIem}>
								<DisableIcon className={css.icon} /> {item.disabled ? t('pages.organisations.reinstate') : t('pages.organisations.disable')}
							</li>
						),
						visible: organisation.isOwner && !isOwner
					},
					{
						// reset password
						optionEl: () => (
							<li onClick={(): void => handleResetPasswordClicked(item)} className={css.dropdownIem}>
								{t('pages.organisations.resetPassword')}
							</li>
						),
						visible: isOwner && email !== profile.email
					},
					{
						// pro license option
						optionEl: (): React.ReactNode => {
							const isProToggleDisabled = !canAddMoreProLicenses && !isAssignedPro
							return (
								<li
									onClick={(): Promise<void> | boolean => !isProToggleDisabled && handleToggleProClicked(item)}
									className={[isProToggleDisabled && css.dropdownIemDisabled, css.dropdownIem].join(' ')}
								>
									{isAssignedPro ? <FreeBadgeIcon className={css.icon} /> : <ProBadgeIcon className={css.icon} />}
									{isAssignedPro ? t('components.userLicenseLimit.downgradeToFree') : t('components.userLicenseLimit.upgradeToPro')}
								</li>
							)
						},
						visible: organisation.isPro && organisation.isOwner
					}
				]

				const filteredOptions = dropdownOptions.filter((option) => option.visible)
				if (!filteredOptions.length) return <div />

				return (
					<div className={css['optionsContainer']}>
						<EditUserEntryOverlay>
							<ul className={css.list}>{filteredOptions.map(({ optionEl }) => optionEl())}</ul>
						</EditUserEntryOverlay>
					</div>
				)
			}
		}
	].filter((column) => column.valueKey !== '__edit' || (column.valueKey === '__edit' && organisation.isOwner))

	const handleInviteClicked = (): void => {
		dispatch(showModal({ name: 'InvitePeopleModal' }))
	}
	const filteredUsers = organisation.isOwner ? organisation.users : organisation.users.filter((user) => user.email === profile.email || user.isOwner)
	const toolBarItems: { component: JSX.Element }[] = organisation.isOwner
		? [
				{
					component: (
						<div onClick={handleInviteClicked}>
							<PersonAddIcon />
							<div>{t('pages.organisations.inviteUser')}</div>
						</div>
					)
				},
				{
					component: (
						<div
							className={!upgradeAllToProClicked ? css.dropdownIemDisabled : ''}
							onClick={(): Promise<void> | undefined => upgradeAllToProClicked && upgradeAllToProClicked()}
						>
							<ProBadgeIcon />
							<div className={css.upgradeAllToProText}>Upgrade all to Pro</div>
						</div>
					)
				}
		  ]
		: []

	return (
		<div>
			<Table
				gridTemplateOverride={{
					display: 'grid',
					gridTemplateColumns: `200px 1fr 85px 75px 75px `
				}}
				showPagination={true}
				headers={columns}
				items={filteredUsers}
				toolBarItems={toolBarItems}
			/>
		</div>
	)
}
