import { BasicButton, DeleteIcon, Input, Modal } from '@lynx/client-core/src/components'
import { hideModal, showModal } from '@lynx/client-core/src/store/modal'
import { string } from '@lynx/core'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { VerifyDomainModalContextType } from 'pages/Organisations/modals/VerifyDomainModal/VerifyDomainModalContextType'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { addEmailDomain, removeEmailDomain } from 'store/organisations'
import css from './ManageDomainsModal.module.scss'

export const ManageDomainsModal = (): React.ReactElement | null => {
	const organisation = useCurrentOrganisation()
	const [domain, setDomain] = useState<string>('')
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const [rejected, setRejected] = useState<boolean>(false)
	const [submitting, setSubmitting] = useState<boolean>(false)

	if (!organisation) return null

	const handleCloseClicked = (): void => {
		dispatch(hideModal())
	}

	const handleDomainChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setDomain(e.target.value)
	}

	const handleAddDomainClicked = async (): Promise<void> => {
		setSubmitting(true)
		setRejected(false)
		if (!string.isUrl(domain)) {
			setRejected(true)
			setSubmitting(false)
			return
		}

		const added = await dispatchThunk(addEmailDomain(organisation.id, domain))

		setRejected(!added)
		setDomain('')
		setSubmitting(false)
	}

	const handleRemoveDomainClicked = async (id: number): Promise<void> => {
		await dispatchThunk(removeEmailDomain(organisation.id, id))
	}

	const handleUnverifiedClicked = (domainId: number, emailDomain: string): void => {
		dispatch(showModal({ name: 'VerifyDomainModal', context: { type: VerifyDomainModalContextType, domainId, emailDomain } }))
	}

	return (
		<Modal name="ManageDomainsModal" title="Manage Email Domains">
			<div className={css['container']}>
				<div>
					<h4>{t('pages.organisations.allowedDomains')}</h4>
					<ul className={css['list']}>
						{organisation.domains.map(({ emailDomain, id, verified }) => (
							<li key={emailDomain}>
								<div>{emailDomain}</div>
								{!verified && (
									<BasicButton variant="danger" onClick={(): void => handleUnverifiedClicked(id, emailDomain)}>
										{t('pages.organisations.unverified')}
									</BasicButton>
								)}
								<DeleteIcon onClick={(): Promise<void> => handleRemoveDomainClicked(id)} />
							</li>
						))}
					</ul>
				</div>
				<div>
					<h4>{t('pages.organisations.addNewDomain')}</h4>
					<Input placeholder="organisation.co.uk" value={domain} onChange={handleDomainChanged} />
				</div>
				{rejected && <div className={css['rejected']}>{t('pages.organisations.domainNotAllowed')}</div>}
				<div className={css['container__buttons']}>
					<BasicButton onClick={handleCloseClicked}>{t('pages.organisations.close')}</BasicButton>
					<BasicButton variant="blue" onClick={handleAddDomainClicked} disabled={!string.isUrl(domain) || submitting}>
						{t('pages.organisations.addDomain')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
