import { MoreHorizIcon } from '@lynx/client-core/src/components'
import { Overlay } from '@lynx/client-core/src/components/Popup/Overlay'
import React from 'react'
import css from './EditInviteEntryOverlay.module.scss'

interface EditInviteOverlayProps {
	children: React.ReactNode
	icon?: React.ReactElement
}

export const EditInviteEntryOverlay = ({ children, icon = <MoreHorizIcon style={{ height: 20 }} /> }: EditInviteOverlayProps): React.ReactElement => {
	return (
		<Overlay icon={icon} title={'title'} className={css['dropdown']} popUpClassName={css['popup']} useMousePosition>
			{children}
		</Overlay>
	)
}
