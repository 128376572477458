import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { resolveTemplatedString } from '@lynx/core/src/utils'
import Axios from 'axios'
import { Logger } from '../modules/Logger'
import { AdditionalHeaders } from './interfaces'

export const { CancelToken } = Axios
export class BaseCall {
	protected authInterceptor!: number
	protected defaultHeaders!: number
	protected client: AxiosInstance
	protected baseUrlInterceptor!: number

	protected resolveTemplatedString = resolveTemplatedString

	constructor() {
		this.client = axios.create()

		this.client.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error) {
					if (error.response) {
						const { status, data } = error.response

						if (status === 401 && data === 'TokenExpired') window.location.href = '/auth/login'
					} else Logger.error(error)
				}
				return Promise.reject(error)
			}
		)
	}

	public setRequestInterceptor(...interceptor: Parameters<typeof axios.interceptors.response.use>): void {
		this.client.interceptors.response.use(...interceptor)
	}

	public setDefaultHeaders(additionalHeaders: AdditionalHeaders): void {
		this.client.interceptors.request.eject(this.defaultHeaders)
		this.defaultHeaders = this.client.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
			config.headers['lynx-application-name'] = 'lynx web'
			config.headers['lynx-application-version'] = '8.x.x'
			config.headers['screen-width'] = window.screen.width
			config.headers['screen-height'] = window.screen.height
			config.headers = { ...config.headers, ...additionalHeaders } as InternalAxiosRequestConfig['headers']

			return config
		})
	}

	public setAuthorization(token: string): void {
		this.client.interceptors.request.eject(this.authInterceptor)
		this.authInterceptor = this.client.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
			config.headers.Authorization = 'Bearer ' + token
			return config
		})
	}

	public clearAuthorization(): void {
		this.client.interceptors.request.eject(this.authInterceptor)
	}

	protected getContentDispositionHeader = (response: AxiosResponse): string => {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
		const matches = filenameRegex.exec(response.headers?.['content-disposition'] || '')

		if (!matches || !matches[1]) {
			return ''
		}

		return matches[1]
	}

	public setBaseUrl(baseURL: string): void {
		this.client.interceptors.request.eject(this.baseUrlInterceptor)
		this.baseUrlInterceptor = this.client.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
			config.baseURL = baseURL
			return config
		})
	}
}
