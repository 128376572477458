import { NotificationBellIcon } from '@lynx/client-core/src/components'
import { usePrevious } from '@lynx/client-core/src/hooks'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './NotificationsIcon.module.scss'

export const NotificationsIcon = ({ onClick }: { onClick?: () => void }): React.ReactElement => {
	const { notificationsData } = useSelector((state: ApplicationState) => state.notifications)
	const isAnyNotViewedNotification = notificationsData.some(({ isViewed }) => !isViewed)
	const [iconAnimation, setIconAnimation] = useState(false)
	const previousNotifications = usePrevious(notificationsData)

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>
		const bellAnimationTrigger = (): void => {
			const isFirstNotification = !previousNotifications && notificationsData.length
			const isNewNotificationComparingToLastOne =
				notificationsData.length && previousNotifications?.length && previousNotifications[0].id !== notificationsData[0].id

			if (isNewNotificationComparingToLastOne || isFirstNotification) {
				setIconAnimation(true)
				timer = setTimeout(() => setIconAnimation(false), 2000)
			} else {
				setIconAnimation(false)
			}
		}

		bellAnimationTrigger()
		return (): void => {
			clearTimeout(timer)
		}
	}, [notificationsData])
	return (
		<div
			className={`${css.bellContainer} ${iconAnimation && isAnyNotViewedNotification && css.bellAnimation}`}
			onClick={(): void => {
				onClick && onClick()
			}}
		>
			{isAnyNotViewedNotification && <div className={css.bellDot} />}
			<NotificationBellIcon />
		</div>
	)
}
