import * as Yup from 'yup'
import { useState } from 'react'
import { CodeGenFormErrors, CodeGenFormFields, CodeGenItem, CodeGenState, UseCodeGenHook } from './interfaces'
import { APIRequest } from '@lynx/client-core/src/api'
import { formatDateOrString } from '@lynx/core/src/date'
import { Logger } from '@lynx/client-core/src/modules'

const initialValues: Partial<CodeGenFormFields> = {
	prefix: 'CPS',
	versionNumber: 0,
	licenseType: 'OEM',
	startNumber: 0,
	numberRequired: 0,
	purchaseOrderNumber: 0,
	who: ''
}

const validationSchema = Yup.object().shape({
	versionNumber: Yup.number().moreThan(0).required('Required > 0'),
	startNumber: Yup.number().required('Required'),
	numberRequired: Yup.number().moreThan(0).required('Required > 0'),
	who: Yup.string().required()
})

export function useCodeGen(): UseCodeGenHook {
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [state, setState] = useState<CodeGenState>({
		generatedKeys: [],
		submissionData: []
	})

	const [values, setValues] = useState<Partial<CodeGenFormFields>>({
		...initialValues
	})

	const [touched, setTouched] = useState<CodeGenFormErrors>({
		versionNumber: false,
		startNumber: false,
		numberRequired: false,
		who: false
	})

	const [errors, setErrors] = useState<CodeGenFormErrors>({
		versionNumber: null,
		startNumber: null,
		numberRequired: null,
		who: null
	})

	const resetValues = (): void => {
		setValues({
			...initialValues
		})
	}

	const setFieldValue = (field: keyof CodeGenFormFields, value: string | number | undefined): void => {
		setValues({ ...values, [field]: value })
	}

	const setFieldTouched = (field: keyof CodeGenFormFields, value: boolean): void => {
		setTouched({ ...touched, [field]: value })
		handleValidation({ ...values })
	}

	const setFieldError = (field: keyof CodeGenFormFields, value: boolean): void => {
		setErrors({ ...errors, [field]: value })
	}

	const handleChange = (field: keyof CodeGenFormFields, value: string | number | undefined): void => {
		setFieldValue(field, value)
		setFieldError(field, false)
		handleValidation({ ...values, [field]: value })
	}

	const fetchData = async (): Promise<void> => {
		const data = await APIRequest.CodeGen.getCodeGen()
		const { maxVersion, nextSeq, generatedKeys } = data

		setValues({
			versionNumber: maxVersion,
			startNumber: nextSeq
		})

		setState((currentState) => ({
			...currentState,
			generatedKeys: generatedKeys.map((row, index) => {
				return {
					index: index,
					Date: formatDateOrString(row.GenDate, 'YYYY-MM-DD HH:mm'),
					'First Key': row.CombinedKey,
					Who: row.Who,
					'PO Number': row.PONumber,
					Count: row.keyCount
				}
			}),
			submissionData: generatedKeys.map((row, index) => {
				return {
					index: index,
					startKey: row.startKey,
					licenseType: row.licenseType,
					lynxVersion: row.lynxVersion,
					keyCount: row.keyCount,
					Prefix: row.Prefix,
					PONumber: row.PONumber
				}
			})
		}))
	}

	const handleGenerateClicked = async (item: CodeGenItem): Promise<void> => {
		const submissionData = state.submissionData.find((data) => data.index === item.index)

		if (!submissionData) {
			return Logger.error('invalid submission data')
		}

		await APIRequest.Logs.getKeys(submissionData)
	}

	const handleValidation = async (currentValues: Partial<CodeGenFormFields>): Promise<boolean> => {
		const isFormValid = await validationSchema.isValid(values, {
			abortEarly: false // Prevent aborting validation after first error
		})

		if (isFormValid) {
			return true
		}

		validationSchema.validate(currentValues, { abortEarly: false }).catch((err) => {
			const formErrors = err.inner.reduce((acc: CodeGenFormErrors, error: Yup.ValidationError) => {
				if (error.path) {
					return {
						...acc,
						[error.path]: error.message
					}
				} else {
					return acc
				}
			}, {})

			setErrors((prevErrors) => ({
				...prevErrors,
				...formErrors
			}))
		})

		throw new Error('Form is invalid')
	}

	const handleSaveClicked = async (data: Partial<CodeGenItem>): Promise<void> => {
		const result = await APIRequest.CodeGen.postCodeGen(data)
		await fetchData()
		if (result.status === 200) {
			alert('Saved, Please find user in table below')
		} else {
			alert('Something has gone wrong!')
		}
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		try {
			event.preventDefault()

			setIsSubmitting(true)

			setTouched({
				versionNumber: true,
				startNumber: true,
				numberRequired: true,
				who: true
			})

			await handleValidation(values)

			await handleSaveClicked({
				PONumber: values.purchaseOrderNumber,
				keyCount: values.numberRequired,
				licenseType: values.licenseType,
				lynxVersion: String(values.versionNumber),
				prefix: values.prefix,
				startKey: String(values.startNumber),
				who: values.who
			})

			setIsSubmitting(false)
			resetValues()
			fetchData()
		} catch (error) {
			setIsSubmitting(false)
		}
	}

	return {
		fetchData,
		handleGenerateClicked,
		handleSubmit,
		handleChange,
		setFieldTouched,
		setFieldValue,
		isSubmitting,
		initialValues,
		errors,
		touched,
		values,
		state
	}
}
