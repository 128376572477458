import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Input, Modal } from '@lynx/client-core/src/components'
import { ErrorMessage, Form, Formik } from 'formik'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { hideModal } from 'store/modal'
import { object, string } from 'yup'
import css from './NextcloudConfigurationModal.module.scss'

export const nextcloudOrganisationSchema = object({
	domain: string().url().required('Domain is a required field')
})

export const NextcloudConfigurationModal = (): ReactElement => {
	const dispatch = useDispatch()
	const organisation = useCurrentOrganisation()
	const { t } = useTranslation()

	const handleFormSubmitted = async ({ domain }: { domain: string }): Promise<void> => {
		if (!organisation) return
		await APIRequest.Organisations.updateOrganisationNextCloud(organisation.id, domain)
		dispatch(hideModal())
	}

	return (
		<Modal name="NextcloudConfigurationModal" title={t('pages.organisation.nextcloudSettings')}>
			<Formik
				initialValues={{ domain: organisation?.nextCloudDomain || '' }}
				validationSchema={nextcloudOrganisationSchema}
				onSubmit={(values, actions): void => {
					handleFormSubmitted(values)
					actions.setSubmitting(false)
				}}
				enableReinitialize={true}
			>
				{({ isSubmitting, handleChange, values, errors, touched, isValid, dirty }): React.ReactNode => (
					<Form className={css['container']}>
						<div>
							<label htmlFor="domain">{t('pages.organisation.domain')}</label>
							<Input
								autoFocus={true}
								value={values.domain}
								type="search"
								name="domain"
								onChange={handleChange}
								inError={(errors.domain && touched.domain) || false}
								placeholder="https://..."
							/>
							<ErrorMessage name="domain" component="span" />
						</div>
						<div className={css['buttons']}>
							<BasicButton
								onClick={(): void => {
									dispatch(hideModal())
								}}
							>
								{t('pages.organisations.createModal.back')}
							</BasicButton>
							<BasicButton variant={isValid ? 'blue' : 'transparent'} disabled={isSubmitting || !dirty} type="submit">
								{t('pages.organisations.save')}
							</BasicButton>
						</div>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}
