import { APIRequest } from '@lynx/client-core/src/api'
import { string } from '@lynx/core'
import { INotification } from '@lynx/core/src/interfaces/Notifications'
import { Action, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ApplicationState } from 'store'
import {
	NotificationsActionTypes,
	NotificationToast,
	SET_NOTIFICATIONS,
	NotificationsState,
	UPDATE_NOTIFICATIONS,
	PUSH_NOTIFICATION_TOAST,
	DELETE_NOTIFICATION_TOAST,
	DELETE_NOTIFICATION
} from './types'

export const setNotifications = (notifications: NotificationsState['notificationsData']): NotificationsActionTypes => {
	return {
		type: SET_NOTIFICATIONS,
		payload: notifications
	}
}

export const updateNotifications = (notifications: NotificationsState['notificationsData']): NotificationsActionTypes => {
	return {
		type: UPDATE_NOTIFICATIONS,
		payload: notifications
	}
}

export const getNotifications =
	(count = 6): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<void> => {
		const res = await APIRequest.Notifications.getNotifications(count)
		if (res && res.data) {
			const notifications = res.data
			dispatch(setNotifications(notifications))
		}
	}

export const setNotificationViewed =
	(notification: INotification): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch): Promise<void> => {
		await APIRequest.Notifications.updateNotification({ id: notification.id, isViewed: notification.isViewed })
		dispatch(updateNotifications([notification]))
	}

const pushNotificationToastInternal = (notificationToast: NotificationToast): NotificationsActionTypes => {
	return {
		type: PUSH_NOTIFICATION_TOAST,
		payload: notificationToast
	}
}

export const deleteNotificationToast = (notificationToast: NotificationToast): NotificationsActionTypes => {
	return {
		payload: notificationToast,
		type: DELETE_NOTIFICATION_TOAST
	}
}

export const deleteNotification = (id: number): NotificationsActionTypes => {
	return {
		payload: { id },
		type: DELETE_NOTIFICATION
	}
}

export const pushNotification =
	(notification: NotificationToast, fetchNotification = true): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		const notificationToast = { ...notification, toastId: string.getRandomId() }
		const { notificationId } = notification
		dispatch(pushNotificationToastInternal(notificationToast))

		if (notificationId && fetchNotification) {
			const newNotificationData = await APIRequest.Notifications.getNotification(notificationId)
			const notifications = getState().notifications.notificationsData
			newNotificationData && dispatch(setNotifications([...notifications, newNotificationData]))
		}
	}
