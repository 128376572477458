import { APIRequest } from '@lynx/client-core/src/api'
import { AddIcon, Button, Document, Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { useDebounce } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { Site } from '@lynx/core/src/interfaces/Sites'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import Styles from './Sites.module.scss'

interface RenderFieldType {
	item: Site
	field: keyof Site
	isEditing: boolean
	type?: 'string' | 'number' | 'boolean'
	onChange: (field: string, v: unknown) => void
}

const RenderField = ({ item, field, isEditing, type = 'string', onChange }: RenderFieldType): JSX.Element | null => {
	const [value, setValue] = useState<unknown>(item[field])

	const handleChange = (v: unknown): void => {
		setValue(v)
		onChange(field, v)
	}

	useEffect(() => {
		setValue(item[field])
	}, [])

	if (isEditing) {
		if (type === 'boolean')
			return (
				<div className={Styles.booleanContainer}>
					<Button onClick={(): void => handleChange(true)} variant={value ? 'blue' : 'grey'}>
						Yes
					</Button>
					<Button onClick={(): void => handleChange(false)} variant={value ? 'grey' : 'blue'}>
						No
					</Button>
				</div>
			)

		if (type === 'string' || type === 'number') {
			const isNumber = typeof item[field] === 'number'
			return (
				<input
					name={field}
					type={type}
					className={isNumber ? Styles['inputNumber'] : Styles['inputText']}
					value={String(value)}
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChange(e.target.value)}
				/>
			)
		}
	}

	return <div className={Styles['truncate']}>{String(value)}</div>
}

export const Sites = (): React.ReactElement => {
	const [sites, setSites] = useState<Site[]>([])

	const [editedSite, setEditedSite] = useState<Site | null>(null)
	const [currentSite, setCurrentSite] = useState<Site | null>(null)

	const handleDelete = async (item: Site): Promise<void> => {
		if (confirm(`Are you sure you want to delete "${item.key}"?`)) {
			setSites([])
			await APIRequest.Sites.deleteSite(item.key)
			await fetchData()
		}
	}

	const handleSave = async (item: Site): Promise<void> => {
		if (!currentSite) return
		await APIRequest.Sites.updateSite(item.key, { ...currentSite })
		const updatedSites = sites.map((s) => (s.key === item.key ? { ...s, ...currentSite } : s))
		setSites(updatedSites)
		setEditedSite(null)
	}

	const handleEdit = (item: Site): void => {
		setEditedSite(item)
		setCurrentSite(item)
	}

	const handleCancel = (): void => {
		setEditedSite(null)
		setCurrentSite(null)
	}

	const handleChange = (key: string, v: unknown): void => {
		if (!currentSite) return
		setCurrentSite({ ...currentSite, [key]: v })
	}

	const columns: TableHeaders = [
		{
			valueKey: 'key',
			label: 'Key',
			customBodyRender: (item) => <RenderField item={item} field="key" onChange={handleChange} isEditing={editedSite?.key === item.key} />
		},
		{
			valueKey: 'url',
			label: 'Url',
			customBodyRender: (item) => <RenderField item={item} field="url" onChange={handleChange} isEditing={editedSite?.key === item.key} />
		},
		{
			valueKey: 'isAuthRequired',
			label: 'Auth Required',
			customBodyRender: (item) => (
				<RenderField type="boolean" item={item} field="isAuthRequired" onChange={handleChange} isEditing={editedSite?.key === item.key} />
			)
		},
		{
			valueKey: 'iconUrl',
			label: 'Icon Url',
			customBodyRender: (item) => <RenderField item={item} field="iconUrl" onChange={handleChange} isEditing={editedSite?.key === item.key} />
		},
		{
			valueKey: 'iconWidth',
			label: 'Icon Width',
			customBodyRender: (item) => (
				<RenderField item={item} field="iconWidth" type="number" onChange={handleChange} isEditing={editedSite?.key === item.key} />
			)
		},
		{
			valueKey: 'iconHeight',
			label: 'Icon Height',
			customBodyRender: (item) => (
				<RenderField item={item} field="iconHeight" type="number" onChange={handleChange} isEditing={editedSite?.key === item.key} />
			)
		},
		{
			valueKey: '__delete',
			label: 'Delete',
			customBodyRender: (item) =>
				editedSite?.key === item.key ? (
					<div className={Styles['actions']}>
						<div className={Styles['link-action']} onClick={(): Promise<void> => handleSave(item)}>
							Save
						</div>
						<div className={Styles['spacer']}>|</div>
						<div className={Styles['link-action']} onClick={handleCancel}>
							Cancel
						</div>
					</div>
				) : (
					<div className={Styles['actions']}>
						<div className={Styles['link-action']} onClick={(): void => handleEdit(item)}>
							Edit
						</div>
						<div className={Styles['spacer']}>|</div>
						<div className={Styles['link-action']} onClick={(): Promise<void> => handleDelete(item)}>
							Delete
						</div>
					</div>
				)
		}
	]

	const fetchData = useDebounce(async () => {
		try {
			const responseData = await APIRequest.Sites.getAll()
			setSites(responseData.data || [])
		} catch (err) {
			Logger.error(err)
		}
	}, 300)

	const handleAddSite = async (): Promise<void> => {
		try {
			setSites([])
			await APIRequest.Sites.createSite({
				key: 'new-site' + Math.random(),
				url: 'https://lynxcloud.app',
				iconUrl: 'https://lynxcloud.app/favicon.ico',
				iconWidth: 0,
				iconHeight: 0,
				isAuthRequired: false
			})
			await fetchData()
		} catch (err) {
			Logger.error(err)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<Document title="Sites" keywords="Sites" auth={true} disableOverflow={true}>
			<Card className={Styles['no-user-selection']}>
				<Table
					initialPageSizeIndex={3}
					title={'Sites'}
					headers={columns}
					items={sites}
					isCustomSearchRender={false}
					totalCount={sites.length}
					toolBarItems={[
						{
							component: (
								<div onClick={handleAddSite}>
									<AddIcon />
									<div>Add Site</div>
								</div>
							)
						}
					]}
				/>
			</Card>
		</Document>
	)
}
