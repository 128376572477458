import { useEffect, useState } from 'react'
import css from './OrganisationDrivesAccess.module.scss'
import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import { ProBadgeIcon, Toggle } from '@lynx/client-core/src/components'
import { APIRequest } from '@lynx/client-core/src/api'
import { useDispatch } from 'react-redux'
import { updateOrganisationData } from 'store/organisations'
import { disallowedProvidersMap, providersData } from '@lynx/core/src/constants'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { setDisallowedLinkProviders } from 'store/drive'
import { useTranslation } from 'react-i18next'

interface Props {
	organisation: OrganisationRedux
}

export const OrganisationDrivesAccess = ({ organisation }: Props): React.ReactElement => {
	const { blockedDriveLinkProviders } = organisation
	const { config } = useSelector((state: ApplicationState) => state)
	const [blockedDrives, setBlockedDrives] = useState<number[]>(blockedDriveLinkProviders)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		setBlockedDrives(blockedDriveLinkProviders)
	}, [organisation.id])

	const onToggleChange = async (isChecked: boolean, driveValue: number): Promise<void> => {
		const previousValue = blockedDrives
		const newValue = isChecked ? blockedDrives.filter((item) => item !== driveValue) : [...blockedDrives, driveValue]
		setBlockedDrives(newValue)

		try {
			const updatedData = await APIRequest.Organisations.updateOrganisationConfigurations(organisation.id, { blockedDriveLinkProviders: newValue })
			dispatch(updateOrganisationData({ ...organisation, blockedDriveLinkProviders: newValue }))
			dispatch(setDisallowedLinkProviders(updatedData))
		} catch (err) {
			setBlockedDrives(previousValue)
		}
	}

	return (
		<div className={css.container}>
			<h1 className={css.title}>
				{t('components.organisationDrivesAccess.otherDrives')} <ProBadgeIcon />
			</h1>
			<div className={css.subTitle}>{t('components.organisationDrivesAccess.allowUsersInfo')}</div>
			<div className={css.list}>
				{disallowedProvidersMap.map((drive) => (
					<div key={drive.value} className={css.driveContainer}>
						<div className={css.drive}>
							<img src={providersData[drive.provider].getIconUrl(config.LYNXCLOUD_CLIENT)} alt="icon" />
							<div>{drive.label}</div>
						</div>
						<Toggle
							checked={!blockedDrives.includes(drive.value)}
							onChange={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => onToggleChange(e.target.checked, drive.value)}
						/>
					</div>
				))}
			</div>
		</div>
	)
}
