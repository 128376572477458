import { OrganisationGroupUsersInterface, OrganisationRedux, OrganisationUsersInterface } from '@lynx/core/src/interfaces/Organisation'

export const reduceOrganisationUserToGroupUser = (
	{ userId, email }: OrganisationUsersInterface,
	organisation: OrganisationRedux,
	groupId: string
): OrganisationGroupUsersInterface => {
	return {
		id: 0,
		userId,
		email,
		organisationId: Number(organisation.id),
		isAdmin: false,
		isEnabled: true,
		isReadonly: false,
		groupId: Number(groupId)
	}
}
