import { APIQueryParams } from '@lynx/core/src/interfaces'
import { BaseCall } from '../BaseCall'
import { APIConfig, APIResponse, FeedbackData, FeedbackQuestion } from '../interfaces'
import { FeedbackAnswer } from '@lynx/core/src/interfaces/Feedback'

export class Feedback extends BaseCall {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	public send = async (data: FeedbackData): Promise<APIResponse> => {
		try {
			const response = await this.client.put<APIResponse>('/feedback', data)

			if (response.status === 200) {
				return response.data
			}
		} catch (e) {
			console.error(e)
		}
	}

	// public results = async (): Promise<APIResponse<FeedbackResults>> => {
	// 	const response = await this.client.get<APIResponse<FeedbackResults>>('/feedback/results')
	// 	return response.data
	// }

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	public get = async (token: number): Promise<APIResponse<FeedbackQuestion>> => {
		try {
			const response = await this.client.get<APIResponse<FeedbackQuestion>>(`/feedback/${token}`)

			if (response.status === 200) {
				return response.data
			}
		} catch (e) {
			console.error(e)
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}

	public getAll = async (params: APIQueryParams): Promise<APIResponse<FeedbackAnswer[]>> => {
		const response = await this.client.get<APIResponse<FeedbackAnswer[]>>('/feedback', { params })
		return response.data
	}
}
