import { APIRequest } from '@lynx/client-core/src/api'
import { Providers } from '@lynx/core/src/interfaces/ObjectStore'
import QueryString from 'qs'
import * as React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

export const Open = (): React.ReactElement | null => {
	const params = useParams<{ driveItemId: string }>()
	const navigate = useNavigate()
	const { driveItemId } = params

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const { driveId, provider } = QueryString.parse(window.location.search.substring(1)) as { driveId?: string; provider?: Providers }
			if (!driveItemId || !provider) {
				navigate('/drive')
				return
			}

			// Get the extra data for the file
			const extraData = await APIRequest.ObjectStore.extra(provider, driveItemId, driveId)
			if (extraData?.type !== 'lynxcloud' && extraData?.type !== 'organisation') {
				navigate('/drive')
				return
			}
			navigate(`/share/${extraData.sharing.token}`)
		}
		mount()
	}, [])

	return null
}
