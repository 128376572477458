import { DriveItemType } from '@lynx/client-core/src/api/interfaces'

export const useDriveSort = (items: DriveItemType[], sortDirection: 'asc' | 'desc' | 'newest' | 'oldest' | null): DriveItemType[] => {
	return items.sort((a: DriveItemType, b: DriveItemType) => {
		const sortDir = sortDirection === 'newest' ? 1 : -1

		if (sortDirection === 'asc') {
			const aName =
				a.mimeType === 'application/folder' && a.id === 'sharedwithme'
					? '  ' + a.name.trimStart()
					: a.mimeType === 'application/folder'
					? ' ' + a.name.trimStart()
					: a.name.trimStart()

			const bName =
				b.mimeType === 'application/folder' && b.id === 'sharedwithme'
					? '  ' + b.name.trimStart()
					: b.mimeType === 'application/folder'
					? ' ' + b.name.trimStart()
					: b.name.trimStart()

			return aName.localeCompare(bName)
		}

		if (sortDirection === 'desc') {
			const aName =
				a.mimeType === 'application/folder' && a.id === 'sharedwithme'
					? a.name.trimStart()
					: a.mimeType === 'application/folder'
					? ' ' + a.name.trimStart()
					: '  ' + a.name.trimStart()

			const bName =
				b.mimeType === 'application/folder' && b.id === 'sharedwithme'
					? b.name.trimStart()
					: b.mimeType === 'application/folder'
					? ' ' + b.name.trimStart()
					: '  ' + b.name.trimStart()

			return bName.localeCompare(aName)
		}

		if (sortDirection === 'newest') {
			const aDateModified =
				a.mimeType === 'application/folder' && a.id === 'sharedwithme'
					? Math.pow(10, 100) + a.dateModified
					: a.mimeType === 'application/folder'
					? a.dateModified
					: a.dateModified / 10

			const bDateModified =
				b.mimeType === 'application/folder' && b.id === 'sharedwithme'
					? Math.pow(10, 100) + b.dateModified
					: b.mimeType === 'application/folder'
					? b.dateModified
					: b.dateModified / 10

			return aDateModified < bDateModified ? 1 * sortDir : -1 * sortDir
		}

		if (sortDirection === 'oldest') {
			const aDateModified =
				a.mimeType === 'application/folder' && a.id === 'sharedwithme'
					? a.dateModified
					: a.mimeType === 'application/folder'
					? a.dateModified / 10
					: a.dateModified

			const bDateModified =
				b.mimeType === 'application/folder' && b.id === 'sharedwithme'
					? b.dateModified
					: b.mimeType === 'application/folder'
					? b.dateModified / 10
					: b.dateModified

			return aDateModified > bDateModified ? -1 * sortDir : 1 * sortDir
		}

		return 0
	})
}
