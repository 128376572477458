import { currencySource } from '@lynx/core/src/constants'
import { Tier, TierPrice } from '@lynx/core/src/interfaces'
import { CurrencyDropdown } from 'pages/Pricing/components'
import css from './OrganisationSubscriptionNewPrice.module.scss'
import { BasicButton, CloseIcon, Input } from '@lynx/client-core/src/components'
import { initialTier } from './OrganisationSubscriptionNewPrice'
import { getTierErrorMsgs } from './SubscriptionCreatePriceTiered.helpers'
import { SubscriptionCreatePriceTieredSingleTier } from './SubscriptionCreatePriceTieredSingleTier'

interface Props {
	tieredPriceObject: TierPrice['tiersData']
	setTieredPriceObject?: React.Dispatch<React.SetStateAction<TierPrice['tiersData']>>
	mainCurrency: string
	setMainCurrency?: React.Dispatch<React.SetStateAction<string>>
}

export const SubscriptionCreatePriceTiered = ({ tieredPriceObject, setTieredPriceObject }: Props): React.ReactElement => {
	const isReadOnly = !setTieredPriceObject
	const tieredNotSelectedCurrency = currencySource.find((currency) => !tieredPriceObject.some((price) => price.tierCurrencyCode === currency.code))
	const getAvailableCurrencies = (current: string): typeof currencySource =>
		currencySource.filter((currency) => !tieredPriceObject.some((price) => price.tierCurrencyCode === currency.code) || currency.code === current)

	const getFirstUnitValue = (index: number, tiers: Tier[]): string => {
		if (index === 0) return '1'
		const previousTier = tiers[index - 1]
		if (previousTier.upTo === null) throw new Error('Invalid previous tier ' + previousTier)
		return String(previousTier.upTo + 1)
	}

	const onAddAnotherTier = (currency: string): void => {
		const newTieredPriceObject = tieredPriceObject.map((tierCurrencyOption) => {
			if (tierCurrencyOption.tierCurrencyCode === currency) {
				const updatedCurrentTiers = [...tierCurrencyOption.tiers]
				const lastOne = updatedCurrentTiers[updatedCurrentTiers.length - 2]
				const oneBeforeLastOne = updatedCurrentTiers[updatedCurrentTiers.length - 1]
				if (lastOne.upTo === null) throw new Error('Invalid oneBeforeLastOne.upTo ' + oneBeforeLastOne.upTo)

				updatedCurrentTiers[updatedCurrentTiers.length - 1] = { ...lastOne, upTo: lastOne.upTo + 2 }
				return { ...tierCurrencyOption, tiers: [...updatedCurrentTiers, { upTo: null, unitAmount: 0, flatAmount: 0 }] }
			}
			return tierCurrencyOption
		})
		setTieredPriceObject?.(newTieredPriceObject)
	}

	const onRemoveTier = (currency: string, index: number): void => {
		const currencyTierObj = tieredPriceObject.find((tierCurrencyOption) => tierCurrencyOption.tierCurrencyCode === currency)
		const isLastOne = currencyTierObj && currencyTierObj.tiers.length - 1 === index
		const newTieredPriceObject = tieredPriceObject.map((tierCurrencyOption) => {
			if (tierCurrencyOption.tierCurrencyCode === currency) {
				const updatedCurrentTiers = tierCurrencyOption.tiers.reduce((acc: Tier[], tier, i) => {
					const isOneBeforeLastOne = i === tierCurrencyOption.tiers.length - 2
					if (isOneBeforeLastOne && isLastOne) return [...acc, { ...tier, upTo: null }]
					if (i !== index) return [...acc, tier]
					return acc
				}, [])
				return { ...tierCurrencyOption, tiers: updatedCurrentTiers }
			}
			return tierCurrencyOption
		})
		setTieredPriceObject?.(newTieredPriceObject)
	}

	return (
		<div className={css.tieredPriceContainer}>
			{tieredPriceObject.map(({ tierCurrencyCode, tiers }, index) => {
				return (
					<div className={css.singleCurrencyTierContainer} key={tierCurrencyCode + 'tiered'}>
						<CurrencyDropdown
							disabled={isReadOnly}
							onItemClicked={(code): void => {
								const newTieredPriceObject = [...tieredPriceObject]
								newTieredPriceObject[index].tierCurrencyCode = code
								setTieredPriceObject?.(newTieredPriceObject)
							}}
							currencyCode={tierCurrencyCode}
							currenciesList={getAvailableCurrencies(tierCurrencyCode)}
						/>
						{tiers.map((tier, i) => {
							return (
								<SubscriptionCreatePriceTieredSingleTier
									tier={tier}
									tiers={tiers}
									getFirstUnitValue={getFirstUnitValue}
									i={i}
									isReadOnly={isReadOnly}
									key={tierCurrencyCode + i}
									tierCurrencyCode={tierCurrencyCode}
									tieredPriceObject={tieredPriceObject}
									setTieredPriceObject={setTieredPriceObject}
									currencyIndex={index}
									onRemoveTier={onRemoveTier}
									onAddAnotherTier={onAddAnotherTier}
								/>
							)
						})}
					</div>
				)
			})}
			{tieredNotSelectedCurrency && !isReadOnly && (
				<BasicButton
					className={css.newRowButton}
					size="sm"
					onClick={(): void => setTieredPriceObject?.([...tieredPriceObject, { ...initialTier, tierCurrencyCode: tieredNotSelectedCurrency.code }])}
				>
					Add a price by currency
				</BasicButton>
			)}
		</div>
	)
}
