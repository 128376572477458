import { APIRequest } from '@lynx/client-core/src/api'
import { ControlBar } from 'pages/Share/components/ControlBar'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { PDFPreviewModalContextType, showModal } from 'store/modal'
import { PDFState } from './interfaces'
import { PDFRenderer } from './modals/PreviewModal/PDFRenderer'
import Styles from './PDF.module.css'

export const PDF = (): React.ReactElement => {
	const params = useParams<{ token: string }>()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [state, setState] = useState<PDFState>({
		token: '',
		shareDetails: null,
		blobSrc: null
	})

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const { token } = params
			if (!token) {
				return
			}

			const shareInfo = await APIRequest.Sharing.info(token)
			if (!shareInfo?.data) {
				navigate('/missing-share')
			}

			const blobProperties = await APIRequest.Sharing.getObjectUrl(token)
			if (!blobProperties) {
				navigate('/missing-share')
				return
			}

			setState({ ...state, token, shareDetails: shareInfo.data || null, blobSrc: blobProperties.link })
		}
		mount()
	}, [])

	const handleViewClicked = (): void => {
		dispatch(showModal({ name: 'PDFPreviewModal', context: { type: PDFPreviewModalContextType, token: state.token } }))
	}

	const handleDownload = async (): Promise<void> => {
		const blobLinkProperties = await APIRequest.Sharing.getObjectUrl(state.token)
		if (!blobLinkProperties) {
			navigate('/missing-share')
			return
		}

		const link = document.createElement('a')
		link.href = blobLinkProperties.link
		document.body.appendChild(link)
		link.download = state.shareDetails?.name || 'download'
		link.click()
		document.body.removeChild(link)
	}

	return (
		<div className={Styles['container']}>
			{state.blobSrc && <PDFRenderer blobSrc={state.blobSrc} />}

			<ControlBar onDownloadClicked={handleDownload} onViewClicked={handleViewClicked} shareDetails={state.shareDetails} token={state.token} />
		</div>
	)
}
