import { APIRequest } from '@lynx/client-core/src/api'
import { AutocompleteInput, ProBadgeIcon, ProOrgBadgeIcon } from '@lynx/client-core/src/components'
import { SearchOrganisation } from '@lynx/core/src/interfaces/Organisation'
import { useState } from 'react'
import css from './OrganisationSearch.module.scss'

interface Props {
	onOrganisationSelected: (organisation: SearchOrganisation | null) => void
}

export const OrganisationSearch = ({ onOrganisationSelected }: Props): React.ReactElement => {
	const [organisations, setOrganisations] = useState<SearchOrganisation[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const searchOrganisation = async (searchValue: string): Promise<void> => {
		setIsLoading(true)
		try {
			const data = await APIRequest.Organisations.searchOrganisation({ searchText: searchValue, start: 1, length: 10 })
			setOrganisations(data.organisations)
		} catch (e) {
			setOrganisations([])
		} finally {
			setIsLoading(false)
		}
	}

	const getOrganisationCustomItemLabel = (item: SearchOrganisation): React.ReactElement => {
		return (
			<div className={css.orgItem}>
				{getOrganisationLabel(item)}
				{item.isPro ? <ProOrgBadgeIcon /> : null}
			</div>
		)
	}
	const getOrganisationLabel = (item: SearchOrganisation): string => `${item.organisationName} (ID: ${item.id})`

	return (
		<div className={css.container}>
			<AutocompleteInput
				getCustomItemLabel={getOrganisationCustomItemLabel}
				inputLabel="Organisation"
				fetchItems={searchOrganisation}
				items={organisations.map((o) => ({ ...o, organisationNameLabel: getOrganisationLabel(o) }))}
				isLoading={isLoading}
				keyPath="organisationNameLabel"
				onItemSelected={(item: SearchOrganisation | null): void => onOrganisationSelected(item)}
			/>
		</div>
	)
}
