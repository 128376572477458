import { APIRequest } from '@lynx/client-core/src/api'
import { AddIcon, Document, Table } from '@lynx/client-core/src/components'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showModal } from 'store/modal'
import { AddNewCountryModal } from './AddNewCountryModal'

export const CountryFix = (): React.ReactElement => {
	const dispatch = useDispatch()
	const [countries, setCountries] = useState<{ badName: string; goodName: string }[]>([])
	const headers = [
		{ label: 'Bad Name', valueKey: 'badCountryName' },
		{ label: 'Good Name', valueKey: 'goodCountryName' }
	]

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const res = await APIRequest.Pages.getCountryFix()
			setCountries(res.data)
		}
		mount()
	}, [])

	const handleNewClicked = (): void => {
		dispatch(showModal({ name: 'AddNewCountryModal' }))
	}

	return (
		<Document title={'Country Fix'} description="Country Fix" keywords="Country Fix" auth={true} disableOverflow={true}>
			<React.Fragment>
				<AddNewCountryModal />
				<Card>
					<Table
						title="Country Fix"
						items={countries}
						headers={headers}
						csvFileName={'Countryfix.csv'}
						toolBarItems={[
							{
								component: (
									<div onClick={handleNewClicked}>
										<AddIcon />
										<div>Add Country</div>
									</div>
								)
							}
						]}
					/>
				</Card>
			</React.Fragment>
		</Document>
	)
}
