import { useOutsideClick } from '../../hooks'
import * as React from 'react'
import { useState } from 'react'
import css from './index.module.scss'
import { ChevronLeftIcon } from '../Icons'

interface DropdownProps {
	className?: string
	openedClassName?: string
	chevron?: boolean
	disabled?: boolean
	style?: React.CSSProperties
	elevation?: boolean
	children?: React.ReactNode
	type?: 'button' | 'reset' | 'submit'
	onSubmit?: (event: React.FormEvent<HTMLButtonElement>) => void
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	onItemClicked?: (key: string | number | null) => void
	title: string | React.ReactElement
	direction?: 'up' | 'down'
	isFullWidth?: boolean
	justify?: 'left' | 'right'
	variant?: 'light' | 'dark'
}

export const Dropdown = ({
	disabled = false,
	children,
	className,
	title,
	direction = 'down',
	justify = 'right',
	chevron,
	isFullWidth,
	openedClassName,
	variant = 'dark'
}: DropdownProps): React.ReactElement => {
	const [opened, setOpened] = useState(false)
	const childrenContainerRef = React.useRef<HTMLDivElement>(null)

	useOutsideClick(childrenContainerRef, () => {
		setOpened(false)
	})

	const getDropdownStyles = (): React.CSSProperties => {
		const styles: React.CSSProperties = {}
		if (direction === 'up' && childrenContainerRef.current) {
			styles.transform = `translateY(${-childrenContainerRef.current.offsetHeight}px)`
		}

		return styles
	}

	const classNames = [css['container'], className].filter((c) => c)
	opened && classNames.push(css['container-opened'])
	disabled && classNames.push(css.dropdownDisabled)

	const openedClassNames = [css['children-container'], css[`direction-${direction}`]]
	isFullWidth && openedClassNames.push(css['fullWidth'])
	openedClassName && openedClassNames.push(openedClassName)
	openedClassNames.push(opened ? css.visible : css.hidden)

	openedClassNames.push(css[`children-container--${variant}`])
	openedClassNames.push(css[`children-container--${justify}`])

	return (
		<React.Fragment>
			<div className={classNames.join(' ')}>
				<div ref={childrenContainerRef}>
					<div
						onClick={(): void => {
							if (disabled) return
							setOpened(!opened)
						}}
						className={`${chevron && css.chevronContainer} ${css.titleContainer}`}
					>
						<div>{title}</div> {chevron && <ChevronLeftIcon className={css.arrow} style={{ transform: `rotate(${opened ? 90 : -90}deg)` }} />}
					</div>

					<div
						style={getDropdownStyles()}
						className={openedClassNames.join(' ')}
						onClick={(): void => {
							setOpened(false)
						}}
					>
						<ul className={css.children}>{children}</ul>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}
