import ModalBase from '@lynx/client-core/src/components/Modal/Modal'
import { Price } from '@lynx/core/src/interfaces'
import css from './OrganisationSubscriptionNewPrice.module.scss'
import { SubscriptionCreatePriceTiered } from './SubscriptionCreatePriceTiered'
import { SubscriptionCreatePriceUnit } from './SubscriptionCreatePriceUnit'
import { OpenInNewIcon } from '@lynx/client-core/src/components'

interface Props {
	priceData: Price
	onClose: () => void
}

export const OrganisationSubscriptionViewPrice = ({ priceData, onClose }: Props): React.ReactElement => {
	const { type } = priceData
	const mainCurrency = 'gbp'

	const stripeUrl = `https://dashboard.stripe.com/test/prices/${priceData.id}`
	return (
		<div>
			<ModalBase name="Price preview" title="Price preview" uncontrolled onHidden={(): void => onClose()} innerContainerClasses={css.modalInnerContainer}>
				<div className={css.container}>
					<div>
						<a href={stripeUrl} className={css.link} target="_blank" rel="noreferrer">
							<OpenInNewIcon /> Click to see it in stripe
						</a>
					</div>
					{type === 'tiered' && <SubscriptionCreatePriceTiered mainCurrency={mainCurrency} tieredPriceObject={priceData.tiersData} />}
					{type === 'per_unit' && <SubscriptionCreatePriceUnit mainCurrency={mainCurrency} flatPriceObject={priceData.currencyOptions} />}
				</div>
			</ModalBase>
		</div>
	)
}
