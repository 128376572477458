import { Reducer } from 'redux'
import { initialConfigState } from './initialState'
import { ConfigActionTypes, ConfigState, READ_CONFIG } from './types'

const reducer: Reducer<ConfigState, ConfigActionTypes> = (state: ConfigState = initialConfigState, action: ConfigActionTypes) => {
	switch (action.type) {
		case READ_CONFIG: {
			return {
				...state,
				...action.payload
			}
		}
		default: {
			return state
		}
	}
}

export { reducer as configReducer }
