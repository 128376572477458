import { APIRequest } from '@lynx/client-core/src/api'
import * as React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ApplicationState } from 'store'

export const LynxActivityExport = (): React.ReactElement => {
	const params = useParams<{ token: string }>()
	const { profile: profileState, config: configState } = useSelector((state: ApplicationState) => state)
	const navigate = useNavigate()

	const mount = async (): Promise<void> => {
		const { token } = params
		if (!token) {
			return
		}

		const shareInfo = await APIRequest.Sharing.info(token)
		if (!shareInfo?.data) {
			navigate('/missing-share')
			return
		}

		if (profileState.loggedIn) {
			const buffer = await APIRequest.Sharing.downloadBuffer(token)
			if (!buffer) {
				navigate('/missing-share')
				return
			}

			await APIRequest.Activities.importCollection(JSON.parse(buffer.toString('utf8')))

			window.location.href = `${configState.LYNXACTIVITIES_CLIENT}?imported=true`
			return
		}
	}

	useEffect(() => {
		mount()
	}, [])

	return <div>Importing Please Wait</div>
}
