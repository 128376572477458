import { BasicButton, Textarea } from '@lynx/client-core/src/components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './EntraIdDomains.module.css'

interface EntraIdProps {
	entraId: {
		tenantId: string
		domains?: string[]
	}
	isEntraId: boolean
}

export interface EntraIdDomainProps {
	navigate: (section: string, props: EntraIdProps | null) => void
	context: EntraIdProps
}

export const EntraIdDomains = (props: EntraIdDomainProps): React.ReactElement => {
	const [domains, setDomains] = useState<string[]>([])
	const { t } = useTranslation()

	const handleNext = (): void => {
		const { entraId, isEntraId } = props.context
		props.navigate('OrganisationDetails', {
			entraId: {
				...entraId,
				domains
			},
			isEntraId
		})
	}

	return (
		<div className={css['container']}>
			<p>{t('pages.organisation.entraIdDomain')}</p>
			<Textarea
				onChange={(e): void => {
					setDomains(e.target.value.split('\n'))
				}}
			/>
			<div className={css['buttons']}>
				<BasicButton
					onClick={(): void => {
						props.navigate('EntraIdSetup', {
							entraId: props.context.entraId,
							isEntraId: props.context.isEntraId
						})
					}}
				>
					{t('pages.organisations.createModal.back')}
				</BasicButton>
				<BasicButton variant="blue" disabled={!domains} onClick={handleNext}>
					{t('pages.organisations.createModal.next')}
				</BasicButton>
			</div>
		</div>
	)
}
