import { BasicButton, Input, Modal } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { createGroup } from 'store/organisations'
import css from './CreateGroupModal.module.scss'
import { useTranslation } from 'react-i18next'

export const CreateGroupModal = (): React.ReactElement => {
	const dispatchThunk = useThunkDispatch()
	const dispatch = useDispatch()
	const organisation = useCurrentOrganisation()
	const { t } = useTranslation()
	const [groupName, setGroupName] = useState<string>('')

	const handleCreateGroupClicked = async (): Promise<void> => {
		if (!organisation) return

		await dispatchThunk(createGroup(organisation.id, groupName))
		dispatch(hideModal())
	}

	const handleGroupNameChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setGroupName(e.target.value)
	}

	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}

	return (
		<Modal name="CreateGroupModal" title="Create Group">
			<div className={css['container']}>
				<div>
					<h4>{t('pages.organisations.createGroup')}</h4>
					<Input placeholder="group" onChange={handleGroupNameChanged} value={groupName} />
				</div>

				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>Cancel</BasicButton>
					<BasicButton variant="blue" onClick={handleCreateGroupClicked} disabled={groupName.length == 0}>
						{t('pages.organisations.create')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
