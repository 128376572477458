import { useEffect } from 'react'

export const useOutsideClick = (ref: React.RefObject<HTMLElement> | null, callback: (event: MouseEvent) => void): void => {
	useEffect(() => {
		const handleClick = (event: MouseEvent): void => {
			if (ref?.current && !ref.current.contains(event.target as HTMLElement)) {
				callback(event)
			}
		}

		document.addEventListener('click', handleClick, true)
		return (): void => {
			document.removeEventListener('click', handleClick, true)
		}
	}, [ref])
}
