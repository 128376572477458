import React from 'react'
import { MenuIcon } from '../Icons'
import css from './ProfileDropdownLoggedOutMobile.button.module.scss'

interface ProfileDropdownLoggedOutMobileButtonProps {
	onOpenClicked: () => void
	className?: string
}

export const ProfileDropdownLoggedOutMobileButton = ({ onOpenClicked, className }: ProfileDropdownLoggedOutMobileButtonProps): React.ReactElement => {
	const classNames = [css['container']]
	className && classNames.push(className)
	return (
		<button className={classNames.join(' ')} onClick={onOpenClicked} aria-label="login">
			<MenuIcon />
		</button>
	)
}
