import { Document } from '@lynx/client-core/src/components'
import useRedirectOnLogin from '@lynx/client-core/src/hooks/useRedirectOnLogin'
import i18next from 'i18next'
import React from 'react'
import css from './Goodbye.module.css'

const links = [
	{
		height: 30,
		icon: '/assets/icons/logo-google-drive.svg',
		name: 'Google Drive',
		href: 'https://myaccount.google.com/permissions?continue=https%3A%2F%2Fmyaccount.google.com%2Fdata-and-privacy%3Fhl%3Den&hl=en'
	},
	{
		height: 30,
		icon: '/assets/icons/logo-onedrive.svg',
		name: 'OneDrive',
		href: 'https://portal.office.com/account/?ref=MeControl#home'
	},
	{
		height: 30,
		icon: '/assets/icons/logo-dropbox.svg',
		name: 'Dropbox',
		href: 'https://www.dropbox.com/account/connected_apps'
	},
	{
		height: 40,
		icon: '/assets/icons/auth-apple-icon.png',
		name: 'Apple',
		href: 'https://appleid.apple.com/account/manage'
	}
]

export const Goodbye = (): React.ReactElement => {
	const title = ''
	const linkElements = links.map((link, key: number) => {
		const { icon, name, height } = link
		return (
			<div className={css['link']} key={key}>
				<div className={css['link__img__container']}>
					<img style={{ height }} src={icon} alt={name} />
				</div>
				<a href={link.href}>{name}</a>
			</div>
		)
	})

	useRedirectOnLogin()

	return (
		<Document title={title} description={title} keywords={title}>
			<div className={css['container']}>
				<div className={css['container__card']}>
					<div>{i18next.t('pages.goodbye.accountDeleted')}</div>
					<div>{i18next.t('pages.goodbye.dataDeleted')}</div>
					<div>{i18next.t('pages.goodbye.removePermissions')}</div>
					<div className={css['container__card__links']}>{linkElements}</div>
				</div>
			</div>
		</Document>
	)
}
