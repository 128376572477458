import { usePrevious } from '../../hooks'
import React, { ForwardedRef, forwardRef } from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { ApplicationState } from '../../store'
import { setLoginModal } from '../../store/profile'
import { getTitleBasedOnDomain } from '../../utils'
import css from './Document.module.scss'

interface DocumentProps {
	title: string
	description?: string
	keywords?: string
	auth?: boolean
	children: React.ReactNode
	disableOverflow?: boolean
	stableScrollBarGutter?: boolean
	style?: React.CSSProperties
	className?: string
	srcs?: string[]
}

/**
 * Document handles authentication for authenticated pages, presenting the login modal when necessary.
 * @param param0
 * @returns
 */
// eslint-disable-next-line react/display-name
export const Document = forwardRef(
	(
		{ srcs = [], className, auth, children, disableOverflow, title, description = title, keywords = title, stableScrollBarGutter, style }: DocumentProps,
		ref: ForwardedRef<HTMLDivElement> | undefined
	): React.ReactElement | null => {
		const profile = useSelector((state: ApplicationState) => state.profile)
		const location = useLocation()
		const dispatch = useDispatch()
		const searchParams = new URLSearchParams(location.search)
		const navigate = useNavigate()

		// The path on last render
		const { pathname } = location
		const previousPathName = usePrevious(pathname)
		const previousLoggedIn = usePrevious(profile.loggedIn)
		const isActivity = searchParams.get('activityId') !== null

		// On start of a component that uses this wrapper we first check if their profile is logged in, and whether the component
		// requires authentication, if it doesn't then this useEffect should do nothing. If however the component does require
		// auth, or their profile later changes to a not logged in state then we should present the login modal
		useEffect(() => {
			// Show the Login Modal
			const handlePresentLoginModal = (): void => {
				const { loggedIn } = profile
				const showLoginModal = !loggedIn && !!auth
				const showLoginModalSticky = showLoginModal
				dispatch(setLoginModal(showLoginModal, showLoginModalSticky))
			}

			// Hide the Login Modal
			const handleHideLoginModal = (): void => {
				dispatch(setLoginModal(false))
			}

			const { loggedIn, showLoginModal } = profile

			// If we're not logged in and the page requires authentication then present the login page
			if (!loggedIn && auth && !profile.showLoginModal) {
				handlePresentLoginModal()
			}

			// If we're logged and modal is open then hide the login modal
			if (loggedIn && showLoginModal) {
				handleHideLoginModal()
			}
		}, [profile, auth])

		useEffect(() => {
			if (!previousLoggedIn && profile.loggedIn) {
				if (searchParams.get('redirect')) {
					navigate(String(searchParams.get('redirect')), { replace: true })
				}
			}
		}, [profile.loggedIn, previousLoggedIn])

		// If the current page doesn't equal the previous page then log Page View with GA irregardless of logged in state
		if (pathname !== previousPathName) {
			ReactGA.send({ hitType: 'pageview', page: pathname, title: `LynxCloud - ${getTitleBasedOnDomain(title, 'LYNX Cloud')}` })
		}

		const classNames = [css['container']]
		disableOverflow && classNames.push(css['container--disable-overflow'])
		stableScrollBarGutter && classNames.push(css['container--enable-gutter'])
		isActivity && classNames.push(css['container--max'])
		className && classNames.push(className)

		const scripts = srcs.map((s) => <script key={s} src={s} type="module" async defer />)

		return (
			<React.Fragment>
				<Helmet>
					<meta charSet="utf-8" />
					<title>{getTitleBasedOnDomain(title, 'LYNX Cloud')}</title>
					<meta name="description" content={description || title} />
					<meta name="keywords" content={keywords || title} />
					{scripts}
				</Helmet>
				<div className={classNames.join(' ')} ref={ref} style={style}>
					{children}
				</div>
			</React.Fragment>
		)
	}
)
