import { APIRequest } from '@lynx/client-core/src/api'
import { supportedLanguages } from '@lynx/client-core/src/components/LanguageDropdown/languagesList'
import i18next from 'i18next'
import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import { ProfileState, setLang } from 'store/profile'
interface LanguageProps {
	profile: ProfileState
	setLang: (lang: string) => void
	style?: React.CSSProperties
}

class Language extends React.Component<LanguageProps> {
	componentDidMount(): void {
		this.setLanguageCode()
	}

	componentDidUpdate(oldProps: LanguageProps): void {
		if (!oldProps.profile.loggedIn && this.props.profile.loggedIn) {
			this.setLanguageCode()
		}
	}

	private getBrowserLanguage(): string {
		const lang = this.props.profile.lang || navigator.language || 'en'

		if (lang.includes('-')) {
			return lang.split('-')[0]
		}

		return lang
	}

	private setLanguageCode = async (): Promise<void> => {
		const { loggedIn } = this.props.profile
		let lang = this.getBrowserLanguage()

		if (!supportedLanguages.includes(lang)) {
			lang = 'en'
		}

		i18next.changeLanguage(lang)
		this.props.setLang(lang) // Set Redux State

		if (loggedIn) {
			await APIRequest.User.changeLang(lang)
		}
	}

	render(): React.ReactNode {
		return null
	}
}

const mapStateToProps = (state: ApplicationState): { profile: ProfileState } => ({
	profile: state.profile
})

export default connect(mapStateToProps, { setLang })(Language)
