import { BasicButton, CloseIcon, Input } from '@lynx/client-core/src/components'
import { Tier, TierPrice } from '@lynx/core/src/interfaces'
import css from './OrganisationSubscriptionNewPrice.module.scss'
import { getTierErrorMsgs } from './SubscriptionCreatePriceTiered.helpers'

interface Props {
	tieredPriceObject: TierPrice['tiersData']
	setTieredPriceObject?: React.Dispatch<React.SetStateAction<TierPrice['tiersData']>>
	tierCurrencyCode: string
	isReadOnly: boolean
	tier: Tier
	tiers: Tier[]
	getFirstUnitValue: (index: number, tiers: Tier[]) => string
	i: number
	currencyIndex: number
	onRemoveTier: (tierCurrencyCode: string, index: number) => void
	onAddAnotherTier: (tierCurrencyCode: string) => void
}
export const SubscriptionCreatePriceTieredSingleTier = ({
	tierCurrencyCode,
	isReadOnly,
	tier,
	tiers,
	tieredPriceObject,
	getFirstUnitValue,
	i,
	currencyIndex,
	setTieredPriceObject,
	onRemoveTier,
	onAddAnotherTier
}: Props): React.ReactElement => {
	return (
		<div key={tierCurrencyCode + i} className={css.singleTier}>
			<div className={css.inputs}>
				<Input sizeInput="sm" label={i === 0 ? 'First unit' : ''} disabled value={getFirstUnitValue(i, tiers)} className={css.firstUnit} />
				<Input
					sizeInput="sm"
					label={i === 0 ? 'Last unit' : ''}
					disabled={tier.upTo === null || isReadOnly}
					value={tier.upTo === null ? 'Infinity' : tier.upTo}
					messages={getTierErrorMsgs(tier, i, tiers)}
					onChange={(e): void => {
						const newTieredPriceObject = [...tieredPriceObject]
						newTieredPriceObject[currencyIndex].tiers[i].upTo = Number(e.target.value)
						setTieredPriceObject?.(newTieredPriceObject)
					}}
				/>
				<Input
					sizeInput="sm"
					label={i === 0 ? 'Unit Amount' : ''}
					value={tier.unitAmount}
					disabled={isReadOnly}
					onChange={(e): void => {
						const newTieredPriceObject = [...tieredPriceObject]
						newTieredPriceObject[currencyIndex].tiers[i].unitAmount = Number(e.target.value)
						setTieredPriceObject?.(newTieredPriceObject)
					}}
				/>
				<Input
					sizeInput="sm"
					label={i === 0 ? 'Flat Amount' : ''}
					value={tier.flatAmount}
					disabled={isReadOnly}
					onChange={(e): void => {
						const newTieredPriceObject = [...tieredPriceObject]
						newTieredPriceObject[currencyIndex].tiers[i].flatAmount = Number(e.target.value)
						setTieredPriceObject?.(newTieredPriceObject)
					}}
				/>
				{!isReadOnly && (
					<CloseIcon
						style={{ visibility: i !== 0 ? 'visible' : 'hidden' }}
						className={css.closeIcon}
						onClick={(): void => onRemoveTier(tierCurrencyCode, i)}
					/>
				)}
			</div>
			{i === tiers.length - 1 && !isReadOnly && (
				<div className={css.newTierBtnContainer}>
					<BasicButton className={css.newRowButton} size="sm" onClick={(): void => onAddAnotherTier(tierCurrencyCode)}>
						Add a another tier
					</BasicButton>
				</div>
			)}
		</div>
	)
}
