import { BaseCall } from '../BaseCall'
import { APIConfig, APIResponse, DisplayNameInterface } from '../interfaces'

interface DisplayNameQueryObject {
	categories?: string[]
	prefix?: string
	suffix?: string
	count?: number
	split?: string
}

export class DisplayName extends BaseCall {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	public generate = async (DisplayNameObject: DisplayNameQueryObject): Promise<APIResponse<DisplayNameInterface>> => {
		try {
			const { categories = ['animals', 'colours', 'fruits'], prefix = '', suffix = '', count = 1, split = '-' } = DisplayNameObject
			const cats = categories.join('&categories=')
			const response = await this.client.get<APIResponse<DisplayNameInterface>>(
				`/displayName?categories=${cats}&prefix=${prefix}&suffix=${suffix}&count=${count}&split=${split}`
			)

			if (response.status === 200) {
				return response.data
			}
		} catch (e) {
			console.error(e)
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
