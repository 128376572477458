import { AuthCookie } from './AuthCookie'
import { Logger } from './Logger'

export class Cookie {
	public static get(name: string, environment?: string): string {
		const r = new RegExp(`(^| )${name}=([^;]+)`, 'g')
		let cookieMatches: RegExpMatchArray | string[] | null = document.cookie.match(r)

		if (!cookieMatches || !cookieMatches.length) {
			return ''
		}

		// Check env variable of the cookie if specified
		if (environment) {
			const environmentCookie = cookieMatches.find((c) => {
				const [refreshToken, accessToken, env] = Buffer.from(decodeURIComponent(c.replace(`${name}=`, '')), 'base64')
					.toString()
					.split(' ')
				return env === environment
			})
			cookieMatches = [environmentCookie || '']
		}

		const c = cookieMatches[0].trim()
		if (c) {
			const val = decodeURIComponent(c).replace(`${name}=`, '')
			return val
		}
		return ''
	}

	public static set(name: string, value: string, opts = {}, expiryDate: Date = new Date()): void {
		expiryDate.setTime(expiryDate.getTime() + 7 * 24 * 60 * 60 * 1000)
		const expires = '; expires=' + expiryDate.toUTCString()

		opts = Object.entries(opts).reduce((str, [k, v]) => `${str}; ${k}=${v}`, '') || {}

		document.cookie = name + '=' + encodeURIComponent(value) + opts + expires
	}

	public static delete(name: string, environment: string): void {
		const domain = AuthCookie.getDomain(environment)
		Cookie.set(name, '', { Domain: domain, 'max-age': -1, expires: new Date(0).toUTCString() })
	}

	public static deleteMarketing(): void {
		const allCookies = document.cookie.split(';')

		for (let i = 0; i < allCookies.length; i++) {
			const name = allCookies[i].split('=')[0]
			if (name.includes('lynx')) continue
			Cookie.set(name, '', { 'max-age': -1, expires: new Date(0).toUTCString() })
		}
	}

	public static log(): void {
		const allCookies = document.cookie.split(';')

		for (let i = 0; i < allCookies.length; i++) {
			Logger.log(allCookies[i])
		}
	}
}
