import qs from 'qs'

export const downloadCsv = <T>(items: T[][], headers: string[], fileName = 'example.csv'): void => {
	const csvFileData = [headers, ...items]

	const csv = csvFileData.reduce((acc, nextRow) => `${acc}${nextRow.join(',')}\n`, '')

	const hiddenElement = document.createElement('a')
	hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
	hiddenElement.target = '_blank'
	hiddenElement.download = fileName
	hiddenElement.click()
}

export const updateQueryParam = (obj: { [key: string]: string | number }): void => {
	const query = qs.stringify(obj)
	const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query}`
	window.history.pushState({ path: newUrl }, '', newUrl)
}

export const deleteQueryParam = (key: string): void => {
	const url = new URL(window.location.toString())
	url.searchParams.delete(key)
	window.history.pushState(null, '', url.toString())
}

enum OSPlatform {
	Android = 'Android',
	Linux64 = 'Linux',
	Macx = 'MacOS',
	Win32 = 'Windows',
	Win64 = 'Windows',
	iOS = 'iOS'
}

export const objKeys = <T extends object>(obj: T): Array<keyof T> => Object.keys(obj) as Array<keyof T>

export const mapUserAgentToPlatform = (userAgent: string): string => {
	if (userAgent.includes('iPhone')) {
		return OSPlatform.iOS
	}

	if (userAgent.includes('Mac')) {
		return OSPlatform.Macx
	}

	if (userAgent.includes('Win64')) {
		return OSPlatform.Win64
	}

	if (userAgent.includes('Win32')) {
		return OSPlatform.Win32
	}

	if (userAgent.includes('Android')) {
		return OSPlatform.Android
	}

	if (userAgent.includes('Linux')) {
		return OSPlatform.Linux64
	}

	return OSPlatform.Android
}

export function isIOS(): boolean {
	return false
	const toMatch = [/iPhone/i, /iPad/i]

	return toMatch.some((toMatchItem) => {
		return navigator?.userAgent.match(toMatchItem)
	})
}

export function isSafari(): boolean {
	const toMatch = [/Safari/i]

	return toMatch.some((toMatchItem) => {
		return navigator?.userAgent.match(toMatchItem)
	})
}

const ua = navigator.userAgent
export const isLynx = ua.includes('(Linux; Android 8.0.0; SM-G960F Build/R16NW)')

export const formatBytes = (bytes: number, decimals = 2): string => {
	if (bytes === 0) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const timeSince = (date: Date): string => {
	const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)

	let interval = seconds / 31536000

	if (interval > 1) {
		return Math.floor(interval) + 'y' //years
	}
	interval = seconds / 2592000
	if (interval > 1) {
		return Math.floor(interval) + 'm' //months
	}
	interval = seconds / 86400
	if (interval > 1) {
		return Math.floor(interval) + 'd' //days
	}
	interval = seconds / 3600
	if (interval > 1) {
		return Math.floor(interval) + 'h' //hours
	}
	interval = seconds / 60
	if (interval > 1) {
		return Math.floor(interval) + 'm' //minutes
	}
	return Math.floor(seconds) + 's' //seconds
}

export const truncateFileName = (name: string, length: number, replacement = '...'): string => {
	return name.replace(new RegExp('(^[^\\.]{' + length + '})[^\\.]+'), '$1' + replacement)
}

export const getTitleBasedOnDomain = (title: string, prefix = 'Lynx'): string => {
	const domain = window.location.hostname

	const parts = []

	let envName = ''

	if (domain.includes('dev')) {
		envName = 'Dev'
	} else if (domain.includes('staging')) {
		envName = 'Staging'
	} else if (domain.includes('nightly')) {
		envName = 'Nightly'
	} else if (domain.includes('localhost')) {
		envName = 'Local'
	} else if (domain.includes('beta')) {
		envName = 'Beta'
	}

	if (prefix) {
		parts.push(`${prefix} ${envName}`.trim())
	}

	if (title) {
		parts.push(title)
	}

	return parts.join(' - ')
}

export const supportedMimeTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif', 'image/bmp', 'image/svg+xml']
export const resizeImage = async (file: File, maxWidth: number, maxHeight?: number): Promise<File | null> => {
	// this resize logic respects aspect ratio
	if (!supportedMimeTypes.includes(file.type)) return null
	const img = new Image()
	img.src = URL.createObjectURL(file)

	return new Promise((resolve, reject) => {
		img.onload = (): void => {
			const MAX_WIDTH = maxWidth
			const MAX_HEIGHT = maxHeight || 9999

			const { width, height } = img
			let resizedWidth = width
			let resizedHeight = height
			// Change the resizing logic
			if (width > MAX_WIDTH) {
				resizedHeight = height * (MAX_WIDTH / width)
				resizedWidth = MAX_WIDTH
			} else if (height > MAX_HEIGHT) {
				resizedWidth = width * (MAX_HEIGHT / height)
				resizedHeight = MAX_HEIGHT
			}

			const canvas = document.createElement('canvas')
			canvas.width = resizedWidth
			canvas.height = resizedHeight

			const ctx = canvas.getContext('2d')
			ctx?.drawImage(img, 0, 0, resizedWidth, resizedHeight)
			canvas.toBlob((blob) => {
				if (blob) {
					const newFile = new File([blob], file.name, { type: file.type })
					resolve(newFile)
				} else {
					reject(new Error('Canvas is empty'))
				}
			}, file.type)
		}
	})
}

export const cropImageToSquare = async (file: File): Promise<File | null> => {
	if (!supportedMimeTypes.includes(file.type)) return null
	const img = new Image()
	img.src = URL.createObjectURL(file)

	return new Promise((resolve, reject) => {
		img.onload = (): void => {
			const { width, height } = img
			const shortestSide = Math.min(width, height)
			const startX = (width - shortestSide) / 2
			const startY = (height - shortestSide) / 2

			const canvas = document.createElement('canvas')
			canvas.width = shortestSide
			canvas.height = shortestSide

			const ctx = canvas.getContext('2d')
			ctx?.drawImage(img, startX, startY, shortestSide, shortestSide, 0, 0, shortestSide, shortestSide)

			canvas.toBlob((blob) => {
				if (blob) {
					const newFile = new File([blob], file.name, { type: file.type })
					resolve(newFile)
				} else {
					reject(new Error('Canvas is empty'))
				}
			}, file.type)
		}
	})
}

export const createCircularImage = async (file: File, mimetype: string): Promise<File | null> => {
	if (!supportedMimeTypes.includes(file.type)) return null
	return new Promise((resolve, reject) => {
		const img = new Image()
		img.src = URL.createObjectURL(file)
		img.onload = (): void => {
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			const diameter = Math.min(img.width, img.height)

			canvas.width = diameter
			canvas.height = diameter

			if (ctx) {
				ctx.clearRect(0, 0, diameter, diameter)
				ctx.save()
				ctx.beginPath()
				ctx.arc(diameter / 2, diameter / 2, diameter / 2, 0, Math.PI * 2)
				ctx.closePath()
				ctx.clip()

				const scale = Math.min(diameter / img.width, diameter / img.height)
				const x = (diameter - img.width * scale) / 2
				const y = (diameter - img.height * scale) / 2

				ctx.drawImage(img, x, y, img.width * scale, img.height * scale)
				ctx.restore()

				canvas.toBlob((blob) => {
					if (blob) {
						const newFile = new File([blob], file.name, { type: mimetype || file.type })
						resolve(newFile)
					} else {
						reject(new Error('Canvas is empty'))
					}
				}, mimetype || file.type)
			} else {
				reject(new Error('Failed to get canvas context'))
			}
		}

		img.onerror = (error): void => reject(error)
	})
}
