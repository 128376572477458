export const isEmail = (email: string): boolean => {
	const emailFormat = /^\S+@\S+\.\S+$/
	return emailFormat.test(email)
}

export const isUrl = (url: string): boolean => {
	const urlFormat = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
	return urlFormat.test(url)
}

// at least 2 characters
export const isName = (name: string): boolean => {
	const nameFormat = /(.*[a-z]){2}/i

	return nameFormat.test(name)
}

export const passwordRegex = (password: string): boolean => {
	const lowercaseLetters = password.match(/[a-z]/)
	const uppercaseLetters = password.match(/[A-Z]/)
	const hasSymbols = password.match(/[^A-Za-z\d]+/)
	const hasNumber = password.match(/\d/)

	if (!lowercaseLetters || !lowercaseLetters[0]) {
		return false
	}

	if (!uppercaseLetters || !uppercaseLetters[0]) {
		return false
	}

	if (!hasSymbols || !hasSymbols[0]) {
		return false
	}

	if (!hasNumber || !hasNumber[0]) {
		return false
	}

	return true
}

export const escapeRegExp = (str: string): string => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const getRandomId = (): string => Math.ceil(Math.random() * 1000000000).toString()

export const pad = (n: number): string => {
	return n < 10 ? '0' + n.toString(10) : n.toString(10)
}

export const upperFirstLetter = <T extends string>(str: T): string => str.charAt(0).toUpperCase() + str.slice(1)

export const getDelimited = (str: string, char: string): string => {
	return str.includes(char) ? str.split(char)[0] : str
}

export const isString = (value: unknown): boolean => typeof value === 'string'
