import React, { useEffect, useState } from 'react'
import css from './Input.module.scss'
import { DefaultInputProps } from './Input.types'
import { InputMessages } from './InputMessages'

export type InputProps = DefaultInputProps &
	React.InputHTMLAttributes<HTMLInputElement> & {
		onEnter?(event: React.KeyboardEvent<HTMLInputElement>): void
	}
export const Input = React.forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
	const {
		className,
		messages,
		sizeInput = 'md',
		variant = 'dark',
		label,
		inError = false,
		icon,
		onEnter,
		onFocus,
		onBlur,
		prefixComponent,
		suffixComponent,
		...rest
	} = props
	const [isFocused, setIsFocused] = useState(false)
	const { disabled, autoFocus } = rest

	const containerClasses = [css.container, css[`container--${variant}`], css[`container--${sizeInput}`]]
	const internalId = rest.id || 'textarea-' + Math.random().toString(36).substr(2, 9)
	const isErrorMessage = messages?.some((message) => message.type === 'error')

	const inputRef = ref || React.createRef<HTMLInputElement>()

	Boolean(inError || isErrorMessage) && containerClasses.push(css['container--error'])
	disabled && containerClasses.push(css['container--disabled'])
	isFocused && containerClasses.push(css['container--focused'])
	className && containerClasses.push(className)

	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === 'Enter') {
			onEnter?.(event)
		}
	}

	const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
		onBlur && onBlur(event)
		setIsFocused(false)
	}

	const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
		onFocus && onFocus(event)
		setIsFocused(true)
	}

	const Icon = icon

	useEffect(() => {
		if (autoFocus && 'current' in inputRef) {
			inputRef.current?.focus()
		}
	}, [autoFocus])

	return (
		<div className={containerClasses.join(' ')}>
			{label && (
				<label className={css.label} htmlFor={internalId}>
					{label}
				</label>
			)}
			<div className={css.inputContainer}>
				{prefixComponent && <div className={css.prefixContainer}>{prefixComponent}</div>}
				{Icon && <Icon className={css.icon} />}
				<input
					ref={inputRef}
					className={css.inputEl}
					onKeyDown={onKeyDown}
					id={internalId}
					onFocus={onFocusHandler}
					onBlur={onBlurHandler}
					autoFocus={autoFocus}
					{...rest}
				/>
				{suffixComponent && <div className={css.suffixContainer}>{suffixComponent}</div>}
			</div>
			<InputMessages messages={messages} />
		</div>
	)
})
Input.displayName = 'input'
