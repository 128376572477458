import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton, Input, ListItem } from '@lynx/client-core/src/components'
import { Logger } from '@lynx/client-core/src/modules'
import { showModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { ResetPasswordModal } from 'pages/Profile/modals/ResetPasswordModal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { setDisplayName, setEmail } from 'store/profile'
import css from './AccountDetails.module.scss'
import { AccountDetailsProfilePicture } from './AccountDetailsProfilePicture'
import { Message } from '@lynx/client-core/src/components/Input/Input.types'

export const AccountDetails = (): React.ReactElement => {
	const { profile } = useSelector((state: ApplicationState) => state)
	const [inputStates, setInputStates] = useState<{ [key: string]: string }>({})
	const [messages, setMessages] = useState<{ [inputKey: string]: Message[] }>({})
	const { t } = useTranslation()
	const dispatchThunk = useThunkDispatch()
	const dispatch = useDispatch()

	const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setInputStates((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value
		}))
	}

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault()
		event.stopPropagation()

		const { email, displayName } = inputStates

		if (email && email !== profile.email) {
			const result = await dispatchThunk(setEmail(email))
			if (!result.success) {
				setMessages({ email: [{ message: result.message ?? t('pages.profile.unknownError'), type: 'error' }] })
				return
			}
			setMessages({ email: [{ message: t('pages.profile.saved'), type: 'info' }] })
		}

		if (displayName && displayName !== profile.displayName) {
			const result = await dispatchThunk(setDisplayName(displayName))
			if (!result.success) {
				setMessages({ displayName: [{ message: result.message ?? t('pages.profile.unknownError'), type: 'error' }] })
				return
			}
			setMessages({ displayName: [{ message: t('pages.profile.saved'), type: 'info' }] })
		}
	}

	const handleResetPasswordClicked = async (): Promise<void> => {
		const result = await APIRequest.User.forgottenPassword(profile.email)
		if (result.status === 200) {
			dispatch(showModal({ name: 'ResetPasswordModal' }))
		} else {
			Logger.error(result.message)
		}
	}

	return (
		<ListItem header={t('pages.profile.accountDetails')}>
			<div className={css['container']}>
				<form onSubmit={handleFormSubmit} className={css['form']}>
					<Input
						label={t('pages.profile.displayName')}
						variant="light"
						onChange={handleInputChanged}
						name="displayName"
						value={inputStates['displayName'] || profile.displayName}
						messages={messages['displayName']}
					/>

					<Input
						label={t('pages.profile.email')}
						variant="light"
						onChange={handleInputChanged}
						name="email"
						value={inputStates['email'] || profile.email}
						messages={messages['email']}
					/>

					<div className={css['form__buttons']}>
						<BasicButton variant="grey" onClick={handleResetPasswordClicked}>
							{t('pages.profile.resetPassword')}
						</BasicButton>
						<BasicButton variant="blue" type="submit">
							{t('pages.profile.save')}
						</BasicButton>
					</div>
				</form>
				<AccountDetailsProfilePicture />
			</div>
			<ResetPasswordModal />
		</ListItem>
	)
}
