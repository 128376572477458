import { useCallback, useEffect, useState } from 'react'

export const useKeyPress = (targetKey: KeyboardEvent['key'], cb: () => void): boolean => {
	const [keyPressed, setKeyPressed] = useState<boolean>(false)
	const downHandler = useCallback(
		({ key }: KeyboardEvent) => {
			if (key === targetKey) {
				setKeyPressed(true)
				cb && cb()
			}
		},
		[targetKey]
	)

	const upHandler = useCallback(
		({ key }: KeyboardEvent) => {
			if (key === targetKey) {
				setKeyPressed(false)
			}
		},
		[targetKey]
	)

	useEffect(() => {
		window.addEventListener('keydown', downHandler)
		window.addEventListener('keyup', upHandler)
		return () => {
			window.removeEventListener('keydown', downHandler)
			window.removeEventListener('keyup', upHandler)
		}
	}, [])
	return keyPressed
}
