import { APIConfig, ZoomSignatureReturn } from '../interfaces'
import { BaseCall } from '../BaseCall'
import LoginError from '../LoginError'

export class Zoom extends BaseCall {
	public getSignature = async (role?: number, meetingNumber?: string): Promise<ZoomSignatureReturn> => {
		try {
			const response = await this.client.get<ZoomSignatureReturn>('/zoom/signature', {
				params: {
					role, meetingNumber
				},
				withCredentials: true
			})

			if (response.status === 200) {
				return response.data
			}

			throw new LoginError({
				data: 'Bad Signature'
			})
		} catch (e) {
			console.error(e)
			throw e
		}
	}

	public createMeeting = async () => {
		try {
			const response = await this.client.post<ZoomSignatureReturn>('/zoom/create-meeting', {}, {
				withCredentials: true
			})

			if (response.status === 200) {
				return response.data
			}

			throw new LoginError({
				data: 'Something went wrong'
			})
		} catch (e) {
			console.error(e)
			throw e
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}