import { Channel, CreateChannelResponse } from '../../api/interfaces'
import {
	WEBSOCKET_BROKEN,
	WEBSOCKET_CLOSED,
	WEBSOCKET_CONNECT,
	WEBSOCKET_DISCONNECT,
	WEBSOCKET_OPEN,
	WEBSOCKET_RECONNECT_ABORTED,
	WEBSOCKET_SEND
} from '../../middleware/reduxwebsockets'
import { NavActionTypes } from '../../middleware/remote/types'
export const CREATE_CHANNEL = '@@online/CREATE_CHANNEL'
export const WEBSOCKET_PREFIX = 'REDUX_WEBSOCKET'
export const SUBSCRIBED_CHANNELS = '@@online/SUBSCRIBED_CHANNELS'
export const SUBSCRIBE_CHANNEL = '@@online/SUBSCRIBE_CHANNEL'
export const UNSUBSCRIBE_CHANNEL = '@@online/UNSUBSCRIBE_CHANNEL'
export const REDUX_WEBSOCKET_BROKEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_BROKEN}`
export const REDUX_WEBSOCKET_OPEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_OPEN}`
export const REDUX_WEBSOCKET_CLOSED = `${WEBSOCKET_PREFIX}::${WEBSOCKET_CLOSED}`
export const REDUX_WEBSOCKET_MESSAGE = 'REDUX_WEBSOCKET::MESSAGE'
export const REDUX_WEBSOCKET_CONNECT = `${WEBSOCKET_PREFIX}::${WEBSOCKET_CONNECT}`
export const REDUX_WEBSOCKET_DISCONNECT = `${WEBSOCKET_PREFIX}::${WEBSOCKET_DISCONNECT}`
export const REDUX_WEBSOCKET_SEND = `${WEBSOCKET_PREFIX}::${WEBSOCKET_SEND}`
export const REDUX_WEBSOCKET_RECONNECT_ABORTED = `${WEBSOCKET_PREFIX}::${WEBSOCKET_RECONNECT_ABORTED}`

export interface MessagingState {
	connected: boolean
	channel: { id: string; paired: boolean }
	lastMessage: {
		type: string
		datetime: number
		payload?: unknown
		channelId: string
	}
}
interface CreateChannelAction {
	type: typeof CREATE_CHANNEL
	payload: {
		channel: CreateChannelResponse
	}
}

interface SubscribedChannelsAction {
	type: typeof SUBSCRIBED_CHANNELS
	payload: {
		channels: Channel[]
	}
}

interface SubscribeChannelAction {
	type: typeof SUBSCRIBE_CHANNEL
	payload: {
		channelId: string
	}
}

interface UnsubscribeChannelAction {
	type: typeof UNSUBSCRIBE_CHANNEL
	payload: {
		channelId: string
	}
}

interface ReceivedMessageAction {
	type: typeof REDUX_WEBSOCKET_MESSAGE
	payload: {
		message: string
	}
}

export type MessagingActionTypes =
	| SubscribeChannelAction
	| SubscribedChannelsAction
	| CreateChannelAction
	| ReceivedMessageAction
	| UnsubscribeChannelAction
	| NavActionTypes
