class InMemoryStore {
	private store: { [key: string]: string } = {}

	public get length(): number {
		return Object.entries(this.store).length
	}

	/**
	 * Clears all entries
	 */
	public clear = (): void => {
		this.store = {}
	}

	/**
	 * Gets entries value
	 * @param key
	 * @returns string (value)
	 */
	public getItem = (key: string): string | null => {
		return this.store[key] || null
	}

	/**
	 * Removes entry by key
	 * @param key
	 */
	public key = (index: number): string | null => {
		const entries = Object.keys(this.store)
		return entries[index] || null
	}

	/**
	 * Removes entry by key
	 * @param key
	 */
	public removeItem = (key: string): void => {
		if (this.store[key]) {
			delete this.store[key]
		}
	}

	/**
	 * Sets entry overwriting any existing value for key (upsert), returns key
	 * @param key
	 * @param value
	 * @returns string (key)
	 */
	public setItem = (key: string, value: string): void => {
		this.store[key] = value
	}
}

Object.defineProperty(window, 'localStorage', {
	value: window.localStorage || new InMemoryStore()
})

export {}
