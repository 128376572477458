export const options = [
	{
		plugins: {
			title: {
				display: true,
				text: 'LYNX Cloud Change Logs'
			}
		},
		responsive: false,
		scales: {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		}
	},
	{
		plugins: {
			title: {
				display: true,
				text: 'LYNX Qt Change Logs'
			}
		},
		responsive: false,
		scales: {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		}
	}
]

export const changeLogKeys = [
	{ product: 'cloud', environment: 'production', os: 'web' },
	{
		product: 'lynx-whiteboard',
		environment: 'releases',
		os: 'win64'
	}
]

export const changeLogYears = ['2020', '2021', '2022', '2023', '2024']
