import { Logout } from 'pages'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Routes, useLocation, useNavigate } from 'react-router'
import { Route } from 'react-router-dom'
import { ApplicationState } from 'store'
import Styles from './Login.module.css'
import { ResetPasswordSection } from './sections/ResetPasswordSection'
import { ResetPasswordSuccessSection } from './sections/ResetPasswordSuccessSection'
import { SignInSection } from './sections/SignInSection'
import * as qs from 'query-string'
import { LogoutEverywhere } from 'pages/LogoutEverywhere/LogoutEverywhere'

export const Login = (): React.ReactElement => {
	const { profile: profileState } = useSelector((state: ApplicationState) => state)
	const navigate = useNavigate()
	const location = useLocation()
	const queryObj = qs.parse(location.search) as { [key: string]: string }

	useEffect(() => {
		const { code, qrCode, scheme } = queryObj
		const currentlyConnected = profileState.loggedIn && (code || qrCode || scheme)
		if (currentlyConnected) {
			const query = qs.stringify(queryObj)
			navigate(`/autologin?${query}`)
		}
	}, [])

	return (
		<div className={Styles['container']}>
			<Routes>
				<Route path="/login" element={<SignInSection />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="/logout/everywhere" element={<LogoutEverywhere />} />
				<Route path="/password-reset" element={<ResetPasswordSection />} />
				<Route path="/password-reset-success" element={<ResetPasswordSuccessSection />} />
			</Routes>
		</div>
	)
}
