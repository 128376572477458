import { Modal } from '@lynx/client-core/src/components'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { hideModal } from 'store/modal'
import Styles from './SignUpModal.module.css'

export const SignUpModal = (): React.ReactElement => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}

	const handleRegisterClicked = (): void => {
		navigate(`/auth/login?redirect=${window.location.href}`)
	}

	return (
		<Modal name={'SignUpModal'}>
			<div className={Styles['container']}>
				<div className={Styles['logo']}>
					<img className={Styles.logoImg} src="/assets/icons/logo.svg" alt="LYNX Logo" />
				</div>
				<div>
					LYNX Whiteboard is a presentation App to help you deliver content to anybody. Download the App to any device, register with us and you can
					share your content with others and view HD images.
					<br />
					<br />
					LYNX Whiteboard is designed to work with Clevertouch screens, find out more at: <br />
					<a href="https://www.clevertouch.com/uk/software/lynx">https://www.clevertouch.com/uk/software/lynx</a>
				</div>
				<div className={Styles['button-container']}>
					<button className={Styles['cancel-button']} onClick={handleCancelClicked}>
						Maybe Later
					</button>
					<button className={Styles['ok-button']} onClick={handleRegisterClicked}>
						Sign me up!
					</button>
				</div>
			</div>
		</Modal>
	)
}
